import { gql } from 'apollo-angular';

const LIST_WEBHOOKS_QUERY = gql`
  query Webhooks($filters: FilterWebhookParamsInput) {
    webhooks(filters: $filters, pagination: { orderBy: "inserted_at", sortingOrder: DESC, perPage: 100 }) {
      id
      events
      target
      authorization
      insertedAt
      updatedAt
      seller {
        id
      }
    }
  }
`;

const GET_EVENT_QUERY = gql`
  query Event($id: String!) {
    event(id: $id) {
      id
      type
      insertedAt
      updatedAt
      seller {
        id
      }
    }
  }
`;

const LIST_EVENTS_QUERY = gql`
  query Events($filters: FilterEventParamsInput) {
    events(filters: $filters, pagination: { orderBy: "inserted_at", sortingOrder: DESC, perPage: 100 }) {
      id
      type
      insertedAt
      updatedAt
      seller {
        id
      }
    }
  }
`;

const GET_NOTIFICATION_QUERY = gql`
  query GetNotification($id: UUID4!) {
    getNotification(id: $id) {
      id
      status
      target
      eventId
      attempts
      seller {
        id
      }
    }
  }
`;

const LIST_NOTIFICATIONS_QUERY = gql`
  query listNotifications($filters: FilterNotificationParams) {
    listNotifications(filters: $filters, pagination: { orderBy: "inserted_at", sortingOrder: DESC, perPage: 100 }) {
      id
      status
      target
      eventId
      attempts
      seller {
        id
      }
    }
  }
`;

export { LIST_WEBHOOKS_QUERY, GET_EVENT_QUERY, LIST_EVENTS_QUERY, GET_NOTIFICATION_QUERY, LIST_NOTIFICATIONS_QUERY };
