import { filter } from 'rxjs';
import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';

import {
  SYNAPSES_SIDENAV_COMPLIANCE,
  SYNAPSES_SIDENAV_FINANCE,
  SYNAPSES_SIDENAV_CX,
  SYNAPSES_SIDENAV_MARKETING,
  SYNAPSES_SIDENAV_ADM,
} from './../../utils/side-nav-synapses';
import { UserModel } from '../../models/users/users.model';
import { SidenavModel } from '../../models/utils/sidenav.model';
import { HistoryModel } from '../../models/utils/history.model';
import { StateManagementService } from '../../state-management/state-management.service';

@Component({
  selector: 'app-simple-sidenav',
  templateUrl: './simple-sidenav.component.html',
  styleUrls: ['./simple-sidenav.component.scss'],
})
export class SimpleSidenavComponent implements OnInit {
  public isCollapsed: boolean = false;
  public user: UserModel = new UserModel();
  public routes: SidenavModel[] = SYNAPSES_SIDENAV_COMPLIANCE;

  constructor(private readonly router: Router, private $notification: StateManagementService) {}

  public ngOnInit(): void {
    this.getUser();
    this.setRouteHistory();
  }

  private getUser(): void {
    this.$notification.users.subscribe((res) => {
      if (res) {
        this.user = res;

        this.routes = this.getRoutesByRole();
      }
    });
  }

  private setRouteHistory(): void {
    this.router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe((e: NavigationEnd) => {
      const sidenav = this.routes.find((route) => route.route === e.url);

      if (sidenav) {
        let routeHistory: HistoryModel[] = JSON.parse(localStorage.getItem('routeHistory')) || [];

        routeHistory = routeHistory.filter((item) => item.name !== sidenav.title);

        if (sidenav.title !== 'Visão Geral') {
          routeHistory.unshift({ name: sidenav.title, image: sidenav.svg, route: sidenav.route });
        }

        if (routeHistory.length > 6) {
          routeHistory.pop();
        }

        localStorage.setItem('routeHistory', JSON.stringify(routeHistory));
      }
    });
  }

  public openHandler(title: string): void {
    this.routes.forEach((route) => {
      if (route.title !== title) {
        route.isOpen = false;
      }
    });
  }

  public goToExternalLink(item: SidenavModel): void {
    if (item.externalLink) {
      window.open(item.externalLink, '_blank');
    }
  }

  public getRoutesByRole(): SidenavModel[] {
    const routesByRole = {
      admin: SYNAPSES_SIDENAV_ADM,
      manager: SYNAPSES_SIDENAV_ADM,
      sale: SYNAPSES_SIDENAV_CX,
      compliance: SYNAPSES_SIDENAV_COMPLIANCE,
      finance: SYNAPSES_SIDENAV_FINANCE,
      customer_experience: SYNAPSES_SIDENAV_CX,
      marketing: SYNAPSES_SIDENAV_MARKETING,
    };

    return routesByRole[this.user.role];
  }
}
