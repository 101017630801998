import moment from 'moment-timezone';
import { NzModalRef } from 'ng-zorro-antd/modal';
import { Component, Input, OnInit } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';

import { DateValidator } from '../../../validators/dateValidator';
import { PhoneNumberValidator } from '../../../validators/phoneValidator';

@Component({
  selector: 'app-modal-validate-rescue-coupon',
  templateUrl: './modal-validate-rescue-coupon.component.html',
  styleUrls: ['./modal-validate-rescue-coupon.component.scss'],
})
export class ModalValidateRescueCouponComponent implements OnInit {
  @Input() title: string = '';

  public rescueForm!: FormGroup;

  constructor(private readonly fb: FormBuilder, public readonly $modalRef: NzModalRef) {}

  public ngOnInit(): void {
    this.createForm();
    this.getValueChanges();
  }

  private createForm(): void {
    this.rescueForm = this.fb.group({
      startDate: new FormControl<string>('', [Validators.required, DateValidator.isValid()]),
      datePicker: new FormControl<Date>(moment().toDate()),
      phoneNumber: new FormControl<string>('', [Validators.required, PhoneNumberValidator.isValid()]),
      amountCents: new FormControl<number>(0, [Validators.required, this.minValueValidator(1)]),
    });
  }

  private getValueChanges(): void {
    this.rescueForm.get('datePicker').valueChanges.subscribe((date) => {
      if (date) {
        this.rescueForm.get('startDate').setValue(moment(date).format('DD/MM/YYYY'));
      }
    });
  }

  public close(hasData: boolean): void {
    if (hasData) {
      this.$modalRef.close(this.rescueForm.value);
    } else {
      this.$modalRef.close();
    }
  }

  private minValueValidator(minAmount: number): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const forbidden = control.value < minAmount;
      return forbidden ? { forbiddenValue: { value: control.value } } : null;
    };
  }
}
