export enum RoleEnum {
  ADMIN = 'Administrador',
  COMPLIANCE = 'Compliance',
  CUSTOMER_EXPERIENCE = 'CX',
  FINANCE = 'Financeiro',
  MARKETING = 'Marketing',
  USER = 'Usuário',
  MANAGER = 'Head',
  SALE = 'Vendas',
}
