import { take } from 'rxjs/operators';
import { Injectable } from '@angular/core';

import { EventModel } from '../../models/event/event.model';
import { WebhookModel } from '../../models/webhooks/webhooks.model';
import { ResponseModel } from '../../models/response/response.model';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { NotificationsModel } from '../../models/notifications/notifications.model';
import { Apollo, Query } from 'apollo-angular';
import { Observable } from 'rxjs';
import {
  GET_EVENT_QUERY,
  GET_NOTIFICATION_QUERY,
  LIST_EVENTS_QUERY,
  LIST_NOTIFICATIONS_QUERY,
  LIST_WEBHOOKS_QUERY,
} from '../../graphql/queries/webhooks.queries';
import {
  CREATE_WEBHOOK_MUTATION,
  DELETE_WEBHOOK_MUTATION,
  RETRY_NOTIFICATION_MUTATION,
  UPDATE_WEBHOOK_MUTATION,
} from '../../graphql/mutations/webhooks.mutations';

@Injectable()
export class WebhooksService {
  constructor(private apollo: Apollo) {}

  public createWebhook(params: any): Observable<any> {
    return this.apollo.mutate({
      mutation: CREATE_WEBHOOK_MUTATION,
      variables: {
        params,
      },
    });
  }

  public updateWebhook(id: string, params: any): Observable<any> {
    return this.apollo.mutate({
      mutation: UPDATE_WEBHOOK_MUTATION,
      variables: {
        id,
        params,
      },
    });
  }

  public deleteWebhook(id: string): Observable<any> {
    return this.apollo.mutate({
      mutation: DELETE_WEBHOOK_MUTATION,
      variables: {
        id,
      },
    });
  }

  public listWebhooks(filters: { sellerId: string }): Observable<any> {
    return this.apollo.watchQuery<Query>({
      query: LIST_WEBHOOKS_QUERY,
      variables: { filters },
      fetchPolicy: 'network-only',
    }).valueChanges;
  }

  public getEvent(id: string): Observable<any> {
    return this.apollo.watchQuery<Query>({
      query: GET_EVENT_QUERY,
      variables: { id },
      fetchPolicy: 'network-only',
    }).valueChanges;
  }

  public listEvents(filters: { sellerId: string }): Observable<any> {
    return this.apollo.watchQuery<Query>({
      query: LIST_EVENTS_QUERY,
      variables: { filters },
      fetchPolicy: 'network-only',
    }).valueChanges;
  }

  public getNotification(id: string): Observable<any> {
    return this.apollo.watchQuery<Query>({
      query: GET_NOTIFICATION_QUERY,
      variables: { id },
      fetchPolicy: 'network-only',
    }).valueChanges;
  }

  public listNotifications(filters: { eventId: string }): Observable<any> {
    return this.apollo.watchQuery<Query>({
      query: LIST_NOTIFICATIONS_QUERY,
      variables: { filters },
      fetchPolicy: 'network-only',
    }).valueChanges;
  }

  public retryNotification(id: string): Observable<any> {
    return this.apollo.mutate({
      mutation: RETRY_NOTIFICATION_MUTATION,
      variables: {
        id,
      },
    });
  }
}
