import { Observable, mergeMap, of, startWith, switchMap } from 'rxjs';
import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { NzModalService } from 'ng-zorro-antd/modal';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFireFunctions } from '@angular/fire/compat/functions';

import { AlertService } from '../alert/alert.service';
import { ContactsService } from '../contacts/contacts.service';
import { ContactModel } from '../../models/contact/contact.model';
import { ResponseModel } from '../../models/response/response.model';
import { CustomerModel } from '../../models/customer/customer.model';
import { PointModel } from '../../models/points/point-of-sales.model';
import { CustomAuthService } from './custom-auth/custom-auth.service';
import { CreateAccountModel } from '../../models/auth/create-account.model';
import { StateManagementService } from '../../state-management/state-management.service';
import { PointOfSalesService } from 'libs/shared/src/lib/services/point-of-sales/point-of-sales.service';
import { UsersService } from '../users/users.service';
import { CustomerService } from '../customer/customer.service';
import { ErrorService } from '../error/error.service';
import { LoginComponent } from '../../components/login/login.component';

@Injectable({
  providedIn: 'root',
})
export class AuthStoreService {
  constructor(
    private $functions: AngularFireFunctions,
    private $notification: StateManagementService,
    public firebaseAuth: AngularFireAuth,
    private customAuth: CustomAuthService,
    private $contact: ContactsService,
    private $modal: NzModalService,
    private $point: PointOfSalesService,
    private $router: Router,
    private $user: UsersService,
    private $customer: CustomerService,
    private $error: ErrorService,
    private $alert: AlertService
  ) {}

  public sendVerificationCode(data: CreateAccountModel): Observable<ResponseModel<any>> {
    return this.$functions.httpsCallable('sendVerificationCode')({
      phoneNumber: `+55${data.phoneNumber}`,
      type: data.type,
    });
  }

  public validatePhoneNumberCode(data: CreateAccountModel): Observable<ResponseModel<any>> {
    return this.$functions.httpsCallable('validatePhoneNumberCode')({
      phoneNumber: `+55${data.phoneNumber}`,
      type: data.type,
      code: data.code,
    });
  }

  public userWithPhoneNumberExists(phoneNumber: string): Observable<ResponseModel<boolean>> {
    return this.$functions.httpsCallable('userWithPhoneNumberExists')(`+55${phoneNumber}`);
  }

  public customLogin(token: string, point: PointModel): void {
    this.customAuth.setAuth(token);
    this.$alert.setAlertInfo('SUCCESS', 'Login realizado com sucesso!');
    sessionStorage.setItem('accessToken', token);
    this.getUser(point, token);
    this.$modal.closeAll();
  }

  public getUser(point: PointModel, token: string): void {
    this.$notification.users
      .pipe(
        switchMap((res) => {
          if (res) {
            return of(res);
          } else {
            return this.$user.getCurrentUser('B2C');
          }
        }),
        mergeMap((res) => {
          if (res?.data?.currentUser?.customers) {
            return this.$customer
              .getCustomerByIdMinimal(res?.data?.currentUser?.customers?.[0]?.id)
              .pipe(startWith(null));
          } else {
            return of(undefined);
          }
        })
      )
      .subscribe({
        next: (res) => {
          if (res?.data?.customer) {
            point.customerId = res?.data?.customer?.id;
            this.$notification.setCustomer(res?.data?.customer);
            this.$notification.setPoint(point);

            this.$point.updatePoint(point.id, {
              customerId: res.data.customer.id,
              auth: token,
              customerPhone: res.data.customer.phone,
            });
          }
        },
        error: (error) => {
          this.$error.errorHandlingStore(error, 'ERRO AO FAZER AUTENTICAÇÃO', 'CUSTOM', point);
          throw new Error(error);
        },
      });
  }

  public createContact(sellerId: string, pointId: string, data: ContactModel): Promise<void> {
    return this.$contact.addNewContact(sellerId, pointId, data);
  }

  public openAuthModal(point: PointModel): void {
    this.$modal.create({
      nzContent: LoginComponent,
      nzFooter: null,
      nzCentered: true,
      nzClosable: false,
      nzWidth: window.innerWidth < 768 ? '328px' : '520px',
      nzComponentParams: {
        point,
      },
      nzClassName: 'ant-modal-fullscreen',
    });
  }

  public isAuth(point: PointModel, customer: CustomerModel): boolean {
    if (point?.auth && point?.customerId && customer.id) {
      return true;
    }

    return false;
  }

  public logOut(point: PointModel, sellerId: string): void {
    sessionStorage.clear();
    this.$point.updatePoint(point.id, { customerId: null, auth: null });

    point.auth = null;
    point.customerId = null;
    this.$notification.setCustomer(null);
    this.$notification.setPoint(point);
    this.$router.navigate(['/internal/' + sellerId + '/' + point.id + '/products/list']);
    this.$alert.setAlertInfo('SUCCESS', 'log out realizado com sucesso!');
  }
}
