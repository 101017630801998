import { NzModalRef } from 'ng-zorro-antd/modal';
import { NzMessageService } from 'ng-zorro-antd/message';
import { Component, Input, Output, EventEmitter } from '@angular/core';

import { PixService } from './../../../../services/pix/pix.service';

@Component({
  selector: 'app-modal-delete-key-confirmation',
  templateUrl: './modal-delete-key-confirmation.component.html',
  styleUrls: ['./modal-delete-key-confirmation.component.scss'],
})
export class ModalDeleteKeyConfirmationComponent {
  @Input() public key: string;
  @Input() public type: string;
  @Input() public sellerId: string;
  @Output() public delete: EventEmitter<string> = new EventEmitter<string>();

  public loadingButton: boolean = false;

  constructor(public modalRef: NzModalRef, private $pix: PixService, private $message: NzMessageService) {}

  public deleteKey(): void {
    this.loadingButton = true;
    this.$pix.deletePixKey(this.key, this.sellerId).subscribe({
      next: (res) => {
        if (res.data.deletePixKey) {
          this.delete.emit('modal-delete-key-success');
        }
        this.loadingButton = false;
      },
      error: (err) => {
        this.loadingButton = false;
        this.$message.error('Não foi possível deletar esta chave. Tente novamente.');
        throw new Error(err);
      },
    });
  }
}
