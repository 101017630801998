import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { Apollo, Query } from 'apollo-angular';

import {
  CREATE_PIX_KEY,
  CREATE_STATIC_PIX_CODE,
  DELETE_PIX_KEY,
  REVERSE_PIX,
} from '../../graphql/mutations/pix.mutations';
import { PixKeyParams } from '../../models/pix/pix.model';
import { GET_PIX_PAYMENT, LIST_PIX_KEYS, LIST_PIX_PAYMENTS } from '../../graphql/queries/pix.queries';
import { FilterPaymentsParamsModel } from '../../models/payment/filter-payments-params.model';

@Injectable()
export class PixService {
  constructor(private readonly apollo: Apollo) {}

  public getPixList(sellerId: string): Observable<any> {
    return this.apollo.watchQuery<Query>({
      query: LIST_PIX_KEYS,
      variables: { sellerId },
      fetchPolicy: 'network-only',
    }).valueChanges;
  }

  public getPixPayments(sellerId: string, filters?: FilterPaymentsParamsModel): Observable<any> {
    return this.apollo.watchQuery<Query>({
      query: LIST_PIX_PAYMENTS,
      variables: { sellerId, filters },
      fetchPolicy: 'cache-and-network',
    }).valueChanges;
  }

  public getPixPayment(paymentId: string): Observable<any> {
    return this.apollo.watchQuery<Query>({
      query: GET_PIX_PAYMENT,
      variables: { paymentId },
      fetchPolicy: 'cache-and-network',
    }).valueChanges;
  }

  public createPixKey(params: PixKeyParams, sellerId: string): Observable<any> {
    return this.apollo.mutate({
      mutation: CREATE_PIX_KEY,
      variables: { params, sellerId },
    });
  }

  public deletePixKey(key: string, sellerId: string): Observable<any> {
    return this.apollo.mutate({
      mutation: DELETE_PIX_KEY,
      variables: { key, sellerId },
    });
  }

  public reversePix(amountCents: number, description: string, id: string): Observable<any> {
    return this.apollo.mutate({
      mutation: REVERSE_PIX,
      variables: { amountCents, description, id },
    });
  }

  public createStaticPixCode(
    amountCents: number,
    description: string,
    key: string,
    transactionIdentification: string,
    sellerId: string
  ): Observable<any> {
    return this.apollo.mutate({
      mutation: CREATE_STATIC_PIX_CODE,
      variables: { amountCents, description, key, transactionIdentification, sellerId },
    });
  }
}
