import { gql } from 'apollo-angular';

const CREATE_ITEM_VARIANT_MUTATION = gql`
  mutation CreateItemVariant($params: CreateItemVariantInput!) {
    createItemVariant(params: $params) {
      id
    }
  }
`;

const UPDATE_ITEM_VARIANT_MUTATION = gql`
  mutation UpdateItemVariant($id: UUID4!, $params: UpdateItemVariantParams!) {
    updateItemVariant(id: $id, params: $params) {
      id
    }
  }
`;

const CREATE_ITEM_VARIANT_OPTION_MUTATION = gql`
  mutation CreateItemVariantOption($params: CreateItemVariantOptionParams!) {
    createItemVariantOption(params: $params) {
      id
    }
  }
`;

const DELETE_ITEM_VARIANT_MUTATION = gql`
  mutation DeleteItemVariant($id: UUID4!) {
    deleteItemVariant(id: $id) {
      deletedAt
    }
  }
`;

export {
  CREATE_ITEM_VARIANT_MUTATION,
  CREATE_ITEM_VARIANT_OPTION_MUTATION,
  UPDATE_ITEM_VARIANT_MUTATION,
  DELETE_ITEM_VARIANT_MUTATION,
};
