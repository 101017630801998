import { SellerModel } from '../sellers/sellers.model';

export class MallModel {
  public id: string;
  public name: string;
  public latitude: string;
  public longitude: string;
  public qrCode: string;
  public logo: { path: string; url: string };
  public email: string;
  public address: string = '';
  public File: {
    url: string;
  };
  public seller?: SellerModel;
  public Address: {
    City: {
      Uf: {
        name: string;
        shortName: string;
      };
      name: string;
    };
    neighborhood: string;
    street: string;
    number: string | number;
    zip?: string;
  };
  public mallPhoto: {
    url: string;
  };
  public stripeConnectId: string = '';
  public externalId: string = '';
  public mallName: string = '';
  public mallAddress: string = '';
  public mallCnpj: string = '';
  public status: string = '';
  public typeMall: string = '';
  public cnpj: string = '';
  public insertedAt: string = '';
  public updatedAt: string = '';
  public authentications: Array<any> = [];
}

export class MallsFilters {
  cnpj?: string;
  typeMall?: string;
  status?: string[];
  id?: string;
  name?: string;
  insertedAt?: { afterOrEqual: string; beforeOrEqual: string };
}
