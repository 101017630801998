import { Component } from '@angular/core';
import { NzModalRef } from 'ng-zorro-antd/modal';

@Component({
  selector: 'modal-alert-installments',
  templateUrl: './modal-alert-installments.component.html',
  styleUrls: ['./modal-alert-installments.component.scss'],
})
export class ModalAlertInstallmentsComponent {
  constructor(public modalRef: NzModalRef) {}

  public closeModal(type: string): void {
    this.modalRef.close(type);
  }
}
