export enum LimitsActionEnum {
  pay = 'PAGAR',
  buy = 'COMPRAR',
  wallet = 'CARTEIRA',
  emit = 'EMITIR',
}

export enum LimitsFunctionalityEnum {
  b2b = 'Estabelecimentos',
  add_balance = 'Adicionar Saldo',
  bills = 'Faturas',
  content_reload = 'Recarga de conteúdo',
  receive = 'Receber',
}

export enum LimitsCategoryEnum {
  wallet = 'Carteira',
  pix = 'PIX',
  bank_slip = 'Boleto',
  credit_card = 'Cartão de crédito',
  transport = 'Transporte',
  games = 'Jogos',
  nfc_with_password = 'NFC com senha',
  nfc_without_password = 'NFC sem senha',
  phones = 'Telefones',
  tv = 'TV',
  digital_content = 'Conteúdo digital',
  invoice = 'Fatura',
}

export enum LimitsPeriodEnum {
  day = 'Durante o dia',
  night = 'Durante a noite',
  diary = 'Por dia',
  monthly = 'Por mês',
}
