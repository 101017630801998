<section class="list-items">
  <div nz-row class="m-b-1">
    <div nz-col nzSpan="24">
      <span class="description">
        Essas são {{ type === 'CATEGORY' ? 'suas categorias criadas' : 'seus tipos criados' }}
      </span>
    </div>
  </div>
  <div nz-row class="m-b-1">
    <div nz-col nzSpan="24">
      <nz-list [nzDataSource]="data" class="list-data">
        <nz-list-item *ngFor="let item of data" nzNoFlex>
          <ul nz-list-item-actions>
            <nz-list-item-action>
              <span
                nz-icon
                nzType="edit"
                nzTheme="outline"
                class="pointer-cursor"
                (click)="callToAction('EDIT', item)"
              ></span>
            </nz-list-item-action>
            <nz-list-item-action>
              <span
                nz-icon
                nzType="delete"
                nzTheme="outline"
                class="pointer-cursor"
                (click)="callToAction('DELETE', item)"
              ></span>
            </nz-list-item-action>
          </ul>
          {{ item.name }}
        </nz-list-item>
      </nz-list>
    </div>
  </div>
  <div nz-row class="m-b-1">
    <div nz-col nzSpan="24">
      <app-secondary-button [block]="true" icon="plus" (click)="callToAction('CREATE')">
        Criar {{ type === 'CATEGORY' ? 'nova categoria' : 'novo tipo' }}
      </app-secondary-button>
    </div>
  </div>
</section>
