import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { Apollo, Query } from 'apollo-angular';

import {
  LIST_STORES_QUERY,
  LIST_STORE_CATEGORIES_QUERY,
  GET_STORE_QUERY_BY_SELLER_ID,
} from '../../graphql/queries/store.queries';
import {
  UPSERT_STORE,
  UPSERT_STORE_PREFERENCES,
  UPDATE_STORE_WHATSAPP_NUMBER,
  UPDATE_STORE_WHATSAPP_NUMBER_CONFIRMATION,
} from '../../graphql/mutations/store.mutations';
import { StoreModel } from '../../models/store/store.model';
import { StateManagementService } from '../../state-management/state-management.service';

@Injectable()
export class StoreService {
  constructor(private readonly apollo: Apollo, private $notification: StateManagementService) {}

  public listStores(): Observable<any> {
    return this.apollo.watchQuery<Query>({
      query: LIST_STORES_QUERY,
      fetchPolicy: 'cache-and-network',
    }).valueChanges;
  }

  public listStoreCategories(): Observable<any> {
    return this.apollo.watchQuery<Query>({
      query: LIST_STORE_CATEGORIES_QUERY,
      fetchPolicy: 'cache-and-network',
    }).valueChanges;
  }

  public getStore(sellerId: string): Observable<any> {
    return this.apollo.watchQuery<Query>({
      query: GET_STORE_QUERY_BY_SELLER_ID,
      variables: { sellerId },
      fetchPolicy: 'network-only',
    }).valueChanges;
  }

  public upsertStore(params: StoreModel, sellerId: string): Observable<any> {
    return this.apollo.mutate({
      mutation: UPSERT_STORE,
      variables: { params, sellerId },
    });
  }

  public upsertStorePreferences(params: any, sellerId: string): Observable<any> {
    return this.apollo.mutate({
      mutation: UPSERT_STORE_PREFERENCES,
      variables: { params, sellerId },
    });
  }

  public updateStoreWhatsappNumberConfirmation(
    params: { code: string; whatsappNumber: string },
    sellerId: string
  ): Observable<any> {
    return this.apollo.mutate({
      mutation: UPDATE_STORE_WHATSAPP_NUMBER_CONFIRMATION,
      variables: { params, sellerId },
    });
  }

  public updateStoreWhatsappNumber(params: any, sellerId: string): Observable<any> {
    return this.apollo.mutate({
      mutation: UPDATE_STORE_WHATSAPP_NUMBER,
      variables: { params, sellerId },
    });
  }

  public getAndSetStore(sellerId: string): void {
    this.getStore(sellerId).subscribe({
      next: (res) => {
        if (res?.data?.storeBySellerId) {
          const store = JSON.parse(JSON.stringify(res?.data?.storeBySellerId));

          if (store.sellers) {
            this.$notification.setSeller(store.sellers);
          }

          this.$notification.setStore(store);
        }
      },
      error: (error) => {
        throw new Error(error);
      },
    });
  }
}
