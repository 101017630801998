import { gql } from 'apollo-angular';

const LIST_USERS_QUERY = gql`
  query Users($name: String, $phone: String, $email: String, $limit: Float, $role: UserRoleEnum, $cpf: String) {
    users(email: $email, name: $name, phone: $phone, role: $role, limit: $limit, cpf: $cpf) {
      id
      name
      cpf
      role
      active
      phone
      insertedAt
      sellers {
        cnpj
        occupation
        person {
          name
        }
        company {
          businessName
        }
      }
      customers {
        name
        cpf
      }
    }
  }
`;

const LIST_INTERNAL_USERS_QUERY = gql`
  query internalUsers($filters: FilterInternalUsersParamsInput, $pagination: PaginationParams) {
    internalUsers(filters: $filters, pagination: $pagination) {
      pageSize
      page
      totalPages
      total
      results {
        id
        role
        name
        email
        phone
        document
        insertedAt
      }
    }
  }
`;

const CURRENT_USER_QUERY = gql`
  query CurrentUser($platform: PlatformEnum!) {
    currentUser(platform: $platform) {
      id
      cpf
      name
      role
      email
      phone
      externalId
      customers {
        id
      }
      onboardingSearch {
        id
        question {
          id
          question
          order
          type
          answers {
            id
            answer
          }
        }
      }
      userSeller {
        seller_id
        updated_at
        role
      }
      onboardingSteps {
        step
      }
      sellers {
        id
        status
        occupation
        cpf
        phone
        stores {
          displayName
        }
        company {
          businessName
        }
        person {
          name
        }
      }
    }
  }
`;

const GET_USER = gql`
  query GetUser($id: UUID4!) {
    getUser(id: $id) {
      id
      cpf
      role
      name
      email
      phone
      active
      externalId
      insertedAt
    }
  }
`;

const LIST_SUBSELLERS_QUERY = gql`
  query subsellers($sellerId: String!, $filters: FilterUserSellerParamsInput, $pagination: PaginationParams) {
    subsellers(sellerId: $sellerId, filters: $filters, pagination: $pagination) {
      pageSize
      page
      totalPages
      total
      results {
        insertedAt
        id
        users {
          id
          name
          cpf
          email
          phone
        }
      }
    }
  }
`;

const GET_SUBSELLER_QUERY = gql`
  query GetSubseller($sellerId: UUID4!, $userId: UUID4!) {
    getSubseller(sellerId: $sellerId, userId: $userId) {
      id
      user {
        id
        name
        cpf
        email
        phone
      }
    }
  }
`;

export {
  LIST_USERS_QUERY,
  CURRENT_USER_QUERY,
  GET_USER,
  LIST_SUBSELLERS_QUERY,
  GET_SUBSELLER_QUERY,
  LIST_INTERNAL_USERS_QUERY,
};
