<section>
  <div class="mobile">
    <div class="m-t-1 between w-100">
      <div class="d-flex m-l-2">
        <div class="back-icon">
          <img (click)="$drawerRef.close()" src="../../assets/images/xmark.svg" alt="Ícone para fechar" />
        </div>
      </div>

      <div class="sellerName centerBlock">Filtro</div>

      <div class="clean end endBlock" (click)="form.reset()">Limpar</div>
    </div>

    <div class="m-1 w-100 centerBlock">
      <div class="inline m-1 w-100">
        <form [formGroup]="form">
          <nz-form-item class="m-t-2">
            <nz-form-label [nzSm]="24">Ordenar por: </nz-form-label>
            <nz-form-control [nzSm]="24" [nzXs]="24">
              <nz-select nzPlaceHolder="Mais relevantes" formControlName="orderBy" nzSize="large">
                <nz-option
                  *ngFor="let option of orderByOptions"
                  [nzLabel]="option.label"
                  [nzValue]="option.value"
                ></nz-option>
              </nz-select>
              <div class="arrow-select">
                <img src="../../assets/images/chevron-down-select.svg" alt="Seta para baixo" />
              </div>
            </nz-form-control>
          </nz-form-item>

          <div class="line-horizontal"></div>

          <nz-form-item>
            <nz-form-label [nzSm]="24">Categoria</nz-form-label>
            <nz-form-control [nzSm]="24" [nzXs]="24">
              <nz-select
                nzMode="multiple"
                nzPlaceHolder="Escolha a categoria"
                formControlName="category"
                nzSize="large"
                nzAllowClear
                nzShowSearch
                nzServerSearch
              >
                <nz-option *ngFor="let tag of tags" [nzLabel]="tag.name" [nzValue]="tag.id"></nz-option>
              </nz-select>
              <div class="arrow-select">
                <img src="../../assets/images/chevron-down-select.svg" alt="Seta para baixo" />
              </div>
            </nz-form-control>
          </nz-form-item>

          <!-- <div class="line-horizontal m-t-2"></div>

          <div class="switch-div">
            <label>Frete grátis </label>
            <nz-switch formControlName="freeFreight"></nz-switch>
          </div>
          <div class="line-horizontal m-t-2"></div>

          <div class="switch-div">
            <label>Descontos </label>
            <nz-switch formControlName="discounts"></nz-switch>
          </div>

          <div class="line-horizontal m-t-2"></div>

          <nz-form-item>
            <nz-form-label [nzSm]="24">Cor </nz-form-label>
            <nz-form-control [nzSm]="24" [nzXs]="24">
              <nz-select nzPlaceHolder="Selecione a cor" formControlName="color" nzSize="large">
                <nz-option *ngFor="let category of categories" [nzLabel]="category.name"
                  [nzValue]="category.id"></nz-option>
              </nz-select>
            </nz-form-control>
          </nz-form-item>

          <nz-form-item>
            <nz-form-label [nzSm]="24">Tamanho </nz-form-label>
            <nz-form-control [nzSm]="24" [nzXs]="24">
              <nz-select nzPlaceHolder="Selecione o tamanho" formControlName="size" nzSize="large">
                <nz-option *ngFor="let category of categories" [nzLabel]="category.name"
                  [nzValue]="category.id"></nz-option>
              </nz-select>
            </nz-form-control>
          </nz-form-item>

          <nz-form-item>
            <nz-form-label [nzSm]="24">Faixa de preço </nz-form-label>
            <nz-form-control [nzSm]="24" [nzXs]="24">
              <nz-slider nzRange [nzMarks]="marks" [nzMin]="0" [nzMax]="50000" formControlName="rangeValue"></nz-slider>

            </nz-form-control>
          </nz-form-item> -->
        </form>

        <div class="between p-b-1">
          <div class="buttons w-100">
            <button
              nz-button
              nzType="primary"
              nzSize="large"
              class="w-100"
              (click)="applyFilters()"
              [disabled]="form.invalid"
            >
              Filtrar
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
