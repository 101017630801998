import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import moment from 'moment';
import { NzModalRef } from 'ng-zorro-antd/modal';
import { BoxOfficeService } from '../../../services/box-office/box-office.service';
import { BoxOfficeSessions } from '../../../models/box-office/box-office.model';

@Component({
  selector: 'app-modal-create-edit-box-office-section',
  templateUrl: './modal-create-edit-box-office-section.component.html',
  styleUrls: ['./modal-create-edit-box-office-section.component.scss'],
})
export class ModalCreateEditBoxOfficeSectionComponent implements OnInit {
  @Input() public title: string = '';
  @Input() public buttonMessage: string = '';
  @Input() public startDate: string = '';
  @Input() public endDate: string = '';
  @Input() public boxOfficeId: string = '';
  @Input() public session: BoxOfficeSessions;
  @Input() public count: number = 0;

  public boxOfficeSessionForm: FormGroup;
  public boxOfficeSession: any;

  constructor(private fb: FormBuilder, public $modalRef: NzModalRef, public $boxOffice: BoxOfficeService) {}

  public ngOnInit(): void {
    this.createForm();
    this.getBoxOfficeById();
  }

  public createForm(): void {
    this.boxOfficeSessionForm = this.fb.group({
      maxParticipants: new FormControl<string>('', [Validators.required]),
      startTime: new FormControl<Date | null>(null, [Validators.required]),
      endTime: new FormControl<Date | null>(null, [Validators.required]),
    });
  }

  public getBoxOfficeById(): void {
    if (this.boxOfficeId) {
      this.$boxOffice.getBoxOfficeById(this.boxOfficeId).subscribe((res) => {
        if (res.data.boxOffice) {
          this.boxOfficeSession = res.data.boxOffice.boxOfficeSessions;
          this.boxOfficeSession = this.boxOfficeSession.filter((res) => this.session.id === res.id);

          this.getBoxOfficeFormValueById();
        }
      });
    }
  }

  public getBoxOfficeFormValueById(): void {
    this.boxOfficeSessionForm.patchValue({
      startTime: this.session.id ? this.session.startDate : this.boxOfficeSession[0].startDate,
      endTime: this.session.id ? this.session.endDate : this.boxOfficeSession[0].endDate,
      maxParticipants: this.session.id ? this.session.maxParticipants : this.boxOfficeSession[0].maxParticipants,
    });
  }

  public sendValue(): void {
    const firstTime = moment(
      this.boxOfficeSessionForm.get('startTime').value,
      'ddd MMM DD YYYY HH:mm:ss [GMT]ZZ'
    ).format('HH:mm');
    const endTime = moment(this.boxOfficeSessionForm.get('endTime').value, 'ddd MMM DD YYYY HH:mm:ss [GMT]ZZ').format(
      'HH:mm'
    );

    const firstHour = moment(firstTime, 'HH:mm');
    const secondHour = moment(endTime, 'HH:mm');

    const endDate = firstHour.add({
      hours: secondHour.hours(),
      minutes: secondHour.minutes(),
    });

    const payload = {
      ...(this.session?.id ? { id: this.session?.id } : null),
      name: this.session?.id ? this.boxOfficeSession[0].name : `Sessão ${++this.count}`,
      available: true,
      maxParticipants: this.boxOfficeSessionForm.get('maxParticipants').value,
      startDate: this.startDate + moment(this.boxOfficeSessionForm.get('startTime').value).format('HH:mm:ss.SSSZ'),
      endDate: this.endDate + endDate.format('HH:mm:ss.SSSZ'),
      duration: endTime,
    };

    this.$modalRef.close(payload);
  }
}
