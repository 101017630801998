<button
  nz-button
  [ngClass]="
    type === 'GREEN'
      ? 'success-button'
      : '' || type === 'MARKETPLACE'
      ? 'marketplace-button'
      : '' || type === 'BLACK'
      ? 'black-button'
      : '' || type === 'RED'
      ? 'red-button'
      : '' || type === 'DARK-PURPLE'
      ? 'dark-purple-button'
      : '' || type === 'BORDERLESS'
      ? 'borderless-button'
      : ''
  "
  nzType="primary"
  [disabled]="disabled"
  [nzLoading]="loading"
  [nzBlock]="block"
>
  <span nz-icon *ngIf="icon && !iconAfterText" [nzType]="icon"></span>
  <img *ngIf="iconImg" class="m-r-0-1" [src]="iconImg" [alt]="alt" />
  <span class="message" *ngIf="label">{{ label }}</span>
  <ng-content></ng-content>
  <span nz-icon *ngIf="icon && iconAfterText" [nzType]="icon"></span>
</button>
