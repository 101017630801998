<section>
  <nz-row>
    <nz-col [nzXs]="4" [nzSm]="4" [nzMd]="4" [nzLg]="2" [nzXl]="2" [nzXXl]="2">
      <img src="../../../assets/icons/icon-location.svg" alt="Ícone de localização"/>
    </nz-col>
    <nz-col [nzXs]="20" [nzSm]="20" [nzMd]="18" [nzLg]="22" [nzXl]="22" [nzXXl]="22">
      <h2>
        Por que pedimos permissões?
      </h2>
      <p>
        Ao liberar a permissão de localização, podemos proporcionar uma melhor experiência na sua busca por melhores cupons.
      </p>
    </nz-col>
  </nz-row>
</section>
