import { NzModalRef } from 'ng-zorro-antd/modal';
import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';

import { OrderModel } from '../../../models/orders/order.model';

@Component({
  selector: 'app-modal-confirmation-cancel-order',
  templateUrl: './modal-confirmation-cancel-order.component.html',
  styleUrls: ['./modal-confirmation-cancel-order.component.scss'],
})
export class ModalConfirmationCancelOrderComponent implements OnInit {
  @Input() order: OrderModel;

  public formGroup!: FormGroup;

  constructor(public readonly modalRef: NzModalRef, private readonly fb: FormBuilder) {}

  public ngOnInit(): void {
    this.createForm();
  }

  public createForm(): void {
    this.formGroup = this.fb.group({
      reason: new FormControl('', [Validators.required]),
      description: new FormControl('', [Validators.maxLength(200)]),
    });
  }

  public confirmCancellation(): void {
    this.modalRef.close(this.formGroup.value);
  }
}
