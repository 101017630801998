import { Injectable } from '@angular/core';

import { AlertService } from '../alert/alert.service';
import { NzMessageService } from 'ng-zorro-antd/message';
import { PointModel } from '../../models/points/point-of-sales.model';
import { StateManagementService } from '../../state-management/state-management.service';

@Injectable()
export class ErrorService {
  constructor(
    private $alert: AlertService,
    private readonly $message: NzMessageService,
    private $notification: StateManagementService
  ) {}

  public errorHandling(error: any, defaultMessage: string, alertType: string = 'CUSTOM'): void {
    const stringifyError = JSON.stringify(error);
    const parseError = JSON.parse(stringifyError);
    const message = parseError?.error?.error
      ? parseError.error.error
      : stringifyError?.includes('Internal Server Error') || stringifyError?.includes('Http failure response')
      ? defaultMessage
      : parseError?.message;

    if (alertType === 'CUSTOM') {
      this.$alert.setAlertInfo('ERROR', message);
    } else {
      this.$message.error(message);
    }

    throw new Error(error);
  }

  public errorHandlingStore(error: any, defaultMessage: string, alertType: string = 'CUSTOM', point: PointModel): void {
    const stringifyError = JSON.stringify(error);
    const parseError = JSON.parse(stringifyError);
    const message =
      stringifyError.includes('Internal Server Error') || stringifyError.includes('Http failure response')
        ? defaultMessage
        : parseError.message;

    if (point.auth && message.toLowerCase().includes('unauthorized')) {
      this.$notification.setOpenModal(true);

      setTimeout(() => {
        this.$notification.setOpenModal(false);
      }, 500);
    }

    if (alertType === 'CUSTOM') {
      this.$alert.setAlertInfo('ERROR', message);
    } else {
      this.$message.error(message);
    }

    throw new Error(error);
  }
}
