<section>
  <header>
    <div class="back" (click)="backCategory()">
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M15 6L9 12L15 18" stroke="#6D54FC" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
      </svg>
      <p>Voltar</p>
    </div>
  </header>

  <body>
    <div class="category-children" *ngFor="let category of selectedProductCategory.children">
      <p (click)="onSelectedCategory(category)">{{ category?.label }}</p>
      <img
        (click)="openCategoriesFilter(category.children)"
        *ngIf="category.children.length"
        src="../../assets/images/chevron-right.svg"
        alt="Seta para a direita"
      />
    </div>
  </body>

  <nz-empty *ngIf="!selectedProductCategory.children || selectedProductCategory.children.length <= 0"></nz-empty>
</section>
