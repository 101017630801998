export class CreateAccountModel {
  public type?: string;
  public code?: string;
  public phoneNumber: string;
  public email?: string;
  public sellerId?: string;
  public updatedAt?: any;
  public username?: string;
  public cpf?: string;
  public platform?: string;
}
