import { NzDrawerRef } from 'ng-zorro-antd/drawer';
import { NzMessageService } from 'ng-zorro-antd/message';
import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, FormControl } from '@angular/forms';
import { SellerModel } from '../../../models/sellers/sellers.model';
import { SellersService } from '../../../services/sellers/sellers.service';
import { NzModalRef } from 'ng-zorro-antd/modal';

@Component({
  selector: 'app-sellers-edit',
  templateUrl: './sellers-edit.component.html',
  styleUrls: ['./sellers-edit.component.scss'],
})
export class SellersEditComponent implements OnInit {
  @Input() public seller: SellerModel;

  public sellerFormGroup!: FormGroup;

  constructor(
    private readonly fb: FormBuilder,
    private $sellers: SellersService,
    public readonly $modalRef: NzModalRef,
    private readonly $message: NzMessageService
  ) {}

  public ngOnInit(): void {
    this.createFormGroup();
    this.getData();
  }

  private createFormGroup(): void {
    this.sellerFormGroup = this.fb.group({
      businessName: new FormControl<string>(''),
      tradeName: new FormControl<string>(''),
      businessLine1: new FormControl<string>(''),
      businessLine2: new FormControl<string>(''),
      businessLine3: new FormControl<string>(''),
      businessPostalCode: new FormControl<string>(''),
      businessNeighborhood: new FormControl<string>(''),
      businessCity: new FormControl<string>(''),
      businessState: new FormControl<string>(''),
      name: new FormControl<string>(''),
      observation: new FormControl<string>(''),
      isBnplActive: new FormControl<boolean>(false),
      birthDate: new FormControl<string>(''),
      nameMother: new FormControl<string>(''),
      line1: new FormControl<string>(''),
      line2: new FormControl<string>(''),
      line3: new FormControl<string>(''),
      postalCode: new FormControl<string>(''),
      neighborhood: new FormControl<string>(''),
      city: new FormControl<string>(''),
      state: new FormControl<string>(''),
      emailVerified: new FormControl<boolean>(false),
      phoneVerified: new FormControl<boolean>(false),
      receivingLimit: new FormControl<string>(''),
    });
  }

  private getData(): void {
    this.sellerFormGroup.patchValue({
      businessName: this.seller?.company?.businessName,
      tradeName: this.seller?.company?.tradeName,
      businessLine1: this.seller?.company?.address?.line1,
      businessLine2: this.seller?.company?.address?.line2,
      businessLine3: this.seller?.company?.address?.line3,
      businessPostalCode: this.seller?.company?.address?.postalCode,
      businessNeighborhood: this.seller?.company?.address?.neighborhood,
      businessCity: this.seller?.company?.address?.city,
      businessState: this.seller?.company?.address?.state,
      name: this.seller?.person?.name,
      observation: this.seller?.observation,
      isBnplActive: this.seller?.isBnplActive,
      birthDate: this.seller?.person?.birthDate,
      nameMother: this.seller?.person?.nameMother,
      line1: this.seller?.person?.address?.line1,
      line2: this.seller?.person?.address?.line2,
      line3: this.seller?.person?.address?.line3,
      postalCode: this.seller?.person?.address?.postalCode,
      neighborhood: this.seller?.person?.address?.neighborhood,
      city: this.seller?.person?.address?.city,
      state: this.seller?.person?.address?.state,
      emailVerified: this.seller?.emailVerified,
      phoneVerified: this.seller?.phoneVerified,
      receivingLimit: this.seller?.receivingLimit?.toString(),
    });
  }

  public editUser(): void {
    const updateSellerParams = {
      id: this.seller.id,
      company: {
        businessName: this.sellerFormGroup.get('businessName').value,
        tradeName: this.sellerFormGroup.get('tradeName').value,
        address: {
          line1: this.sellerFormGroup.get('businessLine1').value,
          line2: this.sellerFormGroup.get('businessLine2').value,
          line3: this.sellerFormGroup.get('businessLine3').value,
          postalCode: this.sellerFormGroup.get('businessPostalCode').value,
          neighborhood: this.sellerFormGroup.get('businessNeighborhood').value,
          city: this.sellerFormGroup.get('businessCity').value,
          state: this.sellerFormGroup.get('businessState').value,
        },
      },
      person: {
        name: this.sellerFormGroup.get('name').value,
        birthDate: this.sellerFormGroup.get('birthDate').value,
        nameMother: this.sellerFormGroup.get('nameMother').value,
        address: {
          line1: this.sellerFormGroup.get('line1').value,
          line2: this.sellerFormGroup.get('line2').value,
          line3: this.sellerFormGroup.get('line3').value,
          postalCode: this.sellerFormGroup.get('postalCode').value,
          neighborhood: this.sellerFormGroup.get('neighborhood').value,
          city: this.sellerFormGroup.get('city').value,
          state: this.sellerFormGroup.get('state').value,
        },
      },
      emailVerified: this.sellerFormGroup.get('emailVerified').value,
      phoneVerified: this.sellerFormGroup.get('phoneVerified').value,
      receivingLimit: parseInt(this.sellerFormGroup.get('receivingLimit').value || ''),
      observation: this.sellerFormGroup.get('observation').value,
      isBnplActive: this.sellerFormGroup.get('isBnplActive').value,
    };

    this.$sellers.editSeller(updateSellerParams).subscribe({
      next: (res) => {
        if (res) {
          this.$message.success('Seller editado com sucesso.');
          this.$modalRef.close(true);
        } else {
          this.$message.error('Não foi possível editar o seller.');
        }
      },
      error: (error) => {
        this.$message.error('Não foi possível editar o seller.');
        throw new Error(error);
      },
    });
  }
}
