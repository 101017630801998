<section class="signInEmailPage">
  <p class="subtitle">Informe o seu Login para entrar no catálogo digital.</p>

  <form class="m-t-2" [formGroup]="formSignIn">
    <nz-form-item>
      <nz-form-label [nzSm]="24" nzFor="signin">E-mail ou Telefone</nz-form-label>
      <nz-form-control [nzSm]="24" nzHasFeedback [nzXs]="24" nzErrorTip="E-mail ou senha incorreta.">
        <nz-input-group nzSize="large">
          <input
            nz-input
            placeholder="Digite seu login"
            [mask]="maskSelector()"
            formControlName="signin"
            id="signin"
            required
          />
        </nz-input-group>
      </nz-form-control>
    </nz-form-item>
    <ng-container *ngIf="showPassword">
      <nz-form-item>
        <nz-form-label [nzSm]="24" nzFor="password">Senha</nz-form-label>
        <nz-form-control [nzSm]="24" nzHasFeedback nzErrorTip="E-mail ou senha incorreta.">
          <nz-input-group nzSize="large" [nzSuffix]="suffixTemplate">
            <input
              [type]="rehide ? 'password' : 'text'"
              nz-input
              placeholder="Digíte sua senha"
              formControlName="password"
              id="password"
              required
            />
          </nz-input-group>
          <ng-template #suffixTemplate>
            <span
              nz-icon
              [nzType]="rehide ? 'eye-invisible' : 'eye'"
              (click)="rehide = !rehide"
              nzTheme="outline"
              class="pointer-cursor"
            ></span>
          </ng-template>
        </nz-form-control>
      </nz-form-item>
      <div class="forgot-password">
        <a (click)="verifyUserLogin(true)"> Esqueci a senha </a>
      </div>
    </ng-container>
    <div class="button-container center w-100">
      <app-primary-button
        *ngIf="showPassword"
        [loading]="loadingSignIn"
        [block]="true"
        class="center w-100"
        (click)="onSignIn()"
        [disabled]="formSignIn.invalid || loadingSignIn"
        >Entrar</app-primary-button
      >
      <app-primary-button
        *ngIf="!showPassword"
        [loading]="loadingSignIn"
        [block]="true"
        class="center w-100"
        (click)="verifyUserLogin()"
        [disabled]="formSignIn.invalid || loadingSignIn"
        >Continuar</app-primary-button
      >
    </div>
  </form>
</section>
