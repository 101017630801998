<section class="import_inventory_modal">
  <div class="header between">
    <h1 class="body-txt-bold">Importar estoque</h1>
    <span nz-icon nzType="close" nzTheme="outline" class="close-icon" (click)="modalRef.close()"></span>
  </div>
  <nz-divider></nz-divider>
  <p class="smaller-light no-margin">Esse arquivo CSV atualizará o voulume de stoque em quantidade disponivel</p>
  <form>
    <div class="upload-wrapper between pointer-cursor" (click)="upload.click()" draggable="true">
      <div class="file-icon">
        <span nz-icon nzType="file-add"></span>
      </div>
      <span class="csv-text">Arquivo CSV</span>
      <span nz-icon nzType="upload" class="upload-icon"></span>
    </div>
  </form>
  <a class="smaller-light no-margin">Saiba mais sobre importar estoque</a>
  <nz-divider></nz-divider>
  <div class="between">
    <app-secondary-button (click)="modalRef.close()">Cancelar</app-secondary-button>
    <app-primary-button (click)="modalRef.close(files)" [disabled]="!files">Exportar</app-primary-button>
  </div>
</section>

<input type="file" #upload hidden accept="text/csv" (change)="saveFile($event)" />
