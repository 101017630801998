import { Injectable } from '@angular/core';

@Injectable()
export class ForgotPasswordService {
  constructor() {}

  public validateStrongPassword(_password: string): boolean {
    if (!_password) {
      return false;
    }

    const regExp = /(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%&*()]).{6,}/;

    if (!regExp.test(_password)) {
      return false;
    }

    return true;
  }

  public validPassword(value: any): any {
    let capitalLetter: RegExp = /(?=.*[A-Z])/;
    let specialCharacter: RegExp = /(?=.*[!@#$%&*()])/;
    let lowerCase: RegExp = /(?=.*[a-z])/;
    let lengthNumber: RegExp = /.{8,}/;
    let number: RegExp = /(?=.*\d)/;
    let isCapitalLetter: string;
    let isSpecialCharacter: string;
    let isLowerCase: string;
    let isLengthNumber: string;
    let isNumber: string;

    if (capitalLetter.test(value)) {
      isCapitalLetter = 'valid';
    } else {
      isCapitalLetter = 'initial';
    }

    if (specialCharacter.test(value)) {
      isSpecialCharacter = 'valid';
    } else {
      isSpecialCharacter = 'initial';
    }

    if (lowerCase.test(value)) {
      isLowerCase = 'valid';
    } else {
      isLowerCase = 'initial';
    }

    if (lengthNumber.test(value)) {
      isLengthNumber = 'valid';
    } else {
      isLengthNumber = 'initial';
    }

    if (number.test(value)) {
      isNumber = 'valid';
    } else {
      isNumber = 'initial';
    }

    return {
      capitalLetter: isCapitalLetter,
      specialCharacter: isSpecialCharacter,
      lowerCase: isLowerCase,
      lengthNumber: isLengthNumber,
      number: isNumber,
    };
  }

  /**
   * @name verifyPassword
   * @return
   * @description Check if the passwords is the same
   */
  public verifyPassword(password: string, confirmPassword: string): boolean {
    if (password !== confirmPassword && password && confirmPassword) {
      return true;
    } else {
      return false;
    }
  }
}
