import { Router } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';
import { NzModalService } from 'ng-zorro-antd/modal';
import { Component, OnDestroy, OnInit } from '@angular/core';

import { Message } from '../../utils/message';
import { shortenName } from '../../utils/utils';
import { LeadModel } from '../../models/lead/lead.model';
import { ErrorService } from '../../services/error/error.service';
import { FidelityService } from '../../services/fidelity/fidelity.service';
import { StateManagementService } from '../../state-management/state-management.service';
import { CouponCategoryFidelityModel } from '../../models/fidelity/coupon-category-fidelity.model';
import { ModalLeadOnboardingComponent } from '../modals/modal-lead-onboarding/modal-lead-onboarding.component';

@Component({
  selector: 'app-coupon-sidenav',
  templateUrl: './coupon-sidenav.component.html',
  styleUrls: ['./coupon-sidenav.component.scss'],
})
export class CouponSidenavComponent implements OnInit, OnDestroy {
  public categories: Array<CouponCategoryFidelityModel> = [];
  public lead: LeadModel = new LeadModel();
  public isCollapsed: boolean = false;
  private entry: boolean = false;
  private destroy$ = new Subject();

  constructor(
    private $error: ErrorService,
    private readonly router: Router,
    private $fidelity: FidelityService,
    private readonly $modal: NzModalService,
    private $notification: StateManagementService
  ) {}

  public ngOnInit(): void {
    this.getLead();
    this.getAllCategories();
    this.getNotification();
  }

  public ngOnDestroy(): void {
    this.destroy$.next(1);
    this.destroy$.complete();
  }

  private getLead(): void {
    this.$notification.leads.subscribe((lead) => {
      if (lead?.id) {
        this.lead = lead;
        this.lead.name = shortenName(this.lead.name);
      }
    });
  }

  private getAllCategories(): void {
    this.$fidelity
      .getCouponCategoriesList()
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (res) => {
          if (res?.data?.allCategoriesCoupon) {
            this.categories = JSON.parse(JSON.stringify(res.data.allCategoriesCoupon));
          }
        },
        error: (error) => {
          this.$error.errorHandling(error, Message.ERROR_CONECTION);
        },
      });
  }

  private getNotification(): void {
    this.$notification.collapsedSidenavs.subscribe((res) => {
      this.isCollapsed = res;
    });
  }

  public openLoginModal(): void {
    if (!this.lead.id) {
      this.$modal.create({
        nzTitle: null,
        nzContent: ModalLeadOnboardingComponent,
        nzFooter: null,
        nzCentered: true,
        nzClosable: true,
        nzClassName: 'ant-modal-fullscreen',
      });
    }
  }

  public goToCategoryPage(id: string): void {
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => this.goTo(`/categories/${id}`));
  }

  public logout(): void {
    sessionStorage.clear();

    // Change route
    this.goTo('/').then(() => {
      window.location.reload();
    });
  }

  public onMenuClickOutside(): void {
    if (this.entry) {
      this.isCollapsed = false;
      this.entry = false;
    } else {
      this.entry = true;
    }
  }

  public goTo(route: string): Promise<boolean> {
    this.isCollapsed = false;
    return this.router.navigate([route]);
  }

  public trackByFn(index: number, category: CouponCategoryFidelityModel): string {
    return category.id;
  }
}
