import { gql } from 'apollo-angular';

const GET_ONBOARDING_QUESTIONS = gql`
  query onboardingQuestions {
    onboardingQuestions {
      id
      question
      order
      type
      active
      answers {
        id
        active
        answer
      }
    }
  }
`;

export { GET_ONBOARDING_QUESTIONS };
