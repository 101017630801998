import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-secondary-button',
  templateUrl: './secondary-button.component.html',
  styleUrls: ['./secondary-button.component.scss'],
})
export class SecondaryButtonComponent {
  @Input() public icon?: string = '';
  @Input() public iconImg?: string = '';
  @Input() public label?: string = '';
  @Input() public alt?: string = '';
  @Input() public type?: string;
  @Input() public block?: boolean = false;
  @Input() public loading?: boolean = false;
  @Input() public disabled?: boolean = false;
  @Input() public iconAfterText?: boolean = false;
  @Input() public iconImgAfterText?: boolean = false;

  constructor() {}
}
