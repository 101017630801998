<section>
  <div class="p-3 waiting m-t-3">
    <div class="text-center">
      <img src="../../assets/images/modal_success.svg" alt="loading Payment" />
    </div>

    <div class="center">
      <h2 class="note">Pronto, seu pagamento foi aprovado!</h2>
    </div>

    <div class="center">
      <span class="title">Operação {{ point?.paymentId }}</span>
    </div>

    <div class="center">
      <span class="obs">{{ point?.paymentAt | customDate }}</span>
    </div>

    <div class="center">
      <h2 class="title">{{ point?.priceToPay | currency : 'R$' }}</h2>
    </div>

    <div class="center" *ngIf="point?.method === 'credit-card'">
      <span class="obs">{{ point?.selectedCreditCard.brand }} terminado em {{ point?.selectedCreditCard.last4 }}</span>
    </div>
    <div class="center" *ngIf="point?.method === 'pix'">
      <span class="obs">Pagamento via Pix</span>
    </div>

    <div class="w-100 center centerBlock">
      <div class="button-div2">
        <button nz-button class="w-100" nzSize="large" nzType="primary" (click)="$modalRef.close()">Fechar</button>
      </div>
    </div>
  </div>
</section>
