<section class="header_marketplace">
  <div class="container">
    <div class="left-content">
      <div class="hamburguer">
        <img src="../../assets/images/menu.svg" alt="Ícone para expandir menu" (click)="openCategoriesFilter()" />
      </div>

      <div class="header-logo" (click)="goToHome()">
        <img (click)="clearList()" src="../../assets/images/new-logo-gen.svg" />
      </div>

      <div class="categories">
        <span
          nz-dropdown
          [nzDropdownMenu]="category.label === 'Categorias' ? menu : null"
          *ngFor="let category of productsCategoryListHeaderLimited; let i = index"
          (click)="category.label !== 'Categorias' ? onSelectCategory(category, 'selected', i) : null"
          [class.active]="i === activeIndex"
        >
          {{ category.label }}
        </span>

        <nz-dropdown-menu #menu="nzDropdownMenu">
          <ul nz-menu>
            <li
              *ngFor="let category of productsCategoryList.slice(0, 8)"
              nz-menu-item
              (click)="onSelectCategory(category, 'selected')"
            >
              {{ category.label }}
            </li>
            <li class="dropdown-li" (click)="goToCategories()">Ver todas as categorias</li>
          </ul>
        </nz-dropdown-menu>
      </div>
    </div>

    <div class="right-content">
      <form [formGroup]="form">
        <div class="search-icon" (click)="clickToShowDrawer()">
          <img src="../../assets/images/search-alt.svg" alt="Ícone de pesquisa" />
        </div>

        <nz-form-item>
          <nz-form-control>
            <nz-input-group [nzPrefix]="searchTemplate">
              <input type="text" nz-input nzSize="large" placeholder="Buscar" formControlName="search" />
              <ng-template #searchTemplate>
                <img src="../../assets/images/search-alt.svg" alt="Ícone de pesquisa" />
              </ng-template>
            </nz-input-group>
          </nz-form-control>
        </nz-form-item>
      </form>

      <div class="login-buttons" *ngIf="!user?.id">
        <button class="custom-button" (click)="goToAuth()">Entrar</button>

        <app-primary-button class="primary-button" type="MARKETPLACE" (click)="goToRegister()"
          >Cadastre-se</app-primary-button
        >
        <app-secondary-button class="secondary-button" type="MARKETPLACE" (click)="goToAuth()"
          >Entrar</app-secondary-button
        >
      </div>

      <div class="profile" *ngIf="user?.id">
        <nz-avatar
          (click)="!isMobile ? showOptionsProfile() : openProfile()"
          nzSize="small"
          style="background-color: #6d54fc"
          [nzText]="userInitialName"
        ></nz-avatar>

        <div class="list-of-options pointer-cursor" *ngIf="isShowOptionsProfile">
          <h3>{{ user?.name }}</h3>
          <p (click)="optionAction(option.type)" *ngFor="let option of listOfProfileOptions">{{ option.name }}</p>
        </div>
      </div>
    </div>
  </div>
</section>
