import { MallModel } from '../models/utils/mall.model';
import { SelectOptionModel } from '../models/utils/select-option.model';

export const SCHEDULES: SelectOptionModel[] = [
  { value: 'daily', name: 'Diária', wasChose: false },
  { value: 'weekly', name: 'Semanal', wasChose: false },
  { value: 'biweekly', name: 'Quinzenal', wasChose: false },
  { value: 'monthly', name: 'Mensal', wasChose: false },
  { value: 'annually', name: 'Anual', wasChose: false },
];

export const STATES: SelectOptionModel[] = [
  { value: 'AC', name: 'Acre' },
  { value: 'AL', name: 'Alagoas' },
  { value: 'AP', name: 'Amapá' },
  { value: 'AM', name: 'Amazonas' },
  { value: 'BA', name: 'Bahia' },
  { value: 'CE', name: 'Ceará' },
  { value: 'DF', name: 'Distrito Federal' },
  { value: 'ES', name: 'Espírito Santo' },
  { value: 'GO', name: 'Goiás' },
  { value: 'MA', name: 'Maranhão' },
  { value: 'MT', name: 'Mato Grosso' },
  { value: 'MS', name: 'Mato Grosso do Sul' },
  { value: 'MG', name: 'Minas Gerais' },
  { value: 'PA', name: 'Pará' },
  { value: 'PB', name: 'Paraíba' },
  { value: 'PR', name: 'Paraná' },
  { value: 'PE', name: 'Pernambuco' },
  { value: 'PI', name: 'Piauí' },
  { value: 'RJ', name: 'Rio de Janeiro' },
  { value: 'RN', name: 'Rio Grande do Norte' },
  { value: 'RS', name: 'Rio Grande do Sul' },
  { value: 'RO', name: 'Rondônia' },
  { value: 'RR', name: 'Roraima' },
  { value: 'SC', name: 'Santa Catarina' },
  { value: 'SP', name: 'São Paulo' },
  { value: 'SE', name: 'Sergipe' },
  { value: 'TO', name: 'Tocantins' },
];

export const HOURS_OF_DAY: any = {
  0: '00:00',
  1: '01:00',
  2: '02:00',
  3: '03:00',
  4: '04:00',
  5: '05:00',
  6: '06:00',
  7: '07:00',
  8: '08:00',
  9: '09:00',
  10: '10:00',
  11: '11:00',
  12: '12:00',
  13: '13:00',
  14: '14:00',
  15: '15:00',
  16: '16:00',
  17: '17:00',
  18: '18:00',
  19: '19:00',
  20: '20:00',
  21: '21:00',
  22: '22:00',
  23: '23:00',
};

export const DAYS_OF_THE_WEEK: any = {
  0: 'Domingo',
  1: 'Segunda-feira',
  2: 'Terça-feira',
  3: 'Quarta-feira',
  4: 'Quinta-feira',
  5: 'Sexta-feira',
  6: 'Sábado',
};

export const WEEKS_OF_THE_MONTH: any = {
  0: 'Primeira semana',
  1: 'Segunda semana',
  2: 'Terceira semana',
  3: 'Quarta semana',
  4: 'Quinta semana',
};

export const MONTHS: any = {
  0: 'JAN',
  1: 'FEV',
  2: 'MAR',
  3: 'ABR',
  4: 'MAI',
  5: 'JUN',
  6: 'JUL',
  7: 'AGO',
  8: 'SET',
  9: 'OUT',
  10: 'NOV',
  11: 'DEZ',
};

export const errorsField: { [key: string]: string } = {
  address: 'endereço',
  cpf: 'CPF',
  cnpj: 'CNPJ',
  email: 'Email',
  name: 'nome',
  phone: 'telefone',
  business_segment_id: 'ocupação',
  occupation: 'ocupação',
  person: 'pessoal',
  account_number: 'número da conta',
  agency_number: 'número da agência',
  account_check_number: 'dígito do número da conta',
  agency_check_number: 'dígito do número da agência',
  bankNumber: 'banco',
};

export const MALLS: Array<MallModel> = [
  {
    id: 11,
    name: 'Outlet Premium São Paulo',
    address: 'Estr. Joaquim Bueno Neto - Jardim Alegre, Itupeva - SP, 13295-000',
    acronym: 'OPSP',
    active: false,
  },
  {
    id: 14,
    name: 'Outlet Premium Grande São Paulo',
    address: 'Estr. de São Bento, 9550 - Jardim Josely, Itaquaquecetuba - SP, 08590-315',
    acronym: 'OPGSP',
    active: true,
  },
  {
    id: 9,
    name: 'Outlet Premium Rio de Janeiro',
    address: 'Rodovia BR 020, km 12, S/N - Toco (Jurema), CE, 61663-020',
    acronym: 'OPRJ',
    active: true,
  },
  {
    id: 8,
    name: 'Outlet Premium Brasília',
    address: '360, BR-060, Alexânia - GO, 72920-000',
    acronym: 'OPBSB',
    active: true,
  },
  {
    id: 10,
    name: 'Outlet Premium Salvador',
    address: 'Estr. do Coco, Km 12,5 - Abrantes, Camaçari - BA, 42827-970',
    acronym: 'OPSSA',
    active: true,
  },
  {
    id: 3,
    name: 'Shopping Bonsucesso',
    address: 'Estr. Pres. Juscelino K. de Oliveira, 5308 - Jardim Albertina, Guarulhos - SP, 07252-000',
    acronym: 'Bonsucesso',
    active: true,
  },
  {
    id: 7,
    name: 'Shopping Unimart',
    address: 'Av. Império do Sol Nascente, 350 - Chácara da República, Campinas - SP, 13033-000',
    acronym: 'Unimart',
    active: true,
  },
  {
    id: 2,
    name: 'Shopping Barueri',
    address: 'R. Gen. de Divisão Pedro Rodrigues da Silva, 400 - Aldeia, Barueri - SP, 06440-180',
    acronym: 'Barueri',
    active: true,
  },
  {
    id: 1,
    name: 'Shopping Maia',
    address: 'Av. Bartolomeu de Carlos, 230 - Jardim Flor da Montanha, Guarulhos - SP, 07097-420',
    acronym: 'Maia',
    active: true,
  },
  {
    id: 5,
    name: 'Shopping Sulacap',
    address: 'Av. Marechal Fontenele, 3545 - Jardim Sulacap, Rio de Janeiro - RJ, 21740-001',
    acronym: 'Sulacap',
    active: true,
  },
  {
    id: 6,
    name: 'Shopping do Vale',
    address: 'Av. Flores da Cunha, 4001 / Cachoeirinha - RS',
    acronym: 'Vale',
    active: true,
  },
  {
    id: 4,
    name: 'Shopping Cascavel',
    address: 'Av. Toledo, 432 - Centro, Cascavel - PR, 85810-230',
    acronym: 'Cascavel',
    active: true,
  },
  {
    id: 13,
    name: 'Shopping Poli',
    address: 'R. Dom Pedro II, 178 - Centro, Guarulhos - SP, 07011-003',
    acronym: 'Poli',
    active: false,
  },
  {
    id: 12,
    name: 'Outlet Premium Fortaleza',
    address: 'Rod. BR 020, S/N - Toco (Jurema), Fortaleza - CE, 61663-020',
    acronym: 'OPFOR',
    active: false,
  },
  {
    id: 15,
    name: 'Outlet Premium Imigrantes',
    address: 'Estr. da Cama Patente, 6202 - Alvarenga, São Bernardo do Campo - SP, 09842-100',
    acronym: 'OPI',
    active: true,
  },
];
