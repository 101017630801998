import { Subject, takeUntil } from 'rxjs';
import { NzModalRef } from 'ng-zorro-antd/modal';
import { Clipboard } from '@angular/cdk/clipboard';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Component, HostListener, Input, OnDestroy, OnInit } from '@angular/core';

import { LeadService } from '@gen/shared';

@Component({
  selector: 'app-modal-share-coupon',
  templateUrl: './modal-share-coupon.component.html',
  styleUrls: ['./modal-share-coupon.component.scss'],
})
export class ModalShareCouponComponent implements OnInit, OnDestroy {
  @Input() public couponId: string = '';

  public loading: boolean = true;
  public formGroup!: FormGroup;
  public methods: Array<{ imageUrl: string; type: string }> = [
    { imageUrl: '/assets/img/circle-whatsapp.svg', type: 'WHATSAPP' },
    { imageUrl: '/assets/img/circle-email.svg', type: 'EMAIL' },
    { imageUrl: '/assets/img/circle-telegram.svg', type: 'TELEGRAM' },
  ];
  public showAlert: boolean = false;
  public typeAlert: string = '';
  public alertMessage: string = '';
  public data: any = {};
  public isMobile: boolean = window.innerWidth < 768;

  private destroy$ = new Subject();

  constructor(
    private $lead: LeadService,
    private readonly fb: FormBuilder,
    private readonly clipboard: Clipboard,
    private readonly $modalRef: NzModalRef
  ) {}

  public ngOnInit(): void {
    this.createForm();
    this.getUrl();
  }

  public ngOnDestroy(): void {
    this.destroy$.next(1);
    this.destroy$.complete();
  }

  private createForm(): void {
    this.formGroup = this.fb.group({
      url: new FormControl<string>({ value: '', disabled: true }),
    });
  }

  private getUrl(): void {
    this.$lead
      .shareCoupon(this.couponId)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (res) => {
          if (res?.data?.shareCoupon) {
            this.data = JSON.parse(JSON.stringify(res.data.shareCoupon));
            this.formGroup.get('url').setValue(this.data?.links?.publicLink);
          }

          this.loading = false;
        },
        error: (error) => {
          this.loading = false;
          this.openAlert('error', 'Erro ao compartilhar o cupom. Por favor, tente novamente mais tarde.', error);
        },
      });
  }

  public sendToPlatform(type: string): void {
    const links = {
      WHATSAPP: this.data?.links?.whatsapp,
      EMAIL: this.data?.links?.email,
      TELEGRAM: this.data?.links?.telegram,
    };

    window.open(links[type], '_blank');
  }

  public copyContent(): void {
    const url = this.formGroup.getRawValue().url;
    this.clipboard.copy(url);
    this.openAlert('success', 'Link de pagamento copiado com sucesso!');
  }

  public closeModal(): void {
    this.$modalRef.close();
  }

  public openAlert(type: string, message: string, error?: any): void {
    if (error) {
      const parseError = JSON.parse(JSON.stringify(error));
      this.alertMessage =
        parseError.message.length > 50 ? parseError.message.substring(0, 50) + '...' : parseError.message;
    } else {
      this.alertMessage = message;
    }
    this.typeAlert = type;
    this.showAlert = true;

    setTimeout(() => {
      this.showAlert = false;
    }, 5000);
  }

  public trackByFn(index: number, method: { imageUrl: string; type: string }): string {
    return method.type;
  }

  @HostListener('window:resize', ['$event'])
  public onResize(event: any): void {
    this.isMobile = event.target.innerWidth < 768;
  }
}
