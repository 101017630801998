<nz-footer id="coupon-footer" *ngIf="title !== 'scan-coupon'">
  <nz-row class="m-b-3">
    <nz-col [nzXs]="24" [nzSm]="24" [nzMd]="12" [nzLg]="12" [nzXl]="12" [nzXXl]="12">
      <nz-row nzJustify="center">
        <nz-col [nzXs]="24" [nzSm]="24" [nzMd]="8" [nzLg]="8" [nzXl]="8" [nzXXl]="8" class="m-b-1-2">
          <h3 class="title-content">Sobre</h3>

          <div class="description-content">
            <p class="simple-text pointer-cursor" (click)="goToOutside('ABOUT')">Sobre nós</p>
            <p class="simple-text pointer-cursor" (click)="goToOutside('PRIVACY')">Política de privacidade</p>
            <p class="simple-text pointer-cursor" (click)="goToOutside('TERMS')">Termos e uso</p>
            <p class="simple-text pointer-cursor" (click)="goToOutside('CAREERS')">Trabalhe conosco</p>
          </div>
        </nz-col>

        <nz-col [nzXs]="24" [nzSm]="24" [nzMd]="8" [nzLg]="8" [nzXl]="8" [nzXXl]="8" class="m-b-1-2">
          <h3 class="title-content">Ajuda</h3>

          <div class="description-content">
            <p class="simple-text pointer-cursor" (click)="goToOutside('QUESTIONS')">Perguntas frequentes</p>
            <p class="simple-text pointer-cursor" (click)="goToOutside('TALK')">Fale conosco</p>
          </div>
        </nz-col>

        <nz-col [nzXs]="24" [nzSm]="24" [nzMd]="8" [nzLg]="8" [nzXl]="8" [nzXXl]="8" class="m-b-1-2">
          <h3 class="title-content">Serviços</h3>

          <div class="description-content">
            <p class="simple-text pointer-cursor" (click)="goToOutside('TICKET')">Estacionamento</p>
          </div>
        </nz-col>
      </nz-row>
    </nz-col>
    <nz-col
      [nzXs]="{ span: 24, offset: 0 }"
      [nzSm]="{ span: 24, offset: 0 }"
      [nzMd]="{ span: 6, offset: 6 }"
      [nzLg]="{ span: 6, offset: 6 }"
      [nzXl]="{ span: 6, offset: 6 }"
      [nzXXl]="{ span: 6, offset: 6 }"
    >
      <div class="social-media" class="m-b-1-2">
        <h3 class="title-content">Social</h3>

        <div class="medias">
          <div class="pointer-cursor" (click)="goToOutside('FACEBOOK')">
            <img src="/assets/img/facebook.svg" alt="Logo facebook em um fundo cinza" />
          </div>

          <div class="pointer-cursor" (click)="goToOutside('INSTAGRAM')">
            <img src="/assets/img/instagram.svg" alt="Logo instagram em um fundo cinza" />
          </div>

          <div class="pointer-cursor" (click)="goToOutside('LINKEDIN')">
            <img src="/assets/img/linkedin.svg" alt="Logo linkedin em um fundo cinza" />
          </div>

          <div class="pointer-cursor" (click)="goToOutside('TIKTOK')">
            <img src="/assets/img/tiktok.svg" alt="Logo tiktok em um fundo cinza" />
          </div>
        </div>
      </div>

      <div class="app-gen">
        <h3 class="title-content">Baixe nosso app</h3>

        <div class="app-platform">
          <div class="pointer-cursor" (click)="goToOutside('ANDROID')">
            <img src="/assets/img/google-play-badge.svg" alt="Logo android em um fundo cinza" />
          </div>

          <div class="pointer-cursor" (click)="goToOutside('APPLE')">
            <img src="/assets/img/app-store-badge.svg" alt="Logo apple em um fundo cinza" />
          </div>
        </div>
      </div>
    </nz-col>
  </nz-row>

  <nz-row class="m-b-1">
    <nz-col nzSpan="24" class="left">
      <img src="/assets/img/logo-genshop.svg" alt="Logo da Gen padrão" class="image-size" />
    </nz-col>
  </nz-row>
  <nz-row>
    <nz-col nzSpan="24" class="left">
      <span class="simple-text">© 2024 Gen. Todos direitos reservados.</span>
    </nz-col>
  </nz-row>
</nz-footer>
