import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { StateManagementService } from '../../state-management/state-management.service';

@Component({
  selector: 'app-mobile-header-sub-seller',
  templateUrl: './mobile-header-sub-seller.component.html',
  styleUrls: ['./mobile-header-sub-seller.component.scss'],
})
export class MobileHeaderSubSellerComponent implements OnInit {
  public title: string = '';

  constructor(
    private readonly router: Router,
    private readonly location: Location,
    private $notification: StateManagementService
  ) {}

  public ngOnInit(): void {
    this.getTitle();
  }

  public goBack(): void {
    const locationState: any = this.location.getState();

    if (locationState.navigationId > 1) {
      this.location.back();
    } else {
      this.router.navigate(['/internal/orders']);
    }
  }

  private getTitle(): void {
    this.$notification.titles.subscribe((res) => {
      this.title = res;
    });
  }
}
