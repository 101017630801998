import { FormBuilder, FormGroup, FormControl } from '@angular/forms';
import { Component, Input, OnInit, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
@Component({
  selector: 'app-code-validation-input',
  templateUrl: './code-validation-input.component.html',
  styleUrls: ['./code-validation-input.component.scss'],
})
export class CodeValidationInputComponent implements OnInit {
  @ViewChild('form') public formElement: HTMLFormElement;

  @Input() public size: number;
  @Output() public onCodeFilled: EventEmitter<string> = new EventEmitter<string>();

  public fillerArray: number[];
  public fullCode: string;
  public inputForm: FormGroup;

  constructor(private fb: FormBuilder) {}

  ngOnInit(): void {
    this.getInitialVariableValues();
    this.createForm();
  }

  private getInitialVariableValues(): void {
    this.fillerArray = this.numberToArraySequence(this.size);
    this.onCodeFilled;
  }

  private numberToArraySequence(size: number): number[] {
    return Array.from({ length: size }, (_, index) => index);
  }

  private createForm(): void {
    this.inputForm = this.fb.group({});

    this.fillerArray.forEach((n) => {
      this.inputForm.addControl(n.toString(), new FormControl(''));
    });
  }

  public setValue(): void {
    this.fullCode = '';
    this.fillerArray.forEach((n) => {
      this.fullCode += this.inputForm.get(n.toString()).value;
    });

    const inputsRef = this.formElement['nativeElement'].children;

    for (let i = 0; i < inputsRef.length; i++) {
      if (!inputsRef[i].value) {
        inputsRef[i].focus();
        break;
      }
    }

    if (this.fullCode.length === this.size) {
      this.onCodeFilled.emit(this.fullCode);
    }
  }
}
