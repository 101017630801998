import { Component } from '@angular/core';
import { NzDrawerRef } from 'ng-zorro-antd/drawer';

@Component({
  selector: 'gen-monorepo-drawer-scan-oriatation-coupon',
  templateUrl: './drawer-scan-oriatation-coupon.component.html',
  styleUrl: './drawer-scan-oriatation-coupon.component.scss',
})
export class DrawerScanOriatationCouponComponent {
  constructor(public drawerRef: NzDrawerRef) {}
}
