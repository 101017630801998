import { EMPTY, switchMap } from 'rxjs';
import { NgModule } from '@angular/core';
import { HttpLink } from 'apollo-angular/http';
import { HttpHeaders } from '@angular/common/http';
import { onError } from '@apollo/client/link/error';
import { ApolloModule, APOLLO_OPTIONS } from 'apollo-angular';
import { ApolloClientOptions, ApolloLink, InMemoryCache, Operation } from '@apollo/client/core';

import { environment } from '@gen/environments';
import { CustomAuthService } from '../services/auth/custom-auth/custom-auth.service';

let unHandledError = false;

export function createApollo(httpLink: HttpLink, customAuthService: CustomAuthService): ApolloClientOptions<any> {
  const auth = new ApolloLink((operation, forward) => {
    customAuthService.auths.subscribe(() => {
      setTokenInHeader(operation);
    });

    return forward(operation);
  });

  const errorHandler = onError(({ forward, graphQLErrors, operation }): any => {
    if (graphQLErrors && !unHandledError) {
      if (graphQLErrors.some((x) => x.message.toLowerCase().includes('unauthorized'))) {
        return customAuthService
          .refreshAuthToken()
          .pipe(
            switchMap((res) => {
              if (res?.data?.refreshAuthToken) {
                // Set token to GraphQL
                sessionStorage.setItem('accessToken', res.data.refreshAuthToken?.authToken || '');
                customAuthService.setAuth(res.data.refreshAuthToken?.authToken || '');
              }

              return EMPTY;
            })
          )
          .pipe(switchMap(() => forward(operation)));
      }

      unHandledError = true;
    } else {
      unHandledError = false;
    }
  });

  return {
    link: ApolloLink.from([auth, errorHandler, httpLink.create({ uri: environment.apiBaseUrl })]),
    cache: new InMemoryCache({ addTypename: false }),
    connectToDevTools: !environment.production,
    defaultOptions: {
      watchQuery: {
        errorPolicy: 'all',
      },
    },
  };
}

function setTokenInHeader(operation: Operation) {
  const accessToken = sessionStorage.getItem('accessToken');
  operation.setContext({
    headers: new HttpHeaders({
      Authorization: `Bearer ${accessToken}`,
    }),
  });
}

@NgModule({
  exports: [ApolloModule],
  providers: [
    {
      provide: APOLLO_OPTIONS,
      useFactory: createApollo,
      deps: [HttpLink, CustomAuthService],
    },
  ],
})
export class GraphQLModule {}
