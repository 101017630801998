import { gql } from 'apollo-angular';

const SEND_VERIFICATION_CODE_MUTATION = gql`
  mutation SendVerificationCode($signIn: String, $method: TwoFactorMethodEnum, $platform: PlatformEnum) {
    sendVerificationCode(signIn: $signIn, method: $method, platform: $platform)
  }
`;

const SEND_AUTH_CODE_MUTATION = gql`
  mutation SendAuthCode($signIn: String) {
    sendAuthCode(signIn: $signIn)
  }
`;

const VALIDATE_VERIFICATION_CODE_MUTATION = gql`
  mutation ValidateVerificationCode($signIn: String, $code: String, $platform: PlatformEnum) {
    validateVerificationCode(signIn: $signIn, code: $code, platform: $platform) {
      message
      validated
      authToken
    }
  }
`;

const RENEW_ACCESS_TOKEN_MUTATION = gql`
  mutation refreshAuthToken($refreshToken: String!) {
    renewAccessToken(refreshToken: $refreshToken) {
      accessToken
      refreshToken
    }
  }
`;

const REFRESH_AUTH_TOKEN_MUTATION = gql`
  mutation refreshAuthToken {
    refreshAuthToken {
      authToken
    }
  }
`;

const REFRESH_TOKEN_NEW_SELLER_MUTATION = gql`
  mutation refreshTokenNewSeller($sellerId: String!) {
    refreshTokenNewSeller(sellerId: $sellerId) {
      authToken
    }
  }
`;

const LOGIN_BY_PASSWORD_MUTATION = gql`
  mutation LoginByPassword($signIn: String!, $password: String!, $platform: PlatformEnum) {
    loginByPassword(signIn: $signIn, password: $password, platform: $platform) {
      email
      phone
      userType
      authToken
      sellers {
        id
        displayName
        occupation
        person {
          name
        }
        stores {
          displayName
        }
        company {
          businessName
        }
      }
    }
  }
`;

const FORGOT_PASSWORD_MUTATION = gql`
  mutation forgotPassword($signIn: String, $platform: PlatformEnum) {
    forgotPassword(signIn: $signIn, platform: $platform)
  }
`;

const CHANGE_PASSWORD_MUTATION = gql`
  mutation changePassword(
    $userId: String
    $oldPassword: String
    $newPassword: String
    $newPasswordConfirmation: String
  ) {
    changePassword(
      userId: $userId
      oldPassword: $oldPassword
      newPassword: $newPassword
      newPasswordConfirmation: $newPasswordConfirmation
    ) {
      id
    }
  }
`;

const CHANGE_PASSWORD_SYNAPSES_MUTATION = gql`
  mutation changePassword(
    $internalId: String
    $oldPassword: String
    $newPassword: String
    $newPasswordConfirmation: String
  ) {
    changePassword(
      internalId: $internalId
      oldPassword: $oldPassword
      newPassword: $newPassword
      newPasswordConfirmation: $newPasswordConfirmation
    ) {
      id
    }
  }
`;

const CREATE_PASSWORD_MUTATION = gql`
  mutation createPassword(
    $userId: String
    $newPassword: String
    $newPasswordConfirmation: String
    $leadId: String
    $internalId: String
  ) {
    createPassword(
      userId: $userId
      newPassword: $newPassword
      newPasswordConfirmation: $newPasswordConfirmation
      leadId: $leadId
      internalId: $internalId
    ) {
      id
      cpf
      name
      role
      email
      phone
      externalId
      customers {
        id
      }
      userSeller {
        seller_id
      }
      sellers {
        status
      }
    }
  }
`;

export {
  LOGIN_BY_PASSWORD_MUTATION,
  REFRESH_AUTH_TOKEN_MUTATION,
  CREATE_PASSWORD_MUTATION,
  RENEW_ACCESS_TOKEN_MUTATION,
  REFRESH_TOKEN_NEW_SELLER_MUTATION,
  CHANGE_PASSWORD_MUTATION,
  SEND_VERIFICATION_CODE_MUTATION,
  FORGOT_PASSWORD_MUTATION,
  SEND_AUTH_CODE_MUTATION,
  CHANGE_PASSWORD_SYNAPSES_MUTATION,
  VALIDATE_VERIFICATION_CODE_MUTATION,
};
