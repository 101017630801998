import { MallModel } from '../mall/mall.model';
import { DeliveryModel } from './delivery.model';
import { OpeningHoursModel } from './opening-hours.model';

export class PointOfSaleModel {
  public eatIn?: boolean = false;
  public takeOut?: boolean = false;
  public fee?: string = '';
  public phone?: string = '';
  public address?: string = '';
  public outOfHours?: boolean = false;
  public averageTime?: number = 0;
  public delivery?: DeliveryModel[] = [];
  public usingDelivery?: boolean = false;
  public serviceCharge?: boolean = false;
  public usingOpeningHours?: boolean = false;
  public openingHours?: OpeningHoursModel[] = [];
  public fantasyName?: string = '';
  public street?: string = '';
  public number?: string = '';
  public complement?: string = '';
  public neighborhood?: string = '';
  public state?: string = '';
  public city?: string = '';
  public zipCode?: string = '';
  public isLinkedMall?: boolean = false;
  public kindOfContact?: string = '';
  public mall?: MallModel = new MallModel();
  public email?: string = '';
  public category?: string = '';
}
