import { gql } from 'apollo-angular';

const SUBSCRIPTION_FIELDS = `
  chargeDay
  chargeMonth
  creditCardId
  customer {
    id
    name
  }
  endDate
  externalId
  id
  insertedAt
  installments
  lastCharge
  location {
    latitude
    longitude
  }
  marketplace {
    id
  }
  method
  methods
  observation
  paymentAttempts
  priceId
  schedulePlans
  seller {
    id
    person {
      name
    }
    company {
      businessName
    }
  }
  sessionId
  startDate
  status
  totalCents
  updatedAt
`;

const SUBSCRIPTION_FIELDS_CHECKOUT = `
  chargeDay
  chargeMonth
  creditCardId
  publicCustomer {
    id
    name
    cpf
    phone
    email
    address {
      postalCode
      line1
      line2
      line3
      neighborhood
      state
      city
    }
  }
  endDate
  externalId
  id
  insertedAt
  installments
  lastCharge
  method
  methods
  observation
  paymentAttempts
  priceId
  schedulePlans
  publicSeller {
    id
    cpf
    cnpj
    displayName
    company {
      businessName
    }
    person {
      name
    }
  }
  items {
    id
    unitPriceCents
    description
    quantity
    item {

      images {
        imageUrl
      }
    }
  }
  payments {
    id
    method
    confirmedAt
    totalCents
    status
    insertedAt
    updatedAt
    credit {
      installments
      publicCreditCard {
        brand
        last4
      }
    }
    pix {
      brcode
    }
    bankSlip {
      bank
      barcode
      printLinkPdf
    }
  }
  sessionId
  startDate
  status
  totalCents
  updatedAt
`;

const SUBSCRIPTION_FIELDS_BUSINESS = `
  chargeDay
  chargeMonth
  creditCardId
  customer {
    id
    name
    cpf
  }
  endDate
  externalId
  id
  insertedAt
  installments
  lastCharge
  method
  methods
  observation
  paymentAttempts
  priceId
  schedulePlans
  items {
    id
    unitPriceCents
    description
    quantity
  }
  seller {
    id
    person {
      name
    }
    company {
      businessName
    }
  }
  payments {
    id
    method
    confirmedAt
    totalCents
    status
    insertedAt
    updatedAt
    credit {
      installments
      creditCard {
        brand
        last4
        expirationYear
        expirationMonth
      }
    }
    bnplCheckout {
      numberOfInstallments
      returnUrl
    }
    pix {
      brcode
    }
    bankSlip {
      bank
      barcode
      printLinkPdf
    }
  }
  sessionId
  startDate
  status
  totalCents
  updatedAt
`;

const LIST_SUBSCRIPTION_QUERY = gql`
  query ListSubscriptionsAdmin($filter: FilterSubscriptionsParams) {
    listSubscriptionsAdmin(filters: $filter, pagination: { orderBy: "inserted_at", sortingOrder: DESC, perPage: 100 }) {
      id
      totalCents
      insertedAt
      method
      status
      seller {
        id
        company {
          businessName
        }
        person {
          name
        }
      }
      customer {
        id
        name
      }
    }
  }
`;

const LIST_SUBSCRIPTION_BUSINESS = gql`
  query ListSubscriptions($filter: FilterSubscriptionsParams) {
    listSubscriptions(filters: $filter, pagination: { orderBy: "inserted_at", sortingOrder: DESC, perPage: 100 }) {
      ${SUBSCRIPTION_FIELDS_BUSINESS}
    }
  }
`;

const GET_SUBSCRIPTION_QUERY = gql`
  query getSubscription($id: UUID4!) {
    getSubscription(id: $id ) {
      ${SUBSCRIPTION_FIELDS}
    }
  }
`;

const GET_SUBSCRIPTION__BUSINESS_QUERY = gql`
  query getSubscription($id: UUID4!) {
    getSubscription(id: $id ) {
      ${SUBSCRIPTION_FIELDS_BUSINESS}
    }
  }
`;

const GET_SUBSCRIPTION_CHECKOUT_QUERY = gql`
  query getSubscription($id: UUID4!) {
    getSubscription(id: $id ) {
      ${SUBSCRIPTION_FIELDS_CHECKOUT}
    }
  }
`;

export {
  LIST_SUBSCRIPTION_QUERY,
  GET_SUBSCRIPTION_QUERY,
  LIST_SUBSCRIPTION_BUSINESS,
  GET_SUBSCRIPTION__BUSINESS_QUERY,
  GET_SUBSCRIPTION_CHECKOUT_QUERY,
};
