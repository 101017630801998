<header class="modal-header" *nzModalTitle>
  <h3 class="title-category">{{ title }}</h3>
  <span class="example-spacer"></span>
  <img src="../../assets/images/close.svg" (click)="$modalRef.close()" class="pointer-cursor" />
</header>

<section class="modal-body">
  <form [formGroup]="rescueForm">
    <div nz-row [nzGutter]="24">
      <div nz-col nzSpan="12">
        <nz-form-item>
          <nz-form-label class="startBlock" [nzNoColon]="true" [nzSm]="24" nzRequired nzFor="startDate"
            >Data da compra</nz-form-label
          >
          <nz-form-control [nzSm]="24" [nzXs]="24" nzHasFeedback nzErrorTip="Campo obrigatório.">
            <nz-input-group nzSize="large">
              <input
                nz-input
                type="text"
                placeholder="00/00/0000"
                formControlName="startDate"
                id="startDate"
                mask="00/00/0000"
              />
            </nz-input-group>
          </nz-form-control>
        </nz-form-item>
      </div>
      <div nz-col nzSpan="12">
        <nz-form-item>
          <nz-form-label class="startBlock" [nzNoColon]="true" [nzSm]="24" nzRequired nzFor="phoneNumber"
            >Número do celular</nz-form-label
          >
          <nz-form-control [nzSm]="24" [nzXs]="24" nzHasFeedback nzErrorTip="Campo obrigatório.">
            <nz-input-group nzSize="large">
              <input
                nz-input
                type="text"
                placeholder="(00) 0 0000-0000"
                formControlName="phoneNumber"
                id="phoneNumber"
                mask="(00) 0 0000-0000"
              />
            </nz-input-group>
          </nz-form-control>
        </nz-form-item>
      </div>
    </div>
    <div nz-row [nzGutter]="24">
      <div nz-col nzSpan="12">
        <nz-date-picker
          nzInline
          class="centerBlock no-border"
          nzSize="small"
          nzShowToday="false"
          formControlName="datePicker"
        ></nz-date-picker>
      </div>
      <div nz-col nzSpan="12">
        <nz-form-item>
          <nz-form-label class="startBlock" [nzNoColon]="true" [nzSm]="24" nzRequired nzFor="amountCents">
            Valor da compra
            <span
              nz-icon
              class="m-l-0"
              nzType="question-circle"
              nzTheme="outline"
              nzTooltipPlacement="top"
              nzTooltipTitle="Inclua o valor total da compra, sem incluir os descontos."
              nz-tooltip
            ></span>
          </nz-form-label>
          <nz-form-control [nzSm]="24" [nzXs]="24" nzHasFeedback nzErrorTip="Campo obrigatório.">
            <nz-input-group nzSize="large">
              <input
                nz-input
                type="text"
                placeholder="R$ 00,00"
                formControlName="amountCents"
                id="amountCents"
                currencyMask
              />
            </nz-input-group>
          </nz-form-control>
        </nz-form-item>
      </div>
    </div>
  </form>
</section>
<footer class="modal-footer" *nzModalFooter>
  <div class="buttons w-100 between">
    <app-secondary-button (click)="close(false)">Cancelar</app-secondary-button>
    <app-primary-button [disabled]="rescueForm.invalid" (click)="close(true)">Confirmar</app-primary-button>
  </div>
</footer>
