import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-modal-delete-key',
  templateUrl: './modal-delete-key.component.html',
  styleUrls: ['./modal-delete-key.component.scss'],
})
export class ModalDeleteKeyComponent {
  @Input() public key: string;
  @Input() public type: string;
  @Input() public sellerId: string;

  public activeModal: string = 'modal-delete-key-confirmation';

  public handleModalActive(event: string): void {
    this.activeModal = event;
  }
}
