import { NzModalRef } from 'ng-zorro-antd/modal';
import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-modal-confirmation-payment-order',
  templateUrl: './modal-confirmation-payment-order.component.html',
  styleUrls: ['./modal-confirmation-payment-order.component.scss'],
})
export class ModalConfirmationPaymentOrderComponent implements OnInit {
  @Input() orderIds: Array<string> = [];

  public formGroup!: FormGroup;

  constructor(public readonly modalRef: NzModalRef, private readonly fb: FormBuilder) {}

  public ngOnInit(): void {
    this.createForm();
  }

  public createForm(): void {
    this.formGroup = this.fb.group({
      paymentMethod: new FormControl<string>('', [Validators.required]),
    });
  }

  public confirmCancellation(): void {
    this.modalRef.close(this.formGroup.value);
  }
}
