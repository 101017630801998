import { gql } from 'apollo-angular';

export const CUSTOMER_FIELDS_MINIMAL = `
id
phone
email
name
cpf
cnpj
status
birthDate
nameMother
address {
  line1
  line2
  line3
  postalCode
  neighborhood
  city
  state
  countryCode
}
user {
  role
  insertedAt
}
insertedAt
`;

const LIST_CUSTOMERS_QUERY = gql`
  query Customers($filter: FilterCustomersParamsInput, $pagination: PaginationParams) {
    customers(filters: $filter, pagination: $pagination) {
      pageSize
      page
      totalPages
      total
      results {
        id
        phone
        email
        name
        cpf
        status
        insertedAt
      }
    }
  }
`;

const GET_CUSTOMER_QUERY = gql`
  query getCustomerDetails($id: String!) {
    customer(id: $id) {
      id
      phone
      email
      name
      nameMother
      cpf
      status
      observation
      statusReason
      limits {
        action {
          name
        }
        amount
        perPeriod {
          name
        }
      }
      birthDate
      address {
        line1
        line2
        line3
        postalCode
        neighborhood
        city
        state
        countryCode
      }
      documents {
        id
        type
        insertedAt
        status
      }
      insertedAt
    }
  }
`;

const GET_CUSTOMER_EXTERNAL_CONSULT = gql`
  query externalCustomerConsult($id: String!) {
    externalCustomerConsult(id: $id) {
      id
      cpfCnpj
      datasets
      url
    }
  }
`;

const GET_CUSTOMER_MINIMAL_QUERY = gql`
query getCustomerDetails($id: String!) {
  customer(id: $id) {
    ${CUSTOMER_FIELDS_MINIMAL}
  }
}
`;

const CUSTOMER_EXISTS_CPF = gql`
  query customerAlreadyExists($cpf: String!) {
    customerAlreadyExists(cpf: $cpf)
  }
`;

const LIST_CUSTOMER_CREDIT_CARD = gql`
  query ListCreditCards($customerId: UUID4!) {
    listCreditCards(customerId: $customerId) {
      brand
      default
      expirationMonth
      expirationYear
      externalId
      holderName
      id
      last4
      token
    }
  }
`;

export {
  LIST_CUSTOMERS_QUERY,
  GET_CUSTOMER_QUERY,
  CUSTOMER_EXISTS_CPF,
  GET_CUSTOMER_EXTERNAL_CONSULT,
  GET_CUSTOMER_MINIMAL_QUERY,
  LIST_CUSTOMER_CREDIT_CARD,
};
