export const baseDevEnvironment = {
  production: false,
  pwaDomain: 'https://store-dev.gen.com.br',
  couponDomain: 'https://cupom-dev.gen.com.br/datails/',
  oldPwaDomain: 'https:/pwa-dev.gen.com.br',
  firebase: {
    apiKey: 'AIzaSyC4QD5hqkEBC0fDpCMN-0RiAI4LEelwsog',
    authDomain: 'neru-teste.firebaseapp.com',
    databaseURL: 'https://neru-teste.firebaseio.com',
    projectId: 'neru-teste',
    storageBucket: 'neru-teste.appspot.com',
    messagingSenderId: '442408618435',
    messagingPublicKey: 'BGZi06e3Cg4MzIBSma3AXtkY-i3IkPwnM4ehWE6O6AyggA0NkvBs0AjSMJhxNTb2GzLwJgqBRs9LzarelBUSBXo',
    appId: '1:442408618435:web:c9c254b632add9aa43d576',
    measurementId: 'G-3DJ719S03K',
  },
  marketplaceId: '1b87a436-7dad-4d1a-a362-537d85a0524c',
  metaPixelId: 'pixelId',
  campaignSellerIds: ['ff135c6e-bd73-4837-a16b-25555d351e2c', '52032167-541f-4b82-9c23-c2c6227eb0b6'],
  RECAPTCHA_V3_KEY: '6LeKy1okAAAAAKHnzfbYoyAoZEAfrqZwY2_hkvKC',
  CRYPTO_PRIVATE_KEY: '3e800e38c3414c2a8c89028241bb07f7',
  mixpanelToken: '*',
  synapsesDomain: 'https://synapses-dev.gen.com.br',
  parkingApi: 'https://homologacao.gen.shop',
  stripePublicableToken:
    'pk_test_51MbngXJfxbU5ybOw2yI265zlAE23F1zq1wiRSYu8mtyonJhMbpqU69HCETcyo9sX7Ek0y5KnP4aadNsiC5lVtOZh00FdxKB8WA',
};
