import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';

import { Message } from 'libs/shared/src/lib/utils/message';
import { LoginModel } from 'libs/shared/src/lib/models/login/login.model';
import { AuthService } from 'libs/shared/src/lib/services/auth/auth-business/auth.service';
import { Router } from '@angular/router';
import { ErrorService } from '@gen/shared';
import { StateManagementService } from 'libs/shared/src/lib/state-management/state-management.service';
import { NzModalRef } from 'ng-zorro-antd/modal';
@Component({
  selector: 'app-modal-login-marketplace',
  templateUrl: './modal-login-marketplace.component.html',
  styleUrls: ['./modal-login-marketplace.component.scss'],
})
export class ModalLoginMarketplaceComponent {
  public rehide: boolean = true;
  public formSignIn!: FormGroup;
  public login: LoginModel = new LoginModel();
  public showPassword: boolean = false;
  public loadingSignIn: boolean = false;
  public loginType: string = 'email';

  constructor(
    private $auth: AuthService,
    public $modalRef: NzModalRef,
    private $notification: StateManagementService,
    private readonly fb: FormBuilder,
    private $error: ErrorService,
    private router: Router
  ) {}

  public ngOnInit(): void {
    this.createForm();
    this.getNotifications();

    if (sessionStorage.getItem('rememberMe')) {
      this.formSignIn.get('signin').setValue(sessionStorage.getItem('rememberMe'));
      this.formSignIn.get('rememberMe').setValue(true);
    }

    this.formSignIn.get('rememberMe').valueChanges.subscribe((res) => {
      if (res) {
        sessionStorage.setItem('rememberMe', this.formSignIn.get('signin').value);
      } else {
        sessionStorage.removeItem('rememberMe');
      }
    });
  }

  public getNotifications() {
    this.$notification.users.subscribe((res) => {
      if (res?.id) {
        this.showPassword = true;
      }
    });
  }

  public createForm(): void {
    this.formSignIn = this.fb.group({
      signin: new FormControl('', [Validators.required]),
      password: new FormControl(''),
      rememberMe: new FormControl(false),
    });
  }

  public onSignIn(): void {
    this.loadingSignIn = true;
    this.login.password = this.formSignIn.get('password').value;

    this.$auth.loginByPassword(this.login.signin, this.login.password, 'LEAD').subscribe(
      (res) => {
        if (res.data?.loginByPassword) {
          this.$auth.customLoginMarketplace(res.data.loginByPassword.authToken);
          this.loadingSignIn = false;
          this.$modalRef.close();
        }
      },
      (error) => {
        this.loadingSignIn = false;
        this.$error.errorHandling(error, Message.LOGIN_ERROR);
      }
    );
  }

  public verifyUserLogin(forgotPassword?: boolean): void {
    this.loadingSignIn = true;

    this.login = new LoginModel();
    this.login.signin = this.formSignIn.get('signin').value;

    this.$auth.verifyUserLogin(this.login.signin, 'LEAD').subscribe({
      next: (res) => {
        if (res?.data?.verifyUserLogin) {
          const hasPassword = res.data.verifyUserLogin.hasPassword;

          this.loadingSignIn = false;

          if (!hasPassword || forgotPassword) {
            this.goToSelectValidation(res.data.verifyUserLogin);
          } else {
            this.showPassword = true;
          }
        }

        if (res?.errors) {
          this.loadingSignIn = false;
          const error = res.errors[0];
          this.$error.errorHandling(error, error.message);
          throw new Error(error);
        }
      },

      error: (error) => {
        this.loadingSignIn = false;
        this.$error.errorHandling(error, Message.LOGIN_ERROR);
        throw new Error(error);
      },
    });
  }

  public goToSelectValidation(user: any): void {
    this.$notification.setUser(user);
    this.router.navigate(['/external/sign-in/validate-select']);
  }

  public maskSelector(): string | null {
    if (
      (this.formSignIn.get('signin')?.value.length >= 3 && this.formSignIn.get('signin')?.value.charAt(2) === '9') ||
      this.formSignIn.get('signin')?.value.charAt(5) === '9'
    ) {
      this.loginType = 'phone';
      return '(00) 0 0000-0000';
    } else {
      this.loginType = 'email';
      return null;
    }
  }
}
