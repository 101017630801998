<nz-sider [nzCollapsedWidth]="90" [nzWidth]="250" nzTheme="light" nzCollapsible [(nzCollapsed)]="isCollapsed">
  <img
    src="/assets/images/logo_synapses.svg"
    routerLink="/internal"
    class="logo pointer-cursor"
    alt="Logo da empresa"
  />
  <nz-divider></nz-divider>
  <ul nz-menu nzMode="inline" class="sider-menu">
    <ng-container *ngFor="let item of routes">
      <li nz-menu-item routerLinkActive="active" *ngIf="!item.hide" [nzDisabled]="item.disable">
        <a
          [title]="isCollapsed ? item.title : ''"
          [routerLink]="item.route"
          (click)="goToExternalLink(item)"
          *ngIf="item.title; else nzDivider"
          class="{{ item.margin && !isCollapsed ? 'm-l-1' : '' }}"
        >
          <ng-container *ngIf="isCollapsed">
            <span *ngIf="item.disable">{{ item.title }}</span>
            <span *ngIf="item?.icon && !item.disable" nz-icon [nzType]="item?.icon"></span>
            <img
              nz-icon
              *ngIf="item?.svg && !item.disable"
              class="img-icon"
              [src]="item?.svg"
              alt="Icones aleatórios"
            />
          </ng-container>
          <ng-container *ngIf="!isCollapsed">
            <span *ngIf="item?.icon" nz-icon [nzType]="item?.icon"></span>
            <img nz-icon *ngIf="item?.svg" class="img-icon" [src]="item?.svg" alt="Icones aleatórios" />
            <span>{{ item.title }}</span>
          </ng-container>
        </a>
      </li>
    </ng-container>
    <ng-template #nzDivider>
      <nz-divider></nz-divider>
    </ng-template>
  </ul>
</nz-sider>
