import { gql } from '@apollo/client/core';

const LIST_PLANS_QUERY = gql`
  query ListPlans($filters: FilterPlansParams) {
    listPlans(filters: $filters, pagination: { orderBy: "inserted_at", sortingOrder: DESC, perPage: 100 }) {
      description
      id
      insertedAt
      name
      prices {
        amountCents
      }
      visible
    }
  }
`;

const GET_PLAN_QUERY = gql`
  query GetPlan($id: UUID4!) {
    getPlan(id: $id) {
      description
      id
      imageUrl
      insertedAt
      name
      publicSeller {
        id
        cpf
        cnpj
        displayName
        company {
          businessName
        }
        person {
          name
        }
      }
      prices {
        id
        amountCents
        schedulePlans
      }
      visible
    }
  }
`;

export { LIST_PLANS_QUERY, GET_PLAN_QUERY };
