import { gql } from 'apollo-angular';

const publicOrderFields = `
  id
  status
  type
  publicSeller {
    id
    cpf
    cnpj
    displayName
    company {
      businessName
    }
    person {
      name
    }
  }
  publicCustomer {
    id
    name
    cpf
    phone
    email
    address {
      postalCode
      line1
      line2
      line3
      neighborhood
      state
      city
    }
  }
  items {
    id
    unitPriceCents
    description
    quantity
    item {
      images {
        imageUrl
      }
    }
  }
  method
  expirationDate
  insertedAt
  updatedAt
  totalCents
  maxInstallments
  methods
  subtotalCents
  shippingCents
  discountCents
  additionCents
  deliveryMethod
  deliveryStatus
  payments {
    id
    method
    confirmedAt
    totalCents
    status
    insertedAt
    updatedAt
    credit {
      installments
      publicCreditCard {
        brand
        last4
        expirationMonth
        expirationYear
      }
    }
    pix {
      brcode
      expiration
    }
    bankSlip {
      bank
      barcode
      expirationDate
      printLinkPdf
       typefulLine
    }
  }
`;

const CREATE_ORDER = gql`
  mutation CreateOrder($params: CreateOrderParams!) {
    createOrder(params: $params) {
      id
    }
  }
`;

const CREATE_ORDERS = gql`
  mutation CreateOrders($params: CreateOrdersParams!) {
    createOrders(params: $params) {
       ${publicOrderFields}
    }
  }
`;

const CREATE_ORDER_CHECKOUT = gql`
  mutation CreateOrder($params: CreateOrderParams!) {
    createOrder(params: $params) {
      id
    }
  }
`;

const UPDATE_ORDER = gql`
  mutation UpdateOrder($orderId: UUID4!, $params: UpdateOrderParams!) {
    updateOrder(orderId: $orderId, params: $params) {
      id
    }
  }
`;

const DELETE_ORDER = gql`
  mutation deleteOrder($orderId: UUID4!) {
    deleteOrder(orderId: $orderId) {
      id
    }
  }
`;

const BATCH_DELETE_ORDERS = gql`
  mutation BatchDeleteOrders($ordersIds: [UUID4]!) {
    batchDeleteOrders(ordersIds: $ordersIds) {
      id
    }
  }
`;

const BATCH_CONFIRM_ORDER_PAYMENT = gql`
  mutation BatchConfirmOrderPayment($ordersIds: [UUID4]!, $paymentMethod: OrderValidConfirmMethodsEnum!) {
    batchConfirmOrderPayment(ordersIds: $ordersIds, paymentMethod: $paymentMethod) {
      id
    }
  }
`;

export {
  CREATE_ORDER,
  DELETE_ORDER,
  UPDATE_ORDER,
  CREATE_ORDERS,
  BATCH_DELETE_ORDERS,
  CREATE_ORDER_CHECKOUT,
  BATCH_CONFIRM_ORDER_PAYMENT,
};
