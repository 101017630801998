import { UserSellersModel } from './user-sellers.model';
import { AddressModel } from './../address/address.model';
import { SellerModel } from '../sellers/sellers.model';
import { OnboardingSearchModel, OnboardingSteps } from '../onboarding/external/onbording-questions.model';
import { UserRole } from '../../types/user-role.type';
export class InternalUserDataModel {
  public internalUsers: ResultListResponseSellers;
}
export class ResultListResponseSellers {
  pageSize: number;
  page: number;
  totalPages: number;
  total: number;
  results: UserModel[];
}
export class UserModel {
  public uid?: string;
  public name: string;
  public cpf: string;
  public email: string;
  public phone: string;
  public password?: string;
  public address?: AddressModel;
  public insertedAt?: string;
  public emailVerified?: boolean;
  public active?: boolean;
  public externalId?: string;
  public id?: string;
  public avatar?: string;
  public role?: UserRole;
  public updatedAt?: Date;
  public getUser?: UserModel;
  public internalUsers?: UserModel[] = [];
  public users?: UserModel[] = [];
  public sellers?: SellerModel[];
  public userSeller?: Array<UserSellersModel>;
  public roles?: Array<string>;
  public activeSeller?: SellerModel;
  public onboardingSteps: OnboardingSteps[] = [];
  public onboardingSearch: OnboardingSearchModel[];
  public sellerId: string;
}
