import { Component, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-input-number-range',
  templateUrl: './input-number-range.component.html',
  styleUrls: ['./input-number-range.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputNumberRangeComponent),
      multi: true,
    },
  ],
})
export class InputNumberRangeComponent implements ControlValueAccessor {
  public value: number[] = [];
  public changed: (value: number[]) => void = () => {};
  public touched: () => void;
  public isDisabled: boolean;
  public initial: number;
  public final: number;

  constructor() {}

  public writeValue(value: any): void {
    this.value = value;
  }

  public registerOnChange(fn: any): void {
    this.changed = fn;
  }

  public registerOnTouched(fn: any): void {
    this.touched = fn;
  }

  public setDisabledState?(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  public registerInput(): void {
    if (this.initial && this.final) {
      this.value = [this.initial, this.final];
      this.changed(this.value);
    }
  }
}
