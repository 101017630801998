<section class="centerBlock">
  <header class="modal-header" *nzModalTitle>
    <h3 class="title-category">{{ title }}</h3>
    <span class="example-spacer"></span>
    <img src="../../assets/images/close.svg" (click)="modalRef.close()" class="pointer-cursor" />
  </header>

  <form [formGroup]="formGroup">
    <nz-form-item>
      <nz-form-label [nzSm]="24" nzFor="street">Rua</nz-form-label>
      <nz-form-control class="w-100" [nzSm]="24" [nzXs]="24" nzHasFeedback nzErrorTip="Preenchimento obrigatório.">
        <nz-input-group nzSize="large">
          <input
            nz-input
            placeholder="Digite a rua"
            type="text"
            formControlName="street"
            id="street"
            required
            autocomplete="off"
          />
        </nz-input-group>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item class="m-t-2">
      <nz-form-label [nzSm]="24" nzFor="city">Cidade</nz-form-label>
      <nz-form-control class="w-100" [nzSm]="24" [nzXs]="24" nzHasFeedback nzErrorTip="Preenchimento obrigatório.">
        <nz-input-group nzSize="large">
          <input
            nz-input
            placeholder="Digite a cidade"
            type="text"
            formControlName="city"
            id="city"
            required
            autocomplete="off"
          />
        </nz-input-group>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item class="m-t-2">
      <nz-form-label [nzSm]="24" nzFor="state">Estado</nz-form-label>
      <nz-form-control class="w-100" [nzSm]="24" [nzXs]="24" nzHasFeedback nzErrorTip="Preenchimento obrigatório.">
        <nz-select
          id="state"
          nzShowSearch
          nzAllowClear
          nzSize="large"
          nzPlaceHolder="Estado"
          formControlName="state"
          autocomplete="off"
        >
          <nz-option *ngFor="let state of states" [nzValue]="state.value" [nzLabel]="state.name"></nz-option>
        </nz-select>
      </nz-form-control>
    </nz-form-item>

    <footer class="modal-footer" *nzModalFooter>
      <div class="buttons w-100">
        <app-secondary-button class="m-r-1" (click)="clearFields()">Limpar</app-secondary-button>
        <app-primary-button icon="search" [disabled]="!this.formGroup.valid" (click)="setHandleSubmit()"
          >Procurar</app-primary-button
        >
      </div>
    </footer>
  </form>
</section>
