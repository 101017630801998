import { ItemsV2Model } from './V2/ItemV2.model';
import { ItemUnitModel } from './V2/itemUnit.model';

export class ItemModel {
  public id?: string;
  public itemId?: string;
  public description: string;
  public unitPriceCents: number;
  public imageUrl?: string;
  public itemUnit?: ItemUnitModel;
  public item?: ItemsV2Model;
  public quantity: number;
}

export class ItemModelListOfData extends ItemModel {
  public shippingCents: number;
}
