<section class="qr_code_confirmation_modal">
  <nz-qrcode [nzValue]="qrCode"></nz-qrcode>
  <h1>QR Code sem valor definido</h1>
  <p>
    Chave aleatória <b>{{ key }}</b>
  </p>
  <div class="warning-info">
    <span nz-icon nzType="exclamation-circle" nzTheme="fill"></span>
    <div>
      <h2>Use essa imagem ou o código para receber</h2>
      <p>Mostre o QR Code ou, se preferir, compartilhe o código ou a imagem com a pessoa que vai fazer o pagamento</p>
    </div>
  </div>

  <div class="buttons">
    <app-primary-button (click)="confirm()">Confirmar</app-primary-button>
    <app-secondary-button (click)="modalRef.close()">Agora não</app-secondary-button>
  </div>
</section>
