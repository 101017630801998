import { NzModalRef } from 'ng-zorro-antd/modal';
import { Component, OnInit } from '@angular/core';
import { TermModel } from '../../../models/sellers/term.model';
import { SellersService } from '../../../services/sellers/sellers.service';
import { ErrorService } from '../../../services/error/error.service';
import { Message } from '../../../utils/message';

@Component({
  selector: 'app-modal-terms-and-conditions',
  templateUrl: './modal-terms-and-conditions.component.html',
  styleUrls: ['./modal-terms-and-conditions.component.scss'],
})
export class ModalTermsAndConditionsComponent implements OnInit {
  public term: TermModel = new TermModel();

  constructor(private readonly modalRef: NzModalRef, private $seller: SellersService, private $error: ErrorService) {}

  public ngOnInit(): void {
    this.getLastTerm();
  }

  public getLastTerm(): void {
    this.$seller.listLastTerm().subscribe({
      next: (res) => {
        if (res?.data?.latestPlatformTerm) {
          this.term = res.data.latestPlatformTerm;
        }
      },
      error: (error) => {
        this.$error.errorHandling(error, Message.ERROR_CONECTION);
      },
    });
  }

  public closeModal(): void {
    this.modalRef.destroy(this.term.id);
  }
}
