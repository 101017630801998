import { Subscription } from 'rxjs';
import { NzUploadFile, NzUploadChangeParam, NzUploadXHRArgs } from 'ng-zorro-antd/upload';
import { Component, Input, OnInit } from '@angular/core';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzModalRef } from 'ng-zorro-antd/modal';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { base64ToBlob } from '../../../utils/files';
import { ItemImagesModel } from '../../../models/items/item-images.model';

@Component({
  selector: 'app-upload-image',
  templateUrl: './upload-image.component.html',
  styleUrls: ['./upload-image.component.scss'],
})
export class UploadImageComponent implements OnInit {
  @Input() images: ItemImagesModel[] = [];
  @Input() maxInput: number = 6;
  public remaining: number = 6;
  public previewImage: string | undefined = '';
  public previewVisible = false;
  public imagesUploaded: NzUploadFile[] = [];
  public imageToCrop: File | undefined;
  public imagesInput: NzUploadFile[] = [];
  public isMissingCrop: boolean = false;

  constructor(public $modalRef: NzModalRef, private msg: NzMessageService) {}

  ngOnInit(): void {
    this.getImagesFromInput();
  }

  public getImagesFromInput(): void {
    this.images.forEach((image) => {
      if (image.url) {
        this.imagesUploaded.push({
          uid: '',
          name: '',
          originFileObj: image.file,
          url: image.url,
          thumbUrl: image.thumbUrl,
        });
      }
    });

    this.imagesInput = this.imagesUploaded;

    if (this.imagesInput.length > 0) {
      this.imageToCrop = this.imagesInput[0].originFileObj;
    }
  }

  public getBase64(file: File): string | ArrayBuffer | null {
    const reader = new FileReader();
    let result: string | ArrayBuffer = '';
    reader.readAsDataURL(file);
    reader.onload = () => (result = reader.result);
    return result;
  }

  public handleChange(event: NzUploadChangeParam): void {
    if (event.type === 'start') {
      event.file.status = 'success';
      this.remaining = 6 - this.imagesUploaded.length;
      this.imageToCrop = event.file.originFileObj;
    }

    if (event.type === 'removed') {
      this.remaining++;
    }
  }

  public customRequest(file: NzUploadXHRArgs): Subscription {
    return new Subscription();
  }

  public handlePreview = (file: NzUploadFile): void => {
    if (!file.url && !file['preview']) {
      file['preview'] = this.getBase64(file.originFileObj!);
    }

    this.previewImage = file.url || file['preview'];
    this.previewVisible = true;
  };

  public selectImage(event: any): void {
    if (event.target.children.length === 1 && event.target.children[0].children[0]) {
      const index = this.imagesUploaded.findIndex((i) => {
        return i.thumbUrl === event.target.children[0].children[0].children[0].src;
      });

      if (index !== -1) {
        const img = this.imagesUploaded[index];
        this.imageToCrop = img.originFileObj;
      }
    }
  }

  public async sendImgs(): Promise<void> {
    let index = 0;
    let imagesToSend: ItemImagesModel[] = [];

    this.imagesInput.forEach((img) => {
      const idx = this.imagesUploaded.indexOf(img);

      if (idx !== -1) {
        this.imagesUploaded.splice(idx, 1);
      }
    });

    for (let imageUploaded of this.imagesUploaded) {
      if (!imageUploaded.url) {
        this.imageToCrop = imageUploaded.originFileObj;
        this.isMissingCrop = true;
        return;
      }

      this.isMissingCrop = false;

      imagesToSend.push({
        thumbUrl: imageUploaded.thumbUrl,
        url: imageUploaded.url,
        label: '',
        file: imageUploaded.originFileObj,
        cropImage: base64ToBlob(imageUploaded.url),
      });
    }

    for (let i = 0; i < 6; i++) {
      if (!imagesToSend[index]) {
        break;
      }

      if (!this.images[i].url) {
        this.images[i] = imagesToSend[index];
        index++;
      }
    }

    this.images.forEach((image, index) => {
      image.label = index === 0 ? 'Foto de capa' : 'Imagem ' + index;
    });

    this.$modalRef.close(this.images);
  }

  public imageCropped(event: ImageCroppedEvent): void {
    const index = this.imagesUploaded.findIndex((image) => image.originFileObj === this.imageToCrop);
    this.imagesUploaded[index].url = event.base64;
  }
}
