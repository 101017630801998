import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { Apollo, Mutation, Query } from 'apollo-angular';

import {
  LIST_BNPL_CHECKOUTS_QUERY,
  LIST_SELLER_BNPL_CHECKOUTS_QUERY,
  GET_SELLER_BNPL_CHECKOUT_BALANCE_QUERY,
} from '../../graphql/queries/bnpl.queries';
import {
  REQUEST_BNPL_MUTATION,
  SET_BNPL_CHECKOUT_DELIVERED_MUTATION,
  IMPORT_PARCELEX_FINANCE_FILE_MUTATION,
  IMPORT_PARCELEX_TRANSACTIONS_FILE_MUTATION,
} from '../../graphql/mutations/bnpl.mutations';
import { FilterPaymentsParamsModel } from '../../models/payment/filter-payments-params.model';

@Injectable()
export class BnplService {
  constructor(private readonly apollo: Apollo) {}

  public requestBnpl(id: string): Observable<any> {
    return this.apollo.mutate<Mutation>({
      mutation: REQUEST_BNPL_MUTATION,
      variables: {
        id,
      },
    });
  }

  public confirmDelivery(bnplCheckoutId: string): Observable<any> {
    return this.apollo.mutate<Mutation>({
      mutation: SET_BNPL_CHECKOUT_DELIVERED_MUTATION,
      variables: {
        bnplCheckoutId,
      },
    });
  }

  public importParcelexFile(binaryFile: string, isFinance: boolean): Observable<any> {
    const mutation = isFinance ? IMPORT_PARCELEX_FINANCE_FILE_MUTATION : IMPORT_PARCELEX_TRANSACTIONS_FILE_MUTATION;

    return this.apollo.mutate<Mutation>({
      mutation,
      variables: {
        binaryFile,
      },
    });
  }

  public getBnplPayments(sellerId: string, filters?: FilterPaymentsParamsModel): Observable<any> {
    return this.apollo.watchQuery<Query>({
      query: LIST_SELLER_BNPL_CHECKOUTS_QUERY,
      fetchPolicy: 'cache-and-network',
      variables: { sellerId, filters },
    }).valueChanges;
  }

  public getSellerBnplCheckoutBalance(sellerId: string, filters?: FilterPaymentsParamsModel): Observable<any> {
    return this.apollo.watchQuery<Query>({
      query: GET_SELLER_BNPL_CHECKOUT_BALANCE_QUERY,
      fetchPolicy: 'cache-and-network',
      variables: { sellerId, filters },
    }).valueChanges;
  }

  public getBnplPaymentsToSynapses(filters?: FilterPaymentsParamsModel): Observable<any> {
    return this.apollo.watchQuery<Query>({
      query: LIST_BNPL_CHECKOUTS_QUERY,
      fetchPolicy: 'cache-and-network',
      variables: { filters },
    }).valueChanges;
  }
}
