import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'hidePartialCpf',
})
export class HidePartialCpfPipe implements PipeTransform {
  transform(value: any): any {
    if (!value) {
      return '';
    }

    const cpf = value.toString().replace(/[^\d]/g, '');
    const hidden = '***.' + cpf.substr(3, 6) + '-**';
    return hidden;
  }
}
