<section class="drawer_register_pix_key_page">
  <div class="close">
    <span nz-icon nzType="close" nzTheme="outline" class="close-icon pointer-cursor" (click)="drawerRef.close()"></span>
  </div>
  <div class="wrapper-content">
    <h1 class="colorPrimary">Cadastro de {{ keyType }}</h1>
    <p>Continue para cadastrar sua {{ keyType }}.</p>
    <div class="key">
      <div class="wrapper-info">
        <span nz-icon nzType="key" nzTheme="outline" class="key-icon"></span>
        <div>
          <h2>{{ keyType }}</h2>
          <p>O código da sua chave ainda será gerado</p>
        </div>
      </div>
    </div>
    <h3 class="colorPrimary">É importante saber</h3>
    <p>Quem te pagar através dessa chave verá alguns dos seus dados:</p>
    <div class="wrapper-data">
      <div class="labels">
        <p>
          Nome
          <span *ngIf="seller?.cnpj"> da empresa</span>
          :
        </p>
        <p>
          Seu
          <span *ngIf="seller?.cnpj">CNPJ</span>
          <span *ngIf="!seller?.cnpj">CPF</span>
          :
        </p>
      </div>
      <div class="data">
        <h2 *ngIf="seller?.company?.businessName">{{ seller?.company?.businessName || '-' }}</h2>
        <h2 *ngIf="!seller?.company?.businessName && seller?.person.name">{{ seller?.person?.name || '-' }}</h2>
        <h2 *ngIf="seller?.cnpj">{{ (seller?.cnpj | mask : '00.000.000/0000-00') || '-' }}</h2>
        <h2 *ngIf="!seller?.cnpj">{{ (seller?.cpf | mask : '000.000.000-00') || '-' }}</h2>
      </div>
    </div>
  </div>
  <nz-divider></nz-divider>
  <div class="buttons">
    <app-secondary-button (click)="drawerRef.close()">Voltar</app-secondary-button>
    <app-primary-button (click)="processKey()" [loading]="loadingButton">Confirmar</app-primary-button>
  </div>
</section>
