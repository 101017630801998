import { gql } from 'apollo-angular';

const LIST_CANCELLMENTS_QUERY = gql`
  query Cancellations($filters: FilterCancellationParamsInput, $pagination: PaginationParams) {
    cancellations(filters: $filters, pagination: $pagination) {
      pageSize
      page
      totalPages
      total
      results {
        amountCents
        id
        status
        payments {
          id
          updatedAt
        }
      }
    }
  }
`;

export { LIST_CANCELLMENTS_QUERY };
