import { combineLatest } from 'rxjs';
import { NzModalRef } from 'ng-zorro-antd/modal';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';

import { Message } from '../../../utils/message';
import { MallModel } from '../../../models/mall/mall.model';
import { ErrorService } from '../../../services/error/error.service';
import { FidelityService } from '../../../services/fidelity/fidelity.service';
import { StateManagementService } from '../../../state-management/state-management.service';
import { CouponCategoryFidelityModel } from '../../../models/fidelity/coupon-category-fidelity.model';

@Component({
  selector: 'app-modal-filter-coupon',
  templateUrl: './modal-filter-coupon.component.html',
  styleUrls: ['./modal-filter-coupon.component.scss'],
})
export class ModalFilterCouponComponent implements OnInit {
  public loading: boolean = true;
  public formGroup!: FormGroup;
  public categories: Array<CouponCategoryFidelityModel> = [];
  public malls: Array<MallModel> = [];
  public orderGroup: Array<any> = [
    { value: 'asc', label: 'Ordem alfabética' },
    { value: 'desc', label: 'Ordem alfabética invertida' },
  ];

  constructor(
    private $error: ErrorService,
    private readonly fb: FormBuilder,
    private $fidelity: FidelityService,
    private readonly $modalRef: NzModalRef,
    private $notification: StateManagementService
  ) {}

  public ngOnInit(): void {
    this.createForm();
    this.getData();
    this.getFilterData();
  }

  private createForm(): void {
    this.formGroup = this.fb.group({
      orderBy: new FormControl<string>(''),
      categories: new FormControl<Array<string>>([]),
      mallId: new FormControl<string>(''),
    });
  }

  private getData(): void {
    combineLatest({
      categories: this.$fidelity.getCouponCategoriesList(),
      malls: this.$fidelity.getMallList(),
    }).subscribe({
      next: (res) => {
        if (res) {
          if (res.categories?.data?.allCategoriesCoupon) {
            this.categories = JSON.parse(JSON.stringify(res.categories.data.allCategoriesCoupon));
          }

          if (res.malls?.data?.malls) {
            this.malls = JSON.parse(JSON.stringify(res.malls.data.malls));
          }
        }

        this.loading = false;
      },
      error: (error) => {
        this.loading = false;
        this.$error.errorHandling(error, Message.ERROR_CONECTION);
      },
    });
  }

  private getFilterData(): void {
    this.$notification.filterForms.subscribe((filterForm) => {
      if (filterForm) {
        this.updateForm(filterForm);
      }
    });
  }

  public clearFields(): void {
    this.formGroup?.reset();
    this.$notification.setFilterData({});
  }

  public setHandleSubmit(): void {
    this.$notification.setFilterForm(this.formGroup.value);
    this.closeDrawer();
  }

  public closeDrawer(): void {
    this.$modalRef.close();
  }

  private updateForm(filterForm: any): void {
    this.formGroup.patchValue({
      orderBy: filterForm?.orderBy,
      categories: filterForm?.categories,
      mallId: filterForm?.mallId,
    });
  }
}
