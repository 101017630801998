import { gql } from 'apollo-angular';

const BANNER_FIELDS = `
  id
  bannerPriority
  bannerUrl
  bannerType
  created_at
  metadata
  active
`;

const LIST_BANNERS_QUERY = gql`
  query Banners($filters: FilterBannersParamsInput, $pagination: PaginationParams) {
    banners(filters: $filters, pagination: $pagination) {
      pageSize
      page
      totalPages
      total
      results {
        id
        bannerPriority
        bannerUrl
        bannerType
        created_at
        metadata
        active
      }
    }
  }
`;

const GET_BANNER_BY_ID_QUERY = gql`
  query Banner($id: String!) {
    banner(id: $id) {
      ${BANNER_FIELDS}
    }
  }
`;

const LIST_SELECTED_BANNERS_QUERY = gql`
  query SelectedBanners($displayAt: [BannerDisplayAt!]) {
    selectedBanners(displayAt: $displayAt) {
      banners {
        ${BANNER_FIELDS}
      }
      display_at
      created_at
      active
      id
    }
  }
`;

const GET_SELECTED_BANNER_BY_ID_QUERY = gql`
  query SelectedBanner($id: String!) {
    selectedBanner(id: $id) {
      banners {
        ${BANNER_FIELDS}
      }
      display_at
      created_at
      active
      id
    }
  }
`;

export { LIST_BANNERS_QUERY, GET_BANNER_BY_ID_QUERY, LIST_SELECTED_BANNERS_QUERY, GET_SELECTED_BANNER_BY_ID_QUERY };
