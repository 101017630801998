<section *ngIf="isShowAlert">
  <div [ngClass]="showStatus.type === 'SUCCESS' ? 'alert alert__success' : 'alert alert__error'">
    <img
      [src]="
        showStatus.type === 'SUCCESS'
          ? '/assets/icons/circle-check.svg'
          : '' || showStatus.type === 'ERROR'
          ? '/assets/icons/circle-exclamation.svg'
          : ''
      "
      [alt]="showStatus.type === 'SUCCESS' ? 'Ícone de aprovado' : 'Ícone de reprovado'"
    />
    <span>{{ showStatus?.type === 'SUCCESS' ? message : '' || showStatus?.type === 'ERROR' ? message : '' }}</span>
  </div>
</section>
