<section class="number_range_input">
  <nz-form-control>
    <nz-input-number-group class="wrapperNumberRangeInput" nzCompact (blur)="touched()">
      <nz-input-number
        nzBorderless
        [(ngModel)]="initial"
        (ngModelChange)="registerInput()"
        nzPlaceHolder="0"
      ></nz-input-number>
      <img src="../../assets/images/icons/swap.svg" alt="swap-icon" />
      <nz-input-number
        nzBorderless
        [(ngModel)]="final"
        (ngModelChange)="registerInput()"
        nzPlaceHolder="00"
      ></nz-input-number>
    </nz-input-number-group>
  </nz-form-control>
</section>
