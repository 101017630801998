<div *ngIf="loading" class="h-100 m-t-2 center w-100">
  <nz-spin nzSize="large" nzSimple></nz-spin>
</div>

<section class="create-edit-invoice__page" *ngIf="!loading">
  <a (click)="goBack()" class="pointer-cursor primary-color">
    <div class="backIcon">
      <img src="../../../assets/images/icons/arrow-sm-left.svg" alt="Seta para esquerda" />
      <span>Voltar</span>
    </div>
  </a>

  <div class="title-page m-t-2">
    <h1>{{ title }}</h1>
  </div>

  <div class="content">
    <form [formGroup]="orderForm" (ngSubmit)="setHandleSubmit()">
      <nz-form-item>
        <div class="label-flex">
          <div class="startBlock">
            <label>Itens</label>
            <img
              class="tooltip-icon"
              nz-popover
              [nzPopoverContent]="popoverItemName"
              nzPopoverPlacement="right"
              nzPopoverOverlayClassName="popoverPrimary"
              src="../../../../../assets/images/icons/tooltip-icon.svg"
              alt="tooltip"
            />
          </div>

          <ng-template #popoverItemName>
            <div class="padding-1">
              <p>Ao finalizar a criação/edição, pressione a tecla ENTER.</p>
            </div>
          </ng-template>
        </div>
        <nz-form-control *ngIf="!loadingCreateItem" [nzSpan]="24" nzHasFeedback nzErrorTip="Campo obrigatório">
          <nz-select
            nzMode="multiple"
            nzShowSearch
            nzId="Itens"
            nzRequired
            [(nzOpen)]="isOpenSelect"
            [nzDropdownRender]="renderTemplateItem"
            formControlName="items"
            [compareWith]="compareFn"
            nzPlaceHolder="Selecionar ou adicionar um item"
            [nzOptions]="listOfOption"
          ></nz-select>
          <ng-template #renderTemplateItem>
            <nz-divider></nz-divider>
            <div class="m-l-1 m-b-1">
              <a class="w-40" (click)="addItem()">
                <span nz-icon nzType="plus"></span>
                Adicionar novo item
              </a>
            </div>
          </ng-template>
        </nz-form-control>
      </nz-form-item>

      <div *ngIf="itemsInput?.items?.length > 0 || addItemEnable">
        <div class="item-table-header">
          <span>Itens da fatura</span>
        </div>
        <app-item-editable-table
          class="items-detail"
          (listItems)="listItems($event)"
          (cancelform)="cancel()"
          [sellerId]="sellerId"
          (newItem)="AddNewItem($event)"
          [itemsInput]="itemsInput"
        />
      </div>

      <hr />

      <nz-form-item>
        <nz-form-control nzHasFeedback nzErrorTip="Campo obrigatório">
          <div class="label-flex">
            <div class="startBlock">
              <label>Contato</label>
            </div>
          </div>
          <nz-select
            [nzSuffixIcon]="suffixIconArrowDownTemplate"
            formControlName="contact"
            nzShowSearch
            nzAllowClear
            nzPlaceHolder="Selecionar um contato"
          >
            <nz-option
              *ngFor="let contact of listOfContacts"
              [nzValue]="contact"
              [nzLabel]="contact.fullName || contact.businessName"
            ></nz-option>
          </nz-select>
        </nz-form-control>
      </nz-form-item>

      <hr />

      <h2 class="m-t-1">Tipo de fatura</h2>
      <nz-form-item>
        <nz-form-control nzHasFeedback nzErrorTip="Campo obrigatório">
          <nz-radio-group nzRequired formControlName="frequency">
            <label nz-radio nzValue="order">Fatura simples </label>
            <label nz-radio nzValue="subscription" [ngClass]="data?.pixPayment ? 'click-disabled' : ''"
              >Fatura recorrente
            </label>
          </nz-radio-group>

          <div class="radio-group-div">
            <span *ngIf="orderForm.value.frequency === 'subscription'">
              Fatura de cobrança recorrente definida por um intervalo de tempo
            </span>
            <span *ngIf="orderForm.value.frequency === 'order'">
              Fatura de cobrança única, podendo ser paga à vista ou parcelada.
            </span>
          </div>
        </nz-form-control>
      </nz-form-item>

      <div *ngIf="showMethods">
        <hr />
        <h2 class="m-t-1">Pagamento</h2>
        <nz-form-item>
          <nz-form-control nzHasFeedback nzErrorTip="Campo obrigatório">
            <label>Formas de pagamento</label>
            <nz-checkbox-group
              [ngClass]="data?.pixPayment ? 'click-disabled' : ''"
              formControlName="methods"
            ></nz-checkbox-group>
          </nz-form-control>
        </nz-form-item>
      </div>

      <ng-container *ngIf="this.orderForm.get('frequency')?.value === 'subscription'">
        <nz-form-item>
          <nz-form-control nzHasFeedback nzErrorTip="Campo obrigatório">
            <div class="label-flex">
              <div class="startBlock">
                <label>Frequência de cobrança</label>
                <img
                  class="tooltip-icon"
                  nz-popover
                  [nzPopoverContent]="popoverItemName"
                  nzPopoverPlacement="right"
                  nzPopoverOverlayClassName="popoverPrimary"
                  src="../../../../../assets/images/icons/tooltip-icon.svg"
                  alt="tooltip"
                />
              </div>

              <ng-template #popoverItemName>
                <div>
                  <p>Ao finalizar a criação/edição, pressione a tecla ENTER.</p>
                </div>
              </ng-template>
            </div>
            <nz-select
              [nzSuffixIcon]="suffixIconArrowDownTemplate"
              formControlName="schedule"
              nzPlaceHolder="Selecionar frequência"
              [nzOptions]="scheduleList"
            >
            </nz-select>
          </nz-form-control>
        </nz-form-item>
      </ng-container>

      <hr />

      <h2 class="m-t-1">Detalhes</h2>

      <ng-container *ngIf="this.orderForm.get('frequency')?.value === 'order'">
        <nz-form-item>
          <nz-form-control nzHasFeedback nzErrorTip="Campo obrigatório">
            <div class="label-flex">
              <div class="startBlock">
                <label>Data de vencimento</label>
                <img
                  class="tooltip-icon"
                  nz-popover
                  [nzPopoverContent]="popoverItemName"
                  nzPopoverPlacement="right"
                  nzPopoverOverlayClassName="popoverPrimary"
                  src="../../../../../assets/images/icons/tooltip-icon.svg"
                  alt="tooltip"
                />
              </div>

              <ng-template #popoverItemName>
                <div>
                  <p>?</p>
                </div>
              </ng-template>
            </div>
            <nz-date-picker
              nzFormat="dd/MM/yyyy"
              nzRequired
              class="w-100"
              nzPlaceHolder="Defina o vencimento"
              formControlName="expirationDate"
              [nzDisabledDate]="disabledDate"
              [nzSuffixIcon]="suffixIconCalendarTemplate"
            >
            </nz-date-picker>
          </nz-form-control>
        </nz-form-item>

        <ng-container *ngIf="showInstallments">
          <nz-form-item>
            <nz-form-control nzHasFeedback nzErrorTip="Campo obrigatório">
              <span>Permitir parcelamento?</span>
              <nz-select
                [nzSuffixIcon]="suffixIconArrowDownTemplate"
                formControlName="installments"
                nzPlaceHolder="Selecionar parcelamento"
                [nzOptions]="listOfInstallments"
              >
              </nz-select>
            </nz-form-control>
          </nz-form-item>
        </ng-container>
      </ng-container>

      <ng-container *ngIf="this.orderForm.get('frequency')?.value === 'subscription'">
        <nz-form-item>
          <nz-form-control nzHasFeedback nzErrorTip="Campo obrigatório">
            <div class="label-flex">
              <div class="startBlock">
                <label>Vencimento da primeira cobrança</label>
                <img
                  class="tooltip-icon"
                  nz-popover
                  [nzPopoverContent]="popoverItemName"
                  nzPopoverPlacement="right"
                  nzPopoverOverlayClassName="popoverPrimary"
                  src="../../../../../assets/images/icons/tooltip-icon.svg"
                  alt="tooltip"
                />
              </div>

              <ng-template #popoverItemName>
                <div>
                  <p>?</p>
                </div>
              </ng-template>
            </div>
            <nz-date-picker
              nzFormat="dd/MM/yyyy"
              formControlName="startDate"
              class="w-100"
              nzPlaceHolder="Selecionar vencimento"
              [nzDisabledDate]="disabledDate"
              [nzSuffixIcon]="suffixIconCalendarTemplate"
            >
            </nz-date-picker>
          </nz-form-control>
        </nz-form-item>

        <nz-form-item>
          <nz-form-control nzHasFeedback nzErrorTip="Campo obrigatório">
            <div class="label-flex">
              <div class="startBlock">
                <label>Fim da assinatura</label>
              </div>
            </div>
            <nz-radio-group formControlName="endSubscription">
              <label nz-radio [nzValue]="false">Sem data definida </label>
              <label nz-radio [nzValue]="true">Definir data </label>
            </nz-radio-group>
          </nz-form-control>
        </nz-form-item>

        <nz-form-item *ngIf="this.orderForm.get('endSubscription')?.value">
          <nz-form-control nzHasFeedback nzErrorTip="Campo obrigatório">
            <nz-date-picker
              nzFormat="dd/MM/yyyy"
              class="w-100"
              formControlName="endDate"
              nzPlaceHolder="Selecionar data para fim de assinatura"
              [nzDisabledDate]="disabledDate"
              [nzSuffixIcon]="suffixIconCalendarTemplate"
            ></nz-date-picker>
          </nz-form-control>
        </nz-form-item>
      </ng-container>
    </form>

    <div class="w-100 between">
      <app-secondary-button (click)="goBack()">Cancelar</app-secondary-button>
      <app-primary-button [disabled]="loadingBtn || !showMethods" [loading]="loadingBtn" (click)="setHandleSubmit()"
        >Salvar</app-primary-button
      >
    </div>
  </div>

  <ng-template #suffixIconArrowDownTemplate>
    <svg class="float-image" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M6 9L12 15L18 9" stroke="#333333" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
    </svg>
  </ng-template>

  <ng-template #suffixIconCalendarTemplate>
    <svg
      class="float-image float-image__calendar"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3 10H21M7 3V5M17 3V5M6.2 21H17.8C18.9201 21 19.4802 21 19.908 20.782C20.2843 20.5903 20.5903 20.2843 20.782 19.908C21 19.4802 21 18.9201 21 17.8V8.2C21 7.07989 21 6.51984 20.782 6.09202C20.5903 5.71569 20.2843 5.40973 19.908 5.21799C19.4802 5 18.9201 5 17.8 5H6.2C5.0799 5 4.51984 5 4.09202 5.21799C3.71569 5.40973 3.40973 5.71569 3.21799 6.09202C3 6.51984 3 7.07989 3 8.2V17.8C3 18.9201 3 19.4802 3.21799 19.908C3.40973 20.2843 3.71569 20.5903 4.09202 20.782C4.51984 21 5.07989 21 6.2 21Z"
        stroke="#979797"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  </ng-template>
</section>
