<section>
  <div class="establishment">
    <div class="inline">
      <div clas="w-100 d-flex">
        <span class="t-name"> Tíquete </span> <span class="t-value"> {{ ticket.ticket }}</span>
      </div>
      <div class="w-100 d-flex">
        <div class="logo">
          <img [ngClass]="'mall-id-' + mall.id" [src]="mall?.logo?.url" width="100%" />
        </div>

        <div class="description">
          <h4>{{ mall?.name }}</h4>
          <p>
            {{ mall?.Address?.street }}, {{ mall?.Address?.number }}, {{ mall?.Address?.neighborhood }},
            {{ mall?.Address?.City?.Uf?.name }}
          </p>
        </div>
      </div>
    </div>
  </div>
</section>
