import { NzModalRef } from 'ng-zorro-antd/modal';
import { Component, OnInit } from '@angular/core';
import { NzMessageService } from 'ng-zorro-antd/message';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';

import { CustomerModel } from '../../models/customer/customer.model';
import { PaymentsService } from '../../services/payments/payments.service';
import { CreditCardModel } from 'libs/shared/src/lib/models/credit-card/credit-card.model';
import { PointOfSalesService } from 'libs/shared/src/lib/services/point-of-sales/point-of-sales.service';

@Component({
  selector: 'app-cvv-bottom-sheet-modal',
  templateUrl: './cvv-bottom-sheet-modal.component.html',
  styleUrls: ['./cvv-bottom-sheet-modal.component.scss'],
})
export class CvvBottomSheetModalComponent implements OnInit {
  public form!: FormGroup;
  public param: { point: any; creditCard: CreditCardModel; method: string; customer: CustomerModel };

  constructor(
    public fb: FormBuilder,
    private $point: PointOfSalesService,
    public $modalRef: NzModalRef,
    public $message: NzMessageService,
    private $payment: PaymentsService
  ) {}

  public ngOnInit(): void {
    this.createForm();
  }

  public createForm(): void {
    this.form = this.fb.group({
      cvv: new FormControl('', [Validators.required, Validators.minLength(3), Validators.maxLength(4)]),
    });
  }

  public submit(): void {
    if (this.form.get('cvv').value === this.param.creditCard.securityCode) {
      this.$point.updatePoint(this.param?.point.id, { cvv: this.form.get('cvv').value });
      this.createPayment();
      this.$modalRef.close(true);
    } else {
      this.$message.error('CVV inválido. Tente novamente.');
      this.form.get('cvv').markAsDirty();
      this.form.get('cvv').setErrors({ invalid: true });
      this.form.get('cvv').markAsTouched();
    }
  }

  public createPayment(): void {
    const payload = this.getPayload();
    this.$payment.createCheckoutPayments(payload);
  }

  public getPayload(): Array<object> {
    const payload = [];

    this.param.point.cart.baskets.map((basket) => {
      payload.push({
        method: this.param.point.selectPaymentMethod.method,
        orderId: basket.order.id || basket.orderId,
        credit: {
          creditCard: {
            expirationMonth: this.param.creditCard.expirationMonth,
            expirationYear: this.param.creditCard.expirationYear,
            holderName: this.param.creditCard.holderName,
            securityCode: this.param.creditCard.securityCode,
            token: this.param.creditCard.token,
          },
          installments: basket.installments || 1,
        },
      });
    });

    return payload;
  }
}
