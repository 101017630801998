import { NzModalRef } from 'ng-zorro-antd/modal';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-modal-reject-message',
  templateUrl: './modal-reject-message.component.html',
  styleUrls: ['./modal-reject-message.component.scss'],
})
export class ModalRejectMessageComponent implements OnInit {
  public formGroup!: FormGroup;

  constructor(private readonly fb: FormBuilder, private readonly $modalRef: NzModalRef) {}

  public ngOnInit() {
    this.createForm();
  }

  public createForm(): void {
    this.formGroup = this.fb.group({
      reason: new FormControl('', [Validators.required, Validators.minLength(10), Validators.maxLength(255)]),
    });
  }

  public handleReject(): void {
    if (this.formGroup.valid) {
      this.$modalRef.close(this.formGroup.value);
    }
  }
}
