import { NzDrawerRef } from 'ng-zorro-antd/drawer';
import { NzModalService } from 'ng-zorro-antd/modal';
import { Component, Input, OnInit } from '@angular/core';
import { NzMessageService } from 'ng-zorro-antd/message';

import { getSmallModalWidth } from '../../../utils/utils';
import { PixService } from '../../../services/pix/pix.service';
import { ErrorService } from '../../../services/error/error.service';
import { PaymentModel } from '../../../models/payment/payment.model';
import { TableHeaderModel } from '../../../models/table/table-header.model';
import { PaymentsService } from '../../../services/payments/payments.service';
import { TableSettingsModel } from '../../../models/table/table-settings.model';
import { ModalConfirmationCancelPaymentComponent } from '../../modals/modal-confirmation-cancel-payment/modal-confirmation-cancel-payment.component';

@Component({
  selector: 'app-drawer-payments-details',
  templateUrl: './drawer-payments-details.component.html',
  styleUrls: ['./drawer-payments-details.component.scss'],
})
export class DrawerPaymentsDetailsComponent implements OnInit {
  @Input() payment: PaymentModel;

  public listOfHeadersColumn: TableHeaderModel[] = [];
  public listOfData: any[] = [];
  public settingValue: TableSettingsModel = new TableSettingsModel();
  public listOfTotalHeadersColumn: TableHeaderModel[] = [];
  public listOfTotalData: any[] = [];
  public settingTotalValue: TableSettingsModel = new TableSettingsModel();
  public loadingBtn: boolean = false;

  constructor(
    private $pix: PixService,
    private $error: ErrorService,
    private $payments: PaymentsService,
    public readonly drawerRef: NzDrawerRef,
    private readonly $modal: NzModalService,
    private readonly $message: NzMessageService
  ) {}

  public ngOnInit(): void {
    this.getTotalTableData();
    this.getTableData();
    this.getListsOfData();
  }

  private getListsOfData(): void {
    this.listOfData = this.payment?.order?.items || [];

    this.listOfTotalData = [
      {
        subtotal: this.payment.subtotalCents,
        discount: this.payment.discountCents,
        delivery: this.payment.shippingCents,
        addition: this.payment.additionCents,
        total: this.payment.totalCents,
      },
    ];
  }

  private getTotalTableData(): void {
    this.settingTotalValue = {
      checkbox: false,
      pagination: false,
      footer: false,
      expandable: false,
    };

    this.listOfTotalHeadersColumn = [
      { name: 'Subtotal' },
      { name: 'Desconto' },
      { name: 'Frete' },
      { name: 'Adições' },
      { name: 'Total' },
    ];
  }

  private getTableData(): void {
    this.settingValue = {
      checkbox: false,
      pagination: true,
      footer: false,
      expandable: false,
    };

    this.listOfHeadersColumn = [{ name: 'Descrição' }, { name: 'Quantidade' }, { name: 'Valor' }];
  }

  public cancelPayment(): void {
    if (this.payment.method === 'PIX') {
      this.$modal
        .create({
          nzContent: ModalConfirmationCancelPaymentComponent,
          nzFooter: null,
          nzClosable: false,
          nzWidth: getSmallModalWidth(),
          nzComponentParams: {
            payment: this.payment,
          },
        })
        .afterClose.subscribe((res: any) => {
          if (res) {
            this.loadingBtn = true;

            this.$pix.reversePix(Math.round(res.amountCents * 100), res.description, this.payment.id).subscribe({
              next: (res) => {
                if (res?.data?.reversePix) {
                  this.$message.success('Valor devolvido com sucesso.');
                }

                this.loadingBtn = false;
              },
              error: (error) => {
                this.loadingBtn = false;
                this.$error.errorHandling(error, 'Falha ao devolver valor. Tente novamente.');
              },
            });
          }
        });
    } else {
      this.loadingBtn = true;

      this.$payments.cancelPaymentApollo(this.payment?.id).subscribe({
        next: (res) => {
          if (res?.data?.cancelPayment) {
            this.$message.success('Solicitação de reembolso realizada.');
          }

          this.loadingBtn = false;
        },
        error: (error) => {
          this.loadingBtn = false;
          this.$error.errorHandling(error, 'Solicitação em andamento.');
        },
      });
    }
  }
}
