import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'frequencyPlan',
})
export class PaymentFrequencyPlanPipe implements PipeTransform {
  transform(price: any, ...args: string[]): string {
    if (price) {
      const frequency: any = {
        daily: 'Diária',
        weekly: 'Semanal',
        biweekly: 'Quinzenal',
        monthly: 'Mensal',
        annually: 'Anual',
      };

      return frequency[price] || '';
    }
    return '';
  }
}
