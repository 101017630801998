import { map, Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { IbgeCityModel } from '../../models/address/ibge-city.model';
import { IbgeStateModel } from '../../models/address/ibge-state.model';
import { FormGroup } from '@angular/forms';
import { AddressModel } from '../../models/address/address.model';
import { IbgeAddressModel } from '../../models/address/ibge-address.model';
import { LockerModel } from '../../models/locker/locker.model';

@Injectable()
export class AddressService {
  constructor(private http: HttpClient) {}

  public getIpAddress(): Observable<{ ip: string }> {
    return this.http.request<{ ip: string }>('GET', 'http://api.ipify.org/?format=json').pipe(
      map((res) => {
        return res;
      })
    );
  }

  public getCep(cep: string): Observable<any> {
    return this.http.request<any>('GET', `https://viacep.com.br/ws/${cep}/json/`).pipe(
      map((res) => {
        return res;
      })
    );
  }

  public getStates(): Observable<IbgeStateModel[]> {
    return this.http
      .request<IbgeStateModel[]>('GET', 'https://servicodados.ibge.gov.br/api/v1/localidades/estados?orderBy=nome')
      .pipe(
        map((res) => {
          return res;
        })
      );
  }

  public getCities(uf: string): Observable<IbgeCityModel[]> {
    return this.http
      .request<IbgeCityModel[]>(
        'GET',
        `https://servicodados.ibge.gov.br/api/v1/localidades/estados/${uf}/mesorregioes?orderBy=nome`
      )
      .pipe(
        map((res) => {
          return res;
        })
      );
  }

  public getAddressPayload(formAddress: FormGroup): AddressModel {
    const address: AddressModel = {
      line1: formAddress.get('street').value,
      line2: formAddress.get('number').value || '0',
      line3: formAddress.get('complement').value || '',
      postalCode: formAddress.get('postalCode').value?.replace('-', ''),
      neighborhood: formAddress.get('neighborhood').value,
      city: formAddress.get('city').value,
      state: formAddress.get('state').value,
      countryCode: 'BR',
    };

    return address;
  }

  public getAddressPayloadLocker(locker: LockerModel): AddressModel {
    const address: AddressModel = {
      line1: locker.location.street,
      line2: locker.location.number || '0',
      line3: locker.location.complement || '',
      postalCode: locker.location?.zipCode?.replace('-', ''),
      neighborhood: locker.location.neighborhood,
      city: locker.location.city,
      state: locker.location.state,
      countryCode: 'BR',
    };

    return address;
  }

  public getFullAddress(uf: string, municipio: string, logradouro: string): Observable<IbgeAddressModel[]> {
    return this.http
      .request<IbgeAddressModel[]>('GET', `https://viacep.com.br/ws/${uf}/${municipio}/${logradouro}/json/`)
      .pipe(
        map((res) => {
          return res;
        })
      );
  }
}
