<header *nzModalTitle class="modal-header">
  <h3 class="title-category">Adicionar novo webhook</h3>
  <span class="example-spacer"></span>
  <img src="../../assets/images/close.svg" (click)="$modalRef.close()" class="pointer-cursor" />
</header>

<body>
  <main>
    <section>
      <form [formGroup]="formDescription">
        <nz-form-item>
          <label>URL</label>
          <nz-form-control
            nzHasFeedback
            [nzErrorTip]="
              formDescription.controls['target'].hasError('pattern') ? 'Url inválida.' : 'Campo obrigatório.'
            "
          >
            <input nz-input formControlName="target" placeholder="URL" />
          </nz-form-control>
        </nz-form-item>

        <nz-form-item>
          <label>Autorização</label>
          <nz-form-control nzHasFeedback nzErrorTip="Campo obrigatório.">
            <input nz-input formControlName="authorization" placeholder="Autorização" />
          </nz-form-control>
        </nz-form-item>

        <nz-form-item>
          <label>Eventos</label>
          <nz-form-control nzHasFeedback nzErrorTip="Campo obrigatório.">
            <nz-select formControlName="events" nzPlaceHolder="Selecione o evento" nzMode="tags">
              <nz-option *ngFor="let event of eventsList" [nzLabel]="event" [nzValue]="event"></nz-option>
            </nz-select>
          </nz-form-control>
        </nz-form-item>
      </form>
    </section>
  </main>
</body>

<footer class="modal-footer" *nzModalFooter>
  <div class="buttons">
    <app-secondary-button (click)="$modalRef.close()">Cancelar</app-secondary-button>
    <app-primary-button
      (click)="setHandleSubmit(formDescription.value)"
      [disabled]="!this.formDescription.valid"
      class="body-txt-bold"
      >Salvar</app-primary-button
    >
  </div>
</footer>
