<section class="create-password">
  <p class="m-b-2 text-create-password">
    Crie uma senha para deixar sua experiência dentro do aplicativo ainda mais segura!
  </p>
  <form class="m-t-2" [formGroup]="formCreatePassword">
    <nz-form-item class="m-t-1">
      <nz-form-label nzRequired nzSpan="24">Senha</nz-form-label>
      <nz-form-control
        nzSpan="24"
        nzHasFeedback
        [nzErrorTip]="
          formCreatePassword.controls['password'].hasError('pattern') ? errorPasswordPattern : errorPasswordRequired
        "
      >
        <nz-input-group [nzSuffix]="suffixIconPassword" nzSize="large">
          <input
            [type]="rehide ? 'password' : 'text'"
            nz-input
            placeholder="Senha"
            formControlName="password"
            maxlength="30"
            (keyup)="validPassword()"
            (click)="onKeyPassword()"
            required
          />
        </nz-input-group>
        <ng-template #suffixIconPassword>
          <span
            nz-icon
            [nzType]="rehide ? 'eye-invisible' : 'eye'"
            (click)="rehide = !rehide"
            nzTheme="outline"
            class="pointer-cursor"
          ></span>
        </ng-template>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item class="m-t-1">
      <nz-form-label nzRequired nzSpan="24">Confirmar senha</nz-form-label>
      <nz-form-control
        nzSpan="24"
        nzHasFeedback
        [nzErrorTip]="
          formCreatePassword.controls['confirmPassword'].hasError('mismatch')
            ? errorConfirmPasswordValid
            : errorPasswordRequired
        "
      >
        <nz-input-group [nzSuffix]="suffixIconConfirmPassword" nzSize="large">
          <input
            [type]="rehideConfirm ? 'password' : 'text'"
            nz-input
            placeholder="Confirmar senha"
            formControlName="confirmPassword"
            maxlength="30"
            autocomplete="off"
            required
          />
        </nz-input-group>
        <ng-template #suffixIconConfirmPassword>
          <span
            nz-icon
            [nzType]="rehideConfirm ? 'eye-invisible' : 'eye'"
            (click)="rehideConfirm = !rehideConfirm"
            nzTheme="outline"
            class="pointer-cursor"
          ></span>
        </ng-template>
      </nz-form-control>
    </nz-form-item>
    <div *ngIf="showDetails" class="showDetails m-t-1">
      <div nz-row>
        <div nz-col nzSpan="12">
          <p class="text-validation">
            <img
              class="validation-img"
              src="/assets/images/icons/{{ isLowerCase === 'valid' ? 'circle-check.svg' : 'circle-checked-red.svg' }}"
              alt="Ícone para ponto de certo ou errado"
            />
            Letra minúscula
          </p>
          <p class="text-validation">
            <img
              class="validation-img"
              src="/assets/images/icons/{{
                isCapitalLetter === 'valid' ? 'circle-check.svg' : 'circle-checked-red.svg'
              }}"
              alt="Ícone para ponto de certo ou errado"
            />
            Letra maiúsculas
          </p>
          <p class="text-validation">
            <img
              class="validation-img"
              src="/assets/images/icons/{{ isNumber === 'valid' ? 'circle-check.svg' : 'circle-checked-red.svg' }}"
              alt="Ícone para ponto de certo ou errado"
            />
            Números
          </p>
        </div>

        <div nz-col nzSpan="12">
          <p class="text-validation">
            <img
              class="validation-img"
              src="/assets/images/icons/{{
                isLengthNumber === 'valid' ? 'circle-check.svg' : 'circle-checked-red.svg'
              }}"
              alt="Ícone para ponto de certo ou errado"
            />
            8 caracteres
          </p>

          <p class="text-validation">
            <img
              class="validation-img"
              src="/assets/images/icons/{{
                isSpecialCharacter === 'valid' ? 'circle-check.svg' : 'circle-checked-red.svg'
              }}"
              alt="Ícone para ponto de certo ou errado"
            />
            Caracteres especiais ( #, $, & e etc)
          </p>
        </div>
      </div>
    </div>
    <div class="m-t-2 center">
      <button
        *ngIf="!loadingBtn"
        nz-button
        nzType="primary"
        [disabled]="formCreatePassword.invalid"
        nzBlock
        class="center"
        (click)="submit()"
      >
        Criar nova senha
      </button>
      <nz-spin *ngIf="loadingBtn" nzSimple></nz-spin>
    </div>
  </form>
</section>
