<div class="container">
  <div *ngIf="title !== ''" class="title">{{ title }}</div>
  <div class="search-container">
    <form [formGroup]="form">
      <nz-input-group [nzPrefix]="prefixIconSearch">
        <input type="text" class="w-100" nz-input placeholder="Pesquisar" formControlName="searchData" />
      </nz-input-group>
      <ng-template #prefixIconSearch>
        <img style="margin-right: 8px" src="../../../assets/images/icons/search-alt.svg" alt="Ícone de lupa" />
      </ng-template>
    </form>
    <img
      (click)="openFilter()"
      *ngIf="page !== ''"
      src="../../assets/images/filter-table.svg"
      class="m-r-1 pointer-cursor"
      alt="Seta para a direita"
    />
    <img
      (click)="(onClick)"
      *ngIf="page === ''"
      src="../../assets/images/filter-table.svg"
      class="m-r-1 pointer-cursor"
      alt="Seta para a direita"
    />
  </div>
</div>
