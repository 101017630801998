import { Injectable } from '@angular/core';
import { NzModalService } from 'ng-zorro-antd/modal';

import { ModalModel } from '../../models/modal/modal.model';
import { ModalErrorComponent } from './../../components/modal-error/modal-error.component';
import { ModalLoadingComponent } from '../../components/modal-loading/modal-loading.component';
import { ModalSuccessComponent } from '../../components/modal-success/modal-success.component';
import { ModalGenericComponent } from '../../components/modal-generic/modal-generic.component';
import { ModalDeleteCreditCardComponent } from '../../components/modal-delete-credit-card/modal-delete-credit-card.component';

@Injectable()
export class SystemService {
  constructor(public readonly $modal: NzModalService) {}

  public openLoading(): void {
    this.$modal.closeAll();

    this.$modal.create({
      nzContent: ModalLoadingComponent,
      nzFooter: null,
      nzWidth: '85%',
      nzBodyStyle: { height: '80vh', 'border-radius': '50px' },
      nzClosable: false,
    });
  }

  public openDialogSuccess(): void {
    this.$modal.closeAll();

    this.$modal.create({
      nzContent: ModalSuccessComponent,
      nzFooter: null,
      nzWidth: '85%',
      nzBodyStyle: { height: '80vh', 'border-radius': '50px' },
      nzClosable: false,
    });
  }

  public closeAll(): void {
    this.$modal.closeAll();
  }

  public openDialogError(error: string): void {
    this.$modal.closeAll();

    const param: any = { data: error };
    this.$modal.create({
      nzContent: ModalErrorComponent,
      nzFooter: null,
      nzComponentParams: param,
      nzWidth: '85%',
      nzBodyStyle: { height: '80vh', 'border-radius': '50px' },
      nzClosable: false,
    });
  }

  private showGenericModal(modalParams?: ModalModel, width?: string): void {
    this.$modal.closeAll();

    this.$modal.create({
      nzContent: ModalGenericComponent,
      nzFooter: null,
      nzComponentParams: { data: modalParams },
      nzWidth: width || '85%',
      nzClosable: false,
    });
  }

  public openGenericModal(
    image?: string,
    title?: string,
    description?: string,
    buttonText?: string,
    callback?: () => void,
    width?: string
  ): void {
    this.showGenericModal(
      {
        imgPath: image ? image : '../../../../assets/svg/estacionamento_12_1_1.svg',
        title: title ? title : 'Pagamento não aprovado',
        description: description ? description : 'Adicione outro cartão e tente novamente.',
        buttonText: buttonText ? buttonText : 'Adicionar outro cartão',
        callback,
      },
      width
    );
  }

  public openModalDeleteCreditCard(modalParams?: ModalModel, width?: string): void {
    this.$modal.closeAll();

    this.$modal.create({
      nzContent: ModalDeleteCreditCardComponent,
      nzFooter: null,
      nzComponentParams: { data: modalParams },
      nzWidth: width || '85%',
      nzClosable: false,
    });
  }
}
