import { gql } from 'apollo-angular';

const GET_ITEM_VARIANT_QUERY = gql`
  query GetItemVariant($id: UUID4!) {
    getItemVariant(id: $id) {
      id
      insertedAt
      itemVariantOptions {
        id
        active
        name
        orderIndex
      }
      name
      orderIndex
      type
      seller {
        id
      }
    }
  }
`;

const LIST_ITEM_VARIANT_OPTIONS_QUERY = gql`
  query ListItemVariantsOptions($filters: ElixirFilterItemVariantsParams, $pagination: ElixirPaginationParams) {
    listItemVariantOptions(filters: $filters, pagination: $pagination) {
      id
      active
      color
      name
      orderIndex
      itemUnits {
        id
      }
    }
  }
`;

const LIST_ITEM_VARIANT_QUERY = gql`
  query ListItemVariants($filters: ElixirFilterItemVariantsParams, $pagination: ElixirPaginationParams) {
    listItemVariants(filters: $filters, pagination: $pagination) {
      id
      insertedAt
      itemVariantOptions {
        id
        active
        color
        name
        orderIndex
        itemUnits {
          id
        }
      }
      name
      orderIndex
      type
    }
  }
`;
const GET_ITEM_VARIANT_OPTION_QUERY = gql`
  query getItemVariantOption($id: UUID4!) {
    getItemVariantOption(id: $id) {
      id
      active
      name
      orderIndex
    }
  }
`;

export {
  GET_ITEM_VARIANT_QUERY,
  GET_ITEM_VARIANT_OPTION_QUERY,
  LIST_ITEM_VARIANT_QUERY,
  LIST_ITEM_VARIANT_OPTIONS_QUERY,
};
