<div class="ant-modal-title" *nzModalTitle>
  <h3 class="no-margin">Logout</h3>
</div>
<div class="header">
  <p class="no-margin">Tem certeza que deseja sair?</p>
</div>
<div class="footer" *nzModalFooter>
  <app-primary-button class="m-r-1" icon="close" (click)="closed(false)">Cancelar</app-primary-button>
  <app-secondary-button icon="logout" (click)="closed(true)">Sair</app-secondary-button>
</div>
