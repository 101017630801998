<section class="modal_confirmation_cancel_order">
  <div class="header between">
    <h1>Solicitação de cancelamento</h1>
    <img class="close-icon pointer-cursor" (click)="modalRef.close()" src="/assets/img/close.svg" alt="Fechar" />
  </div>

  <div class="warningMessage">
    <p class="d-inline">Tem certeza que deseja solicitar o cancelamento deste pagamento?</p>
  </div>
  <span class="order_number">Pagamento nº {{ payment.id }}</span>

  <form [formGroup]="formGroup">
    <div nz-row>
      <div nz-col nzSpan="24">
        <nz-form-item>
          <nz-form-label class="startBlock" [nzNoColon]="true" [nzSm]="24" nzRequired nzFor="amountCents">
            Valor
          </nz-form-label>
          <nz-form-control [nzSm]="24" [nzXs]="24">
            <nz-input-group nzSize="large">
              <input
                nz-input
                type="text"
                currencyMask
                placeholder="R$ 0,00"
                formControlName="amountCents"
                id="amountCents"
              />
            </nz-input-group>
          </nz-form-control>
        </nz-form-item>
      </div>
    </div>
    <div nz-row>
      <div nz-col nzSpan="24">
        <nz-form-item>
          <nz-form-label class="startBlock" [nzNoColon]="true" [nzSm]="24" nzFor="description">
            Descrição
          </nz-form-label>
          <nz-form-control [nzSm]="24" [nzXs]="24" nzErrorTip="Campo obrigatório.">
            <nz-textarea-count [nzMaxCharacterCount]="200">
              <textarea
                rows="4"
                formControlName="description"
                id="description"
                nz-input
                placeholder="Descreva o motivo do cancelamento"
              ></textarea>
            </nz-textarea-count>
          </nz-form-control>
        </nz-form-item>
      </div>
    </div>
  </form>

  <div class="buttons">
    <app-secondary-button (click)="modalRef.close()">Voltar</app-secondary-button>
    <button nz-button [disabled]="formGroup.invalid" class="btn-error-primary" (click)="confirmCancellation()">
      Sim, cancelar
    </button>
  </div>
</section>
