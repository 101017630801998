import { gql } from 'apollo-angular';

const CREATE_COUPON_MUTATION = gql`
  mutation CreateCoupon(
    $code: String
    $sellerId: UUID4!
    $maxCoupons: Int!
    $percent: Decimal!
    $discountCents: Int!
    $type: CouponTypeEnum!
    $maxCouponsPerCpf: Int!
    $maxDiscountCents: Int!
    $endDate: NaiveDateTime!
    $startDate: NaiveDateTime!
    $maxDiscountCentsPerCpf: Int!
    $maxDiscountCentsPerOrder: Int!
    $minTotalCentsForDiscount: Int!
  ) {
    createCoupon(
      params: {
        code: $code
        type: $type
        percent: $percent
        endDate: $endDate
        sellerId: $sellerId
        startDate: $startDate
        maxCoupons: $maxCoupons
        discountCents: $discountCents
        maxCouponsPerCpf: $maxCouponsPerCpf
        maxDiscountCents: $maxDiscountCents
        maxDiscountCentsPerCpf: $maxDiscountCentsPerCpf
        maxDiscountCentsPerOrder: $maxDiscountCentsPerOrder
        minTotalCentsForDiscount: $minTotalCentsForDiscount
      }
    ) {
      id
    }
  }
`;

const UPDATE_COUPON_MUTATION = gql`
  mutation UpdateCoupon(
    $id: UUID4!
    $code: String
    $sellerId: UUID4!
    $maxCoupons: Int!
    $percent: Decimal!
    $discountCents: Int!
    $type: CouponTypeEnum!
    $maxCouponsPerCpf: Int!
    $maxDiscountCents: Int!
    $endDate: NaiveDateTime!
    $startDate: NaiveDateTime!
    $maxDiscountCentsPerCpf: Int!
    $maxDiscountCentsPerOrder: Int!
    $minTotalCentsForDiscount: Int!
  ) {
    updateCoupon(
      id: $id
      params: {
        code: $code
        type: $type
        percent: $percent
        endDate: $endDate
        sellerId: $sellerId
        startDate: $startDate
        maxCoupons: $maxCoupons
        discountCents: $discountCents
        maxCouponsPerCpf: $maxCouponsPerCpf
        maxDiscountCents: $maxDiscountCents
        maxDiscountCentsPerCpf: $maxDiscountCentsPerCpf
        maxDiscountCentsPerOrder: $maxDiscountCentsPerOrder
        minTotalCentsForDiscount: $minTotalCentsForDiscount
      }
    ) {
      id
    }
  }
`;

const DELETE_COUPON_MUTATION = gql`
  mutation DeleteCoupon($id: UUID4!) {
    deleteCoupon(id: $id) {
      updatedAt
    }
  }
`;

export { CREATE_COUPON_MUTATION, UPDATE_COUPON_MUTATION, DELETE_COUPON_MUTATION };
