<main id="choose-seller">
  <section class="m-b-2">
    <nz-row>
      <nz-col nzSpan="24">
        <h3 class="no-margin-bottom">Qual conta gostaria de acessar?</h3>
      </nz-col>
    </nz-row>
  </section>

  <section>
    <nz-row>
      <nz-col nzSpan="24">
        <form [formGroup]="formGroup">
          <nz-radio-group formControlName="choice" (ngModelChange)="close()">
            <label *ngFor="let seller of sellers" nz-radio [nzValue]="seller" class="seller-label">
              <span class="seller-name">{{
                seller?.stores?.displayName ||
                  seller?.company?.businessName ||
                  seller?.occupation ||
                  seller?.person?.name
              }}</span>
            </label>
          </nz-radio-group>
        </form>
      </nz-col>
    </nz-row>
  </section>
</main>
