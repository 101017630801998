import { Component, OnInit, ViewChildren } from '@angular/core';
import { interval, Observable, Subject, takeUntil } from 'rxjs';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { CreateAccountModel } from 'libs/shared/src/lib/models/auth/create-account.model';
import { StateManagementService } from 'libs/shared/src/lib/state-management/state-management.service';
import { AuthService } from 'libs/shared/src/lib/services/auth/auth-business/auth.service';
import { ErrorService } from '@gen/shared';
import { Message } from 'libs/shared/src/lib/utils/message';
import { LoginModel } from 'libs/shared/src/lib/models/login/login.model';

@Component({
  selector: 'app-customer-signin-code-verify',
  templateUrl: './signin-code-verify.component.html',
  styleUrls: ['./signin-code-verify.component.scss'],
})
export class SigninCodeVerifyComponent implements OnInit {
  @ViewChildren('input') inputsList!: any;

  public formGroupCodeVerify: FormGroup;
  public login: LoginModel;
  public createAccountPaylod: CreateAccountModel;
  public loadingCodeBtn: boolean = false;
  public disabledResend: boolean = true;
  public countdown: number = 60;
  private ispause = new Subject();
  private timer?: Observable<number>;

  constructor(
    private fb: FormBuilder,
    private $auth: AuthService,
    private $error: ErrorService,
    private $notification: StateManagementService
  ) {}

  ngOnInit(): void {
    this.createForm();
    this.getSignin();

    this.timer = interval(1000).pipe(takeUntil(this.ispause));
    this.timer.subscribe({
      next: (_: number) => {
        if (this.countdown === 0) {
          this.ispause.next;
        }

        this.countdown--;
      },
    });
  }

  private createForm(): void {
    this.formGroupCodeVerify = this.fb.group({
      code1: new FormControl('', [Validators.required]),
      code2: new FormControl('', [Validators.required]),
      code3: new FormControl('', [Validators.required]),
      code4: new FormControl('', [Validators.required]),
      code5: new FormControl('', [Validators.required]),
      code6: new FormControl('', [Validators.required]),
    });
  }

  public getSignin(): void {
    this.$notification.login.subscribe((res) => {
      this.login = res;

      if (!this.login) {
        this.$notification.setLoginActualPage('signin-validation-select');
      }
    });
  }

  public resendVerificationCode(): void {
    this.loadingCodeBtn = true;

    this.$auth
      .sendVerificationCode(
        { phoneNumber: this.login.phoneNumber, email: this.login.email },
        this.login.validationMethod,
        'B2B'
      )
      .subscribe({
        next: (res) => {
          if (res?.data?.sendVerificationCode) {
            this.countdown = 60;
          }

          this.loadingCodeBtn = false;
        },
        error: (error) => {
          this.$error.errorHandling(error, Message.LOGIN_ERROR);
          this.loadingCodeBtn = false;
          throw new Error(error);
        },
      });
  }

  public validatePhoneNumberCode(): void {
    this.loadingCodeBtn = true;

    const code =
      this.formGroupCodeVerify.get('code1').value +
      this.formGroupCodeVerify.get('code2').value +
      this.formGroupCodeVerify.get('code3').value +
      this.formGroupCodeVerify.get('code4').value +
      this.formGroupCodeVerify.get('code5').value +
      this.formGroupCodeVerify.get('code6').value;

    this.createAccountPaylod = new CreateAccountModel();
    this.createAccountPaylod.code = code;
    this.createAccountPaylod.email = this.login.email;
    this.createAccountPaylod.phoneNumber = this.login.phoneNumber;
    this.createAccountPaylod.type = this.login.validationMethod === 'EMAIL' ? 'SIGN_IN_EMAIL' : 'SIGN_IN_PHONE_NUMBER';

    this.$auth.validateVerificationCode(this.createAccountPaylod, 'B2B').subscribe({
      next: (res) => {
        if (res?.data?.validateVerificationCode?.validated) {
          const token = res.data.validateVerificationCode.authToken;
          sessionStorage.setItem('accessToken', token);
          this.$auth.setAuth(token);
          this.$notification.setLoginActualPage('signin-create-password');
        }

        this.loadingCodeBtn = false;
      },
      error: (error) => {
        this.loadingCodeBtn = false;
        this.$error.errorHandling(error, Message.LOGIN_ERROR);
        throw new Error(error);
      },
    });
  }

  public onInput(event: any, index: number): void {
    if (event.data) {
      this.inputsList._results[index].nativeElement.focus();
    }
  }

  public onPressDeleteKey(event: KeyboardEvent, index: number): void {
    if (event.key === 'Backspace') {
      const input = this.inputsList._results[index];
      if (input) {
        if (index === 5) {
          input.nativeElement.value = null;
          input.nativeElement.focus();
        }
        const previousInput = this.inputsList._results[index - 1];
        if (previousInput) {
          previousInput.nativeElement.value = null;
          previousInput.nativeElement.focus();
        }
      }
    }
  }

  public pasteNumeric(event: ClipboardEvent): void {
    if (event.clipboardData) {
      const code = event.clipboardData.getData('text').replace(/[^0-9]/g, '');

      this.formGroupCodeVerify.setValue({
        code1: code[0] || '',
        code2: code[1] || '',
        code3: code[2] || '',
        code4: code[3] || '',
        code5: code[4] || '',
        code6: code[5] || '',
      });

      if (code.length === 6) {
        setTimeout(() => {
          this.validatePhoneNumberCode();
          event.preventDefault();
        }, 500);
      }
    }
  }
}
