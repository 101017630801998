import { gql } from 'apollo-angular';

const PROCESS_CHARGEBACK_FILE_MUTATION = gql`
  mutation ProcessChargebackFile($binaryFile: String!) {
    processChargebackFile(binaryFile: $binaryFile) {
      id
    }
  }
`;

const CANCEL_PAYMENT_MUTATION = gql`
  mutation cancelPayment($id: UUID4!) {
    cancelPayment(id: $id)
  }
`;

const CREATE_CUSTOMER_PAYMENT_MUTATION = gql`
  mutation createCustomerPayment($params: CreatePaymentParams!) {
    createCustomerPayment(params: $params) {
      id
      method
      confirmedAt
      totalCents
      status
      insertedAt
      updatedAt
      publicSeller {
        id
      }
      credit {
        installments
        publicCreditCard {
          brand
          last4
          expirationMonth
          expirationYear
        }
      }
      pix {
        brcode
        expiration
      }
      bankSlip {
        bank
        barcode
        expirationDate
        printLinkPdf
        typefulLine
      }
    }
  }
`;

const CREATE_CHECKOUT_PAYMENTS_MUTATION = gql`
  mutation createCheckoutPayments($params: [CreatePaymentParams!]) {
    createCheckoutPayments(params: $params)
  }
`;

export {
  PROCESS_CHARGEBACK_FILE_MUTATION,
  CANCEL_PAYMENT_MUTATION,
  CREATE_CHECKOUT_PAYMENTS_MUTATION,
  CREATE_CUSTOMER_PAYMENT_MUTATION,
};
