<section class="secondary">
  <div class="w-90 d-flex between">
    <button [disabled]="quantity === 1" nz-button nzType="primary" nzSize="large" (click)="removeQuantity()">
      <span class="primary-color" nz-icon nzType="minus" nzTheme="outline"> </span>
    </button>
    <span class="quantity"> {{ quantity }} </span>
    <button nz-button nzType="primary" nzSize="large" (click)="addQuantity()">
      <span class="primary-color" nz-icon nzType="plus" nzTheme="outline"></span>
    </button>
  </div>
</section>
