<section class="no_value_qr_code_modal">
  <app-qr-code-generate
    *ngIf="modalActive === 'qr-code-generate'"
    [key]="key"
    [type]="type"
    (generateButton)="handleModalActive($event)"
  ></app-qr-code-generate>
  <app-qr-code-confirmation
    *ngIf="modalActive === 'qr-code-confirmation'"
    [key]="key"
    [type]="type"
    [qrCode]="qrCode"
    (confirmButton)="handleModalActive($event)"
  ></app-qr-code-confirmation>
  <app-qr-code-success
    *ngIf="modalActive === 'qr-code-success'"
    [key]="key"
    [type]="type"
    [qrCode]="qrCode"
  ></app-qr-code-success>
</section>
