import { mergeMap } from 'rxjs';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { NzDrawerService } from 'ng-zorro-antd/drawer';
import { SellerModel } from './../../models/sellers/sellers.model';
import { FormGroup, FormBuilder, FormControl } from '@angular/forms';
import {
  AfterContentChecked,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
} from '@angular/core';

import { CartModel } from '../../models/cart/cart.model';
import { StoreModel } from '../../models/store/store.model';
import { CustomerModel } from '../../models/customer/customer.model';
import { PointModel } from '../../models/points/point-of-sales.model';
import { CategoryModel } from '../../models/categories/categories.model';
import { AuthStoreService } from '../../services/auth/auth-store.service';
import { CategoryService } from '../../services/item/category/category.service';
import { StateManagementService } from '../../state-management/state-management.service';
import { FilterCategoriesComponent } from './filter-categories/filter-categories.component';
import { DrawerSideMenuComponent } from '../drawers/drawer-side-menu/drawer-side-menu.component';

@Component({
  selector: 'app-sub-header',
  templateUrl: './sub-header.component.html',
  styleUrls: ['./sub-header.component.scss'],
})
export class SubHeaderComponent implements OnInit, AfterContentChecked {
  @Input() public seller: SellerModel = new SellerModel();
  @Input() public store: StoreModel = new StoreModel();
  @Input() public point: PointModel;
  @Input() public customer: CustomerModel;

  @Output() public filterOpened = new EventEmitter<boolean>();

  public cart: CartModel = new CartModel();

  public productsCategoryList: Array<CategoryModel> = [];
  public productsCategoryListHeaderLimited: Array<CategoryModel> = [];
  public productsCategoryListChild: Array<CategoryModel> = [];

  public form: FormGroup;

  public clickedFavorite: boolean = false;
  public showSubHeader: boolean = true;
  public isShowInputSearch: boolean = false;
  public passedInput: boolean = false;
  public passedHeader: boolean = false;

  public activeIndex: number;

  public colors: Array<{ label: string; value: string }> = [];
  public sizes: Array<{ label: string; value: string }> = [];
  public amountCents: Array<{ label: string; value: any }> = [];
  public imagesUrl = [
    '../../assets/images/shopping-cart.svg',
    '../../assets/images/clicked-heart.svg',
    '../../assets/images/unclicked-heart.svg',
  ];

  public isAuth: boolean = false;

  constructor(
    private $drawer: NzDrawerService,
    private $notification: StateManagementService,
    private $categories: CategoryService,
    private $auth: AuthStoreService,
    private $location: Location,
    private fb: FormBuilder,
    private router: Router,
    private cdRef: ChangeDetectorRef,
    private route: Router
  ) {}

  public ngOnInit(): void {
    this.getCategories();
    this.createForm();
    this.getValuesChange();
    this.getIsCategory();
    this.getCart();
  }

  public ngAfterContentChecked(): void {
    this.cdRef.detectChanges();
  }

  public getCart(): void {
    this.$notification.carts.subscribe((cart) => {
      if (cart) {
        this.cart = cart;
      }
    });

    this.$notification.points.subscribe((point) => {
      if (point) {
        this.point = point;
        if (this.$auth.isAuth(point, this.customer)) {
          this.isAuth = true;
        }
      }
    });

    this.$notification.openModals.subscribe((open) => {
      if (open) {
        this.$auth.openAuthModal(this.point);
      }
    });

    this.$notification.customers.subscribe((customer) => {
      if (customer && customer.id) {
        this.customer = customer;
        this.isAuth = true;
      } else {
        this.isAuth = false;
      }
    });
  }

  public getCategories(): void {
    if (this.seller?.id) {
      const verifyRoute = this.route.url;

      if (!verifyRoute.includes('/loggedCart')) {
        this.$notification.categorySellers
          .pipe(
            mergeMap((categories) => {
              if (categories) {
                this.productsCategoryList = categories;
                return this.$notification.categories;
              } else {
                this.$categories.getAndSetCategoriesSeller(this.seller.id);
                return this.$notification.categories;
              }
            })
          )
          .subscribe((categories) => {
            if (categories) {
              this.getCategoryList(categories);
            } else {
              this.$categories.getAndSetCategoriesAll();
            }
          });
      }
    }
  }

  public openCategoriesFilter(selectedProductCategory?: CategoryModel): void {
    const width = window.innerWidth;
    let nzHeight = '100%';

    if (width > 768) {
      nzHeight = '60%';
    }

    let maxWidth = '100vm';
    let nzWidth = '100%';

    this.$drawer.create({
      nzClosable: false,
      nzWidth,
      nzHeight,
      nzBodyStyle: {
        'max-width': maxWidth,
        'overflow-x': 'hidden',
        height: nzHeight,
        padding: '0',
      },
      nzPlacement: 'left',
      nzContentParams: {
        categories: this.productsCategoryList,
        sellerId: this.seller.id,
        selectedProductCategory: selectedProductCategory,
      },
      nzContent: FilterCategoriesComponent,
    });
  }

  public openSideMenu(): void {
    let nzHeight = '100%';
    let maxWidth = '85vm';
    let nzWidth = '85%';

    this.$drawer.create({
      nzClosable: false,
      nzWidth,
      nzHeight,
      nzBodyStyle: {
        'max-width': maxWidth,
        'overflow-x': 'hidden',
        height: nzHeight,
        padding: '0',
      },
      nzPlacement: 'left',
      nzContentParams: {
        categories: this.productsCategoryList,
        point: this.point,
        isAuth: this.isAuth,
        customer: this.customer,
        sellerId: this.seller.id,
      },
      nzContent: DrawerSideMenuComponent,
    });
  }

  private createForm(): void {
    this.form = this.fb.group({
      search: new FormControl(''),
      category: new FormControl(''),
      amountCents: new FormControl(''),
      color: new FormControl(''),
      size: new FormControl(''),
    });

    if (this.$auth.isAuth(this.point, this.customer)) {
      this.isAuth = true;
    }
  }

  public getHeaderConfig(): {
    headerTypePrimary: boolean;
    showFilterMobile: boolean;
    headerTitle: string;
    hideIcons: boolean;
    hideArrowBackIcon: boolean;
    hideShoppingCart: boolean;
  } {
    let headerTypePrimary = true;
    let showFilterMobile = true;
    let hideShoppingCart = false;
    let hideIcons = false;
    let hideArrowBackIcon = true;
    let headerTitle = '';

    const verifyRoute = this.route.url;

    if (verifyRoute.includes('/loggedCart')) {
      headerTypePrimary = true;
      hideShoppingCart = true;
      showFilterMobile = false;
      hideArrowBackIcon = true;
      hideIcons = true;
      headerTitle = 'Meu carrinho';
    }

    if (verifyRoute.includes('/details')) {
      headerTypePrimary = true;
      showFilterMobile = false;
      hideShoppingCart = false;
      hideIcons = false;
      headerTitle = this.store.displayName;
    }

    if (verifyRoute.includes('/list')) {
      showFilterMobile = true;
      headerTypePrimary = true;
      hideShoppingCart = false;
      hideArrowBackIcon = false;
      headerTitle = this.store.displayName;
    }

    if (verifyRoute.includes('/select-order-type')) {
      showFilterMobile = false;
      headerTypePrimary = true;
      hideIcons = true;
      headerTitle = 'Entrega';
    }

    if (verifyRoute.includes('/orders')) {
      showFilterMobile = false;
      headerTypePrimary = true;
      hideIcons = true;
      headerTitle = 'Pedidos';
    }

    if (verifyRoute.includes('/order-details')) {
      showFilterMobile = false;
      headerTypePrimary = true;
      hideIcons = true;
      headerTitle = 'Pedido';
    }

    if (verifyRoute.includes('/address')) {
      showFilterMobile = false;
      headerTypePrimary = true;
      hideIcons = true;
      hideShoppingCart = true;
      headerTitle = 'Endereços';
    }

    return { headerTypePrimary, showFilterMobile, headerTitle, hideIcons, hideShoppingCart, hideArrowBackIcon };
  }

  public getValuesChange(): void {
    this.form.get('search').valueChanges.subscribe((res) => {
      const currentUrl = this.router.url;
      const desiredUrl = `/internal/${this.seller?.id}/${this.point.id}/products/list`;
      this.$notification.setSearchValue(res);

      if (currentUrl !== desiredUrl) {
        this.router.navigate([desiredUrl]);
      }

      this.$notification.setSearchValue(res);
    });
  }

  public clearList(): void {
    this.$notification.setSearchValue(null);
    this.$notification.setChangedCategory(null);
    this.$notification.setIsCategory(false);
  }

  public setFilterForm(field: string, value: any) {
    this.form.get(field).setValue(value);
  }

  public getCategoryList(categories): void {
    let options = this.$categories.setCategoriesCascader(categories);

    this.productsCategoryList = options;

    this.productsCategoryListHeaderLimited = options.filter(
      (res) =>
        res.value === '0fbb4ab5-6037-4eb6-b317-a25617b2a22d' ||
        res.value === 'a86e0bd0-c708-49f6-b5a7-56a12a11ea68' ||
        res.value === '34e8fcb4-f6c5-4015-90c7-08e456d56645' ||
        res.value === 'd8f15e44-5f7c-4f20-94ca-72243366beee'
    );

    this.productsCategoryListHeaderLimited.push({
      label: 'Categorias',
    });
    this.productsCategoryListHeaderLimited.reverse();
  }

  public goBack(): void {
    this.$location.back();
  }

  public goToCart(): void {
    this.$notification.setIsCategory(false);
    this.router.navigate(['/loggedCart/' + this.point.id + '/cart']);
  }

  public goToAuthArea(): void {
    this.$notification.setIsCategory(false);
    this.router.navigate(['/internal/' + this.seller?.id + '/' + this.point.id + '/auth-area']);
  }

  public getOrderByValue(orderBy): void {
    this.$notification.setOrderBy(orderBy);
  }

  public onSelectCategory(category: any, type: string, index?: number): void {
    this.activeIndex = index;

    if (category) {
      if (type === 'selected') {
        this.router.navigate(['/internal/' + this.seller?.id + '/' + this.point.id + '/products/list']);
        this.$notification.setChangedCategory(category);
        this.$notification.setIsCategory(true);
      } else {
        this.productsCategoryListChild = category;
      }
    }
  }

  public showInputSearch(): void {
    this.isShowInputSearch = true;
  }

  public getIsCategory(): void {
    this.$notification.isShowCategories.subscribe((res) => {
      if (res === false) {
        this.activeIndex = -1;
      }
    });
  }

  public showAuthModal(): void {
    this.$auth.openAuthModal(this.point);
  }

  @HostListener('window:scroll', ['$event'])
  public onScroll(): void {
    let banner = document.getElementById('input');
    let header = document.getElementById('header');

    this.passedInput = false;
    this.passedHeader = false;

    if (window?.scrollY > banner?.offsetTop + banner?.offsetHeight) {
      this.passedInput = true;
      this.passedHeader = false;
    }

    if (window?.scrollY > header?.offsetTop + header?.offsetHeight) {
      this.passedInput = false;
      this.passedHeader = true;
    }
  }
}
