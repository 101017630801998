import { gql } from 'apollo-angular';

const UPLOAD_SELLER_DOCUMENT_MUTATION = gql`
  mutation UploadSellerDocument($params: UploadSellerDocumentParamsInput!) {
    uploadSellerDocument(params: $params) {
      id
    }
  }
`;

const DELETE_SELLER_DOCUMENT_MUTATION = gql`
  mutation deleteDocument($id: String!) {
    deleteDocument(id: $id) {
      id
    }
  }
`;

const BAN_SELLER_MUTATION = gql`
  mutation BanSeller($id: String!) {
    banSeller(id: $id) {
      id
    }
  }
`;

const BLOCK_SELLER_MUTATION = gql`
  mutation BlockSeller($id: String!) {
    blockSeller(id: $id) {
      id
    }
  }
`;

const UNBLOCK_SELLER_MUTATION = gql`
  mutation UnblockSeller($id: String!) {
    unblockSeller(id: $id) {
      id
    }
  }
`;

const REPROCESS_SELLER_MUTATION = gql`
  mutation ReprocessSeller($id: String!) {
    reprocessSeller(id: $id) {
      id
    }
  }
`;

const APPROVE_SELLER_MUTATION = gql`
  mutation approveSeller($id: String!) {
    approveSeller(id: $id) {
      id
    }
  }
`;

const DECLINE_SELLER_MUTATION = gql`
  mutation DeclineSeller($id: String!) {
    declineSeller(id: $id) {
      id
    }
  }
`;

const EDIT_SELLER_MUTATION = gql`
  mutation UpdateSeller($params: UpdateSellerParamsInput!) {
    updateSeller(params: $params) {
      id
    }
  }
`;

const UPDATE_STORE_PREFERENCES_MUTATION = gql`
  mutation upsertStorePreferences($params: UpsertStorePreferencesParamsInput!, $sellerId: String!) {
    upsertStorePreferences(params: $params, sellerId: $sellerId) {
      id
    }
  }
`;

const ACCEPT_SAAS_PLATFORM_TERM_MUTATION = gql`
  mutation AcceptSaasPlatformTerm($sellerId: String!, $platformTermId: String!) {
    acceptSaasPlatformTerm(sellerId: $sellerId, platformTermId: $platformTermId) {
      id
    }
  }
`;

const CREATE_PLATFORM_TERM_MUTATION = gql`
  mutation CreatePlatformTerm($params: PlatformTermParamsInput!) {
    createPlatformTerm(params: $params) {
      id
    }
  }
`;

const FILL_SELLER_ONBOARDING_MUTATION = gql`
  mutation FillSellerOnboarding($params: FillOnboardingParamsInput!) {
    fillSellerOnboarding(params: $params) {
      id
    }
  }
`;

const UPDATE_ONBOARDING_STEP_MUTATION = gql`
  mutation UpdateOnboardingStep($userId: String!, $step: StepType!) {
    updateOnboardingStep(userId: $userId, step: $step) {
      id
    }
  }
`;

const CREATE_SELLER_SYNAPSES_MUTATION = gql`
  mutation CreateSellerSynapses($params: CreateSellerSynapsesOnboardingParamsInput!) {
    createSellerSynapses(params: $params) {
      id
    }
  }
`;

export {
  BAN_SELLER_MUTATION,
  EDIT_SELLER_MUTATION,
  BLOCK_SELLER_MUTATION,
  APPROVE_SELLER_MUTATION,
  DECLINE_SELLER_MUTATION,
  UNBLOCK_SELLER_MUTATION,
  REPROCESS_SELLER_MUTATION,
  CREATE_PLATFORM_TERM_MUTATION,
  UPLOAD_SELLER_DOCUMENT_MUTATION,
  UPDATE_STORE_PREFERENCES_MUTATION,
  ACCEPT_SAAS_PLATFORM_TERM_MUTATION,
  FILL_SELLER_ONBOARDING_MUTATION,
  UPDATE_ONBOARDING_STEP_MUTATION,
  DELETE_SELLER_DOCUMENT_MUTATION,
  CREATE_SELLER_SYNAPSES_MUTATION,
};
