<section class="account-created centerBlock">
  <div class="data">
    <img src="/assets/images/ilustrations/circle-success.svg" alt="Ícone de para ilustrar " />
    <div>
      <h3>Conta criada com sucesso!</h3>
    </div>
    <div>
      <span> Ative as formas de pagamento para realizar transações e começar a receber pelas suas vendas. </span>
    </div>

    <footer>
      <div class="buttons">
        <div class="primary">
          <app-primary-button (click)="closeModal('NOW')" [block]="true">Ativar agora</app-primary-button>
        </div>
        <div class="secondary">
          <p (click)="closeModal('LATER')">Pular</p>
        </div>
      </div>
    </footer>
  </div>
</section>
