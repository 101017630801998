<div>
  <p [innerHTML]="message"></p>
  <ng-container *ngIf="isImportant">
    <strong>Importante!</strong>
    <span>Esta ação não pode ser desfeita.</span>
  </ng-container>

  <ng-container *ngIf="requestReason">
    <form [formGroup]="formReason">
      <div>
        <nz-form-item class="m-t-1">
          <nz-form-label nzRequired nzFor="name">Razão</nz-form-label>
          <nz-form-control [nzSm]="24" [nzXs]="24" nzHasFeedback nzErrorTip="Campo obrigatório.">
            <input nz-input formControlName="reason" placeholder="Digita a rasão" />
          </nz-form-control>
        </nz-form-item>
      </div>
    </form>
  </ng-container>
</div>
<div *nzModalFooter class="footer">
  <app-secondary-button class="m-r-1" icon="left-circle" (click)="$modalRef.close(false)">Voltar</app-secondary-button>
  <app-primary-button
    [icon]="icon"
    [disabled]="requestReason ? formReason.invalid : false"
    (click)="$modalRef.close(requestReason ? formReason.value.reason : true)"
    >{{ buttonName }}</app-primary-button
  >
</div>
