<ng-container *ngIf="showTitle">
  <header *nzModalTitle>
    <div class="ant-modal-title">
      <h3 class="title-category">Filtrar {{ filterTitle }}</h3>
      <span class="example-spacer"></span>
      <img src="/assets/images/close.svg" (click)="modalRef.close()" class="pointer-cursor" />
    </div>
  </header>
</ng-container>

<section class="modal-filter">
  <form [formGroup]="formGroup">
    <nz-form-item *ngIf="filters.isFilterDocument">
      <nz-form-label class="start" [nzNoColon]="true" [nzSm]="24" nzFor="document">CPF / CNPJ</nz-form-label>
      <nz-form-control class="w-100" [nzSm]="24" [nzXs]="24" nzHasFeedback nzErrorTip="Documento inválido.">
        <nz-input-group [nzSm]="24" [nzXs]="24">
          <input
            nz-input
            placeholder="Digite o CPF ou CNPJ"
            type="text"
            formControlName="document"
            mask="000.000.000-00||00.000.000/0000-00"
            id="document"
            autocomplete="off"
          />
        </nz-input-group>
      </nz-form-control>
    </nz-form-item>

    <nz-form-item *ngIf="filters.isFilterName">
      <nz-form-label class="start" [nzNoColon]="true" [nzSm]="24" nzFor="name">Nome</nz-form-label>
      <nz-form-control class="w-100" [nzSm]="24" [nzXs]="24">
        <nz-input-group [nzSm]="24" [nzXs]="24">
          <input
            nz-input
            placeholder="Digite o nome"
            type="text"
            formControlName="name"
            id="name"
            autocomplete="off"
            (keydown)="onlyNumbers($event)"
          />
        </nz-input-group>
      </nz-form-control>
    </nz-form-item>

    <nz-form-item *ngIf="filters.isFilterEmail">
      <nz-form-label class="start" [nzNoColon]="true" [nzSm]="24" nzFor="email">E-mail</nz-form-label>
      <nz-form-control class="w-100" [nzSm]="24" [nzXs]="24" nzHasFeedback nzErrorTip="Email inválido.">
        <nz-input-group [nzSm]="24" [nzXs]="24">
          <input
            nz-input
            placeholder="Digite o e-mail"
            type="email"
            formControlName="email"
            id="email"
            autocomplete="off"
          />
        </nz-input-group>
      </nz-form-control>
    </nz-form-item>

    <nz-form-item *ngIf="filters.isFilterCreationDate">
      <nz-form-label class="start" [nzNoColon]="true" [nzSm]="24" nzFor="creationDate">Data de criação</nz-form-label>
      <nz-form-control class="w-100" [nzSm]="24" [nzXs]="24" nzHasFeedback nzErrorTip="Período inválido.">
        <nz-range-picker
          [nzFormat]="dateFormat"
          formControlName="creationDate"
          id="creationDate"
          [nzSuffixIcon]="suffixIconCalendarTemplate"
        ></nz-range-picker>
      </nz-form-control>
      <ng-template #suffixIconCalendarTemplate>
        <svg
          class="float-image float-image__calendar"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M3 10H21M7 3V5M17 3V5M6.2 21H17.8C18.9201 21 19.4802 21 19.908 20.782C20.2843 20.5903 20.5903 20.2843 20.782 19.908C21 19.4802 21 18.9201 21 17.8V8.2C21 7.07989 21 6.51984 20.782 6.09202C20.5903 5.71569 20.2843 5.40973 19.908 5.21799C19.4802 5 18.9201 5 17.8 5H6.2C5.0799 5 4.51984 5 4.09202 5.21799C3.71569 5.40973 3.40973 5.71569 3.21799 6.09202C3 6.51984 3 7.07989 3 8.2V17.8C3 18.9201 3 19.4802 3.21799 19.908C3.40973 20.2843 3.71569 20.5903 4.09202 20.782C4.51984 21 5.07989 21 6.2 21Z"
            stroke="#979797"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </ng-template>
    </nz-form-item>
    <nz-form-item *ngIf="filters.isFilterExpirationDate">
      <nz-form-label class="start" [nzNoColon]="true" [nzSm]="24" nzFor="expirationDate"
        >Data de expiração</nz-form-label
      >
      <nz-form-control class="w-100" [nzSm]="24" [nzXs]="24" nzHasFeedback nzErrorTip="Período inválido.">
        <nz-range-picker [nzFormat]="dateFormat" formControlName="expirationDate" id="expirationDate"></nz-range-picker>
      </nz-form-control>
    </nz-form-item>
  </form>

  <form [formGroup]="formRangePrices">
    <nz-form-item *ngIf="filters.isFilterRangeValue">
      <label>Faixa de preço</label>
      <nz-form-control [nzSm]="24" [nzXs]="24">
        <nz-slider
          [nzTipFormatter]="customTipFormatter"
          [nzMin]="0"
          [nzMax]="50000"
          nzRange
          formControlName="rangeValue"
        ></nz-slider>

        <ng-template #titleTemplate let-value>
          <span>{{ value | currency : 'R$' }}</span>
        </ng-template>
      </nz-form-control>
    </nz-form-item>
  </form>

  <form [formGroup]="formFilter">
    <div nz-row [nzGutter]="gutter" *ngIf="filters.isFilterAmount">
      <div nz-col nzSpan="11">
        <nz-form-item>
          <nz-form-label class="start" [nzNoColon]="true" [nzSm]="24" nzFor="fromAmount">Faixa de Preço</nz-form-label>
          <nz-form-control class="w-100" [nzSm]="24" [nzXs]="24" nzHasFeedback nzErrorTip="Faixa de preço inválida.">
            <nz-input-group [nzSm]="24" [nzXs]="24">
              <input
                nz-input
                placeholder="0,00"
                type="text"
                formControlName="fromAmount"
                id="fromAmount"
                currencyMask
                autocomplete="off"
              />
            </nz-input-group>
          </nz-form-control>
        </nz-form-item>
      </div>
      <div nz-col nzSpan="2" class="self-center">
        <div class="d-inline">
          <span>até</span>
        </div>
      </div>
      <div nz-col nzSpan="11" class="special">
        <nz-form-item class="m-t-2">
          <nz-form-control class="w-100" [nzSm]="24" [nzXs]="24" nzHasFeedback nzErrorTip="Faixa de preço inválida.">
            <nz-input-group [nzSm]="24" [nzXs]="24">
              <input
                nz-input
                placeholder="0,00"
                type="text"
                formControlName="toAmount"
                id="toAmount"
                currencyMask
                autocomplete="off"
              />
            </nz-input-group>
          </nz-form-control>
        </nz-form-item>
      </div>
    </div>
    <nz-form-item *ngIf="filters.isFilterSchedule">
      <nz-form-label class="start" [nzNoColon]="true" [nzSm]="24" nzFor="schedule">Periodos</nz-form-label>
      <nz-form-control class="w-100" [nzSm]="24" [nzXs]="24" nzHasFeedback nzErrorTip="Período inválido.">
        <nz-select
          id="schedule"
          nzAllowClear
          nzPlaceHolder="Selecione os períodos"
          formControlName="schedule"
          nzMode="multiple"
        >
          <nz-option nzValue="daily" nzLabel="Diariamente"></nz-option>
          <nz-option nzValue="weekly" nzLabel="Semanalmente"></nz-option>
          <nz-option nzValue="biweekly" nzLabel="Quinzenalmente"></nz-option>
          <nz-option nzValue="monthly" nzLabel="Mensalmente"></nz-option>
          <nz-option nzValue="annually" nzLabel="Anual"></nz-option>
        </nz-select>
        <svg
          class="float-image"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M6 9L12 15L18 9"
            stroke="#333333"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item *ngIf="filters.isFilterPaymentMethod">
      <nz-form-label class="start" [nzNoColon]="true" [nzSm]="24" nzFor="paymentMethod"
        >Formas de pagamento</nz-form-label
      >
      <nz-form-control class="w-100" [nzSm]="24" [nzXs]="24" nzHasFeedback nzErrorTip="Forma de pagamento inválido.">
        <nz-select
          id="paymentMethod"
          nzAllowClear
          nzPlaceHolder="Selecione as formas de pagamento"
          formControlName="paymentMethod"
          nzMode="multiple"
        >
          <nz-option nzValue="PIX" nzLabel="PIX"></nz-option>
          <nz-option nzValue="CREDIT_CARD" nzLabel="Cartão de crédito"></nz-option>
          <nz-option nzValue="BANK_SLIP" nzLabel="Boleto"></nz-option>
          <nz-option nzValue="MONEY_EXTERNAL" nzLabel="Dinheiro"></nz-option>
          <nz-option nzValue="DEBIT_EXTERNAL" nzLabel="Outros"></nz-option>
        </nz-select>
        <svg
          class="float-image"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M6 9L12 15L18 9"
            stroke="#333333"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item *ngIf="filters.isFilterStatus">
      <nz-form-label class="start" [nzNoColon]="true" [nzSm]="24" nzFor="status">Status</nz-form-label>
      <nz-form-control class="w-100" [nzSm]="24" [nzXs]="24" nzHasFeedback nzErrorTip="Status inválido.">
        <nz-select
          id="status"
          nzAllowClear
          nzPlaceHolder="Selecione os status"
          formControlName="status"
          nzMode="multiple"
        >
          <nz-option
            *ngFor="let status of statusList | keyvalue"
            [nzValue]="status.key"
            [nzLabel]="status.value.toString()"
          ></nz-option>
        </nz-select>
        <svg
          class="float-image"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M6 9L12 15L18 9"
            stroke="#333333"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item *ngIf="filters.isFilterCategory">
      <nz-form-label class="start" [nzNoColon]="true" [nzSm]="24" nzFor="category">Categoria</nz-form-label>
      <nz-form-control class="w-100" [nzSm]="24" [nzXs]="24" nzHasFeedback nzErrorTip="Categoria inválida.">
        <nz-select
          id="category"
          nzAllowClear
          nzPlaceHolder="Selecione as categorias"
          formControlName="category"
          nzMode="multiple"
        >
          <nz-option *ngFor="let category of categories" [nzValue]="category.id" [nzLabel]="category.name"></nz-option>
        </nz-select>
      </nz-form-control>
    </nz-form-item>
  </form>
</section>

<footer *nzModalFooter>
  <div class="buttons between w-100">
    <app-secondary-button (click)="clearFields()"> Limpar filtros </app-secondary-button>
    <app-primary-button (click)="setHandleSubmit()"> Aplicar filtros </app-primary-button>
  </div>
</footer>
