import { Component, Input, OnInit } from '@angular/core';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzModalRef } from 'ng-zorro-antd/modal';
import { take } from 'rxjs';
import { ApplicationsService } from '../../../services/applications/applications.service';
import { StateManagementService } from '../../../state-management/state-management.service';
import { AlertService } from '../../../services/alert/alert.service';
import { ErrorService } from '../../../services/error/error.service';

@Component({
  selector: 'app-add-edit-app',
  templateUrl: './add-edit-app.component.html',
  styleUrls: ['./add-edit-app.component.scss'],
})
export class AddEditAppComponent implements OnInit {
  @Input() data: any;

  public formDescription: any;
  public app: any = null;
  public selectedImage: any;

  public loading: boolean = false;
  public isEdit: boolean = false;

  public file?: File;

  public errorImg: string = '/assets/images/logo.svg';
  public sellerId: string = '';
  public title: string = 'Criar novo aplicativo';

  constructor(
    private fb: FormBuilder,
    public $modalRef: NzModalRef,
    public $alert: AlertService,
    public $error: ErrorService,
    private $appService: ApplicationsService,
    private $notification: StateManagementService,
    private storage: AngularFireStorage
  ) {}

  ngOnInit(): void {
    this.createForm();
    this.getUser();
    this.getFormValues();
  }

  public createForm(): void {
    this.formDescription = this.fb.group({
      name: new FormControl(),
      redirectUrl: new FormControl(),
      logoUrl: ['/assets/images/logo.svg', Validators.required],
    });
  }

  public getUser(): void {
    this.$notification.users.subscribe((res) => {
      if (res?.sellerId) {
        this.sellerId = res.sellerId;
      }
    });
  }

  public getFormValues(): void {
    if (this.data) {
      this.title = 'Editar aplicativo';
      this.isEdit = true;
      this.app = this.data;
      this.formDescription.setValue({
        name: this.app.name,
        logoUrl: this.app.logoUrl,
        redirectUrl: this.app.redirectUri[0],
      });
    }

    this.selectedImage = this.formDescription.value.logoUrl;
  }

  public async saveApp(): Promise<void> {
    this.loading = true;
    if (this.app) {
      // if (this.file) {
      //   await this.saveImage();
      // }

      const data: any = {
        name: this.formDescription?.get('name')!.value,
        logoUrl: this.formDescription.get('logoUrl')!.value,
        sellerId: this.sellerId,
        redirectUrl: [this.formDescription.get('redirectUrl')!.value],
      };

      this.$appService.updateApplication(this.app.id, data).subscribe({
        next: (response) => {
          if (response.data.updateApplication) {
            this.$alert.setAlertInfo('SUCCESS', 'Aplicativo atualizado com sucesso!');
            this.$modalRef.close();
          }

          this.loading = false;
        },
        error: (error) => {
          this.loading = false;
          this.$error.errorHandling(error, 'Erro ao atualizar o aplicativo');
          throw new Error(error);
        },
      });
    } else {
      // if (this.file) {
      //   await this.saveImage();
      // }

      const data: any = {
        name: this.formDescription?.get('name')!.value,
        logoUrl: this.formDescription.get('logoUrl')!.value,
        sellerId: this.sellerId,
        redirectUrl: [this.formDescription.get('redirectUrl')!.value],
      };

      this.$appService.createApplication(data).subscribe({
        next: (response) => {
          if (response.data.createApplication) {
            this.$alert.setAlertInfo('SUCCESS', 'Aplicativo criado com sucesso!');
            this.$modalRef.close();
          }

          this.loading = false;
        },
        error: (error) => {
          this.loading = false;
          this.$error.errorHandling(error, 'Erro ao atualizar o aplicativo');
          throw new Error(error);
        },
      });
    }
  }

  public async saveImage(): Promise<void> {
    const sellerId = this.sellerId;
    const filePath = `apps/${sellerId}/${this.file?.name}`;
    const fileRef = this.storage.ref(filePath);
    const task = this.storage.upload(filePath, this.file);

    await task.then(async () => {
      const url = await fileRef.getDownloadURL().pipe(take(1)).toPromise();
      this.formDescription.patchValue({ logoUrl: url });
    });
  }

  public chooseImage(event: any): void {
    this.file = event.target.files[0];
    const reader = new FileReader();
    reader.onloadend = () => {
      this.selectedImage = reader.result;
    };
    if (this.file) {
      reader.readAsDataURL(this.file);
      this.isEdit = true;
    }
  }

  public onDragOver(event: any): void {
    const el: any = document.getElementById('preview-image');
    if (!el.className.includes(' active')) {
      el.className += ' active';
    }
    event.preventDefault();
    event.stopPropagation();
  }

  public onDragLeave(event: any): void {
    const el: any = document.getElementById('preview-image');
    event.stopPropagation();
    if (el.className.includes(' active')) {
      el.className = el.className.replaceAll(' active', '');
    }
  }

  public onDrop(event: any): void {
    const el: any = document.getElementById('preview-image');
    event.preventDefault();
    if (el.className.includes(' active')) {
      el.className = el.className.replaceAll(' active', '');
    }
    this.file = event.dataTransfer.files[0];
    const reader = new FileReader();
    reader.onloadend = () => {
      this.selectedImage = reader.result;
    };
    if (this.file) {
      reader.readAsDataURL(this.file);
    }
  }
}
