import { gql } from 'apollo-angular';

const START_CUSTOMER_ONBOARDING_MUTATION = gql`
  mutation StartCustomerOnboarding($cpf: String!, $name: String!, $phoneNumber: String!) {
    startCustomerOnboarding(cpf: $cpf, name: $name, phoneNumber: $phoneNumber)
  }
`;

const FINISH_SELLER_ONBOARDING_MUTATION = gql`
  mutation FinishSellerOnboarding($params: FinishOnboardingParamsInput!) {
    finishSellerOnboarding(params: $params) {
      id
    }
  }
`;

const FINISH_CUSTOMER_ONBOARDING_MUTATION = gql`
  mutation FinishCustomerOnboarding(
    $address: CreateAddressParams!
    $cpf: String!
    $name: String!
    $termAcceptedAt: NaiveDateTime!
    $termVersion: String!
  ) {
    finishCustomerOnboarding(
      params: { address: $address, cpf: $cpf, name: $name, termAcceptedAt: $termAcceptedAt, termVersion: $termVersion }
    ) {
      id
      email
      status
      phone
    }
  }
`;

const CREATE_ONBOARDING_SEARCH_MUTATION = gql`
  mutation CreateOnboardingSearch($params: OnboardingSearchParamsInput!) {
    createOnboardingSearch(params: $params) {
      id
      question {
        id
        question
        order
        type
        answers {
          id
          answer
        }
      }
    }
  }
`;

const UPDATE_ONBOARDING_STEP_MUTATION = gql`
  mutation UpdateOnboardingStep($userId: String!, $step: StepType!) {
    updateOnboardingStep(userId: $userId, step: $step) {
      id
      users {
        id
        email
        name
        phone
        cpf
        emailVerified
        active
        onboardingSteps {
          step
        }
        onboardingSearch {
          id
          question {
            id
            question
            order
            type
            answers {
              id
              answer
            }
          }
        }
      }
    }
  }
`;

const START_SELLER_ONBOARDING_MUTATION = gql`
  mutation startSellerOnboarding($params: StartOnboardingParamsInput!) {
    startSellerOnboarding(params: $params) {
      id
      email
      name
      phone
      cpf
      emailVerified
      active
      onboardingSteps {
        step
      }
      onboardingSearch {
        id
        question {
          id
          question
          order
          type
          answers {
            id
            answer
          }
        }
      }
    }
  }
`;

export {
  START_SELLER_ONBOARDING_MUTATION,
  CREATE_ONBOARDING_SEARCH_MUTATION,
  UPDATE_ONBOARDING_STEP_MUTATION,
  FINISH_SELLER_ONBOARDING_MUTATION,
  START_CUSTOMER_ONBOARDING_MUTATION,
  FINISH_CUSTOMER_ONBOARDING_MUTATION,
};
