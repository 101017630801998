import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { EditorChangeContent, EditorChangeSelection } from 'ngx-quill';
import Quill from 'quill';

@Component({
  selector: 'app-rich-text-editor',
  templateUrl: './rich-text-editor.component.html',
  styleUrls: ['./rich-text-editor.component.scss'],
})
export class RichTextEditorComponent {
  @Input() public form: FormGroup;
  @Input() public controlName: string;
  @Input() public placeholder: string;
  @Input() public errorMessage: string;
  @Input() public: number = 500;
  @Input() public maxLength: number = 500;

  @Output() public contentChanged = new EventEmitter<string>();

  public blured: boolean = false;
  public focused: boolean = false;

  public toolbarOptions: Array<any> = [
    ['bold', 'italic', 'underline', 'strike'], // toggled buttons
    // ['blockquote', 'code-block'],
    // ['link', 'formula'],
    [{ header: 1 }, { header: 2 }], // custom button values
    [{ list: 'ordered' }, { list: 'bullet' }, { list: 'check' }],
    // [{ script: 'sub' }, { script: 'super' }], // superscript/subscript
    // [{ indent: '-1' }, { indent: '+1' }], // outdent/indent
    // [{ direction: 'rtl' }], // text direction
    [{ size: ['small', false, 'large', 'huge'] }], // custom dropdown
    // [{ header: [1, 2, 3, 4, 5, 6, false] }],
    [{ color: [] }, { background: [] }], // dropdown with defaults from theme
    // [{ font: [] }],
    [{ align: [] }],
    ['clean'], // remove formatting button
  ];
  public modules: Object = {
    toolbar: this.toolbarOptions,
  };

  public onContentChanged(content: any): void {
    this.contentChanged.emit(content);
  }
}
