<section class="m-1" *ngIf="!loading">
  <div class="d-flex align-center">
    <button nz-button nzType="text" nzShape="circle" (click)="drawerRef.close()">
      <span nz-icon nzType="close"></span>
    </button>
    <p class="header-title no-margin-bottom">Detalhes do Usuário B2C</p>
    <span class="example-spacer"></span>

    <div class="d-flex endBlock">
      <app-primary-button icon="down" [loading]="loadingBtn" nz-dropdown [nzDropdownMenu]="menu" nzTrigger="click">
        Opções
      </app-primary-button>
      <nz-dropdown-menu #menu="nzDropdownMenu">
        <ul nz-menu>
          <li nz-menu-item class="primary-color" (click)="showEditDrawer()">Editar informações</li>
          <li nz-menu-item class="primary-color" (click)="approveCustomer()">Aprovar</li>
          <li nz-menu-item class="primary-color" (click)="reprocessCustomer()">Reprocessar</li>
          <li nz-menu-item class="error-color" (click)="declineCustomer()">Reprovar</li>
          <li nz-menu-item class="error-color" (click)="banCustomer()">Banir</li>
        </ul>
      </nz-dropdown-menu>
    </div>
  </div>

  <nz-divider />

  <div class="card-container">
    <nz-tabset nzType="card">
      <nz-tab nzTitle="Dados Pessoais">
        <div class="primary-title">
          {{ customer?.name }}
        </div>

        <div class="header-info" nz-row>
          <div nz-col nzSpan="4">
            <span class="column-title"> STATUS </span>
            <app-status-tag [type]="customer?.status || 'INACTIVE'" gender="male"></app-status-tag>
          </div>
          <div nz-col nzSpan="4">
            <span class="column-title">Criado em</span>
            <span>
              {{
                (customer?.user?.insertedAt | customDate : 'DD/MM/YYYY HH:mm') ||
                  (customer?.insertedAt | customDate : 'DD/MM/YYYY HH:mm') ||
                  '-'
              }}
            </span>
          </div>
          <div nz-col nzSpan="4">
            <span class="column-title">Permissão</span>
            <span> {{ customer?.user?.role ?? '-' }} </span>
          </div>
          <div nz-col nzSpan="4">
            <span class="column-title">ID</span>
            <span> {{ customer?.id }} </span>
          </div>
          <div nz-col nzSpan="4">
            <span class="column-title">ID externo</span>
            <span> {{ customer?.externalId ?? '-' }} </span>
          </div>
        </div>

        <div class="secondary-title m-t-2">Dados do contato</div>

        <div class="header-info" nz-row>
          <div nz-col nzSpan="6">
            <span class="column-title"> CPF </span>
            <span> {{ customer?.cpf | mask : '000.000.000-00' }} </span>
          </div>
          <div nz-col nzSpan="6">
            <span class="column-title">Telefone</span>
            <span> {{ (customer?.phone | mask : '+00 (00) 0 0000-0000') || '-' }} </span>
          </div>
          <div nz-col nzSpan="6" *ngIf="customer?.birthDate">
            <span class="column-title">Data de nascimento</span>
            <span> {{ customer?.birthDate | date : 'dd/MM/yyyy' }} </span>
          </div>
          <div nz-col nzSpan="6" *ngIf="customer?.nameMother">
            <span class="column-title">Nome da mãe</span>
            <span> {{ customer?.nameMother }} </span>
          </div>
          <div nz-col nzSpan="6">
            <span class="column-title">E-mail</span>
            <span> {{ customer?.email || '-' }} </span>
          </div>
          <div nz-col nzSpan="6">
            <span class="column-title">Endereço</span>
            <span>
              {{ customer?.address?.line1 }}, {{ customer?.address?.line2 }} - {{ customer?.address?.postalCode }}
            </span>
          </div>
        </div>

        <div class="secondary-title m-t-2">Estabelecimentos cadastrados</div>

        <div class="w-100">
          <app-main-table
            [listOfHeaders]="listOfHeadersColumn"
            [listOfData]="listOfData"
            tableType="CUSTOMERS_ESTABLISHMENTS"
            [settingValue]="settingValue"
          />
        </div>
      </nz-tab>
      <nz-tab nzTitle="Documentos">
        <app-main-table
          [listOfHeaders]="listOfHeadersColumnDocuments"
          [listOfData]="customer?.documents"
          tableType="DOCUMENTS"
          [settingValue]="settingValue"
          (actionBtn)="handleAction($event)"
        />
        <div class="d-flex endBlock m-t-1">
          <app-primary-button icon="plus" theme="outline" (click)="uploadDocument()">
            Adicionar Documento
          </app-primary-button>
        </div>
      </nz-tab>
      <nz-tab nzTitle="Limites">
        <app-main-table
          [listOfHeaders]="listOfHeadersColumnLimits"
          [listOfData]="customer?.limits"
          tableType="CUSTOMER_LIMITS"
          [settingValue]="settingValue"
        />
      </nz-tab>
    </nz-tabset>
  </div>
</section>

<div class="spinner centerBlock h-100" *ngIf="loading">
  <nz-spin class="centerBlock" nzSize="large"></nz-spin>
</div>
