<div *ngIf="loading" class="h-100 m-t-2 center w-100">
  <nz-spin nzSize="large" nzSimple></nz-spin>
</div>
<section *ngIf="!loading">
  <header>
    <nz-tabset [(nzSelectedIndex)]="index" class="w-fit-content">
      <nz-tab nzTitle="Categoria"></nz-tab>
      <nz-tab nzTitle="Tipo"></nz-tab>
    </nz-tabset>

    <img src="/assets/images/close.svg" (click)="$modalRef.close()" class="pointer-cursor" />
  </header>

  <nz-row class="w-100">
    <nz-col nzSpan="24">
      <app-list-category-or-type-coupons
        [type]="index ? 'TYPE' : 'CATEGORY'"
        [data]="index ? types : categories"
        (actionBtn)="showAction($event)"
      />
    </nz-col>
  </nz-row>

  <nz-divider></nz-divider>

  <nz-row class="w-100">
    <nz-col nzSpan="24" class="endBlock">
      <app-primary-button (click)="$modalRef.close()">Fechar</app-primary-button>
    </nz-col>
  </nz-row>
</section>
