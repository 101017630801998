import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { AngularFireFunctions } from '@angular/fire/compat/functions';

import { ResponseModel } from '../../models/response/response.model';

@Injectable()
export class TokensService {
  constructor(private afFunc: AngularFireFunctions) {}

  public updateRefreshToken(params = {}): Observable<ResponseModel<string>> {
    return this.afFunc.httpsCallable<any, ResponseModel<string>>('updateRefreshToken')(params);
  }
}
