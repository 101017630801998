import { Component, HostListener, Input } from '@angular/core';
import { CouponFidelityModel } from '../../../models/fidelity/coupon-fidelity.model';
import { SellerModel } from '../../../models/sellers/sellers.model';
import { LeadModel } from '../../../models/lead/lead.model';
import { LeadService } from '../../../services/lead/lead.service';
import { ErrorService } from '../../../services/error/error.service';
import { StateManagementService } from '../../../state-management/state-management.service';
import { NzModalRef, NzModalService } from 'ng-zorro-antd/modal';
import { Subject, takeUntil } from 'rxjs';
import { Message } from '../../../utils/message';
import { Router } from '@angular/router';
import { ModalSuccessRedeemCouponComponent } from '../modal-success-redeem-coupon/modal-success-redeem-coupon.component';
import { InternalService } from '../../../services/internal/internal.service';
import { NearlestMallModel } from '../../../models/mall/nearlest-mall.model';
import { LocationType } from '../../../types/location-type';

@Component({
  selector: 'gen-monorepo-modal-use-coupon',
  templateUrl: './modal-use-coupon.component.html',
  styleUrl: './modal-use-coupon.component.scss',
})
export class ModalUseCouponComponent {
  @Input() public id: string = '';

  public coupon: CouponFidelityModel = new CouponFidelityModel();
  public seller: SellerModel = new SellerModel();
  public lead: LeadModel = new LeadModel();

  public location: LocationType;

  public link: string = 'https://cupom.gen.com.br';

  public isShowAvaliationCoupon: boolean = false;
  public isUseCouponOption: boolean = true;
  public isExpired: boolean = false;
  public openModal: boolean = false;
  public useScanner: boolean = true;
  public loading: boolean = true;
  public isMobile: boolean = window.innerWidth < 768;

  public nearlestMall: Array<NearlestMallModel> = [];

  private destroy$ = new Subject();

  constructor(
    private $lead: LeadService,
    private $error: ErrorService,
    private $notification: StateManagementService,
    private readonly $modal: NzModalService,
    private readonly router: Router,
    private $methods: InternalService,
    public modalRef: NzModalRef
  ) {}

  public ngOnInit(): void {
    this.setLocation();
    this.getNotifications();
  }

  public ngOnDestroy(): void {
    this.destroy$.next(1);
    this.destroy$.complete();
  }

  private setLocation(): void {
    this.$methods.getUserLocation();
  }

  private getNotifications(): void {
    this.$notification.leads.subscribe((lead) => {
      if (lead?.id) {
        this.lead = lead;
      }

      this.getCoupon(lead?.id);
    });

    this.$notification.locations.subscribe((location) => {
      if (location) {
        this.location = location;

        // this.verifyNearestMalls();
      }
    });
  }

  private getCoupon(leadId?: string): void {
    this.$lead.getCouponById(leadId, this.id).subscribe({
      next: (res) => {
        if (res?.data?.couponLead) {
          this.coupon = JSON.parse(JSON.stringify(res.data.couponLead));
        }

        this.loading = false;
      },
      error: (error) => {
        this.loading = false;
        this.$error.errorHandling(error, Message.ERROR_CONECTION);
      },
    });
  }

  public verifyNearestMalls(): void {
    this.$lead
      .nearestMalls(JSON.stringify(this.location?.latitude), JSON.stringify(this.location?.longitude))
      .subscribe({
        next: (res) => {
          if (res) {
            this.nearlestMall = res.data.nearestMalls.filter((res) => parseInt(res.distance.replace(' km')) <= 1);
          }
        },

        error: (error) => {
          this.$error.errorHandling(error, Message.ERROR_CONECTION);
        },
      });
  }

  public goToScanner(): void {
    this.modalRef.close();
    this.router.navigate([`/scan/${this.id}`]);
  }

  public likeCoupon(coupon: Partial<CouponFidelityModel>): void {
    if (coupon.isFavorite) {
      this.$lead
        .unlikeCoupon(coupon.id)
        .pipe(takeUntil(this.destroy$))
        .subscribe({
          next: (res) => {
            if (res?.data?.removeLeadLikeCoupon) {
              coupon.isFavorite = !coupon.isFavorite;
            }
          },
          error: (error) => {
            this.$error.errorHandling(error, 'Erro ao retirar o like no cupom. Por favor, tente novamente mais tarde.');
          },
        });
    } else {
      this.$lead
        .likeCoupon(coupon.id)
        .pipe(takeUntil(this.destroy$))
        .subscribe({
          next: (res) => {
            if (res?.data?.addLeadLikeCoupon) {
              coupon.isFavorite = !coupon.isFavorite;
            }
          },
          error: (error) => {
            this.$error.errorHandling(error, 'Erro ao dar like no cupom. Por favor, tente novamente mais tarde.');
          },
        });
    }
  }

  private showSuccessModal(): void {
    this.openModal = false;
    this.$notification.setOpenModal(false);

    this.$modal.create({
      nzTitle: null,
      nzContent: ModalSuccessRedeemCouponComponent,
      nzFooter: null,
      nzCentered: true,
      nzClosable: false,
      nzClassName: 'ant-modal-fullscreen',
      nzComponentParams: {
        coupon: this.coupon,
      },
    });
  }

  @HostListener('window:resize', ['$event'])
  public onResize(event: any): void {
    this.isMobile = event.target.innerWidth < 768;
  }
}
