<div class="sent-msg">
  <p>Insira abaixo o código que foi enviado para o número</p>
  <p>
    <b> {{ account.phoneNumber | mask : '(00) 0 0000 - 0000' }} </b>
    <span><a (click)="changeNumber()">Alterar número</a></span>
  </p>
</div>
<div id="phoneCode">
  <form nz-form [formGroup]="formConfirmPhone" (keydown.enter)="$event.preventDefault()">
    <nz-form-item>
      <nz-form-control [nzSpan]="4">
        <input
          #input
          nz-input
          formControlName="code1"
          type="tel"
          id="code1"
          required
          nzFor="code1"
          mask="0"
          (input)="onInput($event, 1)"
          (paste)="pasteNumeric($event)"
        />
      </nz-form-control>
    </nz-form-item>
    <nz-form-item>
      <nz-form-control [nzSpan]="4">
        <input
          #input
          nz-input
          formControlName="code2"
          type="tel"
          id="code2"
          required
          nzFor="code2"
          mask="0"
          (input)="onInput($event, 2)"
          (keyup)="onPressDeleteKey($event, 1)"
        />
      </nz-form-control>
    </nz-form-item>
    <nz-form-item>
      <nz-form-control [nzSpan]="4">
        <input
          #input
          nz-input
          formControlName="code3"
          type="tel"
          id="code3"
          required
          nzFor="code3"
          mask="0"
          (input)="onInput($event, 3)"
          (keyup)="onPressDeleteKey($event, 2)"
        />
      </nz-form-control>
    </nz-form-item>
    <nz-form-item>
      <nz-form-control [nzSpan]="4">
        <input
          #input
          nz-input
          formControlName="code4"
          type="tel"
          id="code4"
          required
          nzFor="code4"
          mask="0"
          (input)="onInput($event, 4)"
          (keyup)="onPressDeleteKey($event, 3)"
        />
      </nz-form-control>
    </nz-form-item>
    <nz-form-item>
      <nz-form-control [nzSpan]="4">
        <input
          #input
          nz-input
          formControlName="code5"
          type="tel"
          id="code5"
          required
          nzFor="code5"
          mask="0"
          (input)="onInput($event, 5)"
          (keyup)="onPressDeleteKey($event, 4)"
        />
      </nz-form-control>
    </nz-form-item>
    <nz-form-item>
      <nz-form-control [nzSpan]="4">
        <input
          #input
          nz-input
          formControlName="code6"
          type="tel"
          id="code5"
          required
          nzFor="code6"
          mask="0"
          (keyup)="onPressDeleteKey($event, 5)"
        />
      </nz-form-control>
    </nz-form-item>
  </form>
</div>
<div class="resend-code">
  <p id="resendMessage">
    <button id="resend" [disabled]="!allowResend" (click)="resendCode()">Enviar código novamente</button>
    &nbsp; ({{ countdown }} seg.)
  </p>
</div>
<div class="footer">
  <button nz-button (click)="changeNumber()">Voltar</button>
  <app-primary-button
    [loading]="loadingCodeBtn"
    [disabled]="formConfirmPhone.invalid || loadingCodeBtn"
    (click)="validatePhoneNumberCode()"
    [block]="true"
  >
    Entrar
  </app-primary-button>
</div>
