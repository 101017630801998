import { Component, Input, OnInit } from '@angular/core';
import { NzModalRef } from 'ng-zorro-antd/modal';
import { ModalModel } from '../../models/modal/modal.model';

@Component({
  selector: 'app-modal-delete-credit-card',
  templateUrl: './modal-delete-credit-card.component.html',
  styleUrls: ['./modal-delete-credit-card.component.scss'],
})
export class ModalDeleteCreditCardComponent implements OnInit {
  constructor(public modalRef: NzModalRef) {}

  @Input() data?: ModalModel;

  ngOnInit(): void {
    this.getInitialData();
  }

  private getInitialData(): void {
    if (!this.data?.callback) {
      this.data.callback = () => {
        this.modalRef.close();
      };
    }
  }
}
