import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'numberToHoursPipe',
})
export class NumberToHoursPipe implements PipeTransform {
  transform(minutes: number, noZero?: boolean): string {
    if (minutes > 0) {
      const zero = +noZero ? '' : ':00';
      const hours = Math.floor(minutes / 60);
      const minutesLeft = minutes % 60;
      return `${hours < 10 ? '0' : ''}${hours}:${minutesLeft < 10 ? '0' : ''}${minutesLeft}${zero}`;
    } else {
      return '00:00:00';
    }
  }
}
