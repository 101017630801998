<header>
  <div class="row">
    <button class="button button__button--left" (click)="goBack()">
      <span nz-icon nzType="left" nzTheme="outline"></span>
    </button>

    <div class="title">
      <h1>{{ title }}</h1>
    </div>
  </div>
</header>
