import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'itemTypes',
})
export class ItemTypesPipe implements PipeTransform {
  transform(value: any, ...args: string[]): string {
    if (!value) {
      return '';
    }

    const types: any = {
      SERVICE: 'SERVIÇO',
      PRODUCT: 'PRODUTO',
    };

    return types[value || ''];
  }
}
