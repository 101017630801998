import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { Apollo, Query } from 'apollo-angular';

import { GET_LOCKER_QUERY } from '../../graphql/queries/locker.queries';
import { CREATE_LOCKER_ORDER } from '../../graphql/mutations/locker.mutation';
import { LockerModel } from '../../models/locker/locker.model';

@Injectable()
export class LockerService {
  constructor(private readonly $apollo: Apollo) {}

  public getLocker(cep: string, distance: number): Observable<any> {
    return this.$apollo.watchQuery<Query>({
      query: GET_LOCKER_QUERY,
      variables: { cep, distance },
      fetchPolicy: 'network-only',
    }).valueChanges;
  }

  public createLockerOrder(params: LockerModel): Observable<any> {
    return this.$apollo.mutate({
      mutation: CREATE_LOCKER_ORDER,
      variables: { params },
    });
  }
}
