import { ThemeColorsModel } from './theme-colors.model';
import { PointOfSaleModel } from './point-of-sale.model';
import { SellerSegmentType } from '../../types/seller/seller-segment.type';
import { SellerSmartposType } from '../../types/seller/seller-smartpos.type';

export class SettingsModel {
  public fee?: string = '';
  public fine?: string = '';
  public allowfee?: boolean = false;
  public allowFine?: boolean = false;
  public segments?: SellerSegmentType[] = [];
  public mainSegment?: SellerSegmentType;
  public allowChangeQuantity?: boolean = false;
  public allowMethods?: boolean = false;
  public smartposType?: SellerSmartposType;
  public allowInstallment?: boolean = false;
  public allowPaymentLink?: boolean = false;
  public themeColors?: ThemeColorsModel = new ThemeColorsModel();
  public pointOfSale?: PointOfSaleModel = new PointOfSaleModel();
  public lastUpdatedPasswordAt?: string = '';
  public defaultMaxInstallments?: number = 0;
  public defaultPaymentMethods?: { name: string; methods: string }[] = [];
}
