import { Component } from '@angular/core';
import { NzModalRef } from 'ng-zorro-antd/modal';

@Component({
  selector: 'app-modal-gen-box',
  templateUrl: './modal-gen-box.component.html',
  styleUrls: ['./modal-gen-box.component.scss'],
})
export class ModalGenBoxComponent {
  constructor(public modalRef: NzModalRef) {}
}
