import { Component, OnInit } from '@angular/core';
import { NzModalService } from 'ng-zorro-antd/modal';

import { UserClaimsModel } from '../../models/user-claims/user-claims.model';
import { AuthService } from '../../services/auth/auth-business/auth.service';
import { ModalLogoutComponent } from '../modals/modal-logout/modal-logout.component';
import { StateManagementService } from '../../state-management/state-management.service';
import { NotificationsModel } from '../../models/notifications/notifications.model';
import { ErrorService } from '../../services/error/error.service';
import { Message } from '../../utils/message';

@Component({
  selector: 'app-simple-navbar',
  templateUrl: './simple-navbar.component.html',
  styleUrls: ['./simple-navbar.component.scss'],
})
export class SimpleNavbarComponent implements OnInit {
  public title: string = '';
  public user: UserClaimsModel = {} as UserClaimsModel;
  public isPopoverNotificationVisible: boolean = false;
  public isCustomStylePage: boolean = false;

  public notifications: NotificationsModel[] = [];
  public read: NotificationsModel[] = [];
  public unRead: NotificationsModel[] = [];
  public readLimited: NotificationsModel[] = [];
  public unReadLimited: any = [];
  public customStylePage: Array<string> = ['Blacklist B2C Create Edit', 'Blacklist Details'];

  constructor(
    private $auth: AuthService,
    private $error: ErrorService,
    private readonly $modal: NzModalService,
    private $notification: StateManagementService
  ) {}

  public ngOnInit(): void {
    this.getUser();
    this.getTitle();
  }

  private getTitle(): void {
    this.$notification.titles.subscribe((res) => {
      this.title = res;

      this.verifyCustomStylePage();
    });
  }

  public verifyCustomStylePage(): void {
    this.isCustomStylePage = this.customStylePage.includes(this.title);
  }

  private getUser(): void {
    this.$notification.users.subscribe((res) => {
      if (res) {
        this.user = res;
      }
    });
  }

  public async markAllAsRead(): Promise<void> {
    try {
      if (this.user?.sellerId) {
        this.unRead.map(async (res) => {
          if (!res.read) {
            // update notification
          }
        });

        this.isPopoverNotificationVisible = false;
      } else {
        this.$error.errorHandling(null, Message.ERROR_LOAD_DATA);
      }
    } catch (error: any) {
      this.$error.errorHandling(null, Message.ERROR_CONECTION);
      throw new Error(error);
    }
  }

  public logout(): void {
    this.$modal
      .create({
        nzContent: ModalLogoutComponent,
      })
      .afterClose.subscribe((res: boolean) => {
        if (res) {
          this.$auth.logout();
        }
      });
  }
}
