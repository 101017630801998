import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { Apollo, Query } from 'apollo-angular';

import { GET_URL_SHORTENER_BY_HASH_QUERY } from '../../graphql/queries/url-shortener.queries';

@Injectable()
export class UrlShortenerService {
  constructor(private apollo: Apollo) {}

  public getUrlShortenerByHash(hash: string): Observable<any> {
    return this.apollo.watchQuery<Query>({
      query: GET_URL_SHORTENER_BY_HASH_QUERY,
      variables: { hash },
      fetchPolicy: 'network-only',
    }).valueChanges;
  }
}
