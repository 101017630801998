import { gql } from 'apollo-angular';

const UPDATE_DOCUMENT_STATUS = gql`
  mutation UpdateDocument($id: String!, $reason: String, $status: String) {
    updateDocument(id: $id, params: { reason: $reason, status: $status }) {
      id
      status
    }
  }
`;

export { UPDATE_DOCUMENT_STATUS };
