import { Component, ElementRef, EventEmitter, Input, Output } from '@angular/core';
import { Router } from '@angular/router';
import { StateManagementService } from '../../state-management/state-management.service';
import { CartModel } from '../../models/cart/cart.model';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent {
  @Input() public primaryButton: boolean = false;
  @Input() public secondaryButton: boolean = false;
  @Input() public paymentButton: boolean = false;
  @Input() public textPrimaryButton: string = '';
  @Input() public textSecondaryButton: string = '';
  @Input() public textPaymentButton: string = 'Pagar';
  @Input() public disabledPrimaryButton: boolean = false;
  @Input() public disabledPaymentButton: boolean = false;
  @Input() public disabledSecondaryButton: boolean = false;
  @Input() public totalValue: number = null;

  @Output() public primaryButtonClick = new EventEmitter();

  public activePanel: boolean = false;
  public isToggle: boolean = false;
  public isShowCollapse: boolean = false;

  public cart: CartModel = new CartModel();

  constructor(
    private readonly elementRef: ElementRef,
    private router: Router,
    private $notification: StateManagementService
  ) {}

  public ngOnInit(): void {
    this.showNavigation();
  }

  public getNotifications(): void {
    this.$notification.carts.subscribe((cart) => {
      if (cart) {
        this.cart = cart;
      }
    });
  }

  public callToAction(data?: any): void {
    this.primaryButtonClick.emit(data);
  }

  public onClickCollapseIcon(id: string): void {
    this.activePanel = !this.activePanel;
    this.isToggle = !this.isToggle;

    if (this.isToggle) {
      const element = this.elementRef.nativeElement.querySelector(id);
      element.style.transform = 'rotate(270deg)';
      element.style.right = '27%';
    } else {
      const element = this.elementRef.nativeElement.querySelector(id);
      element.style.transform = 'rotate(90deg)';
      element.style.right = '33%';
    }
  }

  public showNavigation(): any {
    const verifyRoute = this.router.url;

    if (verifyRoute.includes('/loggedCart')) {
      this.isShowCollapse = true;
    }
  }
}
