import { AddressModel } from '../address/address.model';

export class CouponFiltersModel {
  customer?: CreateCustomerInput;
  status?: CouponStatusEnum;
  typeDiscount?: CouponTypesEnum;
  categoryIds?: string[];
  mallId?: number;
  name?: string;
  sellerId?: string;
  startDate?: string;
  endDate?: string;
  cumulative?: boolean;
  description?: string;
  appView?: string;
}

export class CreateCustomerInput {
  id: string;
  name: string;
  addressId: string;
  address: AddressModel;
  phone: string;
  email: string;
  userId: string;
  customerId: string;
  status: CustomerStatusEnumCoupons;
}

enum CustomerStatusEnumCoupons {
  ACTIVE = 'ACTIVE',
  DECLINED = 'DECLINED',
  IN_ANALYSIS = 'IN_ANALYSIS',
  IN_COMPLIANCE_REVIEW = 'IN_COMPLIANCE_REVIEW',
  IN_CREDIT_CARD_VALIDATION = 'IN_CREDIT_CARD_VALIDATION',
  REPROCESS_REVIEW = 'REPROCESS_REVIEW',
  IN_PROGRESS = 'IN_PROGRESS',
}

export enum CouponStatusEnum {
  NEW = 'NEW',
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
}

export enum CouponOrderByEnum {
  RELEVANCE = 'RELEVANCE',
  PRICE_DESC = 'PRICE_DESC',
  PRICE_ASC = 'PRICE_ASC',
  DUE_DATE_DESC = 'DUE_DATE_DESC',
  AVALIATION_DESC = 'AVALIATION_DESC',
  NAME = 'NAME',
}

enum CouponTypesEnum {
  PERCENT = 'PERCENT',
  REAL_VALUE = 'REAL_VALUE',
}
