import { CustomerModel } from '../customer/customer.model';
import { MallModel } from '../mall/mall.model';

export class BoxOfficeModel {
  id?: string;
  name?: string;
  available?: boolean;
  childrensEvent?: boolean;
  title?: string;
  registerStartDate?: string;
  registerEndDate?: string;
  startDate?: string;
  endDate?: string;
  mallId?: number;
  description?: string;
  maxParticipantsPerCpf?: number;
  maxParticipants?: number;
  rulesSummary?: string;
  imageUrl?: string;
  boxOfficeSessions?: BoxOfficeSessions[];
  boxOfficeTickets?: BoxOfficeTickets[];
  boxOfficeRulesDocument?: BoxOfficeRulesDocument[];
  mall?: MallModel;
  createdAt?: string;
  updatedAt?: string;
  file?: FileUploadModel;
}

export class FileUploadModel {
  filename: string;
  binary: string;
}

export class BoxOfficeRulesDocument {
  id: string;
  name: string;
  url: string;
  file: FileUploadModel;
}

export class BoxOfficeTickets {
  id?: string;
  participant: string;
  createdAt: Date;
  usedTicket: boolean;
  updatedAt: Date;
  boxOfficeId?: string;
  customerId?: string;
  boxOfficeSession?: BoxOfficeSessions;
  boxOffice?: BoxOfficeModel;
  customer?: CustomerModel;
}

export class BoxOfficeSessions {
  id?: string;
  available: boolean;
  name: string;
  startDate: string;
  endDate: string;
  maxParticipants: number;
  boxOfficeId?: string;
  boxOffice?: BoxOfficeModel;
  boxOfficeTickets?: BoxOfficeTickets[];
  createdAt?: string;
  updatedAt?: string;
  duration: string;
}

export class BoxOfficeFilters {
  mallId?: number;
  customerId?: string;
  name?: string;
  insertedAt?: PeriodFilterParams;
  updatedAt?: PeriodFilterParams;
  available?: boolean;
}

export class PeriodFilterParams {
  afterOrEqual: string;
  beforeOrEqual: string;
}
