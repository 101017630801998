import { gql } from 'apollo-angular';

const SUBSCRIPTION_FIELDS_BUSINESS = `
  chargeDay
  chargeMonth
  creditCardId
  customer {
    id
    name
  }
  endDate
  externalId
  id
  insertedAt
  installments
  lastCharge
  method
  methods
  observation
  paymentAttempts
  priceId
  schedulePlans
  seller {
    id
    person {
      name
    }
    company {
      businessName
    }
  }
  sessionId
  startDate
  status
  totalCents
  updatedAt
`;

const SUBSCRIPTION_FIELDS_CHECKOUT = `
  chargeDay
  chargeMonth
  creditCardId
  publicCustomer {
    id
    name
    cpf
    phone
    email
    address {
      postalCode
      line1
      line2
      line3
      neighborhood
      state
      city
    }
  }
  endDate
  externalId
  id
  insertedAt
  installments
  lastCharge
  method
  methods
  observation
  paymentAttempts
  priceId
  schedulePlans
  publicSeller {
    id
    cpf
    cnpj
    displayName
    company {
      businessName
    }
    person {
      name
    }
  }
  items {
    id
    unitPriceCents
    description
    quantity
    item {

      images {
        imageUrl
      }
    }
  }
  payments {
    id
    method
    confirmedAt
    totalCents
    status
    insertedAt
    updatedAt
    credit {
      installments
      publicCreditCard {
        brand
        last4
      }
    }
    pix {
      brcode
    }
    bankSlip {
      bank
      barcode
      printLinkPdf
    }
  }
  sessionId
  startDate
  status
  totalCents
  updatedAt
`;

const CREATE_SUBSCRIPTION = gql`
  mutation CreateSubscription($params: CreatePaymentSubscriptionParams!) {
    createSubscription(params: $params)  {
      ${SUBSCRIPTION_FIELDS_BUSINESS}
    }
  }
`;

const CREATE_SUBSCRIPTION_CHECKOUT = gql`
  mutation CreateSubscription($params: CreatePaymentSubscriptionParams!) {
    createSubscription(params: $params)  {
      ${SUBSCRIPTION_FIELDS_CHECKOUT}
    }
  }
`;

const UPDATE_SUBSCRIPTION = gql`
  mutation UpdateSubscription($id: UUID4!, $params: UpdatePaymentSubscriptionParams!) {
    updateSubscription(id: $id, params: $params) {
      ${SUBSCRIPTION_FIELDS_BUSINESS}
    }
  }
`;

const UPDATE_SUBSCRIPTION_CHECKOUT = gql`
  mutation UpdateSubscription($id: UUID4!, $params: UpdatePaymentSubscriptionParams!) {
    updateSubscription(id: $id, params: $params) {
      ${SUBSCRIPTION_FIELDS_CHECKOUT}
    }
  }
`;
const DELETE_SUBSCRIPTION = gql`
  mutation deleteSubscription($id: UUID4!) {
    deleteSubscription(id: $id) {
      id
    }
  }
`;

export {
  CREATE_SUBSCRIPTION,
  UPDATE_SUBSCRIPTION,
  DELETE_SUBSCRIPTION,
  UPDATE_SUBSCRIPTION_CHECKOUT,
  CREATE_SUBSCRIPTION_CHECKOUT,
};
