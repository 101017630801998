import { Component, HostListener } from '@angular/core';

@Component({
  selector: 'app-marketplace-promotion',
  templateUrl: './promotion.component.html',
  styleUrls: ['./promotion.component.scss'],
})
export class PromotionComponent {
  public isMobile: boolean = window.innerWidth < 768;
  public showPromotion: boolean = true;

  public promotion: { title: string; description: string; icon: string } = {
    title: 'Ganhe 10% de desconto',
    description: 'Em sua primeira compra',
    icon: '/assets/images/promotion-gift.svg',
  };

  constructor() {}

  @HostListener('window:resize', ['$event'])
  public onResize(event: any): void {
    this.isMobile = event.target.innerWidth < 768;
  }
}
