<header *nzModalTitle class="modal-header">
  <h3 class="title-category">{{ title }}</h3>
  <span class="example-spacer"></span>
  <img src="../../assets/images/close.svg" (click)="$modalRef.close()" class="pointer-cursor" />
</header>

<body>
  <main>
    <section>
      <div>
        <p>
          Crie um nome para o aplicativo e inclua a URL do sistema para onde os usuários devem ser redirecionado.
          <br /><br />
          Adicione sua logo para informar que é você quem está solicitando acesso à conta.
        </p>
      </div>

      <nz-divider></nz-divider>

      <form [formGroup]="formDescription">
        <nz-form-item>
          <label>Nome do App</label>
          <nz-form-control>
            <input nz-input formControlName="name" placeholder="Digite o nome" />
          </nz-form-control>
        </nz-form-item>

        <nz-form-item>
          <label>URL</label>
          <nz-form-control>
            <input nz-input formControlName="redirectUrl" placeholder="URL do direcionamento" />
          </nz-form-control>
        </nz-form-item>

        <!-- <div>
          <h3>Logo</h3>
          <ng-container *ngIf="!isEdit">
            <div class="select-image">
              <div
                id="preview-image"
                class="preview-image"
                (click)="fileInput.click()"
                (dragleave)="onDragLeave($event)"
                (dragover)="onDragOver($event)"
                (drop)="onDrop($event)"
              >
                <img class="low-opacity" [src]="selectedImage" *ngIf="selectedImage" (error)="img.src = errorImg" #img />
                <div (click)="fileInput.click()" class="banner-container">
                  <span nz-icon nzType="inbox" nzTheme="outline"></span>
                  <h4>Clique ou arraste um arquivo para upload</h4>
                  <p>Suporta apenas uma imagem de até 5mb</p>
                </div>
              </div>
              <input hidden (change)="chooseImage($event)" #fileInput type="file" accept=".png,.jpeg,.jpg,.svg" />
            </div>
          </ng-container>
    
          <ng-container *ngIf="isEdit">
            <div class="select-image">
              <div
                id="preview-image"
                class="preview-image"
                (click)="fileInput.click()"
                (dragleave)="onDragLeave($event)"
                (dragover)="onDragOver($event)"
                (drop)="onDrop($event)"
              >
                <img [src]="selectedImage" *ngIf="selectedImage" (error)="img.src = errorImg" #img />
                <mat-icon *ngIf="!selectedImage" color="accent">business</mat-icon>
              </div>
              <div class="m-t-1">
                <app-primary-button class="m-t-1" [disabled]="loading" (click)="fileInput.click()">
                  Selecione a Logo
                </app-primary-button>
              </div>
              <input hidden (change)="chooseImage($event)" #fileInput type="file" accept=".png,.jpeg,.jpg,.svg" />
            </div>
          </ng-container>
        </div> -->
      </form>
    </section>
  </main>
</body>

<footer class="modal-footer" *nzModalFooter>
  <div class="buttons">
    <app-secondary-button (click)="$modalRef.close()">Cancelar</app-secondary-button>
    <app-primary-button (click)="saveApp()" class="body-txt-bold">Salvar</app-primary-button>
  </div>
</footer>
