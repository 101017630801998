<main id="promotion" *ngIf="showPromotion">
  <nz-row>
    <nz-col nzSpan="6" class="desktop"> </nz-col>
    <nz-col [nzXs]="24" [nzSm]="24" [nzMd]="24" [nzLg]="18" [nzXl]="18" [nzXXl]="18">
      <div class="between w-90 items-center h-100">
        <div nz-row class="m-t-2">
          <div nz-col nzSpan="5">
            <img
              class="promotion-img"
              alt="promotion-img"
              nz-image
              [nzDisablePreview]="true"
              [nzSrc]="promotion.icon"
            />
          </div>

          <div nz-col nzSpan="19">
            <div class="inline w-max">
              <div class="w-100">
                <span class="promotion-title">{{ promotion?.title }}</span>
              </div>
              <div class="w-100">
                <span class="promotion-description">{{ promotion.description }}</span>
              </div>
            </div>
          </div>
        </div>

        <div class="promotion-button-div desktop">
          <button>Resgatar desconto</button>
        </div>

        <div (click)="showPromotion = false" class="pointer-cursor">
          <img src="/assets/images/close.svg" />
        </div>
      </div>
    </nz-col>
  </nz-row>
</main>
