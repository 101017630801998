import { gql } from 'apollo-angular';

const LIST_BLACKLIST_QUERY = gql`
  query Blacklists($filters: FilterBlacklistParamsInput, $pagination: PaginationParams) {
    blacklists(filters: $filters, pagination: $pagination) {
      pageSize
      page
      totalPages
      total
      results {
        name
        cnpj
        cpf
        email
        id
        insertedAt
        phone
        updatedAt
        reason
      }
    }
  }
`;

const GET_BLACKLIST_QUERY = gql`
  query Blacklist($id: String!) {
    blacklist(id: $id) {
      name
      cnpj
      cpf
      email
      id
      insertedAt
      phone
      updatedAt
      reason
    }
  }
`;

export { LIST_BLACKLIST_QUERY, GET_BLACKLIST_QUERY };
