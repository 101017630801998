<div class="header-drawer">
  <button class="button-left" nz-button nzType="text" nzShape="circle" (click)="$drawerRef.close()">
    <span nz-icon nzType="close"></span>
  </button>
  <span class="example-spacer"></span>
  <div class="button-right">
    <app-primary-button
      icon="plus"
      (click)="setHandleSubmit()"
      [disabled]="couponForm?.invalid"
      class="buttonsHeader"
      >{{ title }}</app-primary-button
    >
  </div>
</div>

<section>
  <form [formGroup]="couponForm">
    <div>
      <nz-form-item class="m-t-1">
        <nz-form-label nzRequired nzFor="name">Código do cupom</nz-form-label>
        <nz-form-control [nzSm]="24" [nzXs]="24" nzHasFeedback nzErrorTip="Campo obrigatório.">
          <input nz-input formControlName="code" (input)="changeTextToUppercase('code')" placeholder="Ex: CUPOM2022" />
        </nz-form-control>
      </nz-form-item>
    </div>

    <div>
      <nz-form-item class="m-t-1">
        <nz-form-label nzRequired nzFor="name">Desconto</nz-form-label>
        <nz-form-control [nzSm]="24" [nzXs]="24" nzHasFeedback nzErrorTip="Campo obrigatório.">
          <nz-input-group [nzAddOnAfter]="addOnAfterTemplate">
            <input
              *ngIf="couponForm.value.type === 'PERCENT'"
              type="text"
              nz-input
              formControlName="percent"
              mask="percent"
              placeholder="0.00"
              (keydown)="removeZeroFromLeft($event)"
            />
            <input
              *ngIf="couponForm.value.type === 'CENTS'"
              currencyMask
              nz-input
              formControlName="discountCents"
              placeholder="0"
            />
          </nz-input-group>
          <ng-template #addOnAfterTemplate>
            <nz-select formControlName="type" class="selectDiscount">
              <nz-option nzLabel="%" nzValue="PERCENT"></nz-option>
              <nz-option nzLabel="R$" nzValue="CENTS"></nz-option>
            </nz-select>
          </ng-template>
        </nz-form-control>
      </nz-form-item>
    </div>

    <!-- input for type in cents -->
    <div>
      <div>
        <nz-form-item class="m-t-1">
          <nz-form-label nzRequired nzFor="name"
            >Quantidade
            <span
              nz-icon
              nzType="question-circle"
              nzTooltipTitle="Quantidade de cupons que deseja disponibilizar. Quando o valor for 0, significa que você não deseja impor este limite."
              nz-tooltip
              nzTheme="outline"
              class="m-l-0"
            ></span
          ></nz-form-label>
          <nz-form-control [nzSm]="24" [nzXs]="24" nzHasFeedback nzErrorTip="Campo obrigatório.">
            <input
              nz-input
              type="number"
              formControlName="maxCoupons"
              placeholder="0"
              (keydown)="removeZeroFromLeft($event)"
            />
          </nz-form-control>
        </nz-form-item>
      </div>

      <div>
        <label
          >Gasto mínimo
          <span
            nz-icon
            nzType="question-circle"
            [nzTheme]="'outline'"
            nz-tooltip
            nzTooltipTitle="Exija um gasto mínimo de compra para liberar o cupom."
            class="m-l-0"
          ></span>
        </label>
        <nz-switch formControlName="isSwitchMinimum"></nz-switch>
      </div>

      <div *ngIf="couponForm.get('isSwitchMinimum').value" class="subInput">
        <nz-form-item>
          <nz-form-control [nzSm]="24" [nzXs]="24" nzHasFeedback nzErrorTip="Campo obrigatório.">
            <nz-input-group>
              <input currencyMask nz-input formControlName="minTotalCentsForDiscount" placeholder="0,00" />
            </nz-input-group>
          </nz-form-control>
        </nz-form-item>
      </div>

      <div>
        <nz-form-item class="m-t-1">
          <nz-form-label nzRequired nzFor="name"
            >Valor máximo por compra
            <span
              nz-icon
              class="m-l-0"
              nzType="question-circle"
              [nzTheme]="'outline'"
              nzTooltipTitle="Limite um valor máximo de desconto por compra."
              nz-tooltip
            >
            </span
          ></nz-form-label>
          <nz-form-control [nzSm]="24" [nzXs]="24" nzHasFeedback nzErrorTip="Campo obrigatório.">
            <nz-switch formControlName="isSwitchMaximum"></nz-switch>
          </nz-form-control>
        </nz-form-item>
      </div>

      <div *ngIf="couponForm.get('isSwitchMaximum').value" class="subInput">
        <nz-form-item>
          <nz-form-control [nzSm]="24" [nzXs]="24" nzHasFeedback nzErrorTip="Campo obrigatório.">
            <nz-input-group>
              <input currencyMask type="text" nz-input formControlName="maxDiscountCentsPerOrder" placeholder="0,00" />
            </nz-input-group>
          </nz-form-control>
        </nz-form-item>
      </div>

      <ng-container *ngIf="couponForm.value.type === 'PERCENT'">
        <div>
          <label
            >Valor máximo por CPF
            <span
              nz-icon
              nzType="question-circle"
              [nzTheme]="'outline'"
              nz-tooltip
              nzTooltipTitle="Limite um valor máximo de desconto por CPF"
              class="m-l-0"
            ></span>
          </label>
          <nz-switch formControlName="isSwitchMaximumCpf"></nz-switch>
        </div>

        <div *ngIf="couponForm.get('isSwitchMaximumCpf').value" class="subInput">
          <nz-form-item>
            <nz-form-control [nzSm]="24" [nzXs]="24" nzHasFeedback nzErrorTip="Campo obrigatório.">
              <nz-input-group>
                <input currencyMask nz-input formControlName="maxDiscountCentsPerCpf" placeholder="0,00" />
              </nz-input-group>
            </nz-form-control>
          </nz-form-item>
        </div>

        <div>
          <label
            >Limite de quantidade por CPF
            <span
              nz-icon
              nzType="question-circle"
              nzTheme="outline"
              nz-tooltip
              nzTooltipTitle="Limite de uso por CPF"
              class="m-l-0"
            ></span>
          </label>
          <nz-switch formControlName="isSwitchLimitCpf"></nz-switch>
        </div>

        <div *ngIf="couponForm.get('isSwitchLimitCpf').value" class="subInput">
          <nz-form-item>
            <nz-form-control [nzSm]="24" [nzXs]="24" nzHasFeedback nzErrorTip="Campo obrigatório.">
              <nz-input-group>
                <input
                  nz-input
                  type="number"
                  formControlName="maxCouponsPerCpf"
                  placeholder="0"
                  (keydown)="removeZeroFromLeft($event)"
                />
              </nz-input-group>
            </nz-form-control>
          </nz-form-item>
        </div>
      </ng-container>

      <div>
        <nz-form-item>
          <nz-form-label nzRequired nzFor="name">Periodo de disponibilidade </nz-form-label>
          <nz-form-control [nzSm]="24" [nzXs]="24" nzHasFeedback nzErrorTip="Campo obrigatório.">
            <nz-range-picker
              [nzFormat]="dateFormat"
              [nzDisabledDate]="disabledDate"
              formControlName="availabilityAt"
            ></nz-range-picker>
          </nz-form-control>
        </nz-form-item>
      </div>
    </div>
  </form>
</section>
