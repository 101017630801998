import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { StateManagementService } from '../../../state-management/state-management.service';
import { SellerModel } from '../../../models/sellers/sellers.model';
import { Clipboard } from '@angular/cdk/clipboard';
import { NzMessageService } from 'ng-zorro-antd/message';
import { StoreModel } from '../../../models/store/store.model';
import { NzModalRef } from 'ng-zorro-antd/modal';

@Component({
  selector: 'app-modal-contact-store',
  templateUrl: './modal-contact-store.component.html',
  styleUrls: ['./modal-contact-store.component.scss'],
})
export class ModalContactStoreComponent implements OnInit {
  public formContact: FormGroup;
  public store: StoreModel = new StoreModel();

  public isShowCheckButtonPhone: boolean = false;
  public isShowCheckButtonWhats: boolean = false;

  public hrefTel: string = '';
  public hrefWhatsApp: string = '';

  constructor(
    private fb: FormBuilder,
    private $notification: StateManagementService,
    private readonly clipboard: Clipboard,
    private readonly $message: NzMessageService,
    public $modalRef: NzModalRef
  ) {}

  public ngOnInit(): void {
    this.createForm();
    this.getNotification();
  }

  public getNotification(): void {
    this.$notification.stores.subscribe({
      next: (res) => {
        this.store = res;
        this.getFormValue();
        this.hrefTel = `tel:${this.store.callNumber}`;
        this.hrefWhatsApp = `https://wa.me/${this.store.whatsappNumber.replace('+55', '55')}`;
      },
    });
  }

  public createForm(): void {
    this.formContact = this.fb.group({
      whatsapp: new FormControl(),
      phone: new FormControl(),
    });

    this.formContact.get('whatsapp').disable();
    this.formContact.get('phone').disable();
  }

  public getFormValue(): void {
    this.formContact.patchValue({
      phone: this.store.callNumber,
      whatsapp: this.store.whatsappNumber.replace('+55', ''),
    });
  }

  public copyNumber(number: string, type: string): void {
    if (number) {
      this.clipboard.copy(number);

      if (type === 'PHONE') {
        this.isShowCheckButtonPhone = true;

        setTimeout(() => {
          this.isShowCheckButtonPhone = false;
        }, 500);
      } else {
        this.isShowCheckButtonWhats = true;

        setTimeout(() => {
          this.isShowCheckButtonWhats = false;
        }, 500);
      }

      this.$message.success('Número copiado com sucesso!');
    } else {
      this.$message.error('Número não definido.');
    }
  }
}
