/* eslint-disable @angular-eslint/contextual-lifecycle */
import { Injectable } from '@angular/core';

import { StateManagementService } from '../../state-management/state-management.service';
import { AlertDuration, AlertType, StatusAlertModel } from '../../models/status/status-alert.model';

@Injectable()
export class AlertService {
  public alertType: AlertType = new AlertType();
  public alertDuration: AlertDuration = new AlertDuration();
  public alertInfo: StatusAlertModel = new StatusAlertModel();

  constructor(private $notification: StateManagementService) {}

  private getNotification(): void {
    this.$notification.sucessAlerts.subscribe((successAlerts) => {
      if (successAlerts) {
        this.alertInfo = successAlerts;
      }
    });

    this.$notification.errorAlerts.subscribe((errorAlerts) => {
      if (errorAlerts) {
        this.alertInfo = errorAlerts;
      }
    });
  }

  public setAlertInfo(type: string, message: string): void {
    this.getNotification();

    if (type === 'SUCCESS') {
      const payload = {
        message: `${message}`,
        status: true,
        type: this.alertType.SUCCESS,
        duration: this.alertDuration.SOON,
      };
      this.$notification.setSucessAlert(payload);
    } else {
      const payload = {
        message: `${message}`,
        status: true,
        type: this.alertType.ERROR,
        duration: this.alertDuration.SOON,
      };
      this.$notification.setErrorAlert(payload);
    }
  }
}
