<section class="m-1">
  <div class="center">
    <h3 class="primary-color">Limpar carrinho?</h3>
  </div>

  <div class="text m-t-1">Tem certeza que deseja limpar o carrinho? todos os itens serão removidos</div>

  <div class="inline w-100 m-t-1">
    <button nz-button class="w-100" nzSize="large" (click)="$drawerRef.close()" nzType="primary">Cancelar</button>
    <button nz-button class="w-100 m-t-1" (click)="$drawerRef.close(true)" nzSize="large" nzType="default">
      Limpar carrinho
    </button>
  </div>
</section>
