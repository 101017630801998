import { Component, OnInit } from '@angular/core';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzModalRef, NzModalService } from 'ng-zorro-antd/modal';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';

import { Message } from '../../../utils/message';
import { SellerModel } from '../../../models/sellers/sellers.model';
import { AuthService } from '../../../services/auth/auth-business/auth.service';
import { StateManagementService } from '../../../state-management/state-management.service';
import { ValidationEmailComponent } from '../../security/validation-email/validation-email.component';
import { UserModel } from '../../../models/users/users.model';
import { ErrorService } from '../../../services/error/error.service';

@Component({
  selector: 'app-modal-change-email',
  templateUrl: './modal-change-email.component.html',
  styleUrls: ['./modal-change-email.component.scss'],
})
export class ModalChangeEmailComponent implements OnInit {
  public form!: FormGroup;
  public user: UserModel = new UserModel();

  public isConfirmPassword: boolean = false;

  constructor(
    private $auth: AuthService,
    private readonly fb: FormBuilder,
    public readonly modalRef: NzModalRef,
    private $error: ErrorService,
    private readonly $modal: NzModalService,
    private $notification: StateManagementService
  ) {}

  public ngOnInit(): void {
    this.createForm();
    this.getSeller();
  }

  public createForm(): void {
    this.form = this.fb.group({
      email: new FormControl('', [Validators.email, Validators.required]),
      signin: new FormControl(''),
      password: new FormControl(''),
    });
  }

  public getSeller(): void {
    this.$notification.users.subscribe((res) => {
      this.user = res;
    });
  }

  public setHandleSubmit(value?): void {
    if (value) {
      this.modalRef.close(this.form.value);
    } else {
      this.modalRef.close();
    }
  }

  public nextStep(): void {
    this.isConfirmPassword = true;
  }

  public async confirmPassword(): Promise<void> {
    this.$auth.loginByPassword(this.form.value.signin, this.form.value.password, 'B2B').subscribe({
      next: (res) => {
        if (res.data?.loginByPassword) {
          const params: Object = {
            data: {
              ...this.user,
              newEmail: this.form.get('email').value,
              oldEmail: this.user.email,
            },
          };

          this.$modal.create({
            nzComponentParams: params,
            nzTitle: 'Validação do e-mail',
            nzWidth: '500px',
            nzContent: ValidationEmailComponent,
          });
        }

        if (res.errors) {
          const error = res.errors[0];
          this.$error.errorHandling(error, Message.LOGIN_ERROR);
        }
      },
      error: (error) => {
        this.$error.errorHandling(error, Message.ERROR_CONECTION);
      },
    });
  }
}
