import { gql } from 'apollo-angular';

const BOX_OFFICE_BY_ID_QUERY = gql`
  query BoxOffice($id: String!) {
    boxOffice(id: $id) {
      available
      childrensEvent
      title
      registerStartDate
      registerEndDate
      startDate
      endDate
      description
      maxParticipantsPerCpf
      maxParticipants
      rulesSummary
      imageUrl
      boxOfficeSessions {
        id
        name
        available
        startDate
        endDate
        maxParticipants
        duration
        boxOfficeTickets {
          id
        }
      }
      boxOfficeRulesDocument {
        id
        name
        url
      }
      mall {
        id
        name
      }
      boxOfficeTickets {
        id
        participant
        customer {
          id
          name
          cpf
        }
      }
    }
  }
`;

const BOX_OFFICE_TICKET_ENTITY_QUERY = gql`
  query BoxOfficeTickets($filters: FilterBoxOfficeTicketParamsInput) {
    boxOfficeTickets(filters: $filters, pagination: { orderBy: "createdAt", sortingOrder: DESC, perPage: 100 }) {
      id
      participant
      createdAt
      customer {
        id
        name
        cpf
      }
    }
  }
`;

const BOX_OFFICES_QUERY = gql`
  query BoxOffices($filters: FilterBoxOfficeParamsInput, $pagination: PaginationParams) {
    boxOffices(filters: $filters, pagination: $pagination) {
      id
      available
      childrensEvent
      title
      startDate
      endDate
      mallId
      description
      maxParticipantsPerCpf
      maxParticipants
      rulesSummary
      imageUrl
      mall {
        id
        name
      }
      createdAt
      updatedAt
    }
  }
`;

const GET_BOX_OFFICE_REPORT = gql`
  query BoxOfficeReport($id: String!) {
    generateBoxOfficeReport(id: $id) {
      id
      reportUrl
      generatedAt
    }
  }
`;

export { BOX_OFFICE_BY_ID_QUERY, BOX_OFFICE_TICKET_ENTITY_QUERY, GET_BOX_OFFICE_REPORT, BOX_OFFICES_QUERY };
