import { Observable } from 'rxjs';
import { Apollo, Mutation, Query } from 'apollo-angular';
import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/compat/firestore';

import { BankDataModel } from '../../models/bank/bank-data.model';
import { BankAccountModel } from '../../models/bank-account/bank-account.model';
import { CREATE_BANK_ACCOUNT_MUTATION } from '../../graphql/mutations/bank-account.mutations';
import { LIST_DEFAULT_BANKS_QUERY } from '../../graphql/queries/bank.queries';

@Injectable()
export class BankService {
  constructor(private readonly apollo: Apollo, private readonly firestore: AngularFirestore) {}

  public createBankAccount(variables: BankDataModel): Observable<any> {
    return this.apollo.mutate<Mutation>({
      mutation: CREATE_BANK_ACCOUNT_MUTATION,
      variables,
    });
  }

  private _accountCollection(marketplaceId: string, sellerId: string): AngularFirestoreCollection<BankAccountModel> {
    return this.firestore.collection(`marketplaces/${marketplaceId}/sellers/${sellerId}/bankAccounts`);
  }

  public getAccounts(marketplaceId: string, sellerId: string): Observable<BankAccountModel[]> {
    return this._accountCollection(marketplaceId, sellerId).valueChanges();
  }

  public getBanks(): Observable<any> {
    return this.apollo.watchQuery<Query>({
      query: LIST_DEFAULT_BANKS_QUERY,
      fetchPolicy: 'network-only',
    }).valueChanges;
  }
}
