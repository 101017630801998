import html2pdf from 'html2pdf.js';
import { Component, ElementRef, Input, ViewChild } from '@angular/core';

@Component({
  selector: 'app-modal-show-qrcode',
  templateUrl: './modal-show-qrcode.component.html',
  styleUrls: ['./modal-show-qrcode.component.scss'],
})
export class ModalShowQrcodeComponent {
  @ViewChild('pdf') pdf: ElementRef;
  @ViewChild('simplePdf') simplePdf: ElementRef;
  @Input() link: string;

  public loading: boolean = false;

  constructor() {}

  public downloadQrCode(showLogo: boolean): void {
    this.loading = true;
    setTimeout(async () => {
      try {
        const element = showLogo ? this.pdf.nativeElement.innerHTML : this.simplePdf.nativeElement.innerHTML;

        const filename = `qrcode-${Date.now()}.pdf`;

        await html2pdf().from(element).save(filename);
      } catch (error: any) {
        throw new Error(error);
      } finally {
        this.loading = false;
      }
    }, 500);
  }
}
