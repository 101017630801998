<main id="update-price-table">
  <section id="header">
    <nz-row>
      <nz-col nzSpan="12">
        <span class="title">Tabela de preço</span>
      </nz-col>
      <nz-col nzSpan="12" class="endBlock">
        <img
          src="/assets/icons/xmark.svg"
          alt="Ícone para fechar o modal"
          class="pointer-cursor"
          (click)="closeModal()"
        />
      </nz-col>
    </nz-row>
  </section>

  <nz-divider></nz-divider>

  <section id="body">
    <form [formGroup]="formGroup">
      <nz-row class="center">
        <nz-col nzSpan="24" class="header">
          <span class="header-title">{{ table.mallName }}</span>
        </nz-col>
      </nz-row>
      <section class="m-l-1 m-r-1">
        <div *ngFor="let price of pricesControl().controls; let i = index; let last = last">
          <div formArrayName="prices">
            <div [formGroupName]="i">
              <nz-row class="m-b-1-2 m-t-1">
                <nz-col nzSpan="12">
                  <span class="subheader-title">{{ price.value.description }}</span>
                </nz-col>
                <nz-col nzSpan="12" class="endBlock">
                  <img
                    src="/assets/icons/trash-black.svg"
                    alt="Ícone de uma lixeira"
                    class="pointer-cursor"
                    (click)="removePrice(i)"
                    *ngIf="!['Principal', 'Tolerância', 'Adicional'].includes(price.value.description)"
                  />
                </nz-col>
              </nz-row>

              <nz-row [nzGutter]="price.value.description === 'Principal' ? 24 : 0">
                <nz-col
                  [nzSpan]="price.value.description === 'Principal' ? 12 : 24"
                  *ngIf="['Principal', 'Tolerância'].includes(price.value.description)"
                >
                  <nz-form-item>
                    <nz-form-label class="startBlock input-text" [nzNoColon]="true" [nzSm]="24" nzFor="time">
                      Tempo
                    </nz-form-label>
                    <nz-form-control [nzSm]="24" [nzXs]="24">
                      <nz-input-group [nzAddOnAfter]="addOnAfterTemplate">
                        <input nz-input type="number" formControlName="time" id="time" placeholder="15" />
                      </nz-input-group>
                    </nz-form-control>
                    <ng-template #addOnAfterTemplate>
                      <nz-select formControlName="timeType" class="min-select-width">
                        <nz-option nzLabel="min" nzValue="MINUTE"></nz-option>
                        <nz-option nzLabel="hora" nzValue="HOUR"></nz-option>
                      </nz-select>
                    </ng-template>
                  </nz-form-item>
                </nz-col>
                <nz-col
                  [nzSpan]="price.value.description === 'Principal' ? 12 : 24"
                  *ngIf="price.value.description !== 'Tolerância'"
                >
                  <nz-form-item>
                    <nz-form-label class="startBlock input-text" [nzNoColon]="true" [nzSm]="24" nzFor="amount">
                      Valor
                    </nz-form-label>
                    <nz-form-control [nzSm]="24" [nzXs]="24" nzErrorTip="Campo obrigatório.">
                      <nz-input-group>
                        <input
                          nz-input
                          type="text"
                          currencyMask
                          placeholder="R$ 10,00"
                          formControlName="amount"
                          id="amount"
                        />
                      </nz-input-group>
                    </nz-form-control>
                  </nz-form-item>
                </nz-col>
              </nz-row>
            </div>
          </div>

          <nz-divider *ngIf="!last"></nz-divider>
        </div>
      </section>
      <nz-row class="add-btn">
        <nz-col nzSpan="16">
          <nz-form-item class="no-margin-bottom">
            <nz-form-control [nzSm]="24" [nzXs]="24" nzErrorTip="Campo obrigatório.">
              <nz-input-group>
                <input
                  nz-input
                  type="text"
                  placeholder="Nome da descrição"
                  formControlName="mainDescription"
                  id="mainDescription"
                />
              </nz-input-group>
            </nz-form-control>
          </nz-form-item>
        </nz-col>
        <nz-col nzSpan="8" class="center">
          <a
            nz-button
            nzType="text"
            (click)="addPrice()"
            [disabled]="formGroup.get('mainDescription').value.length < 3"
          >
            Adicionar
          </a>
        </nz-col>
      </nz-row>
    </form>
  </section>

  <nz-divider></nz-divider>

  <section id="footer">
    <nz-row [nzGutter]="24">
      <nz-col nzSpan="12">
        <app-secondary-button type="RED" [block]="true" class="w-100 center" (click)="closeModal()">
          Cancelar
        </app-secondary-button>
      </nz-col>
      <nz-col nzSpan="12">
        <app-primary-button
          [block]="true"
          class="w-100 center"
          [disabled]="formGroup.invalid || loading"
          [loading]="loading"
          (click)="updatePriceTable()"
        >
          Alterar preço
        </app-primary-button>
      </nz-col>
    </nz-row>
  </section>
</main>
