export const baseProdEnvironment = {
  production: true,
  appDomain: 'https://checkout.gen.com.br',
  pwaDomain: 'https://store.gen.com.br',
  couponDomain: 'https://cupom.gen.com.br/datails/',
  oldPwaDomain: 'https:/pwa.gen.com.br',
  firebase: {
    apiKey: 'AIzaSyAOt1g4ykujg7_TVEAzKYko-enM4z8SpWA',
    authDomain: 'genpag-app.firebaseapp.com',
    projectId: 'genpag-app',
    storageBucket: 'genpag-app.appspot.com',
    messagingSenderId: '1065194326637',
    appId: '1:1065194326637:web:650f00e6cdb73514e80d64',
    measurementId: 'G-9GTZLYPYYZ',
    databaseURL: 'https://genpag-app.firebaseio.com',
  },
  marketplaceId: 'f081545c-e8b2-4798-887a-f66c61088eda',
  campaignSellerIds: ['619b65c8-f1bb-4658-9270-ac71f6065b27', 'a157b495-3253-43d1-b391-d1a5a7446c21'],
  RECAPTCHA_V3_KEY: '6Lc0vVokAAAAAMZDIKhrbKupvpNx09qcWmFjXYCZ',
  CRYPTO_PRIVATE_KEY: '3e800e38c3414c2a8c89028241bb07f7',
  synapsesDomain: 'https://synapses.gen.com.br',
  parkingApi: 'https://api.gen.shop',
  stripePublicableToken:
    'pk_live_51MbngXJfxbU5ybOwY3280JqmlTokWPNzAfv1o10GzWXOLgFijKVvA3urMOFAsyCPeQiNmTlgoY1IsLAFsgNyuj4L00ReZAz8Dn',
};
