import { NzMessageService } from 'ng-zorro-antd/message';
import { SellerModel } from './../../../../models/sellers/sellers.model';
import { StateManagementService } from './../../../../state-management/state-management.service';
import { PixService } from './../../../../services/pix/pix.service';
import { NzModalRef } from 'ng-zorro-antd/modal';
import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';

@Component({
  selector: 'app-qr-code-generate',
  templateUrl: './qr-code-generate.component.html',
  styleUrls: ['./qr-code-generate.component.scss'],
})
export class QrCodeGenerateComponent implements OnInit {
  @Input() public key: string;
  @Input() public type: string;
  public loadingButton: boolean = false;

  @Output() public generateButton: EventEmitter<{ path: string; qrCode?: string }> = new EventEmitter<{
    path: string;
    qrCode?: string;
  }>();

  private seller: SellerModel;

  constructor(
    public modalRef: NzModalRef,
    private $pix: PixService,
    private $notification: StateManagementService,
    private $message: NzMessageService
  ) {}

  ngOnInit(): void {
    this.getSeller();
  }

  private getSeller(): void {
    this.$notification.sellers.subscribe((res) => {
      if (res) {
        this.seller = res;
      }
    });
  }

  public generateQrCode(): void {
    this.loadingButton = true;
    this.$pix.createStaticPixCode(0, '', this.key, '', this.seller?.id).subscribe({
      next: (res) => {
        this.$message.success('QR Code criado com sucesso.');
        this.generateButton.emit({ path: 'qr-code-confirmation', qrCode: res.data.createStaticPixCode });
        this.loadingButton = false;
      },
      error: (err) => {
        this.loadingButton = false;
        this.$message.error('Falha ao gerar QR Code. Tente novamente.');
        throw new Error(err);
      },
    });
  }
}
