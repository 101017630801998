import { NzModalRef } from 'ng-zorro-antd/modal';
import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-qr-code-confirmation',
  templateUrl: './qr-code-confirmation.component.html',
  styleUrls: ['./qr-code-confirmation.component.scss'],
})
export class QrCodeConfirmationComponent {
  @Input() public key: string;
  @Input() public type: string;
  @Input() public qrCode: string;

  @Output() public confirmButton: EventEmitter<{ path: string }> = new EventEmitter<{ path: string }>();

  constructor(public modalRef: NzModalRef) {}

  public confirm(): void {
    this.confirmButton.emit({ path: 'qr-code-success' });
  }
}
