import { Subject, takeUntil } from 'rxjs';
import { NzModalRef } from 'ng-zorro-antd/modal';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';

import { ErrorService } from '../../../services/error/error.service';
import { AlertService } from '../../../services/alert/alert.service';
import { ParkingService } from '../../../services/parking/parking.service';
import { PriceTableModel } from '../../../models/price-table/price-table.model';
import { ParkingPriceModel } from '../../../models/price-table/parking-price.model';

@Component({
  selector: 'app-modal-update-price-table',
  templateUrl: './modal-update-price-table.component.html',
  styleUrls: ['./modal-update-price-table.component.scss'],
})
export class ModalUpdatePriceTableComponent implements OnInit, OnDestroy {
  @Input() table: PriceTableModel;

  public formGroup!: FormGroup;
  public loading: boolean = false;

  private destroy$ = new Subject();

  constructor(
    private $error: ErrorService,
    private $alert: AlertService,
    private $parking: ParkingService,
    private readonly fb: FormBuilder,
    private readonly $modalRef: NzModalRef
  ) {}

  public ngOnInit(): void {
    this.createForm();

    if (this.table?.mallId) {
      const parkingPriceTable: Array<ParkingPriceModel> = this.table.parkingPriceTable
        .map((o) => ({
          ...o,
          order:
            o.description === 'Principal'
              ? 0
              : o.description === 'Adicional'
              ? 1
              : o.description === 'Tolerância'
              ? 2
              : Math.floor(Math.random() * (19 - 3)) + 3, // Random number between 3 and 19
        }))
        .sort((x, y) => Number(x.order) - Number(y.order));

      parkingPriceTable.forEach((price) => {
        this.pricesControl().push(
          this.fb.group({
            id: new FormControl<string>(price.id),
            amount: new FormControl<string>(Number(price.amount / 100).toString(), [Validators.required]),
            description: new FormControl<string>(price.description),
            time: new FormControl<number>(
              price.time ? (price.time < 3600 ? Number(price.time / 60) : Number(price.time / 3600)) : 0
            ),
            timeType: new FormControl<string>(price.time < 3600 ? 'MINUTE' : 'HOUR'),
          })
        );
      });
    }
  }

  public ngOnDestroy(): void {
    this.destroy$.next(1);
    this.destroy$.complete();
  }

  private createForm(): void {
    this.formGroup = this.fb.group({
      mainDescription: new FormControl<string>(''),
      prices: this.fb.array([]),
    });
  }

  public updatePriceTable(): void {
    this.loading = true;

    const payload: ParkingPriceModel = this.formGroup.get('prices').value.map((price: any) => ({
      id: price.id ?? undefined,
      amount: price.amount ? Math.round(price.amount * 100) : null,
      description: price.description,
      time: price.time
        ? price.timeType === 'MINUTE'
          ? Math.round(price.time * 60)
          : Math.round(price.time * 3600)
        : 0,
    }));

    this.$parking
      .updateParkingTable(String(this.table?.mallId), payload)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (res) => {
          if (res.data?.updateParkingTable) {
            this.$alert.setAlertInfo('SUCCESS', 'Tabela de preços atualizada com sucesso!');
            this.loading = false;
            this.closeModal(true);
          }
        },
        error: (error) => {
          this.loading = false;
          this.$error.errorHandling(error, 'Não foi possível atualizar os preços. Tente novamente.');
        },
      });
  }

  public closeModal(response?: boolean): void {
    this.$modalRef.close(!!response);
  }

  public pricesControl(): FormArray {
    return this.formGroup.controls['prices'] as FormArray;
  }

  public addPrice(): void {
    const mainDescription = this.formGroup.get('mainDescription').value;
    const prices = this.pricesControl();

    prices.push(
      this.fb.group({
        amount: new FormControl<string>('0', [Validators.required]),
        description: new FormControl<string>(mainDescription),
        time: new FormControl<number>(0),
        timeType: new FormControl<string>('MINUTE'),
      })
    );

    this.formGroup.get('mainDescription').setValue('');
  }

  public removePrice(index: number): void {
    const prices = this.pricesControl();

    prices.removeAt(index);
  }
}
