<section class="incorrect_image_modal">
  <div class="header between">
    <h1>{{ title }}</h1>
    <span nz-icon nzType="close" nzTheme="outline" class="close-icon" (click)="modalRef.close()"></span>
  </div>
  <nz-divider></nz-divider>
  <p>{{ message }}</p>
  <nz-divider></nz-divider>
  <div class="end">
    <app-secondary-button (click)="modalRef.close()">Cancelar</app-secondary-button>
    <app-primary-button (click)="modalRef.close('retry')">Carregar nova imagem</app-primary-button>
  </div>
</section>
