import { gql } from 'apollo-angular';

const CREATE_PLAN_MUTATION = gql`
  mutation CreatePlan($params: CreatePlanParams) {
    createPlan(params: $params) {
      id
    }
  }
`;

const UPDATE_PLAN_MUTATION = gql`
  mutation UpdatePlan($id: UUID4, $params: UpdatePlanParams) {
    updatePlan(id: $id, params: $params) {
      id
    }
  }
`;

const DELETE_PLAN_MUTATION = gql`
  mutation DeletePlan($id: UUID4) {
    deletePlan(id: $id) {
      id
    }
  }
`;

export { CREATE_PLAN_MUTATION, UPDATE_PLAN_MUTATION, DELETE_PLAN_MUTATION };
