<section class="create_pix_key_success_modal">
  <span nz-icon nzType="check-circle" nzTheme="outline"></span>
  <h2 class="colorPrimary">Chave ativada</h2>
  <p>Você pode receber um Pix compartilhando essa chave.</p>
  <h3>{{ key }}</h3>
  <app-primary-button routerLink="/internal/pix/pix-keys/my-keys" (click)="modalRef.close()"
    >Ver Minhas Chaves</app-primary-button
  >
  <app-secondary-button (click)="copyKey()">Copiar Chave</app-secondary-button>
</section>

<ng-template #customToaster>
  <div class="customToaster">
    <span nz-icon nzType="exclamation-circle" class="exclamation"></span>
    <div class="content">
      <h4>Chave Pix Copiada</h4>
      <p>
        Você copiou a Chave aleatória <b>{{ selectedKey }}</b>
      </p>
    </div>
    <span nz-icon nzType="close" (click)="removeToaster()" class="close"></span>
  </div>
</ng-template>
