<section>
  <div class="between method-card">
    <div class="d-flex">
      <ng-container [ngSwitch]="param?.creditCard?.brand?.toLowerCase()">
        <img
          class="img-brand"
          src="../../../assets/images/logos_mastercard.svg"
          alt="Mastercard Icon"
          *ngSwitchCase="'mastercard'"
        />
        <img class="img-brand" src="../../../assets/images/logos_visa.svg" alt="Visa Icon" *ngSwitchCase="'visa'" />
        <img class="img-brand" src="../../../assets/images/logos_elo.svg" alt="Visa Icon" *ngSwitchCase="'elo'" />
        <img
          class="img-brand"
          src="../../../assets/images/logos_amex.svg"
          alt="Visa Icon"
          *ngSwitchCase="'american express'"
        />
      </ng-container>

      <div class="inline m-l-1">
        <span class="card-brand"> {{ param?.creditCard?.brand?.toUpperCase() }} Crédito </span>
        <span class="card-number">
          **** {{ param?.creditCard?.last4 || param?.creditCard?.cardNumber?.slice(-4) }}
        </span>
      </div>
    </div>
  </div>

  <div class="center centerBlock">
    <form [formGroup]="form">
      <nz-form-item class="centerBlock">
        <p class="label" nzFor="cvc">Insira o CVV do cartão selecionado</p>
        <nz-form-control [nzSm]="24" [nzXs]="24" nzHasFeedback nzErrorTip="CVV inválido">
          <input nz-input type="tel" class="cvv" placeholder="CVV" mask="000" formControlName="cvv" />
        </nz-form-control>
      </nz-form-item>
    </form>
  </div>

  <div class="inline w-100">
    <button
      nz-button
      class="w-100 btn-confirm-primary confirm"
      nzSize="large"
      [disabled]="form.invalid"
      (click)="submit()"
      nzType="primary"
    >
      Confirmar
    </button>
  </div>
</section>
