import { gql } from 'apollo-angular';

const CREATE_BLACKLIST_ITEM_MUTATION = gql`
  mutation CreateBlacklistItem($cnpj: String, $cpf: String, $email: String, $phone: String, $source: String) {
    createBlacklistItem(params: { cnpj: $cnpj, cpf: $cpf, email: $email, phone: $phone, source: $source }) {
      id
      cnpj
      cpf
      email
      phone
      source
    }
  }
`;

const CREATE_BLACKLIST_MUTATION = gql`
  mutation CreateBlacklist(
    $name: String
    $cnpj: String
    $cpf: String
    $email: String
    $phone: String
    $source: String
    $reason: String
    $type: BlacklistType
  ) {
    createBlacklist(
      params: {
        name: $name
        reason: $reason
        cnpj: $cnpj
        cpf: $cpf
        email: $email
        phone: $phone
        source: $source
        type: $type
      }
    ) {
      id
    }
  }
`;

const UPDATE_BLACKLIST_MUTATION = gql`
  mutation UpdateBlacklist(
    $id: String!
    $name: String
    $cnpj: String
    $cpf: String
    $email: String
    $phone: String
    $source: String
    $reason: String
    $type: BlacklistType
  ) {
    updateBlacklist(
      id: $id
      params: {
        name: $name
        reason: $reason
        cnpj: $cnpj
        cpf: $cpf
        email: $email
        phone: $phone
        source: $source
        type: $type
      }
    ) {
      id
    }
  }
`;

const UPDATE_BLACKLIST_ITEM_MUTATION = gql`
  mutation UpdateBlacklistItem(
    $id: UUID4!
    $cnpj: String
    $cpf: String
    $email: String
    $phone: String
    $source: String
  ) {
    updateBlacklistItem(id: $id, params: { cnpj: $cnpj, cpf: $cpf, email: $email, phone: $phone, source: $source }) {
      id
      cnpj
      cpf
      email
      phone
      source
    }
  }
`;

export {
  CREATE_BLACKLIST_ITEM_MUTATION,
  UPDATE_BLACKLIST_ITEM_MUTATION,
  CREATE_BLACKLIST_MUTATION,
  UPDATE_BLACKLIST_MUTATION,
};
