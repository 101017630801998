import { gql } from 'apollo-angular';

const UPDATE_WEBHOOK_MUTATION = gql`
  mutation UpdateWebhook($id: String!, $params: WebhookParamsInput!) {
    updateWebhook(id: $id, params: $params) {
      id
    }
  }
`;

const CREATE_WEBHOOK_MUTATION = gql`
  mutation CreateWebhook($params: WebhookParamsInput!) {
    createWebhook(params: $params) {
      id
    }
  }
`;

const DELETE_WEBHOOK_MUTATION = gql`
  mutation deleteWebhook($id: String!) {
    deleteWebhook(id: $id) {
      id
    }
  }
`;

const RETRY_NOTIFICATION_MUTATION = gql`
  mutation RetryNotification($id: UUID4!) {
    retryNotification(id: $id) {
      id
    }
  }
`;

export { CREATE_WEBHOOK_MUTATION, UPDATE_WEBHOOK_MUTATION, DELETE_WEBHOOK_MUTATION, RETRY_NOTIFICATION_MUTATION };
