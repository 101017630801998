import { gql } from 'apollo-angular';

const UPDATE_APPLICATION_MUTATION = gql`
  mutation UpdateApplication($id: String!, $params: ApplicationParamsInput!) {
    updateApplication(id: $id, params: $params) {
      id
    }
  }
`;

const CREATE_APPLICATION_MUTATION = gql`
  mutation CreateApplication($params: ApplicationParamsInput!) {
    createApplication(params: $params) {
      id
    }
  }
`;

export { CREATE_APPLICATION_MUTATION, UPDATE_APPLICATION_MUTATION };
