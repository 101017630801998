export function getTimeZone(): string {
  const zones: any = {
    'America/Noronha': 'Etc/GMT+4',
    'America/Sao_Paulo': 'Etc/GMT+3',
    'America/Santarem': 'Etc/GMT+6',
    'America/Araguaina': 'Etc/GMT+6',
    'America/Bahia': 'Etc/GMT+6',
    'America/Belem': 'Etc/GMT+6',
    'America/Boa_Vista': 'Etc/GMT+6',
    'America/Fortaleza': 'Etc/GMT+6',
    'America/Maceio': 'Etc/GMT+6',
    'America/Recife': 'Etc/GMT+6',
    'America/Campo_Grande': 'Etc/GMT+8',
    'America/Cuiaba': 'Etc/GMT+8',
    'America/Manaus': 'Etc/GMT+8',
    'America/Porto_Velho': 'Etc/GMT+8',
    'America/Rio_Branco': 'Etc/GMT+10',
    'America/Eirunepe': 'Etc/GMT+10',
  };

  return zones[Intl.DateTimeFormat().resolvedOptions().timeZone] ?? 'Etc/GMT+6';
}

export function getTimeZone3(): string {
  const zones: any = {
    'America/Noronha': 'Etc/GMT+2',
    'America/Sao_Paulo': 'Etc/GMT+3',
    'America/Santarem': 'Etc/GMT+3',
    'America/Araguaina': 'Etc/GMT+3',
    'America/Bahia': 'Etc/GMT+3',
    'America/Belem': 'Etc/GMT+3',
    'America/Boa_Vista': 'Etc/GMT+3',
    'America/Fortaleza': 'Etc/GMT+3',
    'America/Maceio': 'Etc/GMT+3',
    'America/Recife': 'Etc/GMT+3',
    'America/Campo_Grande': 'Etc/GMT+4',
    'America/Cuiaba': 'Etc/GMT+4',
    'America/Manaus': 'Etc/GMT+4',
    'America/Porto_Velho': 'Etc/GMT+4',
    'America/Rio_Branco': 'Etc/GMT+5',
    'America/Eirunepe': 'Etc/GMT+5',
  };

  return zones[Intl.DateTimeFormat().resolvedOptions().timeZone] ?? 'Etc/GMT+3';
}

export function getTimeZone2(): string {
  const zones: any = {
    'America/Noronha': '+02:00',
    'America/Sao_Paulo': '+03:00',
    'America/Santarem': '+03:00',
    'America/Araguaina': '+03:00',
    'America/Bahia': '+03:00',
    'America/Belem': '+03:00',
    'America/Boa_Vista': '+03:00',
    'America/Fortaleza': '+03:00',
    'America/Maceio': '+03:00',
    'America/Recife': '+03:00',
    'America/Campo_Grande': '+04:00',
    'America/Cuiaba': '+04:00',
    'America/Manaus': '+04:00',
    'America/Porto_Velho': '+04:00',
    'America/Rio_Branco': '+05:00',
    'America/Eirunepe': '+05:00',
  };

  return zones[Intl.DateTimeFormat().resolvedOptions().timeZone] ?? 'Etc/GMT+3';
}

export function parseUTCDate(UTCDate: string): string {
  const expiration = new Date(UTCDate);
  const day = expiration.getUTCDate().toString().padStart(2, '0');
  const month = (expiration.getUTCMonth() + 1).toString().padStart(2, '0');
  const year = expiration.getUTCFullYear();
  return `${day}/${month}/${year}`;
}

export function parseUTCTime(UTCDate: string): string {
  const expiration = new Date(UTCDate);
  const minutes = expiration.getUTCMinutes().toString().padStart(2, '0');
  const hours = expiration.getUTCHours().toString().padStart(2, '0');
  return `${hours}:${minutes}`;
}
