import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-modal-no-value-qr-code',
  templateUrl: './modal-no-value-qr-code.component.html',
  styleUrls: ['./modal-no-value-qr-code.component.scss'],
})
export class ModalNoValueQrCodeComponent {
  @Input() public key: string;
  @Input() public type: string;

  public modalActive: string = 'qr-code-generate';
  public qrCode: string;

  public handleModalActive(event: { path: string; qrCode?: string }): void {
    this.modalActive = event.path;
    if (event.qrCode) {
      this.qrCode = event.qrCode;
    }
  }
}
