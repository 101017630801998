import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';

import { RoleEnum } from '../../../enums/roles';
import { trimData } from '../../../utils/utils';
import { CpfValidator } from '../../../validators/cpfValidator';
import { EmailValidator } from '../../../validators/emailValidator';
import { UserClaimsModel } from '../../../models/user-claims/user-claims.model';
import { NzModalRef } from 'ng-zorro-antd/modal';

@Component({
  selector: 'app-drawer-create-user',
  templateUrl: './drawer-create-user.component.html',
  styleUrls: ['./drawer-create-user.component.scss'],
})
export class DrawerCreateUserComponent implements OnInit {
  @Input() value: any;

  public user: UserClaimsModel = new UserClaimsModel();
  public formDescription!: FormGroup;
  public loading: boolean = false;
  public roles: Array<{ value: RoleEnum; key: string }> = [
    { value: RoleEnum.ADMIN, key: 'ADMIN' },
    { value: RoleEnum.MARKETING, key: 'MARKETING' },
    { value: RoleEnum.FINANCE, key: 'FINANCE' },
    { value: RoleEnum.CUSTOMER_EXPERIENCE, key: 'CUSTOMER_EXPERIENCE' },
    { value: RoleEnum.COMPLIANCE, key: 'COMPLIANCE' },
    { value: RoleEnum.MANAGER, key: 'MANAGER' },
    { value: RoleEnum.SALE, key: 'SALE' },
  ];
  public title: string = 'Convidar colaborador';

  constructor(private readonly fb: FormBuilder, public $modalRef: NzModalRef) {}

  public ngOnInit(): void {
    this.createForm();
    this.setInfo();
  }

  private setInfo(): void {
    if (this.value) {
      this.title = 'Editar colaborador';
      this.getSellerUserById();
    }
  }

  private createForm(): void {
    this.formDescription = this.fb.group({
      id: new FormControl(''),
      active: new FormControl(true),
      role: new FormControl('', [Validators.required]),
      cpf: new FormControl('', [Validators.required, CpfValidator.isValid()]),
      name: new FormControl('', [Validators.required]),
      phone: new FormControl('', [Validators.required]),
      email: new FormControl('', [Validators.required, EmailValidator.isValid()]),
    });
  }

  public getSellerUserById(): void {
    this.formDescription.get('cpf').disable();

    this.formDescription.patchValue({
      id: this.value?.id,
      active: this.value?.active,
      role: this.value?.role,
      cpf: this.value?.document,
      name: this.value?.name,
      phone: this.value?.phone?.substring(3),
      email: this.value?.email,
    });
  }

  public createEditUser(): void {
    this.$modalRef.close(trimData(this.formDescription.value));
  }

  public handleRole(role: string): void {
    this.formDescription.get('role').setValue(role);
  }
}
