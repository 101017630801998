<section>
  <main>
    <div class="company-description">
      <div class="title">
        <h3>Nome da empresa</h3>

        <app-primary-button icon="user" (click)="showCreateUserDrawer($event)">criar usuário</app-primary-button>
      </div>

      <div class="company-info m-t-2">
        <div class="container">
          <div class="content">
            <p>Status</p>
            <span><app-status-tag [type]="'REGISTER'" gender="male"></app-status-tag></span>
          </div>

          <div class="content">
            <p>Criado em</p>
            <span>04/01/2023</span>
          </div>

          <div class="content">
            <p>ID</p>
            <span>123456789</span>
          </div>
        </div>
      </div>

      <div class="contact-info m-t-1">
        <h3>Dados do contato</h3>

        <div class="container">
          <div class="content">
            <p>CPF / CNPJ</p>
            <span>123.456.78/9</span>
          </div>

          <div class="content">
            <p>Telefone</p>
            <span>+55 (11) 9 1234-5678</span>
          </div>

          <div class="content">
            <p>E-mail</p>
            <span>email&#64;email.com</span>
          </div>

          <div class="content">
            <p>Endereço</p>
            <span>Rua alguma, 222 </span>
          </div>
        </div>
      </div>
    </div>

    <hr />

    <div class="list-companys">
      <div class="title">
        <app-secondary-button icon="warning">Rejeitar</app-secondary-button>

        <div class="approve-buttons">
          <app-primary-button icon="user">Aprovar empresa</app-primary-button>
          <app-primary-button icon="user">Aprovar NF </app-primary-button>
        </div>
      </div>

      <div class="table m-t-2">
        <app-main-table
          [listOfHeaders]="listOfHeadersColumn"
          [listOfData]="listOfData"
          tableType="PENDING-COMPANY-DETAILS"
          [settingValue]="settingValue"
        >
        </app-main-table>
      </div>
    </div>
  </main>
</section>
