<header class="modal-header" *nzModalTitle>
  <h3 class="title-category">{{ title }}</h3>
  <span class="example-spacer"></span>
  <img src="../../assets/images/close.svg" (click)="$modalRef.close()" class="pointer-cursor" />
</header>

<section class="modal-body filter_modal">
  <form [formGroup]="form" class="wrapper-fields">
    <div class="columns" *ngFor="let column of fields">
      <div *ngFor="let field of column" class="d-block m-b-1">
        <nz-form-item class="d-flex form-wrapper" *ngIf="field.fieldType === 'text'">
          <label class="smaller-light" *ngIf="field.label">
            {{ field.label }}
            <span
              nz-tooltip
              *ngIf="field.tooltip"
              [nzTooltipTitle]="field.tooltip"
              [nzTooltipPlacement]="field.tooltipPlacement || 'top'"
              nzType="question-circle"
              nzTheme="outline"
            ></span>
          </label>
          <nz-form-control>
            <nz-input-group [nzSuffix]="closeIcon">
              <input
                nz-input
                type="text"
                [placeholder]="field.placeholder || ''"
                [formControlName]="field.formControlName"
              />
            </nz-input-group>
          </nz-form-control>
          <ng-template #closeIcon>
            <span
              nz-icon
              nzType="close"
              class="pointer-cursor closeIcon"
              (click)="form.get(field.formControlName).patchValue('')"
            ></span>
          </ng-template>
        </nz-form-item>

        <nz-form-item class="d-flex form-wrapper" *ngIf="field.fieldType === 'cpf'">
          <label class="smaller-light" *ngIf="field.label">
            {{ field.label }}
            <span
              nz-tooltip
              *ngIf="field.tooltip"
              [nzTooltipTitle]="field.tooltip"
              [nzTooltipPlacement]="field.tooltipPlacement || 'top'"
              nzType="question-circle"
              nzTheme="outline"
            ></span>
          </label>
          <nz-form-control>
            <input
              nz-input
              type="text"
              mask="000.000.000-00"
              [placeholder]="field.placeholder || ''"
              [formControlName]="field.formControlName"
            />
          </nz-form-control>
        </nz-form-item>

        <nz-form-item class="d-flex form-wrapper" *ngIf="field.fieldType === 'cnpj'">
          <label class="smaller-light" *ngIf="field.label">
            {{ field.label }}
            <span
              nz-tooltip
              *ngIf="field.tooltip"
              [nzTooltipTitle]="field.tooltip"
              [nzTooltipPlacement]="field.tooltipPlacement || 'top'"
              nzType="question-circle"
              nzTheme="outline"
            ></span>
          </label>
          <nz-form-control>
            <input
              nz-input
              type="text"
              mask="00.000.000/0000-00"
              [placeholder]="field.placeholder || ''"
              [formControlName]="field.formControlName"
            />
          </nz-form-control>
        </nz-form-item>

        <nz-form-item class="d-flex form-wrapper" *ngIf="field.fieldType === 'document'">
          <label class="smaller-light" *ngIf="field.label"
            >fields
            {{ field.label }}
            <span
              nz-tooltip
              *ngIf="field.tooltip"
              [nzTooltipTitle]="field.tooltip"
              [nzTooltipPlacement]="field.tooltipPlacement || 'top'"
              nzType="question-circle"
              nzTheme="outline"
            ></span>
          </label>
          <nz-form-control>
            <input
              nz-input
              type="text"
              mask="000.000.000-00||00.000.000/0000-00"
              [placeholder]="field.placeholder || ''"
              [formControlName]="field.formControlName"
            />
          </nz-form-control>
        </nz-form-item>

        <nz-form-item class="d-flex form-wrapper" *ngIf="field.fieldType === 'select'">
          <label class="smaller-light" *ngIf="field.label">
            {{ field.label }}
            <span
              nz-tooltip
              *ngIf="field.tooltip"
              [nzTooltipTitle]="field.tooltip"
              [nzTooltipPlacement]="field.tooltipPlacement || 'top'"
              nzType="question-circle"
              nzTheme="outline"
            ></span>
          </label>
          <nz-form-control>
            <nz-select
              [nzOptions]="field.selectOptions"
              [nzPlaceHolder]="field.placeholder"
              [formControlName]="field.formControlName"
            >
            </nz-select>
          </nz-form-control>
        </nz-form-item>

        <nz-form-item class="d-flex form-wrapper" *ngIf="field.fieldType === 'date'">
          <label class="smaller-light" *ngIf="field.label">
            {{ field.label }}
            <span
              nz-tooltip
              *ngIf="field.tooltip"
              [nzTooltipTitle]="field.tooltip"
              [nzTooltipPlacement]="field.tooltipPlacement || 'top'"
              nzType="question-circle"
              nzTheme="outline"
            ></span>
          </label>
          <nz-form-control>
            <nz-range-picker [nzFormat]="dateFormat" [formControlName]="field.formControlName"></nz-range-picker>
          </nz-form-control>
        </nz-form-item>

        <nz-form-item class="d-flex form-wrapper" *ngIf="field.fieldType === 'numberRange'">
          <label class="smaller-light" *ngIf="field.label">
            {{ field.label }}
            <span
              nz-tooltip
              *ngIf="field.tooltip"
              [nzTooltipTitle]="field.tooltip"
              [nzTooltipPlacement]="field.tooltipPlacement || 'top'"
              nzType="question-circle"
              nzTheme="outline"
            ></span>
          </label>
          <nz-form-control>
            <app-input-number-range [formControlName]="field.formControlName"></app-input-number-range>
          </nz-form-control>
        </nz-form-item>
      </div>
    </div>
  </form>
</section>

<footer class="modal-footer" *nzModalFooter>
  <div class="buttons">
    <app-secondary-button (click)="$modalRef.close({})">Limpar filtros</app-secondary-button>
    <app-primary-button (click)="$modalRef.close(form.value)" class="body-txt-bold">Aplicar filtros</app-primary-button>
  </div>
</footer>
