<section>
  <div class="body-compliance">
    <p>
      Na Genpag, privacidade e segurança são prioridades e nos comprometemos com a transparência do tratamento de dados
      pessoais dos nossos usuários/clientes. Por isso, esta presente Política de Privacidade estabelece como é feita a
      coleta, uso e transferência de informações de clientes ou outras pessoas que acessam ou usam nosso site.
    </p>
    <p>
      Ao utilizar nossos serviços, você entende que coletaremos e usaremos suas informações pessoais nas formas
      descritas nesta Política, sob as normas de Proteção de Dados (LGPD, Lei Federal 13.709/2018), das disposições
      consumeristas da Lei Federal 8078/1990 e as demais normas do ordenamento jurídico brasileiro aplicáveis.
    </p>
    <p>
      Dessa forma, a Genpag, doravante denominada simplesmente como “(GENPAG GESTAO E SERVICOS S.A. )”, inscrita no
      CNPJ/MF sob o nº 37.606.428/0001-92, no papel de Controladora de Dados, obriga-se ao disposto na presente Política
      de Privacidade.
    </p>

    <h3>1. Onde se aplica nossa política?</h3>
    <p>
      Esta Política de Privacidade é aplicável a todos os usuários da Genpag no Brasil. Nós coletamos os seus dados
      pessoais sempre que você:
    </p>
    <ul>
      <li>Usar o aplicativo do Genpag para smartphone;</li>
      <li>
        Contratar ou usar qualquer um dos nossos produtos ou serviços, seja fazendo pagamentos ou transações com a sua
        conta do Genpag;
      </li>
      <li>Navegar no website do Genpag - <a href="https://gen.com.br">https://gen.com.br</a>;</li>
      <li>
        Navegar nos blogs do Genpag - <a href="https://blog.gen.com.br/">https://blog.gen.com.br/</a> e
        <a href="https://building.gen.com.br/">https://building.gen.com.br/</a>;
      </li>
      <li>
        Se inscrever na newsletter da Genpag -
        <a href="https://blog.gen.com.br/assinar-newsletter/">https://blog.gen.com.br/assinar-newsletter/</a>;
      </li>
      <li>Entrar em contato conosco pelos nossos canais de atendimento;</li>
      <li>Participar de pesquisas ou promoções realizadas pela Genpag.</li>
    </ul>
    <p>Esta Política de Privacidade se aplica especificamente aos:</p>
    <ul>
      <li>
        <strong>Usuários da Genpag:</strong> pessoas naturais que efetivamente contratem, utilizem ou acessem um ou mais
        serviços ou produtos do Genpag (seja para você mesmo ou para sua pessoa jurídica);
      </li>
      <li>
        <strong>Prospectos da Genpag:</strong> pessoas naturais prospectadas pelo Genpag ou que já tenham solicitado a
        contratação de um dos serviços ou produtos, mas que não tenham se tornado um cliente da Genpag por qualquer
        motivo.
      </li>
    </ul>
    <p>
      Para os candidatos a vagas de emprego no Genpag, a Política de Privacidade pode ser encontrada no site <a
        href="https://apply.workable.com/sejagen/#jobs"
        >Carreiras</a
      >.
    </p>
    <p>
      Para os membros da comunidade da Genpag, a Política de Privacidade pode ser encontrada no site <a
        href="https://gen.com.br/politica-de-privacidade"
        >Política de Privacidade</a
      >.
    </p>
    <p>
      Esta Política de Privacidade também é aplicável a outras formas de coleta de dados pessoais pelo Genpag, que
      permitem a prestação ou o aprimoramento dos nossos serviços. Por exemplo, podemos coletar informações por meio de
      parceiros ou relativas às nossas tecnologias. Todas as formas de coleta e os usos dos seus dados pessoais estão
      descritos nesta Política de Privacidade.
    </p>

    <h3>2. Quais dados coletamos sobre você e para qual finalidade?</h3>
    <p>
      Nosso site coleta e utiliza alguns dados pessoais seus, de forma a viabilizar a prestação de serviços e aprimorar
      a experiência de uso.
    </p>
    <h4>2.1. Dados pessoais fornecidos pelo titular</h4>
    <ul>
      <li>
        Dados cadastrais, tais como: nome, documentos de identificação, nacionalidade, endereço, data de nascimento,
        filiação, gênero, entre outros;
      </li>
      <li>Dados de contato, como telefone e e-mail;</li>
      <li>Dados biométricos, tais como a fotografia do seu documento de identificação e do seu rosto.</li>
    </ul>
    <h4>2.2. Dados pessoais coletados automaticamente</h4>
    <ul>
      <li>
        Dados cadastrais, tais como: nome, filiação, data de nascimento, CPF, número de telefone, endereço, entre
        outros;
      </li>
      <li>
        Dados sobre restrições financeiras, tais como: negativações, valores devidos, datas de vencimento, quantidades
        de consultas, entre outros;
      </li>
      <li>Informações sobre histórico de crédito;</li>
      <li>Score gerado por bureaus de crédito;</li>
      <li>Informações de dívidas a vencer ou vencidas, coobrigações e garantias;</li>
      <li>
        Se você faz parte de alguma lista de Pessoa Politicamente Exposta (PPE) ou lista de restrição (como, OFAC, CSNU
        e outras listas internacionais);
      </li>
      <li>
        Informações constantes da base de dados do Sistema de Informações de Crédito (SCR), mediante consentimento.
      </li>
    </ul>
    <h4>2.3. Dados de navegação do seu dispositivo</h4>
    <ul>
      <li>Endereço IP do dispositivo móvel utilizado para acessar os serviços ou produtos do Genpag;</li>
      <li>Interações realizadas e perfil de uso de sites, blogs e aplicativo do Genpag;</li>
      <li>Dados técnicos, como informações de URL, de conexão de rede, do provedor, e do dispositivo;</li>
      <li>Cookies;</li>
      <li>Atributos do dispositivo, tais como ID, sistema operacional, navegador e modelo;</li>
      <li>Dados de geolocalização do dispositivo caso você autorize a coleta a partir do seu dispositivo.</li>
    </ul>
    <h4>2.4. Dados pessoais originados do uso de nossos produtos</h4>
    <ul>
      <li>Dados de contratação de nossos produtos e serviços;</li>
      <li>
        Dados de transações e movimentações financeiras na conta, como débitos, saques, pagamentos e transferências,
        incluindo também informações dos remetentes e beneficiários;
      </li>
      <li>
        Dados de contratação de operações de crédito, tais como empréstimo pessoal, negociação de valores devidos em
        razão do uso do cartão de crédito e parcelamento de fatura;
      </li>
      <li>Data de convite para se tornar um Cliente da Genpag;</li>
      <li>Histórico de atendimento ao cliente;</li>
      <li>Acesso à sua lista de contatos, caso você ative voluntariamente essa funcionalidade no nosso aplicativo.</li>
    </ul>
    <h4>2.5. Dados Públicos</h4>
    <ul>
      <li>
        Podemos coletar informações sobre você que estejam disponíveis publicamente ou que foram tornadas públicas por
        você;
      </li>
      <li>Informações sobre menções ou interações com o Genpag;</li>
      <li>
        Depoimentos referentes a Genpag postados em perfis e páginas nas redes sociais, juntamente com seu nome e imagem
        (incluindo fotos de perfil).
      </li>
    </ul>

    <h3>3. Como coletamos os seus dados?</h3>
    <p>
      Nesse sentido, a coleta dos seus dados pessoais ocorre através do uso da plataforma web, nas páginas com domínio
      genpag.com.br ou links vinculados a ela. Como também, dentro da própria aplicação móvel.
    </p>
    <h4>3.1. Consentimento</h4>
    <p>
      É a partir do seu consentimento que tratamos os seus dados pessoais. O consentimento é a manifestação livre,
      informada e inequívoca pela qual você autoriza a Genpag a tratar seus dados.
    </p>
    <p>
      Assim, em consonância com a Lei Geral de Proteção de Dados, seus dados só serão coletados, tratados e armazenados
      mediante prévio e expresso consentimento.
    </p>
    <p>
      O seu consentimento será obtido de forma específica para cada finalidade acima descrita, evidenciando o
      compromisso de transparência e boa-fé da Genpag  para com seus usuários/clientes, seguindo as regulações
      legislativas pertinentes.
    </p>
    <p>
      Ao utilizar os serviços da Genpag e fornecer seus dados pessoais, você está ciente e consentindo com as
      disposições desta Política de Privacidade, além de conhecer seus direitos e como exercê-los.
    </p>
    <p>A qualquer tempo e sem nenhum custo, você poderá revogar seu consentimento.</p>
    <p>
      É importante destacar que a revogação do consentimento para o tratamento dos dados pode implicar a impossibilidade
      da performance adequada de alguma funcionalidade do site que dependa da operação. Tais consequências serão
      informadas previamente.
    </p>

    <h3>4. Quais são os seus direitos?</h3>
    <p>
      A Genpag assegura a seus usuários/clientes seus direitos de titular previstos no artigo 18 da Lei Geral de
      Proteção de Dados. Dessa forma, você pode, de maneira gratuita e a qualquer tempo:
    </p>
    <ul>
      <li>
        <strong>Confirmar a existência de tratamento de dados</strong>, de maneira simplificada ou em formato claro e
        completo;
      </li>
      <li>
        <strong>Acessar seus dados</strong>, podendo solicitá-los em uma cópia legível sob forma impressa ou por meio
        eletrônico, seguro e idôneo;
      </li>
      <li><strong>Corrigir seus dados</strong>, ao solicitar a edição, correção ou atualização destes;</li>
      <li>
        <strong>Limitar seus dados</strong> quando desnecessários, excessivos ou tratados em desconformidade com a
        legislação através da anonimização, bloqueio ou eliminação;
      </li>
      <li>
        <strong>Solicitar a portabilidade de seus dados</strong>, através de um relatório de dados cadastrais que
        a (nome empresarial simplificado) trata a seu respeito;
      </li>
      <li>
        <strong>Eliminar seus dados</strong> tratados a partir de seu consentimento, exceto nos casos previstos em lei;
      </li>
      <li><strong>Revogar seu consentimento</strong>, desautorizando o tratamento de seus dados;</li>
      <li>
        <strong>Informar-se sobre a possibilidade de não fornecer seu consentimento</strong> e sobre as consequências da
        negativa.
      </li>
    </ul>

    <h3>5. Como você pode exercer seus direitos de titular?</h3>
    <p>
      Para exercer seus direitos de titular, você deve entrar em contato com a Ouvidoria através dos seguintes meios
      disponíveis:
    </p>
    <ul>
      <li><a href="mailto:ouvidoria@gen.com.br">ouvidoria&#64;gen.com.br</a></li>
    </ul>
    <p>
      De forma a garantir a sua correta identificação como titular dos dados pessoais objeto da solicitação, é possível
      que solicitemos documentos ou demais comprovações que possam comprovar sua identidade. Nessa hipótese, você será
      informado previamente.
    </p>

    <h3>6. Como e por quanto tempo seus dados serão armazenados?</h3>
    <p>
      Seus dados pessoais coletados pela Genpag serão utilizados e armazenados durante o tempo necessário para a
      prestação do serviço ou para que as finalidades elencadas na presente Política de Privacidade sejam atingidas,
      considerando os direitos dos titulares dos dados e dos controladores.
    </p>
    <p>
      De modo geral, seus dados serão mantidos enquanto a relação contratual entre você e a Genpag perdurar. Findado o
      período de armazenamento dos dados pessoais, estes serão excluídos de nossas bases de dados ou anonimizados,
      ressalvadas as hipóteses legalmente previstas no artigo 16 lei geral de proteção de dados, a saber:
    </p>
    <ol>
      <li>cumprimento de obrigação legal ou regulatória pelo controlador;</li>
      <li>estudo por órgão de pesquisa, garantida, sempre que possível, a anonimização dos dados pessoais;</li>
      <li>
        transferência a terceiro, desde que respeitados os requisitos de tratamento de dados dispostos nesta Lei; ou
      </li>
      <li>uso exclusivo do controlador, vedado seu acesso por terceiro, e desde que anonimizados os dados.</li>
    </ol>
    <p>
      Isto é, informações pessoais sobre você que sejam imprescindíveis para o cumprimento de determinações legais,
      judiciais e administrativas e/ou para o exercício do direito de defesa em processos judiciais e administrativos
      serão mantidas, a despeito da exclusão dos demais dados.
    </p>
    <p>
      O armazenamento de dados coletados pela (nome empresarial simplificado) reflete o nosso compromisso com a
      segurança e privacidade dos seus dados. Empregamos medidas e soluções técnicas de proteção aptas a garantir a
      confidencialidade, integridade e inviolabilidade dos seus dados. Além disso, também contamos com medidas de
      segurança apropriadas aos riscos e com controle de acesso às informações armazenadas.
    </p>

    <h3>7. O que fazemos para manter seus dados seguros?</h3>
    <p>
      Para mantermos suas informações pessoais seguras, usamos ferramentas físicas, eletrônicas e gerenciais orientadas
      para a proteção da sua privacidade.
    </p>
    <p>
      Aplicamos essas ferramentas levando em consideração a natureza dos dados pessoais coletados, o contexto e a
      finalidade do tratamento e os riscos que eventuais violações gerariam para os direitos e liberdades do titular dos
      dados coletados e tratados.
    </p>
    <p>Entre as medidas que adotamos, destacamos as seguintes:</p>
    <ul>
      <li>Apenas pessoas autorizadas têm acesso a seus dados pessoais;</li>
      <li>O acesso a seus dados pessoais é feito somente após o compromisso de confidencialidade;</li>
      <li>Seus dados pessoais são armazenados em ambiente seguro e idôneo.</li>
    </ul>
    <p>
      A Genpag  se compromete a adotar as melhores posturas para evitar incidentes de segurança. Contudo, é necessário
      destacar que nenhuma página virtual é inteiramente segura e livre de riscos. É possível que, apesar de todos os
      nossos protocolos de segurança, problemas de culpa exclusivamente de terceiros ocorram, como ataques cibernéticos
      de hackers, ou também em decorrência da negligência ou imprudência do próprio usuário/cliente.
    </p>
    <p>
      Em caso de incidentes de segurança que possa gerar risco ou dano relevante para você ou qualquer um de nossos
      usuários/clientes, comunicaremos aos afetados e a Autoridade Nacional de Proteção de Dados sobre o ocorrido, em
      consonância com as disposições da Lei Geral de Proteção de Dados.
    </p>

    <h3>8. Com quem seus dados podem ser compartilhados?</h3>
    <p>
      Tendo em vista a preservação de sua privacidade, a Genpag não compartilhará seus dados pessoais com nenhum
      terceiro não autorizado.
    </p>
    <p>Seus dados poderão ser compartilhados com nossos parceiros comerciais.</p>
    <p>
      Estes recebem seus dados apenas na medida do necessário para a prestação dos serviços contratados e nossos
      contratos são orientados pelas normas de proteção de dados do ordenamento jurídico brasileiro.
    </p>
    <p>Todavia, nossos parceiros têm suas próprias Políticas de Privacidade, que podem divergir desta.</p>
    <p>Além disso, também existem outras hipóteses em que seus dados poderão ser compartilhados, que são:</p>
    <ol>
      <li>
        Determinação legal, requerimento, requisição ou ordem judicial, com autoridades judiciais, administrativas ou
        governamentais competentes;
      </li>
      <li>Caso de movimentações societárias, como fusão, aquisição e incorporação, de forma automática;</li>
      <li>Proteção dos direitos da Genpag  em qualquer tipo de conflito, inclusive os de teor judicial.</li>
    </ol>
    <h4>8.1. Transferência internacional de dados</h4>
    <p>
      Alguns dos terceiros com quem compartilhamos seus dados podem ser localizados ou possuir instalações localizadas
      em países estrangeiros. Nessas condições, de toda forma, seus dados pessoais estarão sujeitos à Lei Geral de
      Proteção de Dados e às demais legislações brasileiras de proteção de dados.
    </p>
    <p>
      Nesse sentido, a Genpag se compromete a sempre adotar eficientes padrões de segurança cibernética e de proteção de
      dados, nos melhores esforços de garantir e cumprir as exigências legislativas.
    </p>
    <p>
      Ao concordar com essa Política de Privacidade, você concorda com esse compartilhamento, que se dará conforme as
      finalidades descritas no presente instrumento.
    </p>

    <h3>9. Cookies ou dados de navegação</h3>
    <p>
      A Genpag faz uso de Cookies, que são arquivos de texto enviados pela plataforma ao seu computador e que nele se
      armazenam, que contém informações relacionadas à navegação do site. Em suma, os Cookies são utilizados para
      aprimorar a experiência de uso.
    </p>
    <p>
      Ao acessar nosso site e consentir com o uso de Cookies, você manifesta conhecer e aceitar a utilização de um
      sistema de coleta de dados de navegação com o uso de Cookies em seu dispositivo.
    </p>
    <p>
      Os cookies podem ser utilizados para diversos propósitos, incluindo lembrar-se de você e de suas preferências,
      persistir informações relacionadas a suas atividades no site visitado, ou coletar informações que podem ser usadas
      para oferecer conteúdo de uma forma personalizada. Nossos sites também podem utilizar objetos armazenados
      localmente para fornecer determinado conteúdo, tais como vídeo sob demanda, videoclipes ou animação.
    </p>
    <p>
      Você pode, a qualquer tempo e sem nenhum custo, alterar as permissões, bloquear ou recusar os Cookies. Todavia, a
      revogação do consentimento de determinados Cookies pode inviabilizar o funcionamento correto de alguns recursos da
      plataforma.
    </p>
    <p>
      Para gerenciar os cookies do seu navegador, basta fazê-lo diretamente nas configurações do navegador, na área de
      gestão de Cookies.
    </p>
    <p>
      Você pode ter maiores informações sobre os Cookies que utilizamos e como eles funcionam na nossa Política de
      Cookies, disponível neste link (link para a Política de Cookies).
    </p>

    <h3>10. Alteração desta Política de Privacidade</h3>
    <p>A atual versão da Política de Privacidade foi formulada e atualizada pela última vez em: 14/07/2021.</p>
    <p>
      Reservamos o direito de modificar essa Política de Privacidade a qualquer tempo, principalmente em função da
      adequação a eventuais alterações feitas em nosso site ou em âmbito legislativo.
    </p>
    <p>Recomendamos que você a revise com frequência.</p>
    <p>
      Eventuais alterações entrarão em vigor a partir de sua publicação em nosso site e sempre lhe notificaremos acerca
      das mudanças ocorridas.
    </p>
    <p>Ao utilizar nossos serviços e fornecer seus dados pessoais após tais modificações, você as consente.</p>

    <h3>11. Responsabilidade</h3>
    <p>
      A Genpag prevê a responsabilidade dos agentes que atuam nos processos de tratamento de dados, em conformidade com
      os artigos 42 ao 45 da Lei Geral de Proteção de Dados.
    </p>
    <p>
      Nos comprometemos em manter esta Política de Privacidade atualizada, observando suas disposições e zelando por seu
      cumprimento.
    </p>
    <p>
      Além disso, também assumimos o compromisso de buscar condições técnicas e organizativas seguramente aptas a
      proteger todo o processo de tratamento de dados.
    </p>
    <p>
      Caso a Autoridade Nacional de Proteção de Dados exija a adoção de providências em relação ao tratamento de dados
      realizado pela Genpag, comprometemo-nos a segui-las.
    </p>
    <h4>11.1 Isenção de responsabilidade</h4>
    <p>
      Conforme mencionado no Tópico 6, embora adotemos elevados padrões de segurança a fim de evitar incidentes, não há
      nenhuma página virtual inteiramente livre de riscos. Nesse sentido, a Genpag não se responsabiliza por:
    </p>
    <ol>
      <li>
        Quaisquer consequências decorrentes da negligência, imprudência ou imperícia dos usuários em relação a seus
        dados individuais. Garantimos e nos responsabilizamos apenas pela segurança dos processos de tratamento de dados
        e do cumprimento das finalidades descritas no presente instrumento. Destacamos que a responsabilidade em relação
        à confidencialidade dos dados de acesso é do usuário.
      </li>
      <li>
        Ações maliciosas de terceiros, como ataques de hackers, exceto se comprovada conduta culposa ou deliberada
        da Genpag. Destacamos que em caso de incidentes de segurança que possam gerar risco ou dano relevante para você
        ou qualquer um de nossos usuários/clientes, comunicaremos aos afetados e a Autoridade Nacional de Proteção de
        Dados sobre o ocorrido e cumpriremos as providências necessárias.
      </li>
      <li>
        Inveracidade das informações inseridas pelo usuário/cliente nos registros necessários para a utilização dos
        serviços da Genpag; quaisquer consequências decorrentes de informações falsas ou inseridas de má-fé são de
        inteiramente responsabilidade do usuário/cliente.
      </li>
    </ol>

    <h3>12. Encarregado de Proteção de Dados</h3>
    <p>
      A Genpag disponibiliza no site os meios para que você possa entrar em contato conosco para exercer seus direitos
      de titular.
    </p>
    <p>
      Caso tenha dúvidas sobre esta Política de Privacidade ou sobre os dados pessoais que tratamos, você pode entrar em
      contato.
    </p>
  </div>
</section>
