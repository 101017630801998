import { WhatsappPhoneNumbersFilterModel } from './../../models/campaign/whatsapp-phone-numbers-filter.model';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { Apollo, Query } from 'apollo-angular';
import { AngularFireFunctions } from '@angular/fire/compat/functions';

import { ResponseModel } from '../../models/response/response.model';
import { CampaignModel } from '../../models/campaign/campaign.model';
import { CAMPAIGNS_QUERY, CAMPAIGN_QUERY, WEBHOOK_LOGS_REPORT_QUERY, WHATSAPP_PHONE_NUMBERS_QUERY, WHATSAPP_TEMPLATES_QUERY } from '../../graphql/queries/campaigns.queries';
import {
  CREATE_CAMPAIGNS_MUTATION,
  DELETE_CAMPAIGN_MUTATION,
  DUPLICATE_CAMPAIGN_MUTATION,
  UPDATE_CAMPAIGNS_MUTATION,
} from '../../graphql/mutations/campaign.mutation';
import { FilterCampaignsModel } from '../../models/campaign/campaign-filter.model';
import { PaginationModel } from '../../models/pagination/pagination.model';
import { WhatsappTemplatesFilterModel } from '../../models/campaign/whatsapp-templates-filter.model';
import { CreateCampaignModel } from '../../models/campaign/create-campaign.model';

@Injectable()
export class CampaignService {
  constructor(private $functions: AngularFireFunctions, private readonly $apollo: Apollo) {}

  public getCampaignFilter(data: any): Observable<ResponseModel<any>> {
    return this.$functions.httpsCallable('getMallCampaign')(data);
  }

  public campaigns(filters: FilterCampaignsModel, pagination: PaginationModel): Observable<any> {
    return this.$apollo.watchQuery<Query>({
      query: CAMPAIGNS_QUERY,
      variables: { filters, pagination },
      fetchPolicy: 'network-only',
    }).valueChanges;
  }

  public campaign(id: string): Observable<any> {
    return this.$apollo.watchQuery<Query>({
      query: CAMPAIGN_QUERY,
      variables: { id },
      fetchPolicy: 'network-only',
    }).valueChanges;
  }

  public createCampaign(sellerId: string, params: CreateCampaignModel): Observable<any> {
    return this.$apollo.mutate({
      mutation: CREATE_CAMPAIGNS_MUTATION,
      variables: {sellerId, params},
    });
  }

  public updateCampaign(id: string, sellerId: string, params: CreateCampaignModel): Observable<any> {
    return this.$apollo.mutate({
      mutation: UPDATE_CAMPAIGNS_MUTATION,
      variables: {id, sellerId, params},
    });
  }

  public duplicateCampaign(id: string): Observable<any> {
    return this.$apollo.mutate({
      mutation: DUPLICATE_CAMPAIGN_MUTATION,
      variables: {
        id,
      },
    });
  }

  public deleteCampaign(id: string): Observable<any> {
    return this.$apollo.mutate({
      mutation: DELETE_CAMPAIGN_MUTATION,
      variables: {
        id,
      },
    });
  }

  public whatsAppTemplates(filters?: WhatsappTemplatesFilterModel, pagination?: PaginationModel): Observable<any> {
    return this.$apollo.watchQuery<Query>({
      query: WHATSAPP_TEMPLATES_QUERY,
      variables: { filters, pagination },
      fetchPolicy: 'network-only',
    }).valueChanges;
  }


  public whatsAppNumbers(filters?: WhatsappPhoneNumbersFilterModel, pagination?: PaginationModel): Observable<any> {
    return this.$apollo.watchQuery<Query>({
      query: WHATSAPP_PHONE_NUMBERS_QUERY,
      variables: { filters, pagination },
      fetchPolicy: 'network-only',
    }).valueChanges;
  }

  public generateWebhookLogsReport(sellerId: string, campaignId?: string): Observable<any> {
    return this.$apollo.watchQuery<Query>({
      query: WEBHOOK_LOGS_REPORT_QUERY,
      variables: { sellerId, campaignId },
      fetchPolicy: 'network-only',
    }).valueChanges;
  }
}
