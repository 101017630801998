import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'progressiveCountdown',
})
export class ProgressiveCountdownPipe implements PipeTransform {
  transform(value: number): string {
    if (value) {
      return new Date(value * 1000).toISOString().slice(11, 19);
    }
    return '00:00:00';
  }
}
