import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent {
  @Input() title?: string = '';
  @Input() icon?: string = '';
  @Input() logoPath?: string = '';
  @Input() hasFooterHeader?: boolean = false;
  @Input() items?: any = [
    {
      id: null,
      name: '',
    },
  ];

  @Output() public clickItem = new EventEmitter();

  constructor() {}

  public clickItemAction(data?: any): void {
    this.clickItem.emit(data);
  }
}
