<section class="delete_key_confirmation_modal">
  <div class="warning">
    <span nz-icon nzType="exclamation-circle" nzTheme="outline" class="colorPrimary"></span>
  </div>
  <h2 class="colorPrimary">Excluir {{ type }}</h2>
  <p>
    Ao excluir essa chave você não poderá mais usá-la para receber pagamentos na Gen. Quer mesmo excluir a chave
    aleatória <b>{{ key }}</b
    >?
  </p>
  <div class="buttons">
    <app-primary-button (click)="deleteKey()" [loading]="loadingButton">Excluir chave</app-primary-button>
    <app-secondary-button (click)="modalRef.close()">Agora não</app-secondary-button>
  </div>
</section>
