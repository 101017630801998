<header>
  <form [formGroup]="form">
    <div class="search-icon" (click)="$drawerRef.close()">
      <img src="../../assets/images/chevron-left.svg" alt="Ícone para fechar a página" />
    </div>

    <nz-form-item>
      <nz-form-control>
        <nz-input-group [nzPrefix]="searchTemplate" [nzSuffix]="clearTemplate">
          <input type="text" nz-input nzSize="large" placeholder="Buscar" formControlName="search" />
          <ng-template #searchTemplate>
            <svg width="16" height="16" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M16.6725 16.6412L21 21M19 11C19 15.4183 15.4183 19 11 19C6.58172 19 3 15.4183 3 11C3 6.58172 6.58172 3 11 3C15.4183 3 19 6.58172 19 11Z"
                stroke="#C9C9C9"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </ng-template>

          <ng-template #clearTemplate>
            <svg
              (click)="clearField()"
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M4 4L12 12M12 4L4 12" stroke="#C9C9C9" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
          </ng-template>
        </nz-input-group>
      </nz-form-control>
    </nz-form-item>
  </form>
</header>

<body>
  <div class="feedback-error">
    <div class="ilustration">
      <img src="/assets/images/search-filter-error.svg" alt="Ilustração de erro" />
    </div>

    <h1>Nenhum resultado encontrado</h1>
    <p>Tente fazer uma nova pesquisa usando outros termos.</p>
  </div>
</body>
