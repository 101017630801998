import html2canvas from 'html2canvas';
import { NzModalRef } from 'ng-zorro-antd/modal';
import { Component, Input, OnInit } from '@angular/core';

import { StateManagementService } from '../../../state-management/state-management.service';

@Component({
  selector: 'app-modal-insert-image',
  templateUrl: './modal-insert-image.component.html',
  styleUrls: ['./modal-insert-image.component.scss'],
})
export class ModalInsertImageComponent implements OnInit {
  @Input() public imgUrl: string;
  @Input() public indexImg: number;
  @Input() public indexItemVariant: number;
  @Input() public indexItemVariantOption: number;
  @Input() public isNew: boolean = false;
  @Input() public page: 'ITEM' | 'STORE' | 'PLAN' = 'STORE';

  public cropperSize: { width: number; height: number };
  public resultImgSize: { width: number; height: number };

  private resultImg: any;

  public loading: boolean = false;

  constructor(public $notification: StateManagementService, public readonly modalRef: NzModalRef) {}

  public ngOnInit(): void {
    this.getInitialVariables();
  }

  public deleteImg(): void {
    this.$notification.setDeleteImgs({
      indexImage: this.indexImg,
      indexItemVariant: this.indexItemVariant,
      indexItemVariantOption: this.indexItemVariantOption,
    });
    this.modalRef.close();
  }

  private getInitialVariables(): void {
    this.cropperSize = {
      width: 520,
      height: 520,
    };
    this.resultImgSize = {
      width: 520,
      height: 520,
    };
  }

  public getCroppedImage(event: any): void {
    this.resultImg = event;
  }

  public confirm(): void {
    this.loading = true;

    html2canvas(this.resultImg).then((canvas) => {
      const image = new Image(
        this.resultImgSize?.width || this.cropperSize?.width,
        this.resultImgSize?.height || this.cropperSize?.height
      );
      image.src = canvas.toDataURL();
      this.loading = false;
      this.modalRef.close(image.src);
    });
  }
}
