import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NzModalRef } from 'ng-zorro-antd/modal';

@Component({
  selector: 'gen-monorepo-modal-type-store-identify',
  templateUrl: './modal-type-store-identify.component.html',
  styleUrl: './modal-type-store-identify.component.scss',
})
export class ModalTypeStoreIdentifyComponent implements OnInit {
  public form: FormGroup;

  constructor(private fb: FormBuilder, public modalRef: NzModalRef) {}

  public ngOnInit(): void {
    this.createForm();
  }

  public createForm(): void {
    this.form = this.fb.group({
      code: new FormControl('', [Validators.required]),
    });
  }

  public sendCouponCode(): void {
    this.modalRef.close(this.form.get('code').value);
  }
}
