<header *nzModalTitle class="modal-header">
  <h3 class="title-category">Entrar</h3>
  <span class="example-spacer"></span>
  <img src="../../assets/images/close.svg" (click)="modalRef.close()" class="pointer-cursor" />
</header>
<body>
  <main id="customer-onboarding">
    <section class="signInPage">
      <h2>{{ title }}</h2>

      <ng-container *ngIf="loginActualPage === 'signin-create-password'">
        <app-customer-create-password />
      </ng-container>
      <ng-container *ngIf="loginActualPage === 'signin-code-verify'">
        <app-customer-signin-code-verify />
      </ng-container>
      <ng-container *ngIf="loginActualPage === 'signin-password'">
        <app-customer-signin-password />
      </ng-container>
      <ng-container *ngIf="loginActualPage === 'signin-validation-select'">
        <app-customer-validation-select />
      </ng-container>
    </section>
  </main>
</body>
