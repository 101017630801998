import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzDrawerRef } from 'ng-zorro-antd/drawer';

@Component({
  selector: 'app-search-header-marketplace',
  templateUrl: './search-header-marketplace.component.html',
  styleUrls: ['./search-header-marketplace.component.scss'],
})
export class SearchHeaderMarketPlaceComponent implements OnInit {
  public form: FormGroup;

  constructor(private fb: FormBuilder, public $drawerRef: NzDrawerRef, public $message: NzMessageService) {}

  ngOnInit(): void {
    this.createForm();
  }

  public createForm(): void {
    this.form = this.fb.group({
      search: new FormControl(''),
      category: new FormControl(''),
      amountCents: new FormControl(''),
      color: new FormControl(''),
      size: new FormControl(''),
    });
  }

  public clearField(): void {
    this.form.reset();
  }
}
