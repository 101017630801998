import { FeeModel } from './fee.model';
import { PixModel } from './pix.model';
import { BnplModel } from './bnpl.model';
import { CreditModel } from './credit.model';
import { BankSlipModel } from './bank-slip.model';
import { AntifraudModel } from './antifraud.model';
import { DeviceModel } from '../utils/device.model';
import { SellerModel } from '../sellers/sellers.model';
import { LocationModel } from '../utils/location.model';
import { CustomerModel } from '../customer/customer.model';
import { PaymentHistoryModel } from './payment-history.model';
import { AcquirerPaymentModel } from './acquirer-payment.model';

export class PaymentModel {
  public acquirerPayment: AcquirerPaymentModel;
  public additionCents: number;
  public antifraud: AntifraudModel;
  public bankSlip: BankSlipModel;
  public credit: CreditModel;
  public customers: CustomerModel;
  public customer: CustomerModel;
  public publicCustomer: CustomerModel;
  public device: DeviceModel;
  public discountCents: number;
  public feeAmountCents: number;
  public fees: Array<FeeModel>;
  public history: Array<PaymentHistoryModel>;
  public id: string;
  public insertedAt: string;
  public updatedAt: string;
  public confirmedAt: string;
  public location: LocationModel;
  public method: string;
  public order: any;
  public pix: PixModel;
  public bnplCheckout: BnplModel = new BnplModel();
  public sellers: SellerModel;
  public seller: SellerModel;
  public shippingCents: number;
  public status: string;
  public subtotalCents: number;
  public totalCents: number;
}
