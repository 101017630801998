export class StoreOpeningHours {
  public monday: DaysOptions = new DaysOptions();
  public tuesday: DaysOptions = new DaysOptions();
  public wednesday: DaysOptions = new DaysOptions();
  public thursday: DaysOptions = new DaysOptions();
  public friday: DaysOptions = new DaysOptions();
  public saturday: DaysOptions = new DaysOptions();
  public sunday: DaysOptions = new DaysOptions();
  public allDays?: DaysOptions = null;
}

export class DaysOptions {
  public isOpen: boolean = false;
  public closeAt: string = '';
  public openAt: string = '';
  public weekDay?: string = '';
  public label?: string = '';
}
