import { Subject, takeUntil } from 'rxjs';
import { Component, Input, OnInit } from '@angular/core';
import { NzModalRef, NzModalService } from 'ng-zorro-antd/modal';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';

import { Message } from '../../../utils/message';
import { SellerModel } from '../../../models/sellers/sellers.model';
import { ErrorService } from '../../../services/error/error.service';
import { ItemImagesModel } from '../../../models/items/item-images.model';
import { SellersService } from '../../../services/sellers/sellers.service';
import { CategoryModel } from '../../../models/categories/categories.model';
import { UploadImageComponent } from '../upload-image/upload-image.component';
import { ModalItemAssociateComponent } from './../modal-item-associate/modal-item-associate.component';

@Component({
  selector: 'app-modal-create-category',
  templateUrl: './modal-create-category.component.html',
  styleUrls: ['./modal-create-category.component.scss'],
})
export class ModalCreateCategoryComponent implements OnInit {
  @Input() public data: CategoryModel;
  @Input() public isSynapses: boolean;

  public categoryForm!: FormGroup;
  public title: string = 'Cadastrar nova categoria';
  public loading: boolean = false;
  public file: ItemImagesModel = {
    label: 'Foto de capa',
    file: {
      url: '',
    },
  };
  public sellers: Array<SellerModel> = [];

  private destroy$ = new Subject();

  constructor(
    private $error: ErrorService,
    private $seller: SellersService,
    private readonly fb: FormBuilder,
    public readonly $modalRef: NzModalRef,
    private readonly $modal: NzModalService
  ) {}

  public ngOnInit(): void {
    this.createForm();
    this.getSellers();

    if (this.data?.id) {
      setTimeout(() => {
        this.updateForm();
      }, 100);
    }
  }

  private updateForm(): void {
    this.title = 'Editar categoria';
    this.categoryForm.patchValue({
      id: this.data?.id,
      file: this.data?.file,
      url: this.data?.imageUrl,
      name: this.data?.name,
      sellerId: this.data?.seller?.id,
    });

    if (this.data?.imageUrl) {
      this.file.file.url = this.data.imageUrl;
    }
  }

  private createForm(): void {
    if (this.data?.imageUrl) {
      this.file.file = { url: this.data?.imageUrl };
    }

    this.categoryForm = this.fb.group({
      id: new FormControl(''),
      file: new FormControl(''),
      url: new FormControl(''),
      name: new FormControl('', [Validators.required]),
      sellerId: new FormControl('', [Validators.required]),
    });

    if (!this.isSynapses) {
      this.categoryForm.get('sellerId').clearValidators();
      this.categoryForm.get('sellerId').updateValueAndValidity();
    }
  }

  private getSellers(): void {
    this.$seller
      .listSellersToCoupon()
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (res) => {
          if (res?.data?.sellers) {
            this.sellers = JSON.parse(JSON.stringify(res.data.sellers));
          }

          this.loading = false;
        },
        error: (error) => {
          this.loading = false;
          this.$error.errorHandling(error, Message.ERROR_CONECTION, 'ANT');
        },
      });
  }

  public setHandleSubmit(): void {
    let payload = {
      ...this.categoryForm.value,
    };
    this.$modalRef.close(payload);
  }

  public openUploadImageComponent(): void {
    this.$modal
      .create({
        nzClosable: false,
        nzStyle: { display: 'flex', margin: 'auto' },
        nzComponentParams: {
          images: this.file?.file ? [this.file?.file?.url] : [],
          maxInput: 1,
        },
        nzTitle: 'Enviar Imagem',
        nzContent: UploadImageComponent,
      })
      .afterClose.subscribe((res) => {
        if (res) {
          this.file.file = res[0];
          this.categoryForm.get('file').setValue(res[0]);
        }
      });
  }

  public openModalAssociateItems(): void {
    this.$modal
      .create({
        nzContent: ModalItemAssociateComponent,
        nzFooter: null,
        nzClosable: false,
        nzComponentParams: { data: this.categoryForm.value },
      })
      .afterClose.subscribe((res) => {
        if (res) {
          const data: CategoryModel = {
            ...this.categoryForm.value,
            itemsId: res.itemsId,
          };

          this.$modalRef.close(data);
        }
      });
  }

  public deleteImg(): void {
    this.file.file = null;
    this.categoryForm.get('file').setValue(null);
  }
}
