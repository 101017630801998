import { Router } from '@angular/router';
import { Component, Input, Optional } from '@angular/core';
import { NzDrawerRef, NzDrawerService } from 'ng-zorro-antd/drawer';

import { SIDENAV_STORE } from '../../../utils/side-nav';
import { CustomerModel } from '../../../models/customer/customer.model';
import { PointModel } from '../../../models/points/point-of-sales.model';
import { SidenMenuModel } from '../../../models/side-menu/side-menu.model';
import { CategoryModel } from '../../../models/categories/categories.model';
import { AuthStoreService } from '../../../services/auth/auth-store.service';
import { FilterCategoriesComponent } from '../../sub-header/filter-categories/filter-categories.component';

@Component({
  selector: 'app-drawer-side-menu',
  templateUrl: './drawer-side-menu.component.html',
  styleUrls: ['./drawer-side-menu.component.scss'],
})
export class DrawerSideMenuComponent {
  public sideMenu: SidenMenuModel[] = SIDENAV_STORE;
  @Input() categories: Array<CategoryModel> = [];
  @Input() customer: CustomerModel = new CustomerModel();
  @Input() point: PointModel = new PointModel();
  @Input() sellerId: string;
  @Input() isAuth: boolean = false;

  constructor(
    @Optional() private $drawer: NzDrawerRef, // Tornar NzDrawerRef opcional
    private $drawerS: NzDrawerService,
    private $auth: AuthStoreService,
    private router: Router
  ) {}

  public onClickMenu(sideMenu: SidenMenuModel): void {
    if (sideMenu.action) {
      this.doAction(sideMenu.action);
    } else {
      this.router.navigate([sideMenu.route]);
    }
    if (this.$drawer) {
      this.$drawer.close();
    }
  }

  public doAction(sideMenu: string): void {
    switch (sideMenu) {
      case 'categories-filter':
        this.openCategoriesFilter();
        break;
      case 'log-out':
        this.logOut();
        break;
      case 'login':
        this.login();
        break;
      case 'logged-area':
        this.router.navigate(['/internal/' + this.sellerId + '/' + this.point.id + '/auth-area']);
        break;
      default:
        break;
    }
  }

  public openCategoriesFilter(): void {
    const width = window.innerWidth;
    let nzHeight = '100%';

    if (width > 768) {
      nzHeight = '60%';
    }

    this.$drawerS.create({
      nzClosable: false,
      nzWidth: '100%',
      nzHeight,
      nzBodyStyle: {
        'max-width': '100vm',
        'overflow-x': 'hidden',
        height: nzHeight,
        padding: '0',
      },
      nzPlacement: 'top',
      nzContentParams: { categories: this.categories },
      nzContent: FilterCategoriesComponent,
    });
  }

  public login(): void {
    this.$auth.openAuthModal(this.point);
  }

  public logOut(): void {
    this.$auth.logOut(this.point, this.sellerId);
  }
}
