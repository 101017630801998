<section class="code_validation_input">
  <form [formGroup]="inputForm" #form>
    <input
      nz-input
      type="text"
      *ngFor="let i of fillerArray"
      [formControlName]="i"
      maxLength="1"
      (ngModelChange)="setValue()"
    />
  </form>
</section>
