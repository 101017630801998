import { Component, Input } from '@angular/core';
import { NzDrawerRef, NzDrawerService } from 'ng-zorro-antd/drawer';
import { ExpandedFilterCategoriesSubchildrenComponent } from '../expanded-filter-categories-subchildren/expanded-filter-categories-subchildren.component';

@Component({
  selector: 'app-expanded-filter-categorys-children',
  templateUrl: './expanded-filter-categorys-children.component.html',
  styleUrls: ['./expanded-filter-categorys-children.component.scss'],
})
export class ExpandedFilterCategorysChildrenComponent {
  @Input() public selectedProductCategory: any;

  constructor(public $drawerRef: NzDrawerRef, public $drawer: NzDrawerService) {}

  public onSelectedCategory(category): void {
    this.$drawerRef.close(category);
  }

  public backCategory(): void {
    this.$drawerRef.close();
  }

  public openCategoriesFilter(selectedProductCategory?: any): void {
    const width = window.innerWidth;
    let nzHeight = '100%';

    if (width > 768) {
      nzHeight = '60%';
    }

    const drawer = this.$drawer.create({
      nzClosable: false,
      nzWidth: '100%',
      nzHeight,
      nzBodyStyle: {
        'max-width': '100vm',
        'overflow-x': 'hidden',
        height: nzHeight,
        padding: '0',
      },
      nzPlacement: 'top',
      nzContentParams: { selectedProductCategory: selectedProductCategory },
      nzContent: ExpandedFilterCategoriesSubchildrenComponent,
    });
    drawer.afterClose.subscribe((category: string) => {
      if (category) {
        this.onSelectedCategory(category);
      }
    });
  }
}
