import { Component, Input } from '@angular/core';
import { NzModalRef } from 'ng-zorro-antd/modal';

@Component({
  selector: 'app-modal-preview-page',
  templateUrl: './modal-preview-page.component.html',
  styleUrls: ['./modal-preview-page.component.scss'],
})
export class ModalPreviewPageComponent {
  @Input() data: any;
  @Input() type?: string = 'PERSONALIZATION';

  constructor(public modalRef: NzModalRef) {}
}
