import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'bannerType',
})
export class BannerTypePipe implements PipeTransform {
  transform(value: any, ...args: string[]): string {
    if (value) {
      const methods: any = {
        EXTERNAL_LINK: 'Link externo',
        PARKING: 'Estacionamento',
        COUPON: 'Cupom',
        PAYMENT: 'Pagamento',
        SHOP: 'Shop',
        BOX_OFFICE: 'Bilheteria',
      };

      return methods[value] || '';
    }

    return '';
  }
}
