import { ParkingPriceModel } from './../../models/price-table/parking-price.model';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpHeaders, HttpParams } from '@angular/common/http';
import { AngularFirestore } from '@angular/fire/compat/firestore';

import { environment } from '@gen/environments';
import { UtilsService } from '../utils/utils.service';
import { ParkingUserModel } from '../../models/parking-user/parking-user';
import { UserParkingModel } from '../../models/parking/user-parking-model';
import { ResponseParkingModel } from '../../models/parking/response-parking.model';
import { OperacionalParkingModel } from '../../models/parking/operacional-parking.model';
import { LogsRequestParkingModel } from '../../models/parking/logs-request-parking.model';
import { OperacionalRequestParkingModel } from '../../models/parking/operacional-request-parking.model';
import { PriceTableModel } from '../../models/price-table/price-table.model';
import { Apollo } from 'apollo-angular';
import { PARKING_TABLE_QUERY } from '../../graphql/queries/fidelity.queries';
import { UPDATE_PARKING_TABLE_MUTATION } from '../../graphql/mutations/fidelity.mutations';

@Injectable()
export class ParkingService {
  constructor(private $utils: UtilsService, private readonly afStore: AngularFirestore, public apollo: Apollo) {}

  public getUser(id: string): Observable<ParkingUserModel> | undefined {
    return this.afStore.doc(`parkingUsers/${id}`).valueChanges() as Observable<ParkingUserModel>;
  }

  public setUser(data: ParkingUserModel): void {
    this.afStore.doc(`parkingUsers/${data.id}`).set(data, { merge: true });
  }

  public getUserByCpf(cpf: string, mallId: number): Observable<ParkingUserModel[]> | undefined {
    return this.afStore
      .collection(`parkingUsers`, (ref) => ref.where('cpf', '==', cpf).where('mallId', '==', mallId))
      .valueChanges() as Observable<ParkingUserModel[]>;
  }

  public getUserLatestReceipt(id: string): Observable<any> {
    return this.afStore
      .collection(`parkingUsers/${id}/receipts`, (ref) => ref.orderBy('createdAt', 'desc'))
      .valueChanges();
  }

  public getTicketOfTransactions(
    data: OperacionalRequestParkingModel
  ): Observable<ResponseParkingModel<OperacionalParkingModel>> {
    const options = this.prepareOptions(data);
    return this.$utils.httpRequest('GET', `${environment.parkingApi}/ticket/transactions`, options);
  }

  public getOperationalData(data: OperacionalRequestParkingModel): Observable<any> {
    const options = this.prepareOptions(data);
    return this.$utils.httpRequest('GET', `${environment.parkingApi}/ticket/transactions/percentage`, options);
  }

  public getTicketOfFinancialTransactions(
    data: OperacionalRequestParkingModel
  ): Observable<ResponseParkingModel<OperacionalParkingModel>> {
    const options = this.prepareOptions(data);
    return this.$utils.httpRequest('GET', `${environment.parkingApi}/ticket/transactions/financial`, options);
  }

  public getFinancialData(data: OperacionalRequestParkingModel): Observable<any> {
    const options = this.prepareOptions(data);
    return this.$utils.httpRequest(
      'GET',
      `${environment.parkingApi}/ticket/transactions/financial/percentage`,
      options
    );
  }

  public getLogsData(data: LogsRequestParkingModel): Observable<any> {
    const options = this.prepareOptions(data);
    return this.$utils.httpRequest('GET', `${environment.parkingApi}/ticket/logs-from`, options);
  }

  public getParkingPrices(): Observable<PriceTableModel> {
    const options = this.prepareOptions();
    return this.$utils.httpRequest('GET', `${environment.parkingApi}/parking-price`, options);
  }

  public createUserParking(body: UserParkingModel): Observable<any> {
    return this.$utils.httpRequest('POST', `${environment.parkingApi}/user`, { body });
  }

  public upsertParkingPrices(body: PriceTableModel): Observable<any> {
    const parkingToken = sessionStorage.getItem('parkingToken');
    const headers = new HttpHeaders().append('Authorization', `Bearer ${parkingToken}`);
    return this.$utils.httpRequest('POST', `${environment.parkingApi}/parking-price`, { body, headers });
  }

  public updateParkingTable(mallId: string, params: ParkingPriceModel): Observable<any> {
    return this.apollo.mutate({
      mutation: UPDATE_PARKING_TABLE_MUTATION,
      variables: { mallId, params },
    });
  }

  private prepareOptions(data: any = {}): any {
    const parkingToken = sessionStorage.getItem('parkingToken');
    const headers = new HttpHeaders().append('Authorization', `Bearer ${parkingToken}`);
    let params = new HttpParams();

    if (data.startDate) {
      params = params.append('startDate', data.startDate).append('endDate', data.endDate);
    }

    if (data.mallId) {
      params = params.append('id_mall', data.mallId);
    }

    if (data.page) {
      params = params.append('page', data.page).append('perPage', data.perPage);
    }

    if (data.word) {
      params = params.append('word', data.word).append('date', data.date).append('type', data.type);

      if (data.customer) {
        params = params.append('customer', data.customer);
      }
    }

    return {
      params,
      headers,
    };
  }

  public listParkingTable(): Observable<any> {
    return this.apollo.watchQuery<any>({
      query: PARKING_TABLE_QUERY,
      fetchPolicy: 'network-only',
    }).valueChanges;
  }
}
