import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { ApolloQueryResult } from '@apollo/client/core';
import { Apollo, Mutation, Query } from 'apollo-angular';
import { AngularFirestore } from '@angular/fire/compat/firestore';

import {
  GET_USER,
  LIST_USERS_QUERY,
  CURRENT_USER_QUERY,
  LIST_SUBSELLERS_QUERY,
  GET_SUBSELLER_QUERY,
  LIST_INTERNAL_USERS_QUERY,
} from '../../graphql/queries/user.queries';
import {
  CREATE_SUBSELLER_MUTATION,
  DELETE_SUBSELLER_MUTATION,
  UPDATE_SUBSELLER_MUTATION,
  UPDATE_USER_AUTHENTICATED_MUTATION,
  CREATE_INTERNAL_USER_MUTATION,
  UPDATE_INTERNAL_USER_MUTATION,
  DEACTIVE_INTERNAL_USER_MUTATION,
  DELETE_INTERNAL_USER_MUTATION,
} from '../../graphql/mutations/user.mutations';
import { InternalUserDataModel, UserModel } from '../../models/users/users.model';
import { StateManagementService } from '../../state-management/state-management.service';
import { ErrorService } from '../error/error.service';
import { Router } from '@angular/router';
import { FilterUsersParams } from '../../models/users/filter-users-params.model';
import { PaginationModel } from '../../models/pagination/pagination.model';

@Injectable()
export class UsersService {
  constructor(
    private readonly apollo: Apollo,
    private readonly fireStore: AngularFirestore,
    private $notification: StateManagementService,
    private $error: ErrorService,
    private router: Router
  ) {}

  public getUser(marketplaceId: string, sellerId: string, externalUserId: string): Observable<UserModel> {
    return this.fireStore
      ?.doc(`marketplaces/${marketplaceId}/sellers/${sellerId}/users/${externalUserId}`)
      .valueChanges() as Observable<UserModel>;
  }

  public getSubsellerById(sellerId: string, userId: string): Observable<any> {
    return this.apollo.watchQuery<UserModel>({
      query: GET_SUBSELLER_QUERY,
      fetchPolicy: 'cache-and-network',
      variables: { sellerId, userId },
    }).valueChanges;
  }

  public getSubsellerList(
    sellerId: string,
    filters?: FilterUsersParams,
    pagination?: PaginationModel
  ): Observable<any> {
    return this.apollo.watchQuery<UserModel>({
      query: LIST_SUBSELLERS_QUERY,
      fetchPolicy: 'cache-and-network',
      variables: { sellerId, filters, pagination },
    }).valueChanges;
  }

  public createSubseller(sellerId: string, params: Partial<UserModel>): Observable<any> {
    return this.apollo.mutate<Mutation>({
      mutation: CREATE_SUBSELLER_MUTATION,
      variables: {
        sellerId,
        params,
      },
    });
  }

  public updateSubseller(userId: string, sellerId: string, params: Partial<UserModel>): Observable<any> {
    return this.apollo.mutate<Mutation>({
      mutation: UPDATE_SUBSELLER_MUTATION,
      variables: {
        userId,
        sellerId,
        params,
      },
    });
  }

  public deactivateInternalUser(id): Observable<any> {
    return this.apollo.mutate<Mutation>({
      mutation: DEACTIVE_INTERNAL_USER_MUTATION,
      variables: {
        id,
      },
    });
  }

  public deleteSubseller(sellerId: string, userId: string): Observable<any> {
    return this.apollo.mutate<Mutation>({
      mutation: DELETE_SUBSELLER_MUTATION,
      variables: {
        sellerId,
        userId,
      },
    });
  }

  public getUserListApollo(data?: FilterUsersParams): Observable<ApolloQueryResult<UserModel>> {
    return this.apollo.watchQuery<UserModel>({
      query: LIST_USERS_QUERY,
      fetchPolicy: 'cache-and-network',
      variables: { name: data?.name, cpf: data?.cpf, email: data?.email, phone: data?.phone, role: data?.role },
    }).valueChanges;
  }

  public getInternalUserListApollo(
    filters: FilterUsersParams,
    pagination: PaginationModel
  ): Observable<ApolloQueryResult<InternalUserDataModel>> {
    return this.apollo.watchQuery<InternalUserDataModel>({
      query: LIST_INTERNAL_USERS_QUERY,
      fetchPolicy: 'network-only',
      variables: { filters, pagination },
    }).valueChanges;
  }

  public getCurrentUser(platform: string): Observable<any> {
    return this.apollo.watchQuery<Query>({
      query: CURRENT_USER_QUERY,
      variables: { platform },
      fetchPolicy: 'cache-and-network',
    }).valueChanges;
  }

  public getUserApollo(id: string): Observable<ApolloQueryResult<UserModel>> {
    return this.apollo.watchQuery<UserModel>({
      query: GET_USER,
      fetchPolicy: 'cache-and-network',
      variables: { id },
    }).valueChanges;
  }

  public createInternalUser(
    name: string,
    phone: string,
    email: string,
    document: string,
    role: string
  ): Observable<any> {
    return this.apollo.mutate<Mutation>({
      mutation: CREATE_INTERNAL_USER_MUTATION,
      variables: {
        document,
        name,
        email,
        role,
        phone,
      },
    });
  }

  public updateInternalUser(id: string, name: string, role: string): Observable<any> {
    return this.apollo.mutate<Mutation>({
      mutation: UPDATE_INTERNAL_USER_MUTATION,
      variables: {
        id,
        name,
        role,
      },
    });
  }

  public deleteInternalUser(id: string): Observable<any> {
    return this.apollo.mutate<Mutation>({
      mutation: DELETE_INTERNAL_USER_MUTATION,
      variables: {
        id,
      },
    });
  }

  public updateUserAuthenticated(
    authCode: string,
    id: string,
    email?: string,
    phone?: string,
    platform?: string
  ): Observable<any> {
    return this.apollo.mutate<Mutation>({
      mutation: UPDATE_USER_AUTHENTICATED_MUTATION,
      variables: {
        authCode,
        id,
        email,
        phone,
        platform,
      },
    });
  }

  public isOnboardingStepsExternal(user: UserModel): boolean {
    if (user?.onboardingSteps && user?.onboardingSteps.length > 0) {
      const step = user.onboardingSteps[0].step;

      if (['INITIAL', 'VALIDATE_EMAIL', 'SEARCH'].includes(step)) {
        return true;
      }
    }

    return false;
  }

  public isOnboardingStepsInternal(user: UserModel): boolean {
    if (user?.onboardingSteps && user?.onboardingSteps.length > 0) {
      const step = user.onboardingSteps[0].step;

      if (
        ['VALIDATE_PHONE', 'MAIN_DATA', 'COMPANY_DATA', 'PAYMENT_METHODS', 'DOCUMENTS', 'BANK_ACCOUNT'].includes(step)
      ) {
        return true;
      }
    }

    return false;
  }

  public trackOnboardingSteps(user: UserModel, indexStep?: number): void {
    if (user?.onboardingSteps && user?.onboardingSteps.length > 0) {
      user = JSON.parse(JSON.stringify(user));

      const step = user.onboardingSteps[0];
      switch (step.step) {
        case 'INITIAL':
          this.router.navigate(['/external/sign-up/email-verify']);
          break;
        case 'VALIDATE_EMAIL':
          this.router.navigate(['/external/sign-up/onboarding-search']);
          break;
        case 'SEARCH':
          this.router.navigate(['/external/sign-up/phone-input']);
          break;
        case 'VALIDATE_PHONE':
        case 'MAIN_DATA':
          if (indexStep === 1) {
            this.router.navigate(['/internal/onboarding/business-details']);
          } else {
            this.router.navigate(['/internal/onboarding/personal-address']);
          }
          break;
        case 'COMPANY_DATA':
          if (indexStep === 0) {
            this.router.navigate(['/internal/onboarding/personal-address']);
          } else if (indexStep === 1) {
            this.router.navigate(['/internal/onboarding/business-details']);
          } else {
            this.router.navigate(['/internal/onboarding/payment-methods']);
          }
          break;
        case 'PAYMENT_METHODS':
          if (indexStep === 0) {
            this.router.navigate(['/internal/onboarding/personal-address']);
          } else if (indexStep === 1) {
            this.router.navigate(['/internal/onboarding/business-details']);
          } else if (indexStep === 2) {
            this.router.navigate(['/internal/onboarding/payment-methods']);
          } else {
            this.router.navigate(['/internal/onboarding/payment-activation']);
          }
          break;
        case 'DOCUMENTS':
          if (indexStep === 0) {
            this.router.navigate(['/internal/onboarding/personal-address']);
          } else if (indexStep === 1) {
            this.router.navigate(['/internal/onboarding/business-details']);
          } else if (indexStep === 2) {
            this.router.navigate(['/internal/onboarding/payment-methods']);
          } else if (indexStep === 3) {
            this.router.navigate(['/internal/onboarding/payment-activation']);
          } else {
            this.router.navigate(['/internal/onboarding/bank-data']);
          }
          break;
        case 'BANK_ACCOUNT':
          if (indexStep === 0) {
            this.router.navigate(['/internal/onboarding/personal-address']);
          } else if (indexStep === 1) {
            this.router.navigate(['/internal/onboarding/business-details']);
          } else if (indexStep === 2) {
            this.router.navigate(['/internal/onboarding/payment-methods']);
          } else if (indexStep === 3) {
            this.router.navigate(['/internal/onboarding/payment-activation']);
          } else {
            this.router.navigate(['/internal/onboarding/bank-data']);
          }
          break;
      }
    } else {
      this.router.navigate(['/external']);
    }
  }

  public setUserOnboarding(): void {
    const accessToken = sessionStorage.getItem('accessToken');
    if (accessToken) {
      this.getCurrentUser('B2B').subscribe({
        next: (res) => {
          if (res.data.currentUser) {
            this.$notification.setUser(res?.data?.currentUser);
            this.trackOnboardingSteps(res?.data?.currentUser);
          }
        },
        error: (error) => {
          this.$error.errorHandling(error, 'Erro ao recuperar o User');
        },
      });
    } else {
      this.router.navigate(['/external/sign-up/personal-data']);
    }
  }
}
