<header *nzModalTitle>
  <h3 class="title-category">{{ title }}</h3>
  <span class="example-spacer"></span>
  <img src="../../assets/images/close.svg" (click)="$modalRef.close()" class="pointer-cursor" />
</header>

<section>
  <form [formGroup]="categoryForm">
    <div *ngIf="isSynapses">
      <nz-form-item>
        <nz-form-label class="startBlock input-text" [nzNoColon]="true" [nzSm]="24" nzFor="sellerId">
          Lojas
        </nz-form-label>
        <nz-form-control [nzSm]="24" [nzXs]="24" nzHasFeedback nzErrorTip="Campo obrigatório.">
          <nz-select id="sellerId" nzShowSearch formControlName="sellerId" nzPlaceHolder="Escolha um seller">
            <nz-option
              *ngFor="let seller of sellers"
              [nzLabel]="
                (seller.store?.displayName || seller.company?.businessName || seller.person?.name) +
                ' - ' +
                ((seller.cnpj | mask : '00.000.000/0000-00') || (seller.cpf | mask : '000.000.000-00'))
              "
              [nzValue]="seller.id"
            ></nz-option>
          </nz-select>
        </nz-form-control>
      </nz-form-item>
    </div>
    <div>
      <nz-form-item class="m-t-1">
        <nz-form-label class="startBlock" [nzNoColon]="true" [nzSm]="24" nzFor="name">Categoria</nz-form-label>
        <nz-form-control [nzSm]="24" [nzXs]="24" nzHasFeedback nzErrorTip="Campo obrigatório.">
          <input nz-input formControlName="name" id="name" placeholder="Digite o nome da categoria" />
        </nz-form-control>
      </nz-form-item>
    </div>
  </form>

  <footer *nzModalFooter>
    <div class="buttons w-100">
      <app-secondary-button (click)="$modalRef.close()"> Cancelar </app-secondary-button>
      <app-primary-button [disabled]="categoryForm?.invalid" (click)="setHandleSubmit()"> Salvar </app-primary-button>
    </div>
  </footer>
</section>
