<header *nzModalTitle>
  <h3 class="title-category">Filtro</h3>
  <span class="example-spacer"></span>
  <img src="../../assets/images/close.svg" (click)="$modal.close()" class="pointer-cursor" />
</header>

<section>
  <form [formGroup]="formProductFilter">
    <div class="left-content">
      <label>Item</label>
      <nz-form-item>
        <nz-form-control [nzSm]="24" [nzXs]="24">
          <nz-input-group nzSize="large">
            <input nz-input type="text" formControlName="name" placeholder="Nome do item" autocomplete="off" />
          </nz-input-group>
        </nz-form-control>
      </nz-form-item>

      <nz-form-item>
        <nz-form-control [nzSm]="24" [nzXs]="24">
          <nz-input-group nzSize="large">
            <nz-select formControlName="category" nzPlaceHolder="Categoria">
              <nz-option
                *ngFor="let category of categories"
                [nzLabel]="category.name"
                [nzValue]="category.id"
              ></nz-option>
            </nz-select>
          </nz-input-group>
        </nz-form-control>
      </nz-form-item>

      <!-- <nz-form-item>
        <label>Faixa de preço</label>
        <nz-form-control [nzSm]="24" [nzXs]="24">
          <nz-slider nzRange [nzMin]="0" [nzMax]="50000" formControlName="rangeValue"></nz-slider>
        </nz-form-control>
      </nz-form-item> -->
    </div>

    <div class="right-content">
      <label>Data de criação</label>
      <nz-range-picker formControlName="date"></nz-range-picker>
    </div>
  </form>
</section>

<footer *nzModalFooter>
  <div class="buttons">
    <app-secondary-button (click)="(clearFields)">Limpar filtros</app-secondary-button>
    <app-primary-button (click)="getFormValues()">Aplicar filtros</app-primary-button>
  </div>
</footer>
