import { gql } from 'apollo-angular';

const CREATE_ITEM_V2_MUTATION = gql`
  mutation CreateV2Item($params: CreateV2ItemParams!) {
    createV2Item(params: $params) {
      id
      name
      type
      updatedAt
      details
      insertedAt
      description
      seller {
        id
      }
      images {
        description
        id
        insertedAt
        orderIndex
        updatedAt
        url
      }
      itemVariants {
        id
        name
        type
        orderIndex
        itemVariantOptions {
          id
          active
          name
          orderIndex
          itemUnitVariantOptions {
            id
            active
            orderIndex
            images {
              description
              id
              insertedAt
              orderIndex
              updatedAt
              url
            }
            itemVariantOption {
              id
              itemVariant {
                id
              }
            }
          }
        }
      }
      itemUnits {
        active
        price
        id
        image {
          description
          id
          insertedAt
          orderIndex
          updatedAt
          url
        }
        stockCount
        itemUnitVariantOptions {
          orderIndex
          id
          active
          name
          images {
            description
            id
            insertedAt
            orderIndex
            updatedAt
            url
          }
          itemVariantOption {
            id
            name
            itemVariant {
              id
              name
            }
          }
        }
        itemVariantOptions {
          id
          orderIndex
          name
          itemVariant {
            id
            name
            orderIndex
            type
          }
        }
      }
      itemTags {
        name
        id
      }
      itemCategory {
        id
        name
      }
    }
  }
`;

const UPDATE_ITEM_V2_MUTATION = gql`
  mutation UpdateV2Item($id: UUID4!, $params: UpdateV2ItemParams!) {
    updateV2Item(id: $id, params: $params) {
      id
      name
      type
      updatedAt
      details
      insertedAt
      description
      seller {
        id
      }
      images {
        description
        id
        insertedAt
        orderIndex
        updatedAt
        url
      }
      itemVariants {
        id
        name
        type
        orderIndex
        itemVariantOptions {
          id
          active
          name
          orderIndex
          itemUnitVariantOptions {
            id
            active
            orderIndex
            images {
              description
              id
              insertedAt
              orderIndex
              updatedAt
              url
            }
            itemVariantOption {
              id
              itemVariant {
                id
              }
            }
          }
        }
      }
      itemUnits {
        active
        price
        id
        image {
          description
          id
          insertedAt
          orderIndex
          updatedAt
          url
        }
        stockCount
        itemUnitVariantOptions {
          orderIndex
          id
          active
          name
          images {
            description
            id
            insertedAt
            orderIndex
            updatedAt
            url
          }
          itemVariantOption {
            id
            name
            itemVariant {
              id
              name
            }
          }
        }
        itemVariantOptions {
          id
          orderIndex
          name
          itemVariant {
            id
            name
            orderIndex
            type
          }
        }
      }
      itemTags {
        name
        id
      }
      itemCategory {
        id
        name
      }
    }
  }
`;

const UPDATE_ITEM_UNIT_MUTATION = gql`
  mutation updateItemUnit($id: UUID4!, $params: UpdateItemUnitParams!) {
    updateItemPrice(id: $id, params: $params) {
      id
    }
  }
`;

const DELETE_ITEM_V2_MUTATION = gql`
  mutation DeleteV2Item($id: UUID4!) {
    deleteV2Item(id: $id) {
      deletedAt
    }
  }
`;

const BATCH_CREATE_V2_ITEMS_MUTATION = gql`
  mutation BatchCreateV2Items($params: BatchCreateV2ItemsParams!) {
    batchCreateV2Items(params: $params)
  }
`;

const DUPLICATE_V2_ITEMS_MUTATION = gql`
  mutation DuplicateV2Item($id: UUID4!, $sellerId: UUID4!) {
    duplicateV2Item(id: $id, sellerId: $sellerId) {
      id
    }
  }
`;

export {
  UPDATE_ITEM_V2_MUTATION,
  DELETE_ITEM_V2_MUTATION,
  CREATE_ITEM_V2_MUTATION,
  DUPLICATE_V2_ITEMS_MUTATION,
  UPDATE_ITEM_UNIT_MUTATION,
  BATCH_CREATE_V2_ITEMS_MUTATION,
};
