import { Subject, takeUntil } from 'rxjs';
import { NzModalRef } from 'ng-zorro-antd/modal';
import { NzMessageService } from 'ng-zorro-antd/message';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';

import { OrderModel } from '../../../models/orders/order.model';
import { ErrorService } from '../../../services/error/error.service';
import { OrdersService } from '../../../services/orders/orders.service';
import { LockerService } from '../../../services/locker/locker.service';
import { LockerModel } from '../../../models/locker/locker.model';

@Component({
  selector: 'app-modal-create-locker-order',
  templateUrl: './modal-create-locker-order.component.html',
  styleUrls: ['./modal-create-locker-order.component.scss'],
})
export class ModalCreateLockerOrderComponent implements OnInit, OnDestroy {
  @Input() order: OrderModel;

  public formGroup!: FormGroup;
  public loading: boolean = false;

  private destroy$ = new Subject();

  constructor(
    private $error: ErrorService,
    private $locker: LockerService,
    private readonly fb: FormBuilder,
    private readonly $modalRef: NzModalRef,
    private readonly $message: NzMessageService
  ) {}

  public ngOnInit(): void {
    this.createForm();
  }

  public ngOnDestroy(): void {
    this.destroy$.next(1);
    this.destroy$.complete();
  }

  private createForm(): void {
    this.formGroup = this.fb.group({
      nfeNumber: new FormControl<string>('', [Validators.required]),
      volumes: this.fb.array([
        this.fb.group({
          size: new FormControl<string>('', [Validators.required]),
        }),
      ]),
    });
  }

  public generateNewLockerOrder(): void {
    this.loading = true;

    const payload: LockerModel = {
      orderId: this.order.id,
      volumes: this.formGroup.get('volumes').value.map((volume: any) => ({
        designationSize: volume.size,
        invoice: {
          key: this.formGroup.get('nfeNumber').value,
        },
      })),
    };

    this.$locker
      .createLockerOrder(payload)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (resp) => {
          if (resp?.data?.createLockerOrder) {
            this.$message.success('Pedido gerado com sucesso!');
            this.closeModal(true);
          } else {
            this.loading = false;
          }
        },
        error: (error) => {
          this.loading = false;
          this.$error.errorHandling(error, 'O pedido não pode ser gerado, tente novamente.', 'ANT');
        },
      });
  }

  public closeModal(response?: boolean): void {
    this.$modalRef.close(!!response);
  }

  public volumesControl(): FormArray {
    return this.formGroup.controls['volumes'] as FormArray;
  }

  public addVolume(): void {
    const volumes = this.volumesControl();

    volumes.push(
      this.fb.group({
        size: new FormControl<string>('', [Validators.required]),
      })
    );
  }

  public removeVolume(index: number): void {
    const volumes = this.volumesControl();

    volumes.removeAt(index);
  }
}
