/* eslint-disable no-async-promise-executor */
import { Injectable } from '@angular/core';
import {
  AngularFirestore,
  AngularFirestoreCollection,
  CollectionReference,
  Query,
} from '@angular/fire/compat/firestore';
import { environment } from '@gen/environments';
import moment from 'moment';
import { Observable, combineLatest, mergeMap, of, take } from 'rxjs';
import { BasketModel } from '../../models/cart/basket.model';
import { CartModel } from '../../models/cart/cart.model';
import { ItemsV2Model } from '../../models/items/V2/ItemV2.model';
import { StateManagementService } from '../../state-management/state-management.service';
import { v4 as uuidv4 } from 'uuid';

@Injectable()
export class MarketplaceService {
  public marketplaceId: string = environment.marketplaceId;
  public pointId: string;

  constructor(private readonly afStore: AngularFirestore, private $notification: StateManagementService) {}

  public addNewCart(): CartModel {
    const cartId = uuidv4();

    let cart: CartModel = {
      id: cartId,
      status: 'ACTIVE',
      orders: [],
      payments: [],
      baskets: [],
      isPaid: false,
      totalValue: 0,
      insertedAt: moment.utc().format('YYYY-MM-DDTHH:mm:ss'),
    };

    this.getCartCollection()?.doc(cartId).set(cart);
    localStorage.setItem('cartId', cartId);

    return cart;
  }

  public getCartId(): string {
    return localStorage.getItem('cartId');
  }

  public async addProduct(product: ItemsV2Model): Promise<void> {
    try {
      const cartId = this.getCartId();
      const basketCollection = this.getBasketCollection(cartId);
      const basketId = product.seller.id;

      let basket: BasketModel | null = await basketCollection.doc(basketId).valueChanges().pipe(take(1)).toPromise();

      if (!basket) {
        basket = {
          id: basketId,
          cartId: cartId,
          storeName: product.seller?.store?.displayName,
          sellerId: basketId,
          items: [product],
          totalValue: product.price,
        };
      } else {
        basket.items.push(product);
        basket.totalValue += product.price;
      }

      await basketCollection.doc(basketId).set(basket);
      await this.getBasketProductsCollection(basketId).doc(product.id).set(product);
    } catch (error) {
      console.error('Ocorreu um erro ao adicionar o produto:', error);
    }
  }

  public updateProduct(product: ItemsV2Model): void {
    if (product) {
      this.getBasketProductsCollection(product.seller.id).doc(product.id).set(product, { merge: true });
    }
  }

  public getAndSetActiveCart(): void {
    let activeCart = new CartModel();

    this.getActiveCart()
      .pipe(
        mergeMap((carts) => {
          if (carts.length > 0) {
            activeCart = carts[0];
            localStorage.setItem('cartId', activeCart.id);

            return combineLatest({
              baskets: this.getBasketCollection(activeCart.id).valueChanges(),
            });
          } else {
            activeCart = this.addNewCart();
          }

          return of();
        })
      )
      .subscribe({
        next: (res) => {
          if (res) {
            if (res.baskets) {
              this.geBasketWithItens(res.baskets, activeCart);
            } else {
              this.$notification.setCart(activeCart);
            }
          }
        },
        error: (error) => {
          throw new Error(error);
        },
      });
  }

  public getBasketProduct(sellerId: string, productId: string): Observable<ItemsV2Model> | undefined {
    if (productId && sellerId) {
      return this.getBasketProductsCollection(sellerId).doc(productId).valueChanges({ idField: 'id' });
    }

    return of({} as ItemsV2Model);
  }

  public async geBasketWithItens(baskets: BasketModel[], cart: CartModel) {
    await Promise.all(
      baskets.map(async (basket, index: number) => {
        await new Promise<void>(async (resolve) => {
          basket.items = await this.getBasketProducts(basket.sellerId).pipe(take(1)).toPromise();
          basket.totalValue = this.calcTotalPrice(basket.items);
          resolve();
        });
      })
    ).then(async () => {
      cart.baskets = baskets;
      cart.totalValue = this.calcTotalPriceBasket(baskets);
      this.$notification.setCart(cart);
    });
  }

  public getActiveCart(): Observable<CartModel[]> {
    return this.afStore
      .collection(`marketplaces/${this.marketplaceId}/carts`, (ref) => {
        const startDate = moment().subtract(7, 'd').set({ hour: 0, minute: 0, second: 0 }).format();
        const endDate = moment().set({ hour: 23, minute: 59, second: 59 }).format();

        let query: CollectionReference | Query = ref;

        query.where('insertedAt', '>=', startDate).where('insertedAt', '<=', endDate);

        query = query.where('status', '==', 'ACTIVE');

        return query.orderBy('insertedAt', 'desc');
      })
      .valueChanges() as Observable<CartModel[]>;
  }

  public removeProductFromBasket(product: ItemsV2Model): void {
    this.getBasketProductsCollection(product.seller.id).doc(product.id).delete();
  }

  public async clearBasket(basketId: string): Promise<void> {
    const cartId = localStorage.getItem('cartId');
    this.getBasketCollection(cartId).doc(basketId).delete();
  }

  public updateCart(data: any): void {
    const cartId = localStorage.getItem('cartId');
    this.getCartCollection()?.doc(cartId).set(data, { merge: true });
  }

  public calcTotalPriceBasket(baskets: Array<BasketModel>): number {
    return baskets.reduce((acc, basket) => {
      acc += Number(basket.totalValue);
      return acc;
    }, 0);
  }

  public calcTotalPrice(products: Array<ItemsV2Model>): number {
    const totalPrice = products.reduce(
      (acc, product) => (acc += (product.price || product.itemUnits[0].price) * (product.quantity || 1)),
      0
    );
    return Number(totalPrice);
  }

  private getCartCollection(): AngularFirestoreCollection<CartModel> {
    return this.afStore.collection<CartModel>(`marketplaces/${this.marketplaceId}/carts`);
  }

  private getBasketCollection(cartId: string): AngularFirestoreCollection<BasketModel> {
    return this.afStore.collection<BasketModel>(`marketplaces/${this.marketplaceId}/carts/${cartId}/basket`);
  }

  public getBasketProducts(sellerId: string): Observable<ItemsV2Model[]> | undefined {
    return this.getBasketProductsCollection(sellerId).valueChanges({ idField: 'id' });
  }

  private getBasketProductsCollection(sellerId: string): AngularFirestoreCollection<ItemsV2Model> {
    const cartId = localStorage.getItem('cartId');

    return this.afStore.collection<ItemsV2Model>(
      `marketplaces/${this.marketplaceId}/carts/${cartId}/basket/${sellerId}/items`
    );
  }
}
