<section class="wrapper-app-upload-image">
  <div class="clearfix">
    <div class="start selected-input">Selecionado(s): {{ imagesUploaded.length }}/ {{ maxInput }}</div>
    <nz-upload
      class="avatar-uploader"
      (nzChange)="handleChange($event)"
      nzListType="picture-card"
      [(nzFileList)]="imagesUploaded"
      [nzShowButton]="imagesUploaded.length < 6"
      [nzPreview]="handlePreview"
      [nzCustomRequest]="customRequest"
      [nzAccept]="'image/png,image/jpeg,image/jpg,image/bmp'"
      [nzFileType]="'image/png,image/jpeg,image/jpg,image/bmp'"
      [nzLimit]="remaining"
      (click)="selectImage($event)"
      nzMultiple
    >
      <div>
        <span nz-icon nzType="plus"></span>
        <div style="margin-top: 8px">Upload</div>
      </div>
    </nz-upload>
    <nz-modal
      [nzVisible]="previewVisible"
      [nzContent]="modalContent"
      [nzFooter]="null"
      (nzOnCancel)="previewVisible = false"
    >
      <ng-template #modalContent>
        <img [src]="previewImage" [ngStyle]="{ width: '100%' }" />
      </ng-template>
    </nz-modal>
  </div>

  <div *nzModalFooter>
    <app-primary-button class="m-r-1" icon="upload" (click)="sendImgs()" [disabled]="imagesUploaded.length === 0"
      >Confirmar envio</app-primary-button
    >
    <app-secondary-button icon="close" (click)="$modalRef.close()">Cancelar</app-secondary-button>
  </div>

  <div *ngIf="imageToCrop" style="display: flex; flex-direction: column; align-items: center">
    <small *ngIf="isMissingCrop" style="color: red; margin-left: 20px; text-align: justify"
      >Oops, faltou cortar esta imagem, certifique-se de ajustar todas as imagens, selecionando-as no painel à esquerda,
      então clique em 'Confirmar envio' novamente após a edição.</small
    >
    <div class="wrapper-cropper">
      <image-cropper
        [imageFile]="imageToCrop"
        [maintainAspectRatio]="true"
        [aspectRatio]="1 / 1"
        format="png"
        (imageCropped)="imageCropped($event)"
        [resizeToWidth]="500"
        [resizeToHeight]="500"
      ></image-cropper>
    </div>
  </div>
</section>
