import { Injectable, Query } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { Observable } from 'rxjs';
import { CreateItemVariantInput } from '../../../models/items/V2/createItemVariantParams';
import {
  GET_ITEM_VARIANT_QUERY,
  LIST_ITEM_VARIANT_OPTIONS_QUERY,
  LIST_ITEM_VARIANT_QUERY,
} from '../../../graphql/queries/itemVariant.queries';
import {
  CREATE_ITEM_VARIANT_MUTATION,
  CREATE_ITEM_VARIANT_OPTION_MUTATION,
  DELETE_ITEM_VARIANT_MUTATION,
  UPDATE_ITEM_VARIANT_MUTATION,
} from '../../../graphql/mutations/item-variant.mutations';
import { StateManagementService } from '../../../state-management/state-management.service';

@Injectable({
  providedIn: 'root',
})
export class ItemVariantService {
  constructor(private readonly apollo: Apollo, private $notification: StateManagementService) {}

  cores = {
    vermelho: '#FC4E69',
    verde: '#10AD7D',
    azul: '#00BDFF',
    amarelo: '#FFCE1C',
    rosa: '#FEB8C3',
    branco: '#C9C9C9',
    laranja: '#FF6C1A',
    roxo: '#6D54FC',
    preto: '#181818',
    cinza: '#C9C9C9',
    marrom: '#865A42',
    ciano: '#00FFFF',
    indigo: '#4B0082',
    ouro: '#FFD700',
    prata: '#C0C0C0',
    turquesa: '#40E0D0',
    violeta: '#8A2BE2',
    vinho: '#C73B51',
  };

  public listItemVariant(filters: any = {}): Observable<any> {
    return this.apollo.watchQuery<Query>({
      query: LIST_ITEM_VARIANT_QUERY,
      variables: { filters },
      fetchPolicy: 'network-only',
    }).valueChanges;
  }

  public listItemVariantOptions(filters: any = {}): Observable<any> {
    return this.apollo.watchQuery<Query>({
      query: LIST_ITEM_VARIANT_OPTIONS_QUERY,
      variables: { filters },
      fetchPolicy: 'network-only',
    }).valueChanges;
  }

  public getItemVariant(id: string): Observable<any> {
    return this.apollo.watchQuery<Query>({
      query: GET_ITEM_VARIANT_QUERY,
      variables: { id },
      fetchPolicy: 'cache-and-network',
    }).valueChanges;
  }

  public createItemVariant(params: CreateItemVariantInput): Observable<any> {
    params = this.fixColumnName(params);
    return this.apollo.mutate({
      mutation: CREATE_ITEM_VARIANT_MUTATION,
      variables: { params },
    });
  }

  public createItemVariantOption(params: any): Observable<any> {
    return this.apollo.mutate({
      mutation: CREATE_ITEM_VARIANT_OPTION_MUTATION,
      variables: { params },
    });
  }
  public updateItemVariant(id: string, params: CreateItemVariantInput): Observable<any> {
    return this.apollo.mutate({
      mutation: UPDATE_ITEM_VARIANT_MUTATION,
      variables: { id, params },
    });
  }

  public deleteItemVariant(id: string): Observable<any> {
    return this.apollo.mutate({
      mutation: DELETE_ITEM_VARIANT_MUTATION,
      variables: { id },
    });
  }

  public getAndSetItemVariantsOptions(): void {
    const itemVariantOptions = [
      {
        name: 'Amarelo',
        color: '#F7D174',
      },
      {
        name: 'Branco',
        color: '#FFF',
      },
      {
        name: 'Preto',
        color: '#181818',
      },
      {
        name: 'Azul',
        color: '#0B6C97',
      },
      {
        name: 'Verde',
        color: '#10AD7D',
      },
      {
        name: 'Vermelho',
        color: '#C73B51',
      },
    ] as any;

    this.$notification.setItemVariantOptions(itemVariantOptions);
  }

  public fixColumnName(params: CreateItemVariantInput): CreateItemVariantInput {
    params.v2ItemVariantOptions = params.itemVariantOptions;
    params = JSON.parse(JSON.stringify(params));
    delete params.itemVariantOptions;
    return params;
  }
}
