<div *nzModalTitle>
  <h3 class="no-margin">Upload de arquivo</h3>
</div>
<section>
  <form [formGroup]="formGroup">
    <nz-upload
      nzType="drag"
      (nzChange)="handleChange($event)"
      [nzCustomRequest]="customRequest"
      nzAccept=".png,.jpeg,.bmp,.jpg,.pdf"
    >
      <p class="ant-upload-drag-icon">
        <span nz-icon nzType="inbox"></span>
      </p>
      <p class="ant-upload-text">Clique ou arraste o arquivo para a área de upload.</p>
    </nz-upload>

    <div nz-row>
      <div nz-col nzSpan="24">
        <nz-radio-group formControlName="documentType">
          <label nz-radio nzValue="SELFIE">Foto do Rosto</label>
          <label nz-radio nzValue="PERSON_ID_FRONT">Identidade - Frente</label>
          <label nz-radio nzValue="PERSON_ID_BACK">Identidade - Verso</label>
          <label nz-radio nzValue="PROOF_OF_RESIDENCE">Cartão CNPJ</label>
          <label nz-radio nzValue="SOCIAL_CONTRACT">Contrato Social</label>
        </nz-radio-group>
      </div>
    </div>
  </form>
</section>
<div *nzModalFooter>
  <app-primary-button class="m-r-1" icon="close" (click)="closed(false)">Cancelar</app-primary-button>
  <app-secondary-button icon="logout" [disabled]="formGroup.invalid" (click)="closed(true)">
    Enviar arquivo
  </app-secondary-button>
</div>
