<section class="in-analysis centerBlock">
  <div class="data">
    <div class="m-t-2 m-b-1 center">
      <h3>Agradecemos o seu interesse</h3>
    </div>
    <div class="center">
      <span> Com base nas informações fornecidas, não foi possível ativar a sua conta neste momento. </span>
    </div>
  </div>
</section>
<div *nzModalFooter class="padding-0">
  <app-primary-button (click)="closeModal()">OK</app-primary-button>
</div>
