<header>
  <div class="logo">
    <div class="logo-content">
      <img
        src="/assets/images/logo-gen-black.svg"
        routerLink="/internal"
        class="pointer-cursor"
        alt="Logo da empresa"
      />
    </div>
  </div>

  <nz-header>
    <div class="header-container h-100">
      <span class="example-spacer"></span>

      <div nz-dropdown nzTrigger="click" [nzDropdownMenu]="menu" class="profile">
        <div class="h-100">
          <p>{{ user.name || user?.activeSeller?.person?.name }}</p>
          <span>{{
            user?.activeSeller?.store?.displayName ||
              user?.activeSeller?.company?.businessName ||
              user?.activeSeller?.occupation
          }}</span>
        </div>

        <img src="/assets/icons/chevron-down.svg" alt="Seta pra baixo" />
      </div>
      <nz-dropdown-menu #menu="nzDropdownMenu">
        <div class="dropdown-container">
          <div class="logo-profile">
            <nz-avatar [nzSize]="40" nzIcon="user"></nz-avatar>
          </div>

          <div class="profile-info">
            <p>{{ user?.name || user?.activeSeller?.person?.name }}</p>
            <span class="text-seller-name d-block">
              {{
                user?.activeSeller?.store?.displayName ||
                  user?.activeSeller?.company?.businessName ||
                  user?.activeSeller?.occupation
              }}
            </span>
            <span class="text-email d-block">{{ user.email }}</span>

            <hr />

            <div class="button-footer">
              <a (click)="logout()">Sair</a>
            </div>
          </div>
        </div>
      </nz-dropdown-menu>
    </div>
  </nz-header>
</header>
