<div class="center p-t-2 p-b-2" *ngIf="loading">
  <nz-spin nzSize="large"></nz-spin>
</div>

<main id="share-coupon" *ngIf="!loading">
  <section>
    <nz-row>
      <nz-col nzSpan="24" class="d-evenly">
        <div *ngFor="let method of methods" (click)="sendToPlatform(method.type)" class="pointer-cursor">
          <img [src]="method.imageUrl" alt="Imagem dos métodos" />
        </div>
      </nz-col>
    </nz-row>
  </section>

  <nz-divider></nz-divider>

  <section>
    <form [formGroup]="formGroup">
      <nz-row>
        <nz-col nzSpan="24">
          <nz-form-item>
            <nz-form-control [nzSm]="24" [nzXs]="24">
              <nz-input-group nzSize="large">
                <input nz-input type="text" formControlName="url" id="url" />
              </nz-input-group>
            </nz-form-control>
          </nz-form-item>
        </nz-col>
        <nz-col nzSpan="24" class="endBlock">
          <app-secondary-button
            iconImg="/assets/icons/clone.svg"
            alt="Ícone de copiar"
            [block]="true"
            class="center w-100"
            (click)="copyContent()"
          >
            Copiar
          </app-secondary-button>
        </nz-col>
      </nz-row>
    </form>
  </section>
</main>
