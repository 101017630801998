<section class="primary">
  <nz-button-group nzSize="large">
    <button [disabled]="quantity === 1" nz-button nzType="text" nzSize="large" (click)="removeQuantity()">
      <span class="primary-color" nz-icon nzType="minus" nzTheme="outline"> </span>
    </button>
    <span class="quantity"> {{ quantity }} </span>
    <button nz-button nzType="text" nzSize="large" (click)="addQuantity()">
      <span class="primary-color" nz-icon nzType="plus" nzTheme="outline"></span>
    </button>
  </nz-button-group>
</section>
