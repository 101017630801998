import { Component, OnInit } from '@angular/core';
import { NzDrawerRef } from 'ng-zorro-antd/drawer';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';

import { CreditCardModel } from 'libs/shared/src/lib/models/credit-card/credit-card.model';

@Component({
  selector: 'app-drawer-cvv-bottom-sheet',
  templateUrl: './drawer-cvv-bottom-sheet.component.html',
  styleUrls: ['./drawer-cvv-bottom-sheet.component.scss'],
})
export class DrawerCvvBottomSheetComponent implements OnInit {
  public form!: FormGroup;
  public creditCard: CreditCardModel;

  constructor(private readonly $drawerRef: NzDrawerRef, private readonly fb: FormBuilder) {}

  public ngOnInit(): void {
    this.createForm();
  }

  public createForm(): void {
    this.form = this.fb.group({
      cvv: new FormControl('', [Validators.required, Validators.minLength(3), Validators.maxLength(4)]),
    });
  }

  public submit(): void {
    this.$drawerRef.close(this.form.value);
  }
}
