<ng-template #suffixTemplate>
  <span *ngIf="loading" nz-icon nzType="loading" nzTheme="outline"></span>
</ng-template>

<header *nzModalTitle class="modal-header">
  <h3 class="title-category">{{ title }}</h3>
  <span class="example-spacer"></span>
  <img src="../../assets/images/close.svg" (click)="$modalRef.close()" class="pointer-cursor" />
</header>

<body>
  <main>
    <section>
      <p>Insira um ou mais endereços de e-mail para liberar o acesso a plataforma.</p>

      <form nz-form nzContent [formGroup]="formDescription">
        <nz-form-item>
          <label class="m-b-1-1" nzFor="name">Nome e sobrenome</label>
          <nz-form-control nzErrorTip="Preenchimento obrigatório">
            <input nz-input id="name" formControlName="name" placeholder="Nome completo" />
          </nz-form-control>
        </nz-form-item>

        <nz-form-item>
          <label class="m-b-1-1" nzFor="cpf">CPF</label>
          <nz-form-control nzErrorTip="CPF inválido">
            <input nz-input id="cpf" formControlName="cpf" mask="000.000.000-00" placeholder="000.000.000-00" />
          </nz-form-control>
        </nz-form-item>

        <nz-form-item>
          <label class="m-b-1-1" nzFor="email">E-mail</label>
          <nz-form-control nzErrorTip="Preenchimento obrigatório">
            <nz-input-group [nzAddOnAfter]="addOnAfterTemplate">
              <input nz-input id="email" formControlName="email" type="email" placeholder="Digite o e-mail" />
            </nz-input-group>

            <ng-template #addOnAfterTemplate>
              <nz-select nzPlaceHolder="Membros" formControlName="role">
                <nz-option
                  *ngFor="let role of roles; index as i"
                  [nzLabel]="role.value"
                  [nzValue]="role.key"
                ></nz-option>
              </nz-select>
            </ng-template>
          </nz-form-control>
        </nz-form-item>

        <nz-form-item>
          <label class="m-b-1-1" nzFor="phone">Celular</label>
          <nz-form-control nzErrorTip="Preenchimento obrigatório">
            <input nz-input id="phone" formControlName="phone" mask="(00) 0 0000-0000" placeholder="(00) 0 0000-0000" />
          </nz-form-control>
        </nz-form-item>
      </form>
    </section>
  </main>
</body>

<footer class="modal-footer" *nzModalFooter>
  <div class="buttons">
    <app-secondary-button (click)="$modalRef.close()" type="DARK-PURPLE">Cancelar</app-secondary-button>
    <app-primary-button
      [disabled]="formDescription.invalid"
      (click)="createEditUser()"
      class="buttonsHeader"
      type="DARK-PURPLE"
    >
      {{ value ? 'Atualizar' : 'Convidar' }}
    </app-primary-button>
  </div>
</footer>

<ng-template #suffixIconArrowDownTemplate>
  <img src="/assets/images/icons/arrow-down-grey.svg" alt="Ícone para expandir opções" />
</ng-template>
