<section>
  <header>
    <img src="/assets/svg/close.svg" (click)="modalRef.close()" class="pointer-cursor" />
  </header>

  <body>
    <h1>O que é Gen Box?</h1>

    <p>Um armário inteligente que oferece mais praticidade e segurança na retirada das suas compras!</p>
    <p>
      Escolha o Gen Box próximo de você e retire os seus produtos usando um código de liberação ou escaneando o QR Code
      com o celular.
    </p>
  </body>

  <footer>
    <button (click)="modalRef.close()">Entendi</button>
  </footer>
</section>
