import { Directive, HostListener } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[appRequiredOnBlur]',
})
export class RequiredOnBlurDirective {
  constructor(private control: NgControl) {}

  @HostListener('blur')
  onBlur() {
    if (this.control.control) {
      this.control.control.updateValueAndValidity({ onlySelf: false, emitEvent: true });
    }
  }
}
