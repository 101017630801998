import moment from 'moment';
import { ActivatedRoute } from '@angular/router';
import { NzUploadFile } from 'ng-zorro-antd/upload';
import { NzImageService } from 'ng-zorro-antd/image';
import { NzTableComponent } from 'ng-zorro-antd/table';
import { NzMessageService } from 'ng-zorro-antd/message';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
} from '@angular/core';

import { Message } from '../../utils/message';
import { TableType } from '../../types/table-type';
import { SellerModel } from '../../models/sellers/sellers.model';
import { ItemsV2Model } from '../../models/items/V2/ItemV2.model';
import { DocumentModel } from '../../models/document/document.model';
import { ItemUnitModel } from '../../models/items/V2/itemUnit.model';
import { PixPaymentsModel } from '../../models/payments/payments.model';
import { SellersService } from '../../services/sellers/sellers.service';
import { TableHeaderModel } from '../../models/table/table-header.model';
import { TableFooterModel } from '../../models/table/table-footer.model';
import { InternalService } from '../../services/internal/internal.service';
import { UserClaimsModel } from '../../models/user-claims/user-claims.model';
import { TableSettingsModel } from '../../models/table/table-settings.model';
import { CouponFidelityModel } from '../../models/fidelity/coupon-fidelity.model';
import { NotificationsModel } from '../../models/notifications/notifications.model';
import { StateManagementService } from '../../state-management/state-management.service';
import { UserRole } from '../../types/user-role.type';
import { RoleEnum } from '../../enums/roles';

@Component({
  selector: 'app-main-table',
  templateUrl: './main-table.component.html',
  styleUrls: ['./main-table.component.scss'],
})
export class MainTableComponent implements OnInit {
  @ViewChild('itemFooter', { read: TemplateRef }) itemFooter: TemplateRef<any>;
  @ViewChild('walletFooter', { read: TemplateRef }) walletFooter: TemplateRef<any>;
  @ViewChild('paymentFooter', { read: TemplateRef }) paymentFooter: TemplateRef<any>;
  @ViewChild('receiveFooter', { read: TemplateRef }) receiveFooter: TemplateRef<any>;
  @ViewChild('balanceFooter', { read: TemplateRef }) balanceFooter: TemplateRef<any>;
  @ViewChild('editableTableFooter', { read: TemplateRef }) editableTableFooter: TemplateRef<any>;
  @ViewChild('dynamicTable', { static: false }) table: NzTableComponent<any>;

  @Input() public settingValue?: TableSettingsModel = {
    pagination: true,
    footer: false,
    expandable: false,
    checkbox: true,
    frontPagination: true,
  };
  @Input() public footerData: TableFooterModel = {
    countQuantity: 0,
    totalPayments: 0,
    countPayments: 0,
    countRefunds: 0,
    totalRefunds: 0,
    totalAmount: 0,
    totalCredit: 0,
    totalDebit: 0,
    totalBalance: 0,
  };
  @Input() public listOfData: any[] = [];
  @Input() public listOfHeaders: TableHeaderModel[] = [];
  @Input() public tableType: TableType = null;
  @Input() public widthConfig: string[] = [];
  @Input() public platform: string = 'BUSINESS';

  @Output() public actionBtn = new EventEmitter();
  @Output() public showDetailsDrawer = new EventEmitter();
  @Output() public onChangeAmountDropdownClose = new EventEmitter();
  @Output() public pageIndexChange = new EventEmitter<number>();
  @Output() public pageSizeChange = new EventEmitter<number>();

  public fidelityForm?: FormArray;
  public itemPriceForm?: FormArray;

  public allChecked: boolean = false;
  public fileList: NzUploadFile[] = [];
  public indeterminate: boolean = false;
  public documents: DocumentModel[] = [];
  public displayData: readonly any[] = [];
  public user: UserClaimsModel = {} as UserClaimsModel;
  public formInventory: FormGroup = new FormGroup('');
  private initialItemQuantity: { [id: string]: number } = {};
  public pageIndex: number = 1;
  public pageSize: number = 10;
  public checkedData: Array<NotificationsModel> = [];
  public countUnread: number = 0;
  public countRead: number = 0;
  public seller: SellerModel = new SellerModel();
  public isCheckedDisabled: boolean = false;
  public expandSet = new Set<number>();

  constructor(
    private $seller: SellersService,
    private readonly fb: FormBuilder,
    private cdr: ChangeDetectorRef,
    private $internal: InternalService,
    private nzImageService: NzImageService,
    private readonly $message: NzMessageService,
    private readonly activeRoute: ActivatedRoute,
    private $notification: StateManagementService
  ) {}

  public ngOnInit(): void {
    this.activeRoute.url.subscribe();
    this.getUser();
    this.checkTableType();
    this.getNotifications();
  }

  public getNotifications(): void {
    this.$notification.checkedDatas.subscribe((res) => {
      if (res) {
        this.checkedData = res;
        this.countUnread = this.isUnread(this.checkedData);
        this.countRead = this.isRead(this.checkedData);
      }
    });

    this.$notification.sellers.subscribe((res) => {
      if (res) {
        this.seller = res;
      }
    });

    this.$notification.itemVariants.subscribe((res) => {
      if (res) {
        this.listOfData = res;
      }
    });
  }

  private checkTableType(): void {
    if (this.tableType === 'INVENTORY') {
      this.createInventoryForm();
    }

    if (this.tableType === 'FIDELITY-COUPONS-SUMMARY') {
      this.createFidelityForm();
    }

    if (this.tableType === 'ITEM-PRICES') {
      this.createItemPricesForm();
    }
  }

  public setPageIndex(index: number): void {
    this.pageIndex = index;
  }

  public setPageSize(index: number): void {
    this.pageSize = index;
  }

  public onExpandChange(id: number, checked: boolean): void {
    if (checked) {
      this.expandSet.add(id);
    } else {
      this.expandSet.delete(id);
    }
  }

  private createInventoryForm(): void {
    this.formInventory = this.fb.group('');

    this.listOfData.map((item) => {
      this.formInventory?.addControl('amount' + item.id, new FormControl(item.quantity || 0));
      this.initialItemQuantity[item.id] = item.quantity;
    });
  }

  private createFidelityForm(): void {
    this.fidelityForm = new FormArray(
      this.listOfData.map(
        (x: CouponFidelityModel) =>
          new FormGroup({
            active: new FormControl<boolean>(x.status !== 'INACTIVE'),
          })
      )
    );
  }

  private createItemPricesForm(): void {
    this.itemPriceForm = new FormArray(
      this.listOfData.map(
        (x: ItemUnitModel) =>
          new FormGroup({
            active: new FormControl<boolean>(x.active),
          })
      )
    );
  }

  public onQuantityChange(price: ItemUnitModel | ItemsV2Model, event?: any): void {
    if (!event || (event && event.key === 'Enter')) {
      const equalInitial =
        this.initialItemQuantity[price.id] === parseInt(this.formInventory.get('amount' + price.id)?.value);
      if (equalInitial && event) {
        this.onChangeAmountDropdownClose.emit({ error: 'no_change' });
      } else if (!equalInitial) {
        this.onChangeAmountDropdownClose.emit({
          amount: parseInt(this.formInventory.get('amount' + price.id).value),
          priceId: price.id,
          item: !!(price as ItemsV2Model)?.itemUnits,
        });

        const index = this.listOfData.findIndex((item) => item.id === price.id);
        this.listOfData[index].quantity = parseInt(this.formInventory.get('amount' + price.id).value);

        this.initialItemQuantity[price.id] = parseInt(this.formInventory.get('amount' + price.id).value);
      }
    }
  }

  private getUser(): void {
    this.$notification.users.subscribe((res) => {
      if (res?.marketplaceId && res?.sellerId) {
        this.user = res;
      }
    });
  }

  public onPageChange($event: any): void {
    this.pageIndex = $event;
    this.pageIndexChange.emit($event);
  }

  public onPageSizeChange($event: any): void {
    this.pageSize = $event;
    this.pageSizeChange.emit($event);
  }

  public currentPageDataChange($event: readonly any[]): void {
    this.displayData = $event;
    this.refreshStatus();
  }

  public refreshStatus(): void {
    const validData = this.displayData.filter((value) => !value.disabled);
    const allChecked = validData.length > 0 && validData.every((value) => value.checked === true);
    const allUnChecked = validData.every((value) => !value.checked);
    const checkedData = this.listOfData.filter((value) => value.checked);
    const uncheckedData = this.listOfData.filter((value) => !value.checked);

    this.allChecked = allChecked;
    this.indeterminate = !allChecked && !allUnChecked;

    if (checkedData.length) {
      this.displayData.map((value) => {
        value.disabled = !value.checked;
      });
    } else {
      this.displayData.map((value) => {
        value.disabled = false;
      });
    }

    this.$notification.setCheckedData(checkedData);
    this.$notification.setUncheckedData(uncheckedData);
  }

  public checkAll(value: boolean): void {
    this.displayData.forEach((data) => {
      if (!data.disabled) {
        data.checked = value;
      }
    });

    this.refreshStatus();
  }

  public callToAction(action: string, data?: any): void {
    this.actionBtn.emit({ action, data });
  }

  public showDetails(data: any): void {
    this.showDetailsDrawer.next(data);
  }

  public copyContent(content: string): void {
    this.$internal.copyContent(content);
  }

  public changeCouponStatus(coupon: CouponFidelityModel): void {
    const newCoupon = Object.assign({}, coupon, { active: this.getCouponControl('active', coupon).value });
    this.callToAction('EDIT', newCoupon);
  }

  public getCouponControl(controlName: string, coupon: CouponFidelityModel): FormControl {
    const index = this.listOfData.findIndex((data) => data.id === coupon.id);
    return (this.fidelityForm.at(index) as FormGroup)?.get(controlName) as FormControl;
  }

  public changeItemPriceStatus(itemPrice: ItemUnitModel): void {
    const newItemPrice = Object.assign({}, itemPrice, { active: this.getItemUnitControl('active', itemPrice).value });
    this.callToAction('UPDATE', newItemPrice);
  }

  public getItemUnitControl(controlName: string, itemPrice: ItemUnitModel): FormControl {
    const index = this.listOfData.findIndex((data) => data.id === itemPrice.id);
    return (this.itemPriceForm.at(index) as FormGroup)?.get(controlName) as FormControl;
  }

  public isStatusCancelled(status: string): boolean {
    return ['canceled_without_confirmation', 'denied_mgm', 'reversal'].includes(status);
  }

  public goToOutside(link: string): void {
    window.open(link, '_blank');
  }

  public async onSaveImage(event: any, docData: any): Promise<void> {
    docData.loading = true;

    if (!event.target?.files[0]) {
      this.$message.error(Message.ERROR_LOADING_DOCUMENT);
    } else {
      const content = await this.getBase64(event.target?.files[0]);

      if (!content) {
        this.$message.error(Message.ERROR_LOADING_DOCUMENT);
      } else {
        this.$seller
          .uploadSellerDocuments({
            sellerId: docData.sellerId,
            type: docData.id,
            file: {
              binary: content,
              filename: event.target?.files?.[0]?.name,
            },
          })
          .subscribe({
            next: (res) => {
              if (res?.data?.uploadSellerDocument) {
                this.$message.success(Message.SUCCESS_SEND_DOCUMENTS);
              }

              setTimeout(() => {
                docData.loading = false;
                this.callToAction('GET', {});
              }, 1500);
            },
            error: (error) => {
              setTimeout(() => {
                docData.loading = false;
              }, 1500);
              this.$message.error(Message.ERROR_CONECTION);
              throw new Error(error);
            },
          });
      }
    }
  }

  private getBase64(file: File): Promise<string> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = (): void => {
        resolve(reader.result as string);
      };
      reader.onerror = (error): void => {
        reject(error);
      };
    });
  }

  private isUnread(notifications: NotificationsModel[]): number {
    return notifications.filter((notification) => !notification.read)?.length;
  }

  private isRead(notifications: NotificationsModel[]): number {
    return notifications.filter((notification) => notification.read)?.length;
  }

  public disablePixDevolutuin(data: PixPaymentsModel): boolean {
    let blockDevolution = false;

    if (data) {
      const updatedAt = data.updatedAt;
      const sixtyDaysMark = moment(updatedAt).add(60, 'days').format('YYYY-MM-DD');
      const today = moment().format('YYYY-MM-DD');

      if (today > sixtyDaysMark && data.status !== 'PAID') {
        blockDevolution = true;
      }
    }

    return blockDevolution;
  }

  // Novo método para tratar as segmentações e retornar a string formatada
  public getSegmentationNames(segmentations: any[], seller: SellerModel): string {
    if (!segmentations || segmentations.length === 0) {
      return '-'; // ou qualquer mensagem que você desejar exibir quando não houver segmentações
    }

    return segmentations
      .map((segmentation) => (seller?.business_name ? seller?.business_name + ' - ' : '') + segmentation.name)
      .join(', ');
  }

  // Novo método para calcular os dias entre as datas
  public calculateDays(startDates: Date, endDates: Date): string {
    const startDate = new Date(startDates);
    const endDate = new Date(endDates);
    const timeDiff = Math.abs(endDate.getTime() - startDate.getTime());
    const diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24));

    // Verificar se a diferença é igual a 1 para retornar a palavra "dia" em vez de "dias"
    const daysString = diffDays === 1 ? 'dia' : 'dias';

    return `${diffDays} ${daysString}`;
  }

  // Novo método para identificar os canais das campanhas
  public identifyChannel(hasSMS: boolean, hasEmail: boolean): string {
    if (hasSMS && hasEmail) {
      return 'SMS/Email';
    } else if (hasSMS) {
      return 'SMS';
    } else if (hasEmail) {
      return 'Email';
    } else {
      return 'Nenhum canal selecionado';
    }
  }

  // Novo método para identificar os contatos da campanha sem repetição
  public getTotalUniqueContacts(segmentations: any[]): number {
    const uniqueContactsSet = new Set<any>();

    segmentations.forEach((segmentation) => {
      segmentation.contacts.forEach((contact) => {
        uniqueContactsSet.add(contact);
      });
    });

    return uniqueContactsSet.size;
  }

  public formatUTC(sendDate: Date): string {
    const date = new Date(sendDate);
    const year = date.getUTCFullYear();
    const month = this.padNumber(date.getUTCMonth() + 1);
    const day = this.padNumber(date.getUTCDate());
    const hours = this.padNumber(date.getUTCHours());
    const minutes = this.padNumber(date.getUTCMinutes());

    return `${day}/${month}/${year} - ${hours}:${minutes}`;
  }

  private padNumber(num: number): string {
    return num.toString().padStart(2, '0');
  }

  public drop(event: CdkDragDrop<any[]>): void {
    moveItemInArray(this.listOfData, event.previousIndex, event.currentIndex);
    this.listOfData = [...this.listOfData];
  }

  public showPreview(bannerUrl: string): void {
    this.nzImageService.preview(
      [
        {
          src: bannerUrl,
        },
      ],
      { nzZoom: 1.5, nzRotate: 0 }
    );
  }

  public getUserRole(userRole: UserRole): string {
    return RoleEnum[userRole?.toUpperCase()];
  }
}
