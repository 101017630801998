import moment from 'moment-timezone';
import { Injectable } from '@angular/core';
import { Clipboard } from '@angular/cdk/clipboard';

import { getIp } from '../../utils/ip';
import { convertCentToCoin } from '../../utils/coins';
import { LocationType } from '../../types/location-type';
import { OrderModel } from '../../models/orders/order.model';
import { PaymentMethods } from '../../types/payment-methods.type';
import { SellerModel } from '../../models/sellers/sellers.model';
import { PaymentsModel } from '../../models/payments/payments.model';
import { StateManagementService } from './../../state-management/state-management.service';
import { NzModalService } from 'ng-zorro-antd/modal';
import { ErrorPaymentModalComponent } from '../../components/error-payment-modal/error-payment-modal.component';
import { StoreModel } from '../../models/store/store.model';
import { NzCascaderFilter, NzCascaderOption } from 'ng-zorro-antd/cascader';
import { AlertService } from '../alert/alert.service';

@Injectable()
export class InternalService {
  constructor(
    private readonly $clipboard: Clipboard,
    private $notification: StateManagementService,
    private $alert: AlertService,
    private $modal: NzModalService
  ) {}

  public methodsList: Array<{ value: string; label: string; checked: boolean }> = [
    { label: 'Boleto', value: 'BANK_SLIP', checked: false },
    { label: 'Cartão', value: 'CREDIT_CARD', checked: false },
    { label: 'PIX', value: 'PIX', checked: false },
    { label: 'Dinheiro', value: 'MONEY_EXTERNAL', checked: false },
    { label: 'Outros', value: 'DEBIT_EXTERNAL', checked: false },
  ];

  public scheduleList: Array<{ value: string; label: string }> = [
    { label: 'Diária', value: 'daily' },
    { label: 'Semanal', value: 'weekly' },
    { label: 'Quinzenal', value: 'biweekly' },
    { label: 'Mensal', value: 'monthly' },
    { label: 'Anual', value: 'annually' },
  ];

  public installmentList: Array<{ value: string; label: string; disabled: boolean }> = [
    {
      label: '1x',
      value: '1',
      disabled: false,
    },
    {
      label: '2x',
      value: '2',
      disabled: false,
    },
    {
      label: '3x',
      value: '3',
      disabled: false,
    },
    {
      label: '4x',
      value: '4',
      disabled: false,
    },
    {
      label: '5x',
      value: '5',
      disabled: false,
    },
    {
      label: '6x',
      value: '6',
      disabled: false,
    },
    {
      label: '7x',
      value: '7',
      disabled: false,
    },
    {
      label: '8x',
      value: '8',
      disabled: false,
    },
    {
      label: '9x',
      value: '9',
      disabled: false,
    },
    {
      label: '10x',
      value: '10',
      disabled: false,
    },
    {
      label: '11x',
      value: '11',
      disabled: false,
    },
    {
      label: '12x',
      value: '12',
      disabled: false,
    },
  ];

  public getMethods(data: Array<PaymentMethods>): string {
    const methods: any = {
      PIX: 'PIX',
      CREDIT_CARD: 'Cartão de crédito',
      BANK_SLIP: 'Boleto',
      MONEY_EXTERNAL: 'Dinheiro',
      DEBIT_EXTERNAL: 'Outros',
    };

    const result = data?.map((method) => methods[method]).filter((item) => item) || ['-'];

    return result.join('  •  ');
  }

  public getMethod(method: string): string {
    const methods: any = {
      PIX: 'PIX',
      CREDIT_CARD: 'Cartão de crédito',
      BANK_SLIP: 'Boleto',
      MONEY_EXTERNAL: 'Dinheiro',
      DEBIT_EXTERNAL: 'Outros',
    };

    return methods[method || ''];
  }

  public getMethodsBySeller(seller: SellerModel): Array<{ value: string; label: string; checked: boolean }> {
    const formatedMethods = [];
    const data = seller.stores?.[0]?.storePreferences?.defaultPaymentMethods;
    const methods: string[] = data ? (typeof data === 'string' ? JSON.parse(data) : data) : [];

    if (methods.length > 0) {
      methods.forEach((element) => {
        formatedMethods.push({
          label: this.getMethod(element),
          value: element,
          checked: false,
        });
      });

      return formatedMethods;
    }

    return [];
  }

  public getIcon(method: string): string {
    const icon: any = {
      PIX: '../../../../assets/images/icons/pix.svg',
      CREDIT_CARD: '../../../../assets/images/icons/credit-card.svg',
      BANK_SLIP: '../../../../assets/images/icons/money-insert.svg',
      MONEY_EXTERNAL: '../../../../assets/images/icons/money-insert.svg',
      DEBIT_EXTERNAL: '../../../../assets/images/icons/circle-dollar.svg',
      WALLET: '../../../../assets/images/icons/money-insert.svg',
    };

    return icon[method || ''];
  }

  public formatMoney(input: number): string {
    return convertCentToCoin(input.toString());
  }

  public compareFn(o1: any, o2: any): boolean {
    if (o1 && o2) {
      if (o1.id === o2.id) {
        return true;
      }

      if (o1.description === o2.name) {
        return true;
      }

      if (o1.name === o2.name) {
        return true;
      }

      return false;
    }
    return false;
  }

  public percentOrZero(a: number, b: number): number {
    if (b !== 0) {
      return a / b - 1;
    } else {
      return 0;
    }
  }

  public getSchedule(schedule: string): string {
    const schedules: any = {
      daily: 'Diária',
      weekly: 'Semanal',
      biweekly: 'Quinzenal',
      monthly: 'Mensal',
      annually: 'Anual',
    };

    return schedules[schedule];
  }

  public copyContent(content: string): void {
    this.$clipboard.copy(content);
    this.$alert.setAlertInfo('SUCCESS', 'Link de pagamento copiado com sucesso!');
  }

  public treatError(response: any): void {
    let errorMessage = '';

    if (response && response.name === 'ApolloError') {
      errorMessage = response.message;
      let treatedMessage = '';
      switch (errorMessage.toLowerCase()) {
        case 'falha de comunicacao - tente mais tarde [ecom - 912]':
        case 'falha de comunicacao - tente mais tarde [ecom - 911]':
          treatedMessage = 'Falha na comunicação - Tente novamente mais tarde.';
          break;
        case 'verifique os dados do cartao [ecom - 01]':
        case 'verifique os dados do cartao [ecom - 101]':
        case 'verifique os dados do cartao [ecom - 115]':
        case 'verifique os dados do cartao [ecom - 122]':
        case 'verifique os dados do cartao [ecom - 06]':
        case 'verifique os dados do cartao [ecom - 56]':
          treatedMessage = 'Verifique os dados informados.';
          break;
        case 'verifique os dados do cartao [ecom - 54]':
          treatedMessage = 'Cartão com data de expiração vencida.';
          break;
        case 'erro no cartao - nao tente novamente adq 4-79':
        case 'erro no cartao - nao tente novamente adq 4-82':
        case 'erro no cartao - nao tente novamente adq 4-83':
        case 'erro no cartao - nao tente novamente [ecom - 82]':
        case 'erro no cartao - nao tente novamente [ecom - 83]':
        case 'erro no cartao - nao tente novamente [ecom - 121]':
          treatedMessage = 'Não foi possível seguir o pagamento. Contate com o emissor do cartão.';
          break;

        default:
          break;
      }

      if (treatedMessage !== '') {
        errorMessage = treatedMessage;
      }
    }

    if (errorMessage === '') {
      errorMessage = 'Ocorreu uma falha ao processar o pagamento. Por favor, tente novamente mais tarde.';
    }

    this.$notification.setErrorMesage(errorMessage.replace(/[&\/\\#+()$~%'":*?<>[\]{}]/g, ''));

    if (window.innerWidth > 768) {
      this.$modal.create({
        nzContent: ErrorPaymentModalComponent,
        nzFooter: null,
        nzClosable: false,
        nzWidth: '520px',
      });
    } else {
      this.$notification.setTypeResult('ERROR');
    }
    this.$notification.setLoading(false);
  }

  public getUserLocation(): void {
    let location: LocationType = { latitude: 0, longitude: 0, ip: '' };
    getIp((ip: string) => (location.ip = ip));

    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        location.latitude = Number(position.coords.latitude.toFixed(7));
        location.longitude = Number(position.coords.longitude.toFixed(7));
        this.$notification.setLocation(location);
      });
    }

    this.$notification.setLocation(location);
  }

  public setMinInstallment(
    store: StoreModel,
    priceToPay: number,
    sellerMaxInstallments: number
  ): Array<{ value: string; label: string; disabled: boolean }> {
    this.installmentList.map((installments, index) => {
      installments.disabled = this.getMinInstallment(store, priceToPay, sellerMaxInstallments, index + 1);
      installments.label = `${index + 1}x de R$ ${(priceToPay / (index + 1) / 100).toFixed(2)}`;
    });

    this.installmentList = this.installmentList.filter((installment) => !installment.disabled);

    return this.installmentList;
  }

  public getMinInstallment(
    store: StoreModel,
    priceToPay: number,
    sellerMaxInstallments: number,
    index: number
  ): boolean {
    if (index === 1) {
      return false;
    } else {
      if (!store.storePreferences?.allowInstallment) {
        return true;
      } else {
        const result = priceToPay / index > 1000 && index <= sellerMaxInstallments ? false : true;
        return result;
      }
    }
  }

  public planDetails(plan: any): void {
    let frequency: string;
    let planEndDate: string;

    switch (plan?.schedulePlans) {
      case 'daily':
        frequency = 'Diária';
        planEndDate = moment().add(1, 'day').format('YYYY-MM-DD');
        this.$notification.setPlanDetail({ expirationDate: planEndDate, frequency: frequency });
        break;
      case 'weekly':
        frequency = 'Semanal';
        planEndDate = moment().add(1, 'week').format('YYYY-MM-DD');
        this.$notification.setPlanDetail({ expirationDate: planEndDate, frequency: frequency });
        break;
      case 'biweekly':
        frequency = 'Quinzenal';
        planEndDate = moment().add(2, 'weeks').format('YYYY-MM-DD');
        this.$notification.setPlanDetail({ expirationDate: planEndDate, frequency: frequency });
        break;
      case 'monthly':
        frequency = 'Mensal';
        planEndDate = moment().add(1, 'month').format('YYYY-MM-DD');
        this.$notification.setPlanDetail({ expirationDate: planEndDate, frequency: frequency });
        break;
      case 'annually':
        frequency = 'Anual';
        planEndDate = moment().add(1, 'year').format('YYYY-MM-DD');
        this.$notification.setPlanDetail({ expirationDate: planEndDate, frequency: frequency });
        break;
      default:
        frequency = '';
        planEndDate = moment().format('YYYY-MM-DD');
        this.$notification.setPlanDetail({ expirationDate: planEndDate, frequency: frequency });
        break;
    }
  }

  public priceName(schedulePlans: string): string {
    switch (schedulePlans) {
      case 'daily':
        return 'Diária';
      case 'weekly':
        return 'Semanal';
      case 'biweekly':
        return 'Quinzenal';
      case 'monthly':
        return 'Mensal';
      case 'annually':
        return 'Anual';
      default:
        return '';
    }
  }

  public formatRedirectTo(redirectTo: string | undefined): string | undefined {
    if (redirectTo && !redirectTo?.startsWith('https://')) {
      return `https://${redirectTo}`;
    }

    return redirectTo;
  }

  public getString(order: OrderModel, payments: Array<PaymentsModel>): string {
    const lastPaymentIndex = payments?.length - 1 || 0;
    if (
      order?.status != 'PAID' &&
      order?.status != 'PENDING' &&
      order?.status != 'PARTIALLY_PAID' &&
      order?.status != 'IN_ANALYSIS' &&
      order?.status != 'CANCELLED' &&
      order?.status != 'NOT_PAID'
    ) {
      return order.insertedAt;
    }

    if (
      order?.status === 'PENDING' ||
      order?.status === 'PARTIALLY_PAID' ||
      order?.status === 'IN_ANALYSIS' ||
      order?.status === 'CANCELLED' ||
      order?.status === 'NOT_PAID'
    ) {
      return order.updatedAt;
    }

    if (payments && order?.status === 'PAID') {
      return payments[lastPaymentIndex]?.insertedAt;
    }

    return '';
  }

  public isValidSize(img: File): boolean {
    const maxSize = 5242880;
    return img.size <= maxSize;
  }

  public isValidFormat(img: File): boolean {
    const allowedFormats = ['image/png', 'image/jpeg', 'image/jpg', 'image/bmp', 'image/svg+xml', 'image/webp'];
    return allowedFormats.includes(img.type);
  }

  public formatDistance(distanceInMeters: number): string {
    const distanceInKm = distanceInMeters / 1000;
    const result = Math.round(distanceInKm * 10) / 10;
    return `${result}KM`;
  }

  public cascaderFilter: NzCascaderFilter = (inputValue: string, path: NzCascaderOption[]): boolean => {
    const normalizedInput = inputValue
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '')
      .toLowerCase();

    return path.some((o) => {
      const label = o.label;
      const normalizedLabel = label
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '')
        .toLowerCase();
      return !!label && normalizedLabel.includes(normalizedInput);
    });
  };
}
