import { NzModalRef } from 'ng-zorro-antd/modal';
import { NzMessageService } from 'ng-zorro-antd/message';
import { Component, Input, ViewChild, TemplateRef } from '@angular/core';
import { Clipboard } from '@angular/cdk/clipboard';

@Component({
  selector: 'app-modal-create-pix-key-success',
  templateUrl: './modal-create-pix-key-success.component.html',
  styleUrls: ['./modal-create-pix-key-success.component.scss'],
})
export class ModalCreatePixKeySuccessComponent {
  @ViewChild('customToaster') public toaster: TemplateRef<any>;

  @Input() public key: string;

  public selectedKey: string;

  constructor(private $message: NzMessageService, private clipboard: Clipboard, public modalRef: NzModalRef) {}

  public copyKey(): void {
    if (this.key) {
      this.selectedKey = this.key;
      this.clipboard.copy(this.key);
      this.$message.create('', this.toaster);
    } else {
      this.$message.error('Código não definido.');
    }
  }

  public removeToaster(): void {
    this.$message.remove();
  }
}
