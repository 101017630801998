<form [formGroup]="form">
  <quill-editor
    [styles]="{ height: '200px' }"
    formControlName="content"
    [maxLength]="maxLength"
    [placeholder]="placeholder"
    [modules]="modules"
    [ngClass]="
      (form?.controls[controlName]?.hasError('required') && form?.controls[controlName]?.touched) ||
      form?.controls[controlName]?.hasError('maxlength')
        ? 'invalid'
        : ''
    "
    (onContentChanged)="onContentChanged($event)"
    [formControlName]="controlName"
  ></quill-editor>
  <div class="w-quill endBlock">
    <span class="text-counter">{{ form.get(controlName)?.value?.length || 0 }} / {{ maxLength }} </span>
  </div>
  <div
    class="w-quill"
    *ngIf="form?.controls[controlName]?.touched && form?.controls[controlName]?.hasError('required')"
  >
    <span class="text-error"> Campo obrigatório! </span>
  </div>

  <div class="w-quill blockEnd" *ngIf="form?.controls[controlName]?.hasError('maxlength')">
    <span class="text-error"> Você ultrapassou o limite de caracteres permitidos.</span>
  </div>
</form>
