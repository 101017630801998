import { gql } from 'apollo-angular';

const CREATE_HUBSPOT_TOKEN_MUTATION = gql`
  mutation CreateHubspotToken($params: HubspotInputDto!) {
    createHubspotToken(params: $params) {
      token
    }
  }
`;

export { CREATE_HUBSPOT_TOKEN_MUTATION };
