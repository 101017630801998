<header *nzModalTitle class="modal-header">
  <h1>Pré-visualização</h1>
  <img src="/assets/icons/xmark.svg" (click)="modalRef.close()" class="pointer-cursor" />
</header>

<section class="preview_page">
  <p>
    Antes de continuar, verifique se as informações da sua loja estão corretas. Essa é a forma que aparecerá no
    aplicativo Gen.
  </p>
  <div class="wrapper-preview">
    <app-preview-page *ngIf="type === 'PERSONALIZATION'" [data]="data" />
    <app-preview-coupon-app *ngIf="type === 'COUPON'" [coupon]="data" />
  </div>
</section>

<footer *nzModalFooter class="modal-footer">
  <div class="buttons w-100">
    <app-secondary-button (click)="modalRef.close()"> Cancelar </app-secondary-button>
    <app-primary-button (click)="modalRef.close()"> Confirmar </app-primary-button>
  </div>
</footer>
