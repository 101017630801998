<section>
  <header class="modal-header" *nzModalTitle>
    <h3 class="title-category">Alterar senha</h3>
    <span class="example-spacer"></span>
    <img src="../../assets/images/close.svg" (click)="modalRef.close()" class="pointer-cursor" />
  </header>

  <form nz-form nzContent [formGroup]="formNewPassword">
    <nz-form-item class="m-t-1">
      <nz-form-label nzSpan="24">Senha atual</nz-form-label>
      <nz-form-control
        nzSpan="24"
        nzHasFeedback
        [nzErrorTip]="
          formNewPassword.controls['oldPassword'].hasError('pattern') ? errorPasswordPattern : errorPasswordRequired
        "
      >
        <nz-input-group [nzSuffix]="suffixIconPassword" nzSize="large">
          <input
            [type]="rehide ? 'password' : 'text'"
            nz-input
            placeholder="Digite sua senha atual"
            formControlName="oldPassword"
            maxlength="30"
            (keyup)="validPassword()"
          />
        </nz-input-group>
        <ng-template #suffixIconPassword>
          <span
            nz-icon
            [nzType]="rehide ? 'eye-invisible' : 'eye'"
            (click)="rehide = !rehide"
            nzTheme="outline"
            class="pointer-cursor"
          ></span>
        </ng-template>
      </nz-form-control>
    </nz-form-item>

    <nz-form-item class="m-t-1">
      <nz-form-label nzSpan="24">Nova senha</nz-form-label>
      <nz-form-control
        nzSpan="24"
        nzHasFeedback
        [nzErrorTip]="
          formNewPassword.controls['newPassword'].hasError('pattern') ? errorPasswordPattern : errorPasswordRequired
        "
      >
        <nz-input-group [nzSuffix]="suffixIconConfirmPassword" nzSize="large">
          <input
            [type]="rehideConfirm ? 'password' : 'text'"
            nz-input
            placeholder="Digite sua nova senha"
            formControlName="newPassword"
            maxlength="30"
            (keyup)="validPassword()"
            (click)="onKeyPassword($event)"
            autocomplete="off"
          />
        </nz-input-group>
        <ng-template #suffixIconConfirmPassword>
          <span
            nz-icon
            [nzType]="rehideConfirm ? 'eye-invisible' : 'eye'"
            (click)="rehideConfirm = !rehideConfirm"
            nzTheme="outline"
            class="pointer-cursor"
          ></span>
        </ng-template>
      </nz-form-control>
    </nz-form-item>

    <nz-form-item class="m-t-1">
      <nz-form-label nzRequired nzSpan="24">Confirmar senha</nz-form-label>
      <nz-form-control
        nzSpan="24"
        nzHasFeedback
        [nzErrorTip]="
          formNewPassword.controls['newPasswordConfirmation'].hasError('mismatch')
            ? errorConfirmPasswordValid
            : errorPasswordRequired
        "
      >
        <nz-input-group [nzSuffix]="suffixIconConfirmPassword" nzSize="large">
          <input
            [type]="rehideConfirm ? 'password' : 'text'"
            nz-input
            placeholder="Confirmar senha"
            formControlName="newPasswordConfirmation"
            maxlength="30"
            autocomplete="off"
            required
          />
        </nz-input-group>
        <ng-template #suffixIconConfirmPassword>
          <span
            nz-icon
            [nzType]="rehideConfirm ? 'eye-invisible' : 'eye'"
            (click)="rehideConfirm = !rehideConfirm"
            nzTheme="outline"
            class="pointer-cursor"
          ></span>
        </ng-template>
      </nz-form-control>
    </nz-form-item>

    <div *ngIf="showDetails" class="showDetails m-t-1">
      <div nz-row>
        <div nz-col nzSpan="12">
          <p class="text-validation">
            <img
              class="validation-img"
              src="/assets/images/icons/{{ isLowerCase === 'valid' ? 'circle-check.svg' : 'circle-checked-red.svg' }}"
              alt="Ícone para ponto de certo ou errado"
            />
            Letra minúscula
          </p>
          <p class="text-validation">
            <img
              class="validation-img"
              src="/assets/images/icons/{{
                isCapitalLetter === 'valid' ? 'circle-check.svg' : 'circle-checked-red.svg'
              }}"
              alt="Ícone para ponto de certo ou errado"
            />
            Letra maiúsculas
          </p>
          <p class="text-validation">
            <img
              class="validation-img"
              src="/assets/images/icons/{{ isNumber === 'valid' ? 'circle-check.svg' : 'circle-checked-red.svg' }}"
              alt="Ícone para ponto de certo ou errado"
            />
            Números
          </p>
        </div>

        <div nz-col nzSpan="12">
          <p class="text-validation">
            <img
              class="validation-img"
              src="/assets/images/icons/{{
                isLengthNumber === 'valid' ? 'circle-check.svg' : 'circle-checked-red.svg'
              }}"
              alt="Ícone para ponto de certo ou errado"
            />
            8 caracteres
          </p>

          <p class="text-validation">
            <img
              class="validation-img"
              src="/assets/images/icons/{{
                isSpecialCharacter === 'valid' ? 'circle-check.svg' : 'circle-checked-red.svg'
              }}"
              alt="Ícone para ponto de certo ou errado"
            />
            Caracteres especiais ( #, $, & e etc)
          </p>
        </div>
      </div>
    </div>
  </form>

  <footer class="modal-footer" *nzModalFooter>
    <div class="buttons">
      <app-secondary-button (click)="setHandleSubmit(false)">Cancelar</app-secondary-button>
      <app-primary-button
        class="m-l-1"
        icon="send"
        (click)="setHandleSubmit(true)"
        [disabled]="formNewPassword.invalid"
      >
        Salvar
      </app-primary-button>
    </div>
  </footer>
</section>
