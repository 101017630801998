<section>
  <div class="title">
    <h1>Dicas:</h1>
  </div>

  <div class="informations-container">
    <div class="informations-content">
      <img src="/assets/icons/badge-check.svg" alt="Ícone de confirmação" />
      <p>O QR Code deve estar completo sem rasuras ou falhas.</p>
    </div>

    <div class="informations-content">
      <img src="/assets/icons/badge-check.svg" alt="Ícone de confirmação" />
      <p>Verifique se o cupom fiscal não está amassado ou dobrado.</p>
    </div>

    <div class="informations-content">
      <img src="/assets/icons/badge-check.svg" alt="Ícone de confirmação" />
      <p>Posicione o QR Code no centro para focar a câmera.</p>
    </div>

    <div class="informations-content">
      <img src="/assets/icons/badge-check.svg" alt="Ícone de confirmação" />
      <p>Caso não consiga realizar a leitura, infome o código manualmente.</p>
    </div>
  </div>

  <div class="button">
    <app-primary-button [block]="true" (click)="drawerRef.close()">Entendi</app-primary-button>
  </div>
</section>
