<section class="sub_header__page">
  <ng-container *ngIf="getHeaderConfig().headerTypePrimary; else details">
    <div class="mobile mobile-header-container m-t-1 between b-shadow" id="header">
      <div class="d-flex">
        <div class="back-icon" (click)="goBack()" *ngIf="getHeaderConfig().hideArrowBackIcon">
          <img src="../../assets/images/chevron-left.svg" alt="Seta para a esquerda" />
        </div>
        <div [ngClass]="getHeaderConfig().hideArrowBackIcon ? 'center m-l-0' : ''" *ngIf="!getHeaderConfig().hideIcons">
          <img (click)="openSideMenu()" src="../../assets/images/hamburguer.svg" />
        </div>
      </div>

      <div class="title-sub-header centerBlock">
        {{ getHeaderConfig().headerTitle.substring(0, 30) + (getHeaderConfig().headerTitle.length > 30 ? '...' : '') }}
      </div>

      <div class="d-flex">
        <!-- <div *ngIf="!getHeaderConfig().hideIcons">
          <img (click)="clickedFavorite = !clickedFavorite" class="favorite"
            [src]="!clickedFavorite ? imagesUrl[2] : imagesUrl[1]" alt="loading Payment" />
        </div> -->

        <div *ngIf="!getHeaderConfig().hideShoppingCart" class="center shopping-cart" (click)="goToCart()">
          <img [src]="imagesUrl[0]" />

          <div class="item-counter" *ngIf="cart && cart?.baskets?.length > 0">
            <span>{{ cart?.baskets[0]?.items?.length }}</span>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
  <ng-template #details>
    <div class="mobile m-t-1 between b-shadow" id="input">
      <div class="d-flex">
        <div class="subheader-back-icon" (click)="goBack()">
          <img (click)="openSideMenu()" src="../../assets/images/chevron-left.svg" />
        </div>
      </div>

      <div class="d-flex m-l-1">
        <!-- <div>
          <img (click)="clickedFavorite = !clickedFavorite" class="favorite" [src]="
              !clickedFavorite ? '../../assets/images/unclicked-heart.svg' : '../../assets/images/clicked-heart.svg'
            " alt="loading Payment" />
        </div> -->

        <div class="center shopping-cart n-mt-1" (click)="goToCart()">
          <img class="filter-img" src="../../assets/images/shopping-cart.svg" />
        </div>
      </div>
    </div>
  </ng-template>

  <div class="desktop between header-container">
    <div class="header-content">
      <div class="left pointer-cursor">
        <img
          (click)="clearList()"
          [routerLink]="'/internal/' + seller?.id + '/' + (point.id || 'store') + '/products/list'"
          src="../../assets/images/new-logo-gen.svg"
        />
      </div>

      <div class="horizontal-scroll scroll-div pointer-cursor">
        <span
          nz-dropdown
          [nzDropdownMenu]="category.label === 'Categorias' ? menu : null"
          *ngFor="let category of productsCategoryListHeaderLimited; let i = index"
          (click)="category.label !== 'Categorias' ? onSelectCategory(category, 'selected', i) : null"
          [class.active]="i === activeIndex"
          class="{{ i > 0 ? 'm-l-1' : '' }}"
        >
          {{ category.label }}
        </span>

        <nz-dropdown-menu #menu="nzDropdownMenu">
          <ul nz-menu>
            <li
              *ngFor="let category of productsCategoryList.slice(0, 8)"
              nz-menu-item
              (click)="onSelectCategory(category, 'selected')"
            >
              {{ category.label }}
            </li>
            <li class="dropdown-li" [routerLink]="'/internal/' + seller?.id + '/' + point.id + '/products/categories'">
              Ver todas as categorias
            </li>
          </ul>
        </nz-dropdown-menu>
      </div>

      <div class="d-flex pointer-cursor">
        <div class="m-r-1">
          <img
            *ngIf="!isShowInputSearch"
            (click)="showInputSearch()"
            src="../../assets/images/search.svg"
            alt="Ícone de pesquisa"
          />
          <form *ngIf="isShowInputSearch" [formGroup]="form">
            <nz-form-item>
              <nz-form-control>
                <nz-input-group [nzPrefix]="searchTemplate">
                  <input type="text" nz-input nzSize="large" placeholder="Pesquisar" formControlName="search" />
                  <ng-template #searchTemplate>
                    <img src="../../assets/images/search-alt.svg" alt="Ícone de pesquisa" />
                  </ng-template>
                </nz-input-group>
              </nz-form-control>
            </nz-form-item>
          </form>
        </div>

        <!-- <div>
          <img (click)="clickedFavorite = !clickedFavorite" class="favorite"
            [src]="!clickedFavorite ? imagesUrl[2] : imagesUrl[1]" alt="loading Payment" />
        </div> -->

        <div class="center shopping-cart n-mt-1" (click)="goToCart()">
          <img class="filter-img" src="../../assets/images/shopping-cart.svg" />

          <div class="item-counter" *ngIf="cart && cart?.baskets?.length > 0">
            <span>{{ cart?.baskets[0]?.items?.length }}</span>
          </div>
        </div>

        <div class="login-button-container" *ngIf="!isAuth">
          <button class="login-button" (click)="showAuthModal()">Entrar</button>
        </div>

        <div class="center auth-circle n-mt-1" (click)="goToAuthArea()" *ngIf="isAuth">
          <div class="initial-letter">
            <span>{{ customer.name.charAt(0).toUpperCase() }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>

  <ng-container *ngIf="getHeaderConfig().showFilterMobile">
    <div class="mobile m-1">
      <form [formGroup]="form">
        <nz-form-item>
          <nz-form-control>
            <nz-input-group [nzPrefix]="searchTemplate">
              <input type="text" nz-input nzSize="large" placeholder="Pesquisar" formControlName="search" />
              <ng-template #searchTemplate>
                <img src="../../assets/images/search-alt.svg" alt="Ícone de pesquisa" />
              </ng-template>
            </nz-input-group>
          </nz-form-control>
        </nz-form-item>
      </form>
    </div>

    <div class="mobile line-horizontal"></div>
  </ng-container>

  <div class="filter" *ngIf="getHeaderConfig().showFilterMobile" id="input">
    <div class="mobile">
      <span class="d-flex" nz-dropdown nzTrigger="click" [nzDropdownMenu]="menu">
        Ordenar por
        <img src="../../assets/images/chevron-down.svg" alt="Seta para baixo" />
      </span>
      <nz-dropdown-menu #menu="nzDropdownMenu">
        <ul nz-menu>
          <li nz-menu-item (click)="getOrderByValue('inserted_at')">Mais recentes</li>
          <li nz-menu-item (click)="getOrderByValue('name')">Ordem alfabética</li>
        </ul>
      </nz-dropdown-menu>
    </div>
  </div>
</section>
