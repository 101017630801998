import { Component, Input, OnInit } from '@angular/core';
import { NzMessageService } from 'ng-zorro-antd/message';

import { FeesModel } from '../../../../models/fees/fees.model';
import { FeesService } from '../../../../services/fees/fees.service';

@Component({
  selector: 'app-drawer-fees-details',
  templateUrl: './drawer-fees-details.component.html',
  styleUrls: ['./drawer-fees-details.component.scss'],
})
export class DrawerFeesDetailsComponent implements OnInit {
  @Input() value: string;

  public fee: FeesModel;
  public loading: boolean = true;

  constructor(public $fee: FeesService, private readonly $message: NzMessageService) {}

  public ngOnInit(): void {
    this.getFee();
  }

  public getFee(): void {
    this.$fee.getFeeById(this.value).subscribe({
      next: (res) => {
        if (res?.data?.fee) {
          this.fee = res.data.fee;
        }

        this.loading = false;
      },
      error: (error) => {
        this.loading = false;
        this.$message.error('Erro ao buscar a Taxa');
        throw new Error(error);
      },
    });
  }
}
