import { gql } from 'apollo-angular';

const REQUEST_BNPL_MUTATION = gql`
  mutation RequestBnpl($id: UUID4!) {
    requestBnpl(id: $id) {
      id
    }
  }
`;

const SET_BNPL_CHECKOUT_DELIVERED_MUTATION = gql`
  mutation SetBnplCheckoutDelivered($bnplCheckoutId: UUID4!) {
    setBnplCheckoutDelivered(bnplCheckoutId: $bnplCheckoutId)
  }
`;

const IMPORT_PARCELEX_FINANCE_FILE_MUTATION = gql`
  mutation ImportParcelexFinanceFile($binaryFile: String!) {
    importParcelexFinanceFile(binaryFile: $binaryFile)
  }
`;

const IMPORT_PARCELEX_TRANSACTIONS_FILE_MUTATION = gql`
  mutation importParcelexTransactionsFile($binaryFile: String!) {
    importParcelexTransactionsFile(binaryFile: $binaryFile)
  }
`;

export {
  REQUEST_BNPL_MUTATION,
  SET_BNPL_CHECKOUT_DELIVERED_MUTATION,
  IMPORT_PARCELEX_FINANCE_FILE_MUTATION,
  IMPORT_PARCELEX_TRANSACTIONS_FILE_MUTATION,
};
