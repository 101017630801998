<section class="modal_confirmation_delivery_order">
  <div class="header between">
    <h1 class="no-margin-bottom">Confirmar entrega do produto?</h1>
    <img class="pointer-cursor" (click)="closeModal(false)" src="/assets/img/close.svg" alt="Fechar" />
  </div>

  <div class="order-wrapper">
    <span class="order_number">Pedido nº {{ order.id }}</span>
  </div>
  <p>Certifique-se de que todos os itens foram entregues ao cliente. Essa confirmação não poderá ser desfeita.</p>

  <div class="buttons">
    <app-secondary-button (click)="closeModal(false)">Voltar</app-secondary-button>
    <app-primary-button (click)="closeModal(true)">Confirmar entrega</app-primary-button>
  </div>
</section>
