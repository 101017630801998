import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { Apollo, Mutation } from 'apollo-angular';

import { environment } from '@gen/environments';
import { ResponseModel } from '../../models/response/response.model';
import { CREATE_HUBSPOT_TOKEN_MUTATION } from '../../graphql/mutations/hubspot.mutations';
import { AlertService } from '../alert/alert.service';

declare const window: any;

@Injectable()
export class HubspotService {
  public isChatOpen: boolean = false;

  constructor(private readonly apollo: Apollo, private $alert: AlertService) {}

  public toggleChat(): void {
    if (environment.production) {
      window.HubSpotConversations.widget.load();

      if (this.isChatOpen) {
        window.HubSpotConversations.widget.remove();
      } else {
        window.HubSpotConversations.widget.open();
      }

      this.isChatOpen = !this.isChatOpen;
    } else {
      this.$alert.setAlertInfo('ERROR', 'Essa funcionalidade só funciona no ambiente de produção.');
    }
  }

  public createHubspotToken(email: string, firstName: string, lastName: string): Observable<any> {
    return this.apollo.mutate<Mutation>({
      mutation: CREATE_HUBSPOT_TOKEN_MUTATION,
      variables: { params: { email, firstName, lastName } },
    });
  }
}
