import { Component, HostListener } from '@angular/core';
import { NzModalRef } from 'ng-zorro-antd/modal';

@Component({
  selector: 'app-modal-import-inventory',
  templateUrl: './modal-import-inventory.component.html',
  styleUrls: ['./modal-import-inventory.component.scss'],
})
export class ModalImportInventoryComponent {
  public files: FileList;

  constructor(public modalRef: NzModalRef) {}

  public saveFile(event: Event | DragEvent): void {
    if (event instanceof DragEvent) {
      this.files = event.dataTransfer.files;
    }
    if (
      event instanceof Event &&
      (event.target as HTMLInputElement).files &&
      (event.target as HTMLInputElement).files.length
    ) {
      this.files = (event.target as HTMLInputElement).files;
    }
  }

  @HostListener('dragover', ['$event'])
  public onDragOver(event: DragEvent): void {
    event.preventDefault();
  }
  @HostListener('dragenter', ['$event'])
  public onDragEnter(event: DragEvent): void {
    event.preventDefault();
  }
  @HostListener('dragend', ['$event'])
  public onDragEnd(event: DragEvent): void {
    event.preventDefault();
  }
  @HostListener('dragleave', ['$event'])
  public onDragLeave(event: DragEvent): void {
    event.preventDefault();
  }
  @HostListener('drop', ['$event'])
  public onDrop(event: DragEvent): void {
    event.preventDefault();
    event.stopPropagation();
    if (event.dataTransfer.files) {
      let files: FileList = event.dataTransfer.files;
      this.saveFile(event);
    }
  }
}
