import { AddressModel } from '../address/address.model';

export class CompanyModel {
  public businessName: string = '';
  public cnpj: string = '';
  public name: string = '';
  public phone: string = '';
  public nickname: string = '';
  public tradeName?: string = '';
  public address?: AddressModel = new AddressModel();
}
