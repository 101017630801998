import { Clipboard } from '@angular/cdk/clipboard';
import { Component, Input, OnInit } from '@angular/core';
import { NzMessageService } from 'ng-zorro-antd/message';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';

import { LeadService } from '../../../services/lead/lead.service';
import { ErrorService } from '../../../services/error/error.service';

@Component({
  selector: 'app-drawer-share-coupon',
  templateUrl: './drawer-share-coupon.component.html',
  styleUrls: ['./drawer-share-coupon.component.scss'],
})
export class DrawerShareCouponComponent implements OnInit {
  @Input() public couponId: string = '';

  public loading: boolean = true;
  public formGroup!: FormGroup;
  public methods: Array<{ imageUrl: string; type: string }> = [
    { imageUrl: '/assets/img/circle-whatsapp.svg', type: 'WHATSAPP' },
    { imageUrl: '/assets/img/circle-email.svg', type: 'EMAIL' },
  ];

  constructor(
    private $lead: LeadService,
    private $error: ErrorService,
    private readonly fb: FormBuilder,
    private readonly clipboard: Clipboard,
    private readonly $message: NzMessageService
  ) {}

  public ngOnInit(): void {
    this.createForm();
    this.getUrl();
  }

  private createForm(): void {
    this.formGroup = this.fb.group({
      url: new FormControl<string>({ value: '', disabled: true }),
    });
  }

  private getUrl(): void {
    this.$lead.shareCoupon(this.couponId).subscribe({
      next: (res) => {
        if (res?.data?.shareCoupon) {
          this.formGroup.get('url').setValue(res.data.shareCoupon);
        }

        this.loading = false;
      },
      error: (error) => {
        this.loading = false;
        this.$error.errorHandling(error, 'Erro ao compartilhar o cupom. Por favor, tente novamente mais tarde.', 'ANT');
      },
    });
  }

  public sendToPlatform(type: string): void {
    const url = encodeURIComponent(this.formGroup.getRawValue().url);
    const asd = {
      WHATSAPP: `https://api.whatsapp.com/send?text=${url}`,
      EMAIL: `mailto:exemplo@email.com?subject=${url}`,
    };

    window.open(asd[type], '_blank');
  }

  public copyContent(): void {
    const url = this.formGroup.getRawValue().url;
    this.clipboard.copy(url);
    this.$message.success('Link de pagamento copiado com sucesso!');
  }
}
