import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { NzModalRef } from 'ng-zorro-antd/modal';

@Component({
  selector: 'app-modal-export-inventory',
  templateUrl: './modal-export-inventory.component.html',
  styleUrls: ['./modal-export-inventory.component.scss'],
})
export class ModalExportInventoryComponent implements OnInit {
  public form: FormGroup;

  constructor(public modalRef: NzModalRef, private fb: FormBuilder) {}

  public ngOnInit(): void {
    this.createForm();
  }

  public createForm(): void {
    this.form = this.fb.group({
      type: '',
      fileType: '',
    });
  }
}
