import { Observable } from 'rxjs';
import { Injectable, Query } from '@angular/core';
import { Apollo, Mutation } from 'apollo-angular';

import {
  CREATE_BLACKLIST_ITEM_MUTATION,
  CREATE_BLACKLIST_MUTATION,
  UPDATE_BLACKLIST_ITEM_MUTATION,
  UPDATE_BLACKLIST_MUTATION,
} from '../../graphql/mutations/blacklist.mutations';
import { BlacklistModel } from '../../models/blacklist/blacklist.model';
import { FilterBlacklistParams } from '../../models/blacklist/filter-blacklists-params.model';
import { GET_BLACKLIST_QUERY, LIST_BLACKLIST_QUERY } from '../../graphql/queries/blacklist.queries';
import { PaginationModel } from '../../models/pagination/pagination.model';

@Injectable()
export class BlacklistService {
  constructor(private readonly apollo: Apollo) {}

  public getListBlacklist(filters: FilterBlacklistParams, pagination: PaginationModel): Observable<any> {
    return this.apollo.watchQuery<Query>({
      query: LIST_BLACKLIST_QUERY,
      variables: { filters, pagination },
      fetchPolicy: 'network-only',
    }).valueChanges;
  }

  public getBlacklistById(id: string): Observable<any> {
    return this.apollo.watchQuery<Query>({
      query: GET_BLACKLIST_QUERY,
      variables: { id },
      fetchPolicy: 'cache-and-network',
    }).valueChanges;
  }

  public createBlacklistItem(data: BlacklistModel): Observable<any> {
    return this.apollo.mutate<Mutation>({
      mutation: CREATE_BLACKLIST_ITEM_MUTATION,
      variables: {
        cnpj: data.cnpj,
        cpf: data.cpf,
        email: data.email,
        phone: data.phone,
        source: data.source,
      },
    });
  }

  public updateBlacklistItem(id: string, data: BlacklistModel): Observable<any> {
    return this.apollo.mutate<Mutation>({
      mutation: UPDATE_BLACKLIST_ITEM_MUTATION,
      variables: {
        id: id,
        cnpj: data.cnpj,
        cpf: data.cpf,
        email: data.email,
        phone: data.phone,
        source: data.source,
      },
    });
  }

  public createBlacklist(data: BlacklistModel): Observable<any> {
    return this.apollo.mutate<Mutation>({
      mutation: CREATE_BLACKLIST_MUTATION,
      variables: {
        name: data.name,
        reason: data.reason,
        cnpj: data.cnpj,
        cpf: data.cpf,
        email: data.email,
        phone: data.phone,
        source: data.source,
        type: data.type,
      },
    });
  }

  public updateBlacklist(id: string, data: BlacklistModel): Observable<any> {
    return this.apollo.mutate<Mutation>({
      mutation: UPDATE_BLACKLIST_MUTATION,
      variables: {
        id: id,
        name: data.name,
        reason: data.reason,
        cnpj: data.cnpj,
        cpf: data.cpf,
        email: data.email,
        phone: data.phone,
        source: data.source,
        type: data.type,
      },
    });
  }
}
