export class ChartDataModel {
  public name: string;
  public intervals?: Object;
  public series: seriesData[];
}

type seriesData = {
  name: string;
  value: number;
};
