import { Component, Input } from '@angular/core';

import { MallModel } from '../../models/mall/mall.model';
import { TicketModel } from '../../models/ticket/ticket.model';

@Component({
  selector: 'app-mall-card-info',
  templateUrl: './mall-card-info.component.html',
  styleUrls: ['./mall-card-info.component.scss'],
})
export class MallCardInfoComponent {
  @Input() public mall: MallModel = new MallModel();
  @Input() public ticket: TicketModel = new TicketModel();

  constructor() {}
}
