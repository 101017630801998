<section>
  <header>
    <img (click)="$drawerRef.close()" src="../../assets/images/xmark.svg" alt="Ícone para fechar drawer" />

    <h1>Filtro</h1>
  </header>

  <div class="subheader">
    <h2>Filtros aplicados</h2>
    <nz-tag nzMode="closeable" (nzOnClose)="clearFilters('category')" nzColor="#f1f1f1" *ngIf="filters.categoryLabel">{{
      filters.categoryLabel
    }}</nz-tag>
    <nz-tag
      nzMode="closeable"
      (nzOnClose)="clearFilters('priceRange')"
      nzColor="#f1f1f1"
      *ngIf="filters.minValue && filters.maxValue"
      >R$ {{ filters.minValue / 100 | currency : ' ' }} - {{ filters.maxValue / 100 | currency : ' ' }}</nz-tag
    >

    <p (click)="clearFilters('all')">Limpar filtros</p>
  </div>

  <div *ngIf="!loading">
    <section class="p-3">
      <form [formGroup]="form">
        <h4 class="desktop">Filtros</h4>

        <div class="mobile">
          <nz-form-item>
            <nz-form-control>
              <nz-input-group [nzPrefix]="searchTemplate">
                <input type="text" nz-input nzSize="large" placeholder="Buscar" formControlName="searchTerm" />
                <ng-template #searchTemplate>
                  <img src="../../assets/images/search-alt.svg" alt="Ícone de pesquisa" />
                </ng-template>
              </nz-input-group>
            </nz-form-control>
          </nz-form-item>
        </div>

        <div class="order-by-div" id="input">
          <div class="mobile w-100">
            <span class="between w-100" nz-dropdown nzTrigger="click" [nzDropdownMenu]="menu">
              <span>Ordernar Por</span>
              <img src="../../assets/images/chevron-down.svg" alt="Seta para baixo" />
            </span>
            <nz-dropdown-menu #menu="nzDropdownMenu">
              <ul nz-menu>
                <li nz-menu-item (click)="getOrderByValue('inserted_at')">Mais recentes</li>
                <li nz-menu-item (click)="getOrderByValue('name')">Ordem alfabética</li>
              </ul>
            </nz-dropdown-menu>
          </div>
        </div>

        <div *ngFor="let category of categories">
          <nz-collapse nzAccordion [nzBordered]="false" nzExpandIconPosition="right" class="desktop">
            <nz-collapse-panel
              [nzHeader]="category.label"
              [nzShowArrow]="category.isParent"
              (click)="!category.isParent ? onSelectCategory(category) : ''"
            >
              <div *ngFor="let childCategory of category.children">
                <p
                  (click)="onSelectCategory(childCategory)"
                  class="pointer-cursor {{ filters.categoryId === childCategory.value ? 'primary-color' : '' }}"
                >
                  {{ childCategory.label }}
                </p>
              </div>
            </nz-collapse-panel>
          </nz-collapse>

          <div class="mobile m-t-2 childCategory">
            <h3>{{ category.label }}</h3>

            <div nz-row *ngFor="let childCategory of category.children">
              <div nz-col nzSpan="24">
                <p (click)="onSelectCategories(childCategory.value)">
                  {{ childCategory.label }}
                </p>
              </div>
            </div>
          </div>
        </div>

        <div class="line-horizontal"></div>

        <nz-collapse nzAccordion [nzBordered]="false" nzExpandIconPosition="right" class="desktop">
          <nz-collapse-panel nzHeader="Marcas">
            <div nz-row>
              <div nz-col nzSpan="24">
                <nz-form-item class="m-t-1">
                  <nz-form-control [nzSm]="24" [nzXs]="24">
                    <nz-checkbox-group formControlName="sellers"></nz-checkbox-group>
                  </nz-form-control>
                </nz-form-item>
              </div>
            </div>
          </nz-collapse-panel>
        </nz-collapse>

        <nz-collapse nzAccordion [nzBordered]="false" nzExpandIconPosition="right" class="mobile">
          <nz-collapse-panel nzHeader="Marcas">
            <div *ngFor="let seller of sellers">
              <p (click)="onSelectSeller(seller.id)" class="pointer-cursor d-flex">
                <img
                  class="seller-img"
                  [src]="seller?.image_url ? seller.image_url : '/assets/img/default-coupon.svg'"
                  onerror="this.onerror=null; this.src='/assets/img/default-coupon.svg'"
                  alt="Logo da loja"
                />
                <span class="seller-name  {{ filters.sellerId === seller.id ? 'primary-color' : '' }}">
                  {{ seller.business_name }}
                </span>
              </p>
            </div>
          </nz-collapse-panel>
        </nz-collapse>

        <div class="line-horizontal"></div>

        <div>
          <nz-collapse nzAccordion [nzBordered]="false" nzExpandIconPosition="right">
            <nz-collapse-panel nzHeader="Valor">
              <div nz-row [nzGutter]="16">
                <div nz-col nzSpan="12">
                  <nz-form-item>
                    <nz-form-label class="startBlock m-b-0" [nzNoColon]="true" [nzSm]="24" nzFor="discount">
                      Minimo
                    </nz-form-label>
                    <nz-form-control [nzSm]="24" [nzXs]="24">
                      <input nz-input type="text" currencyMask formControlName="minValue" id="discount" />
                    </nz-form-control>
                  </nz-form-item>
                </div>
                <div nz-col nzSpan="12">
                  <nz-form-item>
                    <nz-form-label class="startBlock m-b-0" [nzNoColon]="true" [nzSm]="24" nzFor="discount">
                      Maximo
                    </nz-form-label>
                    <nz-form-control [nzSm]="24" [nzXs]="24">
                      <input nz-input type="text" currencyMask formControlName="maxValue" id="discount" />
                    </nz-form-control>
                  </nz-form-item>
                </div>
              </div>
            </nz-collapse-panel>
          </nz-collapse>
        </div>

        <div class="line-horizontal"></div>

        <div>
          <nz-collapse nzAccordion [nzBordered]="false" nzExpandIconPosition="right">
            <nz-collapse-panel nzHeader="Cor">
              <div *ngFor="let itemVariantOption of itemVariantOptions" (click)="onSelectColor(itemVariantOption.name)">
                <div class="d-flex m-b-0 pointer-cursor">
                  <span
                    *ngIf="itemVariantOption.color"
                    class="color-span"
                    [ngStyle]="{ 'background-color': itemVariantOption.color }"
                  >
                  </span>
                  <span class="color-name  {{ filters.color === itemVariantOption.name ? 'primary-color' : '' }}"
                    >{{ itemVariantOption.name }}
                  </span>
                </div>
              </div>
            </nz-collapse-panel>
          </nz-collapse>
        </div>
      </form>

      <footer class="mobile">
        <div class="between">
          <app-secondary-button class="secondary-button" type="MARKETPLACE" (click)="clearFilters()"
            >Limpar</app-secondary-button
          >
          <app-primary-button class="primary-button" type="MARKETPLACE" (click)="applyFilters()"
            >Aplicar filtro</app-primary-button
          >
        </div>
      </footer>
    </section>
  </div>
</section>
