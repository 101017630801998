<header *nzModalTitle class="modal-header">
  <h3>Identificador da loja</h3>
  <img src="/assets/icons/xmark.svg" (click)="modalRef.close()" class="pointer-cursor" />
</header>

<body>
  <p>Digite o código identificador, encontrado abaixo do QR códe</p>

  <form [formGroup]="form">
    <nz-form-item>
      <nz-form-label [nzSm]="24" nzFor="code">Código de loja</nz-form-label>
      <nz-form-control [nzSm]="24" nzHasFeedback [nzXs]="24" nzErrorTip="Digite o código da loja.">
        <nz-input-group nzSize="large">
          <input nz-input placeholder="Digite o código da loja." formControlName="code" id="code" required />
        </nz-input-group>
      </nz-form-control>
    </nz-form-item>

    <app-secondary-button (click)="sendCouponCode()" type="DARK-PURPLE" [block]="true">Enviar</app-secondary-button>
  </form>
</body>
