<section>
  <div class="center">
    <h3>{{ seller?.settings?.pointOfSale?.fantasyName || seller?.nickname }}</h3>
  </div>

  <ng-container *ngIf="seller?.settings?.pointOfSale?.phone">
    <div class="d-flex" (click)="openWhatsApp()">
      <img class="status-img" src="assets/images/whatsapp.svg" />
      <span class="subtitle m-l-0"> Clique aqui para iniciar uma conversa </span>
    </div>
    <nz-divider></nz-divider>
  </ng-container>

  <ng-container *ngIf="seller?.settings?.pointOfSale?.phone">
    <div class="d-flex" (click)="openPhone()">
      <img class="status-img" src="assets/images/phone.svg" />
      <span class="subtitle m-l-0"> {{ seller?.settings?.pointOfSale?.phone | mask : '00 (00) 0 0000-0000' }}</span>
    </div>
  </ng-container>

  <ng-container *ngIf="seller?.settings?.pointOfSale?.email">
    <nz-divider></nz-divider>
    <div class="d-flex" (click)="openPhone()">
      <img class="status-img" src="assets/images/email.svg" />
      <span class="subtitle"> {{ seller?.settings?.pointOfSale?.email }}</span>
    </div>
  </ng-container>

  <!-- <div class="d-flex">
    <img class="status-img" src="assets/images/svg/clock.svg" />
    <div class="inline">
      <span class="subtitle"> Horário de funcionamento </span>
      <span class="subtitle"> {{ openingHours }}</span>
    </div>
  </div> -->
</section>
