<main id="confirm-payment-order">
  <section id="header">
    <nz-row>
      <nz-col nzSpan="12">
        <h3>Registrar pagamento</h3>
      </nz-col>
      <nz-col nzSpan="12" class="endBlock">
        <img class="close-icon pointer-cursor" (click)="modalRef.close()" src="/assets/img/close.svg" alt="Fechar" />
      </nz-col>
    </nz-row>
  </section>

  <nz-divider></nz-divider>

  <section id="body">
    <nz-row class="m-b-2">
      <nz-col nzSpan="24">
        <p class="d-inline">
          Registrar pagamento para
          <strong
            >{{ orderIds.length }} {{ orderIds.length === 1 ? 'pedido selecionado' : 'pedidos selecionados' }}</strong
          >.
        </p>
      </nz-col>
    </nz-row>

    <nz-row>
      <nz-col nzSpan="24">
        <form [formGroup]="formGroup">
          <nz-form-item>
            <nz-form-control [nzSm]="24" [nzXs]="24" nzHasFeedback nzErrorTip="Campo obrigatório.">
              <nz-select class="wh-30" formControlName="paymentMethod">
                <nz-option nzValue="MONEY_EXTERNAL" nzLabel="Dinheiro"></nz-option>
                <nz-option nzValue="DEBIT_EXTERNAL" nzLabel="Outros"></nz-option>
              </nz-select>
            </nz-form-control>
          </nz-form-item>
        </form>
      </nz-col>
    </nz-row>
  </section>

  <nz-divider></nz-divider>

  <section id="footer">
    <nz-row>
      <nz-col nzSpan="12">
        <app-secondary-button (click)="modalRef.close()">Voltar</app-secondary-button>
      </nz-col>
      <nz-col nzSpan="12" class="endBlock">
        <button nz-button [disabled]="formGroup.invalid" class="btn-error-primary" (click)="confirmCancellation()">
          Sim, pagar
        </button>
      </nz-col>
    </nz-row>
  </section>
</main>
