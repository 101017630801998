import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';

import { NotificationsModel } from '../../models/notifications/notifications.model';

@Injectable()
export class NotificationsService {
  constructor(private readonly fireStore: AngularFirestore) {}

  public getNotifications(marketplaceId: string, sellerId: string): Observable<NotificationsModel[]> | undefined {
    return this.fireStore
      .collection(`marketplaces/${marketplaceId}/sellers/${sellerId}/notifications`)
      ?.valueChanges({ idField: 'id' }) as Observable<NotificationsModel[]>;
  }

  public updateNotification(marketplaceId: string, sellerId: string, data: any, unread: boolean): Promise<any> {
    delete data.dt;
    delete data.type;

    if (!data.date) {
      delete data.date;
    }
    if (!data.insertedAt) {
      delete data.insertedAt;
    }

    data.read = !!unread;

    return this.fireStore
      .collection<NotificationsModel>(`marketplaces/${marketplaceId}/sellers/${sellerId}/notifications`)
      ?.doc(data.id)
      .set(data, { merge: true });
  }

  public async deleteNotification(marketplaceId: string, sellerId: string, idNotification?: string): Promise<any> {
    return await this.fireStore
      .collection<NotificationsModel>(`marketplaces/${marketplaceId}/sellers/${sellerId}/notifications`)
      ?.doc(idNotification)
      .delete();
  }
}
