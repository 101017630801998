<header>
  <div class="row">
    <button class="button button__button--left" (click)="goBack()" *ngIf="['Configurações', 'Pedido'].includes(title)">
      <img src="/assets/icons/chevron-left.svg" alt="Icone para de seta para voltar a página" />
    </button>

    <div class="title">
      <h1>{{ title }}</h1>
    </div>

    <button class="button" routerLink="/internal/settings" *ngIf="title !== 'Configurações'">
      <img src="/assets/img/gear.svg" alt="Icone de engrenagem." />
    </button>
  </div>
</header>
