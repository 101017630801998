import { AbstractControl, ValidatorFn, Validators } from '@angular/forms';

export class EmailValidator {
  constructor() {}

  static isValid(): ValidatorFn {
    return (control: AbstractControl): Validators | null => {
      const email = control.value;
      const re =
        /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

      if (email && !re.test(email)) {
        return { emailNotValid: true };
      }

      return null;
    };
  }
}
