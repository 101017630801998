<section class="preview_page">
  <header class="between m-b-1 p-t-10 p-h-30">
    <time><b>10:00</b></time>
    <div class="icons">
      <img src="/assets/img/sim_status.svg" class="m-r-0" />
      <img src="/assets/img/wifi_status.svg" class="m-r-0" />
      <img src="/assets/img/battery.svg" />
    </div>
  </header>

  <div class="wrapper-title p-h-30">
    <span nz-icon nzType="left" nzTheme="outline"></span>
    <h3 class="d-inline no-margin-bottom">Detalhes da loja</h3>
    <span nz-icon nzType="shopping" nzTheme="outline"></span>
  </div>

  <div class="image-wrapper">
    <img [src]="data?.logo" class="coverImg" alt="Imagem da loja." />
    <nz-tag nzColor="#6e54fd">Aberta</nz-tag>
  </div>

  <div class="between p-h-30 wrapper-name">
    <h2 class="no-margin-bottom">{{ data.displayName }}</h2>
    <div>
      <span nz-icon nzType="heart" nzTheme="outline" class="m-r-1"></span>
      <span nz-icon nzType="share-alt" nzTheme="outline"></span>
    </div>
  </div>

  <p class="m-h-30 description">{{ data.description }}</p>

  <div class="m-h-30">
    <nz-tag nzColor="#E1F3FF" class="tags" *ngFor="let tag of data.tags">{{ tag.name }}</nz-tag>
  </div>

  <nz-card class="m-h-30" nzBorderless>
    <h3>Endereço da loja</h3>
    <h4 *ngIf="data.pointOfSale.line1">{{ data.pointOfSale.line1 }},</h4>
    <h4 *ngIf="data.pointOfSale.line2">Nº{{ data.pointOfSale.line2 }}</h4>
    <h4 *ngIf="data.pointOfSale.neighborhood">{{ data.pointOfSale.neighborhood }}</h4>
    <h4 *ngIf="data.pointOfSale.line3">{{ data.pointOfSale.line3 }}</h4>
    <div class="location">
      <span nz-icon nzType="environment" nzTheme="outline" class="m-r-1 colorPrimary"></span>
      <h4 class="d-inline colorPrimary">8,4km</h4>
    </div>

    <h3 class="openingHours">Horário de funcionamento</h3>

    <h4 *ngFor="let day of openingHours">
      {{ day.isOpen ? day.label + ' - ' + day.openAt + ' às ' + day.closeAt : null }}
    </h4>

    <h3 class="contact">Contato</h3>

    <div class="wrapper-contact between">
      <div class="d-flex">
        <span nz-icon nzType="whats-app" nzTheme="outline" class="m-b-0 colorPrimary"></span>
        <h4>Whatsapp</h4>
      </div>
      <div class="d-flex">
        <span nz-icon nzType="phone" nzTheme="outline" class="m-b-0 colorPrimary"></span>
        <h4>Telefone</h4>
      </div>
      <div class="d-flex">
        <span nz-icon nzType="message" nzTheme="outline" class="m-b-0 colorPrimary"></span>
        <h4>Chat</h4>
      </div>
    </div>
  </nz-card>
</section>
