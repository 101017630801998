import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { NzModalRef } from 'ng-zorro-antd/modal';
import { ValidationDocuments } from '../../../validators/validation-documents.service';

@Component({
  selector: 'app-modal-create-user',
  templateUrl: './modal-create-user.component.html',
  styleUrls: ['./modal-create-user.component.scss'],
})
export class ModalCreateUserComponent implements OnInit {
  @Input() value: any;

  public formDescription: any;

  constructor(
    private fb: FormBuilder,
    private $validationDocuments: ValidationDocuments,
    private modalRef: NzModalRef
  ) {}

  public ngOnInit(): void {
    this.createForm();
    this.getSellerUserById();
  }

  public createForm(): void {
    this.formDescription = this.fb.group({
      uid: new FormControl(this.value?.uid),
      name: new FormControl(''),
      document: new FormControl(''),
      phone: new FormControl(''),
      email: new FormControl('', [Validators.email, Validators.required]),
    });
  }

  public getSellerUserById(): void {
    this.formDescription.patchValue({
      name: this.value?.name,
      document: this.value?.cpf,
      phone: this.value?.phone,
      email: this.value?.email,
    });
  }

  public validationCpf(value?: String): boolean {
    const cpf = value.replace(/[^0-9]+/g, '');

    if (this.$validationDocuments.cpfValidation(cpf)) {
      this.formDescription.get('personalDocument').setErrors(null);
      return true;
    } else {
      this.formDescription.get('personalDocument').setErrors({ pattern: true });
      return false;
    }
  }

  public setHandleSubmit(value?: any): void {
    if (value) {
      this.modalRef.close(this.formDescription.value);
    } else {
      this.modalRef.close();
    }
  }
}
