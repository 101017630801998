declare const window: any;

export function getIp(callback): string | void {
  function response(s): void {
    callback(window.userip);

    s.onload = s.onerror = null;
    document.body.removeChild(s);
  }

  function trigger(): void {
    window.userip = '';

    const s = document.createElement('script');
    s.async = true;

    s.onload = (): void => {
      response(s);
    };
    s.onerror = (): void => {
      response(s);
    };

    s.src = 'https://l2.io/ip.js?var=userip';
    document.body.appendChild(s);
  }

  if (/^(interactive|complete)$/i.test(document.readyState)) {
    trigger();
  } else {
    document.addEventListener('DOMContentLoaded', trigger);
  }
}
