<main id="terms-conditions">
  <div nz-row>
    <div nz-col nzSpan="24">
      <h2 class="no-margin centerBlock">Termos e Condições</h2>
    </div>
  </div>

  <nz-divider></nz-divider>

  <div nz-row>
    <div nz-col nzSpan="24">
      <p>
        Atualizamos nossos Termos e Condições de uso. Para continuar utilizando a plataforma, é necessário que você leia
        e aceite os nossos <a [href]="term.fileUrl" target="_blank">Termos e Condições</a>.
      </p>
    </div>
  </div>

  <nz-divider></nz-divider>

  <div nz-row class="center">
    <div nz-col nzSpan="24">
      <app-primary-button (click)="closeModal()" class="center"> Li e aceito os Termos e Condições </app-primary-button>
    </div>
  </div>
</main>
