import moment from 'moment';
import { differenceInCalendarDays } from 'date-fns';
import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { CouponModel } from '../../../models/coupon/coupon.model';
import { NzDrawerRef } from 'ng-zorro-antd/drawer';

@Component({
  selector: 'app-drawer-create-coupons',
  templateUrl: './drawer-create-coupons.component.html',
  styleUrls: ['./drawer-create-coupons.component.scss'],
})
export class DrawerCreateCouponsComponent implements OnInit {
  @Input() public data: any;

  public couponForm!: FormGroup;
  public title: string = 'Criar cupom';
  public dateFormat: string = 'dd/MM/yyyy';
  public collection: CouponModel = new CouponModel();

  public disabledDate = (current: Date): boolean => differenceInCalendarDays(current, new Date()) < 0;

  constructor(private readonly fb: FormBuilder, public $drawerRef: NzDrawerRef) {
    this.createForm();
  }

  ngOnInit(): void {
    if (this.data?.id) {
      setTimeout(() => {
        this.updateForm();
      }, 0);
    }
  }

  private updateForm(): void {
    this.title = 'Editar cupom';

    this.couponForm.patchValue({
      id: this.data?.id,
      type: this.data?.type,
      code: this.data?.code,
      discountCents: this.data?.discountCents / 100,
      percent: this.data?.percent,
      maxCoupons: this.data?.maxCoupons,
      maxDiscountCents: this.data?.maxDiscountCents / 100,
      maxDiscountCentsPerOrder: this.data?.maxDiscountCentsPerOrder / 100,
      minTotalCentsForDiscount: this.data?.minTotalCentsForDiscount / 100,
      maxCouponsPerCpf: this.data?.maxCouponsPerCpf,
      maxDiscountCentsPerCpf: this.data?.maxDiscountCentsPerCpf / 100,
      availabilityAt: [moment(this.data?.startDate).toDate(), moment(this.data?.endDate).toDate()],
      isSwitchMinimum: !!this.data?.minTotalCentsForDiscount,
      isSwitchMaximum: !!this.data?.maxDiscountCentsPerOrder,
      isSwitchMaximumCpf: !!this.data?.maxDiscountCentsPerCpf,
      isSwitchLimitCpf: !!this.data?.maxCouponsPerCpf,
    });
  }

  public createForm(): void {
    this.couponForm = this.fb.group({
      id: new FormControl(''),
      type: new FormControl('PERCENT', [Validators.required]),
      code: new FormControl('', [Validators.required]),
      discountCents: new FormControl(0),
      percent: new FormControl(''),
      maxCoupons: new FormControl(null, [Validators.required]),
      maxDiscountCents: new FormControl(0),
      maxDiscountCentsPerOrder: new FormControl(0),
      minTotalCentsForDiscount: new FormControl(0),
      maxCouponsPerCpf: new FormControl(null),
      maxDiscountCentsPerCpf: new FormControl(0),
      availabilityAt: new FormControl([], [Validators.required]),
      isSwitchMinimum: new FormControl(false),
      isSwitchMaximum: new FormControl(false),
      isSwitchMaximumCpf: new FormControl(false),
      isSwitchLimitCpf: new FormControl(false),
      startDate: new FormControl(''),
      endDate: new FormControl(''),
    });
  }

  public removeZeroFromLeft(event: any): void {
    if (event.target.value.length === 0 && event.key === '0') {
      event.preventDefault();
    }
  }

  public setHandleSubmit(): void {
    this.couponForm.patchValue({
      maxDiscountCentsPerCpf: this.couponForm.value.maxDiscountCentsPerCpf * 100,
      maxDiscountCentsPerOrder: this.couponForm.value.maxDiscountCentsPerOrder * 100,
      minTotalCentsForDiscount: this.couponForm.value.minTotalCentsForDiscount * 100,
      discountCents: this.couponForm.value.type !== 'PERCENT' ? this.couponForm.value.discountCents * 100 : 0,
      percent: this.couponForm.value.type === 'PERCENT' ? Number(this.couponForm.value.percent) : 0,
      startDate: this.couponForm.value.availabilityAt[0],
      endDate: this.couponForm.value.availabilityAt[1],
      maxDiscountCents: this.couponForm.value?.maxDiscountCents || 0,
      maxCouponsPerCpf: this.couponForm.value?.maxCouponsPerCpf || 0,
    });

    this.$drawerRef.close(this.couponForm.value);
  }

  public changeTextToUppercase(field) {
    const object = {};
    object[field] = this.couponForm.controls[field].value.toUpperCase();
    this.couponForm.patchValue(object);
  }
}
