import { gql } from 'apollo-angular';

const CREATE_PAYMENT_LINK_MUTATION = gql`
  mutation CreatePaymentLink($params: CreatePaymentLinkParams!) {
    createPaymentLink(params: $params) {
      id
    }
  }
`;

const UPDATE_PAYMENT_LINK_MUTATION = gql`
  mutation UpdatePaymentLink($id: UUID4!, $params: CreatePaymentLinkParams!) {
    updatePaymentLink(id: $id, params: $params) {
      id
    }
  }
`;

const DELETE_PAYMENT_LINK_MUTATION = gql`
  mutation DeletePaymentLink($id: UUID4!) {
    deletePaymentLink(id: $id) {
      id
    }
  }
`;

export { CREATE_PAYMENT_LINK_MUTATION, UPDATE_PAYMENT_LINK_MUTATION, DELETE_PAYMENT_LINK_MUTATION };
