import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';

import { Message } from 'libs/shared/src/lib/utils/message';
import { LoginModel } from 'libs/shared/src/lib/models/login/login.model';
import { AuthService } from 'libs/shared/src/lib/services/auth/auth-business/auth.service';
import { AuthStoreService, ErrorService } from '@gen/shared';
import { StateManagementService } from 'libs/shared/src/lib/state-management/state-management.service';
import { PointModel } from '../../../models/points/point-of-sales.model';

@Component({
  selector: 'app-customer-signin-password',
  templateUrl: './signin-password.component.html',
  styleUrls: ['./signin-password.component.scss'],
})
export class SigninPasswordComponent implements OnInit {
  public rehide: boolean = true;
  public formSignIn!: FormGroup;
  public login: LoginModel = new LoginModel();
  public showPassword: boolean = false;
  public loadingSignIn: boolean = false;
  public loginType: string = 'email';
  public point: PointModel;

  constructor(
    private $auth: AuthService,
    private $storeAuth: AuthStoreService,
    private readonly fb: FormBuilder,
    private $notification: StateManagementService,
    private $error: ErrorService
  ) {}

  public ngOnInit(): void {
    this.createForm();

    this.$notification.points.subscribe((point) => (this.point = point));
  }

  public createForm(): void {
    this.formSignIn = this.fb.group({
      signin: new FormControl('', [Validators.required]),
      password: new FormControl(''),
    });
  }

  public onSignIn(): void {
    this.loadingSignIn = true;
    this.login.password = this.formSignIn.get('password').value;

    this.$auth.loginByPassword(this.login.signin, this.login.password, 'B2C').subscribe(
      (res) => {
        if (res.data?.loginByPassword) {
          this.$storeAuth.customLogin(res.data.loginByPassword.authToken, this.point);
          this.loadingSignIn = false;
        }
      },
      (error) => {
        this.loadingSignIn = false;
        this.$error.errorHandling(error, Message.LOGIN_ERROR);
      }
    );
  }

  public verifyUserLogin(forgotPassword?: boolean): void {
    this.loadingSignIn = true;

    this.maskSelector();
    this.login = new LoginModel();
    this.login.signin =
      this.loginType === 'email' ? this.formSignIn.get('signin').value : '+55' + this.formSignIn.get('signin').value;

    this.$auth.verifyUserLogin(this.login.signin.replace(/[^0-9+]/g, ''), 'B2C').subscribe({
      next: (res) => {
        if (res.data?.verifyUserLogin) {
          const hasPassword = res.data.verifyUserLogin.hasPassword;

          this.loadingSignIn = false;

          if (!hasPassword || forgotPassword) {
            this.goToSelectValidation(res.data.verifyUserLogin);
          } else {
            this.showPassword = true;
          }
        }

        if (res.errors) {
          this.loadingSignIn = false;
          const error = res.errors[0];
          this.$error.errorHandling(error, Message.LOGIN_ERROR);
          throw new Error(error);
        }
      },

      error: (error) => {
        this.loadingSignIn = false;
        this.$error.errorHandling(error, Message.LOGIN_ERROR);
        throw new Error(error);
      },
    });
  }

  public goToSelectValidation(user: any): void {
    this.$notification.setUser(user);
    this.$notification.setLoginActualPage('signin-validation-select');
  }

  public maskSelector(): string | null {
    if (this.formSignIn.get('signin')?.value.length >= 3 && this.formSignIn.get('signin')?.value.charAt(2) === '9') {
      this.loginType = 'phone';
      return '(00) 0 0000-0000';
    } else {
      this.loginType = 'email';
      return null;
    }
  }
}
