<header *nzModalTitle>
  <h3 class="title-category">{{ data.active ? 'Desativar item?' : 'Ativar item?' }}</h3>
  <span class="example-spacer"></span>
  <img src="../../assets/images/close.svg" (click)="$modalRef.close()" class="pointer-cursor" />
</header>

<body>
  <p>
    {{ data.active ? 'Ative o item quando quiser.' : 'Desative o item quando quiser.' }}
  </p>
</body>

<footer *nzModalFooter>
  <div class="buttons">
    <app-secondary-button class="m-r-1" (click)="$modalRef.close()">Cancelar</app-secondary-button>
    <app-primary-button (click)="$modalRef.close('ATUALIZAR')">{{
      data.itemUnits[0].active ? 'Desativar' : 'Ativar'
    }}</app-primary-button>
  </div>
</footer>
