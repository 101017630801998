import { gql } from 'apollo-angular';

const LIST_STORES_QUERY = gql`
  query ListStores {
    listStores {
      id
    }
  }
`;

const GET_STORE_QUERY = gql`
  query GetStore($storeId: UUID4!) {
    getStore(storeId: $storeId) {
      whatsappNumber
      callNumber
      displayName
      mainSegment
      description
      storeCategory {
        id
      }
      tags {
        name
      }
      openingHours
      storePreferences {
        allowInstallment
        allowMethods
        defaultMaxInstallments
        defaultPaymentMethods
        takeOut
        locker
        eatIn
      }
      pointOfSale {
        city
        countryCode
        line1
        line2
        line3
        neighborhood
        postalCode
        state
      }
    }
  }
`;

const GET_STORE_QUERY_BY_SELLER_ID = gql`
  query StoreBySellerId($sellerId: String!) {
    storeBySellerId(sellerId: $sellerId) {
      isVisible
      logo
      whatsappNumber
      whatsappConfirmedAt
      callNumber
      displayName
      mainSegment
      description
      storeCategories {
        id
        name
      }
      tags {
        name
      }
      sellers {
        id
        isBnplActive
        status
        company {
          businessName
        }
        person {
          name
        }
      }
      openingHours {
        sunday {
          closeAt
          isOpen
          openAt
        }
        tuesday {
          closeAt
          isOpen
          openAt
        }
        wednesday {
          closeAt
          isOpen
          openAt
        }
        thursday {
          closeAt
          isOpen
          openAt
        }
        friday {
          closeAt
          isOpen
          openAt
        }
        saturday {
          closeAt
          isOpen
          openAt
        }
        monday {
          closeAt
          isOpen
          openAt
        }
        allDays {
          closeAt
          isOpen
          openAt
        }
      }
      storePreferences {
        allowInstallment
        allowMethods
        defaultMaxInstallments
        defaultPaymentMethods
        takeOut
        eatIn
        locker
      }
      pointOfSale {
        city
        countryCode
        line1
        line2
        line3
        neighborhood
        postalCode
        state
      }
    }
  }
`;

const LIST_STORE_CATEGORIES_QUERY = gql`
  query ListStoreCategories {
    listStoreCategories {
      id
      name
    }
  }
`;

export { LIST_STORES_QUERY, GET_STORE_QUERY, LIST_STORE_CATEGORIES_QUERY, GET_STORE_QUERY_BY_SELLER_ID };
