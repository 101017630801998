<section class="qr_code_generate_modal">
  <span nz-icon nzType="qrcode" nzTheme="outline" class="qrcode"></span>
  <h1>Gerar QR Code</h1>
  <p>Gerar QR Code da {{ type }}?</p>
  <span>
    <b>{{ key }}</b>
  </span>
  <app-primary-button (click)="generateQrCode()" [loading]="loadingButton">Gerar</app-primary-button>
  <app-secondary-button (click)="modalRef.close()">Agora não</app-secondary-button>
</section>
