import { gql } from 'apollo-angular';

const GET_LOCKER_QUERY = gql`
  query LockerSites($cep: String!, $distance: Int) {
    lockerSites(cep: $cep, distance: $distance) {
      externalId
      name
      location {
        additionalInformation
        city
        latitude
        longitude
        neighborhood
        number
        reference
        state
        street
        zipCode
      }
    }
  }
`;

export { GET_LOCKER_QUERY };
