<section class="waiting">
  <div class="p-3 m-t-3">
    <div class="center">
      <img src="../../assets/images/modal_loading.svg" alt="loading Payment" />
    </div>

    <div class="center m-t-3">
      <h2 class="note">Estamos processando seu pagamento!</h2>
    </div>

    <div class="w-100 center centerBlock">
      <div class="button-div2">
        <button nz-button class="w-100" nzSize="large" nzType="primary" (click)="$modalRef.close()">Fechar</button>
      </div>
    </div>
  </div>
</section>
