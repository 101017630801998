<header *nzModalTitle>
  <h3>{{ title }}</h3>
  <img src="/assets/images/close.svg" (click)="$modalRef.close()" class="pointer-cursor" />
</header>

<section>
  <div class="m-b-1">
    <span class="description">Crie uma categoria ou um tipo para os cupons</span>
  </div>
  <form [formGroup]="formGroup">
    <nz-form-item>
      <nz-form-label class="startBlock" [nzNoColon]="true" [nzSm]="24" nzRequired nzFor="type">
        Nome da categoria/tipo
      </nz-form-label>
      <nz-form-control nzHasFeedback nzErrorTip="Campo obrigatório.">
        <input nz-input type="text" id="name" formControlName="name" placeholder="Ex. Top cupons" />
      </nz-form-control>
    </nz-form-item>

    <nz-form-item>
      <nz-radio-group formControlName="type" id="type">
        <label nz-radio nzValue="CATEGORY">Categoria</label>
        <label nz-radio nzValue="TYPE">Tipo</label>
      </nz-radio-group>
    </nz-form-item>
  </form>
</section>

<footer *nzModalFooter>
  <div class="buttons">
    <app-secondary-button (click)="setHandleSubmit(false)">Cancelar</app-secondary-button>
    <app-primary-button (click)="setHandleSubmit(true)" [disabled]="formGroup.invalid">Salvar</app-primary-button>
  </div>
</footer>
