import { UserRole } from '../../types/user-role.type';
import { FirebaseModel } from './firebase.model';

export class UserClaimsModel {
  public aud: string = '';
  public id: string = '';
  public auth_time: number = 0;
  public role?: UserRole;
  public displayName?: string = '';
  public avatar?: string = '';
  public defaultSellerId?: string = '';
  public email: string = '';
  public emailVerified?: boolean = false;
  public firebase: FirebaseModel = new FirebaseModel();
  public marketplaceId: string = '';
  public name: string = '';
  public phone_number: string = ''; // firebase
  public phoneNumber: string = ''; // back
  public picture?: string = ''; // login com gmail
  public sellerId?: string = '';
  public sellerStatus: string = '';
  public user_id: string = ''; //uid
  public userId: string = ''; // id do back
  public env?: string = '';
  public rememberMe?: boolean = false;
  public emailLogin?: string = '';
  public cpf?: string = '';
}
