<section class="in-analysis centerBlock">
  <img
    src="/assets/images/icons/xmark.svg"
    alt="Ícone de para fechar modal"
    class="close-icon"
    (click)="closeModal()"
  />
  <div class="data">
    <img src="/assets/images/ilustrations/analisys-ilustration.svg" alt="Ícone de para ilustrar " />
    <div>
      <h3>Conta em análise</h3>
    </div>
    <div>
      <span> Estamos processando os seus dados e você será informado por e-mail sobre o status da conta. </span>
    </div>
    <div class="w-100">
      <app-primary-button (click)="closeModal()" [block]="true">Entendi</app-primary-button>
    </div>
  </div>
</section>
