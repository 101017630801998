import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'deliveryMethod',
})
export class DeliveryMethodPipe implements PipeTransform {
  transform(value: any, ...args: string[]): string {
    if (value) {
      const methods: any = {
        DELIVERY: 'Entrega',
        EAT_IN: 'Compra física em loja',
        LOCKER: 'Gen Box',
        PICK_AND_GO: 'Retirada em loja',
      };

      return methods[value] || '';
    }

    return '';
  }
}
