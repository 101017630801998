import { Component, Input, OnInit } from '@angular/core';

import { PointOfSalesService } from '../../services/point-of-sales/point-of-sales.service';

@Component({
  selector: 'app-basket-button',
  templateUrl: './basket-button.component.html',
  styleUrls: ['./basket-button.component.scss'],
})
export class BasketButtonComponent implements OnInit {
  @Input() public sellerId: string;
  @Input() public pointId: string;

  public productslength: number = 0;

  constructor(private $points: PointOfSalesService) {}

  ngOnInit(): void {
    this.getBasket();
  }

  public getBasket(): void {
    // this.$points.getBasketProducts(this.sellerId, this.pointId).subscribe((products) => {
    //   this.productslength = products.length;
    // });
  }
}
