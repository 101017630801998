<header *nzModalTitle>
  <h3 class="title-category">{{ title }}</h3>
  <span class="example-spacer"></span>
  <img src="/assets/icons/close.svg" (click)="$modalRef.close()" class="pointer-cursor" />
</header>

<body>
  <form [formGroup]="boxOfficeSessionForm">
    <div nz-row [nzGutter]="32" class="m-b-1">
      <div nz-col nzSpan="12">
        <nz-form-item>
          <label>Horário da sessão</label>
          <nz-form-control nzHasFeedback nzErrorTip="Campo obrigatório" class="w-100" [nzSm]="24" [nzXs]="24">
            <nz-time-picker class="w-100" nzFormat="HH:mm" formControlName="startTime"></nz-time-picker>
          </nz-form-control>
        </nz-form-item>
      </div>

      <div nz-col nzSpan="12">
        <nz-form-item>
          <label>Duração da sessão</label>
          <nz-form-control nzHasFeedback nzErrorTip="Campo obrigatório" class="w-100" [nzSm]="24" [nzXs]="24">
            <nz-time-picker class="w-100" nzFormat="HH:mm" formControlName="endTime"></nz-time-picker>
          </nz-form-control>
        </nz-form-item>
      </div>
    </div>

    <nz-form-item>
      <label>Máximo de pessoas por sessão</label>
      <nz-form-control nzHasFeedback nzErrorTip="Campo obrigatório" class="w-100" [nzSm]="24" [nzXs]="24">
        <nz-input-group nzSize="large">
          <input type="number" placeholder="Insira o número máximo" nz-input formControlName="maxParticipants" />
        </nz-input-group>
      </nz-form-control>
    </nz-form-item>
  </form>
</body>

<footer *nzModalFooter>
  <div class="buttons w-100">
    <app-secondary-button (click)="$modalRef.close()" type="DARK-PURPLE">Cancelar</app-secondary-button>
    <app-primary-button type="DARK-PURPLE" (click)="sendValue()" [disabled]="boxOfficeSessionForm.invalid">
      {{ buttonMessage }}
    </app-primary-button>
  </div>
</footer>
