import { Component } from '@angular/core';
import { NzModalRef } from 'ng-zorro-antd/modal';

@Component({
  selector: 'app-modal-logout',
  templateUrl: './modal-logout.component.html',
  styleUrls: ['./modal-logout.component.scss'],
})
export class ModalLogoutComponent {
  constructor(private modalRef: NzModalRef) {}

  public closed(value: boolean): void {
    this.modalRef.destroy(value);
  }
}
