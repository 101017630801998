<section>
  <main *ngIf="!loading">
    <div class="fee-description">
      <div class="title">
        <h3>Dados Principais</h3>
      </div>

      <div class="fee-info m-t-2">
        <div class="container">
          <div class="content">
            <p>Valor</p>
            <span> {{ fee?.amountCents / 100 | currency : 'R$' }} </span>
          </div>

          <div class="content">
            <p>Status</p>
            <span>
              <app-status-tag [type]="fee.active ? 'ACTIVE' : 'INACTIVE'" gender="female"></app-status-tag>
            </span>
          </div>
        </div>
      </div>

      <div class="complement-info m-t-1">
        <h3>Informações complementares</h3>

        <div class="container">
          <div class="content">
            <p>ID</p>
            <span>{{ fee?.id }}</span>
          </div>

          <div class="content">
            <p>Estabelecimento</p>
            <span>
              {{ fee?.seller?.company?.businessName || fee?.seller?.person?.name || '-' }}
            </span>
          </div>

          <div class="content">
            <p>Método</p>
            <span> {{ fee.method | paymentMethod }} </span>
          </div>

          <div class="content">
            <p>Parcela</p>
            <span> {{ fee.installments }}</span>
          </div>
        </div>
      </div>
    </div>

    <hr />
  </main>
  <div class="center m-t-2 m-b-2" *ngIf="loading">
    <nz-spin nzSize="large"></nz-spin>
  </div>
</section>
