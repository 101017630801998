<main id="create-contacts" [ngClass]="sellerId ? 'padding-3' : ''">
  <section id="header">
    <nz-row>
      <nz-col nzSpan="12">
        <span class="title">Importar cadastro de itens</span>
      </nz-col>
      <nz-col nzSpan="12" class="endBlock">
        <img
          src="/assets/icons/xmark.svg"
          alt="Ícone para fechar o modal"
          class="pointer-cursor"
          (click)="closeModal()"
        />
      </nz-col>
    </nz-row>
  </section>

  <nz-divider></nz-divider>

  <section id="body">
    <form [formGroup]="formGroup">
      <nz-row class="m-b-1">
        <nz-col nzSpan="24" class="justify">
          <div class="d-inline w-100">
            <span class="subtitle"> Selecione um arquivo CSV para importar o cadastro de contatos. </span>
            <a href="/assets/files/contacts-model.csv" download="sample_import_items" class="subtitle-link">
              Exemplo de listagem de contatos.
            </a>
          </div>
        </nz-col>
      </nz-row>
      @if (!sellerId) {
      <nz-row>
        <nz-col nzSpan="24">
          <nz-form-item>
            <nz-form-label class="startBlock input-text" [nzNoColon]="true" [nzSm]="24" nzFor="sellerId">
              Lojas
            </nz-form-label>
            <nz-form-control [nzSm]="24" [nzXs]="24" nzHasFeedback nzErrorTip="Campo obrigatório.">
              <nz-select id="sellerId" nzShowSearch formControlName="sellerId" nzPlaceHolder="Escolha um seller">
                <nz-option
                  *ngFor="let seller of sellers"
                  [nzLabel]="
                    (seller.store?.displayName || seller.company?.businessName || seller.person?.name) +
                    ' - ' +
                    ((seller.cnpj | mask : '00.000.000/0000-00') || (seller.cpf | mask : '000.000.000-00'))
                  "
                  [nzValue]="seller.id"
                ></nz-option>
              </nz-select>
            </nz-form-control>
          </nz-form-item>
        </nz-col>
      </nz-row>
      }
      <nz-row>
        <nz-col nzSpan="24">
          <nz-upload
            nzType="drag"
            (nzChange)="handleChange($event)"
            [nzCustomRequest]="customRequest"
            nzAccept=".xls, .xlsx, .csv"
          >
            <p class="ant-upload-drag-icon">
              <span nz-icon nzType="inbox"></span>
            </p>
            <p class="ant-upload-text">Clique ou arraste o arquivo para a área de upload.</p>
          </nz-upload>
        </nz-col>
      </nz-row>
    </form>
  </section>

  <nz-divider></nz-divider>

  <section id="footer">
    <nz-row [nzGutter]="24">
      <nz-col nzSpan="12">
        <app-secondary-button type="RED" [block]="true" class="w-100 center" (click)="closeModal()">
          Cancelar
        </app-secondary-button>
      </nz-col>
      <nz-col nzSpan="12">
        <app-primary-button
          [block]="true"
          class="w-100 center"
          [disabled]="formGroup.invalid || loading"
          [loading]="loading"
          (click)="createContacts()"
        >
          Importar
        </app-primary-button>
      </nz-col>
    </nz-row>
  </section>
</main>
