import { Component, Input, OnInit } from '@angular/core';
import { NzModalRef } from 'ng-zorro-antd/modal';
import { PointModel } from '../../models/points/point-of-sales.model';
import { StateManagementService } from '../../state-management/state-management.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  @Input() public point: PointModel;

  public title: string = '';
  public showLogo: boolean = true;
  public loading: boolean = false;
  public showAlert: boolean = false;
  public typeAlert: string = '';
  public alertMessage: string = '';
  public showAlertCreateAccount: boolean = false;

  public appDownloadUrl: string = 'https://play.google.com/store/apps/details?id=br.com.gen.business';
  public isMobileScreen: boolean = window.innerWidth < 768;

  public loginActualPage:
    | 'signin-password'
    | 'signin-validation-select'
    | 'signin-code-verify'
    | 'signin-create-password' = `signin-password`;

  constructor(private $notification: StateManagementService, public readonly modalRef: NzModalRef) {}

  public ngOnInit(): void {
    this.checkDevice();
    this.getNotifications();
  }

  public getNotifications(): void {
    this.$notification.loginActualPage.subscribe((page) => {
      if (page) {
        this.loginActualPage = page;
      }
    });
  }

  public checkDevice(): void {
    if (
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Mobile|mobile|CriOS/i.test(navigator.userAgent)
    ) {
      if (/android/i.test(navigator.userAgent)) {
        this.appDownloadUrl = 'https://play.google.com/store/apps/details?id=br.com.gen&hl=en_AU&gl=US&pli=1';
      }

      if (/iPad|iPhone|iPod/.test(navigator.userAgent)) {
        this.appDownloadUrl = 'https://apps.apple.com/br/app/gen-promo%C3%A7%C3%B5es-e-descontos/id6443802221';
      }
    }
  }

  public closeModal(value: boolean): void {
    this.modalRef.close(value);
  }

  public setTitle(): void {
    if (this.loginActualPage === 'signin-validation-select') {
      this.title = 'Valide sua conta';
      this.showLogo = false;
    } else if (this.loginActualPage === 'signin-code-verify') {
      this.title = 'Insira o código';
      this.showLogo = false;
    } else if (this.loginActualPage === 'signin-create-password') {
      this.title = 'Cadastre sua senha';
      this.showLogo = false;
    } else {
      this.title = '';
      this.showLogo = true;
    }
  }
}
