import { Clipboard } from '@angular/cdk/clipboard';
import { NzMessageService } from 'ng-zorro-antd/message';
import { Component, Input, ViewChild, ElementRef, TemplateRef } from '@angular/core';

import { downloadImgFromHtml } from 'libs/shared/src/lib/utils/utils';

@Component({
  selector: 'app-qr-code-success',
  templateUrl: './qr-code-success.component.html',
  styleUrls: ['./qr-code-success.component.scss'],
})
export class QrCodeSuccessComponent {
  @ViewChild('qrCodeEl') private qrCodeElement: ElementRef;
  @ViewChild('customToaster') private toaster: TemplateRef<any>;

  @Input() public key: string;
  @Input() public type: string;
  @Input() public qrCode: string;

  constructor(private clipboard: Clipboard, private $message: NzMessageService) {}

  public copy(): void {
    if (this?.qrCode) {
      this.clipboard.copy(this?.qrCode);
      this.$message.create('', this.toaster);
    } else {
      this.$message.error('Código não definido.');
    }
  }

  public removeToaster(): void {
    this.$message.remove();
  }

  public downloadImg(): void {
    const element = this.qrCodeElement.nativeElement;
    downloadImgFromHtml(element, 'qr-code');
  }
}
