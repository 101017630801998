import { gql } from 'apollo-angular';

const LIST_SELLER_BNPL_CHECKOUTS_QUERY = gql`
  query ListSellerBnplCheckouts($sellerId: UUID4!, $filters: FilterBnplCheckoutsParams) {
    listSellerBnplCheckouts(
      sellerId: $sellerId
      filters: $filters
      pagination: { orderBy: "inserted_at", sortingOrder: DESC, perPage: 100 }
    ) {
      status
      downPayment
      totalAmount
      payment {
        id
        status
        insertedAt
        subtotalCents
        publicCustomer {
          name
        }
        order {
          id
          deliveryStatus
        }
      }
    }
  }
`;

const LIST_BNPL_CHECKOUTS_QUERY = gql`
  query ListBnplCheckouts($filters: FilterBnplCheckoutsParams) {
    listBnplCheckouts(filters: $filters, pagination: { orderBy: "inserted_at", sortingOrder: DESC, perPage: 100 }) {
      status
      downPayment
      totalAmount
      liquidationPreviewDate
      liquidationDate
      liquidationStatus
      receivementStatus
      receivementPreviewDate
      insertedAt
      payment {
        status
        seller {
          id
        }
        publicCustomer {
          name
        }
        order {
          id
          deliveryStatus
          status
        }
      }
    }
  }
`;

const GET_SELLER_BNPL_CHECKOUT_BALANCE_QUERY = gql`
  query GetSellerBnplCheckoutBalance($sellerId: UUID4!, $filters: FilterBnplCheckoutsParams) {
    getSellerBnplCheckoutBalance(sellerId: $sellerId, filters: $filters) {
      paidBalanceCents
      pendingBalanceCents
    }
  }
`;

export { LIST_SELLER_BNPL_CHECKOUTS_QUERY, LIST_BNPL_CHECKOUTS_QUERY, GET_SELLER_BNPL_CHECKOUT_BALANCE_QUERY };
