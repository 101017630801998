<section class="export_inventory_modal">
  <div class="header between">
    <h1 class="body-txt-bold">Exportar estoque</h1>
    <span nz-icon nzType="close" nzTheme="outline" class="close-icon" (click)="modalRef.close()"></span>
  </div>
  <nz-divider></nz-divider>
  <form [formGroup]="form">
    <nz-form-item>
      <nz-form-control>
        <nz-radio-group class="column" formControlName="type">
          <label nz-radio nzValue="initial-page" class="body-txt">Pagina inicial</label>
          <label nz-radio nzValue="all-variant" class="body-txt">Todas as variantes</label>
          <label nz-radio nzValue="main-items-without-variant" class="body-txt"
            >Apenas os itens principais sem variantes</label
          >
        </nz-radio-group>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item class="startBlock formItem">
      <label class="smaller-light d-block exportTitle"> Exportar como </label>
      <nz-form-control formControlName="fileType">
        <nz-radio-group>
          <label nz-radio nzValue="csv" class="body-txt">CSV</label>
        </nz-radio-group>
      </nz-form-control>
    </nz-form-item>
  </form>
  <a class="smaller-light">Saiba mais sobre exportar estoque</a>
  <nz-divider></nz-divider>
  <div class="between">
    <app-secondary-button (click)="modalRef.close()">Cancelar</app-secondary-button>
    <app-primary-button>Exportar</app-primary-button>
  </div>
</section>
