import { Component, Input } from '@angular/core';
import { NzDrawerRef } from 'ng-zorro-antd/drawer';

import { OrderModel } from '../../../models/orders/order.model';

@Component({
  selector: 'app-drawer-confirmation-delivery-order',
  templateUrl: './drawer-confirmation-delivery-order.component.html',
  styleUrls: ['./drawer-confirmation-delivery-order.component.scss'],
})
export class DrawerConfirmationDeliveryOrderComponent {
  @Input() order: OrderModel;

  public isShowSuccessButton: boolean = false;

  constructor(public readonly drawerRef: NzDrawerRef) {}

  public closeModal(confirm: boolean): void {
    this.isShowSuccessButton = true;

    setTimeout(() => {
      this.drawerRef.close(confirm);
    }, 1000);
  }
}
