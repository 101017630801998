<section>
  <div class="center m-t-2 m-b-2" *ngIf="loading">
    <nz-spin nzSize="large"></nz-spin>
  </div>
</section>

<section>
  <header class="modal-header" *nzModalTitle>
    <h3 class="title-category">Alterar Telefone</h3>
    <span class="example-spacer"></span>
    <img src="../../assets/images/close.svg" (click)="$modalRef.close()" class="pointer-cursor" />
  </header>

  <body>
    <div *ngIf="!loading">
      <div>
        <div>
          Digite o código de validação enviado por SMS para o número
          <strong>{{ data.newPhone | mask : '(00) 0 0000-0000' }}</strong>
        </div>
        <div class="m-t-2">
          <form [formGroup]="formCode" (keydown.enter)="$event.preventDefault()">
            <div class="inputCode-container">
              <div>
                <mat-form-field class="input-valitadion">
                  <input
                    nz-input
                    #input
                    name="code1"
                    formControlName="code1"
                    maxlength="1"
                    type="tel"
                    (input)="onInput($event, 1)"
                    (paste)="pasteNumeric($event)"
                  />
                </mat-form-field>
              </div>
              <div>
                <mat-form-field class="input-valitadion">
                  <input
                    nz-input
                    #input
                    name="code2"
                    formControlName="code2"
                    maxlength="1"
                    type="tel"
                    (input)="onInput($event, 2)"
                    (keyup)="onPressDeleteKey($event, 0)"
                    (paste)="pasteNumeric($event)"
                  />
                </mat-form-field>
              </div>
              <div>
                <mat-form-field class="input-valitadion">
                  <input
                    nz-input
                    #input
                    name="code3"
                    formControlName="code3"
                    maxlength="1"
                    type="tel"
                    (input)="onInput($event, 3)"
                    (keyup)="onPressDeleteKey($event, 1)"
                    (paste)="pasteNumeric($event)"
                  />
                </mat-form-field>
              </div>
              <div>
                <mat-form-field class="input-valitadion">
                  <input
                    nz-input
                    #input
                    name="code4"
                    formControlName="code4"
                    maxlength="1"
                    type="tel"
                    (input)="onInput($event, 4)"
                    (keyup)="onPressDeleteKey($event, 2)"
                    (paste)="pasteNumeric($event)"
                  />
                </mat-form-field>
              </div>
              <div>
                <mat-form-field class="input-valitadion">
                  <input
                    nz-input
                    #input
                    name="code5"
                    formControlName="code5"
                    maxlength="1"
                    type="tel"
                    (input)="onInput($event, 5)"
                    (keyup)="onPressDeleteKey($event, 3)"
                    (paste)="pasteNumeric($event)"
                  />
                </mat-form-field>
              </div>
              <div>
                <mat-form-field class="input-valitadion">
                  <input
                    nz-input
                    #input
                    name="code6"
                    formControlName="code6"
                    maxlength="1"
                    type="tel"
                    (input)="savePhone()"
                    (keyup)="onPressDeleteKey($event, 4)"
                    (paste)="pasteNumeric($event)"
                  />
                </mat-form-field>
              </div>
            </div>

            <div class="sendEmailCode">
              Não recebeu o código?
              <button nz-button class="fs-14 fw-700" [disabled]="countdown > 0" (click)="resendSmsCode()">
                ENVIAR NOVAMENTE
                <span class="countdown" *ngIf="countdown > 0"> ({{ countdown }}s)</span>
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </body>

  <footer class="modal-footer" *nzModalFooter>
    <div class="buttons">
      <app-primary-button (click)="savePhone()">CONFIRMAR CÓDIGO</app-primary-button>
    </div>
  </footer>
</section>
