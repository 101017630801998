import { gql } from '@apollo/client/core';

const LIST_PAYMENT_LINK_QUERY = gql`
  query ListPaymentLinks($filters: FiltersPaymentLink!) {
    listPaymentLinks(filters: $filters, pagination: { orderBy: "inserted_at", sortingOrder: DESC, perPage: 100 }) {
      active
      amountCents
      id
      insertedAt
      type
      description
    }
  }
`;

const GET_PAYMENT_LINK_QUERY = gql`
  query GetPaymentLink($id: UUID4!) {
    getPaymentLink(id: $id) {
      active
      allowCupons
      allowQuantityChanges
      amountCents
      description
      expireDate
      id
      insertedAt
      paymentMethods
      redirectTo
      type
      publicSeller {
        id
        cpf
        cnpj
        displayName
        company {
          businessName
        }
        person {
          name
        }
      }
    }
  }
`;

export { LIST_PAYMENT_LINK_QUERY, GET_PAYMENT_LINK_QUERY };
