import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'document',
})
export class DocumentPipe implements PipeTransform {
  transform(value: any, ...args: string[]): string {
    if (value) {
      const types: any = {
        selfie: 'Foto do Rosto',
        personIdFront: 'Identidade - Frente',
        personIdBack: 'Identidade - Verso',
        proofOfResidence: 'Cartão CNPJ',
        socialContract: 'Contrato Social',
        qsaIdBack: 'Documento pessoal - Verso',
        qsaIdFront: 'Documento pessoal - Frente',
        admIdBack: 'Documento sócio - Verso',
        admIdFront: 'Documento sócio - Frente',
      };

      return types[value] || '';
    }

    return '';
  }
}
