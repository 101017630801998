<header class="modal-header" *nzModalTitle>
  <h3 class="title-error">Não conseguimos efetuar o pagamento</h3>
  <span class="example-spacer"></span>
  <img src="../../assets/svg/close.svg" (click)="$modalRef.close()" class="pointer-cursor" />
</header>

<section class="modal-body">
  <span class="note">
    {{ errorMsg }}
  </span>
</section>

<footer class="modal-footer" *nzModalFooter>
  <div class="buttons2">
    <button nz-button (click)="tryAgain()" class="button-txt" nzType="primary">Tentar novamente</button>
  </div>
</footer>
