import { Observable } from 'rxjs';
import { Injectable, Query } from '@angular/core';
import { Apollo, Mutation } from 'apollo-angular';

import {
  CREATE_BANNER_MUTATION,
  UPDATE_BANNER_MUTATION,
  DELETE_BANNER_MUTATION,
  CREATE_BANNER_SELECTION_MUTATION,
  UPDATE_BANNER_SELECTION_MUTATION,
  DELETE_BANNER_SELECTION_MUTATION,
} from '../../graphql/mutations/banner.mutations';
import { BannerModel } from '../../models/event/banner.model';
import {
  LIST_BANNERS_QUERY,
  GET_BANNER_BY_ID_QUERY,
  LIST_SELECTED_BANNERS_QUERY,
  GET_SELECTED_BANNER_BY_ID_QUERY,
} from '../../graphql/queries/banner.queries';
import { BannerDisplayAtType } from '../../types/banner-display-at.type';
import { BannerGroupingModel } from '../../models/event/banner-grouping.model';
import { PaginationModel } from '../../models/pagination/pagination.model';
import { FilterBannerModel } from '../../models/banner/banner-filter.model';

@Injectable()
export class BannerService {
  constructor(private readonly apollo: Apollo) {}

  public getBanners(filters?: FilterBannerModel, pagination?: PaginationModel): Observable<any> {
    return this.apollo.watchQuery<Query>({
      query: LIST_BANNERS_QUERY,
      variables: { filters, pagination },
      fetchPolicy: 'network-only',
    }).valueChanges;
  }

  public getBannerById(id: string): Observable<any> {
    return this.apollo.watchQuery<Query>({
      query: GET_BANNER_BY_ID_QUERY,
      variables: { id },
      fetchPolicy: 'cache-and-network',
    }).valueChanges;
  }

  public getBannersGrouping(displayAt?: Array<BannerDisplayAtType>): Observable<any> {
    return this.apollo.watchQuery<Query>({
      query: LIST_SELECTED_BANNERS_QUERY,
      variables: { displayAt },
      fetchPolicy: 'cache-and-network',
    }).valueChanges;
  }

  public getBannerGroupingById(id: string): Observable<any> {
    return this.apollo.watchQuery<Query>({
      query: GET_SELECTED_BANNER_BY_ID_QUERY,
      variables: { id },
      fetchPolicy: 'cache-and-network',
    }).valueChanges;
  }

  public createBanner(data: BannerModel): Observable<any> {
    return this.apollo.mutate<Mutation>({
      mutation: CREATE_BANNER_MUTATION,
      variables: data,
    });
  }

  public updateBanner(data: BannerModel): Observable<any> {
    return this.apollo.mutate<Mutation>({
      mutation: UPDATE_BANNER_MUTATION,
      variables: data,
    });
  }

  public deleteBanner(id: string): Observable<any> {
    return this.apollo.mutate<Mutation>({
      mutation: DELETE_BANNER_MUTATION,
      variables: { id },
    });
  }

  public createBannerGrouping(data: BannerGroupingModel): Observable<any> {
    return this.apollo.mutate<Mutation>({
      mutation: CREATE_BANNER_SELECTION_MUTATION,
      variables: data,
    });
  }

  public updateBannerGrouping(data: BannerGroupingModel): Observable<any> {
    return this.apollo.mutate<Mutation>({
      mutation: UPDATE_BANNER_SELECTION_MUTATION,
      variables: data,
    });
  }

  public deleteBannerGrouping(id: string): Observable<any> {
    return this.apollo.mutate<Mutation>({
      mutation: DELETE_BANNER_SELECTION_MUTATION,
      variables: { id },
    });
  }
}
