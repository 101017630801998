import { Component, OnInit } from '@angular/core';
import { NzModalService } from 'ng-zorro-antd/modal';

import { UserModel } from '../../models/users/users.model';
import { AuthService } from '../../services/auth/auth-business/auth.service';
import { ModalLogoutComponent } from '../modals/modal-logout/modal-logout.component';
import { StateManagementService } from '../../state-management/state-management.service';

@Component({
  selector: 'app-navbar-sub-seller',
  templateUrl: './navbar-sub-seller.component.html',
  styleUrls: ['./navbar-sub-seller.component.scss'],
})
export class NavbarSubSellerComponent implements OnInit {
  public user: UserModel = new UserModel();

  constructor(
    private $auth: AuthService,
    private readonly $modal: NzModalService,
    private $notification: StateManagementService
  ) {}

  public ngOnInit(): void {
    this.getUser();
  }

  private getUser(): void {
    this.$notification.users.subscribe((res) => {
      if (res) {
        this.user = res;
      }
    });
  }

  public logout(): void {
    this.$modal
      .create({
        nzContent: ModalLogoutComponent,
      })
      .afterClose.subscribe((res: boolean) => {
        if (res) {
          this.$auth.logout();
        }
      });
  }
}
