import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { AddressService } from 'libs/shared/src/lib/services/address/address.service';
import { NzDrawerRef } from 'ng-zorro-antd/drawer';

@Component({
  selector: 'app-drawer-pending-company-create-user',
  templateUrl: './drawer-pending-company-create-user.component.html',
  styleUrls: ['./drawer-pending-company-create-user.component.scss'],
})
export class DrawerPendingCompanyCreateUserComponent implements OnInit {
  public formCreateUser: any;

  constructor(private fb: FormBuilder, private $address: AddressService, private drawerRef: NzDrawerRef) {}

  public ngOnInit(): void {
    this.createForm();
  }

  public createForm(): void {
    this.formCreateUser = this.fb.group({
      personalDocument: new FormControl('', [Validators.required]),
      name: new FormControl('', [Validators.required]),
      telephone: new FormControl(),
      email: new FormControl('', [Validators.required]),
      businessDocument: new FormControl('', [Validators.required]),
      socialReason: new FormControl('', [Validators.required]),
      zipCode: new FormControl('', [Validators.required]),
      street: new FormControl('', [Validators.required]),
      number: new FormControl('', [Validators.required]),
      complement: new FormControl(),
      district: new FormControl('', [Validators.required]),
      city: new FormControl('', [Validators.required]),
    });
  }

  public getFormValues(): void {
    this.drawerRef.close(this.formCreateUser.value);
  }

  public getZipCode(value: string, type?: string): void {
    if (value.length === 8) {
      this.$address.getCep(value).subscribe({
        next: (res: any) => {
          this.formCreateUser.patchValue({
            city: res.localidade,
            state: res.uf,
            street: res.logradouro,
            district: res.bairro,
          });
        },
      });
    }
  }
}
