import { CategoryModel } from '../../categories/categories.model';
import { ItemTagModel } from '../../itemTags/itemTag.model';
import { SellerModel } from '../../sellers/sellers.model';
import { ItemUnitModel } from './itemUnit.model';
import { ItemVariantModel } from './itemVariant.model';

export class ItemsV2Model {
  public id?: string;
  public originalId?: string;
  public name: string;
  public description?: string;
  public type: Types;
  public storeId: string;
  public sellerId: string;
  public quantity?: number;
  public loading?: boolean;
  public categoryName?: string;
  public details: string;
  public price?: number;
  public selectedItemUnit?: ItemUnitModel;
  public maxPriceCents?: number;
  public totalStockCount?: number;
  public minPriceCents?: number;
  public itemTags: ItemTagModel[] = [];
  public imageUrl?: string;
  public itemCategory: CategoryModel = new CategoryModel();
  public itemVariants: ItemVariantModel[] = [];
  public itemUnits: ItemUnitModel[] = [];
  public images: ItemImages[];
  public selected?: boolean;
  public seller: SellerModel = new SellerModel();
  public updatedAt: string;
  public insertedAt: string;
  public priceRange: { min: number; max: number } = { min: 0, max: 0 };
}

export type Types = 'PRODUCT' | 'SERVICE';
export type ItemImages = {
  description: String;
  id: string;
  insertedAt: string;
  orderIndex: number;
  updatedAt: string;
  url: string;
};
