<button
  nz-button
  nzType="default"
  [disabled]="disabled"
  [nzLoading]="loading"
  [nzBlock]="block"
  [ngClass]="
    type === 'RED'
      ? 'button button__red'
      : '' || type === 'BOLD'
      ? 'button button__bold'
      : '' || type === 'MARKETPLACE'
      ? 'button button__marketplace'
      : '' || type === 'BLACK'
      ? 'button button__black'
      : '' || type === 'BORDLESS'
      ? 'button button__bordless'
      : '' || type === 'DARK-PURPLE'
      ? 'button button__dark-purple'
      : '' || type === 'BORDERLESS'
      ? 'button button__BORDERLESS'
      : ''
  "
>
  <span nz-icon *ngIf="icon && !iconAfterText" [nzType]="icon"></span>
  <img *ngIf="iconImg && !iconImgAfterText" class="m-r-1" [src]="iconImg" [alt]="alt" />
  <span *ngIf="label">{{ label }}</span>
  <ng-content></ng-content>
  <img *ngIf="iconImg && iconImgAfterText" class="m-l-1" [src]="iconImg" [alt]="alt" />
  <span nz-icon *ngIf="icon && iconAfterText" [nzType]="icon"></span>
</button>
