<section class="pix_confirmation_modal">
  <h1 class="colorPrimary">É importante saber</h1>
  <p class="description">Quem te pagar através dessa chave verá alguns dos seus dados:</p>
  <div class="wrapper-data">
    <div class="labels">
      <p>
        Nome
        <span *ngIf="seller?.cnpj"> da empresa</span>
        :
      </p>
      <p>
        Seu
        <span *ngIf="seller?.cnpj">CNPJ</span>
        <span *ngIf="!seller?.cnpj">CPF</span>
        :
      </p>
    </div>
    <div class="data">
      <h2>
        {{ seller?.company?.businessName || seller?.person?.name || '-' }}
      </h2>
      <h2 *ngIf="seller?.cnpj">{{ (seller?.cnpj | mask : '00.000.000/0000-00') || '-' }}</h2>
      <h2 *ngIf="!seller?.cnpj">{{ (seller?.cpf | mask : '000.000.000-00') || '-' }}</h2>
    </div>
  </div>
  <div class="pix-key">
    <span nz-icon nzType="key" nzTheme="outline"></span>
    <div>
      <h2>{{ type }}</h2>
      <p>
        O código da sua chave é <b>{{ key }}</b>
      </p>
    </div>
    <span class="example-spacer"></span>
  </div>
  <div class="wrapper-buttons">
    <div class="buttons">
      <app-primary-button (click)="confirmQrCode()" [block]="true" class="w-100" [loading]="loadingBtn">
        Confirmar
      </app-primary-button>
      <app-secondary-button (click)="$modalRef.close()" [block]="true" class="w-100">Agora não</app-secondary-button>
    </div>
  </div>
</section>
