import { Location } from '@angular/common';
import { Subject, filter, takeUntil } from 'rxjs';
import { NzModalService } from 'ng-zorro-antd/modal';
import { NavigationEnd, Router } from '@angular/router';
import { Component, OnDestroy, OnInit } from '@angular/core';

import { Message } from '../../utils/message';
import { shortenName } from '../../utils/utils';
import { LeadModel } from '../../models/lead/lead.model';
import { ErrorService } from '../../services/error/error.service';
import { FidelityService } from '../../services/fidelity/fidelity.service';
import { StateManagementService } from '../../state-management/state-management.service';
import { CouponCategoryFidelityModel } from '../../models/fidelity/coupon-category-fidelity.model';
import { ModalLeadOnboardingComponent } from '../modals/modal-lead-onboarding/modal-lead-onboarding.component';

@Component({
  selector: 'app-coupon-header',
  templateUrl: './coupon-header.component.html',
  styleUrls: ['./coupon-header.component.scss'],
})
export class CouponHeaderComponent implements OnInit, OnDestroy {
  public categories: Array<CouponCategoryFidelityModel> = [];
  public lead: LeadModel = new LeadModel();
  public isCollapsed: boolean = false;
  public activeRoute: string = '';
  public title: string = '';
  private destroy$ = new Subject();

  constructor(
    private $error: ErrorService,
    private readonly router: Router,
    private $fidelity: FidelityService,
    private readonly location: Location,
    private readonly $modal: NzModalService,
    private $notification: StateManagementService
  ) {}

  public ngOnInit(): void {
    this.getTitle();
    this.getLead();
    this.getAllCategories();
    this.subscribeToRouteChanges();
  }

  public ngOnDestroy(): void {
    this.destroy$.next(1);
    this.destroy$.complete();
  }

  private getTitle(): void {
    this.$notification.titles.subscribe((res) => {
      this.title = res;
    });
  }

  private getLead(): void {
    this.$notification.leads.subscribe((lead) => {
      if (lead?.id) {
        this.lead = lead;
        this.lead.name = shortenName(this.lead.name);
      }
    });
  }

  public navigateToHome(): void {
    this.router.navigate(['/']);
  }

  public getAllCategories(): void {
    this.$fidelity
      .getCouponCategoriesList()
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (res) => {
          if (res?.data?.allCategoriesCoupon) {
            this.categories = JSON.parse(JSON.stringify(res.data.allCategoriesCoupon));
          }
        },
        error: (error) => {
          this.$error.errorHandling(error, Message.ERROR_CONECTION);
        },
      });
  }

  public subscribeToRouteChanges() {
    if (
      this.router.isActive('/home', {
        paths: 'subset',
        queryParams: 'subset',
        matrixParams: 'ignored',
        fragment: 'ignored',
      })
    ) {
      this.activeRoute = '/home';
    } else {
      this.activeRoute = '';
    }

    this.router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe((event: NavigationEnd) => {
      this.activeRoute = event.urlAfterRedirects;
    });
  }

  public openLoginModal(): void {
    this.$modal.create({
      nzTitle: null,
      nzContent: ModalLeadOnboardingComponent,
      nzFooter: null,
      nzCentered: true,
      nzClosable: true,
      nzClassName: 'ant-modal-fullscreen',
    });
  }

  public goToCategoryPage(id: string): void {
    this.router
      .navigateByUrl('/', { skipLocationChange: true })
      .then(() => this.router.navigate([`/categories/${id}`]));
  }

  public logout(): void {
    sessionStorage.clear();

    this.router.navigate(['/']).then(() => {
      window.location.reload();
    });
  }

  public toggleMenu(): void {
    this.isCollapsed = true;
    this.$notification.setCollapsedSidenav(this.isCollapsed);
  }

  public goBack(): void {
    const locationState: any = this.location.getState();

    if (locationState.navigationId > 1) {
      this.location.back();
    } else {
      this.router.navigate(['/']);
    }
  }

  public trackByFn(index: number, category: CouponCategoryFidelityModel): string {
    return category.id;
  }
}
