<section>
  <main>
    <form [formGroup]="formCreateUser">
      <nz-form-item>
        <label>CPF</label>
        <nz-form-control nzErrorTip="Preenchimento incorreto / sem preenchimento">
          <input nz-input formControlName="personalDocument" mask="000.000.000-00" placeholder="000.000.000-00" />
        </nz-form-control>
      </nz-form-item>

      <nz-form-item>
        <label>Nome</label>
        <nz-form-control nzErrorTip="Preenchimento incorreto / sem preenchimento">
          <input nz-input formControlName="name" placeholder="Nome completo" />
        </nz-form-control>
      </nz-form-item>

      <nz-form-item>
        <label>Telefone (celular)</label>
        <nz-form-control nzErrorTip="">
          <input nz-input formControlName="telephone" placeholder="(00) 0 0000-0000" />
        </nz-form-control>
      </nz-form-item>

      <nz-form-item>
        <label>E-mail</label>
        <nz-form-control nzErrorTip="Preenchimento incorreto / sem preenchimento">
          <input nz-input formControlName="email" placeholder="Digite o e-mail" />
        </nz-form-control>
      </nz-form-item>

      <nz-form-item>
        <label>CNPJ</label>
        <nz-form-control nzErrorTip="Preenchimento incorreto / sem preenchimento">
          <input nz-input formControlName="businessDocument" mask="000.000.000-00" placeholder="123456789" />
        </nz-form-control>
      </nz-form-item>

      <nz-form-item>
        <label>Razão social</label>
        <nz-form-control nzErrorTip="Campo o">
          <input nz-input formControlName="socialReason" placeholder="123456789" />
        </nz-form-control>
      </nz-form-item>

      <nz-form-item>
        <label>CEP</label>
        <nz-form-control nzErrorTip="Preenchimento incorreto / sem preenchimento">
          <input
            nz-input
            formControlName="zipCode"
            placeholder="123456789"
            (keyup)="getZipCode(formCreateUser.get('zipCode').value)"
            mask="00000-000"
          />
        </nz-form-control>
      </nz-form-item>
      <a href="">Não sei meu CEP</a>

      <nz-form-item>
        <label>Rua</label>
        <nz-form-control nzErrorTip="Preenchimento incorreto / sem preenchimento">
          <input nz-input formControlName="street" placeholder="ABCD" />
        </nz-form-control>
      </nz-form-item>

      <div class="row">
        <nz-form-item>
          <label>Número</label>
          <nz-form-control nzErrorTip="Preenchimento incorreto / sem preenchimento">
            <input nz-input formControlName="number" placeholder="123" />
          </nz-form-control>
        </nz-form-item>

        <nz-form-item>
          <label>Complemento</label>
          <nz-form-control nzErrorTip="Preenchimento incorreto / sem preenchimento">
            <input nz-input formControlName="complement" placeholder="123A" />
          </nz-form-control>
        </nz-form-item>
      </div>

      <nz-form-item>
        <label>Bairro</label>
        <nz-form-control nzErrorTip="Preenchimento incorreto / sem preenchimento">
          <input nz-input formControlName="district" placeholder="ABCD" />
        </nz-form-control>
      </nz-form-item>

      <nz-form-item>
        <label>Cidade</label>
        <nz-form-control nzErrorTip="Preenchimento incorreto / sem preenchimento">
          <input nz-input formControlName="city" placeholder="ABCD" />
        </nz-form-control>
      </nz-form-item>

      <div class="m-t-2">
        <app-primary-button [block]="true" icon="user" (click)="getFormValues()" [disabled]="formCreateUser.invalid"
          >Criar</app-primary-button
        >
      </div>
    </form>
  </main>
</section>
