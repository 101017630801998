import { SidenavModel } from '../models/utils/sidenav.model';
import { SidenMenuModel } from '../models/side-menu/side-menu.model';

const balanceRoutes: SidenavModel[] = [
  {
    title: 'Saldos',
    route: '/internal/balances',
    disable: false,
  },
  {
    title: 'Extratos',
    route: '/internal/extract',
    disable: false,
  },
  {
    title: 'Pix',
    route: '/internal/pix',
    disable: false,
  },
];

const contactRoutes: SidenavModel[] = [
  {
    title: 'Contato',
    route: '/internal/contacts',
    disable: false,
  },
  {
    title: 'Empresas',
    route: '/internal/companies',
    disable: false,
  },
  {
    title: 'Segmentação e listas',
    route: '/internal/segmentation',
    disable: false,
  },
];

const itemRoutes: SidenavModel[] = [
  {
    title: 'Itens',
    route: '/internal/inventory/product',
    disable: false,
  },
  {
    title: 'Categorias',
    route: '/internal/inventory/category',
    disable: false,
  },
  {
    title: 'Variações',
    route: '/internal/inventory/option',
    disable: false,
  },
];

const orderRoutes: SidenavModel[] = [
  {
    title: 'Pedidos',
    route: '/internal/orders',
    disable: false,
  },
  {
    title: 'Transações',
    route: '/internal/transactions',
    disable: false,
  },
];

const fidelityRoutes: SidenavModel[] = [
  {
    title: 'Cupons e ofertas',
    route: '/internal/coupon',
    disable: false,
  },
  {
    title: 'Campanhas',
    route: '/internal/campaign',
    disable: false,
  },
];

const paymentRoutes: SidenavModel[] = [
  {
    title: 'Faturas',
    route: '/internal/invoices',
    disable: false,
  },
  {
    title: 'Pagamentos',
    route: '/internal/payments',
    disable: false,
  },
  {
    title: 'Pix Parcelado',
    route: '/internal/bnpl',
    disable: false,
  },
];

const reportRoutes: SidenavModel[] = [
  {
    title: 'Resumo',
    route: '/internal/reports/summary',
    disable: false,
  },
  {
    title: 'Itens',
    route: '/internal/reports/items',
    disable: false,
  },
  {
    title: 'Categorias',
    route: '/internal/reports/category',
    disable: false,
  },
  {
    title: 'Vendedores',
    route: '/internal/reports/sellers',
    disable: false,
  },
  {
    title: 'Pagamentos',
    route: '/internal/reports/payments',
    disable: false,
  },
];

const preferencesRoutes: SidenavModel[] = [
  // {
  //   title: 'Entrega',
  //   route: '/internal/settings/preferences/delivery',
  //   disable: false,
  // },
  {
    title: 'Pagamento',
    route: '/internal/settings/preferences/payment',
    disable: false,
  },
  {
    title: 'Personalização',
    route: '/internal/settings/preferences/personalization',
    disable: false,
  },
];

export const MVP_SIDENAV: SidenavModel[] = [
  {
    title: 'Contatos',
    svg: '/assets/images/icons/users-new.svg',
    disable: false,
    isOpen: false,
    childrens: contactRoutes,
  },
  {
    title: 'Marketing e Promoções',
    svg: '/assets/images/icons/coupon.svg',
    disable: false,
    isOpen: true,
    childrens: fidelityRoutes,
  },
];

export const MAIN_SIDENAV: SidenavModel[] = [
  {
    title: 'Visão geral',
    svg: '/assets/images/icons/chart-collum.svg',
    route: '/internal/dashboard',
    disable: false,
  },
  {
    title: 'Saldos e Extrato',
    svg: '/assets/images/icons/wallet.svg',
    disable: false,
    isOpen: false,
    childrens: balanceRoutes,
  },
  {
    title: 'Contatos',
    svg: '/assets/images/icons/users-new.svg',
    disable: false,
    isOpen: false,
    childrens: contactRoutes,
  },
  {
    title: 'Produtos e serviços',
    svg: '/assets/images/icons/box.svg',
    disable: false,
    isOpen: false,
    childrens: itemRoutes,
  },
  {
    title: 'Pedidos e Transações',
    svg: '/assets/images/icons/change.svg',
    disable: false,
    isOpen: false,
    childrens: orderRoutes,
  },
  {
    title: 'Marketing e Promoções',
    svg: '/assets/images/icons/coupon.svg',
    disable: false,
    isOpen: false,
    childrens: fidelityRoutes,
  },
  {
    title: 'Pagamentos e Finanças',
    svg: '/assets/images/icons/coins.svg',
    disable: false,
    isOpen: false,
    childrens: paymentRoutes,
  },
  {
    title: 'Relatórios',
    svg: '/assets/images/icons/chart-line.svg',
    disable: false,
    isOpen: false,
    childrens: reportRoutes,
  },
];

export const CONFIG_SIDENAV: SidenavModel[] = [
  {
    title: 'Voltar ao início',
    svg: '/assets/icons/chevron-left.svg',
    route: '/internal/dashboard',
    disable: false,
  },
  {
    title: '',
    disable: false,
  },
  {
    title: 'Perfil',
    svg: '/assets/icons/user.svg',
    route: '/internal/settings/profile',
    disable: false,
  },
  {
    title: 'Conta e documentos',
    svg: '/assets/icons/address-card-white.svg',
    route: '/internal/settings/documents',
    disable: false,
  },
  {
    title: 'Minha loja',
    svg: '/assets/icons/shop.svg',
    route: '/internal/settings/preferences',
    disable: false,
    childrens: preferencesRoutes,
  },
  {
    title: 'Notificações',
    svg: '/assets/icons/bell-alt.svg',
    route: '/internal/settings/notifications',
    disable: false,
  },
  {
    title: 'Usuários',
    svg: '/assets/images/icons/users-new.svg',
    route: '/internal/settings/users',
    disable: false,
  },
  {
    title: 'Desenvolvedor',
    svg: '/assets/icons/develop.svg',
    route: '/internal/settings/developer',
    disable: false,
  },
  {
    title: 'Informações',
    svg: '/assets/icons/circle-information.svg',
    route: '/internal/settings/info',
    disable: false,
  },
];

export const SIDENAV_STORE: SidenMenuModel[] = [
  {
    title: 'Pedidos',
    isAuth: true,
    icon: '/assets/images/bag-shopping.svg',
    action: 'logged-area',
    isDesktop: true,
  },
  {
    title: 'Pedidos',
    isAuth: true,
    icon: '/assets/images/bag-shopping.svg',
    action: 'logged-area',
    isDesktop: false,
  },
  {
    title: 'Categorias',
    isAuth: true,
    icon: '/assets/images/bag-shopping.svg',
    action: 'categories-filter',
    isDesktop: false,
  },
  {
    title: 'Sair',
    isAuth: true,
    icon: '/assets/icons/logout.svg',
    action: 'log-out',
    isDesktop: true,
  },
  {
    title: 'Entrar',
    isAuth: false,
    haveBackground: true,
    icon: '/assets/images/bag-shopping.svg',
    action: 'login',
    isDesktop: false,
  },
  {
    title: 'Categorias',
    isAuth: false,
    icon: '/assets/images/bag-shopping.svg',
    action: 'categories-filter',
    isDesktop: false,
  },
];
