import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-delete-button',
  templateUrl: './delete-button.component.html',
})
export class DeleteButtonComponent {
  @Input() public label?: string = '';
  @Input() public alt?: string = '';
  @Input() public block?: boolean = false;
  @Input() public loading?: boolean = false;
  @Input() public disabled?: boolean = false;

  constructor() {}
}
