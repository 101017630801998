import { Category } from './../../models/sellers/sellers.model';
import { Component, HostListener, Input, OnInit, Optional } from '@angular/core';
import { FormGroup, FormBuilder, FormControl } from '@angular/forms';
import { NzDrawerRef } from 'ng-zorro-antd/drawer';
import { CategoryModel } from '../../models/categories/categories.model';
import { StateManagementService } from '../../state-management/state-management.service';
import { SellersService } from '../../services/sellers/sellers.service';
import { SellerModel } from '../../models/sellers/sellers.model';
import { CategoryService } from '../../services/item/category/category.service';
import { ItemVariantOptionModel } from '../../models/items/V2/itemVariantOption.model';
import { ItemVariantService } from '../../services/item/itemVariant/item-variant.service';
import { EMPTY, switchMap } from 'rxjs';
import { MarketplaceFiltersModel } from '../../models/marketplace/marketplace-filters/marketplace-filters.model';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';

@Component({
  selector: 'app-marketplace-filter',
  templateUrl: './marketplace-filter.component.html',
  styleUrls: ['./marketplace-filter.component.scss'],
})
export class MarketplaceFilterComponent implements OnInit {
  public categories: Array<CategoryModel>;
  public loading: boolean = true;
  public form: FormGroup;
  public sellers: SellerModel[] = [];
  public isMobile: boolean = window.innerWidth < 820;
  public filters: MarketplaceFiltersModel = new MarketplaceFiltersModel();

  public orderByOptions: Array<{ label: string; value: string }> = [
    { label: 'Mais recentes', value: 'inserted_at' },
    { label: 'Ordem alfabética', value: 'name' },
  ];

  public itemVariantOptions: ItemVariantOptionModel[] = [];

  constructor(
    private fb: FormBuilder,
    private $seller: SellersService,
    private $categories: CategoryService,
    private $itemVariants: ItemVariantService,
    private $router: Router,
    @Optional() public $drawerRef: NzDrawerRef,
    private $notification: StateManagementService,
    private activatedRoute: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.getNotification();
    this.getFilterRouteName();
  }

  public getNotification(): void {
    this.$notification.sellersList.subscribe((sellers) => {
      if (sellers && sellers.length > 0) {
        this.sellers = sellers;
        this.createForm();
      } else {
        this.$seller.getSellersMarketplace(this.isMobile);
      }
    });

    this.$notification.changedCategorys
      .pipe(
        switchMap((category) => {
          if (category) {
            this.categories = [category];
            return EMPTY;
          } else {
            return this.$notification.sellers;
          }
        }),
        switchMap((seller) => {
          if (seller && Object.keys(seller).length > 0) {
            this.getSellerCategories(seller.id);
            return EMPTY;
          } else {
            return this.$notification.categories;
          }
        })
      )
      .subscribe((categories) => {
        if (categories) {
          this.treatCategoryList(categories);
        } else {
          this.$categories.getAndSetCategoriesAll();
        }
      });

    this.$notification.itemVariantOptions.subscribe((itemVariantOptions) => {
      if (itemVariantOptions && itemVariantOptions.length > 0) {
        this.itemVariantOptions = itemVariantOptions;
      } else {
        this.$itemVariants.getAndSetItemVariantsOptions();
      }
    });
  }

  public getFilterRouteName(): void {
    this.activatedRoute.queryParamMap.subscribe((params) => {
      this.filters.categoryLabel = params.get('categoryLabel');
    });
  }

  private treatCategoryList(categories): void {
    let options = this.$categories.setCategoriesCascader(categories);

    this.categories = options.map((option) => {
      let nameArray: string[] = option.label.split(' ');
      option.label = nameArray[0].slice(0, 11).replace(',', '');

      return option;
    });
  }

  private createForm(): void {
    const sellersCheckbox = [];

    this.sellers.forEach((seller) => {
      sellersCheckbox.push({ label: seller.business_name, value: seller.id });
    });

    this.form = this.fb.group({
      orderBy: new FormControl(''),
      categoryIds: new FormControl(),
      categoryId: new FormControl(),
      searchTerm: new FormControl(),
      color: new FormControl(),
      sellerId: new FormControl(),
      sellers: new FormControl(sellersCheckbox),
      sellerIds: new FormControl(),
      minValue: new FormControl(0),
      maxValue: new FormControl(0),
    });

    this.getValuesChange();
    this.loading = false;
  }

  public getValuesChange(): void {
    this.form.get('sellers').valueChanges.subscribe((res) => {
      if (res) {
        this.sellers = this.sellers.map((seller: any) => ({
          ...seller,
          checked: this.form.value.sellers.includes(seller.value),
        }));

        const sellerIds = JSON.stringify(
          this.form.value.sellers.filter((data: any) => data.checked).map((method: any) => method.value)
        );
        this.filters.sellerIds = sellerIds;
        this.form.get('sellerIds').setValue(sellerIds);

        if (!this.isMobile) {
          this.$router.navigate(['/internal/products'], { queryParams: JSON.parse(JSON.stringify(this.filters)) });
        }
      }
    });

    this.form.get('minValue').valueChanges.subscribe((res) => {
      if (res) {
        this.filters.minValue = res * 100;
        if (!this.isMobile) {
          this.$router.navigate(['/internal/products'], { queryParams: JSON.parse(JSON.stringify(this.filters)) });
        }
      }
    });

    this.form.get('maxValue').valueChanges.subscribe((res) => {
      if (res) {
        this.filters.maxValue = res * 100;
        if (!this.isMobile) {
          this.$router.navigate(['/internal/products'], { queryParams: JSON.parse(JSON.stringify(this.filters)) });
        }
      }
    });

    this.form.get('searchTerm').valueChanges.subscribe((res) => {
      if (res) {
        this.filters.searchTerm = res;
        if (!this.isMobile) {
          this.$router.navigate(['/internal/products'], { queryParams: JSON.parse(JSON.stringify(this.filters)) });
        }
      }
    });
  }

  public applyFilters(): void {
    this.$drawerRef.close(this.filters);
  }

  public clearFilters(type?: string): void {
    this.$notification.setLoading(true);
    this.form.reset();

    if (this.isMobile) {
      this.$drawerRef.close(this.filters);
      this.filters = new MarketplaceFiltersModel();
      this.$notification.setLoading(false);
    } else {
      this.$router.navigate([], {
        queryParams: {
          ...(type === 'category' ? { categoryId: null, categoryLabel: null } : null),
          ...(type === 'priceRange' ? { minValue: null, maxValue: null } : null),
        },
        queryParamsHandling: 'merge',
      });

      if (type === 'all') {
        this.filters = new MarketplaceFiltersModel();

        this.$router.navigate([], {
          queryParams: {
            categoryId: null,
            minValue: null,
            maxValue: null,
            categoryLabel: null,
          },
          queryParamsHandling: 'merge',
        });
      }
    }
  }

  public getSellerCategories(sellerId): void {
    this.$categories.getCategoryList(sellerId).subscribe((res) => {
      if (res?.data?.listItemCategories) {
        const sellerCategories = JSON.parse(JSON.stringify(res.data.listItemCategories)) || [];

        this.categories = sellerCategories.map((category) => {
          const obj = {
            label: category.name,
            value: category.id,
            isLeaf: true,
            children: [],
          };
          return obj;
        });
      }
    });
  }

  public getOrderByValue(orderBy: string): void {
    this.form.get('orderBy').setValue(orderBy);
    this.filters.orderBy = orderBy;

    if (!this.isMobile) {
      this.$router.navigate(['/internal/products'], { queryParams: JSON.parse(JSON.stringify(this.filters)) });
    }
  }

  public onSelectCategory(category: CategoryModel): void {
    this.form.get('categoryId').setValue(category.value);
    this.filters.categoryId = category.value;
    this.filters.categoryLabel = category.label;

    if (!this.isMobile) {
      this.$router.navigate(['/internal/products'], { queryParams: JSON.parse(JSON.stringify(this.filters)) });
    }
  }

  public onSelectCategories(categories: string) {
    const categoryIds = JSON.stringify(categories);
    this.filters.categoryIds = categoryIds;
    this.form.get('categoryIds').setValue(categoryIds);

    if (!this.isMobile) {
      this.$router.navigate(['/internal/products'], { queryParams: JSON.parse(JSON.stringify(this.filters)) });
    }
  }

  public onSelectSeller(sellerId: string): void {
    this.form.get('sellerId').setValue(sellerId);
    this.filters.sellerId = sellerId;

    if (!this.isMobile) {
      this.$router.navigate(['/internal/products'], { queryParams: JSON.parse(JSON.stringify(this.filters)) });
    }
  }

  public onSelectColor(color: string): void {
    this.form.get('color').setValue(color);
    this.filters.color = color;

    if (!this.isMobile) {
      this.$router.navigate(['/internal/products'], { queryParams: JSON.parse(JSON.stringify(this.filters)) });
    }
  }

  @HostListener('window:resize', ['$event'])
  public onResize(event: any): void {
    this.isMobile = event.target.innerWidth < 768;
  }
}
