import { Observable } from 'rxjs';
import { v4 as uuidv4 } from 'uuid';
import moment from 'moment-timezone';
import { Injectable } from '@angular/core';
import { Apollo, Mutation, Query } from 'apollo-angular';

import { PlansModel } from './../../models/plans/plans.model';
import { InternalService } from '../internal/internal.service';
import { PaymentsModel } from '../../models/payments/payments.model';
import { PaymentInfo } from '../../models/payments/payment-info.model';
import { SubscriptionsService } from '../subscriptions/subscriptions.service';
import { GET_PLAN_QUERY, LIST_PLANS_QUERY } from '../../graphql/queries/plan.queries';
import { StateManagementService } from '../../state-management/state-management.service';
import {
  CREATE_PLAN_MUTATION,
  DELETE_PLAN_MUTATION,
  UPDATE_PLAN_MUTATION,
} from '../../graphql/mutations/plan.mutations';

@Injectable()
export class PlansService {
  constructor(
    private readonly apollo: Apollo,
    private $methods: InternalService,
    private $subscription: SubscriptionsService,
    private $notification: StateManagementService
  ) {}

  public getPlanList(filters: any): Observable<any> {
    return this.apollo.watchQuery<Query>({
      query: LIST_PLANS_QUERY,
      variables: { filters },
      fetchPolicy: 'cache-and-network',
    }).valueChanges;
  }

  public getPlanDetail(id: string): Observable<any> {
    return this.apollo.watchQuery<Query>({
      query: GET_PLAN_QUERY,
      variables: { id },
      fetchPolicy: 'cache-and-network',
    }).valueChanges;
  }

  public createPlan(params: PlansModel): Observable<any> {
    return this.apollo.mutate<Mutation>({
      mutation: CREATE_PLAN_MUTATION,
      variables: { params },
    });
  }

  public updatePlan(id: string, params: PlansModel): Observable<any> {
    return this.apollo.mutate<Mutation>({
      mutation: UPDATE_PLAN_MUTATION,
      variables: { id, params },
    });
  }

  public deletePlan(id: string): Observable<any> {
    return this.apollo.mutate<Mutation>({
      mutation: DELETE_PLAN_MUTATION,
      variables: { id },
    });
  }

  public onSignPlan(payload: any, paymentInfo: PaymentInfo): void {
    this.$subscription.createSubscriptionCheckout(payload).subscribe({
      next: (response) => {
        const subscription = response.data.createSubscription;
        if (subscription) {
          paymentInfo.subscription = subscription;

          this.$notification.setLoading(false);

          if (subscription?.status === 'ACTIVE') {
            this.$notification.setTypeResult('RECEIPT');
            this.$notification.setPaymentInfo(paymentInfo);

            if (payload.redirectTo) {
              window.location.href = payload.redirectTo;
            }
          }

          if (subscription?.status === 'WAITING' || subscription?.status === 'FAILING') {
            this.$methods.treatError(null);
          }
        } else {
          this.$methods.treatError(null);
        }
      },
      error: (error) => {
        this.$methods.treatError(error);
        throw new Error(error);
      },
    });
  }

  public createPayload(info: PaymentInfo): Partial<PaymentsModel> {
    let data = {
      method: info?.method,
      planId: info?.plans?.id,
      priceId: info?.selectedPrice?.id,
      schedulePlans: info?.selectedPrice?.schedulePlans,
      sessionId: uuidv4(),
      redirectTo: info?.redirectTo,
      externalId: info?.externalId,
      sellerId: info?.seller?.id,
      startDate: moment().format('YYYY-MM-DD'),
    };

    if (info.customer?.id) {
      data = Object.assign(data, { customerId: info.customer.id });
    } else {
      data = Object.assign(data, { customer: info.customer });
    }

    if (info.method === 'CREDIT_CARD') {
      if (info.creditCard?.id) {
        data = Object.assign(data, {
          creditCardId: info?.creditCard.id,
          securityCode: info?.creditCard?.securityCode,
        });
      } else {
        data = Object.assign(data, {
          creditCard: {
            token: info.token,
            holderName: info.customer?.name,
            expirationMonth: info.creditCard?.expirationMonth,
            expirationYear: info.creditCard?.expirationYear,
            securityCode: info.creditCard?.securityCode,
          },
        });
      }
    }

    return data;
  }
}
