import { Component, Input } from '@angular/core';

import { CouponFidelityModel } from '../../models/fidelity/coupon-fidelity.model';

@Component({
  selector: 'app-preview-coupon-app',
  templateUrl: './preview-coupon-app.component.html',
  styleUrls: ['./preview-coupon-app.component.scss'],
})
export class PreviewCouponAppComponent {
  @Input() public coupon: CouponFidelityModel;
}
