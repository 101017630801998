import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'hidePartialEmail',
})
export class HidePartialEmailPipe implements PipeTransform {
  transform(value: any): any {
    if (!value) {
      return '';
    }

    const parts = value.split('@');
    const hidden = parts[0].slice(0, 1) + '***';
    const hidden2 = parts[1].slice(0, 5) + '***';
    return hidden + '@' + hidden2;
  }
}
