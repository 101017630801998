<header *nzModalTitle class="modal-header">
  <h3 class="title-category">Editar Seller</h3>
  <span class="example-spacer"></span>
  <img src="../../assets/images/close.svg" (click)="$modalRef.close()" class="pointer-cursor" />
</header>

<body>
  <main>
    <section>
      <form nz-form [formGroup]="sellerFormGroup">
        <h3>Informações Pessoais</h3>

        <div class="wrapper-input">
          <div class="input">
            <nz-form-label nzFor="name">Nome</nz-form-label>
            <input nz-input type="text" id="name" formControlName="name" />
          </div>
          <div class="input">
            <nz-form-label nzFor="birthDate">Data de nascimento</nz-form-label>
            <input nz-input type="text" id="birthDate" formControlName="birthDate" />
          </div>
        </div>

        <div class="wrapper-input">
          <div class="input">
            <nz-form-label nzFor="nameMother">Nome da mãe</nz-form-label>
            <input nz-input type="text" id="nameMother" formControlName="nameMother" />
          </div>
        </div>

        <p class="m-t-1">Endereço</p>

        <div class="wrapper-input">
          <div class="input">
            <nz-form-label nzFor="line1">Rua</nz-form-label>
            <input nz-input type="text" id="line1" formControlName="line1" />
          </div>
          <div class="input w-10">
            <nz-form-label nzFor="line2">Número</nz-form-label>
            <input nz-input type="text" id="line2" formControlName="line2" />
          </div>
          <div class="input">
            <nz-form-label nzFor="line3">Complemento</nz-form-label>
            <input nz-input type="text" id="line3" formControlName="line3" />
          </div>
        </div>

        <div class="wrapper-input">
          <div class="input">
            <nz-form-label nzFor="postalCode">CEP</nz-form-label>
            <input nz-input type="text" id="postalCode" formControlName="postalCode" />
          </div>
          <div class="input">
            <nz-form-label nzFor="neighborhood">Bairro</nz-form-label>
            <input nz-input type="text" id="neighborhood" formControlName="neighborhood" />
          </div>
          <div class="input">
            <nz-form-label nzFor="city">Cidade</nz-form-label>
            <input nz-input type="text" id="city" formControlName="city" />
          </div>
          <div class="input">
            <nz-form-label nzFor="state">Estado</nz-form-label>
            <input nz-input type="text" id="state" formControlName="state" />
          </div>
        </div>

        <nz-divider />

        <h3>Empresa</h3>

        <div class="wrapper-input">
          <div class="input">
            <nz-form-label nzFor="businessName">Nome da empresa</nz-form-label>
            <input nz-input type="text" id="businessName" formControlName="businessName" />
          </div>
          <div class="input">
            <nz-form-label nzFor="tradeName">Nome comercial</nz-form-label>
            <input nz-input type="text" id="tradeName" formControlName="tradeName" />
          </div>
        </div>

        <div class="wrapper-input">
          <div class="input">
            <nz-form-label nzFor="tradeName">Observações</nz-form-label>
            <textarea nz-input formControlName="observation" [nzAutosize]="{ minRows: 3, maxRows: 3 }"></textarea>
          </div>
        </div>

        <p class="m-t-1">Endereço</p>

        <div class="wrapper-input">
          <div class="input">
            <nz-form-label nzFor="businessLine1">Rua</nz-form-label>
            <input nz-input type="text" id="businessLine1" formControlName="businessLine1" />
          </div>
          <div class="input w-10">
            <nz-form-label nzFor="businessLine2">Número</nz-form-label>
            <input nz-input type="text" id="businessLine2" formControlName="businessLine2" />
          </div>
          <div class="input">
            <nz-form-label nzFor="businessLine3">Complemento</nz-form-label>
            <input nz-input type="text" id="businessLine3" formControlName="businessLine3" />
          </div>
        </div>

        <div class="wrapper-input">
          <div class="input">
            <nz-form-label nzFor="businessPostalCode">CEP</nz-form-label>
            <input nz-input type="text" id="businessPostalCode" formControlName="businessPostalCode" />
          </div>
          <div class="input">
            <nz-form-label nzFor="businessNeighborhood">Bairro</nz-form-label>
            <input nz-input type="text" id="businessNeighborhood" formControlName="businessNeighborhood" />
          </div>
          <div class="input">
            <nz-form-label nzFor="businessCity">Cidade</nz-form-label>
            <input nz-input type="text" id="businessCity" formControlName="businessCity" />
          </div>
          <div class="input">
            <nz-form-label nzFor="businessState">Estado</nz-form-label>
            <input nz-input type="text" id="businessState" formControlName="businessState" />
          </div>
        </div>

        <nz-divider />

        <p>Geral</p>

        <div nz-row nzGutter="24">
          <div nz-col nzSpan="5" class="self-center">
            <nz-form-label nzFor="emailVerified">Email verificado</nz-form-label>
            <nz-switch nz-input id="emailVerified" formControlName="emailVerified" />
          </div>
          <div nz-col nzSpan="6" class="self-center">
            <nz-form-label nzFor="phoneVerified">Telefone verificado</nz-form-label>
            <nz-switch nz-input id="phoneVerified" formControlName="phoneVerified" />
          </div>
          <div nz-col nzSpan="7" class="self-center">
            <nz-form-label nzFor="isBnplActive">Habilitar Pix Parcelado</nz-form-label>
            <nz-switch nz-input id="isBnplActive" formControlName="isBnplActive" />
          </div>
          <div nz-col nzSpan="6">
            <nz-form-label nzFor="receivingLimit">Limite recebido</nz-form-label>
            <input nz-input type="text" id="receivingLimit" formControlName="receivingLimit" />
          </div>
        </div>
      </form>
    </section>
  </main>
</body>

<footer class="modal-footer" *nzModalFooter>
  <div class="buttons">
    <app-secondary-button (click)="$modalRef.close()">Cancelar</app-secondary-button>
    <app-primary-button icon="user-add" [disabled]="!sellerFormGroup.valid" (click)="editUser()">
      Editar Seller
    </app-primary-button>
  </div>
</footer>
