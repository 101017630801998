import { NgModule } from '@angular/core';

import { DragDropDirective } from './drag-drop.directive';
import { ClickOutsideDirective } from './click-outside.directive';
import { RequiredOnBlurDirective } from './required-on-blur.directive';

@NgModule({
  imports: [],
  declarations: [DragDropDirective, ClickOutsideDirective, RequiredOnBlurDirective],
  exports: [DragDropDirective, ClickOutsideDirective, RequiredOnBlurDirective],
})
export class DirectiveModule {}
