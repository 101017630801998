import { Component, HostListener, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NzDrawerRef } from 'ng-zorro-antd/drawer';

@Component({
  selector: 'app-profile-menu-marketplace',
  templateUrl: './profile-menu-marketplace.component.html',
  styleUrls: ['./profile-menu-marketplace.component.scss'],
})
export class DrawerProfileMenuMarketplaceComponent {
  public listOfProfileOptions: Array<any> = [
    {
      name: 'Minha conta',
      type: 'ACCOUNT',
    },
    {
      name: 'Pagamentos',
      type: 'PAYMENTS',
    },
    {
      name: 'Favoritos',
      type: 'FAVORITES',
    },
    {
      name: 'Pedidos',
      type: 'ORDERS',
    },
    {
      name: 'Sair',
      type: 'LOGOUT',
    },
  ];

  public isMobile: boolean = window.innerWidth < 768;

  constructor(private drawerRef: NzDrawerRef, private route: Router) {}

  @HostListener('window:resize', ['$event'])
  public onResize(event?: any): void {
    this.isMobile = event.target.innerWidth < 768;

    if (this.isMobile) {
      this.drawerRef.close();
    }
  }

  public optionAction(data): void {
    switch (data) {
      case 'ACCOUNT':
        this.route.navigate(['internal/profile/account']);
        break;

      case 'PAYMENTS':
        this.route.navigate(['internal/profile/payments']);
        break;

      case 'FAVORITES':
        this.route.navigate(['internal/profile/favorites']);
        break;

      case 'ORDERS':
        this.route.navigate(['internal/profile/orders']);
        break;

      case 'LOGOUT':
        sessionStorage.clear();

        this.route.navigate(['/external']).then(() => {
          window.location.reload();
        });
        break;
    }
  }
}
