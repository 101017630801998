import { StateManagementService } from 'libs/shared/src/lib/state-management/state-management.service';
import { SellerModel } from 'libs/shared/src/lib/models/sellers/sellers.model';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-contact-bottom-sheet',
  templateUrl: './contact-bottom-sheet.component.html',
  styleUrls: ['./contact-bottom-sheet.component.scss'],
})
export class ContactBottomSheetComponent implements OnInit {
  public seller: SellerModel;
  public openingHours: string;

  constructor(private $notification: StateManagementService) {}

  ngOnInit(): void {
    this.getNotification();
  }

  public getOpeningHours(): void {
    this.openingHours =
      this.seller.settings?.pointOfSale?.openingHours[0].openingTime.substring(0, 2) +
      ':' +
      this.seller.settings?.pointOfSale?.openingHours[0].openingTime.substring(2, 4) +
      ' às ' +
      this.seller.settings?.pointOfSale?.openingHours[0].closingTime.substring(0, 2) +
      ':' +
      this.seller.settings?.pointOfSale?.openingHours[0].closingTime.substring(2, 4);
  }

  public getNotification(): void {
    this.$notification.sellers.subscribe((seller) => {
      this.seller = seller;
    });
  }

  public openWhatsApp(): void {
    window.open('https://wa.me/' + this.seller.settings?.pointOfSale?.phone);
  }

  public openPhone(): void {
    window.open('tel:' + this.seller.settings?.pointOfSale?.phone, '_self');
  }
}
