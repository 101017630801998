import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { isEmpty } from 'lodash';
import { NzModalRef } from 'ng-zorro-antd/modal';
import { CouponCategoryTypeModel } from '../../../models/coupon/coupon-category-type.model';
@Component({
  selector: 'app-modal-create-or-update-category-or-type-coupon',
  templateUrl: './modal-create-or-update-category-or-type-coupon.component.html',
  styleUrls: ['./modal-create-or-update-category-or-type-coupon.component.scss'],
})
export class ModalCreateOrUpdateCategoryOrTypeCouponComponent implements OnInit {
  @Input() data?: CouponCategoryTypeModel;
  @Input() title?: string;

  public formGroup!: FormGroup;

  constructor(private readonly fb: FormBuilder, public readonly $modalRef: NzModalRef) {}

  public ngOnInit(): void {
    this.createForm();
    this.getValues();
  }

  private createForm(): void {
    this.formGroup = this.fb.group({
      name: new FormControl<string>('', [Validators.required]),
      type: new FormControl<string>('CATEGORY', [Validators.required]),
      status: new FormControl<string>('ACTIVE'),
    });
  }

  private getValues(): void {
    if (!isEmpty(this.data)) {
      this.formGroup.patchValue({
        name: this.data.name,
        type: this.data.type,
      });
      this.formGroup.get('type').disable();
    }
  }

  public setHandleSubmit(value: boolean): void {
    if (value) {
      this.$modalRef.close(this.formGroup.value);
    } else {
      this.$modalRef.close();
    }
  }
}
