<section class="type === 'MARKETPLACE' ? 'marketplace' : ''">
  <nz-card [nzCover]="coverTemplate" [nzLoading]="loading" [nzBorderless]="true">
    <div class="inline w-100" class="product-description-container">
      <div class="between w-100">
        <span class="product-title">{{
          isDesktopWindow
            ? product?.name?.substring(0, 25)
            : '' || product?.name?.length > 10
            ? product?.name?.substring(0, 15) + '...'
            : product?.name
        }}</span>
        <!-- <img
              (click)="clickedFavorite = !clickedFavorite"
              class="favorite"
              [src]="!clickedFavorite ? unFavoriteHearthUrl : favoriteHearthUrl"
              alt="loading Payment"
            /> -->
      </div>
      <span class="product-description">{{
        isDesktopWindow
          ? product?.description?.substring(0, 29)
          : '' || product?.description?.length > 10
          ? product?.description?.substring(0, 15) + '...'
          : product?.description || ' - '
      }}</span>
      <span *ngIf="product?.priceRange?.min !== product?.priceRange?.max" class="product-value"
        >{{ product?.priceRange?.min / 100 | currency : 'R$' }}
      </span>
      <span *ngIf="product?.priceRange?.min === product?.priceRange?.max" class="product-value">{{
        product.itemUnits[0].price / 100 | currency : 'R$'
      }}</span>
    </div>
  </nz-card>
  <ng-template #coverTemplate>
    <!-- <ng-container *ngIf="product?.itemOptions && product.itemOptions.length > 0; else noVariation">
      <ng-container
        *ngIf="
          product.itemOptions[0].types &&
            product.itemOptions[0].types.length > 0 &&
            product?.itemOptions[0].types[0].imageUrls.length > 0;
          else noVariationImg
        "
      >
        <img class="product-img" [src]="product?.itemOptions[0].types[0].imageUrls[0]" [nzFallback]="fallback" />
      </ng-container>
      <ng-template #noVariationImg>
        <img class="product-img" nz-image nzSrc="error" [nzFallback]="fallback" />
      </ng-template>
    </ng-container> -->
    <!-- <ng-template #noVariation> -->
    <nz-carousel
      #carousel
      [nzEffect]="'fade'"
      [nzDots]="false"
      *ngIf="images && images.length > 0; else noImgCarroseul"
    >
      <div nz-carousel-content class="img-container" *ngFor="let img of images">
        <img
          nz-image
          [nzDisablePreview]="true"
          class="product-img"
          [nzFallback]="fallback"
          alt="product"
          [nzSrc]="img.url"
        />

        <div class="arrows" *ngIf="images.length > 1">
          <div (click)="prevAndNext($event, 'prev')" class="arrow-left pointer-cursor">
            <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M15 6L9 12L15 18"
                stroke="#666666"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </div>

          <div (click)="prevAndNext($event, 'next')" class="arrow-right pointer-cursor">
            <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M9 6L15 12L9 18"
                stroke="#666666"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </div>
        </div>
      </div>
    </nz-carousel>

    <ng-template #noImgCarroseul>
      <img class="product-img" nz-image nzSrc="error" [nzFallback]="fallback" />
    </ng-template>

    <div class="mobile">
      <img
        nz-image
        [nzDisablePreview]="true"
        [nzFallback]="fallback"
        *ngIf="images && images.length > 0"
        class="product-img"
        alt="product-img"
        [nzSrc]="images[0].url"
      />
    </div>
  </ng-template>
  <!-- </ng-template> -->
</section>
