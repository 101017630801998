<section>
  <div class="headerButton"></div>

  <div class="d-flex align-center">
    <button nz-button nzType="text" nzShape="circle" (click)="drawerRef.close()">
      <span nz-icon nzType="close"></span>
    </button>
    <p class="header-title no-margin-bottom">Detalhes do pagamento</p>
    <span class="example-spacer"></span>

    <div class="d-flex endBlock">
      <app-primary-button icon="dollar-circle" (click)="cancelPayment()" [loading]="loadingBtn">
        Reembolsar
      </app-primary-button>
    </div>
  </div>

  <nz-divider />

  <div>
    <div class="d-flex">
      <h2>Status</h2>
      <app-status-tag class="status-tag" [type]="payment.status" gender="male"></app-status-tag>
    </div>

    <nz-tabset nzType="card">
      <nz-tab nzTitle="Principais">
        <section>
          <div class="company-description">
            <div class="info m-t-2">
              <div class="container">
                <div class="content">
                  <p>ID</p>
                </div>

                <div>
                  <span>{{ payment.id }}</span>
                </div>
              </div>

              <div class="container">
                <div class="content">
                  <p>Seller</p>
                </div>

                <div>
                  <span>{{ payment.seller?.company?.businessName || payment.seller?.person?.name }}</span>
                </div>
              </div>

              <div class="container">
                <div class="content">
                  <p>Customer</p>
                </div>

                <div>
                  <span>{{ payment.customer?.name }}</span>
                </div>
              </div>
            </div>

            <div class="info m-t-1">
              <h3>Dispositivo</h3>

              <div class="container">
                <div class="content">
                  <p>IP</p>
                </div>

                <div>
                  <span>{{ payment.device?.ip || 'Desconhecido' }}</span>
                </div>
              </div>

              <div class="container">
                <div class="content">
                  <p>Modelo</p>
                </div>

                <div>
                  <span>{{ payment.device?.model || 'Desconhecido' }}</span>
                </div>
              </div>

              <div class="container">
                <div class="content">
                  <p>Plataforma</p>
                </div>

                <div>
                  <span>{{ payment.device?.platform || 'Desconhecida' }}</span>
                </div>
              </div>

              <div class="container">
                <div class="content">
                  <p>Ferramenta</p>
                </div>

                <div>
                  <span>{{ payment.device?.userAgent || 'Desconhecida' }}</span>
                </div>
              </div>

              <div class="container">
                <div class="content">
                  <p>Localização</p>
                </div>

                <div *ngIf="payment.location?.latitude">
                  <span>Latitude: {{ payment.location?.latitude }} | Longitude: {{ payment.location?.longitude }}</span>
                </div>
                <div *ngIf="!payment.location?.latitude">
                  <span>Desconhecida</span>
                </div>
              </div>
            </div>

            <div class="info m-t-1" *ngIf="payment.history">
              <h3>Histórico</h3>

              <div class="m-t-1" *ngFor="let history of payment.history">
                <app-status-tag class="status-tag" [type]="history.status" gender="male"></app-status-tag>
                <span>{{ history.inserted_at }}</span>
              </div>
            </div>

            <div class="info m-t-1" *ngIf="payment.acquirerPayment">
              <h3>Resposta Getnet</h3>

              <div class="m-t-1">
                <pre class="code" [innerHTML]="payment.acquirerPayment | prettyjson : [false, 4]"></pre>
              </div>
            </div>
          </div>

          <hr />

          <div>
            <div class="table m-t-2">
              <app-main-table
                [listOfHeaders]="listOfTotalHeadersColumn"
                [listOfData]="listOfTotalData"
                tableType="TOTAL-PAYMENTS"
                [settingValue]="settingTotalValue"
                [widthConfig]="['20%', '20%', '20%', '20%', '20%']"
              >
              </app-main-table>
            </div>
          </div>
        </section>
      </nz-tab>
      <nz-tab nzTitle="Itens">
        <section>
          <app-main-table
            [listOfHeaders]="listOfHeadersColumn"
            [listOfData]="listOfData"
            tableType="PAYMENT-ITEMS"
            [settingValue]="settingValue"
            [widthConfig]="['50%', '25%', '25%']"
          ></app-main-table>
        </section>
      </nz-tab>
      <nz-tab nzTitle="Método">
        <section>
          <div *ngIf="payment.method === 'CREDIT_CARD'">
            <div>
              <span class="method-title">Cartão de crédito</span>
            </div>
            <div class="m-t-1">
              <pre class="code" [innerHTML]="payment.credit | prettyjson : [false, 4]"></pre>
            </div>
          </div>
          <div *ngIf="payment.method === 'BANK_SLIP'">
            <div>
              <span class="method-title">Boleto</span>
            </div>
            <div class="m-t-1">
              <pre class="code" [innerHTML]="payment.bankSlip | prettyjson : [false, 4]"></pre>
            </div>
          </div>
          <div *ngIf="payment.method === 'PIX'">
            <div>
              <span class="method-title">Pix</span>
            </div>
            <div class="m-t-1">
              <pre class="code" [innerHTML]="payment.pix | prettyjson : [false, 4]"></pre>
            </div>
          </div>
          <div *ngIf="payment.method === 'WALLET'">
            <div>
              <span class="method-title">Carteira</span>
            </div>
            <div class="m-t-1">
              <blockquote class="code">Sem informações</blockquote>
            </div>
          </div>
          <div *ngIf="payment.method === 'MONEY_EXTERNAL'">
            <div>
              <span class="method-title">Dinheiro</span>
            </div>
            <div class="m-t-1">
              <blockquote class="code">Sem informações</blockquote>
            </div>
          </div>
          <div *ngIf="payment.method === 'DEBIT_EXTERNAL'">
            <div>
              <span class="method-title">Outros</span>
            </div>
            <div class="m-t-1">
              <blockquote class="code">Sem informações</blockquote>
            </div>
          </div>
          <div *ngIf="payment.method === 'CASHBACK'">
            <div>
              <span class="method-title">Cashback</span>
            </div>
            <div class="m-t-1">
              <blockquote class="code">Sem informações</blockquote>
            </div>
          </div>
        </section>
      </nz-tab>
      <nz-tab nzTitle="Taxas">
        <section>
          <div>
            <div class="m-t-1">
              <pre class="code" [innerHTML]="payment.fees | prettyjson : [false, 4]"></pre>
            </div>
          </div>
        </section>
      </nz-tab>
      <nz-tab nzTitle="Anti-fraude">
        <section>
          <div>
            <div class="m-t-1">
              <pre class="code" [innerHTML]="payment.antifraud | prettyjson : [false, 4]"></pre>
            </div>
          </div>
        </section>
      </nz-tab>
    </nz-tabset>
  </div>
</section>
