export class ModalModel {
  imgPath?: string;
  creditCardName?: string;
  creditCardLastNumbers?: string;
  title?: string;
  description?: string;
  buttonText?: string;
  nzButtonType?: 'primary' | 'dashed' | 'link' | 'text';
  callback?: () => void;
}
