import { Component, Input } from '@angular/core';
import { NzModalRef } from 'ng-zorro-antd/modal';

@Component({
  selector: 'app-modal-delete-key-success',
  templateUrl: './modal-delete-key-success.component.html',
  styleUrls: ['./modal-delete-key-success.component.scss'],
})
export class ModalDeleteKeySuccessComponent {
  @Input() public key: string;
  @Input() public type: string;

  constructor(public modalRef: NzModalRef) {}
}
