import { gql } from 'apollo-angular';

const LIST_APPLICATIONS_QUERY = gql`
  query ListApplications($filters: FilterApplicationParamsInput) {
    applications(filters: $filters, pagination: { orderBy: "inserted_at", sortingOrder: DESC, perPage: 100 }) {
      id
      bankSlipFeeAmountCents
      bankSlipFeePercent
      creditCardAmountCents
      creditCardPercent
      logoUrl
      name
      redirectUri
      secret
      insertedAt
      updatedAt
      seller {
        id
      }
    }
  }
`;

export { LIST_APPLICATIONS_QUERY };
