<section *ngIf="url[1] !== '/products/categories'" class="{{ noMargin ? 'no-margin-left' : '' }}">
  <nz-breadcrumb>
    <nz-breadcrumb-item>
      <a (click)="callToAction()"> Início </a>
    </nz-breadcrumb-item>
    <nz-breadcrumb-item>
      <a (click)="callToAction('SELLER_NAME')" *ngIf="url[1] !== '/cart'">{{
        url[1] === '/products/categories' ? 'Categorias' : store?.displayName
      }}</a>

      <a (click)="callToAction('SELLER_NAME')" *ngIf="url[1] === '/cart'">{{
        url[1] === '/cart' ? 'Meu carrinho' : store?.displayName
      }}</a>
    </nz-breadcrumb-item>
    <nz-breadcrumb-item *ngIf="isShowCategory && actualRoute.includes('/list')">{{
      category?.label
    }}</nz-breadcrumb-item>
  </nz-breadcrumb>

  <div class="seller-name" *ngIf="actualRoute.includes('/list')">
    <h1 *ngIf="!category || !isShowCategory">{{ store?.displayName }}</h1>
    <h1 *ngIf="isShowCategory">{{ category?.label }}</h1>
  </div>

  <div class="desktop" *ngIf="actualRoute.includes('/list')">
    <span
      class="pointer-cursor"
      nz-dropdown
      [nzDropdownMenu]="menu"
      nzTrigger="click"
      (nzVisibleChange)="orderByProducts($event)"
    >
      Ordenar por
      <img src="../../assets/images/chevron-down.svg" alt="Seta para baixo" />
    </span>
    <nz-dropdown-menu #menu="nzDropdownMenu">
      <ul nz-menu>
        <li nz-menu-item (click)="getOrderByValue('inserted_at')">Mais recentes</li>
        <li nz-menu-item (click)="getOrderByValue('name')">Ordem alfabética</li>
      </ul>
    </nz-dropdown-menu>

    <!-- <span class="m-l-1 pointer-cursor" (click)="openFilterMenu()">
        Filtro <img class="filter-img" src="../../assets/images/filter.svg" />
      </span> -->
  </div>
</section>
