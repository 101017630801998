import { ItemImages, ItemsV2Model } from './../../models/items/V2/ItemV2.model';
import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { Apollo, Query } from 'apollo-angular';
import { HttpClient } from '@angular/common/http';
import { catchError, EMPTY, mergeMap, Observable, take } from 'rxjs';

import {
  BATCH_CREATE_V2_ITEMS_MUTATION,
  CREATE_ITEM_V2_MUTATION,
  DELETE_ITEM_V2_MUTATION,
  DUPLICATE_V2_ITEMS_MUTATION,
  UPDATE_ITEM_UNIT_MUTATION,
  UPDATE_ITEM_V2_MUTATION,
} from '../../graphql/mutations/item.mutations';
import { environment } from '@gen/environments';
import {
  GET_ITEM_V2_QUERY,
  LIST_ITEMS_V2_QUERY,
  GET_ITEM_IMG_UPLOAD_URL,
  GET_ITEM_V2_PUBLIC_QUERY,
  LIST_ITEMS_V2_PUBLIC_QUERY,
  LIST_V2_ITEMS_SYNAPSES_QUERY,
} from '../../graphql/queries/item.queries';
import { base64ToBlob } from '../../utils/files';
import { AlertService } from '../alert/alert.service';
import { ErrorService } from '../error/error.service';
import { ItemImagesModel } from '../../models/items/item-images.model';
import { ItemVariantModel } from '../../models/items/V2/itemVariant.model';
import { CreateItemUnitParams } from '../../models/items/V2/createItemUnitParams';
import { StateManagementService } from '../../state-management/state-management.service';
import { AlertDuration, AlertType, StatusAlertModel } from '../../models/status/status-alert.model';
import { CreateV2ItemParams, UpsertV2ItemImageParams } from '../../models/items/V2/createItemParams';
import { ItemUnitModel } from '../../models/items/V2/itemUnit.model';
import { image } from 'html2canvas/dist/types/css/types/image';
import { AbstractControl } from '@angular/forms';
import { isEqual } from 'lodash';

@Injectable({
  providedIn: 'root',
})
export class ItemsService {
  public alertInfo: StatusAlertModel = new StatusAlertModel();
  public alertType: AlertType = new AlertType();
  public alertDuration: AlertDuration = new AlertDuration();

  constructor(
    private $error: ErrorService,
    private $alert: AlertService,
    private readonly router: Router,
    private readonly apollo: Apollo,
    private readonly $http: HttpClient,
    private $notification: StateManagementService
  ) {}

  public getV2ItemList(filters: any = {}, pagination?: any): Observable<any> {
    return this.apollo.watchQuery<Query>({
      query: LIST_ITEMS_V2_QUERY,
      variables: { filters, pagination },
      fetchPolicy: 'network-only',
    }).valueChanges;
  }

  public getV2PublicItemList(filters: any = {}, pagination?: any): Observable<any> {
    return this.apollo.watchQuery<Query>({
      query: LIST_ITEMS_V2_PUBLIC_QUERY,
      variables: { filters, pagination },
      fetchPolicy: 'network-only',
    }).valueChanges;
  }

  public getV2ItemsSynapses(filters: any = {}): Observable<any> {
    return this.apollo.watchQuery<Query>({
      query: LIST_V2_ITEMS_SYNAPSES_QUERY,
      variables: { filters },
      fetchPolicy: 'network-only',
    }).valueChanges;
  }

  public getItemV2ById(id: string): Observable<any> {
    return this.apollo.watchQuery<Query>({
      query: GET_ITEM_V2_QUERY,
      variables: { id },
      fetchPolicy: 'network-only',
    }).valueChanges;
  }

  public getItemImageUploadUrl(extension: string): Observable<any> {
    return this.apollo.watchQuery<Query>({
      query: GET_ITEM_IMG_UPLOAD_URL,
      variables: { extension },
      fetchPolicy: 'network-only',
    }).valueChanges;
  }

  public getPublicItemV2ById(id: string): Observable<any> {
    return this.apollo.watchQuery<Query>({
      query: GET_ITEM_V2_PUBLIC_QUERY,
      variables: { id },
      fetchPolicy: 'network-only',
    }).valueChanges;
  }

  public createItemV2(params: CreateV2ItemParams): Observable<any> {
    return this.apollo.mutate({
      mutation: CREATE_ITEM_V2_MUTATION,
      variables: { params },
    });
  }

  public updateV2Item(id: string, params: CreateV2ItemParams): Observable<any> {
    return this.apollo.mutate({
      mutation: UPDATE_ITEM_V2_MUTATION,
      variables: { id, params },
    });
  }

  public updateItemUnit(params: any): Observable<any> {
    const id = JSON.parse(JSON.stringify(params.id));
    delete params.id;

    return this.apollo.mutate({
      mutation: UPDATE_ITEM_UNIT_MUTATION,
      variables: { id, params },
    });
  }

  public updateActiveItem(id: string, itemUnits: CreateItemUnitParams[]): Observable<any> {
    return this.apollo.mutate({
      mutation: UPDATE_ITEM_V2_MUTATION,
      variables: { id, params: { itemUnits } },
    });
  }

  public deleteV2Item(id: string): Observable<any> {
    return this.apollo.mutate({
      mutation: DELETE_ITEM_V2_MUTATION,
      variables: { id },
    });
  }

  public batchCreateV2Items(params: any): Observable<any> {
    return this.apollo.mutate({
      mutation: BATCH_CREATE_V2_ITEMS_MUTATION,
      variables: { params },
    });
  }

  public duplicateV2Items(id: string, sellerId: string): Observable<any> {
    return this.apollo.mutate({
      mutation: DUPLICATE_V2_ITEMS_MUTATION,
      variables: { id, sellerId },
    });
  }

  public goToStore(sellerId: string): void {
    window.open(`${environment.pwaDomain}/internal/${sellerId}/store/products/list`);
  }

  public goToStoreProduct(sellerId: string, productId: string): void {
    window.open(this.getStoreProductUrl(sellerId, productId));
  }

  public getStoreProductUrl(sellerId: string, productId: string): string {
    return `${environment.pwaDomain}/internal/${sellerId}/store/products/details?productId=${productId}`;
  }

  public createProductV2(data: CreateV2ItemParams, synapses?: boolean): void {
    this.$notification.setLoading(true);

    this.createItemV2(data).subscribe({
      next: (res) => {
        if (res?.data?.createV2Item) {
          this.$alert.setAlertInfo('SUCCESS', 'Item cadastrado com sucesso!');
          if (synapses) {
            this.router.navigate([`/internal/items/products`]);
          } else {
            this.router.navigate([`internal/inventory/product`]);
          }
        }

        this.$notification.setLoading(false);
      },
      error: (error) => {
        this.$notification.setLoading(false);
        this.$error.errorHandling(error, 'Erro ao criar o item');
      },
    });
  }

  public updateProductV2(id: string, data: CreateV2ItemParams, synapses?: boolean): void {
    this.$notification.setLoading(true);

    this.updateV2Item(id, data).subscribe({
      next: (res) => {
        if (res?.data?.updateV2Item) {
          this.$alert.setAlertInfo('SUCCESS', 'Item atualizado com sucesso!');
          if (synapses) {
            this.router.navigate([`/internal/items/products`]);
          } else {
            this.router.navigate([`internal/inventory/product`]);
          }
        }

        this.$notification.setLoading(false);
      },
      error: (error) => {
        this.$notification.setLoading(false);
        this.$error.errorHandling(error, 'Erro ao atualizar o item');
      },
    });
  }

  public getTotalStockCount(item: ItemsV2Model): number {
    return item.itemUnits.reduce((acc, itemUnit) => {
      acc += itemUnit.stockCount;

      return acc;
    }, 0);
  }

  public async checkForInactives(
    sellerId: string,
    pointId: string,
    products: ItemsV2Model[],
    cartId: string
  ): Promise<boolean> {
    let foundInactive = false;

    const inactiveItens = products.filter((product) => !product.itemUnits?.[0]?.active);

    if (inactiveItens.length > 0) {
      return this.inactiveFound(sellerId, pointId);
    }

    products.map(async (element) => {
      const originalProduct = await this.getItemV2ById(element.originalId)?.pipe(take(1)).toPromise();

      if (originalProduct.active === false && originalProduct !== undefined) {
        element.itemUnits[0].active = false;
        foundInactive = true;
      }
    });

    if (foundInactive) {
      return this.inactiveFound(sellerId, pointId);
    }

    return foundInactive;
  }

  public inactiveFound(sellerId: string, pointId: string): boolean {
    this.$alert.setAlertInfo('ERROR', 'Não é possivel pagar um pedido com produtos inativos.');
    this.router.navigate(['/external/' + sellerId + '/' + pointId + '/basket']);
    return true;
  }

  public calcTotalPrice(products: Array<ItemsV2Model>): number {
    const totalPrice = products.reduce(
      (acc, product) => (acc += (product.price || product.itemUnits[0].price) * (product.quantity || 1)),
      0
    );
    return Number(totalPrice);
  }

  public hexFromColorName(colorName: string): string {
    const colors: { [key: string]: string } = {
      preto: '#000000',
      vermelho: '#C73B51',
      verde: '#FEB8C3',
      azul: '#0000FF',
      amarelo: '#48CEA6',
    };
    return colors[colorName.toLowerCase()] || '';
  }

  public uploadItemImage(file: any, storageUrl: string): Observable<any> {
    const headers = {
      'response-content-disposition': 'inline',
      'content-disposition': 'inline',
      'content-type': file.type,
    };

    return this.$http.put(storageUrl, file, { headers });
  }

  public uploadImage(url: string, obj: any): void {
    obj.url = url;
    obj.invalid = false;

    const blob = base64ToBlob(url.split(',')[1]);
    const extension = obj.file.type.split('/')[1];

    const imgLabel = obj.label + '.' + extension;

    obj.file = new File([blob], imgLabel, { type: obj.file.type });

    this.getItemImageUploadUrl(extension)
      .pipe(
        take(1),
        catchError((error) => this.handleImageError(obj, error)),
        mergeMap((res) => {
          if (res?.data?.getV2ItemImageUploadUrl) {
            const putUrl = JSON.parse(JSON.stringify(res.data.getV2ItemImageUploadUrl));
            obj.filename = putUrl.filename;

            return this.uploadItemImage(obj.file, putUrl.url).pipe(
              catchError((error) => this.handleImageError(obj, error))
            );
          }
          return EMPTY;
        })
      )
      .subscribe({
        next: (res) => {},
        error: (error) => {
          obj.loading = false;
          throw error;
        },
      });
  }

  public setItemImagesPayload(images: ItemImagesModel[], imagesBd?: ItemImagesModel[]) {
    const imageUploads: UpsertV2ItemImageParams[] = [];

    if (images && images.length > 0) {
      images.forEach((image, index) => {
        let imageUpload: UpsertV2ItemImageParams = null;

        if (image.id && imagesBd?.length > index) {
          imageUpload = {
            id: imagesBd[index].id,
            url: image.url,
          };
        }

        if (image.filename) {
          imageUpload = {
            orderIndex: image.orderIndex,
            filename: image.filename,
            url: image.url,
            description: image.label,
            id: image.id,
          };
        }

        if (imageUpload) {
          imageUploads.push(imageUpload);
        }
      });
    }

    return imageUploads;
  }

  private handleImageError(obj: any, error: any): Observable<never> {
    obj.loading = false;

    this.$error.errorHandling(error, 'Erro no upload de imagens');
    throw error;
  }

  public getItemUnitImageUrl(element: ItemUnitModel, item: ItemsV2Model) {
    let imageUrl = element.image?.url;

    if (!imageUrl) {
      element.itemVariantOptions.map((res) => {
        const itemVariant = item.itemVariants.find((variant) =>
          variant.itemVariantOptions.some((option) => option.id === res.id)
        );

        const optionsWithImages = itemVariant.itemVariantOptions.filter(
          (option) => option.id === res.id && option.images && option.images.some((image) => image.url)
        );

        if (optionsWithImages && optionsWithImages.length > 0) {
          imageUrl = optionsWithImages[0].images[0].url;
        }
      });
    }

    return imageUrl;
  }

  public setItemVariant(item: Partial<ItemsV2Model>): ItemsV2Model {
    const data: ItemsV2Model = JSON.parse(JSON.stringify(item));
    data.itemVariants?.forEach((itemVariant) => {
      const idTracker = {};

      itemVariant.itemVariantOptions = itemVariant.itemVariantOptions.filter((itemVariantOption) => {
        if (!idTracker[itemVariantOption.id]) {
          idTracker[itemVariantOption.id] = true;
          return true;
        }
        return false;
      });
    });

    data.itemVariants?.map((itemVariant: ItemVariantModel) => {
      itemVariant.itemVariantOptionsNames = itemVariant.itemVariantOptions
        .map((itemVariantOption) => itemVariantOption.name)
        .join(', ');

      itemVariant.itemVariantOptions.map((res) => {
        const optionsWithImages = res.itemUnitVariantOptions.filter(
          (option) => option.images && option.images.some((image) => image.url)
        );
        res.itemUnitVariantOption = optionsWithImages.length > 0 ? optionsWithImages[0] : res.itemUnitVariantOptions[0];

        res.images = res.itemUnitVariantOption?.images;
        res.orderIndex = res.itemUnitVariantOption.orderIndex;
      });

      itemVariant.orderIndex = itemVariant?.itemVariantOptions?.[0]?.orderIndex;
    });

    data.itemVariants?.sort((a, b) => a.orderIndex - b.orderIndex);

    return data;
  }

  public cloneItem(itemId: string, sellerId: string): void {
    this.duplicateV2Items(itemId, sellerId).subscribe({
      next: (res) => {
        if (res.data.duplicateV2Item) {
          const id = res.data.duplicateV2Item.id;
          this.router.navigate([`internal/inventory/create-edit-item/edit/${id}`]);
        }
      },
      error: (error) => {
        this.$error.errorHandling(error, 'Erro ao duplicar o item');
        throw error;
      },
    });
  }

  public setMarketplaceItemUnit(product: ItemsV2Model, itemVariantsControls): any {
    const selecteditemVariantOptions: Array<string> = [];
    let itemVariants: Array<ItemVariantModel> = [];

    if (itemVariantsControls.controls.length > 0) {
      itemVariantsControls.controls.forEach((element: AbstractControl<any>) => {
        selecteditemVariantOptions.push(element.value.selectedItemVariantOption);
        itemVariants.push({
          id: element.value.id,
          name: element.value.name,
          type: element.value.type,
          itemVariantOptions: [
            element.value.itemVariantOptions.find(
              (itemVariantOption) => itemVariantOption.id === element.value.selectedItemVariantOption
            ),
          ],
        });
      });

      product.itemVariants = itemVariants;
    }

    let itemUnit = product.itemUnits[0];

    if (product?.itemVariants?.length > 0) {
      product?.itemUnits.forEach((price) => {
        price.itemVariantOptionsIds = price.itemVariantOptions.map((itemVariantOption) => itemVariantOption.id);
      });

      itemUnit = product?.itemUnits.find((price) =>
        isEqual(selecteditemVariantOptions.sort(), price.itemVariantOptionsIds.sort())
      );
      const amountCents = itemUnit?.price / 100 || undefined;
      product.price = amountCents;
    }

    product.price = itemUnit?.price;
    product.selectedItemUnit = itemUnit;
    product.selectedItemUnit.itemUnitVariantOptionsNames = product.selectedItemUnit.itemVariantOptions
      .map((itemVariantOption) => itemVariantOption.name)
      .join(', ');

    return product;
  }

  public setMarketplaceProduct(item: ItemsV2Model): ItemsV2Model {
    let product = JSON.parse(JSON.stringify(item));
    product = this.setItemVariant(product);
    product.selectedItemUnit = product.itemUnits?.[0];

    if (product.selectedItemUnit) {
      product.selectedItemUnit.itemUnitVariantOptionsNames = product.selectedItemUnit.itemVariantOptions
        ?.map((itemVariantOption) => itemVariantOption.name)
        .join(', ');
    }

    product.quantity = 1;
    product?.itemUnits?.map((price) => {
      price.itemVariantOptionsIds = price.itemVariantOptions.map((itemVariantOption) => itemVariantOption.id);
    });

    product.price = product?.itemUnits?.[0]?.price;
    return product;
  }

  public setMarketplaceProductImages(product: ItemsV2Model): ItemImages[] {
    let images = [];
    if (product.itemVariants.length > 0) {
      images = product.itemVariants.flatMap(
        (variant) => variant.itemVariantOptions?.flatMap((option) => option.images || []) || []
      );

      // Ordena as imagens pelo índice de ordem
      images.sort((a, b) => a.orderIndex - b.orderIndex);
    }

    if (images.length === 0) {
      images = product.images?.sort((a, b) => a.orderIndex - b.orderIndex);
    }

    return images;
  }
}
