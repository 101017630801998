import { AbstractControl, ValidatorFn } from '@angular/forms';

import { CpfValidator } from './cpfValidator';
import { CnpjValidator } from './cnpjValidator';

export class CpfCnpjValidator {
  static isValid(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const value = control.value;

      if (!value || value.length === 0) {
        return null;
      }

      if (value.length === 11) {
        return CpfValidator.isValid()(control);
      }

      if (value.length === 14) {
        return CnpjValidator.isValid()(control);
      }

      return { cpfCnpjNotValid: true };
    };
  }
}
