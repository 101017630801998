import { NzModalRef } from 'ng-zorro-antd/modal';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-modal-incorrect-image',
  templateUrl: './modal-incorrect-image.component.html',
  styleUrls: ['./modal-incorrect-image.component.scss'],
})
export class ModalIncorrectImageComponent implements OnInit {
  @Input() public type: string;

  public message: string;
  public title: string;

  constructor(public modalRef: NzModalRef) {}

  ngOnInit(): void {
    this.getData();
  }

  private getData(): void {
    if (this.type === 'no_image') {
      this.message = 'Imagem obrigatória.';
      this.title = 'Imagem obrigatória';
    }
    if (this.type === 'invalid_image') {
      this.message = 'Insira uma imagem de até 5 MB nos formatos JPG, PNG, SVG e Webp.';
      this.title = 'Imagem ou formato não suportado';
    }
  }
}
