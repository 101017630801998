import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { Apollo, Query } from 'apollo-angular';

import { LIST_ITEM_TAGS_QUERY } from '../../../graphql/queries/item.queries';

@Injectable()
export class TagService {
  constructor(private readonly apollo: Apollo) {}

  public getItemTags(sellerId: string): Observable<any> {
    return this.apollo.watchQuery<Query>({
      query: LIST_ITEM_TAGS_QUERY,
      variables: { sellerId },
      fetchPolicy: 'cache-and-network',
    }).valueChanges;
  }
}
