<main id="banners" class="{{ displayAt === 'MARKETPLACE' ? 'marketplace-height' : '' }}">
  <nz-row>
    <nz-col nzSpan="24">
      <nz-carousel
        #carousel
        [nzDots]="!isMobile && banners.length > 1"
        nzAutoPlay
        [nzAutoPlaySpeed]="5000"
        [nzEnableSwipe]="banners.length > 1"
        nzEffect="scrollx"
        class="carousel"
      >
        <div nz-carousel-content *ngFor="let banner of banners; trackBy: trackByFn">
          <img
            nz-image
            [nzDisablePreview]="true"
            [nzSrc]="banner?.bannerUrl"
            alt="Imagens de banners"
            class="banner-img {{ displayAt === 'MARKETPLACE' ? 'bd-16' : '' }}"
            (click)="goToOutside(banner)"
          />

          <div class="arrows" *ngIf="banners.length > 1">
            <img
              [src]="isMobile ? '/assets/img/mobile-arrow-left.svg' : '/assets/img/arrow-left.svg'"
              alt="Ícone de seta para a esquerda."
              (click)="prevAndNext($event, 'prev')"
              class="arrow-left pointer-cursor"
            />
            <img
              [src]="isMobile ? '/assets/img/mobile-arrow-right.svg' : '/assets/img/arrow-right.svg'"
              alt="Ícone de seta para a direita."
              (click)="prevAndNext($event, 'next')"
              class="arrow-right pointer-cursor"
            />
          </div>
        </div>
      </nz-carousel>
    </nz-col>
  </nz-row>
  <nz-row class="mobile" *ngIf="banners.length > 1">
    <nz-col nzSpan="24" class="center">
      <ul class="banner-dots">
        <li
          *ngFor="let banner of banners; trackBy: trackByFn; let i = index"
          [class.banner-active]="carousel.activeIndex === i"
        >
          <span (click)="carousel.goTo(i)" class="pointer-cursor"></span>
        </li>
      </ul>
    </nz-col>
  </nz-row>
</main>
