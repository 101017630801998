import { gql } from 'apollo-angular';

const UPSERT_STORE = gql`
  mutation UpsertStore($params: UpsertStoreParamsInput!, $sellerId: String!) {
    upsertStore(params: $params, sellerId: $sellerId) {
      id
    }
  }
`;

const UPSERT_STORE_PREFERENCES = gql`
  mutation UpsertStorePreferences($params: UpsertStorePreferencesParamsInput!, $sellerId: String!) {
    upsertStorePreferences(params: $params, sellerId: $sellerId) {
      id
    }
  }
`;

const UPDATE_STORE_WHATSAPP_NUMBER_CONFIRMATION = gql`
  mutation UpdateStoreWhatsappNumberConfirmation(
    $params: UpdateStoreWhatsappNumberConfirmationParamsInput!
    $sellerId: String!
  ) {
    updateStoreWhatsappNumberConfirmation(params: $params, sellerId: $sellerId) {
      whatsappNumber
    }
  }
`;

const UPDATE_STORE_WHATSAPP_NUMBER = gql`
  mutation UpdateStoreWhatsappNumber($params: UpdateStoreWhatsappNumberParamsInput!, $sellerId: String!) {
    updateStoreWhatsappNumber(params: $params, sellerId: $sellerId) {
      whatsappNumber
    }
  }
`;

export {
  UPSERT_STORE,
  UPSERT_STORE_PREFERENCES,
  UPDATE_STORE_WHATSAPP_NUMBER_CONFIRMATION,
  UPDATE_STORE_WHATSAPP_NUMBER,
};
