<div class="h-100-no-overflow">
  <section class="d-flex">
    <div class="wrapper-buttons d-flex w-100"></div>
    <div class="wrapper-content d-flex">
      <img *ngIf="imgPath" [src]="imgPath" />
      <h1 class="m-t-2" *ngIf="title">{{ title }}</h1>
      <p class="description center m-t-1">{{ description }}</p>
      <p class="paid-value" *ngIf="pageType === 'approvedTicket'">
        <strong>{{ paidValue | currency }} </strong>
      </p>
      <p class="exit-limit" *ngIf="pageType === 'approvedTicket'">
        Limite de saída:
        <strong>
          {{ ticket?.validadeDatahora | date : 'HH:mm' }}
        </strong>
        - {{ ticket?.validadeDatahora | date : 'dd/MM/YYYY' }}
      </p>
    </div>
    <div class="wrapper-buttons d-flex w-100 m-b-1">
      <app-primary-button *ngIf="primaryButtonText" (click)="handlePrimaryButton()" [block]="true" class="w-100">
        {{ primaryButtonText }}
      </app-primary-button>
      <app-secondary-button *ngIf="secondaryButtonText" (click)="handleSecondaryButton()" [block]="true" class="w-100">
        {{ secondaryButtonText }}
      </app-secondary-button>
    </div>
  </section>
</div>
