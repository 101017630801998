import { gql } from 'apollo-angular';

const CREATE_INTERNAL_USER_MUTATION = gql`
  mutation CreateInternalUser(
    $name: String!
    $phone: String!
    $email: String!
    $document: String!
    $role: UserRoleEnum!
  ) {
    createInternalUser(name: $name, phone: $phone, email: $email, document: $document, role: $role) {
      id
    }
  }
`;

const UPDATE_INTERNAL_USER_MUTATION = gql`
  mutation UpdateInternalUser($id: String, $name: String, $role: UserRoleEnum) {
    updateInternalUser(id: $id, name: $name, role: $role) {
      id
    }
  }
`;

const UPDATE_USER_AUTHENTICATED_MUTATION = gql`
  mutation updateUserAuthenticated(
    $authCode: String!
    $id: String!
    $email: String
    $phone: String
    $platform: PlatformEnum
  ) {
    updateUserAuthenticated(authCode: $authCode, id: $id, email: $email, phone: $phone, platform: $platform) {
      id
    }
  }
`;

const CREATE_SUBSELLER_MUTATION = gql`
  mutation CreateSubseller($sellerId: String!, $params: CreateUserSellerParamsInput!) {
    createSubseller(sellerId: $sellerId, params: $params) {
      id
    }
  }
`;

const UPDATE_SUBSELLER_MUTATION = gql`
  mutation UpdateSubseller($userId: String!, $sellerId: String!, $params: CreateUserSellerParamsInput!) {
    updateSubseller(userId: $userId, sellerId: $sellerId, params: $params) {
      id
    }
  }
`;

const DELETE_SUBSELLER_MUTATION = gql`
  mutation DeleteSubseller($sellerId: String!, $userId: String!) {
    deleteSubseller(sellerId: $sellerId, userId: $userId) {
      id
    }
  }
`;

const DEACTIVE_INTERNAL_USER_MUTATION = gql`
  mutation DeactivateInternalUser($id: String!) {
    deactivateInternalUser(id: $id) {
      id
    }
  }
`;

const DELETE_INTERNAL_USER_MUTATION = gql`
  mutation DeleteInternalUser($id: String) {
    deleteInternalUser(id: $id) {
      id
    }
  }
`;

export {
  CREATE_INTERNAL_USER_MUTATION,
  UPDATE_INTERNAL_USER_MUTATION,
  CREATE_SUBSELLER_MUTATION,
  UPDATE_SUBSELLER_MUTATION,
  UPDATE_USER_AUTHENTICATED_MUTATION,
  DELETE_SUBSELLER_MUTATION,
  DEACTIVE_INTERNAL_USER_MUTATION,
  DELETE_INTERNAL_USER_MUTATION,
};
