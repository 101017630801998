<section>
  <form nz-form [formGroup]="formGroup">
    <nz-form-item>
      <nz-form-control>
        <nz-textarea-count [nzMaxCharacterCount]="255">
          <textarea
            rows="4"
            nz-input
            formControlName="reason"
            class="m-t-2"
            placeholder="Documento com baixa resolução, sem nitidez..."
          ></textarea>
        </nz-textarea-count>
      </nz-form-control>
    </nz-form-item>
  </form>

  <div *nzModalFooter>
    <button
      nz-button
      nzDanger
      (click)="handleReject()"
      [nzBlock]="true"
      nzType="primary"
      [disabled]="formGroup.invalid"
      class="m-t-0"
    >
      REJEITAR
    </button>
  </div>
</section>
