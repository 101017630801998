import { Component, ViewChildren } from '@angular/core';

import { AuthService } from '../../../services/auth/auth-business/auth.service';
import { NzModalRef } from 'ng-zorro-antd/modal';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { CreateAccountModel } from '../../../models/auth/create-account.model';
import { NzMessageService } from 'ng-zorro-antd/message';
import moment from 'moment';
import { Observable, Subject, interval, takeUntil } from 'rxjs';
import { LoginModel } from '../../../models/login/login.model';
import { StateManagementService } from '../../../state-management/state-management.service';

@Component({
  selector: 'app-modal-register-marketplace',
  templateUrl: './modal-register-marketplace.component.html',
  styleUrls: ['./modal-register-marketplace.component.scss'],
})
export class ModalRegisterMarketplaceComponent {
  @ViewChildren('input') inputsList!: any;

  public title: string = 'Crie sua conta';

  public form: FormGroup;
  public login: LoginModel;
  public formGroupCodeVerify: FormGroup;
  public createAccountPaylod: CreateAccountModel;

  public isGoBack: boolean = false;
  public loadingSignIn: boolean = false;
  public loadingCodeBtn: boolean = false;
  public isShowSmsConfirm: boolean = false;

  public countdown: number = 60;
  private timer?: Observable<number>;

  private ispause = new Subject();

  constructor(
    private $auth: AuthService,
    public $modalRef: NzModalRef,
    private fb: FormBuilder,
    private readonly $message: NzMessageService,
    private $notification: StateManagementService
  ) {}

  public ngOnInit(): void {
    this.createForm();

    this.timer = interval(1000).pipe(takeUntil(this.ispause));
    this.timer.subscribe({
      next: (_: number) => {
        if (this.countdown === 0) {
          this.ispause.next;
        }

        this.countdown--;
      },
    });
  }

  public createForm(): void {
    this.form = this.fb.group({
      fullName: new FormControl('', [Validators.required]),
      document: new FormControl('', [Validators.required]),
      phoneNumber: new FormControl('', [Validators.required]),
      email: new FormControl('', [Validators.required]),
    });

    this.formGroupCodeVerify = this.fb.group({
      code1: new FormControl('', [Validators.required]),
      code2: new FormControl('', [Validators.required]),
      code3: new FormControl('', [Validators.required]),
      code4: new FormControl('', [Validators.required]),
      code5: new FormControl('', [Validators.required]),
      code6: new FormControl('', [Validators.required]),
    });
  }

  public resendSmsCode(): void {
    this.loadingCodeBtn = true;

    this.$auth.sendVerificationCode({ phoneNumber: this.form.get('phoneNumber').value }, 'SMS', 'B2C').subscribe({
      next: (res) => {
        if (res?.data?.sendVerificationCode) {
          this.countdown = 60;
        }

        this.loadingCodeBtn = false;
      },
      error: (error) => {
        this.$message.create('error', error);
        this.loadingCodeBtn = false;
        throw new Error(error);
      },
    });
  }

  public validatePhoneNumberCode(): void {
    this.loadingCodeBtn = true;

    const code =
      this.formGroupCodeVerify.get('code1').value +
      this.formGroupCodeVerify.get('code2').value +
      this.formGroupCodeVerify.get('code3').value +
      this.formGroupCodeVerify.get('code4').value +
      this.formGroupCodeVerify.get('code5').value +
      this.formGroupCodeVerify.get('code6').value;

    this.createAccountPaylod = new CreateAccountModel();
    this.createAccountPaylod.code = code;
    this.createAccountPaylod.phoneNumber = this.form.get('phoneNumber').value;
    this.createAccountPaylod.type = 'SIGN_UP_PHONE_NUMBER';

    this.$auth.validateVerificationCode(this.createAccountPaylod, 'B2C').subscribe({
      next: (res) => {
        if (res?.data?.validateVerificationCode) {
          this.$auth.customLoginMarketplace(res?.data?.validateVerificationCode);

          this.$modalRef.close();
        }

        this.loadingCodeBtn = false;
      },
      error: (error) => {
        this.loadingCodeBtn = false;
        this.$message.create('error', error);
        throw new Error(error);
      },
    });
  }

  public onInput(event: any, index: number): void {
    if (event.data) {
      this.inputsList._results[index].nativeElement.focus();
    }
  }

  public onPressDeleteKey(event: KeyboardEvent, index: number): void {
    if (event.key === 'Backspace') {
      const input = this.inputsList._results[index];
      if (input) {
        if (index === 5) {
          input.nativeElement.value = null;
          input.nativeElement.focus();
        }
        const previousInput = this.inputsList._results[index - 1];
        if (previousInput) {
          previousInput.nativeElement.value = null;
          previousInput.nativeElement.focus();
        }
      }
    }
  }

  public pasteNumeric(event: ClipboardEvent): void {
    if (event.clipboardData) {
      const code = event.clipboardData.getData('text').replace(/[^0-9]/g, '');

      this.formGroupCodeVerify.setValue({
        code1: code[0] || '',
        code2: code[1] || '',
        code3: code[2] || '',
        code4: code[3] || '',
        code5: code[4] || '',
        code6: code[5] || '',
      });

      if (code.length === 6) {
        setTimeout(() => {
          this.validatePhoneNumberCode();
          event.preventDefault();
        }, 500);
      }
    }
  }

  public onSignIn(): void {
    this.loadingSignIn = true;

    this.login = new LoginModel();
    this.login.phoneNumber = this.form.get('phoneNumber').value;

    this.$auth.sendVerificationCode({ phoneNumber: this.login.phoneNumber }, 'SMS', 'B2C').subscribe({
      next: (res) => {
        if (res?.data?.sendVerificationCode) {
          this.$notification.setPhoneNumber(this.login.phoneNumber);
        }

        this.isShowSmsConfirm = true;
        this.title = 'Insira o código';
        this.loadingSignIn = false;
      },
      error: (error) => {
        this.$message.create('error', error);
        this.loadingSignIn = false;
        throw new Error(error);
      },
    });
  }

  public stepBack(): void {
    this.isShowSmsConfirm = false;
  }
}
