<header *nzModalTitle>
  <h3 class="title-category">{{ title }}</h3>
  <span class="example-spacer"></span>
  <img src="../../assets/images/close.svg" (click)="$modalRef.close()" class="pointer-cursor" />
</header>

<div class="body">
  <h3 [innerHTML]="titleName"></h3>
  <p [innerHTML]="message"></p>
</div>

<footer *nzModalFooter>
  <div class="buttons w-100">
    <app-primary-button (click)="$modalRef.close()">{{ buttonMessage }}</app-primary-button>
  </div>
</footer>
