import { Component, Input } from '@angular/core';
import { NzDrawerService } from 'ng-zorro-antd/drawer';
import { NzMessageService } from 'ng-zorro-antd/message';

import { Message } from '../../../utils/message';
import { UserModel } from '../../../models/users/users.model';
import { UsersService } from '../../../services/users/users.service';
import { DrawerCreateUserComponent } from '../drawer-create-user/drawer-create-user.component';

@Component({
  selector: 'app-user-details',
  templateUrl: './user-details.component.html',
  styleUrls: ['./user-details.component.scss'],
})
export class UserDetailsComponent {
  @Input() value: UserModel;

  constructor(
    private $users: UsersService,
    private readonly $drawer: NzDrawerService,
    private readonly $message: NzMessageService
  ) {}

  public showEditUserModal(value: UserModel): void {
    this.$drawer
      .create({
        nzContentParams: { value },
        nzWidth: '80%',
        nzContent: DrawerCreateUserComponent,
        nzClosable: false,
        nzFooter: null,
      })
      .afterClose.subscribe((res) => {
        if (res) {
          this.$users.updateInternalUser(res.id, res.name, res.role).subscribe({
            next: (res) => {
              if (res.data?.updateInternalUser) {
                this.$message.success(Message.USER_CREATED);
              }
            },
            error: (error) => {
              this.$message.error(Message.ERROR_CREATED_USER);
              throw new Error(error);
            },
          });
        }
      });
  }
}
