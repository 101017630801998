<app-loading *ngIf="loading"></app-loading>
<section *ngIf="!loading">
  <div class="mobile">
    <header class="header">
      <div class="close-menu">
        <img (click)="$modal.close()" src="../../assets/images/chevron-left.svg" alt="Ícone para fechar" />
      </div>

      <div class="title-sub-header centerBlock">Categorias</div>

      <div class="center shopping-cart"></div>
    </header>

    <body>
      <div *ngFor="let category of productsCategoryList">
        <nz-collapse nzAccordion [nzBordered]="false" nzExpandIconPosition="right">
          <nz-collapse-panel
            [nzHeader]="category.label"
            [nzShowArrow]="category.isParent"
            (click)="!category.isParent ? onSelectCategory(category, 'selected') : ''"
          >
            <div *ngFor="let childCategory of category.children">
              <p (click)="onSelectCategory(childCategory, 'selected')">
                {{ childCategory.label }}
              </p>
            </div>
          </nz-collapse-panel>
        </nz-collapse>
      </div>
    </body>
  </div>

  <div class="desktop m-t-1 between">
    <div class="left m-l-2">
      <img class="gen-logo" src="../../assets/images/logo-gen.svg" />
    </div>

    <div class="d-flex m-r-2">
      <div class="m-r-1">
        <img src="../../assets/images/search.svg" />
      </div>
      <div>
        <img
          (click)="clickedFavorite = !clickedFavorite"
          class="favorite"
          [src]="!clickedFavorite ? imagesUrl[2] : imagesUrl[1]"
          alt="loading Payment"
        />
      </div>

      <div class="center shopping-cart">
        <img class="filter-img n-mt-2" [src]="imagesUrl[0]" />
      </div>
    </div>
  </div>

  <div class="desktop d-flex centerBlock center m-span">
    <nz-tabset>
      <nz-tab *ngFor="let category of itemCategorys; let i = index" [nzTitle]="category.name">
        <div class="category-container">
          <div *ngFor="let children of category.children">
            <span class="pointer-cursor" (click)="onSelectCategory(category, 'selected')">{{ children.name }}</span>
          </div>
        </div>
      </nz-tab>
    </nz-tabset>

    <div *ngFor="let category of categories; let i = index">
      <span
        (click)="onSelectCategory(category)"
        class="{{ i > 0 ? 'm-l-1' : '' }} {{ selectedProductCategory === category ? 'selected' : '' }}"
      >
        {{ category.name }}
        <div class="inline center">
          <span class="chield-categ m-t-1"> sub-categoria </span>
          <span class="chield-categ"> sub-categoria </span>
        </div>
      </span>
    </div>
  </div>
</section>

<ng-template #infoAllCategorys>
  <svg
    id="imgElementAllCategory"
    class="icon-collapse-template"
    width="24"
    height="25"
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9 6.5L15 12.5L9 18.5"
      stroke="#333333"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</ng-template>
