<section class="qr_code_success">
  <h1>QR Code Pix Estático</h1>
  <nz-divider></nz-divider>
  <div class="content">
    <p>QR Code sem valor definido</p>
    <span class="value">R$ 0,00</span>
    <div #qrCodeEl>
      <nz-qrcode [nzValue]="qrCode"></nz-qrcode>
    </div>

    <p class="key-type">{{ type }}</p>
    <span class="key">{{ key }}</span>
    <div class="warning-info">
      <span nz-icon nzType="exclamation-circle" nzTheme="outline"></span>
      <div>
        <h2>Use essa imagem ou o código para receber</h2>
        <p>Mostre o QR Code ou, se preferir, compartilhe o código ou a imagem com a pessoa que vai fazer o pagamento</p>
      </div>
    </div>
    <app-secondary-button (click)="downloadImg()">Baixar imagem</app-secondary-button>
    <a (click)="copy()">Copiar código</a>
  </div>
</section>

<ng-template #customToaster>
  <div class="customToaster">
    <span nz-icon nzType="exclamation-circle" class="exclamation"></span>
    <div class="content">
      <h4>Sucesso</h4>
      <p>Código copiado com sucesso!</p>
    </div>
    <span nz-icon nzType="close" (click)="removeToaster()" class="close"></span>
  </div>
</ng-template>
