<main id="create-locker-order">
  <section id="header">
    <nz-row>
      <nz-col nzSpan="12">
        <span class="title">Gerar pedido</span>
      </nz-col>
      <nz-col nzSpan="12" class="endBlock">
        <img
          src="/assets/icons/xmark.svg"
          alt="Ícone para fechar o modal"
          class="pointer-cursor"
          (click)="closeModal()"
        />
      </nz-col>
    </nz-row>
  </section>

  <nz-divider></nz-divider>

  <section id="body">
    <form [formGroup]="formGroup">
      <nz-row class="m-b-1-2">
        <nz-col nzSpan="24" class="justify">
          <div class="d-inline w-100">
            <span class="subtitle">
              Insira o número da nota fiscal e em seguida informe o tamanho de cada volume do pedido.
            </span>
            <a href="/assets/files/genbox_gavetas.pdf" download="genbox_gavetas" class="subtitle-link">
              Veja quantidade e os tamanhos das gavetas do Gen Box.
            </a>
          </div>
        </nz-col>
      </nz-row>
      <nz-row>
        <nz-col nzSpan="24">
          <nz-form-item>
            <nz-form-label class="startBlock input-text" [nzNoColon]="true" [nzSm]="24" nzFor="nfeNumber">
              Número da nota fiscal
            </nz-form-label>
            <nz-form-control [nzSm]="24" [nzXs]="24" nzHasFeedback nzErrorTip="Campo obrigatório.">
              <nz-input-group nzSize="large">
                <input
                  nz-input
                  type="text"
                  placeholder="Insira o número da NFe"
                  formControlName="nfeNumber"
                  id="nfeNumber"
                />
              </nz-input-group>
            </nz-form-control>
          </nz-form-item>
        </nz-col>
      </nz-row>
      <nz-row class="center">
        <nz-col nzSpan="24" class="header">
          <span class="header-title">Pedido: Quantidade de itens</span>
        </nz-col>
      </nz-row>
      <div *ngFor="let volume of volumesControl().controls; let i = index">
        <div formArrayName="volumes">
          <div [formGroupName]="i">
            <nz-row class="m-b-1-2 m-t-1">
              <nz-col nzSpan="12">
                <span class="subheader-title">Volume {{ i + 1 }}</span>
              </nz-col>
              <nz-col nzSpan="12" class="endBlock">
                <img
                  src="/assets/icons/trash-black.svg"
                  alt="Ícone de uma lixeira"
                  class="pointer-cursor"
                  (click)="removeVolume(i)"
                  *ngIf="i > 0"
                />
              </nz-col>
            </nz-row>

            <nz-row>
              <nz-col nzSpan="24">
                <nz-form-item>
                  <nz-form-label class="startBlock input-text" [nzNoColon]="true" [nzSm]="24" nzFor="size">
                    Tamanho
                  </nz-form-label>
                  <nz-form-control [nzSm]="24" [nzXs]="24" nzHasFeedback nzErrorTip="Campo obrigatório.">
                    <nz-select id="size" formControlName="size" nzPlaceHolder="Escolha uma opção">
                      <nz-option nzLabel="P (300 x 70 x 400mm)" nzValue="S"> </nz-option>
                      <nz-option nzLabel="M (300 x 100 x 400mm)" nzValue="M"> </nz-option>
                      <nz-option nzLabel="G (300 x 350 x 400mm)" nzValue="L"> </nz-option>
                    </nz-select>
                  </nz-form-control>
                </nz-form-item>
              </nz-col>
            </nz-row>
          </div>
        </div>

        <nz-divider></nz-divider>
      </div>
      <nz-row *ngIf="volumesControl().length < 3">
        <nz-col nzSpan="24" class="add-btn">
          <a (click)="addVolume()" class="pointer-cursor">
            <span nz-icon nzType="plus" nzTheme="outline" class="m-r-0-1"></span>
            Adicionar volume
          </a>
        </nz-col>
      </nz-row>
    </form>
  </section>

  <nz-divider></nz-divider>

  <section id="footer">
    <nz-row [nzGutter]="24">
      <nz-col nzSpan="12">
        <app-secondary-button type="RED" [block]="true" class="w-100 center" (click)="closeModal()">
          Cancelar
        </app-secondary-button>
      </nz-col>
      <nz-col nzSpan="12">
        <app-primary-button
          [block]="true"
          class="w-100 center"
          [disabled]="formGroup.invalid || loading"
          [loading]="loading"
          (click)="generateNewLockerOrder()"
        >
          Gerar
        </app-primary-button>
      </nz-col>
    </nz-row>
  </section>
</main>
