import { ItemModel } from '../items/item.model';
import { LockerModel } from '../locker/locker.model';
import { SellerModel } from '../sellers/sellers.model';
import { ContactModel } from '../contact/contact.model';
import { CustomerModel } from '../customer/customer.model';
import { ChannelMethods } from '../../types/channel-methods.type';
import { PaymentMethods } from '../../types/payment-methods.type';
import { DeliveryMethods } from '../../types/delivery-methods.type';
import { AddressModel } from '../address/address.model';

export class OrderModel {
  public id?: string;
  public orderId?: string;
  public marketplaceId?: string;
  public customerId?: string;
  public method?: string;
  public methods?: PaymentMethods[];
  public observation?: string;
  public contact?: ContactModel;
  public status: string;
  public customerData?: CustomerModel;
  public externalUserId?: string;
  public sellerId: string;
  public deliveryStatus?: string;
  public store?: string;
  public pointOfSaleId?: string;
  public deliveryContactName?: string;
  public deliveryPhone?: string;
  public items?: Array<ItemModel>;
  public subtotalCents: number;
  public totalCents: number;
  public discountCents: number;
  public additionCents: number;
  public maxInstallments?: number;
  public shippingAddress?: AddressModel;
  public type?: string;
  public insertedAt: string;
  public updatedAt: string;
  public publicCustomer?: CustomerModel;
  public subscriptionId?: string;
  public expirationDate?: string;
  public userExternalId?: string;
  public haveLink?: boolean;
  public deliveryMethod?: DeliveryMethods;
  public channel?: ChannelMethods;
  public publicSeller?: SellerModel;
  public seller?: SellerModel;
  public loading?: boolean;
  public customer?: CustomerModel;
  public shippingCents?: number;
  public payments?: any;
  public boxCode?: string = '';
  public boxName?: string = '';
  public lockerOrders?: Array<LockerModel> = [];
}
