import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { Component, OnInit } from '@angular/core';

import { Message } from 'libs/shared/src/lib/utils/message';
import { AuthService } from 'libs/shared/src/lib/services/auth/auth-business/auth.service';
import { ForgotPasswordService } from 'libs/shared/src/lib/services/external/forgot-password.service';
import { StateManagementService } from 'libs/shared/src/lib/state-management/state-management.service';
import { UserModel } from 'libs/shared/src/lib/models/users/users.model';
import { AlertService, AuthStoreService, ErrorService } from '@gen/shared';
import { LoginModel } from 'libs/shared/src/lib/models/login/login.model';
import { PointModel } from '../../../models/points/point-of-sales.model';

@Component({
  selector: 'app-customer-create-password',
  templateUrl: './create-password.component.html',
  styleUrls: ['./create-password.component.scss'],
})
export class CreatePasswordComponent implements OnInit {
  public rehide: boolean = true;
  public rehideConfirm: boolean = true;
  public isNumber: string = 'initial';
  public isCapitalLetter: string = 'initial';
  public isSpecialCharacter: string = 'initial';
  public isLowerCase: string = 'initial';
  public isLengthNumber: string = 'initial';
  public isValid: boolean = false;
  public showDetails: boolean = false;
  public formCreatePassword: FormGroup;
  public pattern: RegExp = /(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%&*()]).{6,}/;
  public errorPasswordPattern: string = Message.MESSAGE_ERROR_PASSWORD_PATTERN;
  public errorPasswordRequired: string = Message.MESSAGE_ERROR_PASSWORD_REQUIRED;
  public errorConfirmPasswordValid: string = Message.MESSAGE_ERROR_CONFIRM_PASSWORD_VALID;
  public login: LoginModel;
  public loadingBtn: boolean = false;
  public user: UserModel;
  public point: PointModel;

  constructor(
    private $auth: AuthService,
    private readonly fb: FormBuilder,
    private readonly $error: ErrorService,
    private $storeAuth: AuthStoreService,
    private readonly $alert: AlertService,
    private $notification: StateManagementService,
    private $forgotPassword: ForgotPasswordService
  ) {}

  public ngOnInit(): void {
    this.createForm();
    this.getNotifications();
  }

  public createForm(): void {
    this.formCreatePassword = this.fb.group({
      password: new FormControl('', [Validators.required, Validators.pattern(this.pattern)]),
      confirmPassword: new FormControl('', [Validators.required, this.confirmedValidator()]),
    });
  }

  public getNotifications(): void {
    this.$notification.points.subscribe((point) => (this.point = point));

    this.$notification.users.subscribe((res) => {
      if (res) {
        this.user = res;
      } else {
        if (!this.user?.id) {
          this.$notification.setLoginActualPage('signin-password');
        }
      }
    });
  }

  public submit(): void {
    this.loadingBtn = true;

    const password = this.formCreatePassword.value.password;
    const confirmPassword = this.formCreatePassword.value.confirmPassword;

    this.$auth.createPassword(this.user.id, password, confirmPassword).subscribe({
      next: (res) => {
        if (res?.data?.createPassword) {
          this.$alert.setAlertInfo('SUCCESS', 'Senha criada com sucesso!');
          this.$storeAuth.customLogin(sessionStorage.getItem('accessToken'), this.point);
        }

        this.loadingBtn = false;
      },
      error: (error) => {
        this.loadingBtn = false;
        this.$error.errorHandling(error, 'Erro ao criar sua senha. Por favor, tente novamente.');
        throw new Error(error);
      },
    });
  }

  public onKeyPassword(): void {
    this.showDetails = !this.$forgotPassword.validateStrongPassword(this.formCreatePassword.get('password')?.value);
  }

  public validPassword(): void {
    const password = this.formCreatePassword.get('password')?.value;

    this.isCapitalLetter = this.$forgotPassword.validPassword(password)['capitalLetter'];
    this.isSpecialCharacter = this.$forgotPassword.validPassword(password)['specialCharacter'];
    this.isLowerCase = this.$forgotPassword.validPassword(password)['lowerCase'];
    this.isLengthNumber = this.$forgotPassword.validPassword(password)['lengthNumber'];
    this.isNumber = this.$forgotPassword.validPassword(password)['number'];
  }

  private confirmedValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const password = this.formCreatePassword?.get('password')?.value;
      const confirmPassword = control.value;

      return password && confirmPassword && password !== confirmPassword ? { mismatch: true } : null;
    };
  }
}
