import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-quantity-button',
  templateUrl: './quantity-button.component.html',
  styleUrls: ['./quantity-button.component.scss'],
})
export class QuantityButtonComponent {
  @Input() public quantity: number = 1;
  @Input() public sellerId: string;

  @Output() public updateQuantity = new EventEmitter();

  public showButton: boolean = true;

  constructor() {}

  public addQuantity(): void {
    this.quantity++;
    this.updateQuantity.emit(this.quantity);
  }

  public removeQuantity(): void {
    this.quantity--;
    this.updateQuantity.emit(this.quantity);
  }
}
