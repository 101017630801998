import { combineLatest } from 'rxjs';
import { Component, OnInit } from '@angular/core';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzModalRef, NzModalService } from 'ng-zorro-antd/modal';

import { getSmallModalWidth } from '../../../utils/utils';
import { FidelityService } from '../../../services/fidelity/fidelity.service';
import { CouponTypeFidelityModel } from '../../../models/fidelity/coupon-type-fidelity.model';
import { CouponCategoryFidelityModel } from '../../../models/fidelity/coupon-category-fidelity.model';
import { ModalConfirmationDeleteComponent } from '../modal-confirmation-delete/modal-confirmation-delete.component';
import { ModalCreateOrUpdateCategoryOrTypeCouponComponent } from '../modal-create-or-update-category-or-type-coupon/modal-create-or-update-category-or-type-coupon.component';

@Component({
  selector: 'app-modal-view-category-and-type-coupon',
  templateUrl: './modal-view-category-and-type-coupon.component.html',
  styleUrls: ['./modal-view-category-and-type-coupon.component.scss'],
})
export class ModalViewCategoryAndTypeCouponComponent implements OnInit {
  public loading: boolean = true;
  public types: Array<CouponTypeFidelityModel> = [];
  public categories: Array<CouponCategoryFidelityModel> = [];
  public index: number = 0;

  constructor(
    private $fidelity: FidelityService,
    public readonly $modalRef: NzModalRef,
    private readonly $modal: NzModalService,
    private readonly $message: NzMessageService
  ) {}

  public ngOnInit(): void {
    this.getData();
  }

  private getData(): void {
    combineLatest({
      types: this.$fidelity.getCouponTypesList(),
      categories: this.$fidelity.getCategoriesList(),
    }).subscribe({
      next: (res) => {
        if (res) {
          if (res.types?.data?.couponsType) {
            this.types = res.types.data.couponsType;
          }

          if (res.categories?.data?.categories) {
            this.categories = res.categories.data.categories;
          }
        }

        this.loading = false;
      },
      error: (error) => {
        this.loading = false;
        throw new Error(error);
      },
    });
  }

  public showAction(event: any): void {
    this.$modalRef.close();

    if (event.action === 'CREATE') {
      this.createCategoryOrType();
    }

    if (event.action === 'EDIT') {
      this.updateCategoryOrType(event);
    }

    if (event.action === 'DELETE') {
      this.deleteCategoryOrType(event);
    }
  }

  public createCategoryOrType(): void {
    this.$modal
      .create({
        nzTitle: 'Criar categoria/tipo',
        nzWidth: getSmallModalWidth(),
        nzFooter: null,
        nzCentered: true,
        nzClosable: true,
        nzContent: ModalCreateOrUpdateCategoryOrTypeCouponComponent,
      })
      .afterClose.subscribe((res) => {
        if (res) {
          this.loading = true;

          if (res.type === 'CATEGORY') {
            this.$fidelity.createCategoryCoupon(res).subscribe({
              next: (res) => {
                if (res) {
                  this.$message.success('Categoria criada com sucesso!');
                }

                this.loading = false;
              },
              error: (error) => {
                this.loading = false;
                this.$message.error('Erro ao criar a categoria. Por favor, tente novamente mais tarde.');
                throw new Error(error);
              },
            });
          } else {
            this.$fidelity.createCouponType(res).subscribe({
              next: (res) => {
                if (res) {
                  this.$message.success('Tipo criado com sucesso!');
                }

                this.loading = false;
              },
              error: (error) => {
                this.loading = false;
                this.$message.error('Erro ao criar o tipo. Por favor, tente novamente mais tarde.');
                throw new Error(error);
              },
            });
          }
        }
      });
  }

  public updateCategoryOrType(event: any): void {
    this.$modal
      .create({
        nzTitle: 'Atualizar categoria/tipo',
        nzWidth: getSmallModalWidth(),
        nzFooter: null,
        nzCentered: true,
        nzClosable: true,
        nzContent: ModalCreateOrUpdateCategoryOrTypeCouponComponent,
        nzComponentParams: { data: { name: event.data.name, type: event.type } },
      })
      .afterClose.subscribe((res) => {
        if (res) {
          this.loading = true;

          if (event.type === 'CATEGORY') {
            this.$fidelity.updateCategoryCoupon({ ...res, id: event.data.id }).subscribe({
              next: (res) => {
                if (res) {
                  this.$message.success('Categoria atualizada com sucesso!');
                }

                this.loading = false;
              },
              error: (error) => {
                this.loading = false;
                this.$message.error('Erro ao atualizar a categoria. Por favor, tente novamente mais tarde.');
                throw new Error(error);
              },
            });
          } else {
            this.$fidelity.updateCouponType({ ...res, id: event.data.id }).subscribe({
              next: (res) => {
                if (res) {
                  this.$message.success('Tipo atualizado com sucesso!');
                }

                this.loading = false;
              },
              error: (error) => {
                this.loading = false;
                this.$message.error('Erro ao atualizar o tipo. Por favor, tente novamente mais tarde.');
                throw new Error(error);
              },
            });
          }
        }
      });
  }

  public deleteCategoryOrType(event: any): void {
    this.$modal
      .create({
        nzContent: ModalConfirmationDeleteComponent,
        nzFooter: null,
        nzClosable: true,
        nzTitle: `Deletar ${event.type === 'CATEGORY' ? 'categoria' : 'tipo'}?`,
        nzComponentParams: {
          message: `Você tem certeza que deseja deletar ${
            event.type === 'CATEGORY' ? 'a categoria' : 'o tipo'
          } <strong>${event.data.name}</strong>`,
          buttonMessage: 'Deletar',
        },
      })
      .afterClose.subscribe((res) => {
        if (res) {
          this.loading = true;

          if (event.type === 'CATEGORY') {
            this.$fidelity.deleteCategoryCoupon(event.data.id).subscribe({
              next: (res) => {
                if (res) {
                  this.$message.success('Categoria deletada com sucesso!');
                }

                this.loading = false;
              },
              error: (error) => {
                this.loading = false;
                this.$message.error('Erro ao deletar a categoria. Por favor, tente novamente mais tarde.');
                throw new Error(error);
              },
            });
          } else {
            this.$fidelity.deleteCouponType(event.data.id).subscribe({
              next: (res) => {
                if (res) {
                  this.$message.success('Tipo deletado com sucesso!');
                }

                this.loading = false;
              },
              error: (error) => {
                this.loading = false;
                this.$message.error('Erro ao deletar o tipo. Por favor, tente novamente mais tarde.');
                throw new Error(error);
              },
            });
          }
        }
      });
  }
}
