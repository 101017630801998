import { NzModalRef } from 'ng-zorro-antd/modal';
import { Subject, Subscription, takeUntil } from 'rxjs';
import { NzMessageService } from 'ng-zorro-antd/message';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { NzUploadChangeParam, NzUploadXHRArgs } from 'ng-zorro-antd/upload';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';

import { Message } from '../../../utils/message';
import { SellerModel } from '../../../models/sellers/sellers.model';
import { ErrorService } from '../../../services/error/error.service';
import { ItemsService } from '../../../services/items/items.service';
import { SellersService } from '../../../services/sellers/sellers.service';

@Component({
  selector: 'app-modal-import-items',
  templateUrl: './modal-import-items.component.html',
  styleUrls: ['./modal-import-items.component.scss'],
})
export class ModalImportItemsComponent implements OnInit, OnDestroy {
  public formGroup!: FormGroup;
  public loading: boolean = false;
  public sellers: Array<SellerModel> = [];

  private destroy$ = new Subject();

  constructor(
    private $error: ErrorService,
    private $items: ItemsService,
    private $seller: SellersService,
    private readonly fb: FormBuilder,
    private readonly $modalRef: NzModalRef,
    private readonly $message: NzMessageService
  ) {}

  public ngOnInit(): void {
    this.createForm();
    this.getSellers();
  }

  public ngOnDestroy(): void {
    this.destroy$.next(1);
    this.destroy$.complete();
  }

  private createForm(): void {
    this.formGroup = this.fb.group({
      sellerId: new FormControl<string>('', [Validators.required]),
      file: new FormControl<string>('', [Validators.required]),
    });
  }

  private getSellers(): void {
    this.$seller
      .listSellersToCoupon()
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (res) => {
          if (res?.data?.sellers?.results) {
            this.sellers = JSON.parse(JSON.stringify(res.data.sellers.results));
          }

          this.loading = false;
        },
        error: (error) => {
          this.loading = false;
          this.$error.errorHandling(error, Message.ERROR_CONECTION, 'ANT');
        },
      });
  }

  public createItems(): void {
    this.loading = true;

    const formData = this.formGroup.value;

    this.$items
      .batchCreateV2Items({
        sellerId: formData.sellerId,
        encodedFile: formData.file.split(',')[1],
      })
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (resp) => {
          if (resp?.data?.batchCreateV2Items) {
            this.$message.success('Produtos adicionados com sucesso!');
            this.closeModal(true);
          } else {
            this.loading = false;
          }
        },
        error: (error) => {
          this.loading = false;
          this.$error.errorHandling(error, 'Oa produtos não foram adicionados corretamente, tente novamente.', 'ANT');
        },
      });
  }

  public closeModal(response?: boolean): void {
    this.$modalRef.close(!!response);
  }

  private getBase64(file: File): Promise<string> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = (): void => {
        resolve(reader.result as string);
      };
      reader.onerror = (error): void => {
        reject(error);
      };
    });
  }

  public async handleChange(event: NzUploadChangeParam): Promise<void> {
    if (event.type === 'start') {
      if (event.type === 'start') {
        event.file.status = 'success';

        const content = await this.getBase64(event.file.originFileObj);

        this.formGroup.get('file').setValue(content);
      }
    }
  }

  public customRequest(file: NzUploadXHRArgs): Subscription {
    return new Subscription();
  }
}
