<section *ngIf="!loading">
  <header>
    <img
      class="coupon-image"
      [src]="seller?.image_url ? seller.image_url : '/assets/img/default-coupon.svg'"
      onerror="this.onerror=null; this.src='/assets/img/default-coupon.svg'"
      alt="Logo da loja"
    />

    <img
      class="xmark"
      src="/assets/img/xmark.svg"
      alt="Ícone ilustrando um x para fechar o modal"
      (click)="modalRef.close()"
    />
  </header>

  <main>
    <body>
      <div class="title">
        <h1>{{ seller?.business_name }}</h1>
      </div>

      <div class="active-coupons">
        <span>{{ seller?.coupons?.length }} {{ seller?.coupons?.length === 1 ? 'cupom ativo' : 'cupons ativos' }}</span>
      </div>

      <div class="description">
        <span>Loja virtual da Nike, comercializando os produtos e lançamentos da marca.</span>
      </div>

      <div class="address" *ngIf="seller?.address">
        <img src="/assets/images/location-pin.svg" alt="Ícone para ilustrar a localização da loja" />

        <p>
          {{
            seller?.address?.street +
              ', ' +
              seller?.address?.number +
              ' - ' +
              seller?.address?.neighborhood +
              ', ' +
              seller?.address?.city +
              ' - ' +
              seller?.address?.state +
              ', ' +
              seller?.address?.zipCode
          }}
        </p>
      </div>
    </body>

    <footer>
      <app-primary-button [block]="true" type="GREEN" (click)="goToStore()">Ver cupons</app-primary-button>
    </footer>
  </main>
</section>
