import { gql } from 'apollo-angular';

const GET_URL_SHORTENER_BY_HASH_QUERY = gql`
  query UrlShortenerByHash($hash: String!) {
    urlShortenerByHash(hash: $hash) {
      originalUrl
      hash
      sellerId
      id
      insertedAt
    }
  }
`;

export { GET_URL_SHORTENER_BY_HASH_QUERY };
