<div *ngIf="loading" class="h-100 m-t-2 center w-100">
  <nz-spin nzSize="large" nzSimple></nz-spin>
</div>

<header *nzModalTitle>
  <h3>{{ title }}</h3>
  <img src="/assets/images/close.svg" (click)="$modalRef.close()" class="pointer-cursor" />
</header>

<section *ngIf="!loading">
  <div class="m-b-2">
    <span class="description">Crie uma categoria ou um tipo para os cupons</span>
  </div>
  <form [formGroup]="formGroup">
    <div nz-row>
      <div nz-col nzSpan="24">
        <nz-form-item>
          <nz-form-label class="startBlock" [nzNoColon]="true" [nzSm]="24" nzFor="categoryId">
            Categoria
          </nz-form-label>
          <nz-form-control [nzSm]="24" [nzXs]="24" nzHasFeedback nzErrorTip="Campo obrigatório.">
            <nz-select id="categoryId" formControlName="categoryId" nzPlaceHolder="Escolha uma opção">
              <nz-option
                *ngFor="let category of categories"
                [nzLabel]="category.name"
                [nzValue]="category.id"
              ></nz-option>
            </nz-select>
          </nz-form-control>
        </nz-form-item>
      </div>
    </div>
    <div nz-row>
      <div nz-col nzSpan="24">
        <nz-form-item>
          <nz-form-label class="startBlock" [nzNoColon]="true" [nzSm]="24" nzFor="typeId"> Tipo </nz-form-label>
          <nz-form-control [nzSm]="24" [nzXs]="24" nzHasFeedback nzErrorTip="Campo obrigatório.">
            <nz-select id="typeId" formControlName="typeId" nzPlaceHolder="Escolha uma opção">
              <nz-option *ngFor="let type of types" [nzLabel]="type.name" [nzValue]="type.id"></nz-option>
            </nz-select>
          </nz-form-control>
        </nz-form-item>
      </div>
    </div>
    <div nz-row>
      <div nz-col nzSpan="24">
        <nz-form-item>
          <nz-form-label class="startBlock" [nzNoColon]="true" [nzSm]="24" nzFor="mallId"> Shopping </nz-form-label>
          <nz-form-control [nzSm]="24" [nzXs]="24" nzHasFeedback nzErrorTip="Campo obrigatório.">
            <nz-select id="mallId" formControlName="mallId" nzPlaceHolder="Escolha uma opção">
              <nz-option *ngFor="let mall of malls" [nzLabel]="mall.name" [nzValue]="mall.id"></nz-option>
            </nz-select>
          </nz-form-control>
        </nz-form-item>
      </div>
    </div>
    <div nz-row>
      <div nz-col nzSpan="24">
        <nz-form-item>
          <nz-form-label class="startBlock" [nzNoColon]="true" [nzSm]="24" nzFor="expirationDate">
            Exibir até
          </nz-form-label>
          <nz-form-control [nzSm]="24" [nzXs]="24" nzHasFeedback nzErrorTip="Campo obrigatório.">
            <nz-date-picker
              id="expirationDate"
              formControlName="expirationDate"
              [nzShowTime]="{ nzFormat: 'HH:mm' }"
              nzFormat="dd/MM/yyy HH:mm"
              class="w-100"
              nzShowToday="false"
              [nzDisabledDate]="disabledDate"
              nzPlaceHolder="Selecione uma data"
              (keydown)="onlyNumbers($event)"
            ></nz-date-picker>
          </nz-form-control>
        </nz-form-item>
      </div>
    </div>
    <div nz-row>
      <div nz-col nzSpan="24">
        <nz-switch
          id="viewApp"
          formControlName="viewApp"
          [nzCheckedChildren]="checkedTemplate"
          [nzUnCheckedChildren]="unCheckedTemplate"
        ></nz-switch>
        <ng-template #checkedTemplate><span class="switch-check" nz-icon nzType="check"></span></ng-template>
        <ng-template #unCheckedTemplate><span class="switch-close" nz-icon nzType="close"></span></ng-template>
        <span class="m-l-2 visible-text"><strong>Exibir cupom no app</strong></span>
      </div>
    </div>
  </form>
</section>

<footer *nzModalFooter>
  <div class="buttons">
    <app-secondary-button (click)="setHandleSubmit(false)">Cancelar</app-secondary-button>
    <app-primary-button (click)="setHandleSubmit(true)" [disabled]="formGroup.invalid">
      Salvar informações
    </app-primary-button>
  </div>
</footer>
