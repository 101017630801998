<section class="center">
  <div class="card">
    <div class="content">
      <div class="content-logo">
        <img
          [src]="
            data.creditCardName === 'Mastercard Crédito'
              ? '../../../../assets/svg/logo_mastercard.svg'
              : '' || data.creditCardName === 'Visa Crédito'
              ? '../../../../assets/svg/logo_visa_small.svg'
              : '' || '../../../../assets/svg/logo_american_express.svg'
          "
          [alt]="
            data.creditCardName === 'Mastercard Crédito'
              ? 'Logo mastercard'
              : '' || data.creditCardName === 'Visa Crédito'
              ? 'Logo visa'
              : '' || 'logo american express'
          "
        />
      </div>

      <div class="description">
        <p>{{ data.creditCardName }}</p>
        <span>{{ data.creditCardLastNumbers }}</span>
      </div>
    </div>
  </div>
  <h1>{{ data?.title || '' }}</h1>
  <p class="description">{{ data?.description || '' }}</p>
  <button nz-button [nzType]="data?.nzButtonType || 'primary'" (click)="data?.callback()">
    {{ data?.buttonText || 'Fechar' }}
  </button>
</section>
