<section class="input_open_select">
  <nz-collapse nzExpandIconPosition="right">
    <nz-collapse-panel
      [nzHeader]="placeholder"
      [nzActive]="true"
      [nzDisabled]="isDisabled || false"
      nzExpandedIcon="left"
    >
      <div *ngFor="let option of options" class="wrapper-option">
        <span
          class="d-block body-txt-light pointer-cursor"
          [style.color]="value === option ? '#6D54FC' : ''"
          (click)="selectOption(option)"
          (blur)="touched()"
          >{{ option }}</span
        >
      </div>
    </nz-collapse-panel>
  </nz-collapse>
</section>

<ng-template #placeholder>
  <span class="body-txt-light">{{ placeHolder }}</span>
</ng-template>
