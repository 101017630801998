import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-quantity-button-secondary',
  templateUrl: './quantity-button-secondary.component.html',
  styleUrls: ['./quantity-button-secondary.component.scss'],
})
export class QuantityButtonSecondaryComponent {
  @Input() public quantity: number = 1;
  @Input() public sellerId: string;

  @Output() public updateQuantity = new EventEmitter();

  constructor() {}

  public addQuantity(): void {
    this.quantity++;
    this.updateQuantity.emit(this.quantity);
  }

  public removeQuantity(): void {
    this.quantity--;
    this.updateQuantity.emit(this.quantity);
  }
}
