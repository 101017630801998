import { gql } from 'apollo-angular';

const WITHDRAWALS_FIELDS = `
  amountCents
  id
  insertedAt
  status
  updatedAt
  statusReason
  wallets {
    currentBalance
    blockedBalance
    sellers {
      id
      person {
        name
        cpf
      }
      company {
        businessName
        cnpj
      }
    }
  }
  walletTransaction {
    id
    status
  }
`;

const WITHDRAWALS_FIELDS_SELLER = `
  amountCents
  id
  insertedAt
  status
  updatedAt
`;

const LIST_WITHDRAWS_QUERY = gql`
  query Withdraws($filter: FilterWithdrawParamsInput, $pagination: PaginationParams) {
    withdraws(filters: $filter, pagination: $pagination) {
      pageSize
      page
      totalPages
      total
      results {
        amountCents
        id
        insertedAt
        status
        updatedAt
        statusReason
        wallets {
          currentBalance
          blockedBalance
          sellers {
            id
            person {
              name
              cpf
            }
            company {
              businessName
              cnpj
            }
          }
        }
        walletTransaction {
          id
          status
        }
      }
    }
  }
`;

const LIST_SELLER_WITHDRAWS_QUERY = gql`
  query sellerWithdraws($filter: FilterWithdrawParamsInput, $sellerId: String!) {
    sellerWithdraws(filters: $filter, pagination: { sortingOrder: DESC, orderBy: "inserted_at", perPage: 100 }, sellerId: $sellerId) {
      ${WITHDRAWALS_FIELDS_SELLER}
    }
  }
`;

const GET_WITHDRAW_QUERY = gql`
  query withdraw($id: String!) {
    withdraw(id: $id) {
      ${WITHDRAWALS_FIELDS}
    }
  }
`;

export { LIST_WITHDRAWS_QUERY, GET_WITHDRAW_QUERY, LIST_SELLER_WITHDRAWS_QUERY };
