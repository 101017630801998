export class TableFooterModel {
  public countQuantity?: number = 0;
  public totalPayments?: number = 0;
  public countPayments?: number = 0;
  public countRefunds?: number = 0;
  public totalRefunds?: number = 0;
  public totalLiquid?: number = 0;
  public totalAmount?: number = 0;
  public totalToReceive?: number = 0;
  public totalCredit?: number = 0;
  public totalDebit?: number = 0;
  public totalBalance?: number = 0;
}
