import { Subject, takeUntil } from 'rxjs';
import { NzCarouselComponent } from 'ng-zorro-antd/carousel';
import { Component, HostListener, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';

import { Message } from '../../utils/message';
import { ErrorService } from '../../services/error/error.service';
import { BannerService } from '../../services/events/banner.service';
import { BannerGroupingModel } from '../../models/event/banner-grouping.model';
import { BannerDisplayAtType } from '../../types/banner-display-at.type';
import { BannerModel } from '../../models/event/banner.model';

@Component({
  selector: 'app-banners',
  templateUrl: './banners.component.html',
  styleUrls: ['./banners.component.scss'],
})
export class BannersComponent implements OnInit, OnDestroy {
  @Input() public displayAt: BannerDisplayAtType;
  @ViewChild('carousel', { static: false }) public carousel: NzCarouselComponent;

  public banners: Array<BannerModel> = [];
  public isMobile: boolean = window.innerWidth < 768;

  private destroy$ = new Subject();

  constructor(private $error: ErrorService, private $banner: BannerService) {}

  public ngOnInit(): void {
    this.getBanners();
  }

  public ngOnDestroy(): void {
    this.destroy$.next(1);
    this.destroy$.complete();
  }

  private getBanners(): void {
    this.$banner
      .getBannersGrouping(['COUPON_PUBLIC'])
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (res) => {
          if (res?.data?.selectedBanners) {
            const data: Array<BannerGroupingModel> = JSON.parse(JSON.stringify(res.data.selectedBanners));

            this.banners = data[0].banners;
          }
        },
        error: (error) => {
          this.$error.errorHandling(error, Message.ERROR_CONECTION);
        },
      });
  }

  public trackByFn(index: number, banner: BannerModel): string {
    return banner.id;
  }

  public prevAndNext(event: any, type: string): void {
    event.stopPropagation();

    if (type === 'prev') {
      this.carousel.pre();
    } else {
      this.carousel.next();
    }
  }

  public goToOutside(banner: BannerModel): void {
    if (banner?.metadata?.link) {
      window.open(banner.metadata.link, '_blank');
    }
  }

  @HostListener('window:resize', ['$event'])
  public onResize(event: any): void {
    this.isMobile = event.target.innerWidth < 768;
  }
}
