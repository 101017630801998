<section class="whatsapp_validation_modal">
  <div class="header between">
    <h2 class="no-margin">Validação</h2>
    <span
      nz-icon
      nzType="close"
      nzTheme="outline"
      class="close-icon pointer-cursor"
      (click)="modalRef.close('closed')"
    ></span>
  </div>
  <nz-divider></nz-divider>
  <p>Digite os 6 números que enviamos para o seu whatsApp</p>
  <app-code-validation-input [size]="6" (onCodeFilled)="getFullCode($event)" class="center"></app-code-validation-input>
  <span class="error-color m-t-1 codeError" *ngIf="invalidCode">Código inválido, tente novamente.</span>
  <nz-divider></nz-divider>
  <div class="d-flex buttons">
    <app-primary-button [disabled]="!!countDown" (click)="updateWhatsappNumber()">
      Enviar novamente ({{ countDown }}s)
    </app-primary-button>
    <app-secondary-button [disabled]="!token" (click)="updateWhatsappNumberConfirmation()">
      Validar
    </app-secondary-button>
  </div>
</section>
