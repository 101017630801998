import { Component, EventEmitter, Input, Output } from '@angular/core';
import { StateManagementService } from '../../state-management/state-management.service';
import { CategoryModel } from '../../models/categories/categories.model';
import { StoreModel } from '../../models/store/store.model';
import { RouteParamsModel } from '../../models/route-params/route-params.model';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';

@Component({
  selector: 'app-navigate-and-filter',
  templateUrl: './navigate-and-filter.component.html',
  styleUrls: ['./navigate-and-filter.component.scss'],
})
export class NavigateAndFilterComponent {
  @Input() public noMargin: boolean = false;
  @Output() public onClick = new EventEmitter();
  public isShowCategory: boolean;

  public store: StoreModel;
  public category: CategoryModel;
  public ids: RouteParamsModel = new RouteParamsModel();

  public url: string[] = [];
  public actualRoute: string = '';

  constructor(private $notification: StateManagementService, private router: Router) {}

  public ngOnInit(): void {
    this.getNotifications();
    this.getUrl();
  }

  public getUrl(): void {
    this.url = this.router.url.split(`${this.ids.pointId}`);
    this.actualRoute = this.router.url;

    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.url = event.url.split(`${this.ids.pointId}`);
      }
    });
  }

  public getNotifications(): void {
    this.$notification.stores.subscribe((store) => {
      if (store) {
        this.store = store;
      }
    });

    this.$notification.changedCategorys.subscribe((category) => {
      if (category) {
        this.category = category;
      }
    });

    this.$notification.routeParams.subscribe((ids) => {
      if (ids) {
        this.ids = ids;
      }
    });

    this.$notification.isShowCategories.subscribe((res) => {
      this.isShowCategory = res;
    });
  }

  public getOrderByValue(orderBy): void {
    if (orderBy) {
      this.$notification.setOrderBy(orderBy);
      this.category = {};
    }
  }

  public callToAction(type?: string): void {
    if (type) {
      this.onClick.emit(type);
    }
    this.router.navigate[`/internal/${this.ids?.sellerId}/${this.ids.pointId}/products/list`];
  }

  public orderByProducts(event: any): void {}
}
