import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { NzMessageService } from 'ng-zorro-antd/message';

import { environment } from '@gen/environments';
import { MallModel } from '../../models/mall/mall.model';
import { TicketService } from '../ticket/ticket.service';
import { ParkingService } from '../parking/parking.service';
import { TicketModel } from '../../models/ticket/ticket.model';
import { ParkingUserModel } from '../../models/parking-user/parking-user';
import { SaveReceiptParamsModel } from '../../models/receipt/save-receipt-params.model';
import { StateManagementService } from '../../state-management/state-management.service';
import { PaymentTicketResponseModel } from '../../models/ticket/paymentTicketResponse.model';

declare const Stripe: any;

@Injectable()
export class StripeService {
  public paymentRequest!: any;
  private stripe!: any;

  constructor(
    private $ticket: TicketService,
    private readonly router: Router,
    private $parking: ParkingService,
    private readonly $message: NzMessageService,
    private $notification: StateManagementService
  ) {}

  public setStripeConfig(ticket: TicketModel, mall: MallModel): void {
    this.stripe = Stripe(environment.stripePublicableToken, {
      apiVersion: '2022-11-15',
      stripeAccount: mall.stripeConnectId,
    });

    this.paymentRequest = this.stripe.paymentRequest({
      country: 'BR',
      currency: 'brl',
      total: {
        label: `Pagamento do tíquete ${ticket.ticket} no shopping ${mall.name}`,
        amount: Number(ticket.analise?.valorDevido) * 100,
      },
      requestPayerName: true,
      disableWallets: ['link'],
    });
  }

  public listenPaymentRequest(user: ParkingUserModel, ticket: TicketModel, mall: MallModel, platform: string): void {
    this.$notification.setLoading(true);

    this.paymentRequest.on('paymentmethod', (ev: any) => {
      this.$ticket
        .payTicket({
          transaction: ticket.transacao,
          paymentMethod: ev.paymentMethod.id,
          idMall: parseInt(mall.id),
          customer: {
            customerId: user.customerId,
            name: user.name,
            email: user.email,
            phone: user.phoneNumber,
          },
          metadata: { seller_id: mall.externalId, cpf: user.cpf },
          platform,
        })
        .subscribe({
          next: (res) => {
            if (res.status === 200) {
              ev.complete('success');
              this.successPaymentFlow(res.body, user, ticket, mall);
              this.$notification.setLoading(false);
            } else {
              ev.complete('fail');
              this.$message.error('Erro ao efetuar o pagamento.');
              this.$notification.setLoading(false);

              if (res.status === 500 || res.status === 502) {
                this.router.navigate([`external/${mall.id}/serverError/${ticket.ticket}`]);
                throw new Error(JSON.stringify(res.body));
              } else {
                this.router.navigate([`external/${mall.id}/paymentError/${ticket.ticket}`]);
                throw new Error(JSON.stringify(res.body));
              }
            }
          },
          error: (error) => {
            ev.complete('fail');
            this.router.navigate([`external/${mall.id}/serverError/${ticket.ticket}`]);
            this.$message.error('Erro ao criar um pagamento. Por favor, tente novamente mais tarde.');
            throw new Error(error);
          },
        });
    });
  }

  private successPaymentFlow(
    body: PaymentTicketResponseModel,
    user: ParkingUserModel,
    ticket: TicketModel,
    mall: MallModel
  ): void {
    this.$message.success('Sucesso ao realizar o pagamento.');

    const params: SaveReceiptParamsModel = {
      user,
      res: body,
      ticket,
      mall,
    };
    user.customerId = body.tag;
    this.$parking.setUser(user);
    this.$ticket.saveReceipt(params);
    this.$ticket.getAndSetTicket({ mallId: mall.id, ticketId: ticket.ticket });

    setTimeout(() => {
      this.router.navigate([`external/${mall.id}/approved/${ticket.ticket}`]);
    }, 500);
  }
}
