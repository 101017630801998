import { NzModalRef } from 'ng-zorro-antd/modal';
import { Component, OnInit, Input } from '@angular/core';

import { ModalModel } from './../../models/modal/modal.model';

@Component({
  selector: 'app-modal-generic',
  templateUrl: './modal-generic.component.html',
  styleUrls: ['./modal-generic.component.scss'],
})
export class ModalGenericComponent implements OnInit {
  @Input() data?: ModalModel;

  constructor(public readonly modalRef: NzModalRef) {}

  public ngOnInit(): void {
    this.getInitialData();
  }

  private getInitialData(): void {
    if (!this.data?.callback) {
      this.data.callback = () => {
        this.modalRef.close();
      };
    }
  }
}
