<header *nzModalTitle>
  <h3>{{ title }}</h3>
  <img src="/assets/images/close.svg" (click)="$modalRef.close()" class="pointer-cursor" />
</header>

<section id="modal-withdraw">
  <nz-row class="m-b-1">
    <nz-col nzSpan="12">
      <span class="data-text">{{
        withdrawal.wallets?.sellers?.company?.businessName || withdrawal.wallets?.sellers?.person?.name
      }}</span>
    </nz-col>
    <nz-col nzSpan="12" class="endBlock">
      <span class="data-text">{{ withdrawal.amountCents / 100 | currency : 'R$' }}</span>
    </nz-col>
  </nz-row>
  <nz-row>
    <nz-col nzSpan="24">
      <form [formGroup]="formGroup">
        <nz-form-item>
          <nz-form-control [nzSm]="24" [nzXs]="24" nzHasFeedback nzErrorTip="Máximo de 250 caracteres.">
            <nz-textarea-count [nzMaxCharacterCount]="250">
              <textarea
                rows="4"
                formControlName="statusReason"
                id="statusReason"
                placeholder="Insira uma observação..."
                nz-input
              ></textarea>
            </nz-textarea-count>
          </nz-form-control>
        </nz-form-item>
      </form>
    </nz-col>
  </nz-row>
</section>

<footer *nzModalFooter>
  <div class="buttons">
    <app-secondary-button (click)="close(false)" type="RED">Cancelar</app-secondary-button>
    <app-primary-button (click)="close(true)" type="DARK-PURPLE">{{ textBtn }}</app-primary-button>
  </div>
</footer>
