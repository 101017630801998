import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { Apollo, Query } from 'apollo-angular';

import {
  CREATE_SEGMENTATION_MUTATION,
  DELETE_SEGMENTATION_MUTATION,
  UPDATE_SEGMENTATION_MUTATION,
} from '../../graphql/mutations/segmentations.mutation';
import { SegmentationModel } from 'libs/shared/src/lib/models/segmentation/segmentation.model';
import { LIST_SEGMENTATIONS_QUERY, SEGMENTATION_QUERY } from '../../graphql/queries/segmentations.queries';
import { FilterSegmentationModel } from '../../models/segmentation/filter-segmentation.model';
import { PaginationModel } from '../../models/pagination/pagination.model';

@Injectable()
export class SegmentationService {
  constructor(private readonly $apollo: Apollo) {}

  public segmentationList(filters: FilterSegmentationModel, pagination: PaginationModel): Observable<any> {
    return this.$apollo.watchQuery<Query>({
      query: LIST_SEGMENTATIONS_QUERY,
      variables: { filters, pagination },
      fetchPolicy: 'network-only',
    }).valueChanges;
  }

  public segmentationOne(id: string): Observable<any> {
    return this.$apollo.watchQuery<Query>({
      query: SEGMENTATION_QUERY,
      variables: { id },
      fetchPolicy: 'network-only',
    }).valueChanges;
  }

  public createSegmentation(variables: SegmentationModel): Observable<any> {
    return this.$apollo.mutate({
      mutation: CREATE_SEGMENTATION_MUTATION,
      variables,
    });
  }

  public updateSegmentation(variables: SegmentationModel): Observable<any> {
    return this.$apollo.mutate({
      mutation: UPDATE_SEGMENTATION_MUTATION,
      variables,
    });
  }

  public deleteSegmentation(id: string): Observable<any> {
    return this.$apollo.mutate({
      mutation: DELETE_SEGMENTATION_MUTATION,
      variables: { id },
    });
  }
}
