import { MallModel } from '../mall/mall.model';
import { ParkingPriceModel } from './parking-price.model';

export class PriceTableModel {
  public mallId?: number = null;
  public id_mall?: number = null;
  public mall?: MallModel;
  public mallName?: string = '';
  public type?: string = '';
  public acronym?: string = '';
  public updated_at?: string = '';
  public prices: Array<ParkingPriceModel> = [];
  public parkingPriceTable?: Array<ParkingPriceModel> = [];
}
