import moment from 'moment-timezone';
import { AbstractControl, ValidatorFn, Validators } from '@angular/forms';

export class DateValidator {
  static less18(control: AbstractControl): { [key: string]: any } {
    const date = moment(control.value, 'DD/MM/YYYY');
    if (control.value.length < 10) {
      return null;
    }
    if (!date.isValid()) {
      return { invalidDate: true };
    }
    const now = moment();
    const diff = now.diff(date, 'years');

    if (diff < 18) {
      return { less18: true };
    }
    return null;
  }

  static isValid(): ValidatorFn {
    return (control: AbstractControl): Validators | null => {
      let date = control.value;

      if (date && date.length >= 8) {
        if (date.length !== 10) {
          date = moment(date, 'DDMMYYYY').format('DD/MM/YYYY');
        }

        const re = /^(0[1-9]|[12][0-9]|3[01])[- /.](0[1-9]|1[012])[- /.](19|20)\d\d$/;

        if (!re.test(date)) {
          return { dateNotValid: true };
        }

        const [day, month, year] = control.value.split('/').map(Number);
        const isLeapYear = year % 4 === 0 && (year % 100 !== 0 || year % 400 === 0);

        if (month === 2 && isLeapYear && day > 29) {
          return { invalidDate: true };
        }

        if (month === 2 && !isLeapYear && day > 28) {
          return { invalidDate: true };
        }
      }

      return null;
    };
  }
}
