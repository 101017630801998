import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { LoginModel } from 'libs/shared/src/lib/models/login/login.model';
import { StateManagementService } from 'libs/shared/src/lib/state-management/state-management.service';
import { AuthService } from 'libs/shared/src/lib/services/auth/auth-business/auth.service';
import { ErrorService } from '@gen/shared';
import { Message } from 'libs/shared/src/lib/utils/message';

@Component({
  selector: 'app-customer-validation-select',
  templateUrl: './signin-validation-select.component.html',
  styleUrls: ['./signin-validation-select.component.scss'],
})
export class SigninValidationSelectComponent implements OnInit {
  public formSignIn: FormGroup;
  public login: LoginModel;
  public validationTypes: Array<{ value: string; name: string; signin: string; mask: string }> = [];
  public loadingSignIn: boolean = false;

  constructor(
    private fb: FormBuilder,
    private $auth: AuthService,
    private $error: ErrorService,
    private $notification: StateManagementService
  ) {}

  ngOnInit(): void {
    this.createForm();
    this.getNotification();
  }

  public getNotification(): void {
    this.$notification.users.subscribe((user) => {
      if (user?.id) {
        this.validationTypes = [
          {
            value: 'SMS',
            signin: user.phone,
            name: 'SMS',
            mask: '+00 (00) 0 0000-0000',
          },
          {
            value: 'WHATSAPP',
            signin: user.phone,
            name: 'WhatsApp',
            mask: '+00 (00) 0 0000-0000',
          },
          {
            value: 'EMAIL',
            signin: user.email,
            name: 'E-mail',
            mask: null,
          },
        ];
      } else {
        this.goToSign();
      }
    });
  }
  public createForm(): void {
    this.formSignIn = this.fb.group({
      validationType: new FormControl(null, [Validators.required]),
    });
  }

  public sendVerificationCode(): void {
    const validationType = this.formSignIn.get('validationType').value;
    const method = validationType.value;

    this.loadingSignIn = true;

    this.login = new LoginModel();

    this.login.signin = validationType.signin;
    this.login.validationMethod = method;
    this.login.email = method === 'EMAIL' ? validationType.signin : null;
    this.login.phoneNumber = method !== 'EMAIL' ? validationType.signin : null;

    this.$auth
      .sendVerificationCode(
        { phoneNumber: this.login.phoneNumber, email: this.login.email },
        this.login.validationMethod,
        'B2B'
      )
      .subscribe({
        next: (res) => {
          if (res?.data?.sendVerificationCode) {
            this.$notification.setLogin(this.login);
            this.$notification.setLoginActualPage('signin-code-verify');
          }

          this.loadingSignIn = false;
        },
        error: (error) => {
          this.$error.errorHandling(error, Message.LOGIN_ERROR);
          this.loadingSignIn = false;
          throw new Error(error);
        },
      });
  }

  public goToSign(): void {
    this.$notification.setLoginActualPage('signin-password');
  }
}
