import { NZ_MODAL_DATA, NzModalRef } from 'ng-zorro-antd/modal';
import { Component, Inject, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';

import { FilterFieldType } from '../../types/filter/filter-field.type';
import { FilterDataInputType } from '../../types/filter/filter-data-input.type';

@Component({
  selector: 'app-modal-filters',
  templateUrl: './modal-filters.component.html',
  styleUrls: ['./modal-filters.component.scss'],
})
export class ModalFiltersComponent implements OnInit {
  @Input() public title: string = '';

  public fields: any;
  public allFields: FilterFieldType[] = [];
  public form!: FormGroup;
  public dateFormat: string = 'dd/MM/yyyy';

  constructor(
    public readonly $modalRef: NzModalRef,
    private readonly fb: FormBuilder,
    @Inject(NZ_MODAL_DATA) private data: Partial<FilterDataInputType>
  ) {}

  public ngOnInit(): void {
    this.getData();
    this.getAllFields();
    this.createForm();
  }

  private getData(): void {
    this.fields = this.data.fields ? [[...this.data.fields]] : [...this.data.columns];
  }

  private getAllFields(): void {
    if (this.data.columns) {
      this.fields.map((column) => {
        this.allFields.push(...column);
      });
    }

    if (this.data.fields) {
      this.allFields = this.fields as FilterFieldType[];
    }
  }

  private createForm(): void {
    this.form = this.fb.group({});
    this.allFields.map((field) => {
      if (this.form.get(field.fieldType)) {
        const nextNumber = this.nextNumberToUse(field.fieldType);
        this.form.addControl(field.fieldType + nextNumber, new FormControl(null));
        field.formControlName = field.fieldType + nextNumber;
      } else {
        this.form.addControl(field.fieldType, new FormControl(null));
        field.formControlName = field.fieldType;
      }
    });
  }

  private nextNumberToUse(name: string): number {
    return Object.keys(this.form.controls).filter((control) => control.startsWith(name)).length;
  }
}
