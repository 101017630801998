import { take } from 'rxjs/operators';
import { Apollo, Query } from 'apollo-angular';
import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject, EMPTY } from 'rxjs';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFireFunctions } from '@angular/fire/compat/functions';

import { Message } from '../../../utils/message';
import { USER_ENTITY_QUERY, VERIFY_USER_LOGIN_QUERY } from '../../../graphql/queries/verify-user-login.queries';
import {
  CHANGE_PASSWORD_MUTATION,
  CHANGE_PASSWORD_SYNAPSES_MUTATION,
  CREATE_PASSWORD_MUTATION,
  LOGIN_BY_PASSWORD_MUTATION,
  REFRESH_TOKEN_NEW_SELLER_MUTATION,
  RENEW_ACCESS_TOKEN_MUTATION,
  SEND_AUTH_CODE_MUTATION,
  SEND_VERIFICATION_CODE_MUTATION,
  VALIDATE_VERIFICATION_CODE_MUTATION,
} from '../../../graphql/mutations/auth.mutations';
import { shortenName } from '../../../utils/utils';
import { AlertService } from '../../alert/alert.service';
import { EmailModel } from '../../../models/email/email.model';
import { ResponseModel } from '../../../models/response/response.model';
import { CreateAccountModel } from '../../../models/auth/create-account.model';
import { StateManagementService } from '../../../state-management/state-management.service';
import { UsersService } from '../../users/users.service';
import { ErrorService } from '../../error/error.service';
import { LeadService } from '../../lead/lead.service';
import { SellerModel } from '../../../models/sellers/sellers.model';

@Injectable()
export class AuthService {
  private auth: BehaviorSubject<string> = new BehaviorSubject<string>('');
  public auths: Observable<string> = this.auth.asObservable();

  constructor(
    private readonly router: Router,
    private readonly apollo: Apollo,
    private $error: ErrorService,
    private $user: UsersService,
    private $lead: LeadService,
    private readonly $alert: AlertService,
    private $notification: StateManagementService,
    private readonly firebaseAuth: AngularFireAuth,
    private readonly $functions: AngularFireFunctions
  ) {}

  public setAuth(value: string): void {
    this.auth.next(value);
  }

  public renewToken(): Observable<any> {
    const refreshToken = sessionStorage.getItem('refreshToken');

    if (refreshToken) {
      return this.apollo.mutate({
        mutation: RENEW_ACCESS_TOKEN_MUTATION,
        variables: {
          refreshToken,
        },
      });
    } else {
      this.logout();

      return EMPTY;
    }
  }

  public validateEmailCode(payload: EmailModel): Observable<ResponseModel<any>> {
    return this.$functions.httpsCallable('validateEmailCode')(payload);
  }

  public changeUserPassword(
    userId: string,
    oldPassword: string,
    newPassword: string,
    newPasswordConfirmation: string
  ): Observable<any> {
    return this.apollo.mutate({
      mutation: CHANGE_PASSWORD_MUTATION,
      variables: {
        userId,
        oldPassword,
        newPassword,
        newPasswordConfirmation,
      },
    });
  }

  public changeUserPasswordSynapses(
    internalId: string,
    oldPassword: string,
    newPassword: string,
    newPasswordConfirmation: string
  ): Observable<any> {
    return this.apollo.mutate({
      mutation: CHANGE_PASSWORD_SYNAPSES_MUTATION,
      variables: {
        internalId,
        oldPassword,
        newPassword,
        newPasswordConfirmation,
      },
    });
  }

  public sendVerificationCode(data: CreateAccountModel, method: string, platform: string): Observable<any> {
    return this.apollo.mutate({
      mutation: SEND_VERIFICATION_CODE_MUTATION,
      variables: {
        signIn: data.phoneNumber ? data?.phoneNumber : data.email,
        method,
        platform,
      },
    });
  }

  public sendAuthCode(data: CreateAccountModel): Observable<any> {
    return this.apollo.mutate({
      mutation: SEND_AUTH_CODE_MUTATION,
      variables: {
        signIn: data.phoneNumber ? data?.phoneNumber : data.email,
      },
    });
  }

  public verifyUserLogin(signIn: string, platform: string): Observable<any> {
    return this.apollo.watchQuery<Query>({
      query: VERIFY_USER_LOGIN_QUERY,
      variables: {
        signIn,
        platform,
      },
      fetchPolicy: 'network-only',
    }).valueChanges;
  }

  public UserEntity(id: string): Observable<any> {
    return this.apollo.watchQuery<Query>({
      query: USER_ENTITY_QUERY,
      variables: { id },
      fetchPolicy: 'network-only',
    }).valueChanges;
  }

  public createPassword(
    userId: string,
    newPassword: string,
    newPasswordConfirmation: string,
    internalId?: string
  ): Observable<any> {
    return this.apollo.mutate({
      mutation: CREATE_PASSWORD_MUTATION,
      variables: {
        userId,
        internalId,
        newPassword,
        newPasswordConfirmation,
      },
    });
  }

  public createMarketplacePassword(data: any): Observable<any> {
    return this.apollo.mutate({
      mutation: CREATE_PASSWORD_MUTATION,
      variables: {
        leadId: data.leadId,
        newPassword: data.password,
        newPasswordConfirmation: data.confirmPassword,
      },
    });
  }

  public userWithPhoneNumberExists(phoneNumber: string): Observable<ResponseModel<boolean>> {
    return this.$functions.httpsCallable('userWithPhoneNumberExists')(`+55${phoneNumber}`);
  }

  public validateVerificationCode(data: any, platform: string): Observable<any> {
    return this.apollo.mutate({
      mutation: VALIDATE_VERIFICATION_CODE_MUTATION,
      variables: {
        signIn: data.type === 'SIGN_IN_PHONE_NUMBER' ? data?.phoneNumber : data?.email,
        code: data.code,
        platform,
      },
    });
  }

  public sendVerifyEmailCode(email: string, name: string): Observable<any> {
    return this.$functions.httpsCallable('sendVerifyEmailCode')({
      email,
      username: shortenName(name),
    });
  }

  public async sendChangeSellerEmail(payload: EmailModel): Promise<any> {
    try {
      const result = await this.$functions.httpsCallable('changeSellerEmail')(payload).pipe(take(1)).toPromise();

      if (result.status === 200) {
        return true;
      } else {
        this.$alert.setAlertInfo('ERROR', result.error);

        return result.error;
      }
    } catch (error) {
      this.$alert.setAlertInfo('ERROR', Message.ERROR_SEND_EMAIL);
      return error;
    }
  }

  public customLogin(token: string, platform?: string): void {
    sessionStorage.setItem('accessToken', token);
    this.setAuth(token);

    this.$user.getCurrentUser(platform || 'B2B').subscribe(
      (res) => {
        if (res?.data?.currentUser) {
          sessionStorage.setItem('activeSellerId', res.data.currentUser?.sellers?.[0]?.id);
          if (res.data.currentUser?.sellers?.[0]?.status === 'DECLINED') {
            this.$alert.setAlertInfo('ERROR', 'Infelizmente a conta não atingiu os critérios de compliance.');
          } else {
            this.$notification.setUser(res.data?.currentUser);
            this.$alert.setAlertInfo('SUCCESS', Message.LOGIN_SUCCESS);
            this.router.navigate(['/internal']);
          }
        }
      },
      (error) => {
        this.$error.errorHandling(error, Message.LOGIN_ERROR);
        throw new Error(error);
      }
    );
  }

  public customLoginMultiSeller(token: string, seller?: SellerModel): void {
    sessionStorage.setItem('accessToken', token);
    this.setAuth(token);

    this.$user.getCurrentUser('B2B').subscribe({
      next: (res) => {
        if (res?.data?.currentUser) {
          if (seller.status === 'DECLINED') {
            this.$alert.setAlertInfo('ERROR', 'Infelizmente a conta não atingiu os critérios de compliance.');
          } else {
            this.$notification.setUser(res.data?.currentUser);
            this.$alert.setAlertInfo('SUCCESS', Message.LOGIN_SUCCESS);
            this.router.navigate(['/internal']);
          }
        }
      },
      error: (error) => {
        this.$error.errorHandling(error, Message.LOGIN_ERROR);
        throw new Error(error);
      },
    });
  }

  public customLoginMarketplace(token: string): void {
    sessionStorage.setItem('accessToken', token);
    this.setAuth(token);

    this.$lead.currentLead().subscribe(
      (res) => {
        if (res?.data?.currentLead) {
          this.$notification.setUser(res.data?.currentLead);
          this.$alert.setAlertInfo('SUCCESS', Message.LOGIN_SUCCESS);
          this.router.navigate(['/internal']);
        }
      },
      (error) => {
        this.$error.errorHandling(error, Message.LOGIN_ERROR);
        throw new Error(error);
      }
    );
  }

  public logout(): void {
    this.$notification.setUser(null);
    sessionStorage.clear();

    this.router.navigate(['/external']).then(() => {
      window.location.reload();
    });
  }

  public standardEncoding(v: string): string {
    return encodeURIComponent(v);
  }

  public async refreshUser(): Promise<void> {
    await this.firebaseAuth.currentUser.then((u) => {
      if (u) {
        u.getIdTokenResult(true);
      }
    });
  }

  public loginByPassword(signIn: string, password: string, platform?: string): Observable<any> {
    return this.apollo.mutate({
      mutation: LOGIN_BY_PASSWORD_MUTATION,
      variables: {
        signIn,
        password,
        platform,
      },
    });
  }

  public refreshTokenNewSeller(sellerId: string): Observable<any> {
    return this.apollo.mutate({
      mutation: REFRESH_TOKEN_NEW_SELLER_MUTATION,
      variables: {
        sellerId,
      },
    });
  }
}
