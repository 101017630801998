<section class="signInValidationSelect">
  <p class="subtitle">Selecione uma forma para receber o código de verificação e fazer login</p>

  <form [formGroup]="formSignIn">
    <nz-radio-group class="w-100" formControlName="validationType">
      <ng-container *ngFor="let validationType of validationTypes">
        <div
          class="validation-card {{
            formSignIn.get('validationType')?.value === validationType ? 'card-selected' : ''
          }}"
          *ngIf="validationType.signin"
        >
          <div nz-row>
            <div nz-col nzSpan="22">
              <div class="inline w-100 h-100 m-l-1">
                <div class="w-100">
                  <span class="validate-name">{{ validationType.name }}</span>
                </div>
                <div class="w-100">
                  <span class="validate-signin" *ngIf="validationType.mask"
                    >{{ validationType.signin | hidePartialPhone }}
                  </span>
                  <span class="validate-signin" *ngIf="!validationType.mask"
                    >{{ validationType.signin | hidePartialEmail }}
                  </span>
                </div>
              </div>
            </div>

            <div nz-col nzSpan="2">
              <label nz-radio [nzValue]="validationType" class="mt"></label>
            </div>
          </div>
        </div>
      </ng-container>
    </nz-radio-group>

    <div class="m-t-1 center">
      <app-primary-button
        [loading]="loadingSignIn"
        [block]="true"
        (click)="sendVerificationCode()"
        class="center w-100"
        [disabled]="formSignIn?.invalid || loadingSignIn"
        >Continuar
      </app-primary-button>
    </div>
  </form>
</section>
