import { Location } from '@angular/common';
import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-simple-header',
  templateUrl: './simple-header.component.html',
  styleUrls: ['./simple-header.component.scss'],
})
export class SimpleHeaderComponent {
  @Input() title: string = '';
  @Input() mallId: string = '';

  constructor(private location: Location, private router: Router) {}

  public goBack(): void {
    const locationState: any = this.location.getState();

    if (locationState.navigationId > 1) {
      this.location.back();
    } else {
      this.router.navigate([`external/${this.mallId}/home`]);
    }
  }
}
