/* eslint-disable no-async-promise-executor */
import * as moment from 'moment';
import { v4 as uuidv4 } from 'uuid';
import {
  AngularFirestore,
  AngularFirestoreCollection,
  CollectionReference,
  Query,
} from '@angular/fire/compat/firestore';
import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { environment } from '@gen/environments';
import { NzDrawerService } from 'ng-zorro-antd/drawer';
import { NzMessageService } from 'ng-zorro-antd/message';
import { Observable, combineLatest, mergeMap, of, take } from 'rxjs';

import { CartModel } from '../../models/cart/cart.model';
import { StoreModel } from '../../models/store/store.model';
import { BasketModel } from '../../models/cart/basket.model';
import { CustomerService } from '../customer/customer.service';
import { PointModel } from '../../models/points/point-of-sales.model';
import { StateManagementService } from './../../state-management/state-management.service';
import { ConfirmCleanBottomSheetComponent } from '../../components/confirm-clean-bottom-sheet/confirm-clean-bottom-sheet.component';
import { SellerModel } from '../../models/sellers/sellers.model';
import { ItemsService } from '../items/items.service';
import { ItemsV2Model } from '../../models/items/V2/ItemV2.model';
import { AlertService } from '../alert/alert.service';

@Injectable()
export class PointOfSalesService {
  public marketplaceId: string = environment.marketplaceId;
  public pointId: string;

  constructor(
    private $item: ItemsService,
    private readonly router: Router,
    private $customers: CustomerService,
    private readonly $drawer: NzDrawerService,
    private readonly afStore: AngularFirestore,
    private $notification: StateManagementService,
    private $alert: AlertService
  ) {}

  public addNewPoint(customerId: string): string {
    const pointId = uuidv4();
    const pointName = `Catálogo ${Date.now()}`;

    let body = {
      id: pointId,
      description: pointName,
      insertedAt: moment.utc().format('YYYY-MM-DDTHH:mm:ss'),
    };

    if (customerId) {
      body = Object.assign(body, {
        customerId,
      });
    }

    this.getPointCollection()?.doc(pointId).set(body);

    this.pointId = pointId;

    return this.pointId;
  }

  public async addProduct(
    seller: SellerModel,
    pointId: string,
    product: ItemsV2Model,
    cartId: string,
    cart: CartModel,
    store: StoreModel
  ): Promise<void> {
    try {
      product.originalId = product.id;
      product.price = product.price || product.itemUnits[0].price;

      const productId = uuidv4();
      const basketCollection = this.getBasketCollection(pointId, cartId);
      const basketId = seller.id;

      let basket: BasketModel | null = await basketCollection.doc(basketId).valueChanges().pipe(take(1)).toPromise();

      if (!basket) {
        basket = {
          id: basketId,
          seller,
          store: store || null,
          cartId,
          pointId,
          sellerId: basketId,
          items: [product],
          totalValue: product.price,
        };
      } else {
        basket.items.push(product);
        basket.totalValue += product.price;
      }

      await basketCollection.doc(basketId).set(basket);
      await this.getBasketProductsCollection(pointId, cartId, basketId).doc(productId).set(product);

      this.updateCart(pointId, cartId, cart);
    } catch (error) {
      console.error('Ocorreu um erro ao adicionar o produto:', error);
    }
  }

  public updateProduct(pointId: string, product: ItemsV2Model, cartId: string, sellerId: string): void {
    if (pointId && product && cartId && sellerId) {
      this.getBasketProductsCollection(pointId, cartId, sellerId)
        .doc(product.id)
        .set(product, { merge: true })
        .then(() => {
          this.$alert.setAlertInfo('SUCCESS', 'Produto adicionado com sucesso!');
          this.router.navigate(['/loggedCart/' + pointId + '/cart']);
        });
    }
  }

  public updateProducts(pointId: string, products: Array<ItemsV2Model>, cartId: string, sellerId: string) {
    if (products) {
      products.map((product) => this.updateProduct(pointId, product, cartId, sellerId));
    }
  }

  public async clearBaskets(pointId: string, cartId: string): Promise<void> {
    const baskets = await this.getBasketCollection(pointId, cartId)
      .valueChanges({ idField: 'id' })
      .pipe(take(1))
      .toPromise();

    if (baskets) {
      baskets.map((basket) => this.getBasketCollection(pointId, cartId).doc(basket.id).delete());
    }
  }

  public async clearBasket(pointId: string, cartId: string, basketId: string): Promise<void> {
    this.getBasketCollection(pointId, cartId).doc(basketId).delete();
  }

  public removeProductFromBasket(pointId: string, productId: string, cartId: string, sellerId: string): void {
    this.getBasketProductsCollection(pointId, cartId, sellerId).doc(productId).delete();
  }

  public getBasketProducts(pointId: string, cartId: string, sellerId: string): Observable<ItemsV2Model[]> | undefined {
    return this.getBasketProductsCollection(pointId, cartId, sellerId).valueChanges({ idField: 'id' });
  }

  public getBasketProduct(
    pointId: string,
    productId: string,
    cartId: string,
    sellerId: string
  ): Observable<ItemsV2Model> | undefined {
    if (pointId && cartId && sellerId) {
      return this.getBasketProductsCollection(pointId, cartId, sellerId).doc(productId).valueChanges({ idField: 'id' });
    }

    return of({} as ItemsV2Model);
  }

  private getPointCollection(): AngularFirestoreCollection<PointModel> {
    return this.afStore.collection<PointModel>(`marketplaces/${this.marketplaceId}/pointOfSales`);
  }

  private getCartCollection(pointId: string): AngularFirestoreCollection<CartModel> {
    return this.afStore.collection<CartModel>(`marketplaces/${this.marketplaceId}/pointOfSales/${pointId}/carts`);
  }

  private getBasketCollection(pointId: string, cartId: string): AngularFirestoreCollection<BasketModel> {
    return this.afStore.collection<BasketModel>(
      `marketplaces/${this.marketplaceId}/pointOfSales/${pointId}/carts/${cartId}/basket`
    );
  }

  private getBasketProductsCollection(
    pointId: string,
    cartId: string,
    sellerId: string
  ): AngularFirestoreCollection<ItemsV2Model> {
    return this.afStore.collection<ItemsV2Model>(
      `marketplaces/${this.marketplaceId}/pointOfSales/${pointId}/carts/${cartId}/basket/${sellerId}/items`
    );
  }

  public openConfirmClean(sellerId: string, pointId: string, cartId: string): void {
    const drawer = this.$drawer.create({
      nzHeight: '300px',
      nzClosable: false,
      nzPlacement: 'bottom',
      nzContent: ConfirmCleanBottomSheetComponent,
    });
    drawer.afterClose.subscribe((res) => {
      if (res) {
        this.setClearBasket(sellerId, pointId, cartId);
      }
    });
  }

  public setClearBasket(sellerId: string, pointId: string, cartId: string): void {
    this.clearBaskets(pointId, cartId);
    this.$notification.setCleanBasket(true);

    setTimeout(() => {
      this.$notification.setCleanBasket(false);
    }, 3000);
  }

  public updatePoint(pointId: string, data: any): void {
    if (pointId) {
      this.getPointCollection()?.doc(pointId).set(data, { merge: true });
    }
  }

  public updateCart(pointId: string, cartId: string, data: any): void {
    if (pointId && cartId && data) {
      this.getCartCollection(pointId)?.doc(cartId).set(data, { merge: true });
    }
  }

  public updateBasket(pointId: string, cartId: string, data: any): void {
    if (pointId && cartId && data) {
      this.getBasketCollection(pointId, cartId)?.doc(data.sellerId).set(data, { merge: true });
    }
  }

  public getPoint(pointId: string): Observable<PointModel> {
    return this.getPointCollection()?.doc(pointId).valueChanges();
  }

  public async setPointInfo(pointId: string): Promise<void> {
    const point = await this.getPoint(pointId).pipe(take(1)).toPromise();
    this.$notification.setPoint(point);
  }

  public getAndSetActiveCart(pointId: string, customerId?: string): void {
    let activeCart = new CartModel();

    this.getActiveCart(pointId)
      .pipe(
        mergeMap((carts) => {
          if (carts.length > 0) {
            activeCart = carts[0];
            this.updatePoint(pointId, { cartId: activeCart.id });

            return combineLatest({
              baskets: this.getBasketCollection(pointId, activeCart.id).valueChanges(),
            });
          } else {
            activeCart = this.addNewCart(pointId, customerId);
            this.updatePoint(pointId, { cart: activeCart });
          }

          return of();
        })
      )
      .subscribe({
        next: (res) => {
          if (res) {
            if (res.baskets) {
              this.geBasketWithItens(res.baskets, activeCart);
            } else {
              this.$notification.setCart(activeCart);

              if (pointId) {
                this.updatePoint(pointId, { cart: activeCart });
              }
            }
          }
        },
        error: (error) => {
          throw new Error(error);
        },
      });
  }

  public async geBasketWithItens(baskets: BasketModel[], cart: CartModel) {
    await Promise.all(
      baskets.map(async (basket, index: number) => {
        await new Promise<void>(async (resolve, reject) => {
          basket.items = await this.getBasketProducts(cart.pointId, cart.id, basket.sellerId).pipe(take(1)).toPromise();
          basket.totalValue = this.$item.calcTotalPrice(basket.items);
          resolve();
        });
      })
    ).then(async () => {
      cart.baskets = baskets;
      cart.totalValue = this.calcTotalPriceBasket(baskets);
      this.$notification.setCart(cart);

      if (cart.pointId) {
        this.updatePoint(cart.pointId, { cart });
      }
    });
  }

  public getActiveCart(pointId: string): Observable<CartModel[]> {
    return this.afStore
      .collection(`marketplaces/${this.marketplaceId}/pointOfSales/${pointId}/carts`, (ref) => {
        const startDate = moment().subtract(7, 'd').set({ hour: 0, minute: 0, second: 0 }).format();
        const endDate = moment().set({ hour: 23, minute: 59, second: 59 }).format();

        let query: CollectionReference | Query = ref;

        query.where('insertedAt', '>=', startDate).where('insertedAt', '<=', endDate);

        query = query.where('status', '==', 'ACTIVE');

        return query.orderBy('insertedAt', 'desc');
      })
      .valueChanges() as Observable<CartModel[]>;
  }

  public addNewCart(pointId: string, customerId: string = null): CartModel {
    const cartId = uuidv4();
    const cart: CartModel = {
      id: cartId,
      status: 'ACTIVE',
      orders: [],
      payments: [],
      baskets: [],
      pointId,
      customerId,
      isPaid: false,
      totalValue: 0,
      insertedAt: moment.utc().format('YYYY-MM-DDTHH:mm:ss'),
    };

    this.getCartCollection(pointId)?.doc(cartId).set(cart);

    return cart;
  }

  public calcTotalPriceBasket(baskets: Array<BasketModel>): number {
    return baskets.reduce((acc, basket) => {
      acc += Number(basket.totalValue);
      return acc;
    }, 0);
  }

  public checkMultisellerBasket(product: Partial<ItemsV2Model>, point: PointModel, cart: CartModel): void {
    const sellerId = product.seller?.id;
    let baskedToBeRemoved = null;

    baskedToBeRemoved = cart.baskets.find((basket) => basket.sellerId !== sellerId);

    if (baskedToBeRemoved) {
      this.clearBasket(point.id, cart.id, baskedToBeRemoved.id);
    }
  }
}
