import { Observable } from 'rxjs';
import { Apollo } from 'apollo-angular';
import { Injectable, Query } from '@angular/core';

import { FilterWithdrawsParams } from '../../models/withdrawls/filter-withdraws-params.model';
import { GET_WITHDRAW_QUERY, LIST_WITHDRAWS_QUERY } from '../../graphql/queries/withdrawals.queries';
import { APPROVE_WITHDRAW_MUTATION, DECLINE_WITHDRAW_MUTATION } from '../../graphql/mutations/withdrawals.mutations';
import { PaginationModel } from '../../models/pagination/pagination.model';

@Injectable()
export class WithdrawlsService {
  constructor(private readonly apollo: Apollo) {}

  public getWithdrawsList(filter: FilterWithdrawsParams, pagination: PaginationModel): Observable<any> {
    return this.apollo.watchQuery<Query>({
      query: LIST_WITHDRAWS_QUERY,
      variables: { filter, pagination },
      fetchPolicy: 'network-only',
    }).valueChanges;
  }

  public getWithdrawById(id: string): Observable<any> {
    return this.apollo.watchQuery<Query>({
      query: GET_WITHDRAW_QUERY,
      variables: { id },
      fetchPolicy: 'cache-and-network',
    }).valueChanges;
  }

  public approveWithdrawl(id: string, statusReason: string): Observable<any> {
    return this.apollo.mutate({
      mutation: APPROVE_WITHDRAW_MUTATION,
      variables: { id, statusReason },
    });
  }

  public declineWithdrawl(id: string, statusReason: string): Observable<any> {
    return this.apollo.mutate({
      mutation: DECLINE_WITHDRAW_MUTATION,
      variables: { id, statusReason },
    });
  }
}
