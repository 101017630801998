import { NzModalRef } from 'ng-zorro-antd/modal';
import { Component, Input, OnInit } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';

import { PaymentModel } from '../../../models/payment/payment.model';

@Component({
  selector: 'app-modal-confirmation-cancel-payment',
  templateUrl: './modal-confirmation-cancel-payment.component.html',
  styleUrls: ['./modal-confirmation-cancel-payment.component.scss'],
})
export class ModalConfirmationCancelPaymentComponent implements OnInit {
  @Input() payment: PaymentModel;

  public formGroup!: FormGroup;

  constructor(public readonly modalRef: NzModalRef, private readonly fb: FormBuilder) {}

  public ngOnInit(): void {
    this.createForm();
  }

  public createForm(): void {
    this.formGroup = this.fb.group({
      amountCents: new FormControl<string>('', [Validators.required, this.minValueValidator(0.01)]),
      description: new FormControl<string>('', [Validators.maxLength(200)]),
    });
  }

  private minValueValidator(minAmount: number): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const forbidden = control.value < minAmount;
      return forbidden ? { forbiddenValue: { value: control.value } } : null;
    };
  }

  public confirmCancellation(): void {
    this.modalRef.close(this.formGroup.value);
  }
}
