<div class="center m-t-2 m-b-2" *ngIf="loading">
  <nz-spin nzSize="large"></nz-spin>
</div>

<header *nzModalTitle>
  <div class="d-flex">
    <h3 class="title-category">{{ title }}</h3>
    <span class="example-spacer"></span>
    <img src="../../assets/images/close.svg" (click)="modalRef.close()" class="pointer-cursor" />
  </div>
</header>

<section *ngIf="!loading">
  <div>
    <div>
      <h3 class="subtitle">Dados bancários</h3>

      <div nz-row [nzGutter]="32" class="m-b-1">
        <div nz-col nzSpan="12">
          <div class="bank-container">
            <div class="bank-info-content">
              <span class="bank-info-title"> Nome </span>
              <span class="bank-info-value">
                {{ data?.bankData?.name }}
              </span>
            </div>
            <div class="bank-info-content">
              <span class="bank-info-title"> Agência </span>
              <span class="bank-info-value">
                {{ data?.bankData?.agencyNumber }}
              </span>
            </div>
            <div class="bank-info-content">
              <span class="bank-info-title"> Conta </span>
              <span class="bank-info-value"> {{ data?.bankData?.accountNumber | mask : '0000000-0' }} </span>
            </div>
          </div>
        </div>
        <div nz-col nzSpan="12">
          <div class="bank-container">
            <div class="bank-info-content">
              <span class="bank-info-title"> Banco </span>
              <span class="bank-info-value">
                {{ data?.bankData?.bankName }}
              </span>
            </div>
            <div class="bank-info-content">
              <span class="bank-info-title"> Tipo </span>
              <span class="bank-info-value">
                {{ data?.bankData?.type }}
              </span>
            </div>
          </div>
        </div>
      </div>

      <nz-divider></nz-divider>

      <div nz-row [nzGutter]="32" class="m-b-1">
        <div nz-col nzSpan="12">
          <div class="start">
            <span class="bank-withdrawl"> Saldo disponível </span>
            <span class="bank-withdrawl m-t-0 d-block start">
              <strong>
                {{ data.currentBalance / 100 | currency : 'R$' }}
              </strong>
            </span>
          </div>
        </div>
        <div nz-col nzSpan="12">
          <div class="d-grid end">
            <span class="bank-withdrawl"> Total de solicitações </span>
            <span class="bank-withdrawl m-t-0">
              <strong>
                {{ waitingValue / 100 | currency : 'R$' }}
              </strong>
            </span>
          </div>
        </div>
      </div>
    </div>
    <nz-divider></nz-divider>
    <form class="m-t-1" [formGroup]="formDescription">
      <nz-form-item>
        <label>Valor do saque</label>
        <nz-form-control
          nzHasFeedback
          [nzErrorTip]="
            formDescription.controls['withdrawValue'].hasError('required')
              ? 'Valor requerido'
              : formDescription.controls['withdrawValue'].hasError('min')
              ? 'Valor precisa ser maior do que R$ 10,00'
              : formDescription.controls['withdrawValue'].hasError('max')
              ? 'Valor maior do que o saldo'
              : ''
          "
        >
          <input nz-input formControlName="withdrawValue" placeholder="Digite o valor" currencyMask />
        </nz-form-control>
      </nz-form-item>
    </form>
  </div>
</section>

<footer class="modal-footer" *nzModalFooter>
  <app-secondary-button (click)="setHandleSubmit(false)">Cancelar</app-secondary-button>
  <app-primary-button [disabled]="formDescription.invalid" (click)="setHandleSubmit(true)" class="m-l-1">
    Solicitar saque
  </app-primary-button>
</footer>
