import { ChangeDetectorRef, Component, HostListener } from '@angular/core';
import { CategoryService } from '../../services/item/category/category.service';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { StateManagementService } from '../../state-management/state-management.service';
import { mergeMap } from 'rxjs';
import { CategoryModel } from '../../models/categories/categories.model';
import { NzDrawerService } from 'ng-zorro-antd/drawer';
import { SearchHeaderMarketPlaceComponent } from '../drawers/search-header-marketplace/search-header-marketplace.component';
import { FilterCategoriesComponent } from '../sub-header/filter-categories/filter-categories.component';
import { NzModalService } from 'ng-zorro-antd/modal';
import { ModalLoginMarketplaceComponent } from '../modals/modal-login-marketplace/modal-login-marketplace.component';
import { DrawerProfileMenuMarketplaceComponent } from '../drawers/profile-menu-marketplace/profile-menu-marketplace.component';
@Component({
  selector: 'gen-monorepo-header-marketplace',
  templateUrl: './header-marketplace.component.html',
  styleUrls: ['./header-marketplace.component.scss'],
})
export class HeaderMarketplaceComponent {
  public productsCategoryList: Array<CategoryModel> = [];
  public productsCategoryListHeaderLimited: Array<CategoryModel> = [];
  public productsCategoryListChild: Array<CategoryModel> = [];
  public listOfProfileOptions: Array<any> = [
    {
      name: 'Minha conta',
      type: 'ACCOUNT',
    },
    {
      name: 'Pagamentos',
      type: 'PAYMENTS',
    },
    {
      name: 'Favoritos',
      type: 'FAVORITES',
    },
    {
      name: 'Pedidos',
      type: 'ORDERS',
    },
    {
      name: 'Sair',
      type: 'LOGOUT',
    },
  ];

  public user: any = {};

  public userInitialName: string = '';

  public form: FormGroup;

  public activeIndex: number;

  public isMobile: boolean = window.innerWidth < 768;
  public isShowOptionsProfile: boolean = false;

  constructor(
    private $notification: StateManagementService,
    private $categories: CategoryService,
    private fb: FormBuilder,
    private cdRef: ChangeDetectorRef,
    private route: Router,
    private $drawer: NzDrawerService
  ) {}

  public ngOnInit(): void {
    this.getCategories();
    this.createForm();
    this.getValuesChange();
    this.getUserDetails();
  }

  public ngAfterContentChecked(): void {
    this.cdRef.detectChanges();
  }

  private createForm(): void {
    this.form = this.fb.group({
      search: new FormControl(''),
      category: new FormControl(''),
      amountCents: new FormControl(''),
      color: new FormControl(''),
      size: new FormControl(''),
    });
  }

  public getValuesChange(): void {
    this.form.get('search').valueChanges.subscribe((res) => {
      this.$notification.setSearchValue(res);
    });
  }

  public getUserDetails(): void {
    this.$notification.users.subscribe({
      next: (user) => {
        this.user = user;
        this.userInitialName = user?.name
          ?.split(' ')
          .map((name) => name.charAt(0))
          .join('');
      },
    });
  }

  public getCategories(): void {
    const verifyRoute = this.route.url;

    if (!verifyRoute.includes('/loggedCart')) {
      this.$notification.categorySellers
        .pipe(
          mergeMap((categories) => {
            if (categories) {
              this.productsCategoryList = categories;
              return this.$notification.categories;
            } else {
              return this.$notification.categories;
            }
          })
        )
        .subscribe((categories) => {
          if (categories) {
            this.getCategoryList(categories);
          } else {
            this.$categories.getAndSetCategoriesAll();
          }
        });
    }
  }

  public getCategoryList(categories): void {
    let options = this.$categories.setCategoriesCascader(categories);

    this.productsCategoryList = options;

    this.productsCategoryListHeaderLimited = options.filter(
      (res) =>
        res.value === '0fbb4ab5-6037-4eb6-b317-a25617b2a22d' || res.value === '34e8fcb4-f6c5-4015-90c7-08e456d56645'
    );

    this.productsCategoryListHeaderLimited.push({
      label: 'Categorias',
    });

    this.productsCategoryListHeaderLimited.push({
      label: 'Marcas',
    });

    this.productsCategoryListHeaderLimited.reverse();

    if (this.productsCategoryListHeaderLimited.length >= 2) {
      const firstElement = this.productsCategoryListHeaderLimited.shift();
      this.productsCategoryListHeaderLimited.push(firstElement);
    }
  }

  public onSelectCategory(category: any, type: string, index?: number): void {
    this.activeIndex = index;

    if (category) {
      if (type === 'selected') {
        // this.$notification.setChangedCategory(category);
        // this.$notification.setIsCategory(true);

        if (index == 3) {
          this.route.navigate(['internal/brands']);
        } else {
          this.route.navigate(['/internal/products'], {
            queryParams: { categoryId: category.value, categoryLabel: category.label },
          });
        }
      } else {
        this.productsCategoryListChild = category;
      }
    }
  }

  public getIsCategory(): void {
    this.$notification.isShowCategories.subscribe((res) => {
      if (res === false) {
        this.activeIndex = -1;
      }
    });
  }

  public clearList(): void {
    this.$notification.setSearchValue(null);
    this.$notification.setChangedCategory(null);
    this.$notification.setIsCategory(false);
  }

  public goToHome(): void {
    this.route.navigate(['/internal/home']);
  }

  public goToCategories(): void {
    this.route.navigate(['/internal/categories']);
  }

  public clickToShowDrawer(): void {
    this.$drawer.create({
      nzContent: SearchHeaderMarketPlaceComponent,
      nzPlacement: 'bottom',
      nzHeight: '100%',
    });
  }

  public openCategoriesFilter(): void {
    const width = window.innerWidth;
    let nzHeight = '100%';

    if (width > 768) {
      nzHeight = '60%';
    }

    this.$drawer.create({
      nzClosable: false,
      nzWidth: '100%',
      nzHeight,
      nzBodyStyle: {
        'max-width': '100vm',
        'overflow-x': 'hidden',
        height: nzHeight,
        padding: '0',
      },
      nzPlacement: 'top',
      nzContentParams: { categories: this.productsCategoryList },
      nzContent: FilterCategoriesComponent,
    });
  }

  public openProfile(): void {
    let nzHeight = '100%';

    this.$drawer.create({
      nzClosable: false,
      nzWidth: '90%',
      nzHeight,
      nzBodyStyle: {
        'max-width': '100vm',
        'overflow-x': 'hidden',
        height: nzHeight,
        padding: '0',
      },
      nzPlacement: 'left',
      nzContent: DrawerProfileMenuMarketplaceComponent,
    });
  }

  public goToAuth(): void {
    this.route.navigate(['external/sign-in/password']);
  }

  public goToRegister(): void {
    this.route.navigate(['external/sign-up/personal-data']);
  }

  public logOut(): void {
    sessionStorage.clear();

    this.route.navigate(['/external']).then(() => {
      window.location.reload();
    });
  }

  @HostListener('window:resize', ['$event'])
  public onResize(event: any): void {
    this.isMobile = event.target.innerWidth < 768;

    if (this.isMobile) {
      this.isShowOptionsProfile = false;
    }
  }

  public showOptionsProfile(): void {
    this.isShowOptionsProfile = !this.isShowOptionsProfile;
  }

  public optionAction(data): void {
    switch (data) {
      case 'ACCOUNT':
        this.route.navigate(['/internal/profile/account']);
        break;

      case 'PAYMENTS':
        this.route.navigate(['/internal/profile/payments']);
        break;

      case 'FAVORITES':
        this.route.navigate(['/internal/profile/favorites']);
        break;

      case 'ORDERS':
        this.route.navigate(['/internal/profile/orders']);
        break;

      case 'LOGOUT':
        sessionStorage.clear();

        this.route.navigate(['/external']).then(() => {
          window.location.reload();
        });
        break;
    }
  }
}
