<div (keydown.enter)="saveItem()" class="item_editable_table">
  <nz-spin *ngIf="loading" nzSimple></nz-spin>
  <form [formGroup]="form">
    <nz-table
      [nzSimple]="false"
      [nzBordered]="false"
      nzTableLayout="fixed"
      nzShowPagination="false"
      [nzData]="itemForm.value"
      [nzShowSizeChanger]="true"
      [nzFooter]="editableTableFooter"
    >
      <thead>
        <tr>
          <th
            *ngFor="let header of listOfHeadersColumn; let i = index"
            [nzSortFn]="header.compare"
            [nzSortPriority]="header.priority"
            [nzWidth]="header.headerWidth"
          >
            <span class="{{ i === listOfHeadersColumn.length - 1 ? 'delete' : '' }} cursor-auto">
              {{ header.name }}
            </span>
          </th>
        </tr>
      </thead>
      <tbody>
        <ng-container formArrayName="itemForm">
          <ng-container *ngFor="let data of itemForm.controls; let i = index">
            <tr [formGroupName]="i" class="item-form" *ngIf="!loading" (click)="editQtd(i)">
              <td nzEllipsis *ngIf="!(i === editIndex && edit)">{{ data.value?.description }}</td>
              <td nzEllipsis *ngIf="i === editIndex && edit">
                <nz-form-control nzHasFeedback nzErrorTip="Campo obrigatório">
                  <nz-input-group>
                    <input nz-input formControlName="description" required type="text" placeholder="ITEM" />
                  </nz-input-group>
                </nz-form-control>
              </td>
              <td nzEllipsis *ngIf="!(i === editIndex && edit)">{{ data.value?.quantity }}</td>
              <td nzEllipsis *ngIf="i === editIndex && edit">
                <nz-form-control nzHasFeedback nzErrorTip="Quantidade mínima: 1">
                  <nz-input-group>
                    <input nz-input formControlName="quantity" required type="number" placeholder="0" />
                  </nz-input-group>
                </nz-form-control>
              </td>
              <td nzEllipsis *ngIf="!(i === editIndex && edit)">
                {{ data.value?.unitPriceCents | currency : 'R$' }}
              </td>
              <td nzEllipsis *ngIf="i === editIndex && edit">
                <nz-form-control nzHasFeedback nzErrorTip="Valor mínimo: R$ 10,00">
                  <nz-input-group>
                    <input
                      nz-input
                      nzSuffix="R$"
                      formControlName="unitPriceCents"
                      currencyMask
                      placeholder="0,00"
                      required
                      type="text"
                    />
                  </nz-input-group>
                </nz-form-control>
              </td>
              <td nzEllipsis>{{ data.value?.unitPriceCents * data.value?.quantity | currency : 'R$' }}</td>
              <td nzEllipsis class="center d-flex centerBlock">
                <app-secondary-button
                  *ngIf="i === editIndex && edit"
                  (click)="saveItem()"
                  type="BORDERLESS"
                  [block]="true"
                >
                  Salvar
                </app-secondary-button>
                <img
                  class="pointer-cursor"
                  (click)="deleteItem(i)"
                  src="../../../../../assets/images/icons/trash.svg"
                  alt="Lixeira"
                />
              </td>
            </tr>
          </ng-container>
        </ng-container>
      </tbody>
      <ng-template #editableTableFooter>
        <tfoot>
          <span class="m-l-1"> Valor Total </span>
          <span class="m-r-1">
            {{ resultTotalValue || 0 | currency : 'R$' }}
          </span>
        </tfoot>
      </ng-template>
    </nz-table>
  </form>
</div>
