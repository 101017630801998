import { AddressModel } from '../address/address.model';
import { SellerModel } from '../sellers/sellers.model';

export class ContactModel {
  public representative?: {
    name?: string;
    address?: AddressModel | null;
    cpf?: string;
  };
  public email?: string;
  public name?: string;
  public fullName?: string;
  public address?: AddressModel | null;
  public id?: string;
  public cnpj?: string | null;
  public cpf?: string | null;
  public insertedAt?: string;
  public phone?: string | null;
  public phoneNumber?: string | null;
  public marketplaceId?: string;
  public shippingAddress?: AddressModel;
  public customerId?: string;
  public sellerId?: string;
  public seller?: SellerModel;
  public birthDate?: string;
  public businessName?: string;
  public isLegalEntity?: string | boolean;
  public created_at?: string;
}

export class ContactType {
  PERSON: string = 'PERSON';
  BUSINESS: string = 'BUSINESS';
}

export class ContactFilterModel {
  email?: string;
  cpf?: string;
  startDate?: any;
  endDate?: any;
  sellerId?: string;
}
