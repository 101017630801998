import moment from 'moment';
import { Observable, mergeMap, of, retry, throwError } from 'rxjs';
import { v4 as uuidv4 } from 'uuid';
import { Injectable } from '@angular/core';
import { NzMessageService } from 'ng-zorro-antd/message';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { AngularFireFunctions } from '@angular/fire/compat/functions';

import { MallModel } from '../../models/mall/mall.model';
import { TicketModel } from './../../models/ticket/ticket.model';
import { ResponseModel } from '../../models/response/response.model';
import { PaidTicketModel } from './../../models/ticket/paidTicket.model';
import { RouteParamsModel } from './../../models/route-params/route-params.model';
import { PaymentTicketParamModel } from '../../models/ticket/paymentTicketParam.model';
import { SaveReceiptParamsModel } from '../../models/receipt/save-receipt-params.model';
import { StateManagementService } from '../../state-management/state-management.service';
import { PaymentTicketResponseModel } from '../../models/ticket/paymentTicketResponse.model';
import { convertToUpperCase } from '../../utils/utils';
import { CreditCardService } from '../credit-card/credit-card.service';
import { SystemService } from '../system/system.service';
import { Router } from '@angular/router';
import { ResponseCreditCardModel } from '../../models/credit-card/response-credit-card.model';
import { ParkingUserModel } from '../../models/parking-user/parking-user';
import { AddressModel } from '../../models/address/address.model';
import { CreditCardModel } from '../../models/credit-card/credit-card.model';

@Injectable()
export class TicketService {
  constructor(
    private $system: SystemService,
    private readonly router: Router,
    private $creditCard: CreditCardService,
    private readonly afStore: AngularFirestore,
    private readonly $message: NzMessageService,
    private $notification: StateManagementService,
    private readonly $functions: AngularFireFunctions
  ) {}

  public getTicket(data: { mallId: string; ticketId: string }): Observable<ResponseModel<TicketModel>> {
    return this.$functions.httpsCallable('getVirtualWindowTicket')(data);
  }

  public payTicket(data: PaymentTicketParamModel): Observable<ResponseModel<PaymentTicketResponseModel>> {
    return this.$functions.httpsCallable('payVirtualWindowTicket')(data);
  }

  public getMalls(): Observable<ResponseModel<Array<MallModel>>> {
    return this.$functions.httpsCallable('getMallList')(null);
  }

  public getMallById(data: { mallId: string }): Observable<ResponseModel<Array<MallModel>>> {
    return this.$functions.httpsCallable('getMallById')(data);
  }

  public getMall(mallId: string): void {
    this.getMallById({ mallId }).subscribe((res) => {
      if (res.status === 200) {
        this.$notification.setMall(res.body.rows[0]);
      }
    });
  }

  public getAndSetTicket(ids: RouteParamsModel): void {
    if (ids && ids.mallId && ids.ticketId) {
      this.getTicket({ mallId: ids.mallId, ticketId: ids.ticketId }).subscribe((res) => {
        if (res?.status === 200) {
          this.$notification.setTicket(res.body);
        }
      });
    }
  }

  public getStayedTime(ticket: TicketModel): { stayedTime: number; valuePerHour: number } {
    const horarioEntrada = moment(ticket.entradaDatahora);

    const stayedTime = moment().diff(horarioEntrada, 'minutes') || 0;
    const valuePerHour = Number(ticket.valorTotal) / (stayedTime / 60);

    return { stayedTime, valuePerHour };
  }

  public saveReceipt(params: SaveReceiptParamsModel): void {
    const id = uuidv4();

    const payload = {
      id,
      customer: {
        name: params.card?.consumidor?.nome || params.user?.name || 'Usuário anônimo',
        email: params.user?.email || '',
        cpf: params.user?.cpf || '',
      },
      mall: params.mall?.externalId ? params.mall : params.pix?.mall,
      ticket: params.ticket || '',
      paymentNumber: params.res?.transacao || params.ticket?.transacao || '',
      stayedTime: this.getStayedTime(params.ticket).stayedTime || 0,
      paymentAt: moment().format(),
      paymentValue: params.res?.valorPago || params.ticket?.valorPago || params.ticket?.analise?.valorDevido,
      card: {
        brand: params.card?.brand || convertToUpperCase(params.pix?.ticket?.method || '') || 'Cartão',
        last4: params.card?.last4 || '',
      },
    };

    this.afStore.doc(`parkingUsers/${params.user.id}/payments/${id}`).set(payload, { merge: true });
  }

  public getPaidTicket(id: string): Observable<PaidTicketModel[]> | undefined {
    const userId = localStorage.getItem('parkingUser') || id;

    if (userId) {
      return this.afStore.collection(`parkingUsers/${userId}/payments`).valueChanges() as Observable<PaidTicketModel[]>;
    }

    return null;
  }

  public initCreditCardPayment(
    user: ParkingUserModel,
    ticket: TicketModel,
    mall: MallModel,
    creditCardData: any,
    addressData: AddressModel
  ): any {
    this.$creditCard.tokenizeCreditCard(creditCardData.cardNumber).subscribe({
      next: (res) => {
        if (res?.data?.tokenizeCreditCard) {
          const payload = this.getPayload(
            res.data.tokenizeCreditCard.numberToken,
            ticket.transacao,
            user,
            creditCardData,
            addressData
          );

          if (creditCardData.saveCard) {
            const cardPayload = Object.assign({}, payload.cartao);

            cardPayload.id = creditCardData.id ? creditCardData.id : uuidv4();
            cardPayload.numero = creditCardData.cardNumber;

            delete cardPayload.codigoSeguranca;

            this.$creditCard.saveCard(cardPayload);
          }

          this.payTicket(payload).subscribe({
            next: (res) => {
              if (res.status === 200) {
                this.successPaymentFlow(res.body, payload.cartao, user, ticket, mall);
                this.$notification.setLoading(false);
              } else {
                this.$message.error('Erro ao efetuar o pagamento.');
                this.$notification.setLoading(false);

                if (res.status === 500 || res.status === 502) {
                  this.router.navigate([`external/${mall.id}/serverError/${ticket.ticket}`]);
                  throw new Error(JSON.stringify(res.body));
                } else {
                  this.router.navigate([`external/${mall.id}/paymentError/${ticket.ticket}`]);
                  throw new Error(JSON.stringify(res.body));
                }
              }
            },
            error: (error) => {
              this.$notification.setLoading(false);
              this.router.navigate([`external/${mall.id}/serverError/${ticket.ticket}`]);
              this.$message.error('Erro ao efetuar o pagamento.');
              throw new Error(error);
            },
          });
        } else {
          this.$notification.setLoading(false);
          this.$system.openGenericModal();
          this.$message.error('Cartão inválido, revise os dados e tente novamente.');
        }
      },
      error: (error) => {
        this.$notification.setLoading(false);
        this.$system.openGenericModal();
        throw new Error(error);
      },
    });
  }

  public getPayload(
    token: string,
    transacao: string,
    user: ParkingUserModel,
    creditCardData: CreditCardModel,
    addressData: AddressModel
  ): PaymentTicketParamModel {
    const payload: PaymentTicketParamModel = {
      transaction: transacao,
      sessionId: uuidv4(),
      cartao: {
        token,
        last4: creditCardData.cardNumber?.slice(-4),
        brand: this.$creditCard.getCreditCardObj(creditCardData.cardNumber).brand || 'MASTERCARD',
        codigoSeguranca: creditCardData.securityCode,
        portador: creditCardData.name || creditCardData.customer?.name || user.name,
        validade: creditCardData.expirationDate
          ? creditCardData.expirationDate.replace('/', '')
          : creditCardData?.expirationMonth + creditCardData?.expirationYear,
        consumidor: {
          cpf: user.cpf,
          nome: creditCardData.name || creditCardData.customer?.name || user.name,
          telefone: user.phoneNumber,
          email: user.email,
          endereco: {
            cep: addressData.postalCode,
            logradouro: addressData.line1,
            numero: addressData.line2,
            uf: addressData.state,
            cidade: addressData.city,
            bairro: addressData.neighborhood,
            complemento: addressData.line3,
          },
        },
      },
    };

    return payload;
  }

  public successPaymentFlow(
    body: PaymentTicketResponseModel,
    card: ResponseCreditCardModel,
    user: ParkingUserModel,
    ticket: TicketModel,
    mall: MallModel
  ): void {
    const params: SaveReceiptParamsModel = { user, res: body, card, ticket, mall };
    this.saveReceipt(params);

    if (mall?.id && ticket?.ticket) {
      this.getTicket({ mallId: mall.id, ticketId: ticket.ticket })
        .pipe(
          mergeMap((res) => {
            if (res?.status !== 200) {
              return throwError(() => new Error('Erro ao buscar o tiquete.'));
            }

            return of(res?.body);
          }),
          retry(3)
        )
        .subscribe((body) => {
          this.$notification.setTicket(body);
          this.router.navigate([`external/${mall.id}/approved/${ticket.ticket}`]);
        });
    }
  }
}
