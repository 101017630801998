<main id="success-redeem-coupon">
  <section id="header" class="m-b-1">
    <nz-row>
      <nz-col nzSpan="24" class="endBlock">
        <img
          src="/assets/icons/xmark.svg"
          alt="Ícone para fechar o modal"
          class="pointer-cursor"
          (click)="closeModal()"
        />
      </nz-col>
    </nz-row>
  </section>

  <section id="body">
    <nz-row class="m-b-1-2 center">
      <nz-col nzSpan="24">
        <img src="/assets/img/green-check.svg" alt="Ícone para o sucesso." />
      </nz-col>
    </nz-row>
    <nz-row class="m-b-0-1 center">
      <nz-col nzSpan="24">
        <span class="title-text">
          Você ganhou
          {{
            coupon.typeDiscount === 'PERCENT'
              ? coupon.value / 100 + '%'
              : 'R$' + (coupon.value / 100 | currency : ' ' : 'symbol' : '1.0-0')
          }}
          OFF
        </span>
      </nz-col>
    </nz-row>
    <nz-row class="m-b-0-1 center">
      <nz-col nzSpan="24">
        <p class="description-text no-margin-bottom">Cupom utilizado com sucesso.</p>
      </nz-col>
    </nz-row>
    <nz-row class="m-b-1-2 center">
      <nz-col nzSpan="24">
        <p class="description-text no-margin-bottom">
          Seu desconto será aplicado no momento da compra na loja
          <span>{{ coupon?.seller?.business_name }} {{ coupon?.mall?.name }}</span>
          .
        </p>
      </nz-col>
    </nz-row>
  </section>

  <section id="footer">
    <nz-row>
      <nz-col nzSpan="24">
        <app-primary-button [block]="true" class="w-100 center copy-btn" (click)="closeModal()">
          Entendi
        </app-primary-button>
      </nz-col>
    </nz-row>
  </section>
</main>
