import { Subscription } from 'rxjs';
import { NzModalRef } from 'ng-zorro-antd/modal';
import { Component, OnInit } from '@angular/core';
import { NzUploadChangeParam, NzUploadXHRArgs } from 'ng-zorro-antd/upload';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-modal-upload-document',
  templateUrl: './modal-upload-document.component.html',
  styleUrls: ['./modal-upload-document.component.scss'],
})
export class ModalUploadDocumentComponent implements OnInit {
  public file: File | undefined;
  public formGroup!: FormGroup;

  constructor(private readonly modalRef: NzModalRef, private readonly fb: FormBuilder) {}

  public ngOnInit(): void {
    this.createForm();
  }

  public createForm(): void {
    this.formGroup = this.fb.group({
      preview: new FormControl<string>('', [Validators.required]),
      documentType: new FormControl<string>('', [Validators.required]),
    });
  }

  public closed(value: any): void {
    if (value) {
      this.file['documentType'] = this.formGroup.get('documentType').value;
      this.modalRef.destroy(this.file);
    } else {
      this.modalRef.destroy();
    }
  }

  public async handleChange(event: NzUploadChangeParam): Promise<void> {
    if (event.type === 'start') {
      event.file.status = 'success';
      this.file = event.file.originFileObj;

      const content = await this.getBase64(this.file);

      this.file['preview'] = content;
      this.formGroup.get('preview').setValue(content);
    }
  }

  public customRequest(file: NzUploadXHRArgs): Subscription {
    return new Subscription();
  }

  private getBase64(file: File): Promise<string> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = (): void => {
        resolve(reader.result as string);
      };
      reader.onerror = (error): void => {
        reject(error);
      };
    });
  }
}
