import { SellerModel } from '../sellers/sellers.model';

export class CategoryModel {
  public id?: string;
  public name?: string;
  public sellerId?: string;
  public imageUrl?: string;
  public itemsId?: string[];
  public selected?: boolean;
  public insertedAt?: string;
  public children?: Array<CategoryModel>;
  public updatedAt?: string;
  public file?: any;
  public value?: string;
  public label?: string;
  public isParent?: boolean;
  public isLeaf?: boolean;
  public seller?: SellerModel = new SellerModel();
}
