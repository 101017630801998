import { gql } from 'apollo-angular';

const LIST_ORDERS_QUERY = gql`
  query ListOrders($filter: FilterOrdersParams) {
    listOrders(filters: $filter, pagination: { orderBy: "inserted_at", sortingOrder: DESC, perPage: 100 }) {
      id
      status
      type
      customer {
        name
        cpf
        cnpj
      }
      items {
        description
      }
      insertedAt
      customerData
      totalCents
      boxCode
      seller {
        id
        person {
          name
        }
        company {
          businessName
        }
        store {
          displayName
        }
      }
      lockerOrders {
        id
        volumes {
          id
          expressNumber
        }
      }
    }
  }
`;

const GET_ORDER_QUERY = gql`
  query getOrder($id: UUID4!) {
    getOrder(id: $id) {
      id
      status
      type
      customer {
        id
        name
        cpf
        cnpj
      }
      customerData
      seller {
        id
        email
        store {
          displayName
        }
        company {
          businessName
        }
        person {
          name
        }
      }
      items {
        id
        unitPriceCents
        description
        quantity
        item {
          id
          images {
            imageUrl
          }
        }
      }
      method
      expirationDate
      insertedAt
      updatedAt
      totalCents
      maxInstallments
      methods
      subtotalCents
      shippingCents
      discountCents
      additionCents
      feeAmountCents
      deliveryMethod
      deliveryStatus
      boxCode
      store
      items {
        description
        quantity
        unitPriceCents
      }
      payments {
        id
        method
        confirmedAt
        totalCents
        subtotalCents
        status
        insertedAt
        updatedAt
        credit {
          installments
          publicCreditCard {
            brand
            last4
            expirationMonth
            expirationYear
          }
        }
        bnplCheckout {
          id
          numberOfInstallments
          downPaymentValue
          returnUrl
          status
          bnplTransaction {
            installments {
              paymentStatus
            }
          }
        }
        pix {
          brcode
        }
        bankSlip {
          bank
          barcode
          printLinkPdf
        }
      }
      lockerOrders {
        id
        volumes {
          id
          expressNumber
          externalId
          designationSize
          invoice {
            key
          }
        }
      }
      shippingAddress {
        postalCode
        line1
        line2
        city
        state
      }
    }
  }
`;

const GET_PUBLIC_ORDER_QUERY = gql`
  query getPublicOrder($id: UUID4!) {
    getPublicOrder(id: $id) {
      id
      status
      deliveryStatus
      deliveryMethod
      type
      boxCode
      boxName
      shippingAddress {
        postalCode
        line1
        line2
        line3
        neighborhood
        state
        city
      }
      publicSeller {
        id
        cpf
        cnpj
        displayName
        company {
          businessName
        }
        person {
          name
        }
      }
      store
      publicCustomer {
        id
        name
        cpf
        phone
        email
        address {
          postalCode
          line1
          line2
          line3
          neighborhood
          state
          city
        }
      }
      items {
        id
        unitPriceCents
        description
        quantity
        item {
          images {
            imageUrl
          }
        }
        itemUnit {
          active
          price
          id
          image {
            description
            id
            insertedAt
            orderIndex
            updatedAt
            url
          }
          item {
            id
            name
            type
            updatedAt
            details
            insertedAt
            description
            seller {
              id
            }
            images {
              description
              id
              insertedAt
              orderIndex
              updatedAt
              url
            }
            itemVariants {
              id
              name
              type
              orderIndex
              itemVariantOptions {
                id
                active
                name
                color
                orderIndex
                itemUnitVariantOptions {
                  orderIndex
                  id
                  active
                  images {
                    description
                    id
                    insertedAt
                    orderIndex
                    updatedAt
                    url
                  }
                  itemVariantOption {
                    id
                    itemVariant {
                      id
                    }
                  }
                }
              }
            }
            itemUnits {
              active
              price
              id
              image {
                description
                id
                insertedAt
                orderIndex
                updatedAt
                url
              }
              stockCount
              itemUnitVariantOptions {
                orderIndex
                active
                images {
                  description
                  id
                  insertedAt
                  orderIndex
                  updatedAt
                  url
                }
                itemVariantOption {
                  id
                  itemVariant {
                    id
                  }
                }
              }
              itemVariantOptions {
                id
                orderIndex
                name
                itemVariant {
                  id
                  name
                  orderIndex
                  type
                }
              }
            }
            itemTags {
              name
              id
            }
            itemCategory {
              id
              name
            }
            priceRange {
              max
              min
            }
          }
          stockCount
          itemUnitVariantOptions {
            orderIndex
            active
            images {
              description
              id
              insertedAt
              orderIndex
              updatedAt
              url
            }
            itemVariantOption {
              id
              itemVariant {
                id
              }
            }
          }
        }
      }
      method
      expirationDate
      insertedAt
      updatedAt
      totalCents
      maxInstallments
      methods
      subtotalCents
      shippingCents
      discountCents
      additionCents
      payments {
        id
        method
        confirmedAt
        totalCents
        status
        insertedAt
        updatedAt
        credit {
          installments
          publicCreditCard {
            brand
            last4
            expirationMonth
            expirationYear
          }
        }
        pix {
          brcode
          expiration
        }
        bankSlip {
          bank
          barcode
          expirationDate
          printLinkPdf
          typefulLine
        }
      }
    }
  }
`;

const LIST_SELLER_ORDERS = gql`
  query ListSellerOrders($sellerId: UUID4!, $filter: FilterOrdersParams) {
    listSellerOrders(
      sellerId: $sellerId
      filters: $filter
      pagination: { orderBy: "inserted_at", sortingOrder: DESC, perPage: 100 }
    ) {
      id
      status
      type
      customer {
        name
        cpf
        cnpj
      }
      insertedAt
      status
      expirationDate
      deliveryMethod
      deliveryStatus
      customerData
      totalCents
    }
  }
`;

const LIST_CUSTOMER_ORDERS_QUERY = gql`
  query ListCustomerOrders($customerId: UUID4!, $filter: FilterOrdersParams) {
    listCustomerOrders(
      customerId: $customerId
      filters: $filter
      pagination: { orderBy: "inserted_at", sortingOrder: DESC, perPage: 100 }
    ) {
      id
      status
      type
      seller {
        id
        store {
          displayName
          pointOfSale {
            city
            countryCode
            line1
            line2
            line3
            neighborhood
            postalCode
            state
          }
        }
      }
      store
      publicCustomer {
        id
        name
        cpf
        phone
        email
        address {
          postalCode
          line1
          line2
          line3
          neighborhood
          state
          city
        }
      }
      items {
        id
        unitPriceCents
        description
        quantity
        itemUnit {
          active
          price
          id
          image {
            description
            id
            insertedAt
            orderIndex
            updatedAt
            url
          }
          item {
            id
            name
            type
            updatedAt
            details
            insertedAt
            description
            seller {
              id
            }
            images {
              description
              id
              insertedAt
              orderIndex
              updatedAt
              url
            }
            itemVariants {
              id
              name
              type
              orderIndex
              itemVariantOptions {
                id
                active
                name
                color
                orderIndex
                itemUnitVariantOptions {
                  orderIndex
                  id
                  active
                  images {
                    description
                    id
                    insertedAt
                    orderIndex
                    updatedAt
                    url
                  }
                  itemVariantOption {
                    id
                    itemVariant {
                      id
                    }
                  }
                }
              }
            }
            itemUnits {
              active
              price
              id
              image {
                description
                id
                insertedAt
                orderIndex
                updatedAt
                url
              }
              stockCount
              itemUnitVariantOptions {
                orderIndex
                active
                images {
                  description
                  id
                  insertedAt
                  orderIndex
                  updatedAt
                  url
                }
                itemVariantOption {
                  id
                  itemVariant {
                    id
                  }
                }
              }
              itemVariantOptions {
                id
                orderIndex
                name
                itemVariant {
                  id
                  name
                  orderIndex
                  type
                }
              }
            }
            itemTags {
              name
              id
            }
            itemCategory {
              id
              name
            }
            priceRange {
              max
              min
            }
          }
          stockCount
          itemUnitVariantOptions {
            orderIndex
            active
            images {
              description
              id
              insertedAt
              orderIndex
              updatedAt
              url
            }
            itemVariantOption {
              id
              itemVariant {
                id
              }
            }
          }
        }
        item {
          images {
            imageUrl
          }
        }
      }
      method
      expirationDate
      insertedAt
      updatedAt
      totalCents
      maxInstallments
      methods
      subtotalCents
      shippingCents
      discountCents
      deliveryStatus
      additionCents
      payments {
        id
        method
        confirmedAt
        totalCents
        status
        insertedAt
        updatedAt
        credit {
          installments
          publicCreditCard {
            brand
            last4
            expirationMonth
            expirationYear
          }
        }
        pix {
          brcode
          expiration
        }
        bankSlip {
          bank
          barcode
          expirationDate
          printLinkPdf
          typefulLine
        }
      }
    }
  }
`;

export { LIST_ORDERS_QUERY, GET_ORDER_QUERY, LIST_SELLER_ORDERS, LIST_CUSTOMER_ORDERS_QUERY, GET_PUBLIC_ORDER_QUERY };
