import { Component } from '@angular/core';
import { NzDrawerRef } from 'ng-zorro-antd/drawer';

@Component({
  selector: 'app-confirm-clean-bottom-sheet',
  templateUrl: './confirm-clean-bottom-sheet.component.html',
  styleUrls: ['./confirm-clean-bottom-sheet.component.scss'],
})
export class ConfirmCleanBottomSheetComponent {
  constructor(public $drawerRef: NzDrawerRef) {}
}
