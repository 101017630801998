import { Component, Input } from '@angular/core';
import { NzDrawerRef, NzDrawerService } from 'ng-zorro-antd/drawer';

@Component({
  selector: 'app-expanded-filter-categories-subchildren',
  templateUrl: './expanded-filter-categories-subchildren.component.html',
  styleUrls: ['./expanded-filter-categories-subchildren.component.scss'],
})
export class ExpandedFilterCategoriesSubchildrenComponent {
  @Input() public selectedProductCategory: any;

  constructor(public $drawerRef: NzDrawerRef, public $drawer: NzDrawerService) {}

  public onSelectedCategory(category): void {
    this.$drawerRef.close(category);
  }

  public backCategory(): void {
    this.$drawerRef.close();
  }
}
