<header class="modal-header" *nzModalTitle>
  <h3 class="title-category">{{ title }}</h3>
  <span class="example-spacer"></span>
  <img src="../../assets/images/close.svg" (click)="$modalRef.close()" class="pointer-cursor" />
</header>

<div [ngClass]="isTextCenter ? 'body body__center' : 'body'">
  <div nz-row class="w-100 m-b-1" *ngIf="variant">
    <div nz-col [nzSpan]="4">
      <div *ngIf="!variant.url" class="upload-container">
        <img src="../../../../../assets/images/icons/file-upload.svg" />
      </div>
      <img class="img-preview" *ngIf="variant.url" [src]="variant.url" />
    </div>
    <div nz-col [nzSpan]="20">
      <div class="inline">
        <span class="variant-name">{{ variant.name }} </span>
        <span class="variant-option"> {{ variant.option }} </span>
      </div>
    </div>
  </div>

  <h3 [innerHTML]="titleName"></h3>
  <p [innerHTML]="message"></p>
</div>

<footer class="modal-footer" *nzModalFooter>
  <div class="buttons w-100">
    <button class="custom-button custom-button__blue" (click)="$modalRef.close()">Cancelar</button>
    <button class="custom-button custom-button__red" (click)="$modalRef.close('EXCLUIR')">
      {{ buttonMessage }}
    </button>
  </div>
</footer>
