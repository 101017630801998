import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'weekDays',
})
export class WeekDaysPipe implements PipeTransform {
  transform(day: string, ...args: string[]): string {
    if (day) {
      const dayTranslated: any = {
        sunday: 'Domingo',
        monday: 'Segunda-feira',
        tuesday: 'Terça-feira',
        wednesday: 'Quarta-feira',
        thursday: 'Quinta-feira',
        friday: 'Sexta-feira',
        saturday: 'Sábado',
        holiday: 'Feriados',
      };

      return dayTranslated[day.toLowerCase()] || '';
    }

    return '';
  }
}
