<header *nzModalTitle class="modal-header">
  <h3 class="title-category">Editar Customer</h3>
  <span class="example-spacer"></span>
  <img src="../../assets/images/close.svg" (click)="modalRef.close()" class="pointer-cursor" />
</header>

<body>
  <main>
    <section>
      <form nz-form [formGroup]="customerFormGroup">
        <p>Informações Pessoais</p>

        <div class="wrapper-input">
          <div class="input">
            <nz-form-label nzFor="name">Nome</nz-form-label>
            <input nz-input type="text" id="name" formControlName="name" />
          </div>
          <div class="input w-20">
            <nz-form-label nzFor="birthDate">Data de nascimento</nz-form-label>
            <nz-date-picker
              class="w-100"
              [nzFormat]="dateFormat"
              formControlName="birthDate"
              id="birthDate"
            ></nz-date-picker>
          </div>
        </div>

        <div class="wrapper-input">
          <div class="input">
            <nz-form-label nzFor="nameMother">Nome da mãe</nz-form-label>
            <input nz-input type="text" id="nameMother" formControlName="nameMother" />
          </div>
        </div>

        <div class="wrapper-input">
          <div class="input w-24">
            <nz-form-label nzFor="email">Email verificado</nz-form-label>
            <input nz-input type="email" id="email" formControlName="email" />
          </div>
        </div>

        <p class="m-t-1">Endereço</p>

        <div class="wrapper-input">
          <div class="input">
            <nz-form-label nzFor="line1">Rua</nz-form-label>
            <input nz-input type="text" id="line1" formControlName="line1" />
          </div>
          <div class="input w-10">
            <nz-form-label nzFor="line2">Número</nz-form-label>
            <input nz-input type="text" id="line2" formControlName="line2" />
          </div>
          <div class="input">
            <nz-form-label nzFor="line3">Complemento</nz-form-label>
            <input nz-input type="text" id="line3" formControlName="line3" />
          </div>
        </div>

        <div class="wrapper-input">
          <div class="input">
            <nz-form-label nzFor="postalCode">CEP</nz-form-label>
            <input nz-input type="text" id="postalCode" mask="00000-000" formControlName="postalCode" />
          </div>
          <div class="input">
            <nz-form-label nzFor="neighborhood">Bairro</nz-form-label>
            <input nz-input type="text" id="neighborhood" formControlName="neighborhood" />
          </div>
          <div class="input">
            <nz-form-label nzFor="city">Cidade</nz-form-label>
            <input nz-input type="text" id="city" formControlName="city" />
          </div>
          <div class="input">
            <nz-form-label nzFor="state">Estado</nz-form-label>
            <input nz-input type="text" id="state" formControlName="state" />
          </div>
        </div>

        <div nz-row class="wrapper-input input">
          <div nz-col nzSpan="24">
            <nz-form-label nzFor="state">Observação</nz-form-label>
            <nz-form-item>
              <nz-form-control>
                <nz-textarea-count [nzMaxCharacterCount]="100">
                  <textarea rows="4" formControlName="observation" nz-input></textarea>
                </nz-textarea-count>
              </nz-form-control>
            </nz-form-item>
          </div>
        </div>
      </form>
    </section>
  </main>
</body>

<footer class="modal-footer" *nzModalFooter>
  <div class="buttons">
    <app-secondary-button (click)="modalRef.close()">Cancelar</app-secondary-button>
    <app-primary-button icon="user-add" [disabled]="!customerFormGroup.valid" (click)="editCustomer()">
      Editar Customer
    </app-primary-button>
  </div>
</footer>
