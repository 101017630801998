<section class="expanded">
  <div class="mobile m-t-1 between b-shadow w-100">
    <div class="d-flex">
      <div class="back-icon">
        <span nz-icon nzType="left" (click)="$modal.close()" nzTheme="outline"></span>
      </div>
      <div class="center m-l-0" (click)="$modal.close('filter-categories')">
        <img src="../../assets/images/hamburguer.svg" />
      </div>
    </div>

    <div class="sellerName centerBlock">Catálogo</div>

    <div class="d-flex m-l-1">
      <div>
        <img
          (click)="clickedFavorite = !clickedFavorite"
          class="favorite"
          [src]="!clickedFavorite ? imagesUrl[2] : imagesUrl[1]"
          alt="loading Payment"
        />
      </div>

      <div class="center shopping-cart" (click)="goToCart()">
        <img class="filter-img n-mt" [src]="imagesUrl[0]" />
      </div>
    </div>
  </div>

  <div class="mobile m-1">
    <form [formGroup]="form">
      <nz-form-item>
        <nz-form-control>
          <nz-input-group class="w-93" [nzPrefix]="searchTemplate">
            <input type="text" class="w-93" nz-input nzSize="large" placeholder="Pesquisar" formControlName="search" />
            <ng-template #searchTemplate>
              <span nz-icon class="search-icon" nzType="search"></span>
            </ng-template>
          </nz-input-group>
        </nz-form-control>
      </nz-form-item>
    </form>
  </div>

  <div class="between filter w-87">
    <span (click)="$modal.close()"> Ordernar por <span nz-icon nzType="down" nzTheme="outline"></span></span>
    <span (click)="$modal.close('filter')">
      Filtro <img class="filter-img" src="../../assets/images/filter.svg" />
    </span>
  </div>
  <div class="inline orderBy-list">
    <span (click)="$modal.close('relevantes')" class="{{ selectedFilter === 'relevantes' ? 'filterApplied' : '' }}">
      Mais relevantes
    </span>
    <span (click)="$modal.close('distancia')" class="{{ selectedFilter === 'distancia' ? 'filterApplied' : '' }}">
      Distância
    </span>
    <span (click)="$modal.close('avaliacao')" class="{{ selectedFilter === 'avaliacao' ? 'filterApplied' : '' }}">
      Melhor avaliação
    </span>
    <span (click)="$modal.close('menorPreco')" class="{{ selectedFilter === 'menorPreco' ? 'filterApplied' : '' }}">
      Menor preço
    </span>
    <span (click)="$modal.close('maiorPreco')" class="{{ selectedFilter === 'maiorPreco' ? 'filterApplied' : '' }}">
      Maior preço
    </span>
  </div>
</section>
