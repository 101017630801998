<section class="side_menu_page">
  <div class="header mobile" *ngIf="customer?.name">
    <span class="mobile">Olá, {{ customer.name }}</span>
  </div>

  <div class="header desktop">
    <span class="desktop">Perfil</span>
  </div>

  <div class="inline sideMenu">
    <ng-container *ngFor="let subItem of sideMenu">
      <div
        class="{{ subItem.isDesktop ? 'desktop' : 'mobile' }} {{
          subItem.haveBackground ? 'primary-color-background' : ''
        }} menu"
        (click)="onClickMenu(subItem)"
        *ngIf="(subItem.isAuth && isAuth) || (!subItem.isAuth && !isAuth)"
      >
        <img [src]="subItem.icon" class="desktop m-l-0" *ngIf="subItem.icon" />

        <span>{{ subItem.title }}</span>
      </div>
    </ng-container>
  </div>
</section>
