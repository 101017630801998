import { AbstractControl, ValidatorFn, Validators } from '@angular/forms';

export class PhoneNumberValidator {
  static isValid(): ValidatorFn {
    return (control: AbstractControl): Validators | null => {
      const phoneNumber = control.value;
      const re = /^[1-9]{2}9[1-9]{1}[0-9]{7}$/i;

      if (phoneNumber && !re.test(phoneNumber)) {
        return { format: true };
      }

      return null;
    };
  }
}
