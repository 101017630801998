import { gql } from 'apollo-angular';

const LIST_DEFAULT_BANKS_QUERY = gql`
  query defaultBanks {
    defaultBanks {
      id
      accountMask
      active
      agencyCheckNumberMask
      agencyMask
      code
      updatedAt
      name
    }
  }
`;

export { LIST_DEFAULT_BANKS_QUERY };
