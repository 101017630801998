<nz-sider
  id="coupon-sidenav"
  nzCollapsible
  nzWidth="80%"
  [nzCollapsedWidth]="0"
  [(nzCollapsed)]="!isCollapsed"
  [nzZeroTrigger]="null"
  [nzTrigger]="null"
  nzTheme="light"
  (clickOutside)="onMenuClickOutside()"
  [ngClass]="isCollapsed ? 'hide-all' : ''"
>
  <section class="header">
    <nz-row class="m-b-2">
      <nz-col nzSpan="24">
        <img src="/assets/img/logo-genshop.svg" alt="Logo da Gen branco" class="logo-image" />
      </nz-col>
    </nz-row>
    <nz-row>
      <nz-col nzSpan="24">
        <button nz-button nzType="primary" class="coupon-login-button" *ngIf="!lead.id">
          <span (click)="openLoginModal()"> Entre ou cadastre-se </span>
        </button>
        <nz-row *ngIf="lead.id">
          <div class="lead-avatar">
            <span>
              {{ lead.name[0] }}
            </span>
          </div>
          <div class="lead-name">{{ lead.name }}</div>
        </nz-row>
      </nz-col>
    </nz-row>
  </section>
  <ul nz-menu nzMode="inline" [nzInlineCollapsed]="!isCollapsed" *ngIf="lead.id">
    <li nz-menu-item (click)="goTo('/my-coupons')">
      <img src="/assets/icons/receipt-alt.svg" class="coupon-icon" alt="Imagem do cupom" />
      <span class="coupon-menu-text">Meus cupons</span>
    </li>
  </ul>
</nz-sider>
