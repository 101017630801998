<section class="bulk_edit_inventory_modal">
  <div class="header between">
    <h1 class="body-txt-bold no-margin-bottom">Editar em massa</h1>
    <span nz-icon nzType="close" nzTheme="outline" class="close-icon" (click)="modalRef.close()"></span>
  </div>
  <nz-divider></nz-divider>
  <form [formGroup]="form">
    <nz-form-item>
      <nz-form-control>
        <nz-radio-group formControlName="type">
          <label nz-radio nzValue="add" class="body-txt">Adicionar</label>
          <label nz-radio nzValue="edit" class="body-txt">Editar</label>
        </nz-radio-group>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item class="startBlock formItem">
      <label class="smaller-light">Estoque</label>
      <nz-form-control>
        <nz-input-group [nzSuffix]="closeIcon">
          <input nz-input placeholder="Quantidade em estoque" class="body-txt-light" />
        </nz-input-group>
      </nz-form-control>
    </nz-form-item>
    <nz-table [nzData]="data" nzShowPagination nzHideOnSinglePage>
      <tbody>
        <tr *ngFor="let item of data">
          <td class="wrapperName">
            <span class="d-block body-txt"> {{ item.name }} </span>
            <span class="body-txt"> {{ item.variation }} </span>
          </td>
          <td class="between quantityWrapper">
            <span class="small-bold">{{ item.available }}</span>
            <span nz-icon nzType="right" class="rightIcon"></span>
            <input nz-input value="150" class="body-txt-light" />
          </td>
        </tr>
      </tbody>
    </nz-table>
  </form>
  <div class="between buttons">
    <app-secondary-button (click)="modalRef.close()">Cancelar</app-secondary-button>
    <app-primary-button>Salvar</app-primary-button>
  </div>
</section>

<ng-template #closeIcon>
  <span nz-icon nzType="close" class="closeIcon"></span>
</ng-template>
