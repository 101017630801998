<header *nzModalTitle class="modal-header">
  <h3 class="title-category">Reembolsar Costumer</h3>
  <span class="example-spacer"></span>
  <img src="../../assets/images/close.svg" (click)="modalRef.close()" class="pointer-cursor" />
</header>

<body>
  <p>Envie o arquivo para realizar o reembolso do Costumer.</p>
  <div class="document">
    <ng-container *ngIf="!isShowFileName">
      <nz-upload
        id="upload--document"
        nzType="drag"
        (nzChange)="handleChange($event)"
        [nzCustomRequest]="customRequest"
        nzAccept=".xls, .xlsx, .csv"
      >
        <button nz-button>
          <div class="content">
            <img src="/assets/images/icons/upload-image-icon.svg" alt="Ícone para fazer upload do documento" />
            <p>Add Document</p>
          </div>

          <img src="/assets/images/icons/upload-file-grey.svg" alt="Ícone para fazer upload do documento" />
        </button>
      </nz-upload>
    </ng-container>

    <ng-container *ngIf="isShowFileName">
      <div class="between upload-card">
        <div class="content">
          <img src="/assets/images/icons/upload-image-icon.svg" alt="Ícone para fazer upload do documento" />
          <div class="text">
            <p>Add Document</p>
            <span>
              {{ file?.name }}
            </span>
          </div>
        </div>
        <img src="/assets/images/icons/upload-file-grey.svg" alt="Ícone para fazer upload do documento" />
      </div>
    </ng-container>
  </div>
</body>

<footer class="modal-footer" *nzModalFooter>
  <div class="buttons">
    <app-secondary-button type="DARK-PURPLE" (click)="closed(false)">Cancelar</app-secondary-button>
    <app-primary-button class="m-r-1" type="DARK-PURPLE" (click)="closed(true)">Enviar arquivo</app-primary-button>
  </div>
</footer>
