<section>
  <div class="steps" nz-row>
    <div *ngFor="let step of steps; let i = index" nz-col class="step" [nzSpan]="24 / steps.length">
      <p>
        <span>{{ i + 1 }}</span> &nbsp;{{ step }}
      </p>
      <div *ngIf="i <= completionIndex" class="bar full"></div>
      <div *ngIf="i > completionIndex" class="bar empty"></div>
    </div>
  </div>
</section>
