<section class="delete_key_modal">
  <app-modal-delete-key-confirmation
    *ngIf="activeModal === 'modal-delete-key-confirmation'"
    [key]="key"
    [type]="type"
    [sellerId]="sellerId"
    (delete)="handleModalActive($event)"
  ></app-modal-delete-key-confirmation>
  <app-modal-delete-key-success *ngIf="activeModal === 'modal-delete-key-success'" [key]="key" [type]="type">
  </app-modal-delete-key-success>
</section>
