import { gql } from 'apollo-angular';

const CREATE_COUPON_FIDELITY_MUTATION = gql`
  mutation CreateCouponFidelity(
    $code: String!
    $name: String!
    $description: String!
    $start_date: DateTime!
    $expiration_date: DateTime
    $value: Int!
    $amount: Int
    $buy_value_min: Int
    $buy_value_max: Int
    $user_usage_limit: Int
    $typeDiscount: CouponTypesEnum!
    $status: CouponStatusEnum!
    $coupon_image: String
    $seller: CreateSellerInput!
    $cumulative: Boolean
  ) {
    createCouponFidelity(
      code: $code
      name: $name
      description: $description
      start_date: $start_date
      expiration_date: $expiration_date
      value: $value
      amount: $amount
      buy_value_min: $buy_value_min
      buy_value_max: $buy_value_max
      user_usage_limit: $user_usage_limit
      typeDiscount: $typeDiscount
      status: $status
      coupon_image: $coupon_image
      seller: $seller
      cumulative: $cumulative
    ) {
      id
    }
  }
`;

const UPDATE_COUPON_FIDELITY_MUTATION = gql`
  mutation UpdateCouponFidelity(
    $id: String!
    $amount: Int
    $buy_value_min: Int
    $description: String
    $expiration_date: DateTime
    $status: CouponStatusEnum
    $coupon_image: String
    $appView: Boolean
    $categoryId: String
    $couponTypeId: String
    $cumulative: Boolean
  ) {
    updateCouponFidelity(
      couponId: $id
      amount: $amount
      buy_value_min: $buy_value_min
      description: $description
      expiration_date: $expiration_date
      status: $status
      coupon_image: $coupon_image
      appView: $appView
      categoryId: $categoryId
      couponTypeId: $couponTypeId
      cumulative: $cumulative
    ) {
      id
    }
  }
`;

const DELETE_COUPON_FIDELITY_MUTATION = gql`
  mutation DeleteCouponFidelity($couponId: String!) {
    deleteCouponFidelity(couponId: $couponId) {
      id
    }
  }
`;

const REDEEM_COUPON_BY_SELLER_MUTATION = gql`
  mutation RedeemCouponBySeller(
    $couponId: String!
    $sellerId: String!
    $buyValueSeller: Int!
    $dateBuySeller: DateTime!
    $phoneCustomer: String!
    $rescuedId: String!
    $customerId: String
    $leadId: String
  ) {
    redeemCouponBySeller(
      couponId: $couponId
      sellerId: $sellerId
      buyValueSeller: $buyValueSeller
      dateBuySeller: $dateBuySeller
      phoneCustomer: $phoneCustomer
      rescuedId: $rescuedId
      customerId: $customerId
      leadId: $leadId
    ) {
      id
    }
  }
`;

const ASSOC_DATA_TO_COUPON_MUTATION = gql`
  mutation InsertCategoryAndTypeCoupon(
    $couponId: String!
    $categoryId: String!
    $couponTypeId: String!
    $expirateDate: DateTime!
    $appView: Boolean!
    $userGen: String!
    $mallId: Int
  ) {
    insertCategoryAndTypeCoupon(
      couponId: $couponId
      categoryId: $categoryId
      couponTypeId: $couponTypeId
      expirateDate: $expirateDate
      appView: $appView
      userGen: $userGen
      mallId: $mallId
    ) {
      id
    }
  }
`;

const CREATE_CATEGORY_COUPON_MUTATION = gql`
  mutation CreateCategoriesCoupon($name: String!, $status: String!) {
    createCategoriesCoupon(name: $name, status: $status) {
      id
    }
  }
`;

const UPDATE_CATEGORY_COUPON_MUTATION = gql`
  mutation UpdateCategorieCoupon($category: CreateCategoriesInputs!) {
    updateCategorieCoupon(category: $category) {
      id
    }
  }
`;

const DELETE_CATEGORY_COUPON_MUTATION = gql`
  mutation RemoveCategorieCoupon($categoryId: String!) {
    removeCategorieCoupon(categoryId: $categoryId) {
      id
    }
  }
`;

const CREATE_TYPE_COUPON_MUTATION = gql`
  mutation CreateCouponType($name: String!, $status: String!) {
    createCouponType(name: $name, status: $status) {
      id
    }
  }
`;

const UPDATE_TYPE_COUPON_MUTATION = gql`
  mutation UpdateCouponType($couponType: CreateCouponTypeInput!) {
    updateCouponType(couponType: $couponType) {
      id
    }
  }
`;

const DELETE_TYPE_COUPON_MUTATION = gql`
  mutation RemoveCouponType($couponTypeId: String!) {
    removeCouponType(couponTypeId: $couponTypeId) {
      id
    }
  }
`;

const LIKE_COUPON_MUTATION = gql`
  mutation AddCustomerLikeCoupon($couponId: String!, $customer: CreateCustomerInput!) {
    addCustomerLikeCoupon(couponId: $couponId, customer: $customer)
  }
`;

const UNLIKE_COUPON_MUTATION = gql`
  mutation RemoveCustomerLikeCoupon($couponId: String!, $customerId: String!) {
    removeCustomerLikeCoupon(couponId: $couponId, customerId: $customerId)
  }
`;

const DELETE_PARKING_ACCOUNT_MUTATION = gql`
  mutation DeleteParkingAccount($id: String!) {
    deleteParkingAccount(id: $id) {
      id
    }
  }
`;

const DELETE_PARKING_USER_MUTATION = gql`
  mutation DeleteParkingUser($id: String!) {
    deleteParkingUser(id: $id) {
      id
    }
  }
`;

const DELETE_PARKING_CAMPAIGN_MUTATION = gql`
  mutation DeleteParkingCampaign($id: String!) {
    deleteParkingCampaign(id: $id) {
      id
    }
  }
`;

const CREATE_PARKING_CAMPAIGN_MUTATION = gql`
  mutation CreateParkingCampaign($payload: CreateParkingCampaignParamsInput!) {
    createParkingCampaign(payload: $payload) {
      id
    }
  }
`;

const UPDATE_PARKING_CAMPAIGN_MUTATION = gql`
  mutation UpdateParkingCampaign($id: String!, $payload: UpdateParkingCampaignParamsInput!) {
    updateParkingCampaign(id: $id, payload: $payload) {
      id
    }
  }
`;

const CREATE_PARKING_ACCOUNT_MUTATION = gql`
  mutation CreateParkingAccount($params: ParkingAccountCreateParamsInput!) {
    createParkingAccount(params: $params) {
      id
      sellerId
      mallId
    }
  }
`;

const UPDATE_PARKING_ACCOUNT_MUTATION = gql`
  mutation UpdateParkingAccount($id: String!, $params: ParkingAccountUpdateParamsInput!) {
    updateParkingAccount(id: $id, params: $params) {
      id
    }
  }
`;

const UPDATE_PARKING_TABLE_MUTATION = gql`
  mutation UpdateParkingTable($mallId: String!, $params: [ParkingTablePriceParamsInput!]!) {
    updateParkingTable(mallId: $mallId, params: $params) {
      id
      time
      amount
    }
  }
`;

export {
  UPDATE_PARKING_TABLE_MUTATION,
  CREATE_PARKING_CAMPAIGN_MUTATION,
  UPDATE_PARKING_CAMPAIGN_MUTATION,
  DELETE_PARKING_USER_MUTATION,
  DELETE_PARKING_CAMPAIGN_MUTATION,
  DELETE_PARKING_ACCOUNT_MUTATION,
  CREATE_PARKING_ACCOUNT_MUTATION,
  UPDATE_PARKING_ACCOUNT_MUTATION,
  LIKE_COUPON_MUTATION,
  UNLIKE_COUPON_MUTATION,
  CREATE_TYPE_COUPON_MUTATION,
  UPDATE_TYPE_COUPON_MUTATION,
  DELETE_TYPE_COUPON_MUTATION,
  ASSOC_DATA_TO_COUPON_MUTATION,
  CREATE_COUPON_FIDELITY_MUTATION,
  UPDATE_COUPON_FIDELITY_MUTATION,
  DELETE_COUPON_FIDELITY_MUTATION,
  CREATE_CATEGORY_COUPON_MUTATION,
  UPDATE_CATEGORY_COUPON_MUTATION,
  DELETE_CATEGORY_COUPON_MUTATION,
  REDEEM_COUPON_BY_SELLER_MUTATION,
};
