<section class="signInCodeVerifyPage">
  <p class="subtitle">
    Enviamos um código de verificação para
    {{ login.validationMethod === 'EMAIL' ? (login.email | hidePartialEmail) : (login.phoneNumber | hidePartialPhone) }}
    Digite o código recebido para validar seu {{ login.validationMethod === 'EMAIL' ? 'email' : 'numero' }} e ter acesso
    a Plataforma Gen Business.
  </p>
  <div id="phoneCode">
    <form [formGroup]="formGroupCodeVerify" (keydown.enter)="$event.preventDefault()">
      <div class="input-content">
        <nz-form-item>
          <nz-form-control nzErrorTip="Código inválido." [nzSpan]="4">
            <input
              #input
              required
              nz-input
              formControlName="code1"
              type="tel"
              id="code1"
              autocomplete="off"
              mask="0"
              (input)="onInput($event, 1)"
              (paste)="pasteNumeric($event)"
            />
          </nz-form-control>
        </nz-form-item>
        <nz-form-item>
          <nz-form-control [nzSpan]="4">
            <input
              #input
              required
              nz-input
              formControlName="code2"
              type="tel"
              id="code2"
              autocomplete="off"
              mask="0"
              (input)="onInput($event, 2)"
              (keyup)="onPressDeleteKey($event, 1)"
            />
          </nz-form-control>
        </nz-form-item>
        <nz-form-item>
          <nz-form-control [nzSpan]="4">
            <input
              #input
              required
              nz-input
              formControlName="code3"
              type="tel"
              id="code3"
              autocomplete="off"
              mask="0"
              (input)="onInput($event, 3)"
              (keyup)="onPressDeleteKey($event, 2)"
            />
          </nz-form-control>
        </nz-form-item>
        <nz-form-item>
          <nz-form-control [nzSpan]="4">
            <input
              #input
              required
              nz-input
              formControlName="code4"
              type="tel"
              id="code4"
              autocomplete="off"
              mask="0"
              (input)="onInput($event, 4)"
              (keyup)="onPressDeleteKey($event, 3)"
            />
          </nz-form-control>
        </nz-form-item>
        <nz-form-item>
          <nz-form-control [nzSpan]="4">
            <input
              #input
              required
              nz-input
              formControlName="code5"
              type="tel"
              id="code5"
              autocomplete="off"
              mask="0"
              (input)="onInput($event, 5)"
              (keyup)="onPressDeleteKey($event, 4)"
            />
          </nz-form-control>
        </nz-form-item>
        <nz-form-item>
          <nz-form-control [nzSpan]="4">
            <input
              #input
              required
              nz-input
              formControlName="code6"
              type="tel"
              id="code6"
              autocomplete="off"
              mask="0"
              (keyup)="onPressDeleteKey($event, 5)"
            />
          </nz-form-control>
        </nz-form-item>
      </div>

      <div class="m-t-2 center">
        <app-primary-button
          [loading]="loadingCodeBtn"
          [block]="true"
          [disabled]="formGroupCodeVerify.invalid || loadingCodeBtn"
          (click)="validatePhoneNumberCode()"
          class="center w-100"
          type="GREEN"
          >Confirmar código</app-primary-button
        >
      </div>

      <div class="m-t-1">
        <app-secondary-button
          [block]="true"
          [disabled]="countdown > 0 || loadingCodeBtn"
          [loading]="loadingCodeBtn"
          (click)="resendVerificationCode()"
          class="center w-100"
        >
          Enviar novamente
          <span class="m-l-0" *ngIf="countdown > 0">({{ countdown }}s)</span>
        </app-secondary-button>
      </div>
    </form>
  </div>
</section>
