import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { Apollo, Query } from 'apollo-angular';

import { LIST_DIGITAL_STATEMENT_QUERY, LIST_EXTERNAL_STATEMENT_QUERY } from '../../graphql/queries/balance.queries';
import { CREATE_WITHDRAW_MUTATION } from '../../graphql/mutations/withdrawals.mutations';
import { FilterWithdrawsParams } from '../../models/withdrawls/filter-withdraws-params.model';
import { LIST_SELLER_WITHDRAWS_QUERY } from '../../graphql/queries/withdrawals.queries';

@Injectable()
export class BalanceService {
  constructor(private readonly apollo: Apollo) {}

  public getDigitalBalance(data: { initDate: string; endDate: string; sellerId: string }): Observable<any> {
    return this.apollo.watchQuery<Query>({
      query: LIST_DIGITAL_STATEMENT_QUERY,
      variables: { sellerId: data.sellerId, initDate: data.initDate, endDate: data.endDate },
      fetchPolicy: 'cache-and-network',
    }).valueChanges;
  }

  public getExternalBalance(data: { initDate: string; endDate: string; sellerId: string }): Observable<any> {
    return this.apollo.watchQuery<Query>({
      query: LIST_EXTERNAL_STATEMENT_QUERY,
      variables: { sellerId: data.sellerId, initDate: data.initDate, endDate: data.endDate },
      fetchPolicy: 'cache-and-network',
    }).valueChanges;
  }

  public createWithdrawl(amountCents: number, sellerId: string): Observable<any> {
    return this.apollo.mutate({
      mutation: CREATE_WITHDRAW_MUTATION,
      variables: { amountCents, sellerId },
    });
  }

  public getWithdrawsList(sellerId: string, filter: FilterWithdrawsParams): Observable<any> {
    return this.apollo.watchQuery<Query>({
      query: LIST_SELLER_WITHDRAWS_QUERY,
      variables: { sellerId, filter },
      fetchPolicy: 'cache-and-network',
    }).valueChanges;
  }
}
