import { Component } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { NzModalRef } from 'ng-zorro-antd/modal';

@Component({
  selector: 'app-modal-bulk-edit-inventory',
  templateUrl: './modal-bulk-edit-inventory.component.html',
  styleUrls: ['./modal-bulk-edit-inventory.component.scss'],
})
export class ModalBulkEditInventoryComponent {
  public form: FormGroup;
  public data: Array<any> = [
    {
      name: 'Tênis Adidas',
      variation: 'Preto 36',
      reserved: 100,
      available: 100,
      checked: false,
      image: '../../../../assets/images/icons/badge-check.svg',
    },
    {
      name: 'Tênis Adidas',
      variation: 'Preto 36',
      reserved: 100,
      available: 100,
      checked: false,
      image: '../../../../assets/images/icons/badge-check.svg',
    },
    {
      name: 'Tênis Adidas',
      variation: 'Preto 36',
      reserved: 100,
      available: 100,
      checked: false,
      image: '../../../../assets/images/icons/badge-check.svg',
    },
    {
      name: 'Tênis Adidas',
      variation: 'Preto 36',
      reserved: 100,
      available: 100,
      checked: false,
      image: '../../../../assets/images/icons/badge-check.svg',
    },
  ];

  constructor(public modalRef: NzModalRef, private fb: FormBuilder) {}

  public createForm(): void {
    this.form = this.fb.group({
      type: '',
    });
  }
}
