<section>
  <main>
    <div class="dates">
      <div class="creation-date">
        <p>Data de criação</p>
        <span>{{ data.infos?.insertedAt | date : 'dd/MM/yyyy' || '-' }}</span>
      </div>

      <div *ngIf="data.type === 'edit'" class="edition-date">
        <p>Data de edição</p>
        <span>{{ data.infos?.updatedAt | date : 'dd/MM/yyyy' || '-' }}</span>
      </div>
    </div>

    <form class="m-t-1" [formGroup]="formDescription">
      <div *ngIf="data.type === 'add'">
        <label>Tipo</label>
        <nz-radio-group formControlName="kindOfBlacklist">
          <label nz-radio nzValue="ESTABLISHMENT">Estabelecimento</label>
          <label nz-radio nzValue="USER">Usuário</label>
        </nz-radio-group>
      </div>

      <nz-form-item class="m-t-2">
        <label>Nome</label>
        <nz-form-control nzErrorTip="Preenchimento incorreto / sem preenchimento">
          <input nz-input formControlName="name" placeholder="Nome do estabelecimento/usuário" />
        </nz-form-control>
      </nz-form-item>

      <nz-form-item>
        <label>ID</label>
        <nz-form-control nzErrorTip="Preenchimento incorreto / sem preenchimento">
          <input nz-input formControlName="id" placeholder="O id será inserido automaticamente." />
        </nz-form-control>
      </nz-form-item>

      <div class="row">
        <nz-form-item>
          <label>Telefone</label>
          <nz-form-control nzErrorTip="Preenchimento incorreto / sem preenchimento">
            <input nz-input formControlName="telephone" placeholder="(00) 00000-0000" mask="(00) 00000-0000" />
          </nz-form-control>
        </nz-form-item>

        <nz-form-item>
          <label>E-mail</label>
          <nz-form-control nzErrorTip="Preenchimento incorreto / sem preenchimento">
            <input nz-input formControlName="email" placeholder="exemplo@exemplo.com" />
          </nz-form-control>
        </nz-form-item>
      </div>

      <div class="row">
        <nz-form-item>
          <label>CPF</label>
          <nz-form-control nzErrorTip="Preenchimento incorreto / sem preenchimento">
            <input nz-input formControlName="personalDocument" placeholder="000.000.000-00" mask="000.000.000-00" />
          </nz-form-control>
        </nz-form-item>

        <nz-form-item>
          <label>CNPJ</label>
          <nz-form-control nzErrorTip="Preenchimento incorreto / sem preenchimento">
            <input
              nz-input
              formControlName="businessDocument"
              placeholder="00.000.000/0000-00"
              mask="00.000.000/0000-00"
            />
          </nz-form-control>
        </nz-form-item>
      </div>

      <div class="m-t-2">
        <app-primary-button
          [block]="true"
          [icon]="data.type === 'add' ? 'stop' : 'edit'"
          (click)="getFormValues()"
          [disabled]="formDescription.invalid"
          >{{ data.type === 'add' ? 'Bloquear' : 'Editar' }}</app-primary-button
        >
      </div>
    </form>
  </main>
</section>
