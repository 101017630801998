<nz-header>
  <div class="d-flex between h-100 w-100">
    <h3>{{ title }}</h3>
    <div class="actions-div w-20">
      <img
        src="/assets/images/user-plus.svg"
        routerLink="/internal/profile/my-staff"
        alt="user-plus"
        class="pointer-cursor"
      />

      <nz-badge>
        <button
          class="button-content"
          nz-popover
          [nzPopoverTitle]="titleNotificationTemplate"
          [nzPopoverContent]="notificationTemplate"
          nzPopoverPlacement="bottomRight"
          nzPopoverTrigger="click"
          nzPopoverOverlayClassName="notificationTemplate"
          [(nzPopoverVisible)]="isPopoverNotificationVisible"
        >
          <a *ngIf="unRead.length" class="head-example"></a>
          <img src="/assets/images/notification.svg" alt="notification" class="pointer-cursor" />
        </button>
      </nz-badge>

      <ng-template #titleNotificationTemplate>
        <div class="title__popOver d-flex startBlock">
          <span> Notificações </span>
          <button class="button-content m-r-1" *ngIf="unRead?.length > 0 || read?.length > 0" (click)="markAllAsRead()">
            Marcar todas como lidas
          </button>
        </div>
      </ng-template>

      <ng-template #notificationTemplate>
        <ng-container *ngIf="unRead?.length > 0; else noNotifications">
          <div class="notification-content notification-content__unRead" *ngFor="let unReads of unReadLimited">
            <img
              class="notification-icon"
              src="../../assets/images/icons/circle-dollar.svg"
              alt="Dolar"
              *ngIf="unReads.action === 'payment'"
            />
            <div class="notification-icon notification-icon__subscription" *ngIf="unReads.action === 'subscription'">
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M8 12.3333L10.4615 15L16 9M7.80039 4.63683C8.52384 4.57909 9.21064 4.29461 9.76302 3.82388C11.0521 2.72537 12.9479 2.72537 14.237 3.82388C14.7894 4.29461 15.4762 4.57909 16.1996 4.63683C17.8878 4.77155 19.2285 6.11215 19.3632 7.80039C19.4209 8.52384 19.7054 9.21064 20.1761 9.76302C21.2746 11.0521 21.2746 12.9479 20.1761 14.237C19.7054 14.7894 19.4209 15.4762 19.3632 16.1996C19.2285 17.8878 17.8878 19.2285 16.1996 19.3632C15.4762 19.4209 14.7894 19.7054 14.237 20.1761C12.9479 21.2746 11.0521 21.2746 9.76302 20.1761C9.21064 19.7054 8.52384 19.4209 7.80039 19.3632C6.11215 19.2285 4.77155 17.8878 4.63683 16.1996C4.57909 15.4762 4.29461 14.7894 3.82388 14.237C2.72537 12.9479 2.72537 11.0521 3.82388 9.76302C4.29461 9.21064 4.57909 8.52384 4.63683 7.80039C4.77155 6.11215 6.11215 4.77155 7.80039 4.63683Z"
                  stroke="#0B6C97"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </div>
            <img
              class="notification-icon notification-icon__withdraw"
              src="../../assets/images/icons/money-withdrawal.svg"
              alt="Saque"
              *ngIf="unReads.action === 'withdraw-waiting'"
            />

            <span class="description__popOver d-block">{{ unReads.description || '-' }}</span>
          </div>
        </ng-container>

        <div class="footer center">
          <a routerLink="/internal/profile/notifications"> Ver todas as notificações </a>
        </div>
      </ng-template>

      <ng-template #noNotifications>
        <span class="no-notifications">Você não possui nenhuma nova notificação no momento.</span>
        <nz-divider></nz-divider>
      </ng-template>

      <div class="pointer-cursor d-flex">
        <a nz-dropdown nzTrigger="hover" [nzDropdownMenu]="menu">
          <nz-avatar [nzSize]="40" nzIcon="user" [nzSrc]="user.avatar" class="pointer-cursor"></nz-avatar>
        </a>
        <nz-dropdown-menu #menu="nzDropdownMenu">
          <ul nz-menu>
            <li nz-menu-item routerLink="/internal/profile">Olá, {{ user.name }}</li>
            <li nz-menu-item routerLink="/internal/profile/personal-data">Dados Pessoais</li>
            <li nz-menu-item routerLink="/internal/profile/my-staff">Minha equipe</li>
            <li nz-menu-item routerLink="/internal/profile/settings">Configurações</li>

            <li nz-menu-item class="negative" (click)="logout()">
              <img src="/assets/images/icons/logout.svg" alt="Ícone de lixeira." />
              Sair
            </li>
          </ul>
        </nz-dropdown-menu>

        <img src="/assets/images/chevron-down.svg" class="m-l-0" alt="flecha baixo" routerLink="/internal/profile" />
      </div>
    </div>
  </div>
</nz-header>
