import { gql } from 'apollo-angular';

const PAYMENT_FIELDS = `
  acquirerPayment
  additionCents
  antifraud
  bankSlip {
    acquirerNumber
    bank
    bankSlipId
    barcode
    expirationDate
    instructions
    issueDate
    printLinkPdf
    printLinkHtml
    typefulLine
  }
  credit {
    authorizedAt
    acquirerTransactionId
    authorizationCode
    creditCardId
    dynamicMcc
    saveCard
    terminalNsu
    transactionId
    transactionType
    creditCard {
      holderName
      last4
      brand
    }
    publicCreditCard {
      brand
      last4
      expirationMonth
      expirationYear
    }
    delayCapture
    installments
    reasonCode
    reasonMessage
  }
  customer {
    id
    name
    cpf
  }
  device
  discountCents
  fees {
    id
    name
    insertedAt
    feePercent
    feeAmountCents
  }
  history
  id
  insertedAt
  location {
    latitude
    longitude
  }
  method
  order {
    items {
      description
      quantity
      unitPriceCents
    }
  }
  pix {
    acquirerTransactionId
    brcode
    expiration
  }
  seller {
    id
    person {
      name
    }
    company {
      businessName
    }
  }
  shippingCents
  status
  subtotalCents
  totalCents
`;

const LIST_PAYMENTS_QUERY = gql`
  query ListPayments($filter: FilterPaymentsParams) {
    listPayments(filters: $filter, pagination: { orderBy: "inserted_at", sortingOrder: DESC, perPage: 500 }) {
      ${PAYMENT_FIELDS}
    }
  }
`;

const PAYMENTS_QUERY = gql`
  query Payments($filters: FilterPaymentParamsInput, $pagination: PaginationParams) {
    paymentsV2(filters: $filters, pagination: $pagination) {
      pageSize
      page
      totalPages
      total
      results {
        status
        id
        method
        totalCents
        insertedAt
        customers {
          id
          name
        }
        sellers {
          company {
            businessName
          }
          person {
            name
          }
        }
      }
    }
  }
`;

const GET_PAYMENT_QUERY = gql`
  query GetPayment($id: UUID4!) {
    getPayment(id: $id) {
      ${PAYMENT_FIELDS}
    }
  }
`;

const GET_PAYMENT_CHECKOUT_QUERY = gql`
  query GetPayment($id: UUID4!) {
    getPayment(id: $id) {
      id
      method
      confirmedAt
      totalCents
      status
      insertedAt
      updatedAt
      credit {
        installments
        publicCreditCard {
          brand
          last4
          expirationMonth
          expirationYear
        }
      }
      pix {
        brcode
        expiration
      }
      bankSlip {
        bank
        barcode
        expirationDate
        printLinkPdf
      }
    }
  }
`;

const LIST_SELLER_PAYMENTS_QUERY = gql`
  query ListSellerPayments($sellerId: UUID4!, $filters: FilterSellerPaymentsParams) {
    listSellerPayments(
      sellerId: $sellerId
      filters: $filters
      pagination: { orderBy: "inserted_at", sortingOrder: DESC, perPage: 100 }
    ) {
      publicCustomer {
        name
      }
      order {
        id
        status
      }
      id
      insertedAt
      method
      status
      totalCents
    }
  }
`;

const GET_SELLER_PAYMENT_QUERY = gql`
  query GetPayment($id: UUID4!) {
    getPayment(id: $id) {
      additionCents
      bankSlip {
        bank
        barcode
        expirationDate
        printLinkPdf
        typefulLine
        printLinkHtml
      }
      seller {
        id
      }
      credit {
        creditCard {
          holderName
          last4
          brand
          expirationYear
          expirationMonth
          billingAddress {
            countryCode
          }
        }
        publicCreditCard {
          brand
          last4
          expirationMonth
          expirationYear
        }
        installments
      }
      order {
        id
        methods
      }
      publicCustomer {
        id
        name
        cpf
      }
      device
      discountCents
      feeAmountCents
      id
      insertedAt
      confirmedAt
      updatedAt
      method
      pix {
        acquirerTransactionId
        brcode
        expiration
      }
      shippingCents
      status
      subtotalCents
      totalCents
      bnplCheckout {
        numberOfInstallments
        updatedAt
      }
    }
  }
`;

export {
  GET_PAYMENT_QUERY,
  LIST_PAYMENTS_QUERY,
  GET_SELLER_PAYMENT_QUERY,
  LIST_SELLER_PAYMENTS_QUERY,
  GET_PAYMENT_CHECKOUT_QUERY,
  PAYMENTS_QUERY,
};
