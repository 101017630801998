import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-list-category-or-type-coupons',
  templateUrl: './list-category-or-type-coupons.component.html',
  styleUrls: ['./list-category-or-type-coupons.component.scss'],
})
export class ListCategoryOrTypeCouponsComponent {
  @Input() public data: any;
  @Input() public type: string;
  @Output() public actionBtn = new EventEmitter();

  public callToAction(action: string, data?: any): void {
    this.actionBtn.emit({ action, data, type: this.type });
  }
}
