export function convertCentToCoin(input: string): string {
  input = Number(input?.replace(',', ''))?.toString()?.padStart(3, '0');

  const centsIndex: number = input?.length - 2;
  const thousandIndex: number = input?.length - 5;
  const millionIndex: number = input?.length - 8;

  if (millionIndex > 0) {
    input =
      input.substring(0, millionIndex) +
      '.' +
      input?.substring(millionIndex, thousandIndex) +
      '.' +
      input?.substring(thousandIndex, centsIndex) +
      ',' +
      input?.substring(centsIndex);
  } else if (thousandIndex > 0) {
    input =
      input?.substring(0, thousandIndex) +
      '.' +
      input?.substring(thousandIndex, centsIndex) +
      ',' +
      input?.substring(centsIndex);
  } else {
    input = input?.substring(0, centsIndex) + ',' + input?.substring(centsIndex);
  }

  return input;
}

export function getCentsFromText(oldValue: string, newValue?: string): number {
  const newNumValue: number = Number(newValue?.replace(/\D/g, ''));
  const oldNumValue: number = oldValue ? Number(oldValue?.replace(/\D/g, '')) : 0;
  return !isNaN(newNumValue) ? newNumValue : oldNumValue;
}
