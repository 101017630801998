import { Component, forwardRef, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-input-open-select',
  templateUrl: './input-open-select.component.html',
  styleUrls: ['./input-open-select.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputOpenSelectComponent),
      multi: true,
    },
  ],
})
export class InputOpenSelectComponent implements ControlValueAccessor {
  @Input() public options: string[];
  @Input() public placeHolder: string;

  public value: string;
  public changed: (value: string) => void = () => {};
  public touched: () => void;
  public isDisabled: boolean;

  constructor() {}

  public writeValue(value: any): void {
    this.value = value;
  }

  public registerOnChange(fn: any): void {
    this.changed = fn;
  }

  public registerOnTouched(fn: any): void {
    this.touched = fn;
  }

  public setDisabledState?(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  public selectOption(option: string): void {
    this.value = option;
    this.changed(option);
  }
}
