import { gql } from 'apollo-angular';

const CREATE_BOX_OFFICE_MUTATION = gql`
  mutation CreateBoxOffice($params: CreateBoxOfficeInput!) {
    createBoxOffice(params: $params) {
      id
    }
  }
`;

const UPDATE_BOX_OFFICE_MUTATION = gql`
  mutation UpdateBoxOffice($id: String!, $params: CreateBoxOfficeInput!) {
    updateBoxOffice(id: $id, params: $params) {
      id
    }
  }
`;

const DELETE_BOX_OFFICE_MUTATION = gql`
  mutation DeleteBoxOffice($id: String!) {
    deleteBoxOffice(id: $id) {
      id
    }
  }
`;

const DELETE_BOX_OFFICE_SESSION_MUTATION = gql`
  mutation DeleteBoxOfficeSession($id: String!) {
    deleteBoxOfficeSession(id: $id) {
      id
    }
  }
`;

const DELETE_BOX_OFFICE_BANNER_MUTATION = gql`
  mutation DeleteBoxOfficeBanner($id: String!) {
    deleteBoxOfficeBanner(id: $id) {
      id
    }
  }
`;

const DELETE_RULE_DOCUMENT_MUTATION = gql`
  mutation DeleteRuleDocument($id: String!) {
    deleteRuleDocument(id: $id) {
      id
    }
  }
`;

const DELETE_BOX_OFFICE_TICKET_MUTATION = gql`
  mutation DeleteBoxOfficeTicket($id: String!) {
    deleteBoxOfficeTicket(id: $id) {
      id
    }
  }
`;

export {
  CREATE_BOX_OFFICE_MUTATION,
  UPDATE_BOX_OFFICE_MUTATION,
  DELETE_BOX_OFFICE_MUTATION,
  DELETE_BOX_OFFICE_SESSION_MUTATION,
  DELETE_BOX_OFFICE_BANNER_MUTATION,
  DELETE_RULE_DOCUMENT_MUTATION,
  DELETE_BOX_OFFICE_TICKET_MUTATION,
};
