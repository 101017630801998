import { TicketStatus } from '../../enums/ticket-status.enum';

export class TicketModel {
  public ticket: string; ///  id do ticket
  public entradaDatahora: string; // entrada
  public validadeDatahora: string;
  public valorTotal: string; // tarifa - valor
  public valorPago: string; // tarifa - pago
  public valorAdquirente: string;
  public transacao: string;
  public transacaoValidadeDatahora: string; // horario que foi pago
  public tempoLimitePermanencia: string;
  public isPaid: boolean;
  public ticketHasUsed: boolean;
  public typeParkingCampaign: string;
  public analise: AnaliseType;
  public historico: TicketHistory[];
  public status: TicketStatusObject;
}

export type AnaliseType = {
  message: string;
  valorDevido: string;
  teste: TicketStatus;
};

export type TicketHistory = {
  _id: string;
  ticket: string;
  pagoEm: string;
  valorPago: string;
  saidaAte: string;
};

export type TicketStatusObject = {
  status: string;
  cod: number;
};
