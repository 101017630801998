import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NzModalRef } from 'ng-zorro-antd/modal';

@Component({
  selector: 'app-confirmation-delete',
  templateUrl: './confirmation-delete.component.html',
  styleUrls: ['./confirmation-delete.component.scss'],
})
export class ConfirmationDeleteComponent implements OnInit {
  @Input() message: string;
  @Input() isImportant: boolean;
  @Input() buttonName: string;
  @Input() isDeleteAction: boolean = true;
  @Input() requestReason: boolean = false;

  public icon: string = 'close-circle';

  public formReason: FormGroup;

  constructor(public $modalRef: NzModalRef, private fb: FormBuilder) {}

  public ngOnInit(): void {
    this.icon = this.isDeleteAction ? 'close-circle' : 'check-circle';

    this.createForm();
  }

  public createForm(): void {
    if (this.requestReason) {
      this.formReason = this.fb.group({
        reason: new FormControl(null, [Validators.required]),
      });
    }
  }
}
