import { gql } from 'apollo-angular';

const CREATE_PIX_KEY = gql`
  mutation CreatePixKey($params: PixKeyParams!, $sellerId: UUID4!) {
    createPixKey(params: $params, sellerId: $sellerId) {
      key
    }
  }
`;

const DELETE_PIX_KEY = gql`
  mutation DeletePixKey($key: String!, $sellerId: UUID4!) {
    deletePixKey(key: $key, sellerId: $sellerId)
  }
`;

const REVERSE_PIX = gql`
  mutation ReversePix($amountCents: Int!, $description: String, $id: UUID4!) {
    reversePix(amountCents: $amountCents, description: $description, id: $id) {
      id
    }
  }
`;

const CREATE_STATIC_PIX_CODE = gql`
  mutation CreateStaticPixCode(
    $amountCents: Int!
    $description: String
    $key: String!
    $transactionIdentification: String
    $sellerId: UUID4!
  ) {
    createStaticPixCode(
      params: {
        amountCents: $amountCents
        description: $description
        key: $key
        transactionIdentification: $transactionIdentification
      }
      sellerId: $sellerId
    )
  }
`;

export { CREATE_PIX_KEY, DELETE_PIX_KEY, REVERSE_PIX, CREATE_STATIC_PIX_CODE };
