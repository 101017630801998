<section class="image_cropper">
  <div>
    <img
      class="cropper-grid"
      src="/assets/icons/img-grid.svg"
      [style.width.px]="cropperSize?.width || minCropperSize?.width"
      [style.height.px]="cropperSize?.height || minCropperSize?.height"
    />
    <div class="image-container" #cropper>
      <div
        #boundary
        class="image-boundary"
        [style.width.px]="
          this.image?.nativeElement?.width +
          this.image?.nativeElement?.width -
          (cropperSize?.width || minCropperSize?.width)
        "
        [style.height.px]="
          this.image?.nativeElement?.height +
          this.image?.nativeElement?.height -
          (cropperSize?.height || minCropperSize?.height)
        "
      >
        <img #img [src]="imgUrl" cdkDrag (cdkDragReleased)="saveCrop()" [cdkDragBoundary]="boundary" />
      </div>
    </div>
    <div class="slider d-flex">
      <img src="/assets/icons/minus-img.svg" />
      <nz-slider [(ngModel)]="zoom" (ngModelChange)="zoomChange()"></nz-slider>
      <img src="/assets/icons/plus-img.svg" />
    </div>
  </div>
</section>
