import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'fromNow',
})
export class FromNowPipe implements PipeTransform {
  transform(value: any, ...args: string[]): string {
    const duration = moment.duration(moment().diff(moment(args[0])));

    if (duration.isValid()) {
      return `${this.pad(duration.hours())}:${this.pad(duration.minutes())}:${this.pad(duration.seconds())}`;
    } else {
      return '';
    }
  }

  private pad(num: number): string {
    if (num < 0) {
      num = Math.abs(num);
    }

    return ('00' + num).slice(-2);
  }
}
