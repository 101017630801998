import { NzModalRef } from 'ng-zorro-antd/modal';
import { Component, OnInit } from '@angular/core';

import { CampaignService } from '../../services/campaign/campaign.service';
import { StateManagementService } from '../../state-management/state-management.service';

@Component({
  selector: 'app-modal-regulation',
  templateUrl: './modal-regulation.component.html',
  styleUrls: ['./modal-regulation.component.scss'],
})
export class ModalRegulationComponent implements OnInit {
  public loading: boolean = true;
  public pdfSrc: string;
  public mallId: string;

  constructor(
    public $modal: NzModalRef,
    private $campaign: CampaignService,
    private $notification: StateManagementService
  ) {}

  public ngOnInit(): void {
    this.getMallId();
    this.getPdfSrc();
  }

  public getMallId(): void {
    this.$notification.malls.subscribe((mall) => {
      this.mallId = mall.id;
    });
  }

  public getPdfSrc(): void {
    this.$campaign.getCampaignFilter({ id_mall: this.mallId }).subscribe((res) => {
      this.pdfSrc = res.body.rows[0].regulationLinkPwa;
    });
  }
}
