<header *nzModalTitle class="modal-header">
  <h3 class="title-category">Filtro</h3>
  <span class="example-spacer"></span>
  <img src="../../assets/images/close.svg" (click)="modalRef.close()" class="pointer-cursor" />
</header>

<body>
  <main>
    <section class="centerBlock">
      <form [formGroup]="filterForm">
        <nz-form-item *ngIf="filters.isFilterId">
          <nz-form-label class="start" [nzNoColon]="true" [nzSm]="24" nzFor="id">ID</nz-form-label>
          <nz-form-control class="w-100" [nzSm]="24" [nzXs]="24" nzHasFeedback nzErrorTip="ID inválido.">
            <nz-input-group [nzSm]="24" [nzXs]="24">
              <input nz-input placeholder="Digite o ID" type="text" formControlName="id" id="id" autocomplete="off" />
            </nz-input-group>
          </nz-form-control>
        </nz-form-item>
        <nz-form-item *ngIf="filters.isFilterSubscriptionId">
          <nz-form-label class="start" [nzNoColon]="true" [nzSm]="24" nzFor="subscriptionId"
            >ID da Recorrência</nz-form-label
          >
          <nz-form-control class="w-100" [nzSm]="24" [nzXs]="24">
            <nz-input-group [nzSm]="24" [nzXs]="24">
              <input
                nz-input
                placeholder="Digite o ID da Recorrência"
                type="text"
                formControlName="subscriptionId"
                id="subscriptionId"
                autocomplete="off"
              />
            </nz-input-group>
          </nz-form-control>
        </nz-form-item>
        <nz-form-item *ngIf="filters.isFilterOrderId">
          <nz-form-label class="start" [nzNoColon]="true" [nzSm]="24" nzFor="orderId">ID do pedido</nz-form-label>
          <nz-form-control class="w-100" [nzSm]="24" [nzXs]="24">
            <nz-input-group [nzSm]="24" [nzXs]="24">
              <input
                nz-input
                placeholder="Digite o ID do pedido"
                type="text"
                formControlName="orderId"
                id="orderId"
                autocomplete="off"
              />
            </nz-input-group>
          </nz-form-control>
        </nz-form-item>
        <nz-form-item *ngIf="filters.isFilterSellerId">
          <nz-form-label class="start" [nzNoColon]="true" [nzSm]="24" nzFor="sellerId">ID do Seller</nz-form-label>
          <nz-form-control class="w-100" [nzSm]="24" [nzXs]="24">
            <nz-input-group [nzSm]="24" [nzXs]="24">
              <input
                nz-input
                placeholder="Digite o ID do Seller"
                type="text"
                formControlName="sellerId"
                id="sellerId"
                autocomplete="off"
              />
            </nz-input-group>
          </nz-form-control>
        </nz-form-item>
        <nz-form-item *ngIf="filters.isFilterCustomerId">
          <nz-form-label class="start" [nzNoColon]="true" [nzSm]="24" nzFor="customerId">ID do Customer</nz-form-label>
          <nz-form-control class="w-100" [nzSm]="24" [nzXs]="24">
            <nz-input-group [nzSm]="24" [nzXs]="24">
              <input
                nz-input
                placeholder="Digite o ID do Customer"
                type="text"
                formControlName="customerId"
                id="customerId"
                autocomplete="off"
              />
            </nz-input-group>
          </nz-form-control>
        </nz-form-item>
        <nz-form-item *ngIf="filters.isFilterTitle">
          <nz-form-label class="start" [nzNoColon]="true" [nzSm]="24" nzRequired nzFor="title"
            >Título do evento</nz-form-label
          >
          <nz-form-control class="w-100" [nzSm]="24" [nzXs]="24" nzHasFeedback nzErrorTip="Preenchimento obrigatório.">
            <nz-input-group [nzSm]="24" [nzXs]="24">
              <input
                nz-input
                placeholder="Digite o título do evento"
                type="text"
                formControlName="title"
                id="title"
                required
                autocomplete="off"
                (keydown)="onlyNumbers($event)"
              />
            </nz-input-group>
          </nz-form-control>
        </nz-form-item>
        <nz-form-item *ngIf="filters.isFilterName">
          <nz-form-label class="start" [nzNoColon]="true" [nzSm]="24" nzFor="name">{{ inputName }}</nz-form-label>
          <nz-form-control class="w-100" [nzSm]="24" [nzXs]="24">
            <nz-input-group [nzSm]="24" [nzXs]="24">
              <input
                nz-input
                placeholder="Digite o nome"
                type="text"
                formControlName="name"
                id="name"
                autocomplete="off"
                (keydown)="onlyNumbers($event)"
              />
            </nz-input-group>
          </nz-form-control>
        </nz-form-item>
        <nz-form-item *ngIf="filters.isFilterDocument">
          <nz-form-label class="start" [nzNoColon]="true" [nzSm]="24" nzFor="document">CPF / CNPJ</nz-form-label>
          <nz-form-control class="w-100" [nzSm]="24" [nzXs]="24" nzHasFeedback nzErrorTip="Documento inválido.">
            <nz-input-group [nzSm]="24" [nzXs]="24">
              <input
                nz-input
                placeholder="Digite o CPF ou CNPJ"
                type="text"
                formControlName="document"
                mask="000.000.000-00||00.000.000/0000-00"
                id="document"
                autocomplete="off"
              />
            </nz-input-group>
          </nz-form-control>
        </nz-form-item>
        <nz-form-item *ngIf="filters.isFilterEmail">
          <nz-form-label class="start" [nzNoColon]="true" [nzSm]="24" nzFor="email">E-mail</nz-form-label>
          <nz-form-control class="w-100" [nzSm]="24" [nzXs]="24" nzHasFeedback nzErrorTip="Email inválido.">
            <nz-input-group [nzSm]="24" [nzXs]="24">
              <input
                nz-input
                placeholder="Digite o e-mail"
                type="email"
                formControlName="email"
                id="email"
                autocomplete="off"
              />
            </nz-input-group>
          </nz-form-control>
        </nz-form-item>
        <nz-form-item *ngIf="filters.isFilterPhone">
          <nz-form-label class="start" [nzNoColon]="true" [nzSm]="24" nzFor="email">Celular</nz-form-label>
          <nz-form-control class="w-100" [nzSm]="24" [nzXs]="24" nzHasFeedback nzErrorTip="Celular inválido.">
            <nz-input-group [nzSm]="24" [nzXs]="24">
              <input
                nz-input
                placeholder="Digite o número"
                type="phone"
                formControlName="phone"
                mask="(00) 0 0000-0000"
                id="phone"
                autocomplete="off"
              />
            </nz-input-group>
          </nz-form-control>
        </nz-form-item>
        <nz-form-item *ngIf="filters.isFilterStoreName">
          <nz-form-label class="start" [nzNoColon]="true" [nzSm]="24" nzFor="storeName">Shopping</nz-form-label>
          <nz-form-control class="w-100" [nzSm]="24" [nzXs]="24" nzHasFeedback nzErrorTip="Preenchimento obrigatório.">
            <nz-input-group [nzSm]="24" [nzXs]="24">
              <input
                nz-input
                placeholder="Digite o nome do shopping"
                type="text"
                formControlName="storeName"
                id="storeName"
                required
                autocomplete="off"
                (keydown)="onlyNumbers($event)"
              />
            </nz-input-group>
          </nz-form-control>
        </nz-form-item>
        <nz-form-item *ngIf="filters.isFilterExpirationDate">
          <nz-form-label class="start" [nzNoColon]="true" [nzSm]="24" nzFor="expirationDate"
            >Data de encerramento</nz-form-label
          >
          <nz-form-control class="w-100" [nzSm]="24" [nzXs]="24" nzHasFeedback nzErrorTip="Período inválido.">
            <nz-range-picker
              [nzFormat]="dateFormat"
              formControlName="expirationDate"
              id="expirationDate"
            ></nz-range-picker>
          </nz-form-control>
        </nz-form-item>

        <nz-form-item *ngIf="filters.isFilterStatus">
          <nz-form-label class="start" [nzNoColon]="true" [nzSm]="24" nzFor="status">Status</nz-form-label>
          <nz-form-control class="w-100" [nzSm]="24" [nzXs]="24" nzHasFeedback nzErrorTip="Status inválido.">
            <nz-select
              id="status"
              nzAllowClear
              nzPlaceHolder="Selecione os status"
              formControlName="status"
              nzMode="multiple"
            >
              <nz-option
                *ngFor="let status of statusList | keyvalue"
                [nzValue]="status.key"
                [nzLabel]="status.value.toString()"
              ></nz-option>
            </nz-select>
          </nz-form-control>
        </nz-form-item>

        <nz-form-item *ngIf="filters.isFilterStoresList">
          <nz-form-label class="start" [nzNoColon]="true" [nzSm]="24" nzFor="storeName">Estabelecimentos</nz-form-label>
          <nz-form-control class="w-100" [nzSm]="24" [nzXs]="24" nzHasFeedback nzErrorTip="Estabelecimento inválido.">
            <nz-select
              id="status"
              nzAllowClear
              nzPlaceHolder="Selecione os estabelecimentos"
              formControlName="storeName"
            >
              <nz-option *ngFor="let store of storesList" [nzValue]="store.id" [nzLabel]="store.name"></nz-option>
            </nz-select>
          </nz-form-control>
        </nz-form-item>

        <nz-form-item *ngIf="filters.isFilterInstallments">
          <nz-form-label class="start" [nzNoColon]="true" [nzSm]="24" nzFor="installments">Parcelas</nz-form-label>
          <nz-form-control
            class="w-100"
            [nzSm]="24"
            [nzXs]="24"
            nzHasFeedback
            nzErrorTip="Número de parcelas inválido."
          >
            <nz-input-group [nzSm]="24" [nzXs]="24">
              <input
                nz-input
                placeholder="Digite o número de parcelas"
                type="text"
                formControlName="installments"
                id="installments"
                autocomplete="off"
              />
            </nz-input-group>
          </nz-form-control>
        </nz-form-item>

        <nz-form-item *ngIf="filters.isFilterMethod">
          <nz-form-label class="start" [nzNoColon]="true" [nzSm]="24" nzFor="method">Método</nz-form-label>
          <nz-form-control class="w-100" [nzSm]="24" [nzXs]="24" nzHasFeedback nzErrorTip="Método inválido.">
            <nz-checkbox-group [(ngModel)]="methodList" formControlName="method"></nz-checkbox-group>
          </nz-form-control>
        </nz-form-item>

        <nz-form-item *ngIf="filters.isFilterCreationDate">
          <nz-form-label class="start" [nzNoColon]="true" [nzSm]="24" nzFor="creationDate"
            >Data de criação</nz-form-label
          >
          <nz-form-control class="w-100" [nzSm]="24" [nzXs]="24" nzHasFeedback nzErrorTip="Período inválido.">
            <nz-range-picker [nzFormat]="dateFormat" formControlName="creationDate" id="creationDate"></nz-range-picker>
          </nz-form-control>
        </nz-form-item>

        <nz-form-item *ngIf="filters.isFilterStatusSingle">
          <nz-form-label class="start" [nzNoColon]="true" [nzSm]="24" nzFor="status">Status</nz-form-label>
          <nz-form-control class="w-100" [nzSm]="24" [nzXs]="24" nzHasFeedback nzErrorTip="Status inválido.">
            <nz-select id="status" nzAllowClear nzPlaceHolder="Selecione os status" formControlName="status">
              <nz-option
                *ngFor="let status of statusList | keyvalue"
                [nzValue]="status.key"
                [nzLabel]="status.value.toString()"
              ></nz-option>
            </nz-select>
          </nz-form-control>
        </nz-form-item>
      </form>
    </section>
  </main>
</body>

<footer class="modal-footer" *nzModalFooter>
  <div class="buttons">
    <app-secondary-button (click)="clearFields()">Limpar filtros</app-secondary-button>
    <app-primary-button (click)="setHandleSubmit()" class="body-txt-bold">Aplicar filtros</app-primary-button>
  </div>
</footer>
