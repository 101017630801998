<section id="show-qrcode">
  <div class="title-container" *nzModalTitle>
    <div class="title-content">
      <h1>Qr Code de pagamento</h1>
      <img src="../../assets/images/icons/xmark.svg" alt="Fechar" />
    </div>
  </div>

  <body class="qrcode-body">
    <div class="qrcode-image">
      <nz-qrcode [nzValue]="link"></nz-qrcode>
    </div>

    <div class="qrcode-description">
      <p>Baixe e disponibilize o QR Code de pagamento em sua loja.</p>
    </div>
  </body>
</section>

<footer class="modal-footer" *nzModalFooter>
  <div class="buttons w-100 between">
    <app-secondary-button [disabled]="loading" [loading]="loading" (click)="downloadQrCode(false)">
      Qr Code
    </app-secondary-button>
    <app-primary-button [disabled]="loading" [loading]="loading" (click)="downloadQrCode(true)">
      Qr Code com logo
    </app-primary-button>
  </div>
</footer>

<div id="pdf" #pdf hidden>
  <div class="pdfContainer">
    <nz-qrcode [nzValue]="link"></nz-qrcode>
  </div>
</div>

<div id="simplePdf" #simplePdf hidden>
  <div class="pdfContainer">
    <nz-qrcode [nzValue]="link"></nz-qrcode>
  </div>
</div>
