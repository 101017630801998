<section class="drawerUser">
  <div class="d-flex between">
    <h2>{{ value?.name }}</h2>
    <app-primary-button icon="down" nz-dropdown [nzDropdownMenu]="menu" nzTrigger="click"> Opções </app-primary-button>
    <nz-dropdown-menu #menu="nzDropdownMenu">
      <ul nz-menu>
        <li nz-menu-item class="primary-color" (click)="showEditUserModal(value)">Editar informações</li>
        <!-- <li nz-menu-item>Excluir contato</li> -->
      </ul>
    </nz-dropdown-menu>
  </div>
  <div class="d-flex m-t-1">
    <div>
      <strong class="d-block">Criado em:</strong>
      <span>{{ value?.insertedAt | date : 'dd/MM/yyyy' }}</span>
    </div>
  </div>
  <div class="m-t-1">
    <h3>Dados do contato</h3>
    <div nz-row nzJustify="space-between" class="m-t-1">
      <div nz-col nzSpan="12">
        <strong class="spanTitle">CPF: </strong>
        <span>{{ value?.cpf | mask : '000.000.000-00' }}</span>
      </div>
      <div nz-col nzSpan="12">
        <strong class="spanTitle">E-mail: </strong>
        <span>{{ value?.email || '-' }}</span>
      </div>
    </div>
    <div nz-row nzJustify="space-between" class="m-t-1">
      <div nz-col nzSpan="12">
        <strong class="spanTitle">Telefone: </strong>
        <span>{{ value?.phone | mask : '+00 (00) 0 0000-0000' || '-' }}</span>
      </div>
    </div>
  </div>
</section>
