<header class="modal-header" *nzModalTitle>
  <h3 class="title-category">{{ data?.name }}</h3>
  <span class="example-spacer"></span>
  <img src="../../assets/images/close.svg" (click)="$modalRef.close()" class="pointer-cursor" />
</header>

<section class="modal-body">
  <ng-container *ngIf="!listLoading">
    <form [formGroup]="form">
      <div>
        <nz-form-item>
          <nz-input-group [nzSuffix]="suffixIconSearch">
            <input type="text" class="w-100" nz-input placeholder="Pesquisar" formControlName="search" />
          </nz-input-group>
          <ng-template #suffixIconSearch>
            <span nz-icon nzType="search"></span>
          </ng-template>
        </nz-form-item>
      </div>

      <span class="form-title"> Itens desta categoria</span>

      <nz-list nzSize="small" class="max-h">
        <ng-container *ngIf="filteredItems?.length > 0">
          <nz-list-item *ngFor="let item of filteredItems" class="startBlock">
            <span>
              {{ item.name }}
            </span>
            <span *ngIf="item?.categoryName" class="categ-name"> ( {{ item?.categoryName || '' }} ) </span>
            <input type="checkbox" [checked]="item.selected" (click)="onSelectItem(item)" />
          </nz-list-item>
        </ng-container>
      </nz-list>
    </form>
  </ng-container>

  <div *ngIf="listLoading" class="h-100 m-t-2 center w-100">
    <nz-spin nzSize="large" nzSimple></nz-spin>
  </div>
</section>

<footer class="modal-footer" *nzModalFooter>
  <div class="buttons w-100">
    <app-secondary-button (click)="$modalRef.close()"> Cancelar </app-secondary-button>
    <app-primary-button (click)="setHandleSubmit()"> Salvar </app-primary-button>
  </div>
</footer>
