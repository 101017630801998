import { Observable } from 'rxjs';
import { Apollo } from 'apollo-angular';
import { Injectable, Query } from '@angular/core';

import { LIST_CANCELLMENTS_QUERY } from '../../graphql/queries/cancellments.queries';
import { FilterCancellationParams } from './../../models/cancellments/filter-cancellation-params.model';
import { PaginationModel } from '../../models/pagination/pagination.model';

@Injectable()
export class CancellmentsService {
  constructor(private readonly apollo: Apollo) {}

  public getListCancellments(filters: FilterCancellationParams, pagination: PaginationModel): Observable<any> {
    return this.apollo.watchQuery<Query>({
      query: LIST_CANCELLMENTS_QUERY,
      variables: { filters, pagination },
      fetchPolicy: 'network-only',
    }).valueChanges;
  }
}
