<section class="product_card_basket">
  <nz-card nzBorderless>
    <div nz-row [nzGutter]="32">
      <div nz-col [nzXs]="8" [nzMd]="8" [nzXl]="6" [nzXXl]="4">
        <img
          *ngIf="images && images.length > 0"
          class="product-img-basket"
          alt="product-img"
          nz-image
          [nzDisablePreview]="true"
          [nzFallback]="fallback"
          [nzSrc]="images[0].url"
        />
        <img
          *ngIf="!images || (images && images.length === 0)"
          class="product-img-basket"
          nz-image
          alt="product-img"
          nzSrc="error"
          [nzFallback]="fallback"
        />
      </div>

      <div nz-col [nzXs]="16" [nzMd]="6" [nzXl]="8" [nzXXl]="10">
        <div class="inline w-100">
          <div class="product-title-container w-100">
            <span class="product-title">{{
              product?.name?.length > 15 ? product?.name?.substring(0, 15) + '...' : product?.name
            }}</span>

            <svg
              (click)="deleteProductCart()"
              class="pointer-cursor mobile"
              width="18"
              height="20"
              viewBox="0 0 18 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1 4H17M13 4L12.7294 3.18807C12.4671 2.40125 12.3359 2.00784 12.0927 1.71698C11.8779 1.46013 11.6021 1.26132 11.2905 1.13878C10.9376 1 10.523 1 9.69357 1H8.30643C7.47705 1 7.06236 1 6.70951 1.13878C6.39792 1.26132 6.12208 1.46013 5.90729 1.71698C5.66405 2.00784 5.53292 2.40125 5.27064 3.18807L5 4M15 4V14.2C15 15.8802 15 16.7202 14.673 17.362C14.3854 17.9265 13.9265 18.3854 13.362 18.673C12.7202 19 11.8802 19 10.2 19H7.8C6.11984 19 5.27976 19 4.63803 18.673C4.07354 18.3854 3.6146 17.9265 3.32698 17.362C3 16.7202 3 15.8802 3 14.2V4M11 8V15M7 8V15"
                stroke="#C73B51"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </div>

          <div class="list-variation-container">
            <ng-container *ngFor="let variant of product.itemVariants">
              <ng-container *ngFor="let itemVariantOption of variant.itemVariantOptions">
                <ng-container *ngIf="variant.type === 'color'; else normalVariation">
                  <div class="d-flex list-variation">
                    <span class="color-span" [style.background]="itemVariantOption.color"> </span>
                    {{ itemVariantOption.name }}{{ product.itemVariants.length > 1 ? ', ' : '' }}
                  </div>
                </ng-container>
                <ng-template #normalVariation>
                  <div class="list-variation">
                    {{ itemVariantOption.name }}
                  </div>
                </ng-template>
              </ng-container>
            </ng-container>
          </div>

          <span class="product-edit m-t-0" (click)="editProductCart()">Editar</span>
        </div>

        <div nz-row class="price-desktop {{ product.itemVariants.length === 0 ? 'm-t-2' : '' }}" [nzGutter]="32">
          <div nz-col [nzLg]="24" [nzXl]="19" [nzXl]="15">
            <app-quantity-button-secondary
              [quantity]="product?.quantity || 1"
              (updateQuantity)="emitQuantity($event)"
            />
          </div>
        </div>
      </div>

      <div class="price-desktop" nz-col [nzMd]="10" [nzXXl]="10">
        <div class="h-100 between">
          <span class="product-value">{{ (product.price / 100) * product.quantity | currency : 'R$' }}</span>
          <div class="actions">
            <span (click)="editProductCart()">Editar</span>
            <svg
              (click)="deleteProductCart()"
              class="pointer-cursor"
              width="18"
              height="20"
              viewBox="0 0 18 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1 4H17M13 4L12.7294 3.18807C12.4671 2.40125 12.3359 2.00784 12.0927 1.71698C11.8779 1.46013 11.6021 1.26132 11.2905 1.13878C10.9376 1 10.523 1 9.69357 1H8.30643C7.47705 1 7.06236 1 6.70951 1.13878C6.39792 1.26132 6.12208 1.46013 5.90729 1.71698C5.66405 2.00784 5.53292 2.40125 5.27064 3.18807L5 4M15 4V14.2C15 15.8802 15 16.7202 14.673 17.362C14.3854 17.9265 13.9265 18.3854 13.362 18.673C12.7202 19 11.8802 19 10.2 19H7.8C6.11984 19 5.27976 19 4.63803 18.673C4.07354 18.3854 3.6146 17.9265 3.32698 17.362C3 16.7202 3 15.8802 3 14.2V4M11 8V15M7 8V15"
                stroke="#C73B51"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </div>
        </div>
      </div>
    </div>

    <div nz-row class="price-mobile m-t-0" [nzGutter]="32">
      <div nz-col nzSpan="10">
        <app-quantity-button-secondary [quantity]="product?.quantity || 1" (updateQuantity)="emitQuantity($event)" />
      </div>
      <div nz-col nzSpan="14">
        <span class="product-value">{{ (product.price / 100) * product.quantity | currency : 'R$' }}</span>
      </div>
    </div>
  </nz-card>
</section>
