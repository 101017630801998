import { gql } from 'apollo-angular';

const CREATE_BANNER_MUTATION = gql`
  mutation CreateBanner(
    $bannerUrl: String!
    $bannerPriority: Int
    $bannerType: BannerTypes
    $metadata: JSONObject
    $active: Boolean
  ) {
    createBanner(
      bannerUrl: $bannerUrl
      bannerPriority: $bannerPriority
      bannerType: $bannerType
      metadata: $metadata
      active: $active
    ) {
      id
    }
  }
`;

const UPDATE_BANNER_MUTATION = gql`
  mutation UpdateBanner(
    $id: String!
    $bannerUrl: String
    $bannerPriority: Int
    $active: Boolean
    $bannerType: BannerTypes
    $metadata: JSONObject
  ) {
    updateBanner(
      id: $id
      bannerUrl: $bannerUrl
      bannerPriority: $bannerPriority
      active: $active
      bannerType: $bannerType
      metadata: $metadata
    ) {
      id
    }
  }
`;

const DELETE_BANNER_MUTATION = gql`
  mutation DeleteBanner($id: String!) {
    deleteBanner(id: $id) {
      id
    }
  }
`;

const CREATE_BANNER_SELECTION_MUTATION = gql`
  mutation CreateBannerSelection($banners: [BannersDto!]!, $displayAt: BannerDisplayAt!, $active: Boolean) {
    createBannerSelection(banners: $banners, displayAt: $displayAt, active: $active) {
      id
    }
  }
`;

const UPDATE_BANNER_SELECTION_MUTATION = gql`
  mutation UpdateBannerSelection(
    $id: String!
    $banners: [BannersDto!]!
    $active: Boolean
    $displayAt: BannerDisplayAt
  ) {
    updateBannerSelection(id: $id, banners: $banners, active: $active, displayAt: $displayAt) {
      id
    }
  }
`;

const DELETE_BANNER_SELECTION_MUTATION = gql`
  mutation DeleteBannerSelection($id: String!) {
    deleteBannerSelection(id: $id) {
      id
    }
  }
`;

export {
  CREATE_BANNER_MUTATION,
  UPDATE_BANNER_MUTATION,
  DELETE_BANNER_MUTATION,
  CREATE_BANNER_SELECTION_MUTATION,
  UPDATE_BANNER_SELECTION_MUTATION,
  DELETE_BANNER_SELECTION_MUTATION,
};
