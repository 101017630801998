import { orderBy } from 'lodash';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { Apollo, Mutation, Query } from 'apollo-angular';

import {
  GET_ALL_SELLERS_QUERY,
  GET_CURRENT_LEAD_QUERY,
  GET_COUPON_BY_ID_QUERY,
  GET_LEAD_MY_COUPONS_QUERY,
  GET_COUPONS_BY_SELLER_QUERY,
  GET_COUPONS_BY_LEAD_ID_QUERY,
  GET_COUPONS_BY_CATEGORY_ID_QUERY,
  CURRENT_LEAD_QUERY,
  GET_NEAREST_MALLS_QUERY,
  GET_CATEGORIES_QUERY,
} from '../../graphql/queries/lead.queries';
import {
  CREATE_LEAD_MUTATION,
  SHARE_COUPON_MUTATION,
  LEAD_SEND_SMS_MUTATION,
  LEAD_LIKE_COUPON_MUTATION,
  LEAD_UNLIKE_COUPON_MUTATION,
  RESCUE_LEAD_COUPON_MUTATION,
  LEAD_VERIFY_AUTH_CODE_MUTATION,
  REDEEM_COUPON_BY_LEAD_MUTATION,
  CREATE_COUPON_AVALIATION_MUTATION,
  SEND_LEAD_PHONE,
  CouponOrderByEnum,
} from '../../graphql/mutations/lead.mutations';
import { CouponAvaliationsFidelityModel } from '../../models/fidelity/coupon-avaliations-fidelity.model';

@Injectable()
export class LeadService {
  constructor(private readonly apollo: Apollo) {}

  public getCurrentLead(): Observable<any> {
    return this.apollo.watchQuery<Query>({
      query: GET_CURRENT_LEAD_QUERY,
      fetchPolicy: 'network-only',
    }).valueChanges;
  }

  public getMyCoupons(filterForm?: any): Observable<any> {
    const variables = this.getVariables({}, filterForm);
    return this.apollo.watchQuery<Query>({
      query: GET_LEAD_MY_COUPONS_QUERY,
      variables,
      fetchPolicy: 'network-only',
    }).valueChanges;
  }

  public getCouponsByLeadId(leadId?: string, filterForm?: any): Observable<any> {
    const variables = this.getVariables({}, leadId, filterForm);

    return this.apollo.watchQuery<Query>({
      query: GET_COUPONS_BY_LEAD_ID_QUERY,
      variables: { ...variables },
      fetchPolicy: 'network-only',
    }).valueChanges;
  }

  public currentLead(): Observable<any> {
    return this.apollo.watchQuery<Query>({
      query: CURRENT_LEAD_QUERY,
      fetchPolicy: 'cache-and-network',
    }).valueChanges;
  }

  public getCouponsByCategoryId(leadId: string, id: string, filterForm?: any, orderBy?: string): Observable<any> {
    const variables = this.getVariables({ leadId, id, orderBy }, filterForm);

    return this.apollo.watchQuery<Query>({
      query: GET_COUPONS_BY_CATEGORY_ID_QUERY,
      variables: {
        categoryIds: variables.filterForm.category,
        mallIds: variables.filterForm.mall,
        typeDiscounts: variables.filterForm.typeDiscount,
      },
      fetchPolicy: 'network-only',
    }).valueChanges;
  }

  public getCouponById(leadId: string, id: string): Observable<any> {
    return this.apollo.watchQuery<Query>({
      query: GET_COUPON_BY_ID_QUERY,
      variables: { leadId, id },
      fetchPolicy: 'network-only',
    }).valueChanges;
  }

  public getAllSellers(leadId?: string, filterForm?: any): Observable<any> {
    const variables = this.getVariables({}, leadId, filterForm);
    return this.apollo.watchQuery<Query>({
      query: GET_ALL_SELLERS_QUERY,
      variables,
      fetchPolicy: 'network-only',
    }).valueChanges;
  }

  public getCouponsBySellerId(sellerId: string, leadId?: string, filterForm?: any): Observable<any> {
    const variables = this.getVariables({ sellerId }, leadId, filterForm);

    return this.apollo.watchQuery<Query>({
      query: GET_COUPONS_BY_SELLER_QUERY,
      variables,
      fetchPolicy: 'network-only',
    }).valueChanges;
  }

  public createLead(name: string, phone: string, email: string, cpf: string, origin?: string): Observable<any> {
    return this.apollo.mutate<Mutation>({
      mutation: CREATE_LEAD_MUTATION,
      variables: { name, phone, email, cpf, origin },
    });
  }

  public sendSms(phoneNumber: string): Observable<any> {
    return this.apollo.mutate<Mutation>({
      mutation: LEAD_SEND_SMS_MUTATION,
      variables: { phoneNumber },
    });
  }

  public verifyAuthCode(phoneNumber: string, code: string): Observable<any> {
    return this.apollo.mutate<Mutation>({
      mutation: LEAD_VERIFY_AUTH_CODE_MUTATION,
      variables: { phoneNumber, code },
    });
  }

  public rescueCoupon(couponId: string): Observable<any> {
    return this.apollo.mutate<Mutation>({
      mutation: RESCUE_LEAD_COUPON_MUTATION,
      variables: { couponId },
    });
  }

  public redeemCoupon(leadId: string, couponId: string, lat: string, long: string): Observable<any> {
    return this.apollo.mutate<Mutation>({
      mutation: REDEEM_COUPON_BY_LEAD_MUTATION,
      variables: { leadId, couponId, lat, long },
    });
  }

  public likeCoupon(couponId: string): Observable<any> {
    return this.apollo.mutate<Mutation>({
      mutation: LEAD_LIKE_COUPON_MUTATION,
      variables: { couponId },
    });
  }

  public unlikeCoupon(couponId: string): Observable<any> {
    return this.apollo.mutate<Mutation>({
      mutation: LEAD_UNLIKE_COUPON_MUTATION,
      variables: { couponId },
    });
  }

  public shareCoupon(couponId: string): Observable<any> {
    return this.apollo.mutate<Mutation>({
      mutation: SHARE_COUPON_MUTATION,
      variables: { couponId },
    });
  }

  public createCouponAvaliation(variables: Partial<CouponAvaliationsFidelityModel>): Observable<any> {
    return this.apollo.mutate<Mutation>({
      mutation: CREATE_COUPON_AVALIATION_MUTATION,
      variables,
    });
  }

  private getVariables(initialData: any, leadId?: any, filterForm?: any): any {

     if (leadId) {
      initialData.leadId = leadId;
    }

    if (filterForm?.mall || filterForm?.mall?.length) {
      initialData.mallIds = filterForm?.mall;
    }

    if (filterForm?.category || filterForm?.category?.length) {
      initialData.categoryIds = filterForm?.category;
    }

    if (filterForm?.typeDiscount || filterForm?.typeDiscount?.length) {
      initialData.typeDiscounts = filterForm.typeDiscount;
    }

    if (filterForm?.name) {
      initialData.name = filterForm.name;
    }


    if (filterForm?.orderBy) {
      initialData.orderBy = filterForm.orderBy;
    }

    if (filterForm?.orderByPriceDesc) {
      initialData.orderBy = CouponOrderByEnum.PRICE_DESC;
    }

    if (filterForm?.orderByName) {
      initialData.orderBy = CouponOrderByEnum.NAME;
    }

    if (filterForm?.orderByPriceAsc) {
      initialData.orderBy = CouponOrderByEnum.PRICE_ASC;
    }

    if (filterForm?.orderByDueDateDesc) {
      initialData.orderBy = CouponOrderByEnum.DUE_DATE_DESC;
    }

    if (filterForm?.orderByAvaliationDesc) {
      initialData.orderByAvaliationDesc = CouponOrderByEnum.AVALIATION_DESC;
    }

    if (filterForm?.orderByNameDesc) {
      initialData.orderByNameDesc = CouponOrderByEnum.NAME;
    }


    return initialData;
  }

  public getCategories(orderBy?: string): any {
    return this.apollo.watchQuery<Query>({
      query: GET_CATEGORIES_QUERY,
      variables: { orderBy },
      fetchPolicy: 'network-only',
    }).valueChanges;
  }

  public sendLeadPhone(phone: string): Observable<any> {
    return this.apollo.mutate<Mutation>({
      mutation: SEND_LEAD_PHONE,
      variables: { phone },
    });
  }

  public nearestMalls(latitude: string, longitude: string): Observable<any> {
    return this.apollo.watchQuery<Query>({
      query: GET_NEAREST_MALLS_QUERY,
      variables: { latitude, longitude },
      fetchPolicy: 'network-only',
    }).valueChanges;
  }
}
