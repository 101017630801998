<section>
  <form nz-form nzContent [formGroup]="formDescription">
    <div>
      <nz-form-item>
        <label>Nome completo *</label>
        <nz-form-control>
          <input nz-input formControlName="name" placeholder="Nome completo" required />
        </nz-form-control>
      </nz-form-item>

      <nz-form-item>
        <label>CPF *</label>
        <nz-form-control
          [nzErrorTip]="
            formDescription.controls['document'].hasError('pattern')
              ? 'Preenchimento incorreto / sem preenchimento'
              : ''
          "
        >
          <input
            nz-input
            formControlName="document"
            mask="000.000.000-00"
            (keyup)="validationCpf(formDescription.get('document').value)"
            placeholder="000.000.000-00"
          />
        </nz-form-control>
      </nz-form-item>

      <nz-form-item>
        <label>Telefone *</label>
        <nz-form-control>
          <input nz-input formControlName="phone" mask="+00 (00) 0 0000-0000" placeholder="(00) 0 0000-0000" />
        </nz-form-control>
      </nz-form-item>

      <nz-form-item>
        <label>E-mail *</label>
        <nz-form-control nzErrorTip="E-mail inválido">
          <input nz-input formControlName="email" type="email" placeholder="Digite o e-mail" />
        </nz-form-control>
      </nz-form-item>

      <div class="warnning">
        <h3>Atenção!</h3>
        <p>O usuário receberá um convite com instruções para acessar o aplicativo no e-mail inserido acima.</p>
      </div>
    </div>
  </form>
</section>
<div *nzModalFooter>
  <app-primary-button class="m-r-1" icon="send" (click)="setHandleSubmit(formDescription)">Salvar</app-primary-button>
  <app-secondary-button (click)="setHandleSubmit()">Cancelar</app-secondary-button>
</div>
