import { AddressModel } from '../address/address.model';
import { OrderModel } from '../orders/order.model';
import { PaymentsModel } from '../payments/payments.model';
import { BasketModel } from './basket.model';

export class CartModel {
  public id: string;
  public status: 'ACTIVE' | 'INACTIVE' | 'PAID';
  public orders?: Array<OrderModel>;
  public payments?: Array<PaymentsModel>;
  public baskets?: Array<BasketModel>;
  public pointId?: string;
  public customerId?: string;
  public isPaid?: boolean;
  public coupon?: string;
  public shippingAddress?: AddressModel;
  public selectedType?: 'DELIVERY' | 'PICK_AND_GO' | 'LOCKER';
  public insertedAt: string;
  public totalValue?: number;
}
