import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';

import { Apollo, Query } from 'apollo-angular';
import { BoxOfficeModel, BoxOfficeFilters } from '../../models/box-office/box-office.model';
import {
  CREATE_BOX_OFFICE_MUTATION,
  DELETE_BOX_OFFICE_BANNER_MUTATION,
  DELETE_BOX_OFFICE_MUTATION,
  DELETE_BOX_OFFICE_SESSION_MUTATION,
  DELETE_BOX_OFFICE_TICKET_MUTATION,
  DELETE_RULE_DOCUMENT_MUTATION,
  UPDATE_BOX_OFFICE_MUTATION,
} from '../../graphql/mutations/box-office.mutation';
import {
  BOX_OFFICE_BY_ID_QUERY,
  BOX_OFFICE_TICKET_ENTITY_QUERY,
  GET_BOX_OFFICE_REPORT,
  BOX_OFFICES_QUERY,
} from '../../graphql/queries/box-office.queries';

@Injectable({
  providedIn: 'root',
})
export class BoxOfficeService {
  constructor(private readonly apollo: Apollo) {}

  public getBoxOfficeById(id: string): Observable<any> {
    return this.apollo.watchQuery<Query>({
      query: BOX_OFFICE_BY_ID_QUERY,
      variables: { id },
      fetchPolicy: 'cache-and-network',
    }).valueChanges;
  }

  public boxOffices(filters: any, pagination: any): Observable<any> {
    return this.apollo.watchQuery<Query>({
      query: BOX_OFFICES_QUERY,
      variables: { filters, pagination },
      fetchPolicy: 'network-only',
    }).valueChanges;
  }

  public getBoxOfficeTickets(filters?: BoxOfficeFilters): Observable<any> {
    return this.apollo.watchQuery<Query>({
      query: BOX_OFFICE_TICKET_ENTITY_QUERY,
      variables: { filters },
      fetchPolicy: 'network-only',
    }).valueChanges;
  }

  public createBoxOffice(params: BoxOfficeModel): Observable<any> {
    return this.apollo.mutate({
      mutation: CREATE_BOX_OFFICE_MUTATION,
      variables: { params },
    });
  }

  public updateBoxOffice(id: string, params: BoxOfficeModel): Observable<any> {
    return this.apollo.mutate({
      mutation: UPDATE_BOX_OFFICE_MUTATION,
      variables: { id, params },
    });
  }

  public deleteBoxOffice(id: string): Observable<any> {
    return this.apollo.mutate({
      mutation: DELETE_BOX_OFFICE_MUTATION,
      variables: { id },
    });
  }

  public deleteBoxOfficeSession(id: string): Observable<any> {
    return this.apollo.mutate({
      mutation: DELETE_BOX_OFFICE_SESSION_MUTATION,
      variables: { id },
    });
  }

  public deleteBoxOfficeBanner(id: string): Observable<any> {
    return this.apollo.mutate({
      mutation: DELETE_BOX_OFFICE_BANNER_MUTATION,
      variables: { id },
    });
  }

  public deleteRuleDocument(id: string): Observable<any> {
    return this.apollo.mutate({
      mutation: DELETE_RULE_DOCUMENT_MUTATION,
      variables: { id },
    });
  }

  public deleteBoxOfficeTicket(id: string): Observable<any> {
    return this.apollo.mutate({
      mutation: DELETE_BOX_OFFICE_TICKET_MUTATION,
      variables: { id },
    });
  }

  public getBoxOfficeReportById(id: string): Observable<any> {
    return this.apollo.watchQuery<Query>({
      query: GET_BOX_OFFICE_REPORT,
      variables: { id },
      fetchPolicy: 'cache-and-network',
    }).valueChanges;
  }
}
