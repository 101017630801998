import { OnboardingStepType } from './../../models/onboarding/external/onbording-questions.model';
import { Observable, of } from 'rxjs';
import { Injectable } from '@angular/core';
import { Apollo, Mutation, MutationResult, Query } from 'apollo-angular';
import {
  CREATE_ONBOARDING_SEARCH_MUTATION,
  FINISH_SELLER_ONBOARDING_MUTATION,
  START_SELLER_ONBOARDING_MUTATION,
  UPDATE_ONBOARDING_STEP_MUTATION,
} from '../../graphql/mutations/onboarding.mutations';
import { GET_ONBOARDING_QUESTIONS } from '../../graphql/queries/onboarding.queries';
import { CREATE_BANK_ACCOUNT_MUTATION } from '../../graphql/mutations/bank-account.mutations';
import { BankDataModel } from '../../models/bank/bank-data.model';

@Injectable()
export class NewOnboardingService {
  constructor(private readonly apollo: Apollo) {}

  public startSellerOnboarding(params: {
    name: string;
    cpf: string;
    email: string;
    password: string;
  }): Observable<any> {
    return this.apollo.mutate<Mutation>({
      mutation: START_SELLER_ONBOARDING_MUTATION,
      variables: { params },
    });
  }

  public finishSellerOnboarding(params: any): Observable<MutationResult> {
    return this.apollo.mutate<Mutation>({
      mutation: FINISH_SELLER_ONBOARDING_MUTATION,
      variables: { params },
    });
  }

  public onboardingQuestions(): Observable<any> {
    return this.apollo.watchQuery<Query>({
      query: GET_ONBOARDING_QUESTIONS,
      fetchPolicy: 'network-only',
    }).valueChanges;
  }

  public createOnboardingSearch(userId: string, questionId: string, answers: Array<string>): Observable<any> {
    return this.apollo.mutate<Mutation>({
      mutation: CREATE_ONBOARDING_SEARCH_MUTATION,
      variables: { params: { userId, questionId, answers } },
    });
  }

  public createBankAccount(data: BankDataModel): Observable<any> {
    return this.apollo.mutate<Mutation>({
      mutation: CREATE_BANK_ACCOUNT_MUTATION,
      variables: {
        bankNumber: data.bank,
        agencyCheckNumber: data.accountCheckNumber,
        type: data.accountType,
        agencyNumber: data.agency,
        accountNumber: data.account,
        accountCheckNumber: data.accountCheckNumber,
        sellerId: data.sellerId,
      },
    });
  }

  public updateOnboardingStep(userId: string, step: OnboardingStepType): Observable<any> {
    return this.apollo.mutate<Mutation>({
      mutation: UPDATE_ONBOARDING_STEP_MUTATION,
      variables: { userId, step },
    });
  }
}
