import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Observable, map, of, switchMap, take } from 'rxjs';
import { Apollo, Mutation, Query } from 'apollo-angular';
import { AngularFirestore } from '@angular/fire/compat/firestore';

import { environment } from '@gen/environments';
import {
  GET_CUSTOMER_QUERY,
  LIST_CUSTOMERS_QUERY,
  CUSTOMER_EXISTS_CPF,
  GET_CUSTOMER_MINIMAL_QUERY,
  GET_CUSTOMER_EXTERNAL_CONSULT,
} from '../../graphql/queries/customer.queries';
import {
  APPROVE_CUSTOMER_MUTATION,
  BAN_CUSTOMER_MUTATION,
  BLOCK_CUSTOMER_MUTATION,
  CREATE_CUSTOMER_MUTATION,
  DECLINE_CUSTOMER_MUTATION,
  EDIT_CUSTOMER_MUTATION,
  REPROCESS_CUSTOMER_MUTATION,
  UPLOAD_CUSTOMER_DOCUMENT_MUTATION,
} from '../../graphql/mutations/customer.mutations';
import { AddressService } from '../address/address.service';
import { AddressModel } from '../../models/address/address.model';
import { CustomerModel } from '../../models/customer/customer.model';
import { StateManagementService } from '../../state-management/state-management.service';
import { FilterCustomersParamsInput } from '../../models/customer/filter-customers-params.model';
import { ErrorService } from '../error/error.service';
import { PointModel } from '../../models/points/point-of-sales.model';
import { PaginationModel } from '../../models/pagination/pagination.model';

@Injectable()
export class CustomerService {
  public marketplaceId: string = environment.marketplaceId;

  constructor(
    private readonly apollo: Apollo,
    private $error: ErrorService,
    public $address: AddressService,
    private readonly fireStore: AngularFirestore,
    private $notification: StateManagementService
  ) {}

  public getCustomerPayload(customer: any, formCostumer: FormGroup): CustomerModel {
    if (customer) {
      customer.name = formCostumer.get('name').value;
      customer.email = formCostumer.get('email').value;

      if (formCostumer.get('document').value.length > 11) {
        customer.cnpj = formCostumer.get('document').value;
        delete customer.cpf;
      } else {
        customer.cpf = formCostumer.get('document').value;
        delete customer.cnpj;
      }

      customer.phone = `+55${formCostumer.get('phone').value}`;

      if (customer.id === '') {
        delete customer.id;
      }

      if (customer.limits) {
        delete customer.limits;
      }

      if (customer.documents) {
        delete customer.documents;
      }

      if (customer.__typename) {
        delete customer.__typename;
      }
    }

    return customer;
  }

  public formatPhone(customer: CustomerModel): string {
    if (customer?.phone?.startsWith('+55')) {
      return customer?.phone.substring(3);
    }

    return customer?.phone || '';
  }

  public getAuthCustomer(uid: string): Observable<CustomerModel> {
    return this.fireStore
      .collection(`marketplaces/${this.marketplaceId}/users`)
      .doc(uid)
      .valueChanges()
      .pipe(
        take(1),
        switchMap((user: any) => {
          if (user) {
            return this.getCustomerByIdMinimal(user.customerId).pipe(map((res) => res?.data?.customer));
          } else {
            return of(null);
          }
        })
      );
  }

  public updateShippingAddress(
    sellerId: string,
    shippingAddress: AddressModel,
    customerId: string,
    isAuth: boolean
  ): void {
    if (isAuth) {
      this.fireStore
        .collection(`marketplaces/${this.marketplaceId}/customers`)
        .doc(customerId)
        .set({ shippingAddress }, { merge: true });
    } else {
      this.fireStore
        .collection(`marketplaces/${this.marketplaceId}/sellers/${sellerId}/contacts`)
        .doc(customerId)
        .set({ shippingAddress }, { merge: true });
    }
  }

  public deleteShippingAddress(sellerId: string, customerId: string, isAuth: boolean): void {
    if (isAuth) {
      this.fireStore
        .collection(`marketplaces/${this.marketplaceId}/customers`)
        .doc(customerId)
        .set({ shippingAddress: null }, { merge: true });
    } else {
      this.fireStore
        .collection(`marketplaces/${this.marketplaceId}/sellers/${sellerId}/contacts`)
        .doc(customerId)
        .set({ shippingAddress: null }, { merge: true });
    }
  }

  public getCustomersList(filter: FilterCustomersParamsInput, pagination: PaginationModel): Observable<any> {
    return this.apollo.watchQuery<Query>({
      query: LIST_CUSTOMERS_QUERY,
      variables: { filter, pagination },
      fetchPolicy: 'network-only',
    }).valueChanges;
  }

  public customerAlreadyExistsCpf(cpf: string): Observable<any> {
    return this.apollo.watchQuery<Query>({
      query: CUSTOMER_EXISTS_CPF,
      variables: { cpf },
      fetchPolicy: 'cache-and-network',
    }).valueChanges;
  }

  public getCustomerById(id: string): Observable<any> {
    return this.apollo.watchQuery<Query>({
      query: GET_CUSTOMER_QUERY,
      variables: { id },
      fetchPolicy: 'cache-and-network',
    }).valueChanges;
  }

  public getCustomerByIdMinimal(id: string): Observable<any> {
    return this.apollo.watchQuery<Query>({
      query: GET_CUSTOMER_MINIMAL_QUERY,
      variables: { id },
      fetchPolicy: 'cache-and-network',
    }).valueChanges;
  }

  public getExternalCustomerConsult(id: string): Observable<any> {
    return this.apollo.watchQuery<Query>({
      query: GET_CUSTOMER_EXTERNAL_CONSULT,
      variables: { id },
      fetchPolicy: 'cache-and-network',
    }).valueChanges;
  }

  public createCustomer(params: CustomerModel): Observable<any> {
    return this.apollo.mutate<Mutation>({
      mutation: CREATE_CUSTOMER_MUTATION,
      variables: {
        params,
      },
    });
  }

  public editCustomer(id: string, params: CustomerModel): Observable<any> {
    return this.apollo.mutate<Mutation>({
      mutation: EDIT_CUSTOMER_MUTATION,
      variables: {
        id,
        params,
      },
    });
  }

  public banCustomer(id: string): Observable<any> {
    return this.apollo.mutate<Mutation>({
      mutation: BAN_CUSTOMER_MUTATION,
      variables: {
        id,
      },
    });
  }

  public declineCustomer(id: string, reason: string): Observable<any> {
    return this.apollo.mutate<Mutation>({
      mutation: DECLINE_CUSTOMER_MUTATION,
      variables: {
        id,
        reason,
      },
    });
  }

  public blockCustomer(id: string): Observable<any> {
    return this.apollo.mutate<Mutation>({
      mutation: BLOCK_CUSTOMER_MUTATION,
      variables: {
        id,
      },
    });
  }

  public approveCustomer(id: string): Observable<any> {
    return this.apollo.mutate<Mutation>({
      mutation: APPROVE_CUSTOMER_MUTATION,
      variables: {
        id,
      },
    });
  }

  public reprocessCustomer(id: string): Observable<any> {
    return this.apollo.mutate<Mutation>({
      mutation: REPROCESS_CUSTOMER_MUTATION,
      variables: {
        id,
      },
    });
  }

  public uploadCustomerDocuments(params: any): Observable<any> {
    return this.apollo.mutate<Mutation>({
      mutation: UPLOAD_CUSTOMER_DOCUMENT_MUTATION,
      variables: { params },
    });
  }

  public getAndSetCustomer(customerId: string): void {
    this.getCustomerById(customerId).subscribe({
      next: (res) => {
        if (res?.data?.customer) {
          this.$notification.setCustomer(res.data.customer);
        }
      },
      error: (error) => {
        throw new Error(error);
      },
    });
  }

  public getAuthCustomerPayload(customer: CustomerModel): CustomerModel {
    const formatedCustomer = {
      cpf: customer.cpf,
      email: customer.email,
      id: customer.id,
      name: customer.name,
      phone: customer.phone,
    };

    return formatedCustomer;
  }

  public getAndSetCustomerMinimal(point: PointModel): void {
    this.getCustomerByIdMinimal(point.customerId).subscribe({
      next: (res) => {
        if (res?.data?.customer) {
          this.$notification.setCustomer(res.data.customer);
        }
      },
      error: (error) => {
        this.$error.errorHandlingStore(error, 'ERRO AO FAZER AUTENTICAÇÃO', 'CUSTOM', point);
        throw new Error(error);
      },
    });
  }
}
