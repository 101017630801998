import { NzModalRef } from 'ng-zorro-antd/modal';
import { Component, Input } from '@angular/core';

import { CouponFidelityModel } from '../../../models/fidelity/coupon-fidelity.model';
import { LeadService } from '../../../services/lead/lead.service';
import { ErrorService } from '../../../services/error/error.service';
import { Message } from '../../../utils/message';

@Component({
  selector: 'app-modal-success-redeem-coupon',
  templateUrl: './modal-success-redeem-coupon.component.html',
  styleUrls: ['./modal-success-redeem-coupon.component.scss'],
})
export class ModalSuccessRedeemCouponComponent {
  @Input() public id: string;
  @Input() public leadId: string;

  public loading: boolean = true;

  public coupon: CouponFidelityModel = new CouponFidelityModel();

  constructor(private readonly $modalRef: NzModalRef, private $lead: LeadService, private $error: ErrorService) {}

  public ngOnInit(): void {
    this.getCoupon();
  }

  public getCoupon(): void {
    this.$lead.getCouponById(this.leadId, this.id).subscribe({
      next: (res) => {
        if (res?.data?.couponLead) {
          this.coupon = JSON.parse(JSON.stringify(res.data.couponLead));
        }

        this.loading = false;
      },
      error: (error) => {
        this.loading = false;
        this.$error.errorHandling(error, Message.ERROR_CONECTION);
      },
    });
  }

  public closeModal(): void {
    this.$modalRef.close();
  }
}
