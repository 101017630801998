import { Component } from '@angular/core';
import { NzModalRef } from 'ng-zorro-antd/modal';

@Component({
  selector: 'app-account-created',
  templateUrl: './account-created.component.html',
  styleUrls: ['./account-created.component.scss'],
})
export class AccountCreatedComponent {
  constructor(public modalRef: NzModalRef) {}

  public closeModal(type: string): void {
    this.modalRef.close(type);
  }
}
