import { Component } from '@angular/core';

@Component({
  selector: 'app-modal-pix-error',
  templateUrl: './modal-pix-error.component.html',
  styleUrls: ['./modal-pix-error.component.scss'],
})
export class ModalPixErrorComponent {
  public reload() {
    window.location.reload();
  }
}
