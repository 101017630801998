import { CartModel } from '../cart/cart.model';
import { CreditCardModel } from '../credit-card/credit-card.model';
import { AddressModel } from './../address/address.model';

export class PointModel {
  public id?: string;
  public insertedAt: string;
  public cartId?: string;
  public cart?: CartModel;
  public installments?: number;
  public customerId?: string;
  public contactId?: string;
  public description: string;
  public auth?: string;
}
