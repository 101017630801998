<section class="insert_image_modal">
  <div class="header between">
    <h1 *ngIf="page === 'STORE' || page === 'PLAN'">Inserir Imagem</h1>
    <h1 *ngIf="page === 'ITEM'">Editar imagem</h1>
    <img src="/assets/icons/close.svg" (click)="modalRef.close()" />
  </div>
  <nz-divider></nz-divider>
  <p *ngIf="page === 'STORE'">Centralize a imagem que irá aparecer como capa da sua loja.</p>
  <p *ngIf="page === 'ITEM'">Use os botões abaixo para ajustar a foto selecionada.</p>
  <p *ngIf="page === 'PLAN'">Centralize a imagem que irá aparecer como capa do plano.</p>
  <app-image-cropper
    [imgUrl]="imgUrl"
    (onChange)="getCroppedImage($event)"
    (getNowImg)="getCroppedImage($event)"
  ></app-image-cropper>
  <nz-divider></nz-divider>
  <div class="d-flex buttons">
    <ng-container *ngIf="isNew">
      <app-secondary-button (click)="modalRef.close()">Cancelar</app-secondary-button>
      <app-primary-button (click)="confirm()" [loading]="loading" [disabled]="loading">Continuar</app-primary-button>
    </ng-container>
    <ng-container *ngIf="!isNew">
      <app-delete-button *ngIf="page === 'ITEM'" (click)="deleteImg()">Excluir</app-delete-button>
      <app-secondary-button (click)="modalRef.close()">Cancelar</app-secondary-button>
      <span *ngIf="page === 'STORE' || page === 'PLAN'" class="example-spacer"></span>
      <app-primary-button (click)="confirm()" [loading]="loading" [disabled]="loading">Continuar</app-primary-button>
    </ng-container>
  </div>
</section>
