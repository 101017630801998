import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'lockerSizes',
})
export class LockerSizesPipe implements PipeTransform {
  transform(value: any, ...args: string[]): string {
    if (value) {
      const sizes: any = {
        S: 'P (300 x 70 x 400mm)',
        M: 'M (300 x 100 x 400mm)',
        L: 'G (300 x 350 x 400mm)',
      };

      return sizes[value] || '';
    }

    return '';
  }
}
