import { gql } from 'apollo-angular';

const LIST_SELLERS_QUERY = gql`
  query Sellers($filter: FilterSellerParamsInput, $pagination: PaginationParams) {
    sellers(filters: $filter, pagination: $pagination) {
      pageSize
      page
      totalPages
      total
      results {
        id
        cpf
        cnpj
        status
        displayName
        insertedAt
        company {
          businessName
        }
        person {
          name
        }
      }
    }
  }
`;

const LIST_SELLERS_TO_COUPON_QUERY = gql`
  {
    sellers(pagination: { sortingOrder: DESC, orderBy: "inserted_at", perPage: 10000 }) {
      pageSize
      page
      totalPages
      total
      results {
        id
        cpf
        cnpj
        email
        phone
        externalId
        status

        person {
          name
        }
        company {
          businessName
          tradeName
        }
        stores {
          logo
          displayName
        }
      }
    }
  }
`;

const LIST_SELLERS_TO_CREATE_EDIT_COUPON_QUERY = gql`
  {
    sellers(pagination: { sortingOrder: DESC, orderBy: "inserted_at", perPage: 10000 }) {
      pageSize
      page
      totalPages
      total
      results {
        id
        cpf
        cnpj
        person {
          name
        }
        company {
          businessName
        }
        stores {
          displayName
        }
      }
    }
  }
`;

const GET_SELLER = gql`
  query seller($id: String!) {
    seller(id: $id) {
      company {
        businessName
        tradeName
        cnpj
        address {
          postalCode
          line1
          line2
          line3
          neighborhood
          state
          city
        }
      }
      id
      cpf
      cnpj
      phone
      status
      insertedAt
      occupation
      email
      isBnplActive
      bnplRequestAt
      emailVerified
      phoneVerified
      isStore
      receivingLimit
      observation
      statusReason
      person {
        name
        birthDate
        nameMother
        address {
          postalCode
          line1
          line2
          line3
          neighborhood
          state
          city
        }
      }
      limits {
        action {
          name
        }
        functionality {
          name
        }
        category {
          name
        }
        perPeriod {
          name
        }
        amount
      }
      bankAccounts {
        bankNumber
        agencyNumber
        agencyCheckNumber
        type
        accountNumber
        accountCheckNumber
      }
      documents {
        id
        url
        status
        type
        insertedAt
        name
      }
      versions {
        event
        insertedAt
      }
    }
  }
`;

const GET_SELLER_DOCUMENTS = gql`
  query seller($id: String!) {
    seller(id: $id) {
      documents {
        status
        type
        insertedAt
        updatedAt
      }
    }
  }
`;

const GET_SELLER_EXTERNAL_CONSULT = gql`
  query externalSellerConsult($id: String!) {
    externalSellerConsult(id: $id) {
      id
      cpfCnpj
      datasets
      url
    }
  }
`;

const GET_SELLER_BUSINESS = gql`
  query seller($id: String!) {
    seller(id: $id) {
      displayName
      company {
        businessName
        tradeName
        cnpj
        address {
          postalCode
          line1
          line2
          line3
          neighborhood
          state
          city
        }
      }
      id
      cpf
      cnpj
      phone
      status
      insertedAt
      occupation
      isBnplActive
      email
      isStore
      receivingLimit
      externalId
      observation
      platformTermAccepted
      sellersPaymentMethods {
        id
        status
        paymentMethods {
          name
          displayName
        }
      }
      person {
        name
        birthDate
        nameMother
        address {
          postalCode
          line1
          line2
          line3
          neighborhood
          state
          city
        }
      }
      celcoinAccount {
        accountNumber
      }
      bankAccounts {
        bankNumber
        agencyNumber
        agencyCheckNumber
        type
        accountNumber
        accountCheckNumber
      }
      stores {
        displayName
        storePreferences {
          defaultPaymentMethods
        }
      }
      documents {
        id
        url
        status
        type
        insertedAt
        name
      }
    }
  }
`;

const GET_LAST_PLATFORM_TERM_QUERY = gql`
  {
    latestPlatformTerm(platform: saas) {
      id
      fileUrl
    }
  }
`;

const LIST_PLATFORM_TERMS_QUERY = gql`
  query ListPlatformTerms($filters: FilterPlatformTermsParams) {
    listPlatformTerms(filters: $filters, pagination: { sortingOrder: DESC, orderBy: "inserted_at", perPage: 100 }) {
      id
      version
      platform
      fileUrl
    }
  }
`;

const GET_EXTERNAL_CONSULT_DETAILS_QUERY = gql`
  query getExternalConsultDetails($id: String!) {
    getExternalConsultDetails(id: $id)
  }
`;

const GET_DEFAULT_ACTIVITIES_QUERY = gql`
  query DefaultActivities {
    defaultActivities {
      id
      active
      description
      code
    }
  }
`;

export {
  GET_SELLER,
  LIST_SELLERS_QUERY,
  GET_SELLER_BUSINESS,
  GET_SELLER_DOCUMENTS,
  LIST_PLATFORM_TERMS_QUERY,
  GET_LAST_PLATFORM_TERM_QUERY,
  LIST_SELLERS_TO_COUPON_QUERY,
  GET_EXTERNAL_CONSULT_DETAILS_QUERY,
  GET_SELLER_EXTERNAL_CONSULT,
  GET_DEFAULT_ACTIVITIES_QUERY,
  LIST_SELLERS_TO_CREATE_EDIT_COUPON_QUERY,
};
