import { NzModalRef } from 'ng-zorro-antd/modal';
import { Component, Input } from '@angular/core';

import { OrderModel } from '../../../models/orders/order.model';

@Component({
  selector: 'app-modal-confirmation-delivery-order',
  templateUrl: './modal-confirmation-delivery-order.component.html',
  styleUrls: ['./modal-confirmation-delivery-order.component.scss'],
})
export class ModalConfirmationDeliveryOrderComponent {
  @Input() order: OrderModel;

  constructor(private readonly modalRef: NzModalRef) {}

  public closeModal(confirm: boolean): void {
    this.modalRef.close(confirm);
  }
}
