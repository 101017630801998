import { gql } from 'apollo-angular';

const CREATE_LOCKER_ORDER = gql`
  mutation CreateLockerOrder($params: CreateLockerOrderParams!) {
    createLockerOrder(params: $params) {
      id
    }
  }
`;

export { CREATE_LOCKER_ORDER };
