<div class="center p-t-2 p-b-2" *ngIf="loading">
  <nz-spin nzSize="large"></nz-spin>
</div>
<main id="filter-coupon" *ngIf="!loading">
  <form [formGroup]="formGroup" class="w-100">
    <section>
      <nz-row>
        <nz-col nzSpan="12">
          <span class="title-text">Filtro</span>
        </nz-col>
        <nz-col nzSpan="12" class="endBlock">
          <img
            src="/assets/icons/xmark.svg"
            alt="Ícone para fechar o modal"
            class="pointer-cursor"
            (click)="closeDrawer()"
          />
        </nz-col>
      </nz-row>
    </section>

    <nz-divider></nz-divider>

    <section class="m-t-2">
      <nz-row>
        <nz-col nzSpan="24">
          <nz-form-item>
            <nz-form-label [nzNoColon]="true" [nzSm]="24" nzFor="orderBy" class="startBlock select-label m-b-0-1">
              Ordenar por
            </nz-form-label>
            <nz-form-control [nzSm]="24" [nzXs]="24">
              <nz-select
                id="orderBy"
                nzShowSearch
                nzAllowClear
                formControlName="orderBy"
                nzPlaceHolder="Escolha a ordem"
              >
                <nz-option *ngFor="let order of orderGroup" [nzLabel]="order.label" [nzValue]="order.value">
                </nz-option>
              </nz-select>
            </nz-form-control>
          </nz-form-item>
        </nz-col>
      </nz-row>
    </section>

    <!-- <section class="p-t-1">
      <nz-row>
        <nz-col nzSpan="24">
          <nz-form-item>
            <nz-form-label [nzNoColon]="true" [nzSm]="24" nzFor="categories" class="startBlock select-label m-b-0-1">
              Categoria
            </nz-form-label>
            <nz-form-control [nzSm]="24" [nzXs]="24">
              <nz-select
                id="categories"
                nzMode="multiple"
                nzShowSearch
                nzAllowClear
                formControlName="categories"
                nzPlaceHolder="Escolha a categoria"
              >
                <nz-option *ngFor="let category of categories" [nzLabel]="category.name" [nzValue]="category.id">
                </nz-option>
              </nz-select>
            </nz-form-control>
          </nz-form-item>
        </nz-col>
      </nz-row>
    </section> -->

    <section class="p-t-1">
      <nz-row>
        <nz-col nzSpan="24">
          <nz-form-item>
            <nz-form-label [nzNoColon]="true" [nzSm]="24" nzFor="mallId" class="startBlock select-label m-b-0-1">
              Empreendimento
            </nz-form-label>
            <nz-form-control [nzSm]="24" [nzXs]="24">
              <nz-select
                id="mallId"
                nzShowSearch
                nzAllowClear
                formControlName="mallId"
                nzPlaceHolder="Escolha o empreendimento"
              >
                <nz-option *ngFor="let mall of malls" [nzLabel]="mall.name" [nzValue]="mall.id"> </nz-option>
              </nz-select>
            </nz-form-control>
          </nz-form-item>
        </nz-col>
      </nz-row>
    </section>

    <section class="m-t-2">
      <nz-row>
        <nz-col nzSpan="12">
          <app-secondary-button (click)="clearFields()"> Limpar filtros </app-secondary-button>
        </nz-col>

        <nz-col nzSpan="12" class="endBlock">
          <app-primary-button (click)="setHandleSubmit()"> Aplicar filtros </app-primary-button>
        </nz-col>
      </nz-row>
    </section>
  </form>
</main>
