import { gql } from 'apollo-angular';

const LIST_COUPONS_QUERY = gql`
  query ListCoupons($filters: FilterCouponsParams) {
    listCoupons(filters: $filters, pagination: { orderBy: "inserted_at", sortingOrder: DESC, perPage: 100 }) {
      id
      code
      type
      percent
      endDate
      startDate
      updatedAt
      insertedAt
      maxCoupons
      discountCents
      allowedUsageAt
      maxCouponsPerCpf
      maxDiscountCents
      couponDiscountsCount
      maxDiscountCentsPerCpf
      maxDiscountCentsPerOrder
      minTotalCentsForDiscount
    }
  }
`;

export { LIST_COUPONS_QUERY };
