<section>
  <header class="modal-header" *nzModalTitle>
    <h3 class="title-category">Alterar e-mail</h3>
    <span class="example-spacer"></span>
    <img src="../../assets/images/close.svg" (click)="modalRef.close()" class="pointer-cursor" />
  </header>

  <body>
    <div *ngIf="!isConfirmPassword" class="email-container">
      <span>Email Atual</span>
      <span
        ><strong>{{ user?.email }}</strong></span
      >
      <nz-tag *ngIf="user?.email" class="m-l-1" [nzColor]="'green'">VALIDADO</nz-tag>
    </div>
    <p *ngIf="isConfirmPassword">
      Alterar o e-mail de {{ user?.email }} para
      <strong> {{ form.get('email').value }}</strong>
    </p>

    <form nz-form nzContent [formGroup]="form">
      <nz-form-item *ngIf="!isConfirmPassword">
        <label>E-mail</label>
        <nz-form-control>
          <input nz-input formControlName="email" type="email" placeholder="Digite o novo e-mail" />
        </nz-form-control>
      </nz-form-item>

      <nz-form-item *ngIf="isConfirmPassword">
        <label>Insira sua login para confirmar a alteração: </label>
        <nz-form-control>
          <input nz-input formControlName="signin" type="text" />
        </nz-form-control>
      </nz-form-item>

      <nz-form-item *ngIf="isConfirmPassword">
        <label>Insira sua senha para confirmar a alteração: </label>
        <nz-form-control>
          <input nz-input formControlName="password" type="password" />
        </nz-form-control>
      </nz-form-item>
    </form>
  </body>

  <footer class="modal-footer" *nzModalFooter>
    <div class="buttons">
      <app-secondary-button (click)="setHandleSubmit()">Cancelar</app-secondary-button>
      <app-primary-button *ngIf="!isConfirmPassword" class="m-l-1" (click)="nextStep()">Continuar</app-primary-button>
      <app-primary-button
        *ngIf="isConfirmPassword"
        class="m-l-1"
        icon="send"
        [disabled]="form.invalid"
        (click)="confirmPassword()"
        >Confirmar</app-primary-button
      >
    </div>
  </footer>
</section>
