import { gql } from 'apollo-angular';

const FEE_FIELDS = `
  id
  acquirerPlanId
  active
  amountCents
  application {
    id
  }
  inserted_at
  installments
  method
  percent
  seller {
    id
    company {
      businessName
    }
    person {
      name
    }
    cpf
    cnpj
  }
  updated_at
`;

const LIST_FEES_QUERY = gql`
  query fees($filter: FilterFeeParamsInput) {
    fees(filters: $filter, pagination: { orderBy: "inserted_at", sortingOrder: DESC, perPage: 100 }) {
      ${FEE_FIELDS}
    }
  }
`;

const GET_FEE_QUERY = gql`
  query fee($id: String!) {
    fee(id: $id) {
      ${FEE_FIELDS}
    }
  }
`;

export { GET_FEE_QUERY, LIST_FEES_QUERY };
