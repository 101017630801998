import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'bannerLocation',
})
export class BannerLocationPipe implements PipeTransform {
  transform(value: any, ...args: string[]): string {
    if (value) {
      const methods: any = {
        MOBILE_PRIMARY: 'Mobile principal',
        MOBILE_SECONDARY: 'Mobile secundário',
        COUPON_PUBLIC: 'Cupom público',
        PWA: 'Balcão virtual',
        CATALOG: 'Catálogo',
      };

      return methods[value] || '';
    }

    return '';
  }
}
