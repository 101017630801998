<header *nzModalTitle>
  <h3 class="title-category">Falar com a loja</h3>
  <span class="example-spacer"></span>
  <svg
    (click)="$modalRef.close()"
    class="pointer-cursor"
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M1 1L13 13M13 1L1 13" stroke="#333333" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
  </svg>
</header>

<section>
  <p>Entre em contato por WhatsApp ou se preferir ligue para o telefone da loja.</p>

  <div class="buttons">
    <a [href]="hrefWhatsApp" target="_blank" rel="noopener" class="button button__green">
      <img src="/assets/icons/whatsapp.svg" alt="Ícone do whatsapp" />
      WhatsApp
    </a>
    <a [href]="hrefTel" class="button">
      <img src="/assets/icons/phone.svg" alt="Ícone do whatsapp" />
      Ligar para a loja
    </a>
  </div>
</section>
