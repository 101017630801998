<header *nzModalTitle class="modal-header">
  <img src="/assets/images/arrow-left.svg" *ngIf="isShowSmsConfirm" (click)="stepBack()" class="pointer-cursor" />
  <h3 class="title-category">{{ title }}</h3>
  <img src="/assets/images/close.svg" (click)="$modalRef.close()" class="pointer-cursor" />
</header>

<body>
  <p class="information" *ngIf="isShowSmsConfirm">
    Insira abaixo o código que foi enviado para o número
    <strong>{{ login.phoneNumber | mask : '(00) 0 0000-0000' }}</strong>
  </p>

  <form class="form" [formGroup]="form" *ngIf="!isShowSmsConfirm">
    <nz-form-item>
      <nz-form-label [nzSpan]="24" [nzNoColon]="true" nzFor="fullName" class="label-text">
        Nome completo
      </nz-form-label>
      <nz-form-control [nzSpan]="24" nzHasFeedback nzErrorTip="Campo inválido, digite o nome completo">
        <input
          nz-input
          formControlName="fullName"
          type="text"
          id="fullName"
          placeholder="Nome Sobrenome"
          autocomplete="off"
        />
      </nz-form-control>
    </nz-form-item>

    <nz-form-item>
      <nz-form-label [nzSpan]="24" [nzNoColon]="true" nzFor="document" class="label-text"> CPF </nz-form-label>
      <nz-form-control [nzSpan]="24" nzHasFeedback nzErrorTip="CPF inválido">
        <input
          nz-input
          formControlName="document"
          mask="000.000.000-00"
          type="text"
          id="document"
          placeholder="000.000.000-00"
          autocomplete="off"
        />
      </nz-form-control>
    </nz-form-item>

    <nz-form-item>
      <nz-form-label [nzSpan]="24" [nzNoColon]="true" nzFor="phoneNumber" class="label-text"> Telefone </nz-form-label>
      <nz-form-control [nzSpan]="24" nzHasFeedback nzErrorTip="Número inválido">
        <input
          nz-input
          formControlName="phoneNumber"
          mask="(00) 0 0000-0000"
          type="tel"
          id="phoneNumber"
          placeholder="(00) 0 0000-0000"
          autocomplete="off"
        />
      </nz-form-control>
    </nz-form-item>

    <nz-form-item>
      <nz-form-label [nzSpan]="24" [nzNoColon]="true" nzFor="email" class="label-text"> E-mail </nz-form-label>
      <nz-form-control [nzSpan]="24" nzHasFeedback nzErrorTip="E-mail inválido">
        <input
          nz-input
          formControlName="email"
          type="email"
          id="email"
          placeholder="exemplo@gen.com"
          autocomplete="off"
        />
      </nz-form-control>
    </nz-form-item>
  </form>

  <form class="formGroupCodeVerify" [formGroup]="formGroupCodeVerify">
    <div class="input-content" *ngIf="isShowSmsConfirm">
      <nz-form-item>
        <nz-form-control nzErrorTip="Código inválido." [nzSpan]="4">
          <input
            #input
            required
            nz-input
            formControlName="code1"
            type="tel"
            id="code1"
            autocomplete="off"
            mask="0"
            (input)="onInput($event, 1)"
            (paste)="pasteNumeric($event)"
          />
        </nz-form-control>
      </nz-form-item>
      <nz-form-item>
        <nz-form-control [nzSpan]="4">
          <input
            #input
            required
            nz-input
            formControlName="code2"
            type="tel"
            id="code2"
            autocomplete="off"
            mask="0"
            (input)="onInput($event, 2)"
            (keyup)="onPressDeleteKey($event, 1)"
          />
        </nz-form-control>
      </nz-form-item>
      <nz-form-item>
        <nz-form-control [nzSpan]="4">
          <input
            #input
            required
            nz-input
            formControlName="code3"
            type="tel"
            id="code3"
            autocomplete="off"
            mask="0"
            (input)="onInput($event, 3)"
            (keyup)="onPressDeleteKey($event, 2)"
          />
        </nz-form-control>
      </nz-form-item>
      <nz-form-item>
        <nz-form-control [nzSpan]="4">
          <input
            #input
            required
            nz-input
            formControlName="code4"
            type="tel"
            id="code4"
            autocomplete="off"
            mask="0"
            (input)="onInput($event, 4)"
            (keyup)="onPressDeleteKey($event, 3)"
          />
        </nz-form-control>
      </nz-form-item>
      <nz-form-item>
        <nz-form-control [nzSpan]="4">
          <input
            #input
            required
            nz-input
            formControlName="code5"
            type="tel"
            id="code5"
            autocomplete="off"
            mask="0"
            (input)="onInput($event, 5)"
            (keyup)="onPressDeleteKey($event, 4)"
          />
        </nz-form-control>
      </nz-form-item>
      <nz-form-item>
        <nz-form-control [nzSpan]="4">
          <input
            #input
            required
            nz-input
            formControlName="code6"
            type="tel"
            id="code6"
            autocomplete="off"
            mask="0"
            (keyup)="onPressDeleteKey($event, 5)"
          />
        </nz-form-control>
      </nz-form-item>
    </div>
  </form>
</body>

<footer *nzModalFooter>
  <div class="first-step" *ngIf="!isShowSmsConfirm">
    <p>
      Ao cadastrar suas informações, você concorda com os
      <a href="" target="_blank" hel="noopener">Termos e Condições</a> e
      <a href="" target="_blank" hel="noopener">Política de Troca.</a>
    </p>

    <app-primary-button (click)="onSignIn()" [block]="true" [disabled]="form.invalid" [loading]="loadingSignIn"
      >Continuar</app-primary-button
    >
  </div>

  <div class="second-step" *ngIf="isShowSmsConfirm">
    <app-primary-button
      [block]="true"
      [disabled]="formGroupCodeVerify.invalid || loadingCodeBtn"
      (click)="validatePhoneNumberCode()"
      class="center w-100"
      >Entrar</app-primary-button
    >

    <div class="m-t-1">
      <app-secondary-button
        [block]="true"
        [disabled]="countdown > 0 || loadingCodeBtn"
        [loading]="loadingCodeBtn"
        (click)="resendSmsCode()"
        class="center w-100"
      >
        Reenviar código
        <span class="m-l-0" *ngIf="countdown > 0">({{ countdown }}s)</span>
      </app-secondary-button>
    </div>
  </div>
</footer>
