<section class="account-created centerBlock">
  <div class="data">
    <img src="/assets/images/ilustrations/circle-success.svg" alt="Ícone de para ilustrar " />
    <div>
      <h3>Cadastre os métodos de pagamentos</h3>
    </div>
    <div>
      <span> Configure as formas de pagamento para realizar os parcelamentos desejados. </span>
    </div>

    <footer>
      <div class="buttons">
        <div class="primary">
          <app-primary-button (click)="closeModal('NOW')" [block]="true">Configure agora</app-primary-button>
        </div>
        <div class="secondary">
          <p (click)="closeModal('LATER')">Pular</p>
        </div>
      </div>
    </footer>
  </div>
</section>
