<header class="modal-header" *nzModalTitle>
  <h3 class="title-category">Solicitação de cancelamento</h3>
  <span class="example-spacer"></span>
  <img src="../../assets/images/close.svg" (click)="modalRef.close()" class="pointer-cursor" alt="Fechar modal" />
</header>

<section class="modal_confirmation_cancel_order">
  <div class="warningMessage">
    <img src="../../../assets/images/icons/triangle-exclamation.svg" alt="Exclamação" />
    <p class="d-inline">Tem certeza que deseja solicitar o cancelamento desta compra?</p>
  </div>
  <span class="order_number">Pedido nº {{ order.id }}</span>
  <p>O cliente será notificado após o cancelamento ser realizado.</p>
  <form [formGroup]="formGroup">
    <div nz-row>
      <div nz-col nzSpan="24">
        <nz-form-item class="d-flex inputSelect">
          <nz-form-label class="startBlock" [nzNoColon]="true" [nzSm]="24" nzRequired nzFor="reason">
            Motivo do cancelamento
          </nz-form-label>
          <nz-form-control class="w-100" nzErrorTip="Campo obrigatório.">
            <nz-select nzPlaceHolder="Selecione uma opção" id="reason" formControlName="reason">
              <nz-option nzValue="UNAVAILABLE" nzLabel="Produto indisponível"></nz-option>
              <nz-option nzValue="ERROR" nzLabel="Problemas com o pagamento"></nz-option>
              <nz-option nzValue="ITEM_ERROR" nzLabel="Erro nas informações do produto"></nz-option>
              <nz-option nzValue="DELIVERY_ERROR" nzLabel="Impossibilidade de realizar a entrega"></nz-option>
              <nz-option nzValue="DUPLICATED" nzLabel="Pedido duplicado"></nz-option>
              <nz-option nzValue="PICK_ERROR" nzLabel="Cliente não veio retirar o produto"></nz-option>
              <nz-option nzValue="CARRIER_RETURNED" nzLabel="Produto devolvido pela transportadora"></nz-option>
              <nz-option nzValue="STORE_RETURNED" nzLabel="Produto devolvido em loja"></nz-option>
              <nz-option nzValue="OTHERS" nzLabel="Outros motivos"></nz-option>
            </nz-select>
          </nz-form-control>
        </nz-form-item>
      </div>
    </div>
    <div nz-row *ngIf="formGroup.get('reason').value === 'OTHERS'">
      <div nz-col nzSpan="24">
        <nz-form-item>
          <nz-form-label class="startBlock" [nzNoColon]="true" [nzSm]="24" nzRequired nzFor="description">
            Descrição
          </nz-form-label>
          <nz-form-control [nzSm]="24" [nzXs]="24" nzErrorTip="Campo obrigatório.">
            <nz-textarea-count [nzMaxCharacterCount]="200">
              <textarea
                rows="4"
                formControlName="description"
                id="description"
                nz-input
                placeholder="Descreva o motivo do cancelamento"
              ></textarea>
            </nz-textarea-count>
          </nz-form-control>
        </nz-form-item>
      </div>
    </div>
  </form>
</section>

<footer class="modal-footer" *nzModalFooter>
  <div class="buttons">
    <app-secondary-button (click)="modalRef.close()">Voltar</app-secondary-button>
    <button nz-button [disabled]="formGroup.invalid" class="btn-error-primary" (click)="confirmCancellation()">
      Sim, cancelar
    </button>
  </div>
</footer>
