import { Observable } from 'rxjs';
import { AngularFirestore, Query } from '@angular/fire/compat/firestore';
import { Apollo } from 'apollo-angular';
import { Injectable } from '@angular/core';
import {
  GET_SUBSCRIPTION_CHECKOUT_QUERY,
  GET_SUBSCRIPTION_QUERY,
  GET_SUBSCRIPTION__BUSINESS_QUERY,
  LIST_SUBSCRIPTION_BUSINESS,
  LIST_SUBSCRIPTION_QUERY,
} from '../../graphql/queries/subscription.queries';
import { FilterSubscriptionsParamsModel } from '../../models/subscriptions/filter-subscriptions-params.model';
import {
  CREATE_SUBSCRIPTION,
  CREATE_SUBSCRIPTION_CHECKOUT,
  DELETE_SUBSCRIPTION,
  UPDATE_SUBSCRIPTION,
  UPDATE_SUBSCRIPTION_CHECKOUT,
} from '../../graphql/mutations/subscription.mutations';
import { StateManagementService } from '../../state-management/state-management.service';
import { InternalService } from '../internal/internal.service';
import { PaymentInfo } from '../../models/payments/payment-info.model';
import { v4 as uuidv4 } from 'uuid';
import { ItemModel } from '../../models/items/item.model';

@Injectable()
export class SubscriptionsService {
  constructor(
    private readonly apollo: Apollo,
    private readonly firestore: AngularFirestore,
    private $methods: InternalService,
    private $notification: StateManagementService
  ) {}

  public createSubscription(params: any): Observable<any> {
    return this.apollo.mutate({
      mutation: CREATE_SUBSCRIPTION,
      variables: { params },
    });
  }

  public createSubscriptionCheckout(params: any): Observable<any> {
    return this.apollo.mutate({
      mutation: CREATE_SUBSCRIPTION_CHECKOUT,
      variables: { params },
    });
  }

  public updateSubscription(params: any): Observable<any> {
    const id = JSON.parse(JSON.stringify(params.id));
    delete params.id;

    return this.apollo.mutate({
      mutation: UPDATE_SUBSCRIPTION,
      variables: { id: id, params },
    });
  }

  public updateSubscriptionCheckout(params: any): Observable<any> {
    const id = JSON.parse(JSON.stringify(params.id));
    delete params.id;

    return this.apollo.mutate({
      mutation: UPDATE_SUBSCRIPTION_CHECKOUT,
      variables: { id: id, params },
    });
  }

  public getSubscriptionListWithCustomer(customerId: string, marketplaceId: string, sellerId: string): Observable<any> {
    return this.firestore
      .collection(`marketplaces/${marketplaceId}/sellers/${sellerId}/subscriptions`, (ref) =>
        ref.where('customerId', '==', customerId).orderBy('insertedAt', 'desc')
      )
      .valueChanges() as Observable<any>;
  }

  public deleteSubscription(id: string | string[]): Observable<any> {
    return this.apollo.mutate({
      mutation: DELETE_SUBSCRIPTION,
      variables: { id },
    });
  }

  public getSubscriptionListApollo(filter?: FilterSubscriptionsParamsModel): Observable<any> {
    return this.apollo.watchQuery<Query>({
      query: LIST_SUBSCRIPTION_QUERY,
      variables: { filter },
      fetchPolicy: 'network-only',
    }).valueChanges;
  }

  public getSubscriptionListBusiness(filter?: FilterSubscriptionsParamsModel): Observable<any> {
    return this.apollo.watchQuery<Query>({
      query: LIST_SUBSCRIPTION_BUSINESS,
      variables: { filter },
      fetchPolicy: 'cache-and-network',
    }).valueChanges;
  }

  public getDetailSubscription(id: string): Observable<any> {
    return this.apollo.watchQuery<Query>({
      query: GET_SUBSCRIPTION__BUSINESS_QUERY,
      variables: { id },
      fetchPolicy: 'cache-and-network',
    }).valueChanges;
  }

  public getDetailSubscriptionCheckout(id: string): Observable<any> {
    return this.apollo.watchQuery<Query>({
      query: GET_SUBSCRIPTION_CHECKOUT_QUERY,
      variables: { id },
      fetchPolicy: 'cache-and-network',
    }).valueChanges;
  }

  public getSubscriptionFilterList(filter?: {
    beforeOrEqual?: string;
    afterOrEqual?: string;
    sellerId?: string;
    customerId?: string;
  }): Observable<any> {
    return this.apollo.watchQuery<Query>({
      query: LIST_SUBSCRIPTION_QUERY,
      variables: {
        afterOrEqual: filter.afterOrEqual,
        beforeOrEqual: filter.beforeOrEqual,
        sellerId: filter.sellerId,
        customerId: filter.customerId,
      },
      fetchPolicy: 'cache-and-network',
    }).valueChanges;
  }

  public getSubscription(id: string): Observable<any> {
    return this.apollo.watchQuery<Query>({
      query: GET_SUBSCRIPTION_QUERY,
      variables: { id },
      fetchPolicy: 'cache-and-network',
    }).valueChanges;
  }

  public createPayload(info: PaymentInfo): any {
    const items = info.subscription.items.map((i: any) => {
      const item = new ItemModel();
      item.description = i.description;
      item.itemId = i.item?.id;
      item.quantity = i.quantity;
      item.unitPriceCents = i.unitPriceCents;

      if (item.itemId === undefined) {
        delete item.itemId;
      }

      return item;
    });

    let data = {
      id: info?.subscription.id,
      method: info?.method,
      items,
      sessionId: uuidv4(),
      redirectTo: info?.redirectTo,
      externalId: info?.externalId,
      sellerId: info?.subscription.seller?.id,
      schedulePlans: info?.subscription?.schedulePlans,
      startDate: info?.subscription?.startDate,
      endDate: info?.subscription?.endDate,
    };

    if (info.customer.id) {
      delete info.customer.id;
    }

    data = Object.assign(data, { customer: info.customer });

    if (info.method === 'CREDIT_CARD') {
      if (info.creditCard?.id) {
        data = Object.assign(data, {
          creditCardId: info?.creditCard.id,
          securityCode: info?.creditCard?.securityCode,
        });
      } else {
        data = Object.assign(data, {
          creditCard: {
            token: info.token,
            holderName: info.customer?.name,
            expirationMonth: info.creditCard?.expirationMonth,
            expirationYear: info.creditCard?.expirationYear,
            securityCode: info.creditCard?.securityCode,
          },
        });
      }
    }

    return data;
  }

  public onSignSubscription(payload: any): void {
    this.updateSubscriptionCheckout(payload).subscribe({
      next: (response) => {
        const subscription = response.data.updateSubscription;

        if (subscription) {
          if (subscription?.status === 'ACTIVE') {
            this.$notification.setTypeResult('RECEIPT');
            if (payload.redirectTo) {
              window.location.href = payload.redirectTo;
            }
          }

          if (subscription?.status === 'WAITING' || subscription?.status === 'FAILING') {
            this.$notification.setTypeResult('ERROR');
            this.$methods.treatError(null);
          }
        } else {
          this.$methods.treatError(response);
        }
        this.$notification.setLoading(false);
      },
      error: (error) => {
        this.$methods.treatError(error);
        this.$notification.setLoading(false);
        throw new Error(error);
      },
    });
  }
}
