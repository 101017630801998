import { NzModalRef } from 'ng-zorro-antd/modal';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-modal-avaliation-coupon',
  templateUrl: './modal-avaliation-coupon.component.html',
  styleUrls: ['./modal-avaliation-coupon.component.scss'],
})
export class ModalAvaliationCouponComponent implements OnInit {
  public formGroup!: FormGroup;

  constructor(private readonly fb: FormBuilder, private readonly $modalRef: NzModalRef) {}

  public ngOnInit(): void {
    this.createForm();
  }

  private createForm(): void {
    this.formGroup = this.fb.group({
      rating: new FormControl<number>(null, [Validators.required, Validators.min(1), Validators.max(5)]),
    });
  }

  public closeModal(hasData: boolean): void {
    if (hasData) {
      this.$modalRef.close(this.formGroup.value);
    } else {
      this.$modalRef.close();
    }
  }
}
