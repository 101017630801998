<section class="modal_confirmation_delivery_order">
  <div class="drawer-close">
    <img src="/assets/icons/xmark.svg" alt="Fechar modal" (click)="drawerRef.close()" />
  </div>

  <div class="header">
    <h1 class="no-margin-bottom">Confirmar entrega do produto?</h1>
  </div>

  <div class="order-wrapper">
    <span class="order_number"
      >Pedido nº <br />
      {{ order.id }}</span
    >
  </div>
  <p>
    Certifique-se de que todos os itens foram entregues ao cliente. <br />
    Essa confirmação não poderá ser desfeita.
  </p>

  <div class="w-100 m-t-1">
    <button class="modal-button modal-button__confirm" *ngIf="!isShowSuccessButton" (click)="closeModal(true)">
      Confirmar entrega
    </button>

    <button *ngIf="isShowSuccessButton" class="modal-button modal-button__check">
      <img src="/assets/icons/check.svg" alt="Ícone de sucesso" />
    </button>
  </div>
</section>
