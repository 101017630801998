import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'deliveryStatus',
})
export class DeliveryStatusPipe implements PipeTransform {
  transform(value: any, ...args: string[]): string {
    if (value) {
      const methods: any = {
        CREATED: 'Realizado',
        IN_SEPARATION: 'Em separação',
        IN_ROUTE: 'Em rota',
        READY_FOR_PICKUP: 'Disponível',
        DELIVERED: 'Entregue',
        RETURNED: 'Devolvido',
        RETURNING: 'Retornando',
        EXPIRED: 'Expirado',
      };

      return methods[value] || '';
    }

    return '';
  }
}
