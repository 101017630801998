<div class="center p-t-2 p-b-2" *ngIf="loading">
  <nz-spin nzSize="large"></nz-spin>
</div>
<main id="share-coupon" *ngIf="!loading">
  <section>
    <nz-row>
      <nz-col nzSpan="12">
        <span class="title-text">Compartilhamento</span>
      </nz-col>
      <nz-col nzSpan="12" class="endBlock">
        <img
          src="/assets/icons/xmark.svg"
          alt="Ícone para fechar o modal"
          class="pointer-cursor"
          (click)="closeModal()"
        />
      </nz-col>
    </nz-row>
  </section>

  <nz-divider></nz-divider>

  <section *ngIf="showAlert">
    <div [ngClass]="typeAlert === 'success' ? 'alert alert__success' : 'alert alert__error'">
      <img
        [src]="
          typeAlert === 'success'
            ? '../../../assets/icons/circle-check.svg'
            : '../../../assets/icons/circle-exclamation.svg'
        "
        [alt]="typeAlert === 'success' ? 'Ícone de aprovado' : 'Ícone de reprovado'"
      />
      <span>{{ alertMessage }}</span>
    </div>
  </section>

  <section>
    <nz-row>
      <nz-col nzSpan="24" class="d-evenly">
        <div
          *ngFor="let method of methods; trackBy: trackByFn"
          (click)="sendToPlatform(method.type)"
          class="pointer-cursor"
        >
          <img [src]="method.imageUrl" alt="Imagem dos métodos" />
        </div>
      </nz-col>
    </nz-row>
  </section>

  <nz-divider></nz-divider>

  <section>
    <form [formGroup]="formGroup">
      <nz-row [nzGutter]="isMobile ? 0 : 24">
        <nz-col [nzXs]="24" [nzSm]="24" [nzMd]="14" [nzLg]="14" [nzXl]="14" [nzXXl]="14">
          <nz-form-item>
            <nz-form-control [nzSm]="24" [nzXs]="24">
              <nz-input-group nzSize="large">
                <input nz-input type="text" formControlName="url" id="url" />
              </nz-input-group>
            </nz-form-control>
          </nz-form-item>
        </nz-col>
        <nz-col [nzXs]="24" [nzSm]="24" [nzMd]="10" [nzLg]="10" [nzXl]="10" [nzXXl]="10" class="endBlock">
          <app-secondary-button
            iconImg="/assets/icons/clone.svg"
            alt="Ícone de copiar"
            [block]="isMobile"
            class="w-100 copy-btn"
            (click)="copyContent()"
          >
            Copiar
          </app-secondary-button>
        </nz-col>
      </nz-row>
    </form>
  </section>
</main>
