<header>
  <div class="row">
    <button class="button button__button--left">
      <span nz-icon nzType="left" nzTheme="outline"></span>
    </button>

    <div class="title">
      <nz-avatar *ngIf="logoPath" [nzSize]="40" [nzSrc]="logoPath"></nz-avatar>
      <h1>{{ title }}</h1>
    </div>

    <button class="button button__button--right">
      <span nzType="right" nz-icon *ngIf="icon" [nzType]="icon"></span>
    </button>
  </div>

  <div *ngIf="hasFooterHeader" class="footer-header">
    <p (click)="clickItemAction(data)" *ngFor="let data of items">
      {{ data.name }}
    </p>
  </div>
</header>
