import { gql } from 'apollo-angular';

const VERIFY_USER_LOGIN_QUERY = gql`
  query VerifyUserLogin($signIn: String, $platform: PlatformEnum) {
    verifyUserLogin(signIn: $signIn, platform: $platform) {
      id
      email
      phone
      hasPassword
      userType
    }
  }
`;

const USER_ENTITY_QUERY = gql`
  query CurrentUser($id: String) {
    currentUser(id: $id) {
      id
      email
      phone
      name
    }
  }
`;

export { VERIFY_USER_LOGIN_QUERY, USER_ENTITY_QUERY };
