export class ResultListResponseCoupons {
  public pageSize: number;
  public page: number;
  public totalPages: number;
  public total: number;
  public results: CouponModel[];
}

export class CouponModel {
  public id?: string = '';
  public sellerId?: string = '';
  public type: CouponType = CouponType.PERCENT;
  public code: string = '';
  public discountCents: number = 0;
  public percent: number = 0;
  public maxCoupons: number = 0;
  public maxDiscountCents: number = 0;
  public maxDiscountCentsPerOrder?: number = 0;
  public minTotalCentsForDiscount?: number = 0;
  public maxCouponsPerCpf?: number = 0;
  public maxDiscountCentsPerCpf?: number = 0;
  public startDate?: string = '';
  public endDate?: string = '';
  public usedCoupons?: number = 0; // Count how many times used this coupon on payment.
  public insertedAt?: string = '';
  public updatedAt?: string = '';
  public couponDiscountsCount?: number = 0;
}

enum CouponType {
  CENTS = 'CENTS',
  PERCENT = 'PERCENT',
}
