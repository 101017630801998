<footer>
  <nz-collapse nzGhost [nzExpandIconPosition]="'right'" *ngIf="isShowCollapse">
    <nz-collapse-panel
      [nzHeader]="activePanel ? 'Esconder detalhes' : 'Ver detalhes'"
      [nzExpandedIcon]="expandedIconTemplate"
      (click)="onClickCollapseIcon('#imgElementAllCategory')"
    >
      <div class="summary-container" (click)="onClickCollapseIcon('#imgElementAllCategory')">
        <div class="summary">
          <h2 class="m-b-0-1">Resumo</h2>
          <div class="between m-b-0-1">
            <span>Subtotal produtos</span>
            <span class="subtotal">{{ totalValue / 100 | currency : 'R$' }}</span>
          </div>
          <div class="discount-content between m-b-0-1">
            <span>Descontos</span>
            <span class="discount"> {{ 0 | currency : 'R$' }}</span>
          </div>
        </div>

        <div class="between total m-b-0-1">
          <span><strong>Total</strong></span>
          <span class="total">{{ totalValue / 100 | currency : 'R$' }}</span>
        </div>
      </div>
    </nz-collapse-panel>
  </nz-collapse>
  <ng-template #expandedIconTemplate>
    <svg
      id="imgElementAllCategory"
      class="icon-collapse-template"
      width="16"
      height="16"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9 6.5L15 12.5L9 18.5"
        stroke="#6D54FC"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  </ng-template>

  <div *ngIf="totalValue && !activePanel" class="between totalValue">
    <span>Total</span>
    <span> {{ totalValue / 100 | currency : 'R$' }}</span>
  </div>
  <div class="buttons">
    <app-primary-button
      *ngIf="primaryButton"
      [disabled]="disabledPrimaryButton"
      [block]="true"
      (click)="callToAction()"
      >{{ textPrimaryButton }}</app-primary-button
    >

    <button *ngIf="paymentButton" nz-button [disabled]="disabledPaymentButton" (click)="callToAction()" nzBlock>
      {{ textPaymentButton }}
    </button>

    <div *ngIf="secondaryButton" class="m-t-1">
      <app-secondary-button [disabled]="disabledSecondaryButton" [block]="true">{{
        textSecondaryButton
      }}</app-secondary-button>
    </div>
  </div>
</footer>
