import { SidenavModel } from '../models/utils/sidenav.model';

export const SYNAPSES_SIDENAV_FINANCE: SidenavModel[] = [
  {
    title: 'Visão Geral',
    svg: '/assets/img/grid.svg',
    route: '/internal/dashboard',
    disable: false,
  },
  {
    title: 'Pagamentos',
    svg: '/assets/img/money-stack.svg',
    route: '/internal/payments',
    margin: true,
    disable: false,
  },
  {
    title: 'B2B',
    svg: '/assets/img/shop.svg',
    disable: true,
  },
  {
    title: 'Sellers',
    svg: '/assets/img/user.svg',
    route: '/internal/sellers',
    margin: true,
    disable: false,
  },
  {
    title: 'Bloqueados',
    svg: '/assets/img/user-lock.svg',
    route: '/internal/blacklist-b2b',
    margin: true,
    disable: false,
  },
  {
    title: 'Cancelamentos',
    svg: '/assets/img/ban.svg',
    route: '/internal/cancellments',
    margin: true,
    disable: false,
  },
  {
    title: 'Saques',
    svg: '/assets/img/money-withdrawal.svg',
    route: '/internal/withdrawals',
    margin: true,
    disable: false,
  },
  {
    title: 'Recorrências',
    svg: '/assets/img/loop.svg',
    route: '/internal/subscriptions',
    margin: true,
    disable: false,
  },
  {
    title: '',
    disable: false,
  },
  {
    title: 'B2C',
    svg: '/assets/img/mobile.svg',
    disable: true,
  },
  {
    title: 'Customers',
    svg: '/assets/img/user.svg',
    route: '/internal/customers',
    margin: true,
    disable: false,
  },
  {
    title: 'Bloqueados',
    svg: '/assets/img/user-lock.svg',
    route: '/internal/blacklist-b2c',
    margin: true,
    disable: false,
  },
  {
    title: 'Cancelamentos',
    svg: '/assets/img/ban.svg',
    route: '/internal/cancellments',
    margin: true,
    disable: false,
  },
  {
    title: 'Taxas',
    svg: '/assets/img/taxes.svg',
    route: '/internal/fees',
    margin: true,
    disable: false,
  },
];

export const SYNAPSES_SIDENAV_COMPLIANCE: SidenavModel[] = [
  {
    title: 'Visão Geral',
    svg: '/assets/img/grid.svg',
    route: '/internal/dashboard',
    disable: false,
  },
  {
    title: 'Pagamentos',
    svg: '/assets/img/money-stack.svg',
    route: '/internal/payments',
    disable: false,
  },
  {
    title: 'B2B',
    svg: '/assets/img/shop.svg',
    disable: true,
  },
  {
    title: 'Sellers',
    svg: '/assets/img/user.svg',
    route: '/internal/sellers',
    margin: true,
    disable: false,
  },
  {
    title: 'Bloqueados',
    svg: '/assets/img/user-lock.svg',
    route: '/internal/blacklist-b2b',
    margin: true,
    disable: false,
  },
  {
    title: 'Cancelamentos',
    svg: '/assets/img/ban.svg',
    route: '/internal/cancellments',
    margin: true,
    disable: false,
  },
  {
    title: 'Saques',
    svg: '/assets/img/money-withdrawal.svg',
    route: '/internal/withdrawals',
    margin: true,
    disable: false,
  },
  {
    title: 'Recorrências',
    svg: '/assets/img/loop.svg',
    route: '/internal/subscriptions',
    margin: true,
    disable: false,
  },
  {
    title: '',
    disable: false,
  },
  {
    title: 'B2C',
    svg: '/assets/img/mobile.svg',
    disable: true,
  },
  {
    title: 'Customers',
    svg: '/assets/img/user.svg',
    route: '/internal/customers',
    margin: true,
    disable: false,
  },
  {
    title: 'Bloqueados',
    svg: '/assets/img/user-lock.svg',
    route: '/internal/blacklist-b2c',
    margin: true,
    disable: false,
  },
  {
    title: 'Cancelamentos',
    svg: '/assets/img/ban.svg',
    route: '/internal/cancellments',
    margin: true,
    disable: false,
  },
];

export const SYNAPSES_SIDENAV_MARKETING: SidenavModel[] = [
  {
    title: 'Visão Geral',
    svg: '/assets/img/grid.svg',
    route: '/internal/dashboard',
    disable: false,
  },
  {
    title: 'Banner',
    svg: '/assets/img/banner-menu.svg',
    route: '/internal/events/banners',
    margin: true,
    disable: false,
  },
  {
    title: 'Bilheteria',
    svg: '/assets/img/box-office.svg',
    route: '/internal/events/box-office',
    disable: false,
  },
  {
    title: 'Agrupamento',
    svg: '/assets/img/group-menu.svg',
    route: '/internal/events/banner-grouping',
    margin: true,
    disable: false,
  },
];

export const SYNAPSES_SIDENAV_PARKING: SidenavModel[] = [
  {
    title: 'Visão Geral',
    svg: '/assets/img/grid.svg',
    route: '/internal/dashboard',
    disable: false,
  },
  {
    title: 'Atendimento',
    svg: '/assets/img/contact-menu.svg',
    externalLink: 'https://gen.movidesk.com/',
    disable: false,
  },
  {
    title: 'Financeiro',
    svg: '/assets/img/finance-menu.svg',
    route: '/internal/parking/financial',
    disable: false,
  },
  {
    title: 'Operacional',
    svg: '/assets/img/operationa-menu.svg',
    route: '/internal/parking/operational',
    disable: false,
  },
  {
    title: 'Jornada do usuário',
    svg: '/assets/img/user-flow-menu.svg',
    route: '/internal/parking/journey-mapping',
    disable: false,
  },
  {
    title: 'Tabela de preços',
    svg: '/assets/img/price-table.svg',
    route: '/internal/parking/price-table',
    disable: false,
  },
];

export const SYNAPSES_SIDENAV_CX: SidenavModel[] = [
  {
    title: 'Visão Geral',
    svg: '/assets/img/grid.svg',
    route: '/internal/dashboard',
    disable: false,
  },
  {
    title: 'Atendimento',
    svg: '/assets/img/contact-menu.svg',
    externalLink: 'https://gen.movidesk.com/',
    disable: false,
  },
  {
    title: 'Pagamentos',
    svg: '/assets/img/money-stack.svg',
    route: '/internal/payments',
    disable: false,
  },
  {
    title: '',
    disable: false,
  },
  {
    title: 'Estacionamento',
    svg: '/assets/img/parking.svg',
    disable: true,
  },
  {
    title: 'Financeiro',
    svg: '/assets/img/finance-menu.svg',
    route: '/internal/parking/financial',
    disable: false,
    margin: true,
  },
  {
    title: 'Operacional',
    svg: '/assets/img/operationa-menu.svg',
    route: '/internal/parking/operational',
    disable: false,
    margin: true,
  },
  {
    title: 'Jornada do usuário',
    svg: '/assets/img/user-flow-menu.svg',
    route: '/internal/parking/journey-mapping',
    disable: false,
    margin: true,
  },
  {
    title: 'Tabela de preços',
    svg: '/assets/img/price-table.svg',
    route: '/internal/parking/price-table',
    disable: false,
    margin: true,
  },
  {
    title: 'Promoções',
    svg: '/assets/img/parking-campaign.svg',
    route: '/internal/parking/malls/parking-campaign-home',
    disable: false,
    margin: true,
  },
  {
    title: 'Garagem',
    svg: '/assets/img/parking-white.svg',
    route: '/internal/parking/malls',
    disable: false,
    margin: true,
  },
  {
    title: '',
    disable: false,
  },
  {
    title: 'B2B',
    svg: '/assets/img/shop.svg',
    disable: true,
  },
  {
    title: 'Sellers',
    svg: '/assets/img/user.svg',
    route: '/internal/sellers',
    margin: true,
    disable: false,
  },
  {
    title: 'Bloqueados',
    svg: '/assets/img/user-lock.svg',
    route: '/internal/blacklist-b2b',
    margin: true,
    disable: false,
  },
  {
    title: 'Recorrências',
    svg: '/assets/img/loop.svg',
    route: '/internal/subscriptions',
    margin: true,
    disable: false,
  },
  {
    title: 'Contatos',
    svg: '/assets/img/contacts.svg',
    route: '/internal/contacts',
    margin: true,
    disable: false,
  },
  {
    title: 'Produtos',
    svg: '/assets/img/product.svg',
    route: '/internal/items',
    margin: true,
    disable: false,
  },
  {
    title: '',
    disable: false,
  },
  {
    title: 'B2C',
    svg: '/assets/img/mobile.svg',
    disable: true,
  },
  {
    title: 'Customers',
    svg: '/assets/img/user.svg',
    route: '/internal/customers',
    margin: true,
    disable: false,
  },
  {
    title: 'Bloqueados',
    svg: '/assets/img/user-lock.svg',
    route: '/internal/blacklist-b2c',
    margin: true,
    disable: false,
  },
  {
    title: 'Cupom',
    svg: '/assets/img/cupom.svg',
    route: '/internal/fidelity',
    margin: true,
    disable: false,
  },
  {
    title: 'Campanha',
    svg: '/assets/img/campaign.svg',
    route: '/internal/fidelity/campaign',
    margin: true,
    disable: false,
  },
];

export const SYNAPSES_SIDENAV_ADM: SidenavModel[] = [
  {
    title: 'Visão Geral',
    svg: '/assets/img/grid.svg',
    route: '/internal/dashboard',
    disable: false,
  },
  {
    title: 'Atendimento',
    svg: '/assets/img/contact-menu.svg',
    externalLink: 'https://gen.movidesk.com/',
    disable: false,
  },
  {
    title: 'Banner',
    svg: '/assets/img/banner-menu.svg',
    route: '/internal/events/banners',
    disable: false,
  },
  {
    title: 'Bilheteria',
    svg: '/assets/img/box-office.svg',
    route: '/internal/events/box-office',
    disable: false,
  },
  {
    title: 'Agrupamento',
    svg: '/assets/img/group-menu.svg',
    route: '/internal/events/banner-grouping',
    disable: false,
  },
  {
    title: 'Pagamentos',
    svg: '/assets/img/money-stack.svg',
    route: '/internal/payments',
    disable: false,
  },
  {
    title: 'Cancelamentos',
    svg: '/assets/img/ban.svg',
    route: '/internal/cancellments',
    margin: false,
    disable: false,
  },
  {
    title: 'Pedidos',
    svg: '/assets/img/product.svg',
    route: '/internal/orders',
    margin: false,
    disable: false,
  },
  {
    title: 'Gen Box',
    svg: '/assets/img/user-lock.svg',
    route: '/internal/orders/gen-box',
    margin: false,
    disable: false,
  },
  {
    title: '',
    disable: false,
  },
  {
    title: 'Estacionamento',
    svg: '/assets/img/parking.svg',
    disable: true,
  },
  {
    title: 'Financeiro',
    svg: '/assets/img/finance-menu.svg',
    route: '/internal/parking/financial',
    disable: false,
    margin: true,
  },
  {
    title: 'Operacional',
    svg: '/assets/img/operationa-menu.svg',
    route: '/internal/parking/operational',
    disable: false,
    margin: true,
  },
  {
    title: 'Jornada do usuário',
    svg: '/assets/img/user-flow-menu.svg',
    route: '/internal/parking/journey-mapping',
    disable: false,
    margin: true,
  },
  {
    title: 'Tabela de preços',
    svg: '/assets/img/price-table.svg',
    route: '/internal/parking/price-table',
    disable: false,
    margin: true,
  },
  {
    title: 'Promoções',
    svg: '/assets/img/parking-campaign.svg',
    route: '/internal/parking/malls/parking-campaign-home',
    disable: false,
    margin: true,
  },
  {
    title: 'Garagem',
    svg: '/assets/img/parking-white.svg',
    route: '/internal/parking/malls',
    disable: false,
    margin: true,
  },
  {
    title: '',
    disable: false,
  },
  {
    title: 'B2B',
    svg: '/assets/img/shop.svg',
    disable: true,
  },
  {
    title: 'Sellers',
    svg: '/assets/img/user.svg',
    route: '/internal/sellers',
    margin: true,
    disable: false,
  },
  {
    title: 'Bloqueados',
    svg: '/assets/img/user-lock.svg',
    route: '/internal/blacklist-b2b',
    margin: true,
    disable: false,
  },
  {
    title: 'Recorrências',
    svg: '/assets/img/loop.svg',
    route: '/internal/subscriptions',
    margin: true,
    disable: false,
  },
  {
    title: 'Contatos',
    svg: '/assets/img/contacts.svg',
    route: '/internal/contacts',
    margin: true,
    disable: false,
  },
  {
    title: 'Produtos',
    svg: '/assets/img/product.svg',
    route: '/internal/items',
    margin: true,
    disable: false,
  },
  {
    title: 'Saques',
    svg: '/assets/img/money-withdrawal.svg',
    route: '/internal/withdrawals',
    margin: true,
    disable: false,
  },
  {
    title: '',
    disable: false,
  },
  {
    title: 'B2C',
    svg: '/assets/img/mobile.svg',
    disable: true,
  },
  {
    title: 'Customers',
    svg: '/assets/img/user.svg',
    route: '/internal/customers',
    margin: true,
    disable: false,
  },
  {
    title: 'Bloqueados',
    svg: '/assets/img/user-lock.svg',
    route: '/internal/blacklist-b2c',
    margin: true,
    disable: false,
  },
  {
    title: 'Cupom',
    svg: '/assets/img/cupom.svg',
    route: '/internal/fidelity',
    margin: true,
    disable: false,
  },
  {
    title: 'Campanha',
    svg: '/assets/img/campaign.svg',
    route: '/internal/fidelity/campaign',
    margin: true,
    disable: false,
  },
];
