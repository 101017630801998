/* eslint-disable no-async-promise-executor */
import moment from 'moment-timezone';
import { Observable, take } from 'rxjs';
import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { AngularFireFunctions } from '@angular/fire/compat/functions';

import { getTimeZone3 } from '../../utils/time';
import { ResponseModel } from './../../models/response/response.model';
import { ChartDataModel } from '../../models/reports/charts-data/charts-data.model';
import { ReportsItemsModel } from '../../models/reports/reports-items/reports-items.model';
import { SelectedDatesModel } from '../../models/reports/selected-dates/selected-dates.model';
import { ReportsDashboardModel } from '../../models/reports/reports-dashboard/reports-dashboard.model';
import { ReportsDashboardParamsModel } from './../../models/reports/reports-dashboard/reports-dashboard-params.model';

@Injectable()
export class ReportsService {
  public checkArray: Array<string> = [];

  constructor(private $functions: AngularFireFunctions, private $fStore: AngularFirestore) {}

  public getReport(data: SelectedDatesModel, type: string, sellerId: string): Observable<any> {
    if (type === 'item') {
      return this.getItemsReport(data, sellerId);
    } else {
      return this.getCategoriesReport(data, sellerId);
    }
  }

  public getSalesReport(data: SelectedDatesModel, sellerId: string): Observable<any> {
    data.sellerId = sellerId;
    return this.$functions.httpsCallable('getSalesReport')(data);
  }

  public getItemsReport(data: SelectedDatesModel, sellerId: string): Observable<any> {
    data.sellerId = sellerId;
    return this.$functions.httpsCallable('getItemsReport')(data);
  }

  public getCategoriesReport(data: SelectedDatesModel, sellerId: string): Observable<any> {
    data.sellerId = sellerId;
    return this.$functions.httpsCallable('getCategoriesReport')(data);
  }

  public getDashboardReport(
    data: ReportsDashboardParamsModel,
    sellerId: string
  ): Observable<ResponseModel<ReportsDashboardModel>> {
    data.sellerId = sellerId;
    const timezone = getTimeZone3();
    return this.$functions.httpsCallable('getBusinessReport')({ data, timezone });
  }

  public async getTop5Charts(
    Items: any,
    startDate: string,
    endDate: string,
    type: string,
    sellerId: string
  ): Promise<any> {
    const promise = await new Promise(async (resolve, reject) => {
      // get top 5 items from given period.
      const topFive = Items.sort((a: { quantity: number }, b: { quantity: number }) => b.quantity - a.quantity).slice(
        0,
        5
      );

      const datesArray: Array<any> = [];
      const weeksArray: Array<any> = [];
      let daylist: Array<string> = [];
      let datasetValue: Array<ChartDataModel> = [];

      // loop through  topfive,, creating a chart object for each index and setting its color
      topFive.forEach((element: ReportsItemsModel) => {
        const unavailable: string = 'Venda avulsa';
        const name: string = element.description ? element.description : unavailable;

        const chartData = new ChartDataModel();
        chartData.name = name;
        chartData.series = [{ name: '', value: 0 }];
        datasetValue.push(chartData);
      });

      // get how many days passed between startDate and endDate
      daylist = this.getDates(new Date(startDate), new Date(endDate));

      const passedDays: number = daylist.length;

      // separates periods into intervals, based on days passed
      await new Promise<void>(async (resolve, reject) => {
        if (passedDays >= 1 && passedDays <= 7) {
          // days intervals, each label wiil be 1 day

          await Promise.all(
            daylist.map(async (date: string) => {
              // loop through the days of the period

              const data: SelectedDatesModel = {
                startDate: moment(date).set({ hour: 0, minute: 0, second: 0 }).format('YYYY-MM-DDTHH:mm:ss'),
                endDate: moment(date).set({ hour: 23, minute: 59, second: 59 }).format('YYYY-MM-DDTHH:mm:ss'),
              };

              datasetValue.forEach((element) => {
                element.series.push({ value: 0, name: moment(data.startDate).format('DD/MM/YYYY') });
              });

              if (type === 'item') {
                await new Promise<void>(async (resolve, reject) => {
                  // create promisse for the request
                  const itemsReport = await this.getItemsReport(data, sellerId).pipe(take(1)).toPromise();
                  // request on API from the day of the loop
                  if (itemsReport) {
                    itemsReport.body.map((element: { description: any; totalCents: any }) => {
                      const name = element.description ? element.description : 'Venda avulsa';

                      if (datasetValue.filter((e) => e.name === name).length > 0) {
                        // verifiy if one of the top 5 item its on the result, if so, fill the value on the data array
                        const dataIndex: number = datasetValue.findIndex((e) => e.name === name);
                        const dateIndex: number = datasetValue[dataIndex].series.findIndex(
                          (e: { value: number; name: string }) => e.name === moment(data.startDate).format('DD/MM/YYYY')
                        );
                        datasetValue[dataIndex].series[dateIndex] = {
                          value: element.totalCents,
                          name: moment(data.startDate).format('DD/MM/YYYY'),
                        };
                      }
                    });

                    resolve(); // resolve promisse when request is over
                  } else {
                    reject();
                  }
                });
              } else {
                // same flow, but with categories chart
                await new Promise<void>(async (resolve, reject) => {
                  const categoryReport = await this.getCategoriesReport(data, sellerId).pipe(take(1)).toPromise();

                  if (categoryReport) {
                    categoryReport.body.map((element: { description: any; totalCents: any }) => {
                      const name = element.description ? element.description : 'Não categorizado';
                      if (datasetValue.filter((e) => e.name === name).length > 0) {
                        // verifiy if one of the top 5 item its on the result, if so, fill the value on the data array
                        const dataIndex: number = datasetValue.findIndex((e) => e.name === name);
                        const dateIndex: number = datasetValue[dataIndex].series.findIndex(
                          (e: { value: number; name: string }) => e.name === moment(data.startDate).format('DD/MM/YYYY')
                        );
                        datasetValue[dataIndex].series[dateIndex] = {
                          value: element.totalCents,
                          name: moment(data.startDate).format('DD/MM/YYYY'),
                        };
                      }
                    });

                    resolve();
                  } else {
                    reject();
                  }
                });
              }
            })
          )
            .then(() => {
              resolve();
            })
            .catch(() => {
              reject();
            });
        } else if (passedDays > 7 && passedDays <= 31) {
          // weeks period
          let aux: number = passedDays;

          while (aux > 0) {
            if (aux >= 7) {
              // removes 7 days from the days array and put it into the weeks array, until there are no weeks left
              weeksArray.push(daylist.slice(0, 7));
              daylist.splice(0, 7);
              aux = aux - 7;
            } else {
              // fill the remaning days into dateArrays
              datesArray.push(daylist.slice(0, 1));
              daylist.splice(0, 1);
              aux = aux - 1;
            }
          }

          await Promise.all(
            weeksArray.map(async (element: any) => {
              // loop through the weeks of the period
              const data: SelectedDatesModel = {
                startDate: moment(element[0]).set({ hour: 0, minute: 0, second: 0 }).format('YYYY-MM-DDTHH:mm:ss'), // get the first day of the week
                endDate: moment(element[6]).set({ hour: 23, minute: 59, second: 59 }).format('YYYY-MM-DDTHH:mm:ss'), // last day of the week
              };

              const dateParam: string =
                moment(data.startDate).format('DD/MM/YYYY') + ' - ' + moment(data.endDate).format('DD/MM/YYYY');

              datasetValue.forEach((element) => {
                element.series.push({
                  name: dateParam,
                  value: 0,
                });
              });

              if (type === 'item') {
                await new Promise<void>(async (resolve, reject) => {
                  const itemsReport = await this.getItemsReport(data, sellerId).pipe(take(1)).toPromise();
                  if (itemsReport.status === 200) {
                    itemsReport.body.map((element: { description: any; totalCents: any }, i: any) => {
                      // verifiy if one of the top 5 item its on the result, if so, fill the value on the data array
                      const name = element.description ? element.description : 'Venda avulsa';

                      if (datasetValue.filter((e) => e.name === name).length > 0) {
                        // find index of the position item
                        const index: number = datasetValue.findIndex((e) => e.name === name);

                        // find index of series from the position date
                        const dateIndex: number = datasetValue[index].series.findIndex(
                          (e: { name: string; value: number }) => e.name === dateParam
                        );
                        datasetValue[index].series[dateIndex] = {
                          name: dateParam,
                          value: element.totalCents,
                        };
                      }
                    });
                    resolve(); // resolve promisse when request is over
                  } else {
                    reject();
                  }
                });
              } else {
                // same flow, but with categories chart
                await new Promise<void>(async (resolve, reject) => {
                  const categoriesReport = await this.getCategoriesReport(data, sellerId).pipe(take(1)).toPromise();
                  if (categoriesReport.status === 200) {
                    categoriesReport.body.map((element: { description: any; totalCents: any }) => {
                      const name = element.description ? element.description : 'Não categorizado';

                      if (datasetValue.filter((e) => e.name === name).length > 0) {
                        // find index of the position item
                        const index: number = datasetValue.findIndex((e) => e.name === name);

                        // find index of series from the position date
                        const dateIndex: number = datasetValue[index].series.findIndex(
                          (e: { name: string; value: number }) => e.name === dateParam
                        );
                        datasetValue[index].series[dateIndex] = {
                          name: dateParam,
                          value: element.totalCents,
                        };
                      }
                    });
                    resolve();
                  } else {
                    reject();
                  }
                });
              }
            })
          ).then(async () => {
            const finalIndex = datesArray.length - 1; // execute the same flow on the remaning days ( when the period does not close exact 7-day weeks)
            const startDateParam = typeof datesArray[0] === 'object' ? datesArray[0][0] : datesArray[0];
            const endDateParam =
              typeof datesArray[finalIndex] === 'object' ? datesArray[finalIndex][0] : datesArray[finalIndex];

            const data = {
              startDate: moment(startDateParam).set({ hour: 0, minute: 0, second: 0 }).format('YYYY-MM-DDTHH:mm:ss'),
              endDate: moment(endDateParam).set({ hour: 23, minute: 59, second: 59 }).format('YYYY-MM-DDTHH:mm:ss'),
            };

            const dateParam: string =
              moment(data.startDate).format('DD/MM/YYYY') + ' - ' + moment(data.endDate).format('DD/MM/YYYY');

            datasetValue.forEach((element) => {
              element.series.push({
                name: dateParam,
                value: 0,
              });
            });

            if (type === 'item') {
              const itemsReport = await this.getItemsReport(data, sellerId).pipe(take(1)).toPromise();
              if (itemsReport.status === 200) {
                itemsReport.body.map((element: { description: any; totalCents: any }) => {
                  const name = element.description ? element.description : 'Venda avulsa';

                  if (datasetValue.filter((e) => e.name === name).length > 0) {
                    // find index of the position item
                    const index: number = datasetValue.findIndex((e) => e.name === name);

                    // find index of series from the position date
                    const dateIndex: number = datasetValue[index].series.findIndex(
                      (e: { name: string; value: number }) => e.name === dateParam
                    );
                    datasetValue[index].series[dateIndex] = {
                      name: dateParam,
                      value: element.totalCents,
                    };
                  }
                });
                resolve();
              } else {
                reject();
              }
            } else {
              const categoriesReport = await this.getCategoriesReport(data, sellerId).pipe(take(1)).toPromise();
              if (categoriesReport.status === 200) {
                categoriesReport.body.map((element: { description: any; totalCents: any }) => {
                  const name = element.description ? element.description : 'Não categorizado';
                  if (datasetValue.filter((e) => e.name === name).length > 0) {
                    // find index of the position item
                    const index: number = datasetValue.findIndex((e) => e.name === name);

                    // find index of series from the position date
                    const dateIndex: number = datasetValue[index].series.findIndex(
                      (e: { name: string; value: number }) => e.name === dateParam
                    );
                    datasetValue[index].series[dateIndex] = {
                      name: dateParam,
                      value: element.totalCents,
                    };
                  }
                });
                resolve();
              } else {
                reject();
              }
            }
          });
        } else if (passedDays > 31) {
          const groupedByMonth: Array<string> = this.groupByMonth(daylist); // group days list into month array
          const finalIndexParent: number = Object.keys(groupedByMonth).length - 1; // get the final month of the period

          await new Promise<void>(async (resolve, reject) => {
            let index: number = 0;
            for (const key in groupedByMonth) {
              // loop through the month array
              const child: string = groupedByMonth[key];
              const finalIndex: number = child.length - 1;

              let data: SelectedDatesModel = {
                startDate: moment(child[0]).set({ hour: 0, minute: 0, second: 0 }).format('YYYY-MM-DDTHH:mm:ss'), // first date of the month
                endDate: moment(child[finalIndex]) // last day of the month
                  .set({ hour: 23, minute: 59, second: 59 })
                  .format('YYYY-MM-DDTHH:mm:ss'),
              };

              datasetValue.forEach((element) => {
                element.series.push({
                  name: key,
                  value: 0,
                });
              });

              if (index === finalIndexParent && this.checkArray.length > 0) {
                // in cases when the same month apear two times, but in diferent years ( 05-21 -> 05-22)
                data = {
                  startDate: moment(child[0]).set({ hour: 0, minute: 0, second: 0 }).format('YYYY-MM-DDTHH:mm:ss'),
                  endDate: moment(this.checkArray[this.checkArray.length - 1])
                    .set({ hour: 23, minute: 59, second: 59 })
                    .format('YYYY-MM-DDTHH:mm:ss'),
                };
              }

              if (type === 'item') {
                // same flow
                await new Promise<void>(async (resolve, reject) => {
                  const itemsReport = await this.getItemsReport(data, sellerId).pipe(take(1)).toPromise();
                  if (itemsReport.status === 200) {
                    itemsReport.body.map((element: { description: any; totalCents: any }) => {
                      const name = element.description ? element.description : 'Venda avulsa';

                      if (datasetValue.filter((e) => e.name === name).length > 0) {
                        const index = datasetValue.findIndex((e) => e.name === name);

                        // find index of series from the position date
                        const dateIndex: number = datasetValue[index].series.findIndex(
                          (e: { value: number; name: string }) => e.name === key
                        );
                        datasetValue[index].series[dateIndex] = {
                          name: key,
                          value: element.totalCents,
                        };
                      }
                    });

                    resolve();
                  } else {
                    reject();
                  }
                });
              } else {
                await new Promise<void>(async (resolve, reject) => {
                  const categoriesReport = await this.getCategoriesReport(data, sellerId).pipe(take(1)).toPromise();
                  if (categoriesReport.status === 200) {
                    categoriesReport.body.map((element: { description: any; totalCents: any }) => {
                      const name = element.description ? element.description : 'Não categorizado';

                      if (datasetValue.filter((e) => e.name === name).length > 0) {
                        const index = datasetValue.findIndex((e) => e.name === name);

                        // find index of series from the position date
                        const dateIndex: number = datasetValue[index].series.findIndex(
                          (e: { value: number; name: string }) => e.name === key
                        );
                        datasetValue[index].series[dateIndex] = {
                          name: key,
                          value: element.totalCents,
                        };
                      }
                    });
                    resolve();
                  } else {
                    reject();
                  }
                });
              }
            }
            resolve();
          }).then(() => {
            resolve();
          });
        }
      }).then(() => {
        datasetValue.forEach((element) => {
          element.series.push({ value: 0, name: ' ' });
        });

        resolve(datasetValue); // resolve data
      });
    }).then((res) => {
      return res;
    });

    return promise;
  }

  public getDates(start: Date, end: Date): Array<string> {
    const arr: Array<string> = [];

    for (const dt = new Date(start); dt <= end; dt.setDate(dt.getDate() + 1)) {
      arr.push(moment(dt).format('YYYY-MM-DDTHH:mm:ss'));
    }

    return arr;
  }

  public groupByMonth(data: Array<string>): Array<string> {
    const monthNames: Array<string> = [
      'Janeiro',
      'Fevereiro',
      'Março',
      'Abril',
      'Maio',
      'Junho',
      'Julho',
      'Agosto',
      'Setembro',
      'Outubro',
      'Novembro',
      'Dezembro',
    ];

    let jumpPush: boolean = false;
    const array: Array<string> = [];
    const repeatedMounth: Array<string> = [];

    data.forEach((item: string) => {
      const itemDate: Date = new Date(item);
      const itemMonth: string = monthNames[itemDate.getMonth()];
      const check: string = moment(itemDate).endOf('month').format('YYYY-MM-DD');
      const day: string = moment(itemDate).format('YYYY-MM-DD');

      if (repeatedMounth.includes(itemMonth)) {
        jumpPush = true;
        this.checkArray.push(item);
      } else {
        jumpPush = false;
      }

      if (check === day) {
        repeatedMounth.push(itemMonth);
      }

      if (!jumpPush) {
        (array[itemMonth] = array[itemMonth] || []).push(item);
      }
    });

    return array;
  }
}
