<section class="delete_key_success_modal">
  <span nz-icon nzType="check-circle" nzTheme="outline"></span>
  <h2 class="colorPrimary">Chave {{ type }} excluída</h2>
  <p>
    A Chave {{ type }}<br /><b>{{ key }}</b> foi excluída.
  </p>
  <div class="buttons">
    <app-primary-button (click)="modalRef.close()">Entendi</app-primary-button>
  </div>
</section>
