import { Component, Input, OnInit, ViewChild, ElementRef, Output, EventEmitter, AfterViewInit } from '@angular/core';
@Component({
  selector: 'app-image-cropper',
  templateUrl: './image-cropper.component.html',
  styleUrls: ['./image-cropper.component.scss'],
})
export class ImageCropperComponent implements OnInit, AfterViewInit {
  @ViewChild('img') public image: ElementRef<HTMLImageElement>;
  @ViewChild('boundary') public boundary: ElementRef<HTMLDivElement>;
  @ViewChild('cropper') public cropper: ElementRef<HTMLDivElement>;

  @Input() public imgUrl: string;
  @Input() public cropperSize: { width: number; height: number };
  @Input() public resultImgSize: { width: number; height: number };

  @Output() public onChange: EventEmitter<HTMLDivElement> = new EventEmitter<HTMLDivElement>();
  @Output() public getNowImg: EventEmitter<HTMLDivElement> = new EventEmitter<HTMLDivElement>();

  public minCropperSize: { width: number; height: number };
  public zoom: number;

  private initialImageSize: { width: number; height: number };

  public ngOnInit(): void {
    this.setInitialVariables();
    setTimeout(() => {
      this.getNowImg.emit(this.cropper.nativeElement);
    }, 100);
  }

  public ngAfterViewInit(): void {
    this.setCropperMinSize();
  }

  private setInitialVariables(): void {
    this.minCropperSize = {
      width: 520,
      height: 520,
    };
    this.zoom = 0;
    this.onChange;
  }

  private setCropperMinSize(): void {
    this.image.nativeElement.height = this.cropperSize?.height || this.minCropperSize?.height;
  }

  private setInitialImgSize(): void {
    this.initialImageSize = {
      height: this.image?.nativeElement?.height,
      width: this.image?.nativeElement?.width,
    };
  }

  public zoomChange(): void {
    if (!this.initialImageSize?.width || !this.initialImageSize?.height) {
      this.setInitialImgSize();
    }

    this.image.nativeElement.width = this.initialImageSize.width * (this.zoom / 100 + 1);
    this.image.nativeElement.height = this.initialImageSize.height * (this.zoom / 100 + 1);
    this.saveCrop();
  }

  public saveCrop(): void {
    this.onChange.emit(this.cropper.nativeElement);
  }
}
