import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'hidePartialPhone',
})
export class HidePartialPhonePipe implements PipeTransform {
  transform(value: any): any {
    if (!value) {
      return '';
    }

    const phone = value.toString().replace(/[^\d]/g, '');
    const hidden =
      '+' + phone.substr(0, 2) + ' (' + phone.substr(2, 2) + ') ' + phone.substr(4, 1) + '****-' + phone.substr(9, 4);
    return hidden;
  }
}
