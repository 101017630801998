import CryptoJS from 'crypto-js';
import { Injectable } from '@angular/core';

import { environment } from '@gen/environments';

@Injectable({
  providedIn: 'root',
})
export class SingletonSecureStorageService {
  constructor() {}

  public init() {
    Storage.prototype['_setItem'] = Storage.prototype.setItem;
    Storage.prototype['_getItem'] = Storage.prototype.getItem;

    Storage.prototype.setItem = function (key, value) {
      if (environment.production) {
        this._setItem(key, CryptoJS.AES.encrypt(value, environment.CRYPTO_PRIVATE_KEY).toString());
      } else {
        this._setItem(key, value);
      }
    };

    Storage.prototype.getItem = function (key) {
      const value = this._getItem(key);

      if (value) {
        if (environment.production) {
          return CryptoJS.AES.decrypt(value, environment.CRYPTO_PRIVATE_KEY).toString(CryptoJS.enc.Utf8);
        } else {
          return value;
        }
      }

      return null;
    };
  }
}
