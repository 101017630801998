import { Component, Input } from '@angular/core';
import { NzModalRef } from 'ng-zorro-antd/modal';

@Component({
  selector: 'app-modal-warning-delete',
  templateUrl: './modal-warning-delete.component.html',
  styleUrls: ['./modal-warning-delete.component.scss'],
})
export class ModalWarningDeleteComponent {
  @Input() title: string;
  @Input() titleName: string;
  @Input() message: string;
  @Input() buttonMessage: string;

  constructor(public $modalRef: NzModalRef) {}
}
