import { Component, Input } from '@angular/core';
import { NzModalRef } from 'ng-zorro-antd/modal';
import { NzMessageService } from 'ng-zorro-antd/message';

import { PixService } from '../../../services/pix/pix.service';
import { SellerModel } from './../../../models/sellers/sellers.model';
import { StaticQrCodeForm } from './../../../models/pix/staticQrCodeForm.model';

@Component({
  selector: 'app-modal-pix-confirmation',
  templateUrl: './modal-pix-qr-code-confirmation.component.html',
  styleUrls: ['./modal-pix-qr-code-confirmation.component.scss'],
})
export class ModalPixQrCodeConfirmationComponent {
  @Input() key: string;
  @Input() type: KeyType;
  @Input() seller: SellerModel;
  @Input() formData: StaticQrCodeForm;

  public loadingBtn: boolean = false;

  constructor(
    private $pix: PixService,
    public readonly $modalRef: NzModalRef,
    private readonly $message: NzMessageService
  ) {}

  public confirmQrCode(): void {
    this.loadingBtn = true;

    this.$pix
      .createStaticPixCode(
        Math.round(Number(this.formData.value || 0) * 100),
        this.formData.description,
        this.key,
        this.formData.identifier,
        this.seller.id
      )
      .subscribe({
        next: (res) => {
          if (res?.data?.createStaticPixCode) {
            this.$message.success('QR Code criado com sucesso.');
            this.$modalRef.close(res.data.createStaticPixCode);
          }

          this.loadingBtn = false;
        },
        error: (error) => {
          this.loadingBtn = false;
          this.$message.error('Falha ao gerar QR Code. Tente novamente.');
          throw new Error(error);
        },
      });
  }
}
