import { AddressModel } from '../address/address.model';

export class PersonModel {
  public id: string = '';
  public birthDate?: string = '';
  public cpf: string = '';
  public name: string = '';
  public nameMother?: string = '';
  public statusCpf?: string = '';
  public address?: AddressModel = new AddressModel();
}
