import { StoreTag } from './store-tag.model';
import { StoreOpeningHours } from './store-opening-hours';
import { StoreCategoryModel } from './store-category.model';
import { ItemsV2Model } from '../items/V2/ItemV2.model';

export class StoreModel {
  public whatsappNumber?: string = '';
  public callNumber: string = '';
  public displayName: string = '';
  public storeCategory?: StoreCategoryModel = new StoreCategoryModel();
  public storeCategories?: StoreCategoryModel = new StoreCategoryModel();
  public storeCategoryId?: string = '';
  public description: string = '';
  public mainSegment?: string = '';
  public whatsappConfirmedAt?: string = '';
  public logo?: string = '';
  public tags: StoreTag[] = [];
  public outOfHours?: boolean;
  public isVisible?: boolean;
  public recommendedItems?: Array<ItemsV2Model>;
  public openingHours: StoreOpeningHours = new StoreOpeningHours();
  public storePreferences?: {
    allowInstallment: boolean;
    allowMethods: boolean;
    defaultMaxInstallments: number;
    defaultPaymentMethods: Array<string>;
    takeOut: boolean;
    locker: boolean;
  };
  public pointOfSale: {
    id?: string;
    city: string;
    countryCode?: string;
    line1: string;
    line2: string;
    line3: string;
    neighborhood: string;
    postalCode: string;
    state: string;
  };
}
