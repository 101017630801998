import { Component, Input, OnInit } from '@angular/core';

import { StatusAlertModel } from '../../models/status/status-alert.model';
import { StateManagementService } from '../../state-management/state-management.service';

@Component({
  selector: 'app-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss'],
})
export class AlertComponent implements OnInit {
  @Input() public message: string = '';
  public showStatus: StatusAlertModel;
  public isShowAlert: boolean;

  constructor(private $notification: StateManagementService) {}

  public ngOnInit(): void {
    this.getStatusType();
  }

  public getStatusType(): void {
    this.$notification.sucessAlerts.subscribe((successAlerts) => {
      if (successAlerts) {
        this.showStatus = successAlerts;
        this.isShowAlert = true;

        setTimeout(() => {
          this.isShowAlert = false;
        }, this.showStatus.duration);
      }
    });

    this.$notification.errorAlerts.subscribe((errorAlerts) => {
      if (errorAlerts) {
        this.showStatus = errorAlerts;
        this.isShowAlert = true;

        setTimeout(() => {
          this.isShowAlert = false;
        }, this.showStatus.duration);
      }
    });
  }
}
