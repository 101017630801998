import { Component } from '@angular/core';
import { NzModalRef } from 'ng-zorro-antd/modal';

@Component({
  selector: 'app-in-analysis',
  templateUrl: './in-analysis.component.html',
  styleUrls: ['./in-analysis.component.scss'],
})
export class InAnalysisComponent {
  constructor(public modalRef: NzModalRef) {}

  public closeModal(): void {
    this.modalRef.close();
  }
}
