export class MarketplaceFiltersModel {
  public sellerId: string;
  public categoryId: string;
  public categoryLabel: string;
  public minValue: number;
  public maxValue: number;
  public categoryIds: string;
  public searchTerm: string;
  public orderBy: string;
  public sellerIds: string;
  public color: string;
}
