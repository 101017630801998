import { Component } from '@angular/core';
import { NzDrawerService } from 'ng-zorro-antd/drawer';
import { DrawerPendingCompanyCreateUserComponent } from '../drawer-pending-company-create-user/drawer-pending-company-create-user.component';
import { TableHeaderModel } from 'libs/shared/src/lib/models/table/table-header.model';
import { TableSettingsModel } from 'libs/shared/src/lib/models/table/table-settings.model';

@Component({
  selector: 'app-drawer-pending-company-details',
  templateUrl: './drawer-pending-company-details.component.html',
  styleUrls: ['./drawer-pending-company-details.component.scss'],
})
export class DrawerPendingCompanyDetailsComponent {
  public listOfHeadersColumn: TableHeaderModel[] = [
    {
      name: 'Data',
    },
    {
      name: 'Código NF',
    },
    {
      name: 'Status Sefaz ',
    },
    {
      name: 'Tipo',
    },
    {
      name: 'Valor',
    },
    {
      name: 'ID do usuário',
    },
    {
      name: 'Status Gen',
    },
  ];
  public listOfData: any[] = [
    {
      date: '04/01/2023',
      nfCode: '1234456789',
      statusSefaz: 'Confirmada',
      type: 'QR Code',
      value: 'R$ 86,90',
      userId: 123,
      statusGen: 'PENDING',
    },
  ];
  public settingValue: TableSettingsModel = {
    checkbox: false,
    pagination: true,
    footer: false,
    expandable: false,
  };

  public loading: boolean = false;

  constructor(private $drawer: NzDrawerService) {}

  public showCreateUserDrawer(event?: any): void {
    this.$drawer.create({
      nzTitle: 'Criar usuário',
      nzWidth: '800px',
      nzContent: DrawerPendingCompanyCreateUserComponent,
    });
  }
}
