import { gql } from 'apollo-angular';

const LIST_DIGITAL_STATEMENT_QUERY = gql`
  query ListDigitalStatement($sellerId: UUID4!, $initDate: NaiveDateTime!, $endDate: NaiveDateTime!) {
    listDigitalStatement(
      filters: { sellerId: $sellerId, transactedAt: { afterOrEqual: $initDate, beforeOrEqual: $endDate } }
    ) {
      statement {
        fee
        date
        type
        total
        amount
        seller {
          company {
            businessName
          }
          person {
            name
          }
        }
        payment {
          id
          status
        }
        description
      }
      currentBalance
    }
  }
`;

const LIST_EXTERNAL_STATEMENT_QUERY = gql`
  query ListExternalStatement($sellerId: UUID4!, $initDate: NaiveDateTime!, $endDate: NaiveDateTime!) {
    listExternalStatement(
      filters: { sellerId: $sellerId, transactedAt: { afterOrEqual: $initDate, beforeOrEqual: $endDate } }
    ) {
      balance
      statement {
        type
        date
        amount
        status
        order {
          id
          customerData
        }
        payment {
          id
          method
          credit {
            installments
          }
          status
        }
        description
        installment
        totalReceivable
        referenceNumber
        confirmationDate
        settlementDetails {
          agency
          accountNumber
          bank
        }
      }
    }
  }
`;

export { LIST_DIGITAL_STATEMENT_QUERY, LIST_EXTERNAL_STATEMENT_QUERY };
